<div class="x_panel x_panel_close">
        <div class="x_content">
               <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;"><span ><i  class="fa fa-arrow-left"></i></span>
                   <div  class="ml-auto text-right"><span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span><i  class="fa fa-chevron-right"></i><span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Gestion Droit d'Acces</span>
                   </div>
               </div>
               <div class="x_content">
                 <div class="x_panel_title x_panel_close">
    <div class="x_title">
      <h5><i class="fa fa-home"></i>&nbsp;&nbsp;<i class="fa fa-chevron-right">&nbsp;&nbsp;</i>Gestion droit d'acces</h5>
      <ul class="nav navbar-right panel_toolbox">
      </ul>
      <div class="clearfix"></div>
    </div>
    <div class="x_content">
      <div class="row bloc_recherche_table">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-3">

            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="">Groupe</label>
                <select type="text" id="" class="form-control form-control-sm" [(ngModel)]="droitsaccess.idgroupe" (ngModelChange)="updateAccess(droitsaccess.idgroupe)">
                  <option *ngFor = "let x of listegroupe" value="{{x.idgroupe}}">{{x.libelle}}</option>
                </select>
              </div>
            </div>
            <div class="col-md-3">

            </div>

          </div>
        </div>
      </div>
      <br>
          <div class="col-md-12">
              <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6 col-xs-12">
                  <ul class="submenu-ul">
                      <li class="li-style-secund" *ngFor = "let x of listemenus">
                        <a class="submenu-ul-active">
                          <i class="fas fa-home"></i>&nbsp;&nbsp;&nbsp;
                          <span><b>{{x.nom_menu}}</b></span>
                        </a>
                        <input *ngIf="x.sous_menus.length == 0" type="checkbox"  name="{{x.id_menu}}" style="float: right; margin: 8px;" [(ngModel)]="Elementliste[x.id_menu+'_0']" (ngModelChange)="ActiverDesactiver(x.id_menu, 0, droitsaccess.idgroupe)">
                        <ul class="submenu-ul" *ngIf="x.sous_menus.length > 0">
                            <li  class="li-style-secund" *ngFor = "let s of x.sous_menus">
                              <a class="submenu-ul-active">
                                <span><b>{{s.nom_sous_menu}}</b></span>
                              </a>
                              <input type="checkbox"  name="{{s.id_menu}}" style="float: right; margin: 8px;" [(ngModel)]="Elementliste[s.id_menu+'_'+s.id_sous_menu]" (ngModelChange)="ActiverDesactiver(s.id_menu, s.id_sous_menu, droitsaccess.idgroupe)">
                            </li>
                        </ul>
                      </li>
                  </ul>
                </div>
              </div>
          </div><br>
          <!--<div class="col-sm-12 text-right mobile-buttons">
                  <button class="btn btn-success btn-sm">Enregistrer</button>
                  <button class="btn btn-secondary btn-sm">Annuler</button>
                </div>-->
    </div>
</div>

               </div>
        </div>
</div>
