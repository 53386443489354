import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { Utilisateur } from '../creer-utilisateur/utilisateur';
import { Groupe } from '../creer-groupe/groupe';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';

@Component({
  selector: 'app-utilisateuretgroupe',
  templateUrl: './utilisateuretgroupe.component.html',
  styleUrls: ['./utilisateuretgroupe.component.css']
})
export class UtilisateuretgroupeComponent implements OnInit {
  liste_utilisateurs:any = [];
  liste_groupes:any = [];
  liste_groupes_utilisateurs:any = [];
  apiUrl:String = "";
  usersModel:Utilisateur = new Utilisateur();
  groupeModel:Groupe = new Groupe();

  constructor(private transfertDataService : transfertDataService) { }

  ngOnInit(): void {
    this.listeGroupe();
    this.listeUsers();
    this.listeUsersGroupe();
  }


  Enregistrer() : void{
   this.apiUrl = "SERVER/groupeware/groupeutilisateur/modifier";


    this.usersModel.idgroupe= Number(this.groupeModel.idgroupe);
    this.usersModel.active= Boolean(this.usersModel.active);

   var d = {"users": this.usersModel};
   this.transfertDataService.EnvoiData(d, this.apiUrl).pipe(
       retryWhen(error =>
         error.pipe(
           scan((acc:any, error:any) => {
             if (acc > 2) throw error;
             return acc + 1;
           }, 1),
           delay(1000)
         )
       )
   ).subscribe((value:any) => {
     this.listeUsersGroupe();
     this.Annuler();
   }, (erreur:any) => {
       console.log(erreur.status);
   })
  }

  Annuler() : void{
    this.usersModel = new Utilisateur();
    this.groupeModel = new Groupe();
  }

  listeGroupe() : void{

    this.apiUrl = "SERVER/groupeware/groupeutilisateur/listegroupe";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
      this.liste_groupes = response.liste_groupe;
    }, (erreur:any) => {
        console.log(erreur.status);
    })

  }

  listeUsers() : void{

    this.apiUrl = "SERVER/groupeware/groupeutilisateur/liste_utilisateur";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
      this.liste_utilisateurs = response.liste_user;
    }, (erreur:any) => {
        console.log(erreur.status);
    })
  }

  listeUsersGroupe() : void{

        this.apiUrl = "SERVER/groupeware/groupeutilisateur/utilisateurs_par_goupes";
        var data = {};
        this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
            retryWhen(error =>
              error.pipe(
                scan((acc:any, error:any) => {
                  if (acc > 2) throw error;
                  return acc + 1;
                }, 1),
                delay(1000)
              )
            )
        ).subscribe((response:any) => {
          this.liste_groupes_utilisateurs = response.data;
        }, (erreur:any) => {
            console.log(erreur.status);
        })
  }
Selectuser(iduser:any){


 for (let index = 0; index < this.liste_utilisateurs.length; index++) {
   if (this.liste_utilisateurs[index].id==iduser) {
     this.usersModel=this.liste_utilisateurs[index];
    break;

   }
 }
}
}
