
<div class="x_panel x_panel_close">
    <div class="x_content">
        <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;">
            <span ><i  class="fa fa-arrow-left"></i></span>
            <div  class="ml-auto text-right">
                <span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span>
                <i class="fa fa-chevron-right"></i>
                <span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Gestion Compte Escale Navire - Saisir Proforma</span>
             </div>
        </div>
        <br>

        <ul class="nav nav-tabs bar_tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="saisir-tab" data-toggle="tab" href="javascript:void(0)"
                   [ngClass]="{ 'active':activeTab==='saisir'}" (click)="Tabulation('saisir')"
                    role="tab" aria-controls="saisir" aria-selected="true">Saisir Proforma</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="javascript:void(0)"
                   [ngClass]="{ 'active':activeTab==='profile'}" (click)="Tabulation('profile')"
                    role="tab" aria-controls="profile" aria-selected="false">Liste Proforma</a>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div [ngClass]="{ 'show':activeTab==='saisir', 'active':activeTab==='saisir'}"
                class="tab-pane fade active show" id="saisir" role="tabpanel" aria-labelledby="saisir-tab">
                <br>
                <div class="x_panel_title x_panel_close" style="width: 99%;margin: 0px auto;display: table;">
                    <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-6 form-group">
                                  <div class="persoautocomplete">
                                        <label for="">Selectionner N°Dossier*</label>
                                       <input [(ngModel)]="DOSSIERModel.numeroDossier" type="text" name="numdossForm" id="numdossForm" placeholder="N°Dossier"  class="form-control form-control-sm" autocomplete="off" (focus)="reInitSearchForm()" (blur)="reInitSearchBlurForm()" disabled="disabled">
                                         <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="">
                                         </button>
                                          <div class="classPerso  dropdown-menu">
                                            <form class="px-0 py-3">
                                                <div class="col-md-12">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                          <input type="text" [(ngModel)]="dossier.numeroDossier" class="form-control form-control-sm" placeholder="N°Dossier" name="FormSaisiMouvNav" (ngModelChange)="ListeAutoCompleteDossier(dossier.numeroDossier)" autocomplete="off">
                                                          <div>
                                                            <br>
                                                              <table class="table_bloc_liste tableReseach">
                                                                  <thead class="theadfixed">
                                                                      <th>N°Dossier</th>
                                                                  </thead>
                                                                  <tbody>
                                                                      <tr class="SelectTable"  *ngFor="let x of listdoss" (click)="SelectionAutocomplete(x)">
                                                                          <td>{{x.numeroDossier}}</td>
                                                                      </tr>
                                                                  </tbody>
                                                              </table>
                                                          </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                          </div>
                                  </div>
                              </div>
                              <div class="form-group col-xs-12 col-md-6">
                                <label for="Compagnie">Navire</label>
                                <input type="text" class="form-control form-control-sm" id="" [(ngModel)]="DOSSIERModel.Navire"  placeholder="Navire" ng-disabled="true" disabled="disabled">
                              </div>


                                  <div class="form-group col-xs-12 col-md-6">
                                    <label for="Compagnie">Taux Euro</label>
                                    <input type="number" class="form-control form-control-sm" id=""  step="655" min="0" max="" id="number" [(ngModel)]="proformaModel.tauxEuro" value="655" placeholder="650">
                                    </div>
                            </div>
                          </div>
                        <br>
                        <div class="col-md-12">
                            <div class="">
                              <p>
                                <button class="btn btn-sm btn-primary" (click) = "ajouter()">
                                  <i class="fas fa-plus"></i>&nbsp;Nouveau
                                </button>&nbsp;&nbsp;
                                <button class="btn btn-sm btn-secondary" (click)="SuivantPrecedent('prev')" *ngIf="proformaModel.id_proforma != 0">
                                  <i class="fas fa-chevron-left"></i>
                                  &nbsp;&nbsp;&nbsp;Précédent&nbsp;
                                </button>
                                &nbsp;&nbsp;
                                <button class="btn btn-sm btn-secondary" (click)="SuivantPrecedent('next')" *ngIf="proformaModel.id_proforma != 0">
                                  Suivant&nbsp;&nbsp;&nbsp;
                                  <i class="fas fa-chevron-right" style="color:#fff;"></i>
                                </button>
                              </p>
                                <table class="table_mainoeuvre_fourniture table_bloc_liste">
                                    <thead>
                                      <tr>

                                          <th>Date</th>
                                          <th>Opération Escale</th>
                                          <th>Tarif(Euro)</th>
                                          <th>Unité</th>
                                          <th>TVA(%)</th>
                                          <th>HT(Euro)</th>
                                          <th>HT(CFA)</th>
                                          <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr *ngFor="let x of dataSousMenuModel;let i = index;" (click)="selectionligne(i, x.ordre)" [ngClass]="i == ligneSelectionne ? 'choix' : ''">
                                        <td>
                                          <input type="date" class="form-control form-control-sm" [(ngModel)]="x.date" placeholder="jj/mm/aaaa" style="width: 100px;" (ngModelChange)="UpdateEltProforma(x)">
                                        </td>
                                        <td>

                                          <select class="form-control form-control-sm" name="Fournisseur" id="fourn" [(ngModel)]="x.type_activite">
                                            <option value="">Sélectionner Type Opération</option>
                                            <option  *ngFor="let x of listeoperation" value="{{x.libelle}}">{{x.libelle}}</option>
                                          </select>

                                        </td>
                                        <td>
                                          <input type="number" class="form-control form-control-sm" placeholder="Tarif" [(ngModel)]="x.prix_unitaire"(ngModelChange)="UpdateEltProforma(x)">
                                        </td>
                                        <td>
                                          <input type="number" class="form-control form-control-sm" placeholder="Unité" [(ngModel)]="x.nbre"(ngModelChange)="UpdateEltProforma(x)">
                                        </td>

                                        <td>
                                          <input type="number" class="form-control form-control-sm" placeholder="Tva(%)" [(ngModel)]="x.tva"(ngModelChange)="UpdateEltProforma(x)">
                                        </td>
                                        <td>
                                          <input type="number" class="form-control form-control-sm" placeholder="HT(CFA)" [(ngModel)]="x.montant_ht_cfa"(ngModelChange)="UpdateEltProforma(x)">
                                        </td>
                                        <td>
                                          <input type="number" class="form-control form-control-sm" placeholder="HT(Euro)" [(ngModel)]="x.montant_ht_euro"(ngModelChange)="UpdateEltProforma(x)">
                                        </td>
                                        <td class="text-center">
                                          <button class="btn btn-danger btn-sm" type="button" (click)="supprimer_sous_menu(x.id_elt_proforma, i)" title="supprimer">
                                                <i class="fa fa-trash-alt" ></i>
                                          </button>

                                          </td>
                                      </tr>
                                      <tr>
                                              <td style="text-align: right; font-weight:bold" colspan="7">Montant HT(EURO)</td>
                                              <td colspan="7">{{MTHT}}</td>
                                           </tr>
                                           <tr>
                                              <td style="text-align: right; font-weight:bold" colspan="7">Montant HT(CFA)</td>
                                              <td colspan="7">{{MTHT_euro}}</td>
                                           </tr>
                                           <tr>
                                              <td style="text-align: right; font-weight:bold" colspan="7">Montant TVA(CFA)</td>
                                                <td colspan="7">{{MTTVA}}</td>
                                           </tr>
                                           <tr>
                                              <td style="text-align: right; font-weight:bold" colspan="7">Montant TVA(EURO)</td>
                                              <td colspan="7">{{MTTVA_euro}}</td>
                                           </tr>

                                           <tr>
                                              <td style="text-align: right; font-weight:bold" colspan="7">Montant TTC(EURO)</td>
                                              <td colspan="7">{{MTTTC}}</td>
                                           </tr>
                                           <tr>
                                              <td style="text-align: right; font-weight:bold" colspan="7">Montant TTC(CFA)</td>
                                              <td colspan="7">{{MTTTC_euro}}</td>
                                           </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br>
                        <div class="col-md-12 text-center">
                        <div class="card-body">
                            <span>Télécharger des Documents</span>
                               <br><br>
                               <div class="col-md-12">
                                <div class="download-card" style="text-align: left!important;">
                                  <div class="col-md-12">
                                    <div class="row">
                                      <div class="inline"><br><br><img style ="width: 35px;" src="./assets/img/cloud.png"></div>
                                      <div class="inline1"><br><br><p><span>&nbsp;Cliquez sur le bouton pour ajouter des fichers</span><br>&nbsp; <button type="button" class="btn btn-primary btn-sm btn-sm-perso" (click)="fileInputBlock.click()">PARCOURIR LES FICHIERS </button></p></div>
                                    </div>
                                  </div>
                                </div>
                               </div>
                               <div class="row blockphotosZone" *ngIf="photos_previsualisation.length > 0">
                                    <br><br>
                                    <div class="col-md-3 photosZone" *ngFor="let x of photos_previsualisation"
                                         [ngClass]=" x.iddocument == '' ? 'uploadfile' : ''">
                                        <span>
                                            <span *ngIf="x.iddocument == ''" class="fichierToUpload">FICHIER NON ENREGISTRE</span>
                                        </span>
                                        <img [src]="x.src" id="file_{{x.iddocument}}">
                                        <div class="text-center text-center pt-2 pb-2 action-band">
                                          <button class="btn btn-sm btn-secondary" (click)="apercu(x.iddocument)">
                                                <i class="fas fa-search"></i>
                                            </button>&nbsp;&nbsp;
                                            <button class="btn btn-sm btn-danger" (click)="supprimer_image(x.lien, x.iddocument)">
                                                <i class="fas fa-trash"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <input type="file" name="file" id="file" [hidden]="true" (change)="onFileChanged($event)" multiple="multiple" #fileInputBlock>
                        </div>
                        <br>
                        <div class="text-right-btn">
                            <button type="button" class="btn btn-primary  btn-sm btn-enregistrer" (click) = "Enregistrer(proformaModel, $event)">Enregistrer</button>&nbsp;
                            <button type="button" class="btn btn-secondary btn-sm btn-annuler" (click)="Annuler()">Annuler</button>&nbsp;
                         </div>
                        <br>
                    </div>
                    <br>
                </div>


            <div class="tab-pane fade" id="profile" role="tabpanel"  aria-labelledby="profile-tab"
                 [ngClass]="{'show':activeTab==='profile', 'active':activeTab==='profile'}">
                <br>
                    <div class="">
                      <div class="  form-group col-xs-12 col-md-12 recherche_liste">
                        <div class="row">
                          <div class="col-sm-12 col-md-3 ">
                            <div class="form-group">
                              <label for="">N°Dossier</label>
                              <input type="text" id="" placeholder="N°DOSSIER" class="form-control form-control-sm" name="libe" [(ngModel)]="recherche.numeroDossier" (ngModelChange)="RechercheProfrma(recherche)">
                            </div>
                          </div>

                          <div class="col-sm-12 col-md-3 ">
                            <div class="form-group">
                              <label for="">Période du </label>
                              <input type="date" id="" placeholder="jj/mm/aaaa" class="form-control form-control-sm" name="libe" [(ngModel)]="recherche.date1" (ngModelChange)="RechercheProfrma(recherche)">
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-3 ">
                            <div class="form-group">
                              <label for="">Au</label>
                              <input type="date" id="" placeholder="jj/mm/aaaa" class="form-control form-control-sm" name="libe" [(ngModel)]="recherche.date2" (ngModelChange)="RechercheProfrma(recherche)">
                            </div>
                          </div>
                        </div>


                        </div>
                    </div>
                    <br>
                    <div class="Table_Longer">
                        <table class="table_bloc_liste">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Date Proforma</th>
                                    <th>N°Dossier</th>
                                    <th>Montant HT</th>
                                    <th>Montant TVA</th>
                                    <th>Montant TTC</th>
                                    <th>Saisir Par</th>
                                    <th>Créer Le</th>
                                    <th>Modifier Par</th>
                                    <th>Modifier Le</th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor = "let x of listproforma">
                                  <td>{{x.id_proforma}}</td>
                                  <td>{{x.date_creation}}</td>
                                  <td>{{x.numeroDossier}}</td>
                                  <td>{{x.montant_ht}}</td>
                                  <td>{{x.montant_tva}}</td>
                                  <td>{{x.montant_ttc}}</td>
                                  <td>{{x.nom}}</td>
                                  <td>{{x.date_creation}}</td>
                                  <td>{{x.modifierpar}}</td>
                                  <td>{{x.datemodification}}</td>
                                  <td class="text-center">
                                    <button  class="btn btn-success btn-sm" (click) ="AfficherDonner(x.id_proforma)">
                                           <i class="fas fa-pencil-alt"></i>
                                    </button>
                                  </td>
                                   <td class="text-center">
                                      <button  class="btn btn-danger btn-sm" (click) = "SupprimerPopup(x.id_proforma)">
                                         <i class="far fa-trash-alt"></i>
                                      </button>
                                   </td>
                                    <td class="text-center">
                                        <button class="btn btn-warning btn-sm" (click)="downloadDoc(x.id_dechargelent)" *ngIf="x.doc == 1">
                                          <i class="fa fa-download"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row">
                      <div  class="col-md-6 form-group"></div>
                      <div  class="col-md-3 form-group">
                        <div  class="form-group">
                          <label >&nbsp;</label>
                          <button  class="btn btn-success btn-block btn-sm" (click)="Exporter()">
                            <i  class="fas fa-excel"></i>&nbsp;Exporter</button>
                        </div>
                      </div>
                      <div  class="col-md-3 form-group">
                        <div  class="form-group">
                          <label >&nbsp;</label>
                          <button  class="btn btn-warning btn-block btn-sm" (click)="ConfirmationImpressionFormulaire()"><i  class="fas fa-print"></i>&nbsp;Imprimer</button>
                        </div>
                      </div>
                    </div>
                    <br>
            <div class="col-xs-12 col-ms-12 text-right" style="float: right;" *ngIf="liste_pagination.length > 1">
                <div class="pagination pagination_liste_tableau">
                  <a class="page-link" *ngFor="let n of liste_pagination, let i = index" [ngClass]="{'active': i == activePagination}" (click)="CurrentPagePagination(n,i,$event)">{{i + 1}}</a>
                </div>
            </div>

                </div>

            </div>

        </div>
</div>
<button type="button" class="btn btn-primary  btn-sm suppdossier" style="display:none;" data-backdrop="static" data-toggle="modal" data-target=".bs-Form-sm"></button>
<div class="modal fade bs-Form-sm" tabindex="-1" role="dialog" aria-hidden="true">
 <div class="modal-dialog modal-sm" role="document">
  <div class="modal-content">
      <div class="row container_modal">
          <div class="modal-header">
            <h6 class="modal-title" style="font-weight: bold;">SUPPRESSION Proforma</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>
            </button>
          </div>
          <div>
             <div class="modal-content-dl">
              <p>Souhaitez-vous supprimer ce Proforma ?</p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" data-dismiss="modal"  class="btn btn-primary btn-sm" (click) = "Supprimer()">Oui</button>
            <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Non</button>
          </div>
      </div>
    </div>
  </div>
</div>
