import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { AuthService } from '../../../services/connexion/auth.service';
import { MOUVEMENTRECUPCONTENEUR } from '../../../classes/mouvementRecupConteneur';

@Component({
  selector: 'app-mouvement-recup-conteneur',
  templateUrl: './mouvement-recup-conteneur.component.html',
  styleUrls: ['./mouvement-recup-conteneur.component.css']
})
export class MouvementRecupConteneurComponent implements OnInit {
    activeTab:String="saisir";
  apiUrl:String = "";
  listeMvtConteneur:any = [];
  recherche:any = {};
  supp_id:any = "";
  erreurSuppression = "";

  MvtConteneur:MOUVEMENTRECUPCONTENEUR = new MOUVEMENTRECUPCONTENEUR();

  constructor(private transfertDataService : transfertDataService, private AuthService : AuthService) { }


  ngOnInit(): void {
    this.ListeMvtConteneur();
  }

  Annuler():void{
    this.MvtConteneur = new MOUVEMENTRECUPCONTENEUR();
    this.recherche.mouvementConteneur = "";
  }

  search(activeTab:String){
     this.activeTab=activeTab
   };

    Enregistrer(data:any): void{
    //Champ obligatoire
    if(this.VerificationChampsObligatoire(["#mouvementConteneur1"]) == false){
      return;
    }

    var infoUser = this.AuthService.getInfoUtilisateur();

    if(this.MvtConteneur.idMouvementConteneur == 0){
      this.apiUrl = "SERVER/importparametre/mvtrecupconteneur/enregistrer";
      this.MvtConteneur.creerPar = infoUser.id;

      /*Unicite de l'enregistrement*/
      for(let i = 0; i < this.listeMvtConteneur.length; i++){
        if(data.mouvementConteneur == this.listeMvtConteneur[i].mouvementConteneur){
          $("#mouvementConteneur1").css("border","1px solid red");
            return;
        }
      }

    }else{
      this.apiUrl = "SERVER/importparametre/mvtrecupconteneur/modifier";
      this.MvtConteneur.modifierPar = infoUser.id;
    }



    var d = {"MvtConteneur": this.MvtConteneur};
    this.transfertDataService.EnvoiData(d, this.apiUrl).pipe(
            retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((value:any) => {
          /*CONTRAINTE D'UNICITE*/
          if(value.status == -1){
              $("#mouvementConteneur1").css("border","1px solid red");
            }else{
              this.ListeMvtConteneur();
             this.search("profile");
             this.Annuler();
            } 
         }, (erreur:any) => {
             console.log(erreur.status);
         })

  }



  ListeMvtConteneur(): void{
    this.apiUrl = "SERVER/importparametre/mvtrecupconteneur/listeconteneur";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((response:any) => {
         this.listeMvtConteneur = response.liste;

       }, (erreur:any) => {
           console.log(erreur.status);
       })

  }

  information(id:any):void{
    this.apiUrl = "SERVER/importparametre/mvtrecupconteneur/information";
    var data = {id:id};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
      retryWhen(error =>
        error.pipe(
              scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((response:any) => {
               this.MvtConteneur = response.mvt[0];
               this.search("saisir");

           }, (erreur:any) => {
             console.log(erreur.status);
         })

     }

  SupprimerPopup(id:any): void{
    $(".suppliste").trigger("click");
    this.supp_id = id;
  }

  supprimer(id:any):void{
       this.apiUrl = "SERVER/importparametre/mvtrecupconteneur/supprimer";
       var data = {id:id};
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
     ).subscribe((value:any) => {
        if(value.status == -1){
            this.erreurSuppression = "Impossible de supprimer ce Mouvement Récupération Conteneur.";
            $(".exampleModal").trigger("click");
        }else{
          this.ListeMvtConteneur();
        }
       }, (erreur:any) => {
           console.log(erreur.status);
       })

     }

  //Fonction de verification de champs

  VerificationChampsObligatoire(tableau_champs_obligatoires:any){
        var verification_formulaire = true;
        for (var i = 0; i < tableau_champs_obligatoires.length; i++) {
            if($(tableau_champs_obligatoires[i]).val() == "" || $(tableau_champs_obligatoires[i]).val() == null || $(tableau_champs_obligatoires[i]).val() == "? string: ?"){
                    $(tableau_champs_obligatoires[i]).css("border","1px solid red");
                    verification_formulaire = false;
                    document.getElementById(tableau_champs_obligatoires[i].replace("#",""))?.scrollIntoView({behavior: "smooth"});
                    break;
            }else{
                $(tableau_champs_obligatoires[i]).css("border","");
            }
        }

        return verification_formulaire;
  }

  recherch(data:any): void{
     if(this.recherche.mouvementConteneur == "")
     this.ListeMvtConteneur();
     else{
       this.apiUrl = "SERVER/importparametre/mvtrecupconteneur/recherche";
       var data = data;
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
     ).subscribe((value:any) => {
         this.listeMvtConteneur= value.liste;
       }, (erreur:any) => {
           console.log(erreur.status);
       })
     }

 }

}
