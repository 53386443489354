import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-creer-booking',
  templateUrl: './creer-booking.component.html',
  styleUrls: ['./creer-booking.component.css']
})
export class CreerBookingComponent implements OnInit {
  activeTab:String="saisir";

  constructor() { }

  ngOnInit(): void {
  }

  search(activeTab:String){
    this.activeTab=activeTab
  }

}
