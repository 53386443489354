import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { pipe } from 'rxjs';
import { PRODUIT } from '../../../classes/produit';
import { PRODUITMAG } from '../../../classes/produit_magasin';
import { MAGASIN } from '../../../classes/magasin';
import { DOSSIER } from '../../../classes/Dossier';
import { PRODUITDISPO } from '../../../classes/produit_dispo_magasin';
import { MVT_PRODUIT } from '../../../classes/mvt_produit_magasin';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import {DomSanitizer} from '@angular/platform-browser';
@Component({
  selector: 'app-stock-magasins-sorties-magasin',
  templateUrl: './stock-magasins-sorties-magasin.component.html',
  styleUrls: ['./stock-magasins-sorties-magasin.component.css']
})
export class StockMagasinsSortiesMagasinComponent implements OnInit {
  
    apiUrl:String = "";
    activeTab:String="saisir";
    dossier:any = "";
    listauto:any = [];
    dossierAuto:any={};
    produitAuto:any={};
    liste_magasins:any = [];
    listautorech:any = [];
    liste_prodmagasins:any = [];
    listautoSpecifik:any = [];
    recherche:any={};
    liste_Clients:any = [];
    liste_specifi:any = [];
    photos_previsualisation:any = [];
    array_files:any = [];
    produitmagModel:PRODUITMAG = new PRODUITMAG();
    produitdispoModel:PRODUITDISPO = new PRODUITDISPO();
    magasinModel:MAGASIN = new MAGASIN();
    dossierModel:DOSSIER = new DOSSIER();
    prduitModel:PRODUIT = new PRODUIT();
    mvtModel:MVT_PRODUIT = new MVT_PRODUIT();
    constructor(private sanitizer:DomSanitizer, private transfertDataService : transfertDataService) { }

    ngOnInit(): void {
      this.listeProduitmag();
      this.recherche.numDossier="";
      this.recherche.nom_societe="";
      this.recherche.nom_produit_magasin="";
      this.recherche.NomMagasin="";
      this.listemagasin();
      //this.listeClient();
      //this.listeprodui();
    }

    search(activeTab:String){
       this.activeTab=activeTab;
     };


       Annuler(){
         this.produitmagModel = new PRODUITMAG();
         this.dossierModel = new DOSSIER();
         this.prduitModel = new PRODUIT();
         this.magasinModel = new MAGASIN();
         this.produitdispoModel= new PRODUITDISPO();
         this.mvtModel= new MVT_PRODUIT();
         this.dossierAuto = {};
         this.produitAuto = {};
       }

       ListeAutoCompleteDossier(numDoss:any){


        this.apiUrl = "SERVER/stockmagasin/sortiemagasin/recherche_magasin";
           var data = {NumDoss:numDoss};

           this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((value:any) => {
             this.listauto= value.listAuto;
           }, (erreur:any) => {
           })
       }



       SelectionAutocomplete(data:any): void{
         this.dossierAuto.iddossier= data.idGestionNavire;
         this.dossierAuto.numDossier= data.numeroDossier;
         this.dossierAuto.numeroDossier= data.numeroDossier;
         this.dossierAuto.NomCompagnie = data.NomCompagnie;

       }

       ListeAutoCompleteProduit(nomSousProduit:any){


        this.apiUrl = "SERVER/stockmagasin/sortiemagasin/recherche_produit";
           var data = {nom:nomSousProduit, iddossier:this.dossierAuto.iddossier};

           this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((value:any) => {
             this.listautoSpecifik= value.listautoSpecifiks;
           }, (erreur:any) => {
           })
       }

       SelectionAutocompleteProduit(data:any): void{
         this.produitAuto.idProduit= data.idProduit;
         this.produitAuto.nomSous= data.nomSousProduit;
         this.produitAuto.nomSousProduit= data.nomSousProduit;
         this.produitAuto.nom_societe = data.nom_societe;
         this.produitAuto.poids = data.poids;
         this.produitAuto.idClient =data.idClient;
         this.produitAuto.idSousProduit=data.idSousProduit;
         this.produitmagModel.id_produit_magasin=data.id_produit_magasin;
       }



         listemagasin() : void{

           this.apiUrl = "SERVER/stockmagasin/sortiemagasin/listemagasin";
           var data = {};
           this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
               retryWhen(error =>
                 error.pipe(
                   scan((acc:any, error:any) => {
                     if (acc > 2) throw error;
                     return acc + 1;
                   }, 1),
                   delay(1000)
                 )
               )
           ).subscribe((response:any) => {
             this.liste_magasins = response.liste_magasin;
           }, (erreur:any) => {
           })

         }

        PoidsTotalCalcule() : void{
           this.produitdispoModel.poids_dispo = (this.produitAuto.poids * this.produitdispoModel.qte_dispo)/1000;
        }




          Enregistrer(): void{
            if(this.mvtModel.id_mvt == 0){
              this.apiUrl = "SERVER/stockmagasin/sortiemagasin/enregistrer";
            }else{
              this.apiUrl = "SERVER/stockmagasin/sortiemagasin/modifier";
            }
             this.produitmagModel = this.dossierAuto;
             this.produitmagModel = this.produitAuto;

            var d = {"produitmag": this.produitmagModel,"idMagasin": this.magasinModel.idMagasin,"poids_dispo": this.produitdispoModel.poids_dispo,
                   "qte_dispo": this.produitdispoModel.qte_dispo,"date_mvt":this.mvtModel.date_mvt,"clientapprovisionne":this.produitAuto.nom_societe,
                   "provenance":this.mvtModel.provenance, "bl":this.mvtModel.bl_lta, "mvt":this.mvtModel};

            this.transfertDataService.EnvoiData(d, this.apiUrl).pipe(
                retryWhen(error =>
                  error.pipe(
                    scan((acc:any, error:any) => {
                      if (acc > 2) throw error;
                      return acc + 1;
                    }, 1),
                    delay(1000)
                  )
                )
            ).subscribe((value:any) => {
                if(value.status == -1){
                   $(".suppdossier").trigger("click");
                 }else{
                    this.listeProduitmag();
                    this.Annuler();
                    this.search("profile");
                 }
              
            }, (erreur:any) => {
            })
          }

         listeProduitmag(): void{
           this.apiUrl = "SERVER/stockmagasin/sortiemagasin/listeproduitmag";
           var data = {};


           this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
               retryWhen(error =>
                 error.pipe(
                   scan((acc:any, error:any) => {
                     if (acc > 2) throw error;
                     return acc + 1;
                   }, 1),
                   delay(1000)
                 )
               )
           ).subscribe((response:any) => {
             this.liste_prodmagasins = response.liste_prodmagasins;
           }, (erreur:any) => {
           })
         }

         autocompleteProduitClient(id_client:any){


                    this.apiUrl = "SERVER/stockmagasin/sortiemagasin/listeautocompleteProduitClient";
                    var data = {id_client:id_client};


                    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
                        retryWhen(error =>
                          error.pipe(
                            scan((acc:any, error:any) => {
                              if (acc > 2) throw error;
                              return acc + 1;
                            }, 1),
                            delay(1000)
                          )
                        )
                    ).subscribe((response:any) => {
                      this.liste_specifi = response.listeProduitClient;

                    }, (erreur:any) => {
                    })

                    this.Recherche(this.recherche);

         }
         listeClient() : void{

           this.apiUrl = "SERVER/stockmagasin/sortiemagasin/listeclient";
           var data = {iddossier:this.recherche.iddossier};


           this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
               retryWhen(error =>
                 error.pipe(
                   scan((acc:any, error:any) => {
                     if (acc > 2) throw error;
                     return acc + 1;
                   }, 1),
                   delay(1000)
                 )
               )
           ).subscribe((response:any) => {
             this.liste_Clients = response.liste_client;


             this.listeprodui();
           }, (erreur:any) => {
           })

         }


         listeprodui() : void{

           this.apiUrl = "SERVER/stockmagasin/sortiemagasin/listeprodmag";
           var data = {idClient:this.recherche.idClient};

           this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
               retryWhen(error =>
                 error.pipe(
                   scan((acc:any, error:any) => {
                     if (acc > 2) throw error;
                     return acc + 1;
                   }, 1),
                   delay(1000)
                 )
               )
           ).subscribe((response:any) => {
             this.liste_specifi = response.listeprodmag;

           }, (erreur:any) => {
           })

         }
          AfficherDonner(id_mvt:any): void{
             this.apiUrl = "SERVER/stockmagasin/sortiemagasin/information";
             var data = {id:id_mvt};
             this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
               retryWhen(error =>
                 error.pipe(
                   scan((acc:any, error:any) => {
                     if (acc > 2) throw error;
                     return acc + 1;
                   }, 1),
                   delay(1000)
                 )
               )
           ).subscribe((response:any) => {
                this.produitmagModel = response.listeproduitmagModel[0];
               this.dossierAuto.numDossier= response.listeproduitmagModel[0].numeroDossier;
               this.magasinModel.idMagasin= response.listeproduitmagModel[0].id_magasin;
               this.dossierAuto.NomCompagnie= response.listeproduitmagModel[0].Navire;
               this.produitAuto.nomSous= response.listeproduitmagModel[0].nom_produit_magasin;
               this.produitAuto.nomSous = response.listeproduitmagModel[0].id_produit_magasin;
               this.produitAuto.nom_societe= response.listeproduitmagModel[0].nom_societe;
               this.produitdispoModel.qte_dispo= response.listeproduitmagModel[0].qte;
               this.produitdispoModel.poids_dispo= response.listeproduitmagModel[0].poids;
               this.mvtModel.date_mvt= response.listeproduitmagModel[0].date_mvt;
               this.mvtModel.provenance= response.listeproduitmagModel[0].provenance;
               this.mvtModel.bl_lta= response.listeproduitmagModel[0].bl_lta;
               this.mvtModel.id_mvt= response.listeproduitmagModel[0].id_mvt;

               
               this.produitAuto.nomSous = response.listeproduitmagModel[0].nom_produit_magasin;
               this.produitAuto.nomSousProduit= response.listeproduitmagModel[0].nom_produit_magasin;
               this.produitAuto.nom_societe = response.listeproduitmagModel[0].nom_societe;
               this.produitAuto.poids = response.listeproduitmagModel[0].poidsunitaire;
               this.produitAuto.idClient = response.listeproduitmagModel[0].id_client;
               this.produitAuto.idSousProduit= response.listeproduitmagModel[0].id_sous_produit;
               this.produitmagModel.id_produit_magasin= response.listeproduitmagModel[0].id_produit_magasin;
               this.search("saisir");
               
             }, (erreur:any) => {
           })
         }

         Supprimer(id_mvt:any): void{
             this.apiUrl = "SERVER/stockmagasin/sortiemagasin/supprimer";
             var data = {id:id_mvt};
             this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
               retryWhen(error =>
                 error.pipe(
                   scan((acc:any, error:any) => {
                     if (acc > 2) throw error;
                     return acc + 1;
                   }, 1),
                   delay(1000)
                 )
               )
           ).subscribe((value:any) => {
               this.listeProduitmag();
             }, (erreur:any) => {
             })
         }

         Recherche(data:any): void{
          if(this.recherche.numeroDossier == "" && this.recherche.nom_societe == "" && this.recherche.nom_produit_magasin == "" && this.recherche.NomMagasin == "" ){
              this.listeProduitmag();
          }else{
             this.apiUrl = "SERVER/stockmagasin/sortiemagasin/recherche";
             var data = data;

             this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
               retryWhen(error =>
                 error.pipe(
                   scan((acc:any, error:any) => {
                     if (acc > 2) throw error;
                     return acc + 1;
                   }, 1),
                   delay(1000)
                 )
               )
           ).subscribe((value:any) => {

              this.liste_prodmagasins= value.liste_prodmagasins;
             }, (erreur:any) => {
             })
           }

         }

         ListeAutoCompleteDossierrech(numDoss:any){
          this.apiUrl = "SERVER/stockmagasin/sortiemagasin/recherche_magasinrech";
             var data = {NumDoss:this.recherche.numeroDossier};
             this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
               retryWhen(error =>
                 error.pipe(
                   scan((acc:any, error:any) => {
                     if (acc > 2) throw error;
                     return acc + 1;
                   }, 1),
                   delay(1000)
                 )
               )
           ).subscribe((value:any) => {
               this.listautorech= value.listautorech;

             }, (erreur:any) => {
             })
         }
         SelectionAutocompleterech(data:any): void{
           this.recherche.iddossier= data.iddossier;
           this.recherche.numDossier= data.numeroDossier;
           this.recherche.numeroDossier= data.numeroDossier;
           this.listeClient();
           this.Recherche(this.recherche);

         }


  apercu(iddocument:any): void{
    var element = <HTMLImageElement> $("#file_" + iddocument).get(0);
    element.requestFullscreen();
  }

  supprimer_image(nomfichier:any, iddocument:any) : void{
        if(iddocument == ""){
            for (var i = 0; i < this.array_files.length; i++) {

                if(this.array_files[i].name == nomfichier){
                    this.array_files.splice(i, 1);
                }
            }

            for (var i = 0; i < this.photos_previsualisation.length; i++) {
                if(this.photos_previsualisation[i].lien == nomfichier){
                    this.photos_previsualisation.splice(i, 1);
                }
            }
        }else{
            var apiUrl = "SERVER/gestionnavire/navire/SupprimerFile";
            var data = {id:iddocument};
            this.transfertDataService.EnvoiData(data, apiUrl).pipe(
               retryWhen(error =>
                 error.pipe(
                   scan((acc:any, error:any) => {
                     if (acc > 2) throw error;
                     return acc + 1;
                   }, 1),
                   delay(1000)
                 )
               )
            ).subscribe((value:any) => {
                for (var i = 0; i < this.photos_previsualisation.length; i++) {
                    if(this.photos_previsualisation[i].iddocument == iddocument){
                        this.photos_previsualisation.splice(i, 1);
                    }
                }
             }, (erreur:any) => {
            })
        }

  }

  onFileChanged(event: any): void {
        this.array_files = [];
        var src = URL.createObjectURL(event.target.files[0]);
        var elements = event.target.files;
        for(var i = 0; i < elements.length; i++){
          var src = URL.createObjectURL(event.target.files[i]);
          this.photos_previsualisation.push({iddocument : '', src : this.sanitizer.bypassSecurityTrustUrl(src), lien : event.target.files[i]});
          this.array_files.push(event.target.files[i]);
        }
  }
}
