export class contenudecharge {
  public idContenuNavire: number = 0;
	public id_dechargement: number = 0;
	public reference: string = "";
	public idClient: number = 0;
	public idProduit: number = 0;
	public designationProduit: string = "";;
	public PoidsNet: number = 0;
	public qte: number = 0;
	public iduser: number = 0;
	public lieu_destination: string = "";
	public cale: number = 0;
	public immat_vehicule: string = "";
	public date: string = "";
  constructor() {  

  }

}
