export class mouvementproduit {

  public idMvtProduit: number = 0;
  public idProduit: number = 0;
  public sens: string = "";
  public qte: string = "";
  public dateMouve: string = "";
  public idUser: string = "";
  public dateCreation: string = "";
  public commentaire: string = "";
  public modifierpar: number = 0;
  public datemodification: string = "";
  constructor() {

  }

}
