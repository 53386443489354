import { Component, OnInit } from '@angular/core';
import { journaltransite } from '../../../classes/journaltransite';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { ConfigurationService } from '../../../config/configuration.service';
 import { AuthService } from '../../../services/connexion/auth.service';

@Component({
  selector: 'app-etat-journal-transit',
  templateUrl: './etat-journal-transit.component.html',
  styleUrls: ['./etat-journal-transit.component.css']
})
export class EtatJournalTransitComponent implements OnInit {
  apiUrl:String = "";
  activeTab:String="saisir";
  dataSousMenu:any = [];
  listautoSpecifik:any = [];
  camion:any = [];
  dataSousMenuModel:any = [];
  dossier:any={};
 Totalcumul_sacs:any = 0;
 Totalcumul_poids:any = 0;
  produit:any={};
  listdoss:any = [];
  journaltransite:journaltransite = new journaltransite();

  constructor(private transfertDataService : transfertDataService, private AuthService : AuthService, private ConfigurationService : ConfigurationService) { }

  ngOnInit(): void {
    this.liste();
  }


    search(activeTab:String){
      this.activeTab=activeTab;
    };
    reInitSearchForm(): void{
      this.listdoss = [];
    }
    reInitSearchBlurForm(): void{
      setTimeout((val:any) => {
          this.listdoss = [];
      }, 500);
    }
    ListeAutoCompleteDossier(numerodossier:any){
     this.apiUrl = "SERVER/gestionnavire/naviremouvementsnavire/recherche_dossier";
        var data = {numeroDossier:numerodossier};
        this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
          retryWhen(error =>
            error.pipe(
              scan((acc:any, error:any) => {
                if (acc > 2) throw error;
                return acc + 1;
              }, 1),
              delay(1000)
            )
          )
      ).subscribe((value:any) => {
          this.listdoss= value.listdoss;
        }, (erreur:any) => {
            console.log(erreur.status);
        })
    }
    SelectionAutocomplete(data:any): void{
      this.dossier.numeroDossier1 = data.numeroDossier;
      this.dossier.Navire = data.Navire;
      this.journaltransite.idGestionNavire = data.idGestionNavire;
      this.liste();
    }
    liste(): void{
      this.apiUrl = "SERVER/journalTransite/etat/liste";
      var data = {idGestionNavire:this.journaltransite.idGestionNavire};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
          retryWhen(error =>
            error.pipe(
              scan((acc:any, error:any) => {
                if (acc > 2) throw error;
                return acc + 1;
              }, 1),
              delay(1000)
            )
          )
      ).subscribe((response:any) => {
        this.dataSousMenu = response.liste_sous_menu;
      }, (erreur:any) => {
          console.log(erreur.status);
      })
    }
}
