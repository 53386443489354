<div class="container-fluid">
    <div class="row">
      <div class="Menu" [ngStyle]="{'width': resize === true ? '55px' : ''}">
          <app-menu  (resize_menu_change)="resize_window($event)"></app-menu>
      </div>
      <div class="EspaceAffichage" [ngStyle]="{'width': resize === true ? 'calc(100% - 55px)' : ''}">
        <app-espace-affichage  [resize]="resize"></app-espace-affichage>
      </div>
    </div>
</div>
