import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { ConfigurationService } from '../../../config/configuration.service';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { AuthService } from '../../../services/connexion/auth.service';
import { CRUDService } from '../../../services/CRUD/crud.service';
import {DomSanitizer} from '@angular/platform-browser';
import { mouvementproduit } from '../../../classes/mouvementproduit';

@Component({
  selector: 'app-entree-fourniture',
  templateUrl: './entree-fourniture.component.html',
  styleUrls: ['./entree-fourniture.component.css']
})
export class EntreeFournitureComponent implements OnInit {

  activeTab:String="saisir";
  dataSousMenuModel:any = [];
  liste_Auto_Vehicule:any = [];
  liste_Auto_Vehicule2:any = [];
  apiUrl:any;
  Idveh:any;
  Auto:any = {};
  mouvementproduit:mouvementproduit = new mouvementproduit();
  liste:any =  [];
  recherche:any = {};
  lien_fiche_ExpST:any;
   activePagination:number = 0;
  liste_pagination:any = [];
  Err:any;
  ModaleTitle:any;
  ModaleText:any;
  ModalFoot:any = "FootNoErr";
  QteRestore:any;
  listefournisseur:any = [];
  listecategorieproduit:any = [];
  suppiddossier:any = {};
  constructor(private sanitizer:DomSanitizer, private transfertDataService : transfertDataService, private AuthService : AuthService, private CRUDService : CRUDService, private ConfigurationService : ConfigurationService, private HttpClient : HttpClient) { }


  ngOnInit(): void {
    this.listeMouvement();
    this.recherche.numProduit = "";
    this.recherche.NomProd = "";
    this.recherche.idfournisseur = "";

    this.recherche.date1 = "";
    this.recherche.date2 = "";
    this.Listefournisseur();
    this.Listecategorieproduit();
  }
  Tabulation(activeTab:any): void{
      this.activeTab=activeTab;
   }

  reInitSearch(): void{

  }

  reInitSearchBlur(): void{
    setTimeout((val:any) => {

    }, 200);
  }

  Annuler(){
    this.dataSousMenuModel = [];
    this.Auto = {};
    this.mouvementproduit = new mouvementproduit();
  }

  //Fonction de verification de champs
     VerificationChampsObligatoire(tableau_champs_obligatoires:any){
         var verification_formulaire = true;
         for (var i = 0; i < tableau_champs_obligatoires.length; i++) {
             if($(tableau_champs_obligatoires[i]).val() == "" || $(tableau_champs_obligatoires[i]).val() == null || $(tableau_champs_obligatoires[i]).val() == 0 || $(tableau_champs_obligatoires[i]).val() == "? string: ?"){
                     $(tableau_champs_obligatoires[i]).css("border","1px solid red");
                     verification_formulaire = false;
                     document.getElementById(tableau_champs_obligatoires[i].replace("#",""))?.scrollIntoView({behavior: "smooth"});
                     break;
             }else{
                 $(tableau_champs_obligatoires[i]).css("border","");
             }
         }

         return verification_formulaire;
     }

  AutoCompleteDossier(elt:any){
     this.apiUrl = "SERVER/GestionStock/mouvement/AutocompleteDossier";
       var data = {numProduit:elt};
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
     ).subscribe((value:any) => {
         this.liste_Auto_Vehicule = value.auto_dossier;
       }, (erreur:any) => {
           console.log(erreur.status);
       })
  }

   AutoCompleteDossier2(elt:any){
     this.apiUrl = "SERVER/GestionStock/mouvement/AutocompleteDossierNomprod";
       var data = {NomProd:elt};
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
     ).subscribe((value:any) => {
         this.liste_Auto_Vehicule = value.auto_dossier;
       }, (erreur:any) => {
           console.log(erreur.status);
       })
  }

  SelectionAutocompleteDossier2(data:any): void{
    this.mouvementproduit.idProduit = data.idProduit;
    this.Auto.numProduit = data.numProduit;
    this.Auto.NomProd = data.NomProd;
  }


   SelectionAutocompleteDossier(data:any): void{
    this.mouvementproduit.idProduit = data.idProduit;
    this.Auto.numProduit = data.numProduit;
    this.Auto.NomProd = data.NomProd;
  }

  Listefournisseur():void{
      var url = "SERVER/g/api/liste";
      this.CRUDService.CRUD("fournisseur", this.Auto, url).subscribe((value:any) => {
          this.listefournisseur = value.data;
         }, (erreur:any) => {

        });
    }

 Listecategorieproduit():void{
      var url = "SERVER/g/api/liste";
      this.CRUDService.CRUD("categorieproduit", this.Auto, url).subscribe((value:any) => {
          this.listecategorieproduit = value.data;
         }, (erreur:any) => {

        });
    }


  Enregistrer(event:any){
     if(this.VerificationChampsObligatoire(["#idProduit","#entree","#qte"]) == false)
        return;
        event.target.disabled = true;
     if(this.mouvementproduit.idMvtProduit == 0){
        this.apiUrl = "SERVER/GestionStock/mouvement/enregistrer";
     }else{
        this.apiUrl = "SERVER/GestionStock/mouvement/modifier";
        this.restore(this.QteRestore, this.mouvementproduit.idProduit, this.mouvementproduit.sens);
     }

      var infoUser = this.AuthService.getInfoUtilisateur();
      this.mouvementproduit.idUser = infoUser.id;
      this.mouvementproduit.sens = "entree";
      var data = this.mouvementproduit;
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
          retryWhen(error =>
            error.pipe(
              scan((acc:any, error:any) => {
                if (acc > 2) throw error;
                return acc + 1;
              }, 1),
              delay(1000)
            )
          )
      ).subscribe((value:any) => {
        if(value.status == 1){
          event.target.disabled = false;
          this.listeMouvement();
            this.Annuler();
            this.Tabulation("profile");
        }else{
          event.target.disabled = false;
            this.ModaleTitle = "ERREUR !";
            this.ModaleText  = "Quantité  Indisponible !";
            this.ModalFoot = "FootErr";
            $(".ouverturefiche").trigger("click");
        }

      }, (erreur:any) => {
        event.target.disabled = false;
          console.log(erreur.status);
      })
  }

  listeMouvement():void{
    var apiUrl = "SERVER/GestionStock/mouvement/listeEntree";
    var d = {};
    this.transfertDataService.EnvoiData(d, apiUrl).pipe(
          retryWhen(error =>
            error.pipe(
              scan((acc:any, error:any) => {
                if (acc > 2) throw error;
                return acc + 1;
              }, 1),
              delay(1000)
            )
          )
      ).subscribe((value:any) => {
         this.liste = value.data
         //this.Calcule();
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }

  AfficherDonnee(id:any){
    this.apiUrl = "SERVER/GestionStock/mouvement/Afficher";
       var data = {"id":id};
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
     ).subscribe((value:any) => {
         this.mouvementproduit = value.data[0];
         this.QteRestore = value.data[0].qte;
         this.Auto.numProduit =  value.data[0].numProduit;
         this.Auto.NomProd =  value.data[0].NomProd;

         this.Tabulation('saisir');
       }, (erreur:any) => {
           console.log(erreur.status);
       })
  }

   restore(QteRestore:any, idProduit:any, sens:any){
    this.apiUrl = "SERVER/GestionStock/mouvement/restore";
       var data = {"QteRestore":QteRestore, "idProduit":idProduit, "sens":sens};
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
     ).subscribe((value:any) => {
          this.apiUrl = "SERVER/GestionStock/mouvement/modifier";
          var infoUser = this.AuthService.getInfoUtilisateur();
          this.mouvementproduit.idUser = infoUser.id;
          var data = this.mouvementproduit;
          this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
              retryWhen(error =>
                error.pipe(
                  scan((acc:any, error:any) => {
                    if (acc > 2) throw error;
                    return acc + 1;
                  }, 1),
                  delay(1000)
                )
              )
          ).subscribe((value:any) => {
            if(value.status == 1){
                this.listeMouvement();
                this.Annuler();
                this.Tabulation("profile");
            }else{
                this.ModaleTitle = "ERREUR !";
                this.ModaleText  = "Quantité  Indisponible !";
                this.ModalFoot = "FootErr";
                $(".ouverturefiche").trigger("click");
            }

          }, (erreur:any) => {
              console.log(erreur.status);
          })
         }, (erreur:any) => {
             console.log(erreur.status);
         })
  }


  SupprimerDossierPopup(id:any): void{
    $(".suppdossier").trigger("click");
    this.suppiddossier.id = id;
  }

  supprimer(): void{
    this.apiUrl = "SERVER/GestionStock/mouvement/delete";
       var data = {"id":this.suppiddossier.id};
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
     ).subscribe((value:any) => {
          this.listeMouvement();
       }, (erreur:any) => {
           console.log(erreur.status);
       })
  }

  Recherche(){
    if(this.recherche.numProduit == "" &&  this.recherche.date1 == "" && this.recherche.date2 == "" && this.recherche.NomProd == "" && this.recherche.idfournisseur == ""){
            this.listeMouvement();
        }
     this.apiUrl = "SERVER/GestionStock/mouvement/Recherche";
       var data = this.recherche;
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
     ).subscribe((value:any) => {
          this.liste = value.data
          //this.Calcule();
       }, (erreur:any) => {
           console.log(erreur.status);
       })
  }

  ConfirmationImpressionFormulaire(){
   this.lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/ReportingStockPetitesFournitures/Gestion_entree/pdf_article.php?numProduit="+this.recherche.numProduit+"&idfournisseur="+this.recherche.idfournisseur+"&NomProd="+this.recherche.NomProd+"&date1="+this.recherche.date1+"&date2="+this.recherche.date2;
   $("#printRapport").remove();
   $("body").append('<div id="printRapport">\
                       <div class="col-md-12 form-group" style="display:none;">\
                         <button class="btn btn-primary btn-sm bs-modal-frame-sm-btn" data-backdrop="static" data-toggle="modal" data-target=".bs-modal-frame-sm"></button>\
                       </div>\
                       <div class="modal fade bs-modal-frame-sm" tabindex="-1" role="dialog" aria-hidden="true">\
                         <div class="modal-dialog" role="document">\
                             <div class="modal-content">\
                                 <div class="row container_modal" style="width:100%;">\
                                 <div class="modal-header" style="margin-bottom:0px;">\
                                   <h6 class="modal-title" style="font-weight: bold;">REPORTING ENTREE PRODUIT</h6>\
                                   <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>\
                                   </button>\
                                 </div>\
                                 <div class="row col-md-12" style="width:100%;height: calc(100vh - 90px);padding-left: 0px; padding-right: 0px; margin-left: 0px; margin-right: 0px;">\
                                   <div class="col-md-12 waiting">\
                                     <div class="snippet" data-title=".dot-pulse">\
                                       <div class="stage">\
                                         <div class="dot-pulse"></div>\
                                       </div>\
                                     </div>\
                                   </div>\
                                   <iframe class="rapportframe" name="rapportframe" style="width:100%;"></iframe>\
                                 </div>\
                             </div>\
                           </div>\
                         </div>\
                       </div>\
                   </div>');
   $(".bs-modal-frame-sm-btn").trigger("click");
   window.open(this.lien_fiche_ExpST , "rapportframe")?.focus();
   $(".rapportframe").css("display", "none");
   $('.rapportframe').on('load', function(){
       $(".waiting").css("display", "none");
       $(".rapportframe").css("display", "initial");
   });
 }

 ExporterExcel(){
    var lien = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/FicheExcel/reporting.php?numProduit="+this.recherche.numProduit+"&idfournisseur="+this.recherche.idfournisseur+"&NomProd="+this.recherche.NomProd+"&date1="+this.recherche.date1+"&date2="+this.recherche.date2+"&action=Entreearticle";
    window.open(lien , '_blank')?.focus();
}

}
