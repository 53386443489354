<div class="x_panel x_panel_close">
        <div class="x_content">
            <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;"><span ><i  class="fa fa-arrow-left"></i></span><div  class="ml-auto text-right"><span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span><i  class="fa fa-chevron-right"></i><span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Gestion Paramètres - Imprimer
              </span>
             </div>
            </div>
            <br>

             <ul class="nav nav-tabs bar_tabs" id="myTab" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link active" id="saisir-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='saisir'}" (click)="search('saisir')"
                           role="tab" aria-controls="saisir" aria-selected="true">Saisir</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="profile-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='profile'}" (click)="search('profile')"
                            role="tab" aria-controls="profile" aria-selected="false">Liste</a>
                      </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div [ngClass]="{ 'show':activeTab==='saisir', 'active':activeTab==='saisir'}"
                    class="tab-pane fade active show" id="saisir" role="tabpanel" aria-labelledby="saisir-tab">
                         <br>
                        <div class="col-md-12">
                                <div class="row">
                                    <div class="form-group col-md-6">
                                            <label>Pied de Page </label>
                                            <textarea id="exampleFormControlSelect1" [(ngModel)] = "imprime.pied_page" class="form-control form-control-sm"></textarea>
                                     </div>
                                 </div>
                             </div>
                             <br>
                        <div class="col-md-12 text-center">
                          <div class="card-body">
                               <span>Télécharger un logo</span>
                               <br><br>
                               <div class="col-md-12">
                                <div class="download-card" style="text-align: left!important;">
                                  <div class="col-md-12">
                                    <div class="row">
                                      <div class="inline"><br><br><img style ="width: 35px;" src="./assets/img/cloud.png"></div>
                                      <div class="inline1"><br><br><p><span>&nbsp;Cliquez sur le bouton pour ajouter des fichers</span><br>&nbsp; <button type="button" class="btn btn-primary btn-sm btn-sm-perso" (click)="fileInputBlock.click()">PARCOURIR LES FICHIERS </button></p></div>
                                    </div>
                                  </div>
                                </div>
                               </div>
                               <div class="row blockphotosZone" *ngIf="photos_previsualisation.length > 0">
                                <br><br>
                                <div class="col-md-3 photosZone" *ngFor="let x of photos_previsualisation" [ngClass]=" x.iddocument == '' ? 'uploadfile' : ''">
                                    <span>
                                      <span *ngIf="x.iddocument == ''" class="fichierToUpload">FICHIER NON ENREGISTRE</span>
                                    </span>
                                    <img [src]="x.src" id="file_{{x.iddocument}}">
                                    <div class="text-center text-center pt-2 pb-2 action-band">
                                      <button class="btn btn-sm btn-secondary" (click)="apercu(x.iddocument)">
                                            <i class="fas fa-search"></i>
                                        </button>&nbsp;&nbsp;
                                    </div>
                                </div>
                            </div>
                            </div>

                            <input type="file" name="file" id="file" [hidden]="true" (change)="onFileChanged($event)" multiple="multiple" #fileInputBlock>
                        </div>
                              <br>
                            <div  class="col-sm-12 text-right mobile-buttons">
                                <button  class="btn btn-primary btn-sm btn-enregistrer" (click) = "Enregistrer(imprime)">
                                    <font style="vertical-align: inherit;">
                                        <font style="vertical-align: inherit;">Enregistrer</font>
                                    </font>
                                </button>&nbsp;&nbsp;
                                <button  class="btn btn-secondary btn-sm btn-enregistrer" (click) = "Annuler()">
                                    <font style="vertical-align: inherit;">
                                        <font style="vertical-align: inherit;">Annuler</font>
                                    </font>
                                </button>
                            </div>
                        </div>
                 <div class="tab-pane fade" id="profile" role="tabpanel"  aria-labelledby="profile-tab" [ngClass]="{'show':activeTab==='profile', 'active':activeTab==='profile'}">
                      <br>
                      <div class="">
                          <div class="recherche_liste">
                                <div class="form-group col-xs-12 col-md-4">
                                  <div _ngcontent-cqh-c52="" class="form-group">
                                        <label _ngcontent-pfx-c52="">
                                            <font style="vertical-align: inherit;">
                                                <font style="vertical-align: inherit;">Rechercher par</font>
                                            </font>
                                        </label>
                                        <input name="recherche" type="text" placeholder="Pied de Page" class="form-control form-control-sm" [(ngModel)] = "recherche.pied_page" (ngModelChange)="Recherche()">
                                  </div>
                                </div>
                          </div>
                      </div>
                    <br>
                      <div class="Table_Longer">
                          <table class="table_bloc_liste">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Pied de Page</th>
                            <th>Créer Par</th>
                            <th>Créer Le</th>
                            <th>Modifier Par</th>
                            <th>Modifier Le</th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let i of liste">
                            <td>{{i.id}}</td>
                            <td>{{i.pied_page}}</td>
                            <td>{{i.nom}}</td>
                            <td>{{i.date}}</td>
                            <td>{{i.modifierpar}}</td>
                            <td>{{i.datemodification}}</td>
                            <td class="text-center">
                                <button  class="btn btn-warning btn-sm" (click)="information(i.id)">
                                   <i class="fas fa-pencil-alt"></i>
                                </button>
                            </td>
                            <td class="text-center">
                                <button  class="btn btn-danger btn-sm" (click)="SupprimerPopup(i.id)">
                                    <i class="far fa-trash-alt"></i>
                                </button>
                            </td>
                      </tr>


                        </tbody>
                      </table>
                      </div>

                 </div>

                </div>

        </div>
</div>
<button type="button" class="btn btn-primary  btn-sm suppliste" style="display:none;" data-backdrop="static" data-toggle="modal" data-target=".bs-Form-sm"></button>
<div class="modal fade bs-Form-sm" tabindex="-1" role="dialog" aria-hidden="true">
 <div class="modal-dialog modal-sm" role="document">
  <div class="modal-content">
      <div class="row container_modal">
          <div class="modal-header">
            <h6 class="modal-title" style="font-weight: bold;">SUPPRESSION</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>
            </button>
          </div>
          <div>
             <div class="modal-content-dl">
              <p>Souhaitez-vous supprimer ce enregistrement ?</p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" data-dismiss="modal"  class="btn btn-primary btn-sm" (click) = "supprimer(supp_id)">Oui</button>
            <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Non</button>
          </div>
      </div>
    </div>
  </div>
</div>
