import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { ConfigurationService } from '../../../config/configuration.service';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';

@Component({
  selector: 'app-exploitation-recapitulatif',
  templateUrl: './exploitation-recapitulatif.component.html',
  styleUrls: ['./exploitation-recapitulatif.component.css']
})
export class ExploitationRecapitulatifComponent implements OnInit {
  activeTab:String="saisir";
  apiUrl:any="";
  listesuividechargement:any = [];
  info_dossier:any = [];
  recherche:any = {};
  dossier:any = {};
  listdoss:any = [];
  TotalPoidPrevu:any;
  TotalCoutExp:any;
TotalMontantNavire:any;
TotalMontant:any;
  TotalPoidDecharge:any;
  Poids_restant:any;

  constructor(private transfertDataService : transfertDataService,  private ConfigurationService : ConfigurationService) { }

  ngOnInit(): void {

  }
  Tabulation(activeTab:any): void{
    this.activeTab=activeTab;
  }

  ListeAutoCompleteDossier(numerodossier:any){
   this.apiUrl = "SERVER/exploitation/recapitulatif_dechargement/recherche_dossier";
      var data = {numeroDossier:numerodossier};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listdoss= value.listdoss;
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }

  SelectionAutocomplete(data:any): void{
     this.recherche.numeroDossier = data.numeroDossier;
     this.recherche.idGestionNavire = data.idGestionNavire;
     this.Recherche(this.recherche);
  }

  Recherche(data:any): void{
      this.apiUrl = "SERVER/exploitation/recapitulatif_dechargement/recherche";
      var data = data;
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
      this.TotalCoutExp = value.total_cout_exploitation;
      this.TotalMontantNavire = value.total_reglementnavire;
      this.TotalMontant = this.TotalMontantNavire - this.TotalCoutExp; 
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }

  reInitSearchForm(): void{
    this.listdoss = [];
  }

  reInitSearchBlurForm(): void{
    setTimeout((val:any) => {
        this.listdoss = [];
    }, 500);
  }


}
