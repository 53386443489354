export class PRODUIT {

  public idProduit: number = 0;
  public idClient: number = 0;
  public NomProduit: string = "";
  public NomSpecificProduit: string = "";
  public poidsProduit: string = "";
  public user: number = 0;
  public date: string = "";
  public modifierpar: number = 0;
  public datemodification: string = "";
  constructor() {

  }

}
