import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { Fonction } from './fonction';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { AuthService } from '../../../services/connexion/auth.service';

@Component({
  selector: 'app-fonction',
  templateUrl: './fonction.component.html',
  styleUrls: ['./fonction.component.css']
})
export class FonctionComponent implements OnInit {
  apiUrl:String = "";
  listefonction_data:any = [];
  fonction:any = {};
  recherche:any = {};
  activeTab:String="saisir";

  FonctionModel:Fonction = new Fonction();
 constructor(private AuthService : AuthService,private transfertDataService : transfertDataService) { }

 ngOnInit(): void {
   this.Liste_des_Fonctions();
 }


   search(activeTab:any): void{
    this.activeTab=activeTab;
  }

  Annuler():void{
     this.FonctionModel = new Fonction();
     this.recherche.libelle = "";
    }

  enregistrer(data:any): void{

    if(this.FonctionModel.idFonction == 0)
      this.apiUrl = "SERVER/groupeware/fonction/enregistrer";
    else
      this.apiUrl = "SERVER/groupeware/fonction/modifier";
      var infoUser = this.AuthService.getInfoUtilisateur();
      this.FonctionModel.idUser = infoUser.id;
      var d = {"fonction": this.FonctionModel};
      this.transfertDataService.EnvoiData(d, this.apiUrl).pipe(
          retryWhen(error =>
            error.pipe(
              scan((acc:any, error:any) => {
                if (acc > 2) throw error;
                return acc + 1;
              }, 1),
              delay(1000)
            )
          )
      ).subscribe((value:any) => {
        this.Liste_des_Fonctions();
        this.Annuler();
        this.Tabulation("profile");
      }, (erreur:any) => {
          console.log(erreur.status);
      })

  }

  supprimer(idFonction:any):void{
    this.apiUrl = "SERVER/groupeware/fonction/supprimer";
    var data = {id:idFonction};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
      retryWhen(error =>
        error.pipe(
          scan((acc:any, error:any) => {
            if (acc > 2) throw error;
            return acc + 1;
          }, 1),
          delay(1000)
        )
      )
  ).subscribe((value:any) => {
      this.Liste_des_Fonctions();
    }, (erreur:any) => {
        console.log(erreur.status);
    })

  }


  Liste_des_Fonctions(): void{

    this.apiUrl = "SERVER/groupeware/fonction/listefonction";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
      this.listefonction_data = response.liste_fonction;
    }, (erreur:any) => {
        console.log(erreur.status);
    })

  }

  information(idFonction:any):void{


        this.apiUrl = "SERVER/groupeware/fonction/information";
        var data = {idFonction:idFonction};
        this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
          retryWhen(error =>
            error.pipe(
              scan((acc:any, error:any) => {
                if (acc > 2) throw error;
                return acc + 1;
              }, 1),
              delay(1000)
            )
          )
      ).subscribe((response:any) => {
            this.FonctionModel = response.fonction[0];
            this.Tabulation("saisir");
        }, (erreur:any) => {
          console.log(erreur.status);
      })

  }

    Tabulation(activeTab:any): void{
      this.activeTab=activeTab;
    }

  rechercheFonction(data:any): void{

    if(this.recherche.libelle == "")
    this.Liste_des_Fonctions();
    else{
      this.apiUrl = "SERVER/groupeware/fonction/recherche";
      var data = data;
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listefonction_data= value.liste_fonction;
      }, (erreur:any) => {
          console.log(erreur.status);
      })
    }

}



  }
