export class CLIENT {

  public idClient: number = 0;
  public nom_societe: string = "";
  public idMagasin: number = 0;
  public responsable: string = "";
  public telephone: string = "";
  public adresse: string = "";
  public mail: string = "";
  public numero_compte: string = "";
  public user: number = 0;
  public date: string = "";
  public modifierpar: number = 0;
  public datemodification: string = "";
  constructor() {

  }

}
