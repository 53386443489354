import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { AuthService } from '../../services/connexion/auth.service';
import { transfertDataService } from '../../services/transfertData/transfertData.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  title = 'myapp';
  resize : boolean = false;
  utilisateur:any = {};
  showFiller1 : boolean = false;
  liste:boolean = false;
  GrupWare:boolean = false;
  config:boolean = false;
  OpenClosemenu:any = [];
  menuData:any = [];
  apiUrl:string = "";
  /*menuData: any = [
                     {url:"/recherche", nom_menu:"Recherche", icone:"fas fa-search", sousmenu:[]},
                     {url:"./", nom_menu:"Gestion Navires", icone:"fas fa-ship", sousmenu:[
                         {url:"/creerdossiernavire", nom_sous_menu:"Créer Dossier Navire", icone:"fas fa-dot-circle"},
                         {url:"/mouvementsnavire", nom_sous_menu:"Mouvements Navires", icone:"fas fa-dot-circle"},
                     ]},
                     {url:"./", nom_menu:"Gestion Déchargements", icone:"fas fa-truck", sousmenu:[
                         {url:"/saisirdechagement", nom_sous_menu:"Saisir Déchargement", icone:"fas fa-dot-circle"},
                         {url:"/etatdechargement", nom_sous_menu:"Etat Déchargement", icone:"fas fa-dot-circle"},
                         {url:"/etatdifferentiel", nom_sous_menu:"Etat Différentiel", icone:"fas fa-dot-circle"},
                     ]},
                     {url:"./", nom_menu:"Gestion des Magasins", icone:"fas fa-warehouse", sousmenu:[
                         {url:"/stockInitialMagasinGestionMagasin", nom_sous_menu:"Stock Initial Magasins", icone:"fas fa-dot-circle"},
                         {url:"/entreesMagasinGestionMagasin", nom_sous_menu:"Entrées Magasins", icone:"fas fa-dot-circle"},
                         {url:"/sortiesMagasinGestionMagasin", nom_sous_menu:"Sorties Magasins", icone:"fas fa-dot-circle"},
                         {url:"/etatStockMagasinGestionMagasin", nom_sous_menu:"Etat Stock Magasins", icone:"fas fa-dot-circle"},
                         {url:"/transfertMagasinGestionMagasin", nom_sous_menu:"Transfert Magasins", icone:"fas fa-dot-circle"},
                     ]},
                     {url:"./", nom_menu:"Stock Magasins", icone:"fas fa-boxes", sousmenu:[
                         {url:"/sortisMagasinStockMagasin", nom_sous_menu:"Sortis Magasins", icone:"fas fa-dot-circle"},
                         {url:"/etatStockMagasinStockMagasin", nom_sous_menu:"Etat Stock Magasins", icone:"fas fa-dot-circle"},
                     ]},
                     {url:"./", nom_menu:"Exploitation", icone:"fas fa-clipboard-check", sousmenu:[
                         {url:"/saisirBudgetNavireExploitation", nom_sous_menu:"Saisir Budget Navire", icone:"fas fa-dot-circle"},
                         {url:"/saisirChargesNavireExploitation", nom_sous_menu:"Saisir Charges Navire", icone:"fas fa-dot-circle"},
                         {url:"/tableauBordExploitation", nom_sous_menu:"Tableau de Bord", icone:"fas fa-dot-circle"},
                         {url:"/recapitulatifExploitation", nom_sous_menu:"Récapitulatif", icone:"fas fa-dot-circle"},
                     ]},
                     {url:"/gestionClients", nom_menu:"Gestion Clients", icone:"fas fa-user-check", sousmenu:[]},
                     {url:"/gestionProduitsClients", nom_menu:"Gestion Produits Clients", icone:"fab fa-product-hunt", sousmenu:[]},
                     {url:"./", nom_menu:"Reporting", icone:"fas fa-chart-bar", sousmenu:[
                         {url:"/etatStockMagasinReporting", nom_sous_menu:"Etat du Stock Magasin", icone:"fas fa-dot-circle"},
                         {url:"/etatStockClientReporting", nom_sous_menu:"Etat Stock Client", icone:"fas fa-dot-circle"},
                         {url:"/etatCoutsManutentionReporting", nom_sous_menu:"Etat des Coûts de Manutention", icone:"fas fa-dot-circle"},
                         {url:"/listeDossiersReporting", nom_sous_menu:"Liste des Dossiers", icone:"fas fa-dot-circle"},
                         {url:"/listeDechargementsReporting", nom_sous_menu:"Liste des Déchargements", icone:"fas fa-dot-circle"},
                         {url:"/listeClientsReporting", nom_sous_menu:"Liste des Clients", icone:"fas fa-dot-circle"},
                         {url:"/listeProduitsClientsReporting", nom_sous_menu:"Liste des Produits Clients", icone:"fas fa-dot-circle"},
                     ]},
                     {url:"./", nom_menu:"Gestion Paramètres", icone:"fas fa-cogs", sousmenu:[
                         {url:"/navire", nom_sous_menu:"Navires", icone:"fas fa-dot-circle"},
                         {url:"/detailsproduit", nom_sous_menu:"Détails Produits", icone:"fas fa-dot-circle"},
                         {url:"/provenance", nom_sous_menu:"Provenance", icone:"fas fa-dot-circle"},
                         {url:"/compagnie", nom_sous_menu:"Compagnie", icone:"fas fa-dot-circle"},
                         {url:"/poids", nom_sous_menu:"Poids", icone:"fas fa-dot-circle"},
                         {url:"/positionnavire", nom_sous_menu:"Position Navire", icone:"fas fa-dot-circle"},
                         {url:"/quai", nom_sous_menu:"Quai", icone:"fas fa-dot-circle"},
                         {url:"/acconier", nom_sous_menu:"Acconier", icone:"fas fa-dot-circle"},
                         {url:"/elementschargesexploitation", nom_sous_menu:"Eléments Charges Exploitation", icone:"fas fa-dot-circle"},
                     ]},
                     {id_menu : "./", url:"./", nom_menu:"Groupeware", icone:"fas fa-users", sousmenu:[
                         {url:"/CreerMenu", nom_sous_menu:"Créer Ménu", icone:"fas fa-dot-circle"},
                         {url:"/creergroupe", nom_sous_menu:"Créer Groupe", icone:"fas fa-dot-circle"},
                         {url:"/creerfonction", nom_sous_menu:"Créer Fonction", icone:"fas fa-dot-circle"},
                         {url:"/creerutilisateur", nom_sous_menu:"Créer Utilisateur", icone:"fas fa-dot-circle"},
                         {url:"/utilisateuretgroupe", nom_sous_menu:"Utilisateur & Groupe", icone:"fas fa-dot-circle"},
                         {url:"/droitdacces", nom_sous_menu:"Droit d'Acces", icone:"fas fa-dot-circle"},
                         {url:"/historiqueutilisateur", nom_sous_menu:"Historique Utilisateur", icone:"fas fa-dot-circle"},

                     ]},
                      {id_menu : "3", url:"./", nom_menu:"Configuration", icone:"fa-cog fas", sousmenu:[
                         {url:"/GenererVue", nom_sous_menu:"Générer Vue", icone:"fas fa-file"},
                     ]}
                    ]*/
@Output() resize_menu_change = new EventEmitter();
@Output() show_Filler = new EventEmitter();
@Input() size : boolean = false;
  constructor(private AuthService : AuthService, private transfertDataService : transfertDataService) { }

  ngOnInit(): void {
    
    if(localStorage.getItem("_tkex")){
      this.utilisateur = this.AuthService.getInfoUtilisateur();
      this.MenuUtilisateurConnecte();
    }else{
          this.utilisateur.nom = "";
    }

  }

  EtatNavigation(): void{
     /*var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if (isMobile) {
        return 1;
      }else{
        return 0;
      }*/
  }

  toggleResize() : void {
    this.resize = !this.resize;
    this.resize_menu_change.emit(this.resize);
    this.liste = false;
    this.GrupWare = false;
  }

  ClickMenu(idmenu:any, ArrayOpenClosemenu:any) : void{
      var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if (isMobile) {
        this.resize = !this.resize;
        this.resize_menu_change.emit(this.resize);
      }else{
        this.OpenClosemenu[idmenu] = !this.OpenClosemenu[idmenu];
      }
  }

  toggleResizeOpen(idmenu:any): void {
    this.resize = !this.resize;
    this.resize_menu_change.emit(this.resize);
    setTimeout(() => {
      this.OpenClosemenu[idmenu] = !this.OpenClosemenu[idmenu];
    },500)
  }

  MenuUtilisateurConnecte() : void{
     
      this.apiUrl = "SERVER/groupeware/menu/menuUtilisateur";
      var data = {"action":"menuUtilisateur", "uid":this.utilisateur.id};
      this.transfertDataService.EnvoiData(data, this.apiUrl).subscribe((value:any) => {
            this.menuData = value.data;
            for(var i = 0; i < this.menuData.length; i++){
              this.OpenClosemenu[this.menuData[i].id_menu] = false;
            }
            this.animateMenu();
            
      })
  }


  ShowFiller(){
    if (window.matchMedia('(max-width: 480px)').matches) {
      this.showFiller1 = true;
      this.show_Filler.emit(this.showFiller1);
    }
  }

  listes(){
    this.liste = !this.liste;
    this.GrupWare = false;
    this.animateMenu();
  }

   animateMenu() : void{
        var date = new Date();
        (function run(){
            if($('.list-submenu li').length > 0){
              $('.list-submenu li a').unbind( "click" );
              var openUpMenu = function () {
                 $('.list-submenu').find('li').removeClass('active active-sm');
                 $('.list-submenu').find('li ul').slideUp();
              }

              var setContentHeight = function () {
                  // reset height
                  $('.list-submenu').css('min-height', $(window).height()!);

                  var bodyHeight = $('body').outerHeight()!;
                  var footerHeight = $('body').hasClass('footer_fixed')!;
                  var leftColHeight = $('.list-submenu').eq(1).height()!;
                  var contentHeight = bodyHeight < leftColHeight ? leftColHeight : bodyHeight;

                  // normalize content
                  contentHeight = $('.list-submenu').height()! + 35;

                  $('.list-submenu').css('min-height', contentHeight);
              };

              $(".list-submenu li a").on("click", function(e){
                  e.stopPropagation();
                  //Remove all class active on li
                  $(".list-submenu .child_menu li").each(function(){
                      $(this).removeClass("active");
                  })

                  var $li = $(this).parent();

                  if ($li.is('.active')) {
                      $li.removeClass('active');
                      $('ul:first', $li).slideUp(function () {
                          setContentHeight();
                      });
                  } else {
                      // prevent closing menu if we are on child menu
                      if (!$li.parent().is('.child_menu')) {
                          openUpMenu();
                      }

                      $li.addClass('active');

                      $('ul:first', $li).slideDown(function () {
                          setContentHeight();
                      });
                  }
              })
            }else{
                setTimeout((ev:any) => {
                  run();
                }, 500)
                
            }
        })();
  }



}
