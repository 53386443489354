<div class="x_panel x_panel_close">
    <div class="x_content">
        <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;"><span ><i  class="fa fa-arrow-left"></i></span>
        	<div  class="ml-auto text-right"><span class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span>
        		<i  class="fa fa-chevron-right"></i>
        		<span class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Import Facturation Conteneur - Créer Facture</span>
         	</div>
        </div>
        <br>
        <div class="col-md-12">
            <div class="content-action">
              <ul class="nav nav-tabs bar_tabs" id="myTab" role="tablist">
                 <li class="nav-item">
                   <a class="nav-link active" id="saisir-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='saisir'}" (click)="search('saisir')"
                      role="tab" aria-controls="saisir" aria-selected="true">Créer Facture Conteneur</a>
                 </li>

              </ul>

                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade active show" id="saisir" role="tabpanel" aria-labelledby="saisir-tab"
                       [ngClass]="{ 'show':activeTab==='saisir', 'active':activeTab==='saisir'}">
                    <br>

                      <div class="x_panel_title x_panel_close">
                        <div class="x_title">
                          <h6>Creer Facture Conteneur</h6>
                          <ul class="nav navbar-right panel_toolbox">
                          </ul>
                          <div class="clearfix"></div>
                        </div>
                        <div class="x_content">
                          <div class="row">
                              <div class="form-group col-md-6">
                                  <label>Date Facture *</label>
                                  <input name="dateTraite" id="DateFacturee" type="date" placeholder="Date Traitée" class="form-control form-control-sm"
                                         [(ngModel)]="factureconteneur.DateFacture">
                              </div>



                              <div class="form-group col-md-6">
                                   <label for="">Armateur*</label>
                                   <select class="form-control form-control-sm" [(ngModel)]="factureconteneur.idAmateur" id="amateur1">

                                    <option *ngFor="let i of amateur" value="{{i.idAmateur}}">{{i.nomAmateur}}</option>
                                   </select>



                              </div>
                              <div class="form-group col-md-6">
                                   <label for="">Periode Facture</label>
                                   <select class="form-control form-control-sm" [(ngModel)]="factureconteneur.periodefacture" id="amateur1">
                                     <option value=""></option>
                                     <option value="1er quinzaine ">1er quinzaine </option>
                                     <option value="2eme quinzaine">2eme quinzaine</option>
                                  </select>



                              </div>


                          </div>
                          <div class="x_panel_title x_panel_close">
                            <div class="x_title" style="background :#6c757d">
                              <h6>Liste Conteneur A Facturer</h6>
                              <ul class="nav navbar-right panel_toolbox">
                              </ul>
                              <div class="clearfix"></div>
                            </div>
                            <div class="x_content">
                              <div class="row">

                                  <div class=" row  col-md-12">
                                    <div class="col-sm-4">
                                        <div class="row">


                                              <div class="form-group col-xs-12 col-md-12">
                                                <label for="">N° Conteneur</label>
                                                  <input name="recherche" type="text" placeholder="N° Conteneur" class="form-control form-control-sm"
                                                         [(ngModel)]="recherche.numConteneur" (ngModelChange)="recherch(recherche)">
                                              </div>

                                        </div>

                                    </div>
                                    <div class="col-sm-4">
                                      <div class="row">
                                        <br>
                                        <div class="form-check col-xs-12 col-md-2" style="margin-left:13px;">
                                            <br>
                                             <input type="checkbox" class="form-check-input" id="" style="float: left;" [(ngModel)]="recherche.tous"
                                             (ngModelChange)="TousAfficher()">
                                             <label for="flexCheckChecked1">Tous</label>
                                         </div>
                                         <div class="form-check col-xs-12 col-md-2"style="margin-left:30px;">
                                             <br>
                                             <input type="checkbox" class="form-check-input" id="" style="float: left;" [(ngModel)]="recherche.Aucun"
                                             (ngModelChange)="RienAfficher()">
                                             <label for="flexCheckChecked1">Aucun</label>
                                         </div>
                                      </div>
                                    </div>

                                  </div>
                                  <div class="Table_Longer mx-3">
                                      <table class="table_bloc_liste">
                                          <thead>
                                              <tr>
                                                  <th style="width: 50px;">Choix</th>
                                                  <th>N° Conteneur</th>
                                                  <th>Client</th>
                                                  <th>N°BL</th>
                                                  <th>Taille</th>
                                                  <th>Type</th>
                                                  <th>Numéro Déclaration</th>
                                                  <th>Transitoire</th>
                                                  <th>Lieu Livraison</th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                            <tr *ngFor="let i of listeConteneurs; let x = index">
                                              <td class="">
                                                  &nbsp;<input type="checkbox" class="form-check-input" id="" style="margin-left: 10px;" [(ngModel)]="i.choix" (ngModelChange)="Ajouter(i, x)">
                                              </td>
                                              <td>{{i.numConteneur}}</td>
                                              <td>{{i.client}}</td>
                                              <td>{{i.numBL}}</td>
                                              <td>{{i.taille}}</td>
                                              <td>{{i.type}}</td>
                                              <td>{{i.numDeclaration}}</td>
                                              <td>{{i.transitaire}}</td>
                                              <td>{{i.lieuLivraison}}</td>
                                            </tr>
                                          </tbody>
                                      </table>
                                  </div>
                              </div>
                            </div>
                          </div>
                          <!--<div class="x_panel_title x_panel_close">
                            <div class="x_title">
                              <h6>Liste Conteneur Facturer</h6>
                              <ul class="nav navbar-right panel_toolbox">
                              </ul>
                              <div class="clearfix"></div>
                            </div>
                            <div class="x_content">
                              <div class="row">
                                  <div class="col-sm-4">
                                      <div class="row">
                                         <div class="form-check col-xs-12 col-md-2" style="margin-left:13px;">
                                              <input type="checkbox" class="form-check-input" id="" style="float: left;">
                                              <label for="flexCheckChecked1">Tous</label>
                                          </div>
                                          <div class="form-check col-xs-12 col-md-2">
                                              <input type="checkbox" class="form-check-input" id="" style="float: left;">
                                              <label for="flexCheckChecked1">Aucun</label>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="form-group col-xs-12 col-md-4">
                                  </div>
                                  <div class="form-group col-xs-12 col-md-4">
                                      <div class="btn-group" style="float:right;">
                                          <button type="button" class="btn btn-danger btn-md" (click)="Retirer()">
                                              Retirer
                                          </button>
                                      </div>
                                  </div>
                                  <div class="Table_Longer mx-3">
                                      <table class="table_bloc_liste">
                                          <thead>
                                              <tr>
                                                <th style="width: 50px;">Choix</th>
                                                <th>N° Conteneur</th>
                                                <th>Client</th>
                                                <th>N°BL</th>
                                                <th>Taille</th>
                                                <th>Type</th>
                                                <th>Numéro Déclaration</th>
                                                <th>Transitoire</th>
                                                <th>Lieu Livraison</th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                            <tr *ngFor="let y of listeConteneursSelect">
                                              <td class="">
                                                  &nbsp;<input type="checkbox" class="form-check-input" id="" style="margin-left: 10px;" [(ngModel)]="y.choix">
                                              </td>
                                              <td>{{y.numConteneur}}</td>
                                              <td>{{y.client}}</td>
                                              <td>{{y.numBL}}</td>
                                              <td>{{y.taille}}</td>
                                              <td>{{y.type}}</td>
                                              <td>{{y.numDeclaration}}</td>
                                              <td>{{y.transitoire}}</td>
                                              <td>{{y.lieuLivraison}}</td>
                                            </tr>
                                          </tbody>
                                      </table>
                                  </div>
                              </div>
                            </div>
                          </div>--->

                          <br>

                          <div class="col-sm-12 text-right mobile-buttons">
                              <button class="btn btn-primary btn-sm btn-enregistrer" (click)="Enregistrer()">
                                  <font style="vertical-align: inherit;">
                                      <font style="vertical-align: inherit;">Enregistrer</font>
                                  </font>
                              </button>&nbsp;&nbsp;
                              <button type="button" class="btn btn-primary  btn-sm ouverturefiche" style="display:none;" data-backdrop="static" data-toggle="modal" data-target=".bs-Form-sm"></button>&nbsp;&nbsp;

                              <button class="btn btn-secondary btn-sm btn-enregistrer" (click)="Annuler()">
                                  <font style="vertical-align: inherit;">
                                      <font style="vertical-align: inherit;">Annuler</font>
                                  </font>
                              </button>
                          </div>

                        </div>
                      </div>

                      <div class="modal fade bs-Form-sm" tabindex="-1" role="dialog" aria-hidden="true">
                                           <div class="modal-dialog modal-sm" role="document">
                                            <div class="modal-content">
                                                <div class="row container_modal">
                                                    <div class="modal-header">
                                                      <h6 class="modal-title" style="font-weight: bold;">{{ModaleTitle}}</h6>
                                                      <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>
                                                      </button>
                                                    </div>
                                                    <div>
                                                       <div class="modal-content-dl">
                                                        <p>{{ModaleText}}</p>
                                                      </div>
                                                    </div>
                                                    <div class="modal-footer" *ngIf = "ModalFoot == 'FootNoErr'">
                                                      <button type="button" data-dismiss="modal"  class="btn btn-primary btn-sm" (click) = "Fiche()">Imprimer</button>
                                                      <button type="button" class="btn btn-success btn-sm" data-dismiss="modal" (click) = "Fiche1()">Exporter</button>
                                                      <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" (click) = "search('profile')">Non</button>

                                                    </div>
                                                    <div class="modal-footer" *ngIf = "ModalFoot == 'FootErr'">
                                                      <button type="button" class="btn btn-primary btn-sm" data-dismiss="modal" (click) = "search('saisir')">Non</button>
                                                    </div>
                                                </div>
                                              </div>
                                            </div>
                        </div>



                  </div>


                </div>
            </div>
        </div>

    </div>
</div>
<button type="button" class="btn btn-primary  btn-sm suppliste" style="display:none;" data-backdrop="static" data-toggle="modal" data-target=".bs-Form-sm"></button>
<div class="modal fade bs-Form-sm" tabindex="-1" role="dialog" aria-hidden="true">
 <div class="modal-dialog modal-sm" role="document">
  <div class="modal-content">
      <div class="row container_modal">
          <div class="modal-header">
            <h6 class="modal-title" style="font-weight: bold;">SUPPRESSION</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>
            </button>
          </div>
          <div>
             <div class="modal-content-dl">
              <p>Souhaitez-vous supprimer ce enregistrement ?</p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" data-dismiss="modal"  class="btn btn-primary btn-sm" (click)="supprimer(supp_id)">Oui</button>
            <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Non</button>
          </div>
      </div>
    </div>
  </div>
</div>
