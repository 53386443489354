export class journaltransite {
  public idjournaltransite: number = 0;
  public date: string = "";
  public camion: string = "";
  public destination: string = "";
  public idContenuNavire: number =0;
  public nbresac: number = 0;
  public poids: number = 0;
  public cumulsacs: number = 0;
  public cumulpoids: number = 0;
  public soldesacs: number = 0;
  public soldepoids: number = 0;
  public blexpert: string = "";
  public blacc: string = "";
  public bltransite: string = "";
  public typetransport: string = "";
  public idGestionNavire: number = 0;
  public idProduit: number = 0;
  public creerPar: number = 0;
  public creerLe: string = "";
  public modifierPar: number = 0;
  public modifierLe: string = "";

  constructor() {
  }

}
