<div class="x_panel x_panel_close">
    <div class="x_content">
        <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;">
            <span><i  class="fa fa-arrow-left"></i></span>
            <div  class="ml-auto text-right">
                <span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span>
                      <i class="fa fa-chevron-right"></i>
                <span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Gestion Magasins - Récapitulatif Stock Clients
                </span>
         </div>
        </div>
        <br>
           <div class="col-md-12">
                        <div class="content-action">
                             <ul class="nav nav-tabs bar_tabs" id="myTab" role="tablist">
                                      <li class="nav-item">
                                        <a class="nav-link active" id="saisir-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='saisir'}" (click)="search('saisir')"
                                           role="tab" aria-controls="saisir" aria-selected="true">Récapitulatif Stock Clients</a>
                                      </li>
                                </ul>
                                <div class="tab-content" id="myTabContent">
                                    <div [ngClass]="{ 'show':activeTab==='saisir', 'active':activeTab==='saisir'}"
                                    class="tab-pane fade active show" id="saisir" role="tabpanel" aria-labelledby="saisir-tab">
                                        <div class="col-md-12 col-sm-12">
                                            <div class="x_content">
                                                <div class="row">
                                                    <div class="col-12 col-md-12 text-right" style="margin-bottom: 5px;">
                                                        <button class="btn btn-sm btn-danger" (click)="ReinitFilter()">
                                                            <i class="fa fa-eraser"></i>&nbsp;Rénitialiser la recherche
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="form-group col-xs-12 col-md-12 recherche_liste">
                                                        <div class="row">
                                                                <div class="form-group col-xs-12 col-md-3">
                                                                        <label for="Recherche_magasin">Magasin</label>
                                                                        <select class="form-control form-control-sm" name="recherche_magasin" id="recherche_magasin" [(ngModel)] = "recherche.idMagasin" (ngModelChange)="RechercheTabListe(recherche)">
                                                                            <option value=""></option>
                                                                            <option *ngFor = "let x of liste_magasins" value="{{x.idMagasin}}">{{x.NomMagasin}}</option>
                                                                        </select>
                                                                </div>
                                                                <div class="form-group col-xs-12 col-md-3">
                                                                <div class="persoautocomplete">
                                                                <label for="">N°Dossier</label>
                                                                <input [(ngModel)]="recherche.numeroDossier" type="text" name="recherchenumdossFormEntreeDisopRecap" id="recherchenumdossFormEntreeDisopRecap" placeholder="N°Dossier"  class="form-control form-control-sm" autocomplete="off" (focus)="reInitSearchForm()" (blur)="reInitSearchBlurForm()" disabled="disabled">
                                                                    <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="">
                                                                    </button>
                                                                    <div class="classPerso  dropdown-menu">
                                                                    <form class="px-0 py-3">
                                                                        <div class="col-md-12">
                                                                            <div class="row">
                                                                                <div class="col-md-12">
                                                                                    <input type="text" [(ngModel)]="recherche.numeroDossier1" class="form-control form-control-sm" placeholder="N°Dossier" name="FormSaisiEntreeStockDisopRecap" (ngModelChange)="ListeAutoCompleteDossierrech(recherche.numeroDossier1)" autocomplete="off">
                                                                                    <div>
                                                                                    <br>
                                                                                        <table class="table_bloc_liste tableReseach">
                                                                                            <thead class="theadfixed">
                                                                                                <th>N°Dossier</th>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                <tr class="SelectTable"  *ngFor="let x of listdoss" (click)="SelectionAutocompleterech(x)">
                                                                                                    <td>{{x.numeroDossier}}</td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                                <div class="form-group col-xs-12 col-md-3">
                                                                    <div class="persoautocomplete">
                                                                    <label for="">Client</label>
                                                                    <input [(ngModel)]="recherche.client" type="text" name="recherchenumdossFormEntreeDisopRecapC" id="recherchenumdossFormEntreeDisopRecapC" placeholder="Client"  class="form-control form-control-sm" autocomplete="off" (focus)="reInitSearchFormClient()" (blur)="reInitSearchBlurFormClient()" disabled="disabled">
                                                                        <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="">
                                                                        </button>
                                                                        <div class="classPerso  dropdown-menu">
                                                                        <form class="px-0 py-3">
                                                                            <div class="col-md-12">
                                                                                <div class="row">
                                                                                    <div class="col-md-12">
                                                                                        <input type="text" [(ngModel)]="recherche.client1" class="form-control form-control-sm" placeholder="Client" name="FormSaisiEntreeStockRechDisopRecap" (ngModelChange)="ListeAutoCompleteClientrech(recherche.client1)" autocomplete="off">
                                                                                        <div>
                                                                                        <br>
                                                                                            <table class="table_bloc_liste tableReseach"> 
                                                                                                <thead class="theadfixed">
                                                                                                    <th>Client</th>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    <tr class="SelectTable"  *ngFor="let x of liste_ClientsRech" (click)="SelectionAutocompleterechClient(x)">
                                                                                                        <td>{{x.nom_societe}}</td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group col-xs-12 col-md-3">
                                                                    <div class="persoautocomplete">
                                                                      <label for="">Produit</label>
                                                                     <input [(ngModel)]="recherche.nom_produit_magasin" type="text" name="recherchenom_produit_magasinFormEntreeRecaap" id="recherchenom_produit_magasinFormEntreeRecaap" placeholder="Produit"  class="form-control form-control-sm" autocomplete="off" (focus)="reInitSearchFormEntreeProduit()" (blur)="reInitSearchBlurFormEntreeProduit()" disabled="disabled">
                                                                       <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="">
                                                                       </button>
                                                                        <div class="classPerso  dropdown-menu">
                                                                          <form class="px-0 py-3">
                                                                              <div class="col-md-12">
                                                                                  <div class="row">
                                                                                      <div class="col-md-12">
                                                                                        <input type="text" [(ngModel)]="recherche.nom_produit_magasin1" class="form-control form-control-sm" placeholder="Produit" name="FormSaisiEntreeProduitStockRechRecaap" (ngModelChange)="ListeAutoCompleteProduitrech(recherche.nom_produit_magasin1)" autocomplete="off">
                                                                                        <div>
                                                                                          <br>
                                                                                            <table class="table_bloc_liste tableReseach"> 
                                                                                                <thead class="theadfixed">
                                                                                                    <th>Produit</th>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    <tr class="SelectTable"  *ngFor="let x of liste_ProduitsRech" (click)="SelectionAutocompleterechProduit(x)">
                                                                                                        <td>{{x.nom_produit_magasin}}</td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                      </div>
                                                                                  </div>
                                                                              </div>
                                                                          </form>
                                                                        </div>
                                                                      </div>
                                                            </div>
                                                                <div class="form-group col-xs-12 col-md-3">
                                                                    <label for="Recherche_magasin">Période du</label>
                                                                    <input type="date" id="Recherche_datedebut" [(ngModel)]="recherche.datedebut" class="form-control form-control-sm" placeholder="jj/mm/yyyy" (ngModelChange)="RechercheTabListe(recherche)" >
                                                                </div>
                                                                <div class="form-group col-xs-12 col-md-3">
                                                                        <label for="Recherche_magasin">Au</label>
                                                                        <input type="date" id="Recherche_datefin" [(ngModel)]="recherche.datefin" class="form-control form-control-sm" placeholder="jj/mm/yyyy" (ngModelChange)="RechercheTabListe(recherche)" >
                                                                </div>
                                                        </div>
                                                    </div>
                                                    <table class="table_bloc_liste">
                                                        <thead>
                                                          <tr>
                                                            <th>Date</th>
                                                            <th>Client</th>
                                                            <th>Poids (en tonne)</th>
                                                            <th>Quantité</th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let x of Liste_etat_stock">
                                                                <td>{{x.Date}}</td>
                                                                <td>{{x.nom_societe}}</td>
                                                                <td>{{x.poids}}</td>
                                                                <td>{{x.qte}}</td>
                                                            </tr>
                                                        </tbody>
                                                  </table>
                                                </div>
                                                <div  class="col-sm-12 text-right mobile-buttons" *ngIf="Liste_etat_stock.length > 0">
                                                    <br>
                                                    <button  class="btn btn-success btn-sm btn-enregistrer" (click)="EtatExcel()">
                                                      <font style="vertical-align: inherit;">
                                                          <font style="vertical-align: inherit;">Exporter</font>
                                                      </font>
                                                  </button>&nbsp;&nbsp;
                                                    <button _ngcontent-wyu-c52="" class="btn btn-secondary btn-sm btn-enregistrer" (click)="EtatPdf()">
                                                        <font style="vertical-align: inherit;">
                                                            <font style="vertical-align: inherit;">Imprimer</font>
                                                        </font>
                                                    </button>
                                                </div>
                                        </div>
                                    </div>

                                </div>
                          </div>
                    </div>

    </div>
</div>
