import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { pipe } from 'rxjs';
import { CONTENEURIMPORT } from '../../../classes/conteneurimport';
import { PRODUIT } from '../../../classes/produit';
import { AuthService } from '../../../services/connexion/auth.service';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { ConfigurationService } from '../../../config/configuration.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-saisir-recuperation',
  templateUrl: './saisir-recuperation.component.html',
  styleUrls: ['./saisir-recuperation.component.css']
})
export class SaisirRecuperationComponent implements OnInit {
  activePagination:number = 0;
  liste_pagination:any = [];
  modelAutocompleCamion:any = {};
  liste_Camions:any = [];
  apiUrl:String = "";
  recherche:any = {};
  ConteneurImportModel:any = {idChauffeur:0,shiftReception:'',idCamion:0,eir:'',observation:'',retourCreerPar:0,retourModifierPar:0};
  conteneur:any = {};
  activeTab:String="saisir";
  listeConteneurs:any = [];
  supp_id:any = "";
  iddocument:any = "";
  amateur:any = [];
  camion:any = [];
  chauffeur:any = [];
  photos_previsualisation:any = [];
  array_files:any = [];
  code_conteneur:any = "";

  constructor(private sanitizer:DomSanitizer,private transfertDataService : transfertDataService, private AuthService : AuthService,private ConfigurationService : ConfigurationService,private HttpClient : HttpClient) { }

  ngOnInit(): void {
    this.listeRetour();
    this.recherche.amateur = "";
    this.recherche.numero = "";
    this.recherche.date1 = "";
    this.recherche.date2 = "";
    this.listeAmateur();
    this.listeChauffeur();
    this.listeCamion();
  }

    Annuler():void{
     this.ConteneurImportModel = {};
     this.recherche.amateur = "";
     this.recherche.conteneur = "";
     this.recherche.date1 = "";
     this.recherche.date2 = "";
     this.modelAutocompleCamion = {};
     this.conteneur = {};
     this.array_files = [];
     this.photos_previsualisation = [];
  }

  reInitSearch(): void{
    this.liste_Camions = [];
  }

  reInitSearchBlur(): void{
    setTimeout((val:any) => {
        this.liste_Camions = [];
    }, 500);

  }

  search(activeTab:String){
     this.activeTab=activeTab
   };

    //Fonction de verification de champs
    VerificationChampsObligatoire(tableau_champs_obligatoires:any){
        var verification_formulaire = true;
        for (var i = 0; i < tableau_champs_obligatoires.length; i++) {
            if($(tableau_champs_obligatoires[i]).val() == "" || $(tableau_champs_obligatoires[i]).val() == null || $(tableau_champs_obligatoires[i]).val() == "? string: ?"){
                    $(tableau_champs_obligatoires[i]).css("border","1px solid red");
                    verification_formulaire = false;
                    document.getElementById(tableau_champs_obligatoires[i].replace("#",""))?.scrollIntoView({behavior: "smooth"});
                    break;
            }else{
                $(tableau_champs_obligatoires[i]).css("border","");
            }
        }

        return verification_formulaire;
    }

  ListeAutoCompleteCamion(immat:any){
   this.apiUrl = "SERVER/importretourconteneur/recuperation/autocompletcamion";
      var data = {camion:immat};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.liste_Camions= value.liste_camion;

      }, (erreur:any) => {

      })
  }

  SelectionAutocomplete(data:any): void{
    this.modelAutocompleCamion.numeroCamion = data.numConteneur;
    this.conteneur.taille = data.taille;
    this.conteneur.type = data.type;
    this.conteneur.dateLivraison = data.dateLivraison;
    this.conteneur.lieuLivraison = data.lieuLivraison;
    this.conteneur.nomAmateur = data.nomAmateur;
    this.conteneur.numBL = data.numBL;
    this.modelAutocompleCamion.nomClient = data.nom_societe;
    this.ConteneurImportModel.idConteneurImport = data.idConteneurImport;
    this.code_conteneur = data.idConteneurImport;

    this.reInitSearch();
  }

  enregistrer(event:any){
    var infoUser = this.AuthService.getInfoUtilisateur();
    if(this.VerificationChampsObligatoire(["#numeroCamion","#camions","#chauffeur"]) == false)
        return;

      console.log(this.ConteneurImportModel.retourCreerPar);

    if(this.ConteneurImportModel.retourCreerPar == 0 || this.ConteneurImportModel.retourCreerPar == undefined ){
      this.apiUrl = "SERVER/importretourconteneur/recuperation/enregistrer";
      this.ConteneurImportModel.retourCreerPar = infoUser.id;

    }else{
      this.apiUrl = "SERVER/importretourconteneur/recuperation/modifier";
      this.ConteneurImportModel.retourModifierPar = infoUser.id;
    }

    this.ConteneurImportModel.idCamion = parseInt(this.ConteneurImportModel.idCamion);
    this.ConteneurImportModel.idChauffeur = parseInt(this.ConteneurImportModel.idChauffeur);



    var d = {"retour": this.ConteneurImportModel};
    var mData = JSON.stringify(d);
    var formData = new FormData();

    formData.append('data', mData);
    formData.append('typedoc', '10');

    if (this.array_files.length > 0) {
      for (var i = 0; i < this.array_files.length; i++) {
          formData.append("file["+i+"]", this.array_files[i]);
      }
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data; charset=utf-8; boundary=' + Math.random().toString().substr(2));
    headers.append('Accept', 'application/json');



this.HttpClient.post<any>(this.ConfigurationService.configuration.hostname + this.apiUrl, formData, {headers}).subscribe((resp:any) => {
          event.target.disabled = false;
          this.listeRetour();
          this.Annuler();
          this.search("profile");
      })





  }


  listeAmateur(){
    this.apiUrl = "SERVER/importretourconteneur/recuperation/nomAmateur";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((response:any) => {
         this.amateur = response.liste;
       }, (erreur:any) => {
           console.log(erreur.status);
       })

  }

  listeChauffeur(){
    this.apiUrl = "SERVER/importretourconteneur/recuperation/nomChauffeur";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((response:any) => {
         this.chauffeur = response.liste;

       }, (erreur:any) => {
           console.log(erreur.status);
       })

  }

  listeCamion(){
    this.apiUrl = "SERVER/importretourconteneur/recuperation/camions";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((response:any) => {
         this.camion = response.liste;

       }, (erreur:any) => {
           console.log(erreur.status);
       })

  }

  listeRetour(): void{
    this.apiUrl = "SERVER/importretourconteneur/recuperation/liste";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((response:any) => {
         this.listeConteneurs = response.liste;
         this.Calcule();
       }, (erreur:any) => {
           console.log(erreur.status);
       })

  }


  information(id:any):void{
    this.apiUrl = "SERVER/importretourconteneur/recuperation/information";
    var data = {id:id};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
      retryWhen(error =>
        error.pipe(
              scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((response:any) => {
            this.modelAutocompleCamion.numeroCamion = response.conteneur[0].numConteneur;
            this.conteneur.taille = response.conteneur[0].taille;
            this.conteneur.type = response.conteneur[0].type;
            this.conteneur.dateLivraison = response.conteneur[0].dateLivraison;
            this.conteneur.lieuLivraison = response.conteneur[0].lieuLivraison;
            this.conteneur.numBL = response.conteneur[0].numBL;
            this.modelAutocompleCamion.nomClient = response.conteneur[0].client;
            this.ConteneurImportModel = response.conteneur[0];

            for(var i = 0; i < response.photos.length; i++){
                response.photos[i].src = this.ConfigurationService.configuration.ServerWebHostname + "/" + response.photos[i].src;
                this.photos_previsualisation = response.photos;
               }
               
              this.search("saisir");
           }, (erreur:any) => {
             console.log(erreur.status);
         })

  }

  SupprimerPopup(id:any): void{
    $(".suppliste").trigger("click");
    this.supp_id = id;
    this.iddocument = id;
  }

  supprimer(id:any):void{
       this.apiUrl = "SERVER/importretourconteneur/recuperation/supprimer";
       var data = {id:id,iddocument:this.iddocument};
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
        )
     ).subscribe((value:any) => {
         //this.supprimer_image();
         this.listeRetour();
       }, (erreur:any) => {
           console.log(erreur.status);
       })

  }


  recherch(data:any): void{
     if(this.recherche.amateur == "" && this.recherche.date1 == "" && this.recherche.date2 == "" && this.recherche.numero == "")
     this.listeRetour();
     else{
       this.apiUrl = "SERVER/importretourconteneur/recuperation/recherche";
       var data = data;
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
     ).subscribe((value:any) => {
         this.listeConteneurs = value.data;
         this.Calcule();
       }, (erreur:any) => {
           console.log(erreur.status);
       })
     }

 }

   apercu(iddocument:any): void{
    var element = <HTMLImageElement> $("#file_" + iddocument).get(0);
    element.requestFullscreen();
  }

  supprimer_image(nomfichier:any, iddocument:any) : void{
        if(iddocument == ""){
            for (var i = 0; i < this.array_files.length; i++) {

                if(this.array_files[i].name == nomfichier){
                    this.array_files.splice(i, 1);
                }
            }

            for (var i = 0; i < this.photos_previsualisation.length; i++) {
                if(this.photos_previsualisation[i].lien == nomfichier){
                    this.photos_previsualisation.splice(i, 1);
                }
            }
        }else{
            var apiUrl = "SERVER/importretourconteneur/recuperation/SupprimerFile";
            var data = {id:iddocument};
            this.transfertDataService.EnvoiData(data, apiUrl).pipe(
               retryWhen(error =>
                 error.pipe(
                   scan((acc:any, error:any) => {
                     if (acc > 2) throw error;
                     return acc + 1;
                   }, 1),
                   delay(1000)
                 )
               )
            ).subscribe((value:any) => {
                for (var i = 0; i < this.photos_previsualisation.length; i++) {
                    if(this.photos_previsualisation[i].iddocument == iddocument){
                        this.photos_previsualisation.splice(i, 1);
                    }
                }
             }, (erreur:any) => {
            })
        }

  }

  onFileChanged(event: any): void {
        this.array_files = [];
        var src = URL.createObjectURL(event.target.files[0]);
        var elements = event.target.files;
        for(var i = 0; i < elements.length; i++){
          var src = URL.createObjectURL(event.target.files[i]);
          this.photos_previsualisation.push({iddocument : '', src : this.sanitizer.bypassSecurityTrustUrl(src), lien : event.target.files[i]});
          this.array_files.push(event.target.files[i]);
        }
  }


  downloadDoc(iddossier:any){
    var apiUrl = "SERVER/importretourconteneur/recuperation/zip";
      var data = {iddossier:iddossier};
      this.transfertDataService.EnvoiData(data, apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
      ).subscribe((value:any) => {
         window.open(this.ConfigurationService.configuration.hostname + value.link , "_self")?.focus();
       }, (erreur:any) => {
      })
  }


   Exporter(): void{
      var lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/ImportRecuperationTC/recuperation/excel_saisirconteneur.php?numConteneur="+this.recherche.numero+"&amateur="+this.recherche.amateur+"&date1="+this.recherche.date1+"&date2="+this.recherche.date2;
        window.open(lien_fiche_ExpST , '_blank')?.focus();
  }

  ConfirmationImpressionFormulaire(){
         var lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/ImportRecuperationTC/recuperation/pdfsaisirconteneur.php?numConteneur="+this.recherche.numero+"&amateur="+this.recherche.amateur+"&date1="+this.recherche.date1+"&date2="+this.recherche.date2;
       $("#printRapport").remove();
         $("body").append('<div id="printRapport">\
                             <div class="col-md-12 form-group" style="display:none;">\
                               <button class="btn btn-primary btn-sm bs-modal-frame-sm-btn" data-backdrop="static" data-toggle="modal" data-target=".bs-modal-frame-sm"></button>\
                             </div>\
                             <div class="modal fade bs-modal-frame-sm" tabindex="-1" role="dialog" aria-hidden="true">\
                               <div class="modal-dialog" role="document">\
                                   <div class="modal-content">\
                                       <div class="row container_modal" style="width:100%;">\
                                       <div class="modal-header" style="margin-bottom:0px;">\
                                         <h6 class="modal-title" style="font-weight: bold;">LISTE RECUPERATION TC / VT</h6>\
                                         <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>\
                                         </button>\
                                       </div>\
                                       <div class="row col-md-12" style="width:100%;height: calc(100vh - 90px);padding-left: 0px; padding-right: 0px; margin-left: 0px; margin-right: 0px;">\
                                         <div class="col-md-12 waiting">\
                                           <div class="snippet" data-title=".dot-pulse">\
                                             <div class="stage">\
                                               <div class="dot-pulse"></div>\
                                             </div>\
                                           </div>\
                                         </div>\
                                         <iframe class="rapportframe" name="rapportframe" style="width:100%;"></iframe>\
                                       </div>\
                                   </div>\
                                 </div>\
                               </div>\
                             </div>\
                         </div>');
         $(".bs-modal-frame-sm-btn").trigger("click");
         window.open(lien_fiche_ExpST , "rapportframe")?.focus();
         $(".rapportframe").css("display", "none");
         $('.rapportframe').on('load', function(){
             $(".waiting").css("display", "none");
             $(".rapportframe").css("display", "initial");
         });
  }







PaginationNb(index:number) : void{
  var data = {"index":index,  "data": this.recherche};
  this.apiUrl = "SERVER/importretourconteneur/recuperation/PaginationNb";
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
       retryWhen(error =>
         error.pipe(
           scan((acc:any, error:any) => {
             if (acc > 2) throw error;
             return acc + 1;
           }, 1),
           delay(1000)
         )
       )
    ).subscribe((value:any) => {
       this.listeConteneurs = value.data;

     }, (erreur:any) => {
    })

}

Calcule() : void{
  this.apiUrl = "SERVER/importretourconteneur/recuperation/CalculePagination";
    this.transfertDataService.EnvoiData(this.recherche, this.apiUrl).pipe(
       retryWhen(error =>
         error.pipe(
           scan((acc:any, error:any) => {
             if (acc > 2) throw error;
             return acc + 1;
           }, 1),
           delay(1000)
         )
       )
    ).subscribe((value:any) => {
       this.liste_pagination = value.data;
     }, (erreur:any) => {
    })
}

CurrentPagePagination(data:any, index:number, ev:any): void{
    this.activePagination = index;
    this.PaginationNb(data.index);
}

}
