import { Component, OnInit } from '@angular/core';
import { journaltransite } from '../../../classes/journaltransite';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { ConfigurationService } from '../../../config/configuration.service';
 import { AuthService } from '../../../services/connexion/auth.service';
@Component({
  selector: 'app-saisir-journal-transit',
  templateUrl: './saisir-journal-transit.component.html',
  styleUrls: ['./saisir-journal-transit.component.css']
})
export class SaisirJournalTransitComponent implements OnInit {
  apiUrl:String = "";
  activeTab:String="saisir";
  dataSousMenu:any = [];
  listautoSpecifik:any = [];
  camion:any = [];
  dataSousMenuModel:any = [];
  dossier:any={};
 Totalcumul_sacs:any = 0;
 Totalcumul_poids:any = 0;
  produit:any={};
    numblselectionner:any;
  listdoss:any = [];
  journaltransite:journaltransite = new journaltransite();
  constructor(private transfertDataService : transfertDataService, private AuthService : AuthService, private ConfigurationService : ConfigurationService) { }

  ngOnInit(): void {
    this.listeCamion();
  }

  search(activeTab:String){
    this.activeTab=activeTab;
  };
  reInitSearchForm(): void{
    this.listdoss = [];
  }
  listeCamion(){
    this.apiUrl = "SERVER/journalTransite/saisirjournal/camions";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((response:any) => {
         this.camion = response.liste;

       }, (erreur:any) => {
           console.log(erreur.status);
       })

  }
  reInitSearchBlurForm(): void{
    setTimeout((val:any) => {
        this.listdoss = [];
    }, 500);
  }

  ListeAutoCompleteProduit(nomSousProduit:any){


   this.apiUrl = "SERVER/journalTransite/saisirjournal/recherche_produit";
   var data = {numBL:nomSousProduit, idDossier:this.journaltransite.idGestionNavire};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listautoSpecifik= value.listproduit;
      }, (erreur:any) => {
      })
  }

  SelectionAutocompleteProduit(data:any): void{
    this.journaltransite.idProduit= data.idProduit;
    this.produit.nomSous= data.NomSpecificProduit;
    this.produit.nomSousProduit= data.NomSpecificProduit;
    this.produit.nom_societe = data.nom_societe;
    this.produit.PoidsNet = data.PoidsNet;
    this.produit.qte =data.qte;

    this.journaltransite.idContenuNavire= data.idContenuNavire;
    this.numblselectionner =data.reference;
    this.produit.totalpoids = (this.produit.PoidsNet * this.produit.qte);

    this.listeSousMenuMenus();

  }
  ListeAutoCompleteDossier(numerodossier:any){
   this.apiUrl = "SERVER/gestionnavire/naviremouvementsnavire/recherche_dossier";
      var data = {numeroDossier:numerodossier};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listdoss= value.listdoss;
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }
  SelectionAutocomplete(data:any): void{
    this.dossier.numeroDossier1 = data.numeroDossier;
    this.dossier.Navire = data.Navire;
    this.journaltransite.idGestionNavire = data.idGestionNavire;
  }
    /*ajouter(): void{
        var sousmenu = new journaltransite();
        this.dataSousMenuModel.push(sousmenu);
    }*/

    ajouter(): void{
      var infoUser = this.AuthService.getInfoUtilisateur();
      this.journaltransite.creerPar = infoUser.id;
        var data = {
            "idjournaltransite": 0,
            "date": "",
            "camion": "",
            "destination": "",
            "nbresac": 0,
            "poids": 0,
            "idContenuNavire":Number(this.journaltransite.idContenuNavire),
            "cumulsacs": 0,
            "cumulpoids": 0,
            "soldesacs": 0,
            "soldepoids": 0,
            "blexpert": "",
            "blacc":  "",
            "bltransite":  "",
            "typetransport": "",
            "idGestionNavire":Number(this.journaltransite.idGestionNavire),
            "idProduit": Number(this.journaltransite.idProduit),
            "creerPar": Number(this.journaltransite.creerPar),
            "creerLe":  "",
            "modifierPar": 0,
            "modifierLe":  ""
        }
        this.apiUrl = "SERVER/journalTransite/saisirjournal/enregistrersousmenu";
        this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
          retryWhen(error =>
            error.pipe(
              scan((acc:any, error:any) => {
                if (acc > 2) throw error;
                return acc + 1;
              }, 1),
              delay(1000)
            )
          )
        ).subscribe((value:any) => {
          this.listeSousMenuMenus();
        }, (erreur:any) => {
            console.log(erreur.status);
        })

    }
    CalculHT(): void{
      this.Totalcumul_sacs = 0;
      this.Totalcumul_poids = 0;

     for (let i = 0; i < this.dataSousMenuModel.length; i++) {
       if (isNaN(this.dataSousMenuModel[i]["poids"])) {
         this.dataSousMenuModel[i]["poids"] = 0;
       }

       if (isNaN(this.dataSousMenuModel[i]["nbresac"])) {
         this.dataSousMenuModel[i]["nbresac"] = 0;
       }
        var cumul_sacs = 0;
        var cumul_poids = 0;
        var poids = 0;

        if (i == 0) {
          cumul_poids = parseFloat(this.dataSousMenuModel[i]["poids"]);
          cumul_sacs = parseFloat(this.dataSousMenuModel[i]["nbresac"]);
        }else{
          for (let j = 0; j <= i; j++) {
            cumul_poids = cumul_poids +  parseFloat(this.dataSousMenuModel[j]["poids"]);
            cumul_sacs = cumul_sacs +  parseFloat(this.dataSousMenuModel[j]["nbresac"]);



          }
        }
        this.dataSousMenuModel[i]["cumulpoids"] = cumul_poids;
        this.dataSousMenuModel[i]["cumulsacs"] = cumul_sacs;

        this.dataSousMenuModel[i]["cumulpoids"] = (Math.round(this.dataSousMenuModel[i]["cumulpoids"] * 100000) / 100000);

            poids = poids +  parseFloat(this.dataSousMenuModel[i]["poids"]);
        this.dataSousMenuModel[i]["soldesacs"] = this.produit.qte - this.dataSousMenuModel[i]["cumulsacs"];
        this.dataSousMenuModel[i]["soldepoids"] = this.produit.PoidsNet - this.dataSousMenuModel[i]["cumulpoids"];

        this.dataSousMenuModel[i]["soldepoids"] = (Math.round(this.dataSousMenuModel[i]["soldepoids"] * 100000) / 100000);

        this.Totalcumul_poids = this.Totalcumul_poids + poids;
        this.Totalcumul_poids = (Math.round(this.Totalcumul_poids * 100000) / 100000);

        this.Totalcumul_sacs = cumul_sacs +  this.Totalcumul_sacs;
      }
  }
  supprimer_sous_menu(idsousmenu:any, index:any): void{

          this.apiUrl = "SERVER/journalTransite/saisirjournal/supprimersousmenu";
            var data = {idjournaltransite:idsousmenu};
            this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
              retryWhen(error =>
                error.pipe(
                  scan((acc:any, error:any) => {
                    if (acc > 2) throw error;
                    return acc + 1;
                  }, 1),
                  delay(1000)
                )
              )
          ).subscribe((value:any) => {
            this.CalculHT();
              this.listeSousMenuMenus();


            }, (erreur:any) => {

          })


  }
    listeSousMenuMenus(): void{
      console.log(this.numblselectionner);

      this.apiUrl = "SERVER/journalTransite/saisirjournal/listesousmenu";
      var data = {idGestionNavire:this.journaltransite.idGestionNavire, idProduit:this.journaltransite.idProduit, idContenuNavire:this.journaltransite.idContenuNavire};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
          retryWhen(error =>
            error.pipe(
              scan((acc:any, error:any) => {
                if (acc > 2) throw error;
                return acc + 1;
              }, 1),
              delay(1000)
            )
          )
      ).subscribe((response:any) => {
        this.dataSousMenuModel = response.liste_sous_menu;
        this.CalculHT();


      }, (erreur:any) => {
          console.log(erreur.status);
      })
    }
    UpdateEltProforma(elt:any){

   this.apiUrl = "SERVER/journalTransite/saisirjournal/modifiersousmenu";
   var data = {elt:elt};
   this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
     retryWhen(error =>
       error.pipe(
         scan((acc:any, error:any) => {
           if (acc > 2) throw error;
           return acc + 1;
         }, 1),
         delay(1000)
       )
     )
 ).subscribe((value:any) => {
   this.CalculHT();

   }, (erreur:any) => {

 })
 }

}
