<div class="x_panel x_panel_close">
        <div class="x_content">
            <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;">
                <span><i  class="fa fa-arrow-left"></i></span>
                <div  class="ml-auto text-right">
                    <span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span>
                          <i class="fa fa-chevron-right"></i>
                    <span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Gestion Magasin - Sortie Magasin
                    </span>
             </div>
            </div>
            <br>
               <div class="col-md-12">
                            <div class="content-action">
                                 <ul class="nav nav-tabs bar_tabs" id="myTab" role="tablist">
                                          <li class="nav-item">
                                            <a class="nav-link active" id="saisir-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='saisir'}" (click)="search('saisir')"
                                               role="tab" aria-controls="saisir" aria-selected="true">Saisir Nouvelle Sortie Magasin</a>
                                          </li>
                                          <li class="nav-item">
                                            <a class="nav-link" id="profile-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='profile'}" (click)="search('profile')"
                                                role="tab" aria-controls="profile" aria-selected="false">Liste des Sorties Magasin</a>
                                          </li>
                                    </ul>
                                    <div class="tab-content" id="myTabContent">
                                        <div [ngClass]="{ 'show':activeTab==='saisir', 'active':activeTab==='saisir'}"
                                        class="tab-pane fade active show" id="saisir" role="tabpanel" aria-labelledby="saisir-tab">

                                          <br>
                                           <div class="x_panel x_panel_close">
                                                  <div class="x_title">
                                                        <h5>Saisir Sortie Magasin</h5>
                                                        <ul class="nav navbar-right panel_toolbox" style="padding-left: 0px;"></ul>
                                                        <div class="clearfix"></div>
                                                  </div>
                                                  <div class="col-md-12">
                                                    <div class="row">
                                                        <div class="col-md-6 form-group">
                                                            <div class="persoautocomplete">
                                                            <label for="">N°Dossier</label>
                                                           <input type="text"  class="form-control form-control-sm" name="numeroDossier" [(ngModel)] = "dossierAuto.numDossier" autocomplete="off" disabled="">
                                                            <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                                                                <div class="classPerso  dropdown-menu">
                                                                  <form class="px-0 py-3">
                                                                      <div class="col-md-12">
                                                                          <div class="row">
                                                                              <div class="col-md-12">
                                                                                <label>Saisir N° Dossier</label>
                                                                                <input type="text" [(ngModel)] = "dossierAuto.numeroDossier" class="form-control" placeholder="N° Dossier" (ngModelChange)="ListeAutoCompleteDossier(dossierAuto.numeroDossier)" name="dosss1">
                                                                                <div>
                                                                                  <br>
                                                                                    <table class="table_bloc_liste tableReseach">
                                                                                        <thead class="theadfixed">
                                                                                            <th>N°Dossier</th>
                                                                                            <th>Nom Magasin</th>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <tr class="SelectTable" *ngFor="let x of listauto" (click)="SelectionAutocomplete(x)">
                                                                                                <td>{{x.numeroDossier}}</td>
                                                                                                <td>{{x.NomMagasin}}</td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  </form>
                                                                </div>
                                                              </div>
                                                            </div>

                                                         <div class="col-md-6">
                                                           <label>Navire</label>
                                                           <input type="text" class="form-control form-control-sm" name="navire" autocomplete="off" placeholder="Navire" [(ngModel)] = "dossierAuto.NomCompagnie"  disabled="disabled">
                                                        </div>
                                                        <div class="col-md-6 form-group">
                                                            <div class="persoautocomplete">
                                                                <label for="">Produit</label>
                                                                <input type="text"  class="form-control form-control-sm" name="nomSousProduit" [(ngModel)] = "produitAuto.nomSous" autocomplete="off" disabled="">
                                                                <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                </button>
                                                                <div class="classPerso  dropdown-menu">
                                                                  <form class="px-0 py-3">
                                                                      <div class="col-md-12">
                                                                          <div class="row">
                                                                              <div class="col-md-12">
                                                                                 <label>Saisir Sous produit</label>
                                                                                    <input type="text" [(ngModel)] = "produitAuto.nomSousProduit" class="form-control" placeholder="Nom Sous Produit" (ngModelChange)="ListeAutoCompleteProduit(produitAuto.nomSousProduit)" name="prod1">
                                                                                <div>
                                                                                  <br>
                                                                                    <table class="table_bloc_liste tableReseach">
                                                                                        <thead class="theadfixed">
                                                                                            <th>Nom Sous Produit</th>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <tr class="SelectTable" *ngFor="let x of listautoSpecifik" (click)="SelectionAutocompleteProduit(x)">
                                                                                                <td>{{x.nomSousProduit}}</td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  </form>
                                                                </div>
                                                              </div>
                                                            </div>

                                                          <div class="col-md-6">
                                                               <label>Nom Client</label>
                                                               <input type="text" class="form-control form-control-sm" name="numdossier" autocomplete="off" placeholder="Nom Client" [(ngModel)] = "produitAuto.nom_societe" ng-disabled="true" disabled="disabled">
                                                           </div>
                                                           <div class="col-md-6">
                                                                <label>Quantite</label>
                                                                <input type="number" class="form-control form-control-sm" name="quantite" placeholder="quantite" autocomplete="off" [(ngModel)] = "produitdispoModel.qte_dispo" (ngModelChange)="PoidsTotalCalcule()">
                                                           </div>
                                                            <div class="col-md-6">
                                                                <label>Poids Total</label>
                                                                <input type="number" class="form-control form-control-sm" name="poids_total" placeholder="Poids Total" autocomplete="off" [(ngModel)] = "produitdispoModel.poids_dispo">
                                                           </div>
                                                           <div class="form-group col-md-6">
                                                                    <label for="exampleFormControlSelect1">
                                                                        <font style="vertical-align: inherit;">
                                                                            <font style="vertical-align: inherit;">BL LTA</font>
                                                                        </font>
                                                                    </label>
                                                                    <input type="text" placeholder="BL LTA" class="form-control form-control-sm" [(ngModel)] = "mvtModel.bl_client">
                                                             </div>
                                                            <div class="form-group col-md-6">
                                                                    <label for="exampleFormControlSelect1">
                                                                        <font style="vertical-align: inherit;">
                                                                            <font style="vertical-align: inherit;">Provenance</font>
                                                                        </font>
                                                                    </label>
                                                                    <input type="text" placeholder="Provenance" class="form-control form-control-sm" [(ngModel)] = "mvtModel.provenance">

                                                             </div>
                                                              <div class="form-group col-md-6">
                                                                    <div class="form-group">
                                                                        <label for="exampleFormControlInput2">Date Sortie Stock</label>
                                                                        <input type="date" id="exampleFormControlInput2" placeholder="name@example.com" class="form-control form-control-sm" [(ngModel)] = "this.mvtModel.date_mvt">
                                                                    </div>
                                                             </div>
                                                        </div>
                                                  </div>
                                              </div>
                                            <br>
                                            <div class="col-md-12 text-center">
                                              <div class="card-body">
                                                   <span>Télécharger des Documents</span>
                                                   <br><br>
                                                   <div class="col-md-12">
                                                    <div class="download-card" style="text-align: left!important;">
                                                      <div class="col-md-12">
                                                        <div class="row">
                                                          <div class="inline"><br><br><img style ="width: 35px;" src="./assets/img/cloud.png"></div>
                                                          <div class="inline1"><br><br><p><span>&nbsp;Cliquez sur le bouton pour ajouter des fichers</span><br>&nbsp; <button type="button" class="btn btn-primary btn-sm btn-sm-perso" (click)="fileInputBlock.click()">PARCOURIR LES FICHIERS </button></p></div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                   </div>
                                                   <div class="row blockphotosZone" *ngIf="photos_previsualisation.length > 0">
                                                    <br><br>
                                                    <div class="col-md-3 photosZone" *ngFor="let x of photos_previsualisation" [ngClass]=" x.iddocument == '' ? 'uploadfile' : ''">
                                                        <span>
                                                          <span *ngIf="x.iddocument == ''" class="fichierToUpload">FICHIER NON ENREGISTRE</span>
                                                        </span>
                                                        <img [src]="x.src" id="file_{{x.iddocument}}">
                                                        <div class="text-center text-center pt-2 pb-2 action-band">
                                                          <button class="btn btn-sm btn-secondary" (click)="apercu(x.iddocument)">
                                                                <i class="fas fa-search"></i>
                                                            </button>&nbsp;&nbsp;
                                                            <button class="btn btn-sm btn-danger" (click)="supprimer_image(x.lien, x.iddocument)">
                                                                <i class="fas fa-trash"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>

                                                <input type="file" name="file" id="file" [hidden]="true" (change)="onFileChanged($event)" multiple="multiple" #fileInputBlock>
                                            </div>
                                            <br>
                                           <div class="col-sm-12 text-right mobile-buttons">
                                                      <button class="btn btn-primary btn-enregistrer" (click) = "Enregistrer()">
                                                          <font style="vertical-align: inherit;">
                                                              <font style="vertical-align: inherit;">Enregistrer</font>
                                                          </font>
                                                      </button>
                                                      &nbsp;
                                                      <button class="btn btn-secondary btn-enregistrer" (click) = "Annuler()">
                                                          <font style="vertical-align: inherit;">
                                                              <font style="vertical-align: inherit;">Annuler</font>
                                                          </font>
                                                      </button>
                                              </div>

                                        </div>

                                     <div class="tab-pane fade" id="profile" role="tabpanel"  aria-labelledby="profile-tab" [ngClass]="{'show':activeTab==='profile', 'active':activeTab==='profile'}">

                                        <br>
                                        <div class="row">
                                          <div class="form-group col-xs-12 col-md-12 recherche_liste">
                                            <div class="row">
                                                <div class="form-group col-xs-12 col-md-3">
                                                        <label for="Recherche_magasin">Nom magasin</label>
                                                        <select class="form-control form-control-sm" name="recherche_magasin" id="recherche_magasin" [(ngModel)] = "recherche.NomMagasin" (ngModelChange)="Recherche(recherche)">
                                                          <option *ngFor = "let x of liste_magasins" value="{{x.NomMagasin}}">{{x.NomMagasin}}</option>
                                                        </select>
                                                  </div>
                                                  <div class="form-group col-xs-12 col-md-3 persoautocomplete">
                                                      <label for="">N°Dossier</label>
                                                      <input type="text" placeholder="N°Dossier"   class="form-control form-control-sm" [(ngModel)] = "recherche.numDossier"  autocomplete="off" (ngModelChange)="Recherche(recherche)">
                                                  </div>
                                                  <div class="form-group col-xs-12 col-md-3">
                                                          <label for="Recherche_client">Client</label>
                                                          <input id="Recherche_client" [(ngModel)] = "recherche.nom_societe" class="form-control form-control-sm" placeholder="Nom client"  (ngModelChange)="Recherche(recherche)">
                                                  </div>
                                                  <div class="form-group col-xs-12 col-md-3">
                                                          <label for="Recherche_sous_produit">Nom produit</label>
                                                          <input type="text" id="Recherche_sous_produit" [(ngModel)] = "recherche.nom_produit_magasin" class="form-control form-control-sm" placeholder="Nom sous produit" (ngModelChange)="Recherche(recherche)">
                                                  </div>
                                            </div>
                                          </div>
                                          <table class="table_bloc_liste">
                                              <thead>
                                                <tr>
                                                <th>
                                                   Magasin
                                                </th>
                                                <th>
                                                    N°Dossier
                                                </th>
                                                <th>
                                                    Client
                                                </th>
                                                <th>
                                                   Nom produit
                                                </th>
                                                <th>
                                                    Qte total
                                                </th>
                                                <th>
                                                    Poids total
                                                </th>
                                                <!--<th>
                                                    Creer Par
                                                </th>-->
                                                <th>
                                                </th>
                                                <th>
                                                </th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  <tr *ngFor="let n of liste_prodmagasins" class="ng-scope">
                                                      <td>{{n.NomMagasin}}</td>
                                                      <td>{{n.numeroDossier}}</td>
                                                      <td>{{n.nom_societe}}</td>
                                                      <td>{{n.nom_produit_magasin}}</td>
                                                      <td>{{n.qte}}</td>
                                                      <td>{{n.poids}}</td>
                                                      <!--<td>{{n.nom}}</td>-->
                                                      <td class="text-center">
                                                            <button type="button" class="btn btn-info btn-sm" (click)="AfficherDonner(n.id_mvt)"><i class="fas fa-edit"></i></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-danger btn-sm" (click)="Supprimer(n.id_mvt)"><i class="far fa-trash-alt"></i></button>
                                                        </td>
                                                  </tr>
                                              </tbody>
                                          </table>
                                          
                                      </div>

                                     </div>

                                    </div>
                              </div>
                        </div>

        </div>
</div>

<button type="button" class="btn btn-primary  btn-sm suppdossier" style="display:none;" data-backdrop="static" data-toggle="modal" data-target=".bs-Form-sm"></button>
<div class="modal fade bs-Form-sm" tabindex="-1" role="dialog" aria-hidden="true">
 <div class="modal-dialog modal-sm" role="document">
  <div class="modal-content">
      <div class="row container_modal">
          <div class="modal-header">
            <h6 class="modal-title" style="font-weight: bold;">ERREUR</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>
            </button>
          </div>
          <div>
             <div class="modal-content-dl">
              <p><b>Stock Indisponible !!!</b></p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" data-dismiss="modal"  class="btn btn-primary btn-sm" (click) = "search('saisir')">OK</button>
          </div>
      </div>
    </div>
  </div>
</div>
