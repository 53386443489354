<div class="x_panel x_panel_close">
    <div class="x_content">
       <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;"><span ><i  class="fa fa-arrow-left"></i></span>
           <div  class="ml-auto text-right"><span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span><i  class="fa fa-chevron-right"></i><span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Gestion Déchargements</span>
           </div>
       </div>
       <ul class="nav nav-tabs bar_tabs" id="myTab" role="tablist">
             <li class="nav-item">
               <a class="nav-link active" id="saisir-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='saisir'}" (click)="Tabulation('saisir')"
                  role="tab" aria-controls="saisir" aria-selected="true">Etats Déchargements</a>
             </li>
       </ul>

   <div class="tab-content" id="myTabContent">
     <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
       <br>
       <div class="">
          <div class="x_content">
            <div class="form-group col-xs-12 col-md-12 recherche_liste">
               <div class="row">
                  <div class="col-md-4 form-group">
                      <div class="persoautocomplete">
                                      <label for="">N°Dossier</label>
                                     <input [(ngModel)]="recherche.numeroDossier" type="text" name="recherchenumdossForm" id="recherchenumdossForm" placeholder="N°Dossier"  class="form-control form-control-sm" autocomplete="off" (focus)="reInitSearchForm()" (blur)="reInitSearchBlurForm()" disabled="disabled">
                                       <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="">
                                       </button>
                                        <div class="classPerso  dropdown-menu">
                                          <form class="px-0 py-3">
                                              <div class="col-md-12">
                                                  <div class="row">
                                                      <div class="col-md-12">
                                                        <input type="text" [(ngModel)]="dossier.numeroDossier" class="form-control form-control-sm" placeholder="N°Dossier" name="FormSaisiEtatDecharg" (ngModelChange)="ListeAutoCompleteDossier(dossier.numeroDossier)" autocomplete="off">
                                                        <div>
                                                          <br>
                                                            <table class="table_bloc_liste tableReseach">
                                                                <thead class="theadfixed">
                                                                    <th>N°Dossier</th>
                                                                </thead>
                                                                <tbody>
                                                                    <tr class="SelectTable"  *ngFor="let x of listdoss" (click)="SelectionAutocomplete(x)">
                                                                        <td>{{x.numeroDossier}}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </form>
                                        </div>
                                </div>
                    </div>

                </div>
            </div>
         </div>
       </div>
       <div class="x_panel text-center">
         <div class="x_content">
               <div class="row col-md-12" style="display: inline-block;">
                    <div class="tile_count row">
                      <div class="col-md-4 col-sm-4  tile_stats_count" style="margin-bottom: 10px;border-bottom: 0;padding-bottom: 10px;border-right: 2px solid #D9DEE4; ">
                         <span><h3 style="width: 100%;padding: 8px;margin-top: 0px;color: #006699;font-size: 15px;">Total Poids Navire (Tonne)</h3></span>
                         <div class="count ng-binding" style="line-height: 47px;font-weight: 600; font-size: 40px;">{{TotalPoidPrevu}}</div>
                      </div>

                      <div class="col-md-4 col-sm-4  tile_stats_count" style="margin-bottom: 10px;border-bottom: 0;padding-bottom: 10px; border-right: 2px solid #D9DEE4;">
                         <span><h3 style="width: 100%; padding: 8px;margin-top: 0px;color: #006699;font-size: 15px;">Total Poids Déchargé (Tonne)</h3></span>
                         <div class="count green ng-binding" style="font-size: 40px;line-height: 47px;font-weight: 600;color: #1ABB9C;">{{TotalPoidDecharge}}</div>
                      </div>
                     <div class="col-md-4 col-sm-4  tile_stats_count" style="margin-bottom: 10px;border-bottom: 0;padding-bottom: 10px;">
                         <span><h3 style="width: 100%;padding: 8px;margin-top: 0px;color: #006699;font-size: 15px;">Total Restant (Tonne)</h3></span>
                         <div class="count red ng-binding" style="font-size: 40px;line-height: 47px;font-weight: 600;color: #E74C3C;">{{Poids_restant}}</div>
                     </div>
                   </div>
               </div>
         </div>
        </div>
        <div  *ngFor="let x of info_dossier">
             <div class="x_panel"  *ngIf = "x.items.length > 0">
                  <div class="x_content">
                      <div style="width: 100%;background: #f8f8f8;margin-bottom: 15px;">
                        <div style="width: 33%;border-right: 1px solid #ddd;display: inline-block;height: inherit;padding: 5px;text-align: center;font-size: 18px;font-weight: 600;text-align: center;">
                                <span style="display: block;font-size: 12px;">Période de Travail</span>
                                <span style="display: block;color: #1184bd;font-size: 14px;">{{x.jour_nuit}}</span>
                        </div>
                        <div style="width: 33%;border-right: 1px solid #ddd;display: inline-block;height: inherit;padding: 5px;text-align: center;font-size: 18px;font-weight: 600;text-align: center;">
                                <span style="display: block;font-size: 12px;">N° Déchargement</span>
                                <span style="display: block;color: #1184bd;font-size: 14px;">{{x.id_dechargelent}}</span>
                        </div>
                        <div style="width: 33%;display: inline-block;height: inherit;padding: 5px;text-align: center;font-size: 18px;font-weight: 600;text-align: center;">
                                <span style="display: block;font-size: 12px;">Date Déchargement</span>
                                <span style="display: block;color: #1184bd;font-size: 14px;">{{x.debut_operation}}</span>
                        </div>
                    </div>
                      <div class="form-row col-md-12 col-sm-12">

                        <div class="form-group col-xs-12 col-md-12">
                            <div class="block_tableau">
                                <div class="panel-body">
                                    <table class="table_bloc_liste">
                                        <thead>
                                            <tr>
                                              <th>#</th>
                                              <th>
                                                Désignation produit
                                              </th>

                                              <th>
                                                Client
                                              </th>

                                              <th>
                                                Cale
                                              </th>

                                              <th>
                                                N°Borderau
                                              </th>

                                              <th>
                                                Destination
                                              </th>

                                              <th>
                                                Poids (Tonne)
                                              </th>

                                              <th>
                                                Quantité
                                              </th>



                                            </tr>

                                        </thead>
                                        <tbody>

                                          <tr *ngFor="let n of x.items">
                                            <td>{{n.idContenuNavire}}</td>
                                            <td>{{n.designationProduit}}</td>
                                            <td>{{n.nom_societe}}</td>
                                            <td class="text-center">{{n.libelle}}</td>
                                            <td>{{n.reference}}</td>
                                            <td class="text-center">{{n.lieu_destination}}</td>
                                            <td class="text-center">{{n.PoidsNet}}</td>
                                            <td class="text-center">{{n.qte}}</td>

                                          </tr>
                                          <tr *ngIf="x.items.length > 0">
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td class="text-center"></td>
                                            <td class="text-center"></td>
                                            <td class="text-center"><b>TOTAL</b></td>
                                            <td class="text-center"><b>{{x.TotalPoidsNetDechargement}}</b></td>
                                            <td class="text-center"><b>{{x.TotalqteDechargement}}</b></td>
                                          </tr>

                                    </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                  </div>
            </div>
        </div>

          <div *ngIf = "info_dossier.length > 0" class="col-md-12 col-sm-12 text-right ng-scope">
            <button  class="btn btn-success btn-sm" (click)="Exporter()">
              <i class="fas fa-file-excel"></i>&nbsp;&nbsp;Exporter</button>&nbsp;&nbsp;&nbsp;&nbsp;
            <button  class="btn btn-warning btn-sm" (click)="Imprimer()"><i  class="fas fa-print"></i>&nbsp;&nbsp;Imprimer</button>
          
         
  </div>
     </div>
   </div>



            </div>

       </div>
