import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Router, CanActivate } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";

import { ConfigurationService } from '../../config/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  UtilisateurConnecte:any = {};
  constructor(private http: HttpClient, private router: Router, private ConfigurationService : ConfigurationService) { }

  Connexion(data:any) : Observable<any>{
    return this.http.post<any>(this.ConfigurationService.configuration.hostname+"SERVER/auth/connexion",data, {observe:'body', responseType:'json'});
  }

  deconnexion(): any{
    if(localStorage.getItem("_tkex") != null)
      localStorage.removeItem("_tkex");
    this.router.navigate(['connexion']);
  }

  getToken() : any{
    return localStorage.getItem("_tkex");
  }

  getInfoUtilisateur() : any{
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(this.getToken());
    this.UtilisateurConnecte = decodedToken.data;
    return this.UtilisateurConnecte;
  }
}
