import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../services/transfertData/transfertData.service';
import { CLIENT } from '../../classes/client';
import { UTILISATEUR } from '../../classes/Utilisateur';
import { pipe } from 'rxjs';
import { AuthService } from '../../services/connexion/auth.service';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';

@Component({
  selector: 'app-gestion-clients',
  templateUrl: './gestion-clients.component.html',
  styleUrls: ['./gestion-clients.component.css']
})
export class GestionClientsComponent implements OnInit {
  activeTab:String="saisir";
  apiUrl:String = "";
  listeclient_data:any = [];
  recherche:any = {};
  duplicateProduit:boolean = false;
  message_error:any = "";

  ClientModel:CLIENT = new CLIENT();
  userModel:UTILISATEUR = new UTILISATEUR();
  constructor(private transfertDataService : transfertDataService, private AuthService : AuthService) { }

  ngOnInit(): void {
    this.Liste_des_Clients();
  }
  search(activeTab:String){
     this.activeTab=activeTab
   };

   //Fonction de verification de champs
    VerificationChampsObligatoire(tableau_champs_obligatoires:any){
        var verification_formulaire = true;
        for (var i = 0; i < tableau_champs_obligatoires.length; i++) {
            if($(tableau_champs_obligatoires[i]).val() == "" || $(tableau_champs_obligatoires[i]).val() == null || $(tableau_champs_obligatoires[i]).val() == "? string: ?"){
                    $(tableau_champs_obligatoires[i]).css("border","1px solid red");
                    verification_formulaire = false;
                    document.getElementById(tableau_champs_obligatoires[i].replace("#",""))?.scrollIntoView({behavior: "smooth"});
                    break;
            }else{
                $(tableau_champs_obligatoires[i]).css("border","");
            }
        }

        return verification_formulaire;
    }


     Annuler():void{
        this.ClientModel = new CLIENT();
        this.recherche.nom_societe = "";
        this.recherche.telephone = "";
       }

     enregistrer(data:any, event:any): void{
       this.duplicateProduit = false;
       if(this.VerificationChampsObligatoire(["#nomSociete", "#telephoneSociete"]) == false)
        return;
event.target.disabled = true;
       if(this.ClientModel.idClient == 0)
         this.apiUrl = "SERVER/gestionclient/client/enregistrer";
       else
         this.apiUrl = "SERVER/gestionclient/client/modifier";

         var infoUser = this.AuthService.getInfoUtilisateur();
          this.ClientModel.user = infoUser.id;

         var d = {"client": this.ClientModel};
         this.transfertDataService.EnvoiData(d, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((value:any) => {
            if(value.status == -1){
              event.target.disabled = false;
             this.duplicateProduit = true;
             this.message_error = value.msg;
          }else{
            event.target.disabled = false;
            this.Liste_des_Clients();
            this.search("profile");
            this.Annuler();
          }


         }, (erreur:any) => {
           event.target.disabled = false;
             console.log(erreur.status);
         })

     }

     supprimer(idClient:any):void{
       this.apiUrl = "SERVER/gestionclient/client/supprimer";
       var data = {id:idClient};
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
     ).subscribe((value:any) => {
         this.Liste_des_Clients();
       }, (erreur:any) => {
           console.log(erreur.status);
       })

     }


     Liste_des_Clients(): void{

       this.apiUrl = "SERVER/gestionclient/client/listeclient";
       var data = {};
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
           retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((response:any) => {
         this.listeclient_data = response.liste_client;
       }, (erreur:any) => {
           console.log(erreur.status);
       })

     }

     information(idClient:any):void{


           this.apiUrl = "SERVER/gestionclient/client/information";
           var data = {idClient:idClient};
           this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((response:any) => {
               this.ClientModel = response.client[0];
               this.ClientModel.idMagasin = this.ClientModel.idMagasin;
               this.ClientModel.user = this.ClientModel.user;
               this.search("saisir");

           }, (erreur:any) => {
             console.log(erreur.status);
         })

     }
     rechercheClient(data:any): void{

       if(this.recherche.nom_societe == "" && this.recherche.telephone == "" )
       this.Liste_des_Clients();
       else{
         this.apiUrl = "SERVER/gestionclient/client/recherche";
         var data = data;
         this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
           retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((value:any) => {
           this.listeclient_data= value.liste_client;
         }, (erreur:any) => {
             console.log(erreur.status);
         })
       }

   }


}
