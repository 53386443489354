import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';

@Component({
  selector: 'app-exploitation-tableaubord',
  templateUrl: './exploitation-tableaubord.component.html',
  styleUrls: ['./exploitation-tableaubord.component.css']
})
export class ExploitationTableaubordComponent implements OnInit {
  activeTab:String="saisir";
  apiUrl:String = "";
  dossierDechargement:any = {};
  ListeAutoCompleteDoss:any = [];
  RecapitulatifData:any = [];
  constructor(private transfertDataService : transfertDataService,) { }

  ngOnInit(): void {
    this.dossierDechargement.iddossier = "";
    this.dossierDechargement.numeroDossier = "";
    this.dossierDechargement.date_operation = "";
  }

  ListeAutoCompleteDossier(numdoss:any){
   this.apiUrl = "SERVER/exploitation/recapitulatif_dechargement/ListeAutoCompleteDossier";
      var data = {numdoss:numdoss};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.ListeAutoCompleteDoss = value.ListeAutoCompleteDoss;
      }, (erreur:any) => {
      })
  }

  SelectionAutocomplete(data:any): void{
    this.dossierDechargement.iddossier = data.idGestionNavire;
    this.dossierDechargement.numeroDossier = data.numeroDossier;
    this.dossierDechargement.Navire = data.Navire;
    this.reInitSearch();
  }


  reInitSearch(): void{
    this.ListeAutoCompleteDoss = [];
  }

  reInitSearchBlur(): void{
    setTimeout((val:any) => {
        this.ListeAutoCompleteDoss = [];
    }, 500);
    
  }

  Recapitulatif(){
      this.apiUrl = "SERVER/exploitation/recapitulatif_dechargement/recapitulatif";
      this.transfertDataService.EnvoiData(this.dossierDechargement, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.RecapitulatifData = value.data;
      }, (erreur:any) => {
      })
  }

}
