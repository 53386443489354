<div class="x_panel x_panel_close">
    <div class="x_content">
        <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;"><span ><i  class="fa fa-arrow-left"></i></span>
        	<div  class="ml-auto text-right"><span class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span>
        		<i  class="fa fa-chevron-right"></i>
        		<span class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Export-Livraison Conteneur - Saisir Depot Booking</span>
         	</div>
        </div>
        <br>
        <div class="col-md-12">
            <div class="content-action">
              <ul class="nav nav-tabs bar_tabs" id="myTab" role="tablist">
                 <li class="nav-item">
                   <a class="nav-link" id="profile-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='profile'}" (click)="search('profile')"
                       role="tab" aria-controls="profile" aria-selected="false">Saisir Depot Booking</a>
                 </li>
              </ul>

              <div class="tab-pane fade" id="profile" role="tabpanel"  aria-labelledby="profile-tab"
                   [ngClass]="{'show':activeTab==='profile', 'active':activeTab==='profile'}">
                   


              </div>	
            </div>
        </div>
    </div>
</div>
