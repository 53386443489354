<div class="x_panel x_panel_close">
    <div class="x_content">
        <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;"><span ><i  class="fa fa-arrow-left"></i></span>
        	<div  class="ml-auto text-right"><span class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span>
        		<i  class="fa fa-chevron-right"></i>
        		<span class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Import Livraison Conteneur - Saisir Livraison Conteneur</span>
         	</div>
        </div>
        <br>
        <div class="col-md-12">
            <div class="content-action">
              <ul class="nav nav-tabs bar_tabs" id="myTab" role="tablist">
                 <li class="nav-item">
                   <a class="nav-link active" id="saisir-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='saisir'}" (click)="search('saisir')"
                      role="tab" aria-controls="saisir" aria-selected="true">Saisir Livraison Conteneur</a>
                 </li>
              </ul>

                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade active show" id="saisir" role="tabpanel" aria-labelledby="saisir-tab"
                       [ngClass]="{ 'show':activeTab==='saisir', 'active':activeTab==='saisir'}">
                    <br>
                    <div>
                      <div class="row">
                        <div class="form-group col-md-6">
                            <div class="persoautocomplete">
                                <label for="">N°BL</label>
                                <input type="text" name="" id="Conteneur1"   class="form-control form-control-sm" [(ngModel)]="Conteneur.numBL"
                                       autocomplete="off" (focus)="reInitSearchFormconteneur()" (blur)="reInitSearchBlurFormconteneur()" disabled="disabled" placeholder="N°BL">
                                <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id=""></button>
                                <div class="classPerso  dropdown-menu">
                                    <form class="px-0 py-3">
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <input type="text" [(ngModel)]="Conteneur.numConteneurSaisi"class="form-control form-control-sm"
                                                           placeholder="N°BL" name="FormSaisiEntreeStockRechDisopRecap"
                                                           (ngModelChange)="ListeAutoCompleteConteneurrech(Conteneur.numConteneurSaisi)" autocomplete="off">
                                                    <div>
                                                    <br>
                                                        <table class="table_bloc_liste tableReseach">
                                                            <thead class="theadfixed">
                                                                <th>N°BL</th>
                                                            </thead>
                                                            <tbody>
                                                              <tr class="SelectTable"  *ngFor="let x of liste_conteneurRech"
                                                                  (click)="SelectionAutocompleterechconteneur(x)">
                                                                  <td>{{x.numBL}}</td>
                                                              </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                      </div>
                      <div id="accordion" *ngIf="historiqueBL.length > 0">
                        <div class="card" *ngFor="let x of historiqueBL; let i = index" style="margin-bottom: 25px;">
                          <div class="card-header" id="headingOne" style="padding: 0px;color: #fff;font-weight: 400;background-color: #007bff;">
                            <h5 class="mb-0">
                              <button style="font-size: 12px; padding: 3px;float: left;color: #fff;font-weight: 500;text-transform: uppercase;" class="btn" data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne">
                                Contenur : {{x.numConteneur}}
                              </button>
                              <button style="font-size: 12px; padding: 3px;float: right;color: #fff;font-weight: 500;text-transform: uppercase;" class="btn" data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne">
                                Transitaire : {{x.nom}}
                              </button>
                            </h5>
                          </div>
                      
                          <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion" style="background: #d9e2eb;">
                            <div class="card-body" style="padding: 15px;float: left;width: 100%;">
                                <div>
                                    <button type="button" class="btn btn-success btn-sm mb-1" (click)="NouvelHistorique(x)">
                                        <i class="fa fa-plus"></i>
                                    </button>
                                    <table class="table_bloc_liste" style="width: 100%;">
                                        <thead>
                                            <tr>
                                                <th style="width: 50px;">Mouvement</th>
                                                <th>Date Mouvement</th>
                                                <th>Mode Livraison</th>
                                                <th>Camion</th>
                                                <th>Chauffeur</th>
                                                <th>Shift</th>
                                                <th>Agent</th>
                                                <th>Observation</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of x.historique">
                                           
                                            <td>
                                              <select class="form-control form-control-sm" [(ngModel)]="item.idMVTLivraison" (ngModelChange)="UpdateHistorique(item)" style="width: 150px;">
                                                <option *ngFor="let xx of liste_MouvementContenur" value="{{xx.idMouvementConteneur}}">{{xx.mouvementConteneur}}</option>
                                              </select>
                                            </td>
                                            <td>
                                              <input type="date" class="form-control form-control-sm" [(ngModel)]="item.date_mvt" (ngModelChange)="UpdateHistorique(item)">
                                            </td>
                                            <td>
                                              <select class="form-control form-control-sm" [(ngModel)]="item.mode_livraison" (ngModelChange)="UpdateHistorique(item)">
                                                <option *ngFor="let xx of liste_ModeLivraison" value="{{xx.id}}">{{xx.mode}}</option>
                                              </select>
                                            </td>
                                            <td>
                                              <select class="form-control form-control-sm" [(ngModel)]="item.camion" (ngModelChange)="UpdateHistorique(item)">
                                                <option *ngFor="let xx of liste_camionSelecte" value="{{xx.idCamion}}">{{xx.immat}}</option>
                                              </select>
                                            </td>
                                            <td>
                                              <select class="form-control form-control-sm" [(ngModel)]="item.chauffeur" (ngModelChange)="UpdateHistorique(item)">
                                                <option *ngFor="let xx of liste_chauffeurSelecte" value="{{xx.idChauffeur}}">{{xx.NomChauffeur}}</option>
                                              </select>
                                            </td>
                                            <td>
                                              <select class="form-control form-control-sm" [(ngModel)]="item.shift" (ngModelChange)="UpdateHistorique(item)" style="width: 80px;">
                                                  <option value="0" selected="selected">Jour</option>
                                                  <option value="1">Nuit</option>
                                              </select>
                                            </td>
                                            <td>
                                              <select class="form-control form-control-sm" [(ngModel)]="item.agent" (ngModelChange)="UpdateHistorique(item)">
                                                <option *ngFor="let xx of liste_agents" value="{{xx.idAgent}}">{{xx.nomAgent}}</option>
                                              </select>
                                            </td>
                                            <td>
                                              <textarea style="width: 150px;" class="form-control form-control-sm" name="observation" id="observation" cols="1" rows="1" [(ngModel)]="item.observation" (ngModelChange)="UpdateHistorique(item)"></textarea>
                                            </td>
                                            <td>
                                              <button class="btn btn-danger btn-sm" type="button" (click)="SuppHistorique(item)" title="supprimer">
                                                    <i class="fa fa-trash-alt" ></i>
                                              </button>
                                            </td>
                                          </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                  
                </div>
            </div>
        </div>

    </div>
</div>
<button type="button" class="btn btn-primary  btn-sm suppliste" style="display:none;" data-backdrop="static" data-toggle="modal" data-target=".bs-Form-sm"></button>
<div class="modal fade bs-Form-sm" tabindex="-1" role="dialog" aria-hidden="true">
 <div class="modal-dialog modal-sm" role="document">
  <div class="modal-content">
      <div class="row container_modal">
          <div class="modal-header">
            <h6 class="modal-title" style="font-weight: bold;">SUPPRESSION</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>
            </button>
          </div>
          <div>
             <div class="modal-content-dl">
              <p>Souhaitez-vous supprimer ce enregistrement ?</p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" data-dismiss="modal"  class="btn btn-primary btn-sm" (click)="supprimer(supp_id)">Oui</button>
            <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Non</button>
          </div>
      </div>
    </div>
  </div>
</div>
