import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { BUDGET_CLIENT_NAVIRE } from '../../../classes/budget_client_navire';
import { reglementnavire } from '../../../classes/reglementnavire';
import { reglementclient } from '../../../classes/reglementclient';
import { elementreglementclt } from '../../../classes/elementreglementclt';
import { DOSSIER } from '../../../classes/Dossier';
import { AuthService } from '../../../services/connexion/auth.service';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';

@Component({
  selector: 'app-saisir-budget-navire',
  templateUrl: './saisir-budget-navire.component.html',
  styleUrls: ['./saisir-budget-navire.component.css']
})
export class SaisirBudgetNavireComponent implements OnInit {
  apiUrl:String = "";
  activeTab:String="saisir";
  Open:any = false;
  Open1:any = false;
    clientAutocoplet:any = {};
  dossier:any = "";
  listauto:any = [];
    suppelt:any = {};
  dossierAuto:any={};
  listclient:any = [];
  eltReglmentClient:any = [];
    AfficherRechercheAutompleteClient : Array<boolean> = [];
    liste_ClientsRech:any=[];
  listproduit:any = [];
  listebudgetnavire:any = [];
  liste_Clients:any = [];
  dataSousMenuModel:any = [];
idClt:any = 0;
  dataSousMenu:any = [];
  idClient:any;
  recherche:any={};
  TotalBudgetDossier:any = 0;

  reglementnavireModel:reglementnavire = new reglementnavire();
  reglementclientModel:reglementclient = new reglementclient();
  elementreglementclt:elementreglementclt = new elementreglementclt();

  dossierModel:DOSSIER = new DOSSIER();
  sousmenuModel:BUDGET_CLIENT_NAVIRE = new BUDGET_CLIENT_NAVIRE();
  constructor(private AuthService : AuthService,private transfertDataService : transfertDataService) { }

  ngOnInit(): void {
      var date = new Date();

    this.listeBudgetNavire();

    this.recherche.numeroDossier="";
    this.recherche.Navire="";
  }
///////////////////////////////////////////////////////////////////New New////////////////////////////////////////////////////////////////////////////////////
UpdateElt(elt:any, table:any){


  this.apiUrl = "SERVER/exploitation/saisirbudget/modifiersousmenu";
  var data = {elt:elt};
  this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
    retryWhen(error =>
      error.pipe(
        scan((acc:any, error:any) => {
          if (acc > 2) throw error;
          return acc + 1;
        }, 1),
        delay(1000)
      )
    )
).subscribe((value:any) => {
  this.CalculHT(table);
  }, (erreur:any) => {

})



}

UpdateClt(table:any){

if (this.reglementnavireModel.idReglementnavire == 0) {
    this.CalculMontant(table);
}
else{
  this.apiUrl = "SERVER/exploitation/saisirbudget/modifiersousmenuPrincipal";
  var data = {elt:table};
  this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
    retryWhen(error =>
      error.pipe(
        scan((acc:any, error:any) => {
          if (acc > 2) throw error;
          return acc + 1;
        }, 1),
        delay(1000)
      )
    )
).subscribe((value:any) => {
  this.CalculMontant(table);
  }, (erreur:any) => {

})


}


}
CalculMontant(table:any): void{

table.MontantRegle =(table.Netapayer - (table.Netapayer * table.Perte)/100) - table.AchatRiz;
  table.MontantRegle = (Math.round(table.MontantRegle * 10000) / 10000);
this.reglementnavireModel.total_reglementnavire = 0;
for (let f = 0; f < this.dataSousMenuModel.length; f++) {
console.log(this.dataSousMenuModel[f].MontantRegle);



this.reglementnavireModel.total_reglementnavire = this.reglementnavireModel.total_reglementnavire + this.dataSousMenuModel[f].MontantRegle;
  this.reglementnavireModel.total_reglementnavire = (Math.round(this.reglementnavireModel.total_reglementnavire * 10000) / 10000);
}
}
CalculHT(table:any): void{


  var netapayer = 0;
  var sommeNetapays = 0;
  var qte = 0;

 for (let i = 0; i < table.eltReglmentClient.length; i++) {


if (isNaN(table.eltReglmentClient[i]["prixT"])) {
     table.eltReglmentClient[i]["prixT"] = 0;
   }

   if (isNaN(table.eltReglmentClient[i]["tonnage"])) {
     table.eltReglmentClient[i]["tonnage"] = 0;
   }
   netapayer = netapayer + (parseFloat(table.eltReglmentClient[i]["prixT"]) * parseFloat(table.eltReglmentClient[i]["tonnage"]));


   console.log(this.dataSousMenuModel.length);


  }
    table.Netapayer = netapayer;

    table.Netapayer = (Math.round(netapayer * 10000) / 10000);


this.CalculMontant(table);


}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////::
  NOUVEAUClt():void{
    if (this.reglementnavireModel.idReglementnavire == 0) {
      var sousmenu:any = {"idReglementClient": 0,
        "idReglementNavire": 0,
        "Netapayer": 0,
        "Perte": 0,
        "idClient": 0,
        "AchatRiz": 0,
        "MontantRegle": 0,
        "dateCreation": "",
        "dateModif": "",
        "creerPar": 0,
        "modifierPar": 0};
      sousmenu.eltReglmentClient = [];
      this.dataSousMenuModel.push(sousmenu);
    }else{
      this.enregistrer_sous_menuPrincipale();
    }



  }
  enregistrer_sous_menuPrincipale(): void{

      var data = {
          "idReglementClient": 0,
          "idReglementNavire": Number(this.reglementnavireModel.idReglementnavire),
          "Netapayer": 0,
          "Perte": 0,
          "idClient": 0,
          "AchatRiz": 0,
          "MontantRegle": 0,
          "dateCreation": "",
          "dateModif": "",
          "creerPar": 0,
          "modifierPar": 0
      }
      console.log(data);

      this.apiUrl = "SERVER/exploitation/saisirbudget/enregistrersousmenuPrincipal";


      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
      ).subscribe((value:any) => {
        this.listeSousMenuMenus();
      }, (erreur:any) => {
          console.log(erreur.status);
      })

  }
  listeSousMenuMenus(): void{
    this.apiUrl = "SERVER/exploitation/saisirbudget/listesousmenuPrincipal";
    var data = {idReglementnavire: this.reglementnavireModel.idReglementnavire, iddossier:this.reglementnavireModel.iddossier};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {


    this.dataSousMenuModel = response.liste_sous_menu;
          console.log(this.dataSousMenuModel);
    //  this.CalculHT();

    for (let i = 0; i <response.liste_sous_menu.length; i++) {


       this.dataSousMenuModel[i].Netapayer = response.liste_sous_menu[i].Netapayer;
       this.dataSousMenuModel[i].Perte = response.liste_sous_menu[i].Perte;
       this.dataSousMenuModel[i].AchatRiz = response.liste_sous_menu[i].AchatRiz;
       this.dataSousMenuModel[i].MontantRegle = response.liste_sous_menu[i].MontantRegle;

console.log(response.liste_sous_menu[i].MontantRegle);

    }
    for (let i = 0; i <response.clt.length; i++) {


       this.dataSousMenuModel[i].client= response.clt[i].element[0].nom_societe;

       this.dataSousMenuModel[i].eltReglmentClient = response.clt[i].element;

this.dataSousMenuModel[i].client = response.clt[i][0].nom_societe;
console.log(response.clt[i].nom_societe);

}

    }, (erreur:any) => {
        console.log(erreur.status);
    })
  }
  listeSousMenuMenusElt(x:any): void{
    this.apiUrl = "SERVER/exploitation/saisirbudget/listesousmenu";
    var data = {idClient :this.idClt, iddossier: this.reglementnavireModel.iddossier};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
      console.log(x.eltReglmentClient);
      console.log(x);
      x.eltReglmentClient = response.liste_sous_menu;
     this.CalculHT(x);
    }, (erreur:any) => {
        console.log(erreur.status);
    })
  }

  reInitSearchFormClient(): void{
    this.liste_ClientsRech = [];
  }

  reInitSearchBlurFormClient(): void{
    setTimeout((val:any) => {
        this.liste_ClientsRech = [];

    }, 500);
  }
  ListeAutoCompleteClientrech(client:any) : void{

    this.apiUrl = "SERVER/importlivraisonconteneur/receptionconteneur/listeclientrecherche";
    var data = {client:client};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
      this.liste_ClientsRech = response.liste_client;
    }, (erreur:any) => {})

  }

  SelectionAutocompleterechClient(table:any ,row:any, index:any):void{
  this.dataSousMenuModel[index].client1 = row.nom_societe;
  table.client= row.nom_societe;
  this.idClt= row.idClient;

for (let i = 0; i < this.dataSousMenuModel.length; i++) {
  this.dataSousMenuModel[i].idClient = row.idClient;

}
  this.liste_ClientsRech = [];
  var infoUser = this.AuthService.getInfoUtilisateur();
  var data = [{
      "ideltReglementClient": 0,
      "date": "",
      "iddossier":this.reglementnavireModel.iddossier,
      "destination": "Mag Portuaire",
      "prixT": 0,
      "tonnage": 0,
      "idClient":row.idClient,
      "creePar": infoUser.id,
      "dateCreation": "",
      "modifierPar": 0,
      "dateModifier":"",
  },
  {
      "ideltReglementClient": 0,
      "date": "",
      "destination": "Mag Hors Port (Client)",
      "prixT": 0,
      "iddossier":this.reglementnavireModel.iddossier,
      "tonnage": 0,
        "idClient":row.idClient,
    "creePar": infoUser.id,
      "dateCreation": "",
      "modifierPar": 0,
      "dateModifier":"",
  },
  {
      "ideltReglementClient": 0,
      "date": "",
      "destination": "Mag Hors Port (LTA)",
      "prixT": 0,
      "tonnage": 0,
      "iddossier":this.reglementnavireModel.iddossier,
        "idClient":row.idClient,
    "creePar": infoUser.id,
      "dateCreation": "",
      "modifierPar": 0,
      "dateModifier":"",
  },
  {
      "ideltReglementClient": 0,
      "date": "",
      "destination": "Chargement à Quai",
      "prixT": 0,
      "tonnage": 0,
      "iddossier":this.reglementnavireModel.iddossier,
        "idClient":row.idClient,
    "creePar": infoUser.id,
      "dateCreation": "",
      "modifierPar": 0,
      "dateModifier":"",
  },
  {
      "ideltReglementClient": 0,
      "date": "",
      "destination": "Autre",
      "prixT": 0,
      "tonnage": 0,
      "iddossier":this.reglementnavireModel.iddossier,
    "idClient":row.idClient,
    "creePar": infoUser.id,
      "dateCreation": "",
      "modifierPar": 0,
      "dateModifier":"",
  }]

    table.eltReglmentClient = data;
//  this.eltReglmentClient = this.dataSousMenu;


          this.apiUrl = "SERVER/exploitation/saisirbudget/enregistrersousmenuElt";


          this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
            retryWhen(error =>
              error.pipe(
                scan((acc:any, error:any) => {
                  if (acc > 2) throw error;
                  return acc + 1;
                }, 1),
                delay(1000)
              )
            )
          ).subscribe((value:any) => {
            console.log(table);

            this.listeSousMenuMenusElt(table);
          }, (erreur:any) => {
              console.log(erreur.status);
          })

}
  search(activeTab:String){
     this.activeTab=activeTab;
  };

  reInitSearch(): void{
    this.listauto = [];
  }

  reInitSearchBlur(): void{
    setTimeout((val:any) => {
        this.listauto = [];
    }, 500);

  }

  CalculPoidsQte(){
    this.TotalBudgetDossier = 0;

    for(var i = 0; i < this.dataSousMenuModel.length; i++){
      if(isNaN(this.dataSousMenuModel[i].budget_client))
        this.dataSousMenuModel[i].budget_client = 0;

      var budget_client = parseFloat(this.dataSousMenuModel[i].budget_client);
      this.TotalBudgetDossier = this.TotalBudgetDossier + budget_client;
    }

  }


   Annuler(){
     this.reglementnavireModel = new reglementnavire();
      this.reglementclientModel = new reglementclient();
      this.elementreglementclt = new elementreglementclt();
     this.dossierModel = new DOSSIER();
     this.dossierAuto = {};
     this.dataSousMenuModel = [];
   }
   supprimer_sous_menu(x:any, idsousmenu:any): void{

           this.apiUrl = "SERVER/exploitation/saisirbudget/supprimersoumenu";
             var data = {id:idsousmenu};
             this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
               retryWhen(error =>
                 error.pipe(
                   scan((acc:any, error:any) => {
                     if (acc > 2) throw error;
                     return acc + 1;
                   }, 1),
                   delay(1000)
                 )
               )
           ).subscribe((value:any) => {

           this.listeSousMenuMenusElt(x);

             }, (erreur:any) => {

           })


   }
     ListeAutoCompleteDossier(numDoss:any){

      this.apiUrl = "SERVER/exploitation/saisirbudget/autocomplete_dossier";
         var data = {NumDoss:numDoss};
         this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
           retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((value:any) => {
           this.listauto = value.listAuto;
         }, (erreur:any) => {

         })
     }

     SelectionAutocomplete(data:any): void{
       this.dossierAuto.iddossier= data.idGestionNavire;

       this.dossierAuto.Dossier= data.numeroDossier;
       this.dossierAuto.numeroDossier= data.numeroDossier;
       this.dossierAuto.date_arrivee_prevu = data.date_arrivee_prevu;
       this.dossierAuto.Navire = data.Navire;
       this.dossierAuto.date= data.date;
       this.dossierAuto.num_navire = data.IdNavire;
       this.reglementnavireModel.iddossier= data.idGestionNavire;
       this.reglementnavireModel.num_navire= data.IdNavire;
       this.listeClient(this.dossierAuto.iddossier);
     }


       enregistrer_sous_menu(sousmenu:any): void{
          this.apiUrl = "SERVER/exploitation/saisirbudget/modifiersous_menu";
          var d = {"sousmenu":sousmenu};
           this.transfertDataService.EnvoiData(d, this.apiUrl).pipe(
               retryWhen(error =>
                 error.pipe(
                   scan((acc:any, error:any) => {
                     if (acc > 2) throw error;
                     return acc + 1;
                   }, 1),
                   delay(1000)
                 )
               )
           ).subscribe((value:any) => {
                this.CalculPoidsQte();
           }, (erreur:any) => {

           })

       }


       InformationClient(client:any){

                  this.apiUrl = "SERVER/exploitation/saisirbudget/infoClient";
                  var data = {idClient:client.idClient, idGestionNavire:this.dossierAuto.iddossier};
                  this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
                      retryWhen(error =>
                        error.pipe(
                          scan((acc:any, error:any) => {
                            if (acc > 2) throw error;
                            return acc + 1;
                          }, 1),
                          delay(1000)
                        )
                      )
                  ).subscribe((response:any) => {
                    client.idClient= Number(response.info[0].idClient);
                    client.poids_prevu= Number(response.info[0].poids_prevu);
                    client.qte_prevu= Number(response.info[0].qte_prevu);
                  }, (erreur:any) => {
                      console.log(erreur.status);
                  })
       }

       listeClient(iddossier:any) : void{

         this.apiUrl = "SERVER/exploitation/saisirbudget/listeclient";
         var data = {"id":iddossier};
         this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((response:any) => {
             this.liste_Clients = response.liste_client;
             for(var i = 0; i < response.liste_client.length; i++){
                var sousmenuclient = new BUDGET_CLIENT_NAVIRE();
                sousmenuclient.id_budget_client = response.liste_client[i].id_budget_client;
                sousmenuclient.idClient = response.liste_client[i].idClient;
                sousmenuclient.poids_prevu = response.liste_client[i].poids_prevu;
                sousmenuclient.iddossier = this.dossierAuto.iddossier;
                sousmenuclient.qte_prevu = response.liste_client[i].qte_prevu;
                sousmenuclient.budget_client = response.liste_client[i].budget_client;
                sousmenuclient.date_creation = response.liste_client[i].date_creation;

                this.dataSousMenuModel.push(sousmenuclient);
             }

             this.CalculPoidsQte();

         }, (erreur:any) => {

         })

       }




       Enregistrer(data:any,event:any): void{

     event.target.disabled = true;
         if(this.reglementnavireModel.idReglementnavire == 0){
           this.apiUrl = "SERVER/exploitation/saisirbudget/enregistrer";
         }else{
           this.apiUrl = "SERVER/exploitation/saisirbudget/modifier";
         }

          var infoUser = this.AuthService.getInfoUtilisateur();
          this.reglementnavireModel.idUser = infoUser.id;
          this.reglementnavireModel.modifierpar = Number(this.reglementnavireModel.modifierpar);
         var d = {"reglement": this.reglementnavireModel, "sousmenu": this.dataSousMenuModel};

         this.transfertDataService.EnvoiData(d, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((value:any) => {
           event.target.disabled = false;
           this.listeBudgetNavire();
           this.Annuler();
           this.search("profile");
         }, (erreur:any) => {
           event.target.disabled = false;
             console.log(erreur.status);
         })
       }

       listeBudgetNavire(): void{
         this.apiUrl = "SERVER/exploitation/saisirbudget/listebudget";
         var data = {};
         this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((response:any) => {
           this.listebudgetnavire = response.listebudgetnavire;
         }, (erreur:any) => {

         })
       }

        AfficherDonner(idReglementnavire:any): void{
           this.apiUrl = "SERVER/exploitation/saisirbudget/information";
           var data = {id:idReglementnavire};
           this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((response:any) => {
               this.reglementnavireModel = response.budgetNavire[0];


               for (let i = 0; i <response.ELTClient.length; i++) {
this.dataSousMenuModel[i]= {};
                  this.dataSousMenuModel[i].eltReglmentClient = [];
                 this.dataSousMenuModel[i].eltReglmentClient = response.ELTClient[i];




               }



               for (let i = 0; i <response.Client.length; i++) {


                  this.dataSousMenuModel[i].Netapayer = response.Client[i].Netapayer;
                  this.dataSousMenuModel[i].Perte = response.Client[i].Perte;
                  this.dataSousMenuModel[i].AchatRiz = response.Client[i].AchatRiz;
                  this.dataSousMenuModel[i].MontantRegle = response.Client[i].MontantRegle;
                 this.dataSousMenuModel[i].client = response.Client[i].nom_societe;

               }




               this.dossierAuto.iddossier= response.budgetNavire[0].idGestionNavire;

               this.dossierAuto.Dossier= response.budgetNavire[0].numeroDossier;
               this.dossierAuto.numeroDossier= response.budgetNavire[0].numeroDossier;
               this.dossierAuto.Navire = response.budgetNavire[0].Navire;
               this.dossierAuto.num_navire = response.budgetNavire[0].IdNavire;
               this.reglementnavireModel.iddossier= response.budgetNavire[0].idGestionNavire;
               this.reglementnavireModel.num_navire= response.budgetNavire[0].IdNavire;



               this.search("saisir");
           }, (erreur:any) => {
         })
       }

       SupprimerBudjet(): void{
           this.apiUrl = "SERVER/exploitation/saisirbudget/supprimer";
             var data = {id:this.suppelt.id};
           this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((value:any) => {
             this.listeBudgetNavire();
           }, (erreur:any) => {
               console.log(erreur.status);
           })
       }
       SupprimerPopup(id:any): void{
         $(".suppdossier").trigger("click");
         this.suppelt.id = id;
       }
       Recherche(data:any): void{
         console.log(data);
         if(this.recherche.numeroDossier == "" && this.recherche.Navire == "" ){
           this.listeBudgetNavire();
         }else{
           this.apiUrl = "SERVER/exploitation/saisirbudget/recherche";
           var data = data;

           this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((value:any) => {

            this.listebudgetnavire= value.listebudgetnavire;
           }, (erreur:any) => {
               console.log(erreur.status);
           })
         }

       }


}
