import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { ConfigurationService } from '../../../config/configuration.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-rp-etat-couts-manutention',
  templateUrl: './rp-etat-couts-manutention.component.html',
  styleUrls: ['./rp-etat-couts-manutention.component.css']
})
export class RpEtatCoutsManutentionComponent implements OnInit {
  apiUrl:String = "";
  activeTab:String="saisir";
  listmagasin:any = [];
  recherche:any={};
  listdoss:any=[];
  listclient:any=[];
  listprod:any=[];
  Liste_cout_manut:any=[];
  lien_fiche_ExpST:any;
  constructor(private transfertDataService : transfertDataService,  private ConfigurationService : ConfigurationService) { }

  ngOnInit(): void {
  }
  search(activeTab:String){
      this.activeTab=activeTab
  };

  ListeAutoCompleteDossier(numerodossier:any){
     this.apiUrl = "SERVER/reporting/etatcoutmanut/recherche_dossier";
      var data = {numeroDossier:numerodossier};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listdoss= value.listdoss;
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }

  SelectionAutocomplete(data:any): void{
    this.recherche.numeroDossier = data.numeroDossier;
    this.recherche.idGestionNavire = data.idGestionNavire;
  }

   Recherche(data:any): void{
      this.apiUrl = "SERVER/reporting/etatcoutmanut/recherche";
      var d = {iddossier:this.recherche.idGestionNavire};
      this.transfertDataService.EnvoiData(d, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
      if(this.recherche.numeroDossier == ""){
        this.Liste_cout_manut = [];
      }
      this.Liste_cout_manut= value.Liste_cout_manut;
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }


  RapportPdf(){
this.lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/Reporting/pdf_Reportingficheetatcoutmanut.php?iddossier="+this.recherche.idGestionNavire;
$("#printRapport").remove();
$("body").append('<div id="printRapport">\
                   <div class="col-md-12 form-group" style="display:none;">\
                     <button class="btn btn-primary btn-sm bs-modal-frame-sm-btn" data-backdrop="static" data-toggle="modal" data-target=".bs-modal-frame-sm"></button>\
                   </div>\
                   <div class="modal fade bs-modal-frame-sm" tabindex="-1" role="dialog" aria-hidden="true">\
                     <div class="modal-dialog" role="document">\
                         <div class="modal-content">\
                             <div class="row container_modal" style="width:700px;margin-left:-35px">\
                             <div class="modal-header" style="margin-bottom:0px;">\
                               <h6 class="modal-title" style="font-weight: bold;">ETAT COUT MANUTENTION</h6>\
                               <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>\
                               </button>\
                             </div>\
                             <div class="row col-md-12" style="width:100%;height: calc(100vh - 90px);padding-left: 0px; padding-right: 0px; margin-left: 0px; margin-right: 0px;">\
                               <div class="col-md-12 waiting">\
                                 <div class="snippet" data-title=".dot-pulse">\
                                   <div class="stage">\
                                     <div class="dot-pulse"></div>\
                                   </div>\
                                 </div>\
                               </div>\
                               <iframe class="rapportframe" name="rapportframe" style="width:100%;"></iframe>\
                             </div>\
                         </div>\
                       </div>\
                     </div>\
                   </div>\
               </div>');
$(".bs-modal-frame-sm-btn").trigger("click");
window.open(this.lien_fiche_ExpST , "rapportframe")?.focus();
$(".rapportframe").css("display", "none");
$('.rapportframe').on('load', function(){
   $(".waiting").css("display", "none");
   $(".rapportframe").css("display", "initial");
});
}


}
