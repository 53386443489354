export class AGENT {
  public idAgent: number = 0;
  public nomAgent: string = "";
  public contact: string = "";
  public creerPar: number = 0;
  public creerLe: string = "";
  public modifierPar: number = 0;
  public modifierLe: string = "";
  
  constructor() {
  }

}
