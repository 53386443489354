<div class="x_panel x_panel_close">
    <div class="x_content">
        <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;"><span ><i  class="fa fa-arrow-left"></i></span>
        	<div  class="ml-auto text-right"><span class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span>
        		<i  class="fa fa-chevron-right"></i>
        		<span class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Import Facturation Conteneur - Liste Facture</span>
         	</div>
        </div>
        <br>
        <div class="col-md-12">
            <div class="content-action">
              <ul class="nav nav-tabs bar_tabs" id="myTab" role="tablist">

                <li class="nav-item">
                   <a class="nav-link" id="profile-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='profile'}"
                       (click)="search('profile')"
                       role="tab" aria-controls="profile" aria-selected="false">Liste Facture Conteneur</a>
                 </li>
              </ul>

                <div class="tab-content" id="myTabContent">

                    <div class="tab-pane fade" id="profile" role="tabpanel"  aria-labelledby="profile-tab"
                         [ngClass]="{'show':activeTab==='profile', 'active':activeTab==='profile'}">
                         <br>
                        <div class="">
                            <div class="recherche_liste">
                                <div class="row">
                                    <div class="form-group col-xs-12 col-md-4">
                                      <label for="">Armateur</label>
                                      <select class="form-control form-control-sm" [(ngModel)]="recherche.amateur1" id="gg"  (ngModelChange)="rechercheFacture(recherche)">
                                       <option value=""></option>
                                       <option *ngFor="let i of amateur" value="{{i.nomAmateur}}">{{i.nomAmateur}}</option>
                                      </select>



                                      </div>
                                      <div class="form-group col-xs-12 col-md-4">
                                          <label>Periode Du</label>
                                          <input name="recherche" type="date" class="form-control form-control-sm"
                                                 [(ngModel)]="recherche.dateDebut" (ngModelChange)="rechercheFacture(recherche)">
                                      </div>

                                      <div class="form-group col-xs-12 col-md-4">
                                          <label>Au</label>
                                          <input name="recherche" type="date" class="form-control form-control-sm"
                                                 [(ngModel)]="recherche.dateFin" (ngModelChange)="rechercheFacture(recherche)">
                                      </div>




                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="Table_Longer">
                            <table class="table_bloc_liste">
                             <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Date Facture</th>
                                    <th>Armateur</th>
                                    <th>Periode Facture</th>
                                    <th>Créer Par</th>
                                    <th>Créer Le</th>
                                    <th>Modifier Par</th>
                                    <th>Modifier Le</th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr  *ngFor="let i of listeConteneursfacturer">
                                        <td>{{i.IDFactureConteneur}}</td>
                                    <td>{{i.DateFacture}}</td>
                                    <td>{{i.nomAmateur}}</td>
                                      <td>{{i.periodefacture}}</td>
                                    <td>{{i.nom}}</td>
                                    <td>{{i.creerLe}}</td>
                                    <td>{{i.modifierPar}}</td>
                                    <td>{{i.modifierLe}}</td>

                                    <td class="text-center">
                                        <button  class="btn btn-warning btn-sm" (click)="Modifier(i.IDFactureConteneur)">
                                           <i class="fas fa-pencil-alt"></i>
                                        </button>
                                    </td>
                                    <td class="text-center">
                                        <button  class="btn btn-danger btn-sm" (click)="SupprimerPopup(i.IDFactureConteneur)">
                                            <i class="far fa-trash-alt"></i>
                                        </button>
                                    </td>
                                    <td class="text-center">
                                        <button  class="btn btn-success btn-sm" (click)="Exporter1(i.IDFactureConteneur)">
                                           <i class="fas fa-file-excel"></i>
                                        </button>
                                    </td>
                                    <td class="text-center">
                                        <button  class="btn btn-warning btn-sm" (click)="ConfirmationImpressionFormulaire1(i.IDFactureConteneur)">
                                           <i class="fas fa-print"></i>
                                        </button>
                                    </td>
                                  </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="row">
                              <div  class="col-md-6 form-group"></div>
                              <div  class="col-md-3 form-group">
                                <div  class="form-group">
                                  <label >&nbsp;</label>
                                  <button  class="btn btn-success btn-block btn-sm" (click)="Exporter()">
                                    <i  class="fas fa-excel"></i>&nbsp;Exporter</button>
                                </div>
                              </div>
                              <div  class="col-md-3 form-group">
                                <div  class="form-group">
                                  <label >&nbsp;</label>
                                  <button  class="btn btn-warning btn-block btn-sm" (click)="ConfirmationImpressionFormulaire()"><i  class="fas fa-print"></i>&nbsp;Imprimer</button>
                                </div>
                              </div>
                            </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<button type="button" class="btn btn-primary  btn-sm suppliste" style="display:none;" data-backdrop="static" data-toggle="modal" data-target=".bs-Form-sm"></button>
<div class="modal fade bs-Form-sm" tabindex="-1" role="dialog" aria-hidden="true">
 <div class="modal-dialog modal-sm" role="document">
  <div class="modal-content">
      <div class="row container_modal">
          <div class="modal-header">
            <h6 class="modal-title" style="font-weight: bold;">SUPPRESSION</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>
            </button>
          </div>
          <div>
             <div class="modal-content-dl">
              <p>Souhaitez-vous supprimer ce enregistrement ?</p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" data-dismiss="modal"  class="btn btn-primary btn-sm" (click)="supprimer(supp_id)">Oui</button>
            <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Non</button>
          </div>
      </div>
    </div>
  </div>
</div>
