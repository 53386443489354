import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-etat-journalier-restitution-conteneur-vide',
  templateUrl: './etat-journalier-restitution-conteneur-vide.component.html',
  styleUrls: ['./etat-journalier-restitution-conteneur-vide.component.css']
})
export class EtatJournalierRestitutionConteneurVideComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
