import { Component, OnInit } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpEventType
 } from '@angular/common/http';
 import { AuthService } from '../../../services/connexion/auth.service';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { proforma_escale } from '../../../classes/proforma_escale';
import { elt_proforma_escale } from '../../../classes/elt_proforma_escale';
import { fournisseur } from '../../../classes/fournisseur';
import { DOSSIER } from '../../../classes/Dossier';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { ConfigurationService } from '../../../config/configuration.service';
@Component({
  selector: 'app-etat-recapitulatif',
  templateUrl: './etat-recapitulatif.component.html',
  styleUrls: ['./etat-recapitulatif.component.css']
})
export class EtatRecapitulatifComponent implements OnInit {

  apiUrl:String = "";
  activeTab:String="saisir";
  listnavire:any = [];
  listproforma:any = [];
  listefournisseur:any = [];
  listeoperation:any = [];
  suppelt:any = {};
  photos_previsualisation:any = [];
  array_files:any = [];
  dataSousMenuModel:any = [];
  listeCalculer:any=[];
  total:any={};
  dossier:any={};
  recherche:any={};
  listdoss:any = [];
  ligneSelectionne:any = -1;
  precLine:any = 0;
  Difference:any = 0;
  MTTVA:any = 0;
  MTTTC:any = 0;
  MTHT:any = 0;
  MTTVA_euro:any = 0;
  MTTTC_euro:any = 0;
  MTHT_euro:any = 0;
  MTR:any = 0;
  montantRemise:any = 0;
  montantTVA:any = 0;
  montantTTC:any = 0;
  activePagination:number = 0;
  liste_pagination:any = [];
  proformaModel:proforma_escale = new proforma_escale();
  elt_proforma:elt_proforma_escale = new elt_proforma_escale();
  fournisseur:fournisseur = new fournisseur();
  DOSSIERModel:DOSSIER = new DOSSIER();

  constructor(private HttpClient : HttpClient, private AuthService : AuthService,private sanitizer:DomSanitizer, private transfertDataService : transfertDataService, private ConfigurationService : ConfigurationService) { }


  ngOnInit(): void {


  }


  Tabulation(activeTab:any): void{
    this.activeTab=activeTab;
  }
  VerificationChampsObligatoire(tableau_champs_obligatoires:any){
      var verification_formulaire = true;
      for (var i = 0; i < tableau_champs_obligatoires.length; i++) {
          if($(tableau_champs_obligatoires[i]).val() == "" || $(tableau_champs_obligatoires[i]).val() == null || $(tableau_champs_obligatoires[i]).val() == "? string: ?"){
                  $(tableau_champs_obligatoires[i]).css("border","1px solid red");
                  verification_formulaire = false;
                  document.getElementById(tableau_champs_obligatoires[i].replace("#",""))?.scrollIntoView({behavior: "smooth"});
                  break;
          }else{
              $(tableau_champs_obligatoires[i]).css("border","");
          }
      }

      return verification_formulaire;
  }

  CalculHT(): void{

console.log(this.dataSousMenuModel);
this.dataSousMenuModel.forEach((element : any) => {
  console.log(element);
});
/*  this.dataSousMenuModel.forEach((element : any) => {
    element.Difference =element.montantfacture - element.montantproforma;
    console.log(element.montantfacture);
    console.log(element.montantproforma);
    console.log(element.Difference);



  });*/






  }
  ListeAutoCompleteDossier(numerodossier:any){
   this.apiUrl = "SERVER/GestionCompteEscale/etat/recherche_dossier";
      var data = {numeroDossier:numerodossier};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listdoss= value.listdoss;

        console.log(value);

      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }
  SelectionAutocomplete(data:any): void{
console.log(data);
    this.DOSSIERModel.numeroDossier = data.numeroDossier;
    this.DOSSIERModel.idGestionNavire = data.idGestionNavire;
    this.DOSSIERModel.Navire = data.Navire;
    this.proformaModel.id_dossier = data.idGestionNavire;
    this.dataSousMenuModel= data.liste;
    this.listeCalculer= data.montantTTC;
    this.CalculHT();

  }


  reInitSearchForm(): void{
    this.listdoss = [];
      this.dataSousMenuModel= [];
  }

  reInitSearchBlurForm(): void{
    setTimeout((val:any) => {
        this.listdoss = [];
          this.dataSousMenuModel= [];
    }, 500);
  }









      Exporter(): void{
          var lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/GestionCompteEscale/excel_etat.php?id="+this.DOSSIERModel.idGestionNavire;
            window.open(lien_fiche_ExpST , '_blank')?.focus();
      }
      ConfirmationImpressionFormulaire(){
         var lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/GestionCompteEscale/pdfetat.php?id="+this.DOSSIERModel.idGestionNavire;
       $("#printRapport").remove();
         $("body").append('<div id="printRapport">\
                             <div class="col-md-12 form-group" style="display:none;">\
                               <button class="btn btn-primary btn-sm bs-modal-frame-sm-btn" data-backdrop="static" data-toggle="modal" data-target=".bs-modal-frame-sm"></button>\
                             </div>\
                             <div class="modal fade bs-modal-frame-sm" tabindex="-1" role="dialog" aria-hidden="true">\
                               <div class="modal-dialog" role="document">\
                                   <div class="modal-content">\
                                       <div class="row container_modal" style="width:100%;">\
                                       <div class="modal-header" style="margin-bottom:0px;">\
                                         <h6 class="modal-title" style="font-weight: bold;">ETAT RECAPITULATIF COMPTE ESCALE</h6>\
                                         <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>\
                                         </button>\
                                       </div>\
                                       <div class="row col-md-12" style="width:100%;height: calc(100vh - 90px);padding-left: 0px; padding-right: 0px; margin-left: 0px; margin-right: 0px;">\
                                         <div class="col-md-12 waiting">\
                                           <div class="snippet" data-title=".dot-pulse">\
                                             <div class="stage">\
                                               <div class="dot-pulse"></div>\
                                             </div>\
                                           </div>\
                                         </div>\
                                         <iframe class="rapportframe" name="rapportframe" style="width:100%;"></iframe>\
                                       </div>\
                                   </div>\
                                 </div>\
                               </div>\
                             </div>\
                         </div>');
         $(".bs-modal-frame-sm-btn").trigger("click");
         window.open(lien_fiche_ExpST , "rapportframe")?.focus();
         $(".rapportframe").css("display", "none");
         $('.rapportframe').on('load', function(){
             $(".waiting").css("display", "none");
             $(".rapportframe").css("display", "initial");
         });
       }



}
