import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { ConfigurationService } from '../../../config/configuration.service';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
@Component({
  selector: 'app-etat-dechargement',
  templateUrl: './etat-dechargement.component.html',
  styleUrls: ['./etat-dechargement.component.css']
})
export class EtatDechargementComponent implements OnInit {
  activeTab:String="saisir";
  apiUrl:any="";
  listesuividechargement:any = [];
  info_dossier:any = [];
  recherche:any = {};
  dossier:any = {};
  listdoss:any = [];
  TotalPoidPrevu:any;
  TotalPoidDecharge:any;
  Poids_restant:any;

  constructor(private transfertDataService : transfertDataService,  private ConfigurationService : ConfigurationService) { }

  ngOnInit(): void {
    
  }
  Tabulation(activeTab:any): void{
    this.activeTab=activeTab;
  }

  ListeAutoCompleteDossier(numerodossier:any){
   this.apiUrl = "SERVER/gestiondechargement/etatdechargement/recherche_dossier";
      var data = {numeroDossier:numerodossier};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listdoss= value.listdoss;
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }

  SelectionAutocomplete(data:any): void{
     this.recherche.numeroDossier = data.numeroDossier;
     this.recherche.idGestionNavire = data.idGestionNavire;
     this.Recherche(this.recherche);
  }

  Recherche(data:any): void{
      this.apiUrl = "SERVER/gestiondechargement/etatdechargement/recherche";
      var data = data;
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
      this.TotalPoidPrevu = value.PoidsPrevu;
      this.TotalPoidDecharge = value.Poids_Decharge;
      this.Poids_restant = value.Poids_restant;
      this.info_dossier= value.dechargement;
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }

  reInitSearchForm(): void{
    this.listdoss = [];
  }

  reInitSearchBlurForm(): void{
    setTimeout((val:any) => {
        this.listdoss = [];
    }, 500);
  }
  Exporter(): void{
      var lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/Dechargement/excel_etat_dechargement.php?idGestionNavire="+this.recherche.idGestionNavire;
        window.open(lien_fiche_ExpST , '_blank')?.focus();
  }

  Imprimer(): void{
    var lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/Dechargement/pdf_etat_dechargement.php?idGestionNavire="+this.recherche.idGestionNavire;
    $("#printRapport").remove();
    $("body").append('<div id="printRapport">\
                       <div class="col-md-12 form-group" style="display:none;">\
                         <button class="btn btn-primary btn-sm bs-modal-frame-sm-btn" data-backdrop="static" data-toggle="modal" data-target=".bs-modal-frame-sm"></button>\
                       </div>\
                       <div class="modal fade bs-modal-frame-sm" tabindex="-1" role="dialog" aria-hidden="true">\
                         <div class="modal-dialog" role="document">\
                             <div class="modal-content">\
                                 <div class="row container_modal">\
                                 <div class="modal-header" style="margin-bottom:0px;">\
                                   <h6 class="modal-title" style="font-weight: bold;">ETAT DECHARGEMENTS</h6>\
                                   <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>\
                                   </button>\
                                 </div>\
                                 <div class="row col-md-12" style="width:100%;height: calc(100vh - 90px);padding-left: 0px; padding-right: 0px; margin-left: 0px; margin-right: 0px;">\
                                   <div class="col-md-12 waiting" style="width: 450px;">\
                                     <div class="snippet" data-title=".dot-pulse">\
                                       <div class="stage">\
                                         <div class="dot-pulse"></div>\
                                       </div>\
                                     </div>\
                                   </div>\
                                   <iframe class="rapportframe" name="rapportframe" style="width:100%;"></iframe>\
                                 </div>\
                             </div>\
                           </div>\
                         </div>\
                       </div>\
                   </div>');
    $(".bs-modal-frame-sm-btn").trigger("click");
    window.open(lien_fiche_ExpST , "rapportframe")?.focus();
    $(".rapportframe").css("display", "none");
    $('.rapportframe').on('load', function(){
       $(".waiting").css("display", "none");
       $(".rapportframe").css("display", "initial");
    });
  }
}
