<div class="x_panel x_panel_close">
	    <div class="x_content">
	    	<div  class="d-flex no-block align-items-center" style="margin: 5px 10px;"><span ><i  class="fa fa-arrow-left"></i></span><div  class="ml-auto text-right"><span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span><i  class="fa fa-chevron-right"></i><span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Gestions clients
              </span>
             </div>
            </div>
	    	   <div class="">
					        <div class="content-action">
					             <ul class="nav nav-tabs bar_tabs" id="myTab" role="tablist">
					                      <li class="nav-item">
					                        <a class="nav-link active" id="saisir-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='saisir'}" (click)="search('saisir')"
					                           role="tab" aria-controls="saisir" aria-selected="true">Saisir Nouveau Client</a>
					                      </li>
					                      <li class="nav-item">
					                        <a class="nav-link" id="profile-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='profile'}" (click)="search('profile')"
					                            role="tab" aria-controls="profile" aria-selected="false">Liste des Clients</a>
					                      </li>
					                </ul>
					                <div class="tab-content" id="myTabContent">
					                    <div [ngClass]="{ 'show':activeTab==='saisir', 'active':activeTab==='saisir'}"
								        class="tab-pane fade active show" id="saisir" role="tabpanel" aria-labelledby="saisir-tab">

								          <br>
								          	<div class="cadreformulaire">
                                                <div class="row">
                                                	  <div  class="form-group col-md-6">
		                                             	<label  for="exampleFormControlSelect1">
		                                             		<font style="vertical-align: inherit;">
		                                             			<font style="vertical-align: inherit;">Nom Société*</font>
		                                             		</font>
		                                             	</label>
		                                             	<input type="text" placeholder="Nom Société" name="nomSociete" id="nomSociete"  [(ngModel)]="ClientModel.nom_societe"class="form-control form-control-sm">

		                                             </div>
		                                             <div class="form-group col-md-6">
			                                             	<label>
			                                             		<font style="vertical-align: inherit;">
			                                             			<font style="vertical-align: inherit;">Responsable</font>
			                                             		</font>
			                                             	</label>
			                                             	<input type="text" placeholder="Responsable" name="responsable" [(ngModel)]="ClientModel.responsable" class="form-control form-control-sm">
		                                             </div>
		                                             <div  class="form-group col-md-6">
			                                             <label>
			                                             		<font style="vertical-align: inherit;">
			                                             			<font style="vertical-align: inherit;">Téléphone*</font>
			                                             		</font>
			                                             	</label>
			                                             	<input type="text" placeholder="Téléphone" name="telephoneSociete" id="telephoneSociete" [(ngModel)]="ClientModel.telephone" class="form-control form-control-sm">
		                                             </div>
		                                             <div class="form-group col-md-6">

		                                             	<label>
			                                             		<font style="vertical-align: inherit;">
			                                             			<font style="vertical-align: inherit;">Adresse</font>
			                                             		</font>
			                                             	</label>
			                                             	<input type="text" placeholder="Adresse" name="adresse" [(ngModel)]="ClientModel.adresse" class="form-control form-control-sm">

		                                             </div>

		                                             <div  class="form-group col-md-6">
			                                             <label>
			                                             		<font style="vertical-align: inherit;">
			                                             			<font style="vertical-align: inherit;">Numéro Contribuable</font>
			                                             		</font>
			                                             	</label>
			                                             	<input type="text" placeholder="Numéro Contribuable"  name="numero_compte" [(ngModel)]="ClientModel.numero_compte"class="form-control form-control-sm">
		                                             </div>
		                                             <div class="form-group col-md-6">

		                                             	<label>
			                                             		<font style="vertical-align: inherit;">
			                                             			<font style="vertical-align: inherit;">Email</font>
			                                             		</font>
			                                             	</label>
			                                             	<input type="text" placeholder="Email"  name="mail" [(ngModel)]="ClientModel.mail" class="form-control form-control-sm">

		                                             </div>
		                                             <div class="col-md-12" *ngIf="duplicateProduit == true">
			                                             	<div class="alert alert-danger alert-dismissible fade show" role="alert">
																<strong>Alerte!</strong> {{message_error}}
																	<button type="button" class="close" data-dismiss="alert" aria-label="Close">
																    <span aria-hidden="true">&times;</span>
																  </button>
																</div>
			                                            </div>
		                                             <div class="col-md-12">
		                                             		<div class="text-right-btn">
				                                              <button type="button" class="btn btn-primary btn-sm btn-enregistrer" (click)="enregistrer(ClientModel,$event)">Enregistrer</button>&nbsp;
				                                              <button type="button" class="btn btn-secondary btn-sm btn-annuler" (click)="Annuler()">Annuler</button>&nbsp;
				                                            </div>
		                                             </div>
                                                </div>
                                            </div>




					                    </div>

					                 <div class="tab-pane fade" id="profile" role="tabpanel"  aria-labelledby="profile-tab" [ngClass]="{'show':activeTab==='profile', 'active':activeTab==='profile'}">

					                 	<br>
                                         <div class="">
								                  <div class="">
								                    <div class="x_content">
								                      <div class="form-group col-xs-12 col-md-12 recherche_liste">
								                         <div class="row">
								                          <div class="form-group col-xs-12 col-md-4">
								                                  <div  class="form-group">
										                                <label>
								                                     		<font style="vertical-align: inherit;">
								                                     			<font style="vertical-align: inherit;">Rechercher par</font>
								                                     		</font>
								                                     	</label>
										                                <input type="text" placeholder="Nom Société" name="om_societe" [(ngModel)]="recherche.nom_societe" (ngModelChange)="rechercheClient(recherche)" class="form-control form-control-sm">
									                              </div>
								                          </div>

								                          <div class="form-group col-xs-12 col-md-4">
								                                  <div  class="form-group">
										                                <label>
								                                     		<font style="vertical-align: inherit;">
								                                     			<font style="vertical-align: inherit;"></font>
								                                     		</font>
								                                     	</label>
										                                <input type="text" placeholder="Téléphone"  name="telephonec" [(ngModel)]="recherche.telephone" (ngModelChange)="rechercheClient(recherche)"class="form-control form-control-sm">
									                              </div>
								                          </div>
                                                        </div>
								                      </div>
																			<div class="">
																			<div class="Table_Longer1">
																				 <table class="table_bloc_liste table_responsive">
																							<thead>
																								<tr>
															                                    <th>#</th>
															                                    <th>
															                                        Nom société
															                                    </th>
															                                    <th>
															                                        Responsable
															                                    </th>
															                                    <th>
															                                        Téléphone
															                                    </th>
															                                    <th>
															                                        Adresse
															                                    </th>
															                                    <th>
															                                        N°Compte contribuable
															                                    </th>
																																	<th>Créer Par</th>
																																	<th>Créer Le</th>
																																	<th>Modifier Par</th>
																																	<th>Modifier Le</th>
															                                    <th>
															                                    </th>
															                                    <th>
															                                    </th>
															                                </tr>
																							</thead>
																							<tbody>
																									 <tr *ngFor="let x of listeclient_data">
																											<td>{{x.idClient}}</td>
																											<td>{{x.nom_societe}}</td>
																											<td>{{x.responsable}}</td>
																											<td>{{x.telephone}}</td>
																											<td>{{x.adresse}}</td>
																											<td>{{x.numero_compte}}</td>
																											<td>{{x.nom}}</td>
																											<td>{{x.date}}</td>
																											<td>{{x.modifierpar}}</td>
																											<td>{{x.datemodification}}</td>
																											<td class="text-center">
												                                <button  class="btn btn-success btn-sm" (click)="information(x.idClient)">
												                                  <i class="fas fa-pencil-alt"></i>
												                                </button>
												                              </td>
												                              <td class="text-center">
												                                <button  class="btn btn-danger btn-sm" (click)="supprimer(x.idClient)">
												                                   <i class="far fa-trash-alt"></i>
												                                </button>
												                              </td>
																									 </tr>
																							 </tbody>
																					 </table>
																			</div>
																	    </div>


								                    </div>
								                  </div>
                                          </div>

					                 </div>

								    </div>
						      </div>
						</div>

	    </div>
</div>
