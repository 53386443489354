export class produit {
  public idProduit: number = 0;
  public numProduit: string = "";
  public qteInit: number = 0;
  public prix: number = 0;
  public idfournisseur: number = 0;
  public NomProd: string = "";
  public description: string = "";
  public dateCreation: string = "";
  public idUser: string = "";
  public modifierpar: number = 0;
  public datemodification: string = "";
  constructor() {

  }

}
