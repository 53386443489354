export class proforma_escale {

  public id_proforma: number = 0;
  public id_dossier: number = 0;
  public date_proforma: string = "";
  public montant_ht: number = 0;
  public montant_tva: number = 0;
  public montant_ttc: number = 0;
  public montant_ht_ero: number = 0;
  public montant_tva_ero: number = 0;
  public montant_ttc_ero: number = 0;
  public date_creation: string = "";
  public id_user: number = 0;
  public idfournisseur: number = 0;
  public type_activite: string = "";
  public tauxEuro: number = 0;
  public datemodification: string = "";
  public modifierpar: number = 0;
  constructor() {

  }

}
