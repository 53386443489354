import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { LIEUPOSITION } from '../../../classes/lieu_position';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { AuthService } from '../../../services/connexion/auth.service';

@Component({
  selector: 'app-gp-position-navire',
  templateUrl: './gp-position-navire.component.html',
  styleUrls: ['./gp-position-navire.component.css']
})
export class GpPositionNavireComponent implements OnInit {

  activeTab:String="saisir";
  apiUrl:String = "";
  listepositionnavire:any = [];
  recherche:any = {};
  supp_id:any = "";
  Libpositionnavire:LIEUPOSITION = new LIEUPOSITION();
  constructor(private transfertDataService : transfertDataService,private AuthService : AuthService) { }

  ngOnInit(): void {
    this.Liste_des_positionnavire();
  }
  search(activeTab:String){
     this.activeTab=activeTab
   };


     Annuler():void{
        this.Libpositionnavire = new LIEUPOSITION();
        this.recherche.lieu_position = "";
       }

    enregistrer(data:any): void{

       if(this.Libpositionnavire.id == 0)
         this.apiUrl = "SERVER/parametres/positionnavire/enregistrer";
       else
         this.apiUrl = "SERVER/parametres/positionnavire/modifier";

         var infoUser = this.AuthService.getInfoUtilisateur();
          this.Libpositionnavire.idUser = infoUser.id;
         var d = {"position_navire": this.Libpositionnavire};
         this.transfertDataService.EnvoiData(d, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((value:any) => {
           this.Liste_des_positionnavire();
           this.search("profile");
           this.Annuler();
         }, (erreur:any) => {
         })

     }

    supprimer(idPositionNavire:any):void{
       this.apiUrl = "SERVER/parametres/positionnavire/supprimer";
       var data = {idPositionNavire:idPositionNavire};
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
     ).subscribe((value:any) => {
         this.Liste_des_positionnavire();
       }, (erreur:any) => {
       })

     }


     Liste_des_positionnavire(): void{

       this.apiUrl = "SERVER/parametres/positionnavire/listepositionnavire";
       var data = {};
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
           retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((response:any) => {
         this.listepositionnavire = response.liste_positionnavire;
       }, (erreur:any) => {
       })

     }

     information(idPositionNavire:any):void{

           this.apiUrl = "SERVER/parametres/positionnavire/information";
           var data = {id:idPositionNavire};
           this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((response:any) => {
               this.Libpositionnavire = response.position_navire[0];
               this.search("saisir");

           }, (erreur:any) => {
         })

     }

     recherchepositionnavire(data:any): void{
       if(this.recherche.lieu_position == "")
       this.Liste_des_positionnavire();
       else{
         this.apiUrl = "SERVER/parametres/positionnavire/recherche";
         var data = data;
         this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
           retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((value:any) => {
           this.listepositionnavire= value.liste_positionnavire;
         }, (erreur:any) => {
         })
       }

   }

    SupprimerPopup(id:any): void{
    $(".suppliste").trigger("click");
    this.supp_id = id;
  }

}
