export class CONTENEURIMPORT {
  public idConteneurImport: number = 0;
  public idAmateur: number = 0;
  public idCamion: number = 0;
  public idChauffeur: number = 0;
  public idAgent: number = 0;
  public numBL: string = "";
  public idClient: number = 0;
  public idtransitaire: number = 0;
  public numConteneur: string = "";
  public taille: string = "";
  public type: string = "";
  public badi: string = "";
  public idlieuLivraison: number = 0;
  public idmodeLivraison: number = 0;
  public dateEntreLTA: string = "";
  public dateReception: string = "";
  public dateRequette: string = "";
  public chauffeurReception: string = "";
  public idMouvementConteneur: number = 0;
  public dateMVT: string = "";
  public shiftReception: string = "";
  public camionReception: string = "";
  public eir: string = "";
  public observation: string = "";
  public rl: string = "";
  public dateLivraison: string = "";
  public do: string = "";
  public numDeclaration: string = "";
  public contactClient: string = "";
  public idConteneurTraite: number = 0;
  public IDFactureConteneur: number = 0;
  public traite: number = 0;
  public facturer: number = 0;
  public creerPar: number = 0;
  public retourCreerPar: number = 0;
  public retourModifierPar: number = 0;
  public creerLe: string = "";
  public modifierPar: number = 0;
  public modifierLe: string = "";

  constructor() {
  }

}
