export class CAMION {
  public idCamion: number = 0;
  public marque: string = "";
  public modele: string = "";
  public immat: string = "";
  public creerPar: number = 0;
  public creerLe: string = "";
  public modifierPar: number = 0;
  public modifierLe: string = "";
  
  constructor() {
  }

}
