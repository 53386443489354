import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { pipe } from 'rxjs';
import { PRODUIT } from '../../../classes/produit';
import { PRODUITMAG } from '../../../classes/produit_magasin';
import { MAGASIN } from '../../../classes/magasin';
import { DOSSIER } from '../../../classes/Dossier';
import { PRODUITDISPO } from '../../../classes/produit_dispo_magasin';
import { MVT_PRODUIT } from '../../../classes/mvt_produit_magasin';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { AuthService } from '../../../services/connexion/auth.service';
import {DomSanitizer} from '@angular/platform-browser';
@Component({
  selector: 'app-stock-initial-magasins',
  templateUrl: './stock-initial-magasins.component.html',
  styleUrls: ['./stock-initial-magasins.component.css']
})
export class StockInitialMagasinsComponent implements OnInit {
  apiUrl:String = "";
  activeTab:String="saisir";
  dossier:any = "";
  listauto:any = [];
  dossierAuto:any={};
  produitAuto:any={};
  liste_magasins:any = [];
  listautorech:any = [];
  liste_prodmagasins:any = [];
  listautoSpecifik:any = [];
  recherche:any={};
  liste_Clients:any = [];
  liste_specifi:any = [];
  listeClientsDossier:any = [];

  photos_previsualisation:any = [];
  array_files:any = [];

  produitmagModel:PRODUITMAG = new PRODUITMAG();
  produitdispoModel:PRODUITDISPO = new PRODUITDISPO();
  magasinModel:MAGASIN = new MAGASIN();
  dossierModel:DOSSIER = new DOSSIER();
  prduitModel:PRODUIT = new PRODUIT();
  mvtModel:MVT_PRODUIT = new MVT_PRODUIT();
  constructor(private sanitizer:DomSanitizer, private transfertDataService : transfertDataService, private AuthService : AuthService) { }

  ngOnInit(): void {
    this.listeProduitmag();
    this.recherche.numDossier="";
    this.recherche.nom_societe="";
    this.recherche.nom_produit_magasin="";
    this.recherche.NomMagasin="";
    this.listemagasin();
    //this.listeClient();
    //this.listeprodui();
  }

  search(activeTab:String){
     this.activeTab=activeTab;
   };


     Annuler(){
       this.produitmagModel = new PRODUITMAG();
       this.dossierModel = new DOSSIER();
       this.prduitModel = new PRODUIT();
       this.magasinModel = new MAGASIN();
       this.produitdispoModel= new PRODUITDISPO();
       this.mvtModel= new MVT_PRODUIT();
       this.dossierAuto = {};
       this.produitAuto = {};
     }

     ListeAutoClientDossier(iddossier:any){
      this.apiUrl = "SERVER/gestionmagasin/stockinitialmagasin/ClientDossier";
         var data = {iddossier:iddossier};
         this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
           retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((value:any) => {
           this.listeClientsDossier= value.listeClientsDossier;
         }, (erreur:any) => {
             console.log(erreur.status);
         })
     }

     ListeAutoCompleteDossier(numDoss:any){
      this.apiUrl = "SERVER/gestionmagasin/stockinitialmagasin/recherche_magasin";
         var data = {NumDoss:numDoss};
         this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
           retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((value:any) => {
           this.listauto= value.listAuto;
         }, (erreur:any) => {
             console.log(erreur.status);
         })
     }



     SelectionAutocomplete(data:any): void{
       this.dossierAuto.iddossier= data.idGestionNavire;
       this.dossierAuto.numDossier= data.numeroDossier;
       this.dossierAuto.numeroDossier= data.numeroDossier;
       this.dossierAuto.NomCompagnie = data.Navire;

       this.ListeAutoClientDossier(this.dossierAuto.iddossier);

     }

     ListeAutoCompleteProduit(nomSousProduit:any){


      this.apiUrl = "SERVER/gestionmagasin/stockinitialmagasin/recherche_produit";
         var data = {nom:nomSousProduit, idclient:this.dossierAuto.idClient};

         this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
           retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((value:any) => {
           this.listautoSpecifik= value.listautoSpecifiks;
         }, (erreur:any) => {
             console.log(erreur.status);
         })
     }

     SelectionAutocompleteProduit(data:any): void{
       this.produitAuto.idProduit= data.idProduit;
       this.produitAuto.nomSous= data.nomSousProduit;
       this.produitAuto.nomSousProduit= data.nomSousProduit;
       this.produitAuto.nom_societe = data.nom_societe;
       this.produitAuto.poids = data.poids;
       this.produitAuto.idClient =data.idClient;
       this.produitAuto.idSousProduit=data.idSousProduit;
     }



       listemagasin() : void{

         this.apiUrl = "SERVER/gestionmagasin/stockinitialmagasin/listemagasin";
         var data = {};
         this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((response:any) => {
           this.liste_magasins = response.liste_magasin;
         }, (erreur:any) => {
             console.log(erreur.status);
         })

       }

       PoidsTotalCalcule() : void{
           this.produitdispoModel.poids_dispo = ((this.produitAuto.poids /1000) * this.produitdispoModel.qte_dispo);
       }

       //Fonction de verification de champs
        VerificationChampsObligatoire(tableau_champs_obligatoires:any){
            var verification_formulaire = true;
            for (var i = 0; i < tableau_champs_obligatoires.length; i++) {
                if($(tableau_champs_obligatoires[i]).val() == "" || $(tableau_champs_obligatoires[i]).val() == 0 ||  $(tableau_champs_obligatoires[i]).val() == null || $(tableau_champs_obligatoires[i]).val() == "? string: ?"){
                        $(tableau_champs_obligatoires[i]).css("border","1px solid red");
                        verification_formulaire = false;
                        document.getElementById(tableau_champs_obligatoires[i].replace("#",""))?.scrollIntoView({behavior: "smooth"});
                        break;
                }else{
                    $(tableau_champs_obligatoires[i]).css("border","");
                }
            }

            return verification_formulaire;
        }




       Enregistrer(): void{
         if(this.VerificationChampsObligatoire(["#magasinEntreeInit","#numeroDossierStockInit", "#listeclientDoss", "#qte_dispo", "#poids_dispo", "#date_mvt"]) == false)
                return;

         if(this.produitmagModel.id_produit_magasin == 0){
           this.apiUrl = "SERVER/gestionmagasin/stockinitialmagasin/enregistrer";
         }else{
           this.apiUrl = "SERVER/gestionmagasin/stockinitialmagasin/modifier";
         }

        

          this.produitmagModel = this.dossierAuto;
          this.produitmagModel.id_client = this.produitAuto.idClient;
          this.produitmagModel.id_sous_produit = this.produitAuto.idSousProduit;
          this.produitmagModel.nom_produit_magasin = this.produitAuto.nomSousProduit;
          this.produitmagModel.poids_unitaire = this.produitAuto.poids;
          this.produitmagModel.date_creation = "";
          var infoUser = this.AuthService.getInfoUtilisateur();
          this.produitmagModel.iduser = infoUser.id;
         var d = {"produitmag": this.produitmagModel,"idMagasin": this.magasinModel.idMagasin,"poids_dispo": this.produitdispoModel.poids_dispo,
                "qte_dispo": this.produitdispoModel.qte_dispo,"date_mvt":this.mvtModel.date_mvt,"clientapprovisionne":this.produitAuto.nom_societe};


         this.transfertDataService.EnvoiData(d, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((value:any) => {
           this.listeProduitmag();
           this.Annuler();
           this.search("profile");
         }, (erreur:any) => {
             
         })
       }

       listeProduitmag(): void{
         this.apiUrl = "SERVER/gestionmagasin/stockinitialmagasin/listeproduitmag";
         var data = {};
         this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((response:any) => {
           this.liste_prodmagasins = response.liste_prodmagasins;
         }, (erreur:any) => {
             console.log(erreur.status);
         })
       }

       autocompleteProduitClient(id_client:any){


                  this.apiUrl = "SERVER/gestionmagasin/stockinitialmagasin/listeautocompleteProduitClient";
                  var data = {id_client:id_client};


                  this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
                      retryWhen(error =>
                        error.pipe(
                          scan((acc:any, error:any) => {
                            if (acc > 2) throw error;
                            return acc + 1;
                          }, 1),
                          delay(1000)
                        )
                      )
                  ).subscribe((response:any) => {
                    this.liste_specifi = response.listeProduitClient;

                  }, (erreur:any) => {
                      console.log(erreur.status);
                  })

                  this.Recherche(this.recherche);

       }
       listeClient() : void{

         this.apiUrl = "SERVER/gestionmagasin/stockinitialmagasin/listeclient";
         var data = {iddossier:this.recherche.iddossier};


         this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((response:any) => {
           this.liste_Clients = response.liste_client;


           this.listeprodui();
         }, (erreur:any) => {
             console.log(erreur.status);
         })

       }


       listeprodui() : void{

         this.apiUrl = "SERVER/gestionmagasin/stockinitialmagasin/listeprodmag";
         var data = {idClient:this.recherche.idClient};

         this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((response:any) => {
           this.liste_specifi = response.listeprodmag;
           console.log(response);

         }, (erreur:any) => {
             console.log(erreur.status);
         })

       }
        AfficherDonner(id_produit_magasin:any): void{
           this.apiUrl = "SERVER/gestionmagasin/stockinitialmagasin/information";
           var data = {id:id_produit_magasin};
           this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((response:any) => {
               this.produitmagModel = response.listeproduitmagModel[0];
               this.dossierAuto.numDossier= response.listeproduitmagModel[0].numeroDossier;
               this.dossierAuto.iddossier= response.listeproduitmagModel[0].iddossier;
               this.magasinModel.idMagasin= response.listeproduitmagModel[0].id_magasin;
               this.dossierAuto.id_produit_magasin= response.listeproduitmagModel[0].id_produit_magasin;
               this.dossierAuto.NomCompagnie= response.listeproduitmagModel[0].Navire;
               this.produitAuto.nomSous= response.listeproduitmagModel[0].nom_produit_magasin;
               this.produitAuto.nom_societe= response.listeproduitmagModel[0].nom_societe;
               this.produitdispoModel.qte_dispo= response.listeproduitmagModel[0].qte_Initial;
               this.produitdispoModel.poids_dispo= response.listeproduitmagModel[0].poids_Initial;
               this.mvtModel.date_mvt= response.listeproduitmagModel[0].date_mvt;

               this.produitAuto.idClient = response.listeproduitmagModel[0].id_client;
              this.produitAuto.idSousProduit = response.listeproduitmagModel[0].id_sous_produit;
              this.produitAuto.nomSousProduit = response.listeproduitmagModel[0].nom_produit_magasin;
              this.produitAuto.poids = response.listeproduitmagModel[0].poids_unitaire;
              this.produitAuto.date_creation = "";

              this.ListeAutoClientDossier(this.dossierAuto.iddossier);

               this.search("saisir");
           }, (erreur:any) => {
             console.log(erreur.status);
         })
       }

       Supprimer(id_produit_magasin:any): void{
           this.apiUrl = "SERVER/gestionmagasin/stockinitialmagasin/supprimer";
           var data = {id:id_produit_magasin};
           this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((value:any) => {
             this.listeProduitmag();
           }, (erreur:any) => {
               console.log(erreur.status);
           })
       }

       Recherche(data:any): void{
         console.log(data);
         if(this.recherche.numeroDossier == "" && this.recherche.nom_societe == "" && this.recherche.nom_produit_magasin == "" && this.recherche.NomMagasin == "" ){
           this.listeProduitmag();
         }else{
           this.apiUrl = "SERVER/gestionmagasin/stockinitialmagasin/recherche";
           var data = data;

           this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((value:any) => {

            this.liste_prodmagasins= value.liste_prodmagasins;
           }, (erreur:any) => {
               console.log(erreur.status);
           })
         }

       }

       ListeAutoCompleteDossierrech(numDoss:any){


        this.apiUrl = "SERVER/gestionmagasin/stockinitialmagasin/recherche_magasinrech";
           var data = {NumDoss:this.recherche.numeroDossier};
           console.log(data);

           this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((value:any) => {
             this.listautorech= value.listautorech;

           }, (erreur:any) => {
               console.log(erreur.status);
           })
       }
       SelectionAutocompleterech(data:any): void{
         this.recherche.iddossier= data.iddossier;
         this.recherche.numDossier= data.numeroDossier;
         this.recherche.numeroDossier= data.numeroDossier;
         this.listeClient();
         this.Recherche(this.recherche);
         console.log(data);

       }

  apercu(iddocument:any): void{
    var element = <HTMLImageElement> $("#file_" + iddocument).get(0);
    element.requestFullscreen();
  }

  supprimer_image(nomfichier:any, iddocument:any) : void{
        if(iddocument == ""){
            for (var i = 0; i < this.array_files.length; i++) {

                if(this.array_files[i].name == nomfichier){
                    this.array_files.splice(i, 1);
                }
            }

            for (var i = 0; i < this.photos_previsualisation.length; i++) {
                if(this.photos_previsualisation[i].lien == nomfichier){
                    this.photos_previsualisation.splice(i, 1);
                }
            }
        }else{
            var apiUrl = "SERVER/gestionnavire/navire/SupprimerFile";
            var data = {id:iddocument};
            this.transfertDataService.EnvoiData(data, apiUrl).pipe(
               retryWhen(error =>
                 error.pipe(
                   scan((acc:any, error:any) => {
                     if (acc > 2) throw error;
                     return acc + 1;
                   }, 1),
                   delay(1000)
                 )
               )
            ).subscribe((value:any) => {
                for (var i = 0; i < this.photos_previsualisation.length; i++) {
                    if(this.photos_previsualisation[i].iddocument == iddocument){
                        this.photos_previsualisation.splice(i, 1);
                    }
                }
             }, (erreur:any) => {
            })
        }

  }

  onFileChanged(event: any): void {
        this.array_files = [];
        var src = URL.createObjectURL(event.target.files[0]);
        var elements = event.target.files;
        for(var i = 0; i < elements.length; i++){
          var src = URL.createObjectURL(event.target.files[i]);
          this.photos_previsualisation.push({iddocument : '', src : this.sanitizer.bypassSecurityTrustUrl(src), lien : event.target.files[i]});
          this.array_files.push(event.target.files[i]);
        }
  }
 

}
