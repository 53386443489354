export class declaration_fait_navire {
  public id_declaration_fait_navire: number = 0;
  public iddossier: string = "";
  public fait: string = "";
  public iduser: number = 0;
  public date: string = "";
  public time: string = "";
  public dateCreation: string = "";
  public ordre: string = "";
  constructor() {  
  }
  
}