import { Component, OnInit } from '@angular/core';
import { cale } from '../../../classes/cale';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { CRUDService } from '../../../services/CRUD/crud.service';

@Component({
  selector: 'app-gp-cale',
  templateUrl: './gp-cale.component.html',
  styleUrls: ['./gp-cale.component.css']
})
export class GpCaleComponent implements OnInit {
  activeTab:String="saisir";
  apiUrl:String = "";
  liste:any = [];
  recherche:any = {};
  supp_id:any = "";

  cale:cale = new cale();

  constructor(private CRUDService : CRUDService) { }

  ngOnInit(): void {
    this.listeCale();

  }

  Tabulation(activeTab:any): void{
      this.activeTab=activeTab;
  }

  search(activeTab:String){
     this.activeTab=activeTab
   };


  Annuler():void{
    this.cale = new cale();
    this.recherche.libelle = "";
  }

    enregistrer():void{
      if(this.cale.id == 0)
        var url = "SERVER/g/api/enregistrer";
      else
        var url = "SERVER/g/api/modifier";
  
      this.CRUDService.CRUD("cale", this.cale, url).subscribe((value:any) => {
          this.listeCale();
          this.Tabulation("profile");
          this.Annuler();
      }, (erreur:any) => {
  
      });
    }

    listeCale():void{
      var url = "SERVER/g/api/liste";
      this.CRUDService.CRUD("cale", this.cale, url).subscribe((value:any) => {
          this.liste = value.data;
         }, (erreur:any) => {
  
        });
    }

    AfficherCale(cale:any):void{
      this.cale = cale;
      this.Tabulation("saisir");
    }

  RechercheCale(recherche:any):void{
      if(this.recherche.libelle == "")
           this.listeCale();
      else{
        var url = "SERVER/g/api/recherche";
        this.CRUDService.CRUD("cale", this.recherche, url).subscribe((value:any) => {
            this.liste = value.data;
           }, (erreur:any) => {
          });
      }
  } 

  SupprimerPopup(id:any): void{
    $(".suppliste").trigger("click");
    this.supp_id = id;
  }

  SupprimerCale(cale:any):void{
      var url = "SERVER/g/api/supprimer";
      this.CRUDService.CRUD("cale", cale,url).subscribe((value:any) => {
          this.listeCale();
         }, 
         (erreur:any) => {
        });
    }

}
