export class elementreglementclt {
  public ideltReglementClient: number = 0;
  public date: string = "";
  public destination: string = "";
  public prixT: number = 0;
  public iddossier: number = 0;
  public tonnage: number = 0;
  public idClient: number = 0;
  public creePar: number = 0;
  public dateCreation:string = "";
  public modifierPar: number = 0;
  public dateModifier: string = "";
  constructor() {

  }

}
