import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { HttpClient, HttpHeaders, HttpEventType
 } from '@angular/common/http';
import { dechargement } from '../../../classes/Dechargement';
import { contenudecharge } from '../../../classes/Contenuedecharge';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { AuthService } from '../../../services/connexion/auth.service';
import { ConfigurationService } from '../../../config/configuration.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-saisir-dechargement',
  templateUrl: './saisir-dechargement.component.html',
  styleUrls: ['./saisir-dechargement.component.css']
})
export class SaisirDechargementComponent implements OnInit {
  activeTab:String="saisir";
  apiUrl:String = "";
  navire:any={};
  recherche:any={};
  dossier:any={};
  listdoss:any = [];
  dataSousMenuModel:any = [];
  listeposition:any = [];
  listproduit:any = [];
  Liste_dechargement_navires:any = [];
  listecales:any = [];
  id_dechargelent:any;
  idClient:any;
  liste_quai:any = [];
  liste_acconier:any = [];
  totalPoidsDossier:any = 0;
  totalQteDossier:any = 0;
  ligneSelectionne:any = -1;
  lien_fiche_ExpST:any = "";
  suppdechargement:any = {};

  photos_previsualisation:any = [];
  array_files:any = [];

  dechargementModel:dechargement = new dechargement();
  contenudechargeModel:contenudecharge = new contenudecharge();
  constructor(private HttpClient : HttpClient,private sanitizer:DomSanitizer, private transfertDataService : transfertDataService, private AuthService : AuthService,private ConfigurationService : ConfigurationService,) { }

  ngOnInit(): void {
    this.ListeQuai();
    this.ListeAcconier();
    this.listeDesCales();
    this.listeDechargement();
    this.recherche.numeroDossier = "";
    this.recherche.Navire = "";
    this.recherche.jour_nuit = "";
    this.recherche.date = "";
  }


  Tabulation(activeTab:any): void{
    this.activeTab=activeTab;
  }

  listeDesCales(){
   this.apiUrl = "SERVER/gestiondechargement/saisirdechargement/listecales";
      var data = {};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listecales = value.listecales;
      }, (erreur:any) => {

      })
  }

  apercu(iddocument:any): void{
    var element = <HTMLImageElement> $("#file_" + iddocument).get(0);
    element.requestFullscreen();
  }

  //Fonction de verification de champs
    VerificationChampsObligatoire(tableau_champs_obligatoires:any){
        var verification_formulaire = true;
        for (var i = 0; i < tableau_champs_obligatoires.length; i++) {
            if($(tableau_champs_obligatoires[i]).val() == "" || $(tableau_champs_obligatoires[i]).val() == null || $(tableau_champs_obligatoires[i]).val() == "? string: ?"){
                    $(tableau_champs_obligatoires[i]).css("border","1px solid red");
                    verification_formulaire = false;
                    document.getElementById(tableau_champs_obligatoires[i].replace("#",""))?.scrollIntoView({behavior: "smooth"});
                    break;
            }else{
                $(tableau_champs_obligatoires[i]).css("border","");
            }
        }

        return verification_formulaire;
    }


  supprimer_image(nomfichier:any, iddocument:any) : void{
        if(iddocument == ""){
            for (var i = 0; i < this.array_files.length; i++) {

                if(this.array_files[i].name == nomfichier){
                    this.array_files.splice(i, 1);
                }
            }

            for (var i = 0; i < this.photos_previsualisation.length; i++) {
                if(this.photos_previsualisation[i].lien == nomfichier){
                    this.photos_previsualisation.splice(i, 1);
                }
            }
        }else{
            var apiUrl = "SERVER/gestionnavire/navire/SupprimerFile";
            var data = {id:iddocument};
            this.transfertDataService.EnvoiData(data, apiUrl).pipe(
               retryWhen(error =>
                 error.pipe(
                   scan((acc:any, error:any) => {
                     if (acc > 2) throw error;
                     return acc + 1;
                   }, 1),
                   delay(1000)
                 )
               )
            ).subscribe((value:any) => {
                for (var i = 0; i < this.photos_previsualisation.length; i++) {
                    if(this.photos_previsualisation[i].iddocument == iddocument){
                        this.photos_previsualisation.splice(i, 1);
                    }
                }
             }, (erreur:any) => {
            })
        }

  }

  onFileChanged(event: any): void {
        this.array_files = [];
        var src = URL.createObjectURL(event.target.files[0]);
        var elements = event.target.files;
        for(var i = 0; i < elements.length; i++){
          var src = URL.createObjectURL(event.target.files[i]);
          this.photos_previsualisation.push({iddocument : '', src : this.sanitizer.bypassSecurityTrustUrl(src), lien : event.target.files[i]});
          this.array_files.push(event.target.files[i]);
        }
  }


  ajouter(): void{
    if(this.dechargementModel.id_dechargelent == 0){
      var sousmenu = new contenudecharge();
      this.dataSousMenuModel.push(sousmenu);
    }
    else{
      this.enregistrer_sous_menu();
    }

  }

  Annuler(){
    this.dechargementModel = new dechargement();
    this.dataSousMenuModel = [];
    this.array_files = [];
    this.photos_previsualisation = [];
    this.listdoss = [];
  }

  ListeAutoCompleteDossierDechargements(numerodossier:any){
   this.apiUrl = "SERVER/gestiondechargement/saisirdechargement/recherche_dechargements_dossier";
      var data = {numeroDossier:numerodossier};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listdoss= value.listdoss;
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }

  SelectionAutocomplete(data:any): void{
   this.dechargementModel.id_dechargelent = data.id_dechargelent;
   this.dechargementModel.numeroDossier = data.numeroDossier;
   this.dechargementModel.Navire = data.Navire;
   this.dechargementModel.numero_navire = data.IdNavire;
   this.dechargementModel.poids_total = data.tonnageprevu;
   this.dechargementModel.qte_total = data.total_qte;
   this.dechargementModel.iddossier = data.idGestionNavire;

   this.dechargementModel.jour_nuit = data.jour_nuit;
   this.dechargementModel.nombre_equipe = data.nombre_equipe;
   this.dechargementModel.num_mini = data.num_mini;
   this.dechargementModel.quai = data.quai;
   this.dechargementModel.debut_operation = data.debut_operation;
   this.dechargementModel.acconier = data.acconier;

   this.dechargementModel.tonnage_cale1 = data.tonnage_cale1;
   this.dechargementModel.tonnage_cale2 = data.tonnage_cale2;
   this.dechargementModel.tonnage_cale3 = data.tonnage_cale3;
   this.dechargementModel.tonnage_cale4 = data.tonnage_cale4;
   this.dechargementModel.tonnage_cale5 = data.tonnage_cale5;

   this.dechargementModel.superviseur = data.superviseur;
   this.dechargementModel.expert_client = data.expert_client;
   this.dechargementModel.observation = data.observation;

   this.ListeAutoCompleteProd("");
  }


   ListeAutoCompleteProd(prod:any){
   this.apiUrl = "SERVER/gestiondechargement/saisirdechargement/recherche_produit";
      var data = {NomSpecificProduit:prod, idGestionNavire:this.dechargementModel.iddossier};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listproduit = value.listproduit;
        console.log(this.listproduit);
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }

  SelectionAutocompleteProd(data:any, datax:any): void{
    datax.designationProduit = data.designationProduit;
    datax.idProduit = data.idProduit;
    this.idClient = data.idClient;
    datax.idClient = data.idClient;
    this.ListeCLient(this.idClient, datax);

    this.UpdateDechargementNavire(datax);
  }


  ListeCLient(clt:any, tab:any){
   this.apiUrl = "SERVER/gestiondechargement/saisirdechargement/recherche_client";
      var data = {idClient:clt};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        tab.nom_societe = value.listclient[0].nom_societe;
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }

  ListeQuai(){
   this.apiUrl = "SERVER/gestiondechargement/saisirdechargement/recherche_quai";
      var data = {};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.liste_quai = value.liste_quai;
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }


   ListeAcconier(){
   this.apiUrl = "SERVER/gestiondechargement/saisirdechargement/recherche_Acconier";
      var data = {};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.liste_acconier = value.liste_acconier;
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }


  Enregistrer(data:any, event:any): void{
    if(this.VerificationChampsObligatoire(["#numdossForm", "#jour_nuit", "#date_dechargement"]) == false)
        return;
        event.target.disabled = true;
    if(this.dechargementModel.id_dechargelent == 0){
      this.apiUrl = "SERVER/gestiondechargement/saisirdechargement/enregistrer";
    }
    else{
      this.apiUrl = "SERVER/gestiondechargement/saisirdechargement/modifier";
    }

    var infoUser = this.AuthService.getInfoUtilisateur();
    this.dechargementModel.iduser = infoUser.id;
    var d = {"dechargement": this.dechargementModel, "sousmenu" : this.dataSousMenuModel};
    d.dechargement.jour_nuit = String(d.dechargement.jour_nuit);

      var mData = JSON.stringify(d);
      var formData = new FormData();

      formData.append('data', mData);
      formData.append('typedoc', '2');
      if (this.array_files.length > 0) {
        for (var i = 0; i < this.array_files.length; i++) {
            formData.append("file["+i+"]", this.array_files[i]);
        }
      }
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data; charset=utf-8; boundary=' + Math.random().toString().substr(2));
      headers.append('Accept', 'application/json');

      this.HttpClient.post<any>(this.ConfigurationService.configuration.hostname + this.apiUrl, formData, {headers}).subscribe((resp:any) => {
          event.target.disabled = false;
          this.listeDechargement();
          this.Annuler();
          this.Tabulation("profile");
      })
  }

  downloadDoc(id_dechargelent:any){
    var apiUrl = "SERVER/gestiondechargement/saisirdechargement/zip";
      var data = {id_dechargelent:id_dechargelent};
      this.transfertDataService.EnvoiData(data, apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
      ).subscribe((value:any) => {
         window.open(this.ConfigurationService.configuration.hostname + value.link , "_self")?.focus();
       }, (erreur:any) => {
      })
  }

  SupprimerDechargementPopup(id:any): void{
    $(".suppdossier").trigger("click");
    this.suppdechargement.id = id;
  }


  ImpressionFiche(Id:any){
      this.lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/pdf_Dechargement.php?id_dechargelent="+Id;
      $("#printRapport").remove();
      $("body").append('<div id="printRapport">\
                          <div class="col-md-12 form-group" style="display:none;">\
                            <button class="btn btn-primary btn-sm bs-modal-frame-sm-btn" data-backdrop="static" data-toggle="modal" data-target=".bs-modal-frame-sm"></button>\
                          </div>\
                          <div class="modal fade bs-modal-frame-sm" tabindex="-1" role="dialog" aria-hidden="true">\
                            <div class="modal-dialog" role="document">\
                                <div class="modal-content">\
                                    <div class="row container_modal" style="width:100%;">\
                                    <div class="modal-header" style="margin-bottom:0px;">\
                                      <h6 class="modal-title" style="font-weight: bold;">IMPRESSION FICHE</h6>\
                                      <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>\
                                      </button>\
                                    </div>\
                                    <div class="row col-md-12" style="width:100%;height: calc(100vh - 90px);padding-left: 0px; padding-right: 0px; margin-left: 0px; margin-right: 0px;">\
                                      <div class="col-md-12 waiting">\
                                        <div class="snippet" data-title=".dot-pulse">\
                                          <div class="stage">\
                                            <div class="dot-pulse"></div>\
                                          </div>\
                                        </div>\
                                      </div>\
                                      <iframe class="rapportframe" name="rapportframe" style="width:100%;"></iframe>\
                                    </div>\
                                </div>\
                              </div>\
                            </div>\
                          </div>\
                      </div>');
      $(".bs-modal-frame-sm-btn").trigger("click");
      window.open(this.lien_fiche_ExpST , "rapportframe")?.focus();
      $(".rapportframe").css("display", "none");
      $('.rapportframe').on('load', function(){
          $(".waiting").css("display", "none");
          $(".rapportframe").css("display", "initial");
      });
    }


  listeDechargement(): void{
    this.apiUrl = "SERVER/gestiondechargement/saisirdechargement/listedechargement";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
      this.Liste_dechargement_navires = response.Liste_dechargement_navires;
      console.log(this.Liste_dechargement_navires);

    }, (erreur:any) => {
        console.log(erreur.status);
    })
  }

  AfficherDonner(id:any): void{
      this.apiUrl = "SERVER/gestiondechargement/saisirdechargement/information";
      var data = {id_dechargelent:id};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
          this.dechargementModel = response.listedossier[0];
          this.dataSousMenuModel = response.sousmenu;
          this.id_dechargelent = response.sousmenu.id_dechargelent;
          this.dechargementModel.poids_total = response.listedossier[0].total_poids;
          this.dechargementModel.qte_total = response.listedossier[0].total_qte;

          this.totalPoidsDossier = response.totalPoidsDossier;
          this.totalQteDossier = response.totalQteDossier;
          this.ligneSelectionne = -1;
          for(var i = 0; i < response.photos.length; i++){
            response.photos[i].src = this.ConfigurationService.configuration.ServerWebHostname + "/" + response.photos[i].src;
            this.photos_previsualisation = response.photos;
           }
          this.Tabulation("saisir");
      }, (erreur:any) => {
        console.log(erreur.status);
    })
  }

  SupprimerDechargement(): void{
      this.apiUrl = "SERVER/gestiondechargement/saisirdechargement/supprimerDechargement";
      var data = {id_dechargelent:this.suppdechargement.id};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listeDechargement();
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }

  enregistrer_sous_menu(): void{
      var infoUser = this.AuthService.getInfoUtilisateur();
      var data = {
          "idContenuNavire": 0,
          "reference": "",
          "idClient": 0,
          "idProduit": 0,
          "designationProduit": "",
          "PoidsNet": 0,
          "qte": 0,
          "iduser": infoUser.id,
          "lieu_destination": "",
          "cale": 0,
          "immat_vehicule": "",
          "id_dechargement": this.dechargementModel.id_dechargelent,
          "date": ""
      }
      this.apiUrl = "SERVER/gestiondechargement/saisirdechargement/enregistrersousmenu";


      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
      ).subscribe((value:any) => {
        this.listeSousMenuMenus();
      }, (erreur:any) => {
          console.log(erreur.status);
      })

  }

  supprimer_sous_menu(idsousmenu:any, index:any): void{

      if(this.dechargementModel.id_dechargelent == 0){
        this.dataSousMenuModel.splice(index,1);
        this.CalculPoidsQte();
      }else{
          this.apiUrl = "SERVER/gestiondechargement/saisirdechargement/supprimersousmenu";
            var data = {idContenuNavire:idsousmenu, idDossier:this.dechargementModel.iddossier, id_dechargement : this.dechargementModel.id_dechargelent};
            this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
              retryWhen(error =>
                error.pipe(
                  scan((acc:any, error:any) => {
                    if (acc > 2) throw error;
                    return acc + 1;
                  }, 1),
                  delay(1000)
                )
              )
          ).subscribe((value:any) => {
              this.listeSousMenuMenus();
            }, (erreur:any) => {

          })
      }

  }


  listeSousMenuMenus(): void{
    this.apiUrl = "SERVER/gestiondechargement/saisirdechargement/listesousmenu";
    var data = {id_dechargement:this.dechargementModel.id_dechargelent};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
      this.dataSousMenuModel = response.liste_sous_menu;
      this.CalculPoidsQte();
    }, (erreur:any) => {
        console.log(erreur.status);
    })
  }


  Recherche(data:any): void{
    if(this.recherche.numeroDossier == "" && this.recherche.Navire == "" && this.recherche.jour_nuit == "" && this.recherche.date == ""){
      this.listeDechargement();
      return;
    }
      this.apiUrl = "SERVER/gestiondechargement/saisirdechargement/recherche";
      var data = data;
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
      this.Liste_dechargement_navires= value.Liste_dechargement_navires;
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }

  CalculPoidsQte(){
    var poids = 0;
    var qte = 0;
    for(var i = 0; i < this.dataSousMenuModel.length; i++){
      if(isNaN(this.dataSousMenuModel[i].qte))
        this.dataSousMenuModel[i].qte = 0;
      if(isNaN(this.dataSousMenuModel[i].PoidsNet))
        this.dataSousMenuModel[i].PoidsNet = 0;

      var qte_liste = parseFloat(this.dataSousMenuModel[i].qte);
      var poids_liste = parseFloat(this.dataSousMenuModel[i].PoidsNet);

      poids = poids + poids_liste;
      qte = qte + qte_liste;
    }

    if(isNaN(poids))
        poids = 0;
     if(isNaN(qte))
        qte = 0;
    this.totalPoidsDossier = poids.toFixed(2);
    this.totalQteDossier = qte;
  }

  UpdateDechargementNavire(contenu:any){
    var infoUser = this.AuthService.getInfoUtilisateur();
    contenu.iduser = infoUser.id;
    if(this.dechargementModel.id_dechargelent == 0){
        this.CalculPoidsQte();
    }else{
        this.apiUrl = "SERVER/gestiondechargement/saisirdechargement/modifiersousmenu";
        var data = {contenu:contenu};
        this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
          retryWhen(error =>
            error.pipe(
              scan((acc:any, error:any) => {
                if (acc > 2) throw error;
                return acc + 1;
              }, 1),
              delay(1000)
            )
          )
      ).subscribe((value:any) => {
          this.totalPoidsDossier = value.totalPoidsDossier;
          this.totalQteDossier = value.totalQteDossier;
        }, (erreur:any) => {

      })
    }

  }

  reInitSearchForm(): void{
    this.listdoss = [];
  }

  reInitSearchBlurForm(): void{
    setTimeout((val:any) => {
        this.listdoss = [];
    }, 500);
  }

  reInitSearchDesignationProduit(): void{
    this.listproduit = [];
  }

  reInitSearchBlurDesignationProduit(): void{
    setTimeout((val:any) => {
        this.listproduit = [];
    }, 500);
  }


}
