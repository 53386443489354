
<div class="x_panel x_panel_close">
    <div class="x_content">
        <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;">
            <span ><i  class="fa fa-arrow-left"></i></span>
            <div  class="ml-auto text-right">
                <span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span>
                <i class="fa fa-chevron-right"></i>
                <span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Gestion Compte Escale Navire - Etat Recapitulatif</span>
             </div>
        </div>
        <br>

        <div class="tab-content" id="myTabContent">

                <div class="x_panel_title x_panel_close" style="width: 99%;margin: 0px auto;display: table;">
                    <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-6 form-group">
                                  <div class="persoautocomplete">
                                        <label for="">Selectionner N°Dossier*</label>
                                       <input [(ngModel)]="DOSSIERModel.numeroDossier" type="text" name="numdossForm" id="numdossForm" placeholder="N°Dossier"  class="form-control form-control-sm" autocomplete="off" (focus)="reInitSearchForm()" (blur)="reInitSearchBlurForm()" disabled="disabled">
                                         <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="">
                                         </button>
                                          <div class="classPerso  dropdown-menu">
                                            <form class="px-0 py-3">
                                                <div class="col-md-12">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                          <input type="text" [(ngModel)]="dossier.numeroDossier" class="form-control form-control-sm" placeholder="N°Dossier" name="FormSaisiMouvNav" (ngModelChange)="ListeAutoCompleteDossier(dossier.numeroDossier)" autocomplete="off">
                                                          <div>
                                                            <br>
                                                              <table class="table_bloc_liste tableReseach">
                                                                  <thead class="theadfixed">
                                                                      <th>N°Dossier</th>
                                                                  </thead>
                                                                  <tbody>
                                                                      <tr class="SelectTable"  *ngFor="let x of listdoss" (click)="SelectionAutocomplete(x)">
                                                                          <td>{{x.numeroDossier}}</td>
                                                                      </tr>
                                                                  </tbody>
                                                              </table>
                                                          </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                          </div>
                                  </div>
                              </div>

                            </div>
                          </div>
                        <br>
                        <div class="col-md-12">
                            <div class="">

                                <table class="table_mainoeuvre_fourniture table_bloc_liste">
                                    <thead>
                                      <tr>

                                          <th>Date</th>
                                          <th>Opération Escale</th>
                                          <th>Tarif</th>
                                          <th>Unité</th>
                                          <th>TVA(%)</th>
                                          <th>Montant TTC Proforma</th>
                                          <th>Montant TTC Facture</th>
                                          <th>Différence</th>

                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr *ngFor="let x of dataSousMenuModel">
                                        <td>{{x.date_creation}}</td>
                                        <td>{{x.type_activite}}</td>
                                        <td>{{x.prix_unitaire}}</td>
                                        <td>{{x.nbre}}</td>
                                        <td>{{x.tva}}</td>
                                        <td>{{x.montantproforma}}</td>
                                        <td>{{x.montantfacture}}</td>
                                        <td>{{x.Difference}}</td>
                                      </tr>

                                    </tbody>
                                    <tfoot>
                                      <tr *ngFor="let x of listeCalculer">
                                        <td style="font-weight:bold">TOTAL</td>
                                        <td>{{x.TTCP}}</td>
                                        <td>{{x.TTCF}}</td>
                                        <td></td>
                                      </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        <br>
                        <div class="text-right-btn">
                          <button  class="btn btn-success btn-enregistrer btn-sm" (click)="Exporter()">
                            <i  class="fas fa-excel"></i>&nbsp;Exporter</button>&nbsp;
                          <button  class="btn btn-warning btn-enregistrer btn-sm" (click)="ConfirmationImpressionFormulaire()"><i  class="fas fa-print"></i>&nbsp;Imprimer</button>&nbsp;
                         </div>



                    </div>
                    <br>




            </div>

        </div>
</div>
