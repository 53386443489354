<div class="x_panel x_panel_close">
    <div class="x_content">
        <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;"><span ><i  class="fa fa-arrow-left"></i></span>
        	<div  class="ml-auto text-right"><span class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span>
        		<i  class="fa fa-chevron-right"></i>
        		<span class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Import Reporting - Liste Conteneurs Livrés</span>
         	</div>
        </div>
        <br>
        <div class="col-md-12">
            <div class="content-action">
                <ul class="nav nav-tabs bar_tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="saisir-tab" data-toggle="tab" href="javascript:void(0)"
                           role="tab" aria-controls="saisir" aria-selected="true">Liste Conteneurs Livrés</a>
                    </li>
                </ul>

                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade active show" id="saisir" role="tabpanel" aria-labelledby="saisir-tab">
                    	<br>
                        <div class="">
                            <div class="recherche_liste">
                            <div class="row">
                              <div class="form-group col-md-3">
                                 <label for="">Armateur</label>
                                 <select class="form-control form-control-sm" [(ngModel)]="recherche.amateur" (ngModelChange)="recherch(recherche)">
                                  <option *ngFor="let i of amateur" value="{{i.idAmateur}}">{{i.nomAmateur}}</option>
                                 </select>
                              </div>
                                <div class="form-group col-xs-12 col-md-3">
                                  <label>
                                      <font style="vertical-align: inherit;">
                                          <font style="vertical-align: inherit;">N° Conteneur</font>
                                      </font>
                                  </label>
                                    <input name="recherche" type="text" placeholder="N° Conteneur" class="form-control form-control-sm"
                                        [(ngModel)]="recherche.numConteneur" (ngModelChange)="recherch(recherche)">
                                </div>

                                <div class="form-group col-md-3">
                                    <label>Chauffeur</label>
                                    <select [(ngModel)]="recherche.chauffeur" class="form-control form-control-sm" id="nomagent" (ngModelChange)="recherch(recherche)">
                                        <option> </option>
                                        <option *ngFor="let i of chauffeur" value="{{i.idChauffeur}}">{{i.NomChauffeur}}</option>
                                        
                                    </select>
                                </div>

                                <div class="form-group col-md-3">
                                    <label>Camion</label>
                                    <select [(ngModel)]="recherche.camion" class="form-control form-control-sm" id="nomagent" (ngModelChange)="recherch(recherche)">
                                        <option> </option>
                                        <option *ngFor="let i of camion" value="{{i.idCamion}}">{{i.immat}}</option>
                                        
                                    </select>
                                </div>

                                <div class="form-group col-xs-12 col-md-4">
                                  <label>N° BL</label>
                                    <input name="recherche" type="text" placeholder="N° BL" class="form-control form-control-sm"
                                        [(ngModel)]="recherche.numBL" (ngModelChange)="recherch(recherche)">
                                </div>


                                <div class="form-group col-xs-12 col-md-4">
                                    <label>Periode Du</label>
                                    <input name="recherche" type="date" class="form-control form-control-sm"
                                           [(ngModel)]="recherche.dateDebut" (ngModelChange)="recherch(recherche)">
                                </div>

                                <div class="form-group col-xs-12 col-md-4">
                                    <label>Au</label>
                                    <input name="recherche" type="date" class="form-control form-control-sm"
                                           [(ngModel)]="recherche.dateFin" (ngModelChange)="recherch(recherche)">
                                </div>
                            </div>

                          </div>
                        </div>
                        <br>
                        <div class="Table_Longer">
                            <table class="table_bloc_liste">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Date Reception</th>
                                    <th>Nom Armateur</th>
                                    <th>Nom Client</th>
                                    <th>Type</th>
                                    <th>Taille</th>
                                    <th>N° BL</th>
                                    <th>Lieu Livraison</th>
                                    <th>Date Livraison</th>
                                    <th>Créer Par</th>
                                    <th>Créer Le</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let i of listeConteneurs">
                                    <td>{{i.idConteneurImport}}</td>
                                    <td>{{i.dateReception}}</td>
                                    <td>{{i.amateur}}</td>
                                    <td>{{i.client}}</td>
                                    <td>{{i.type}}</td>
                                    <td>{{i.taille}}</td>
                                    <td>{{i.numBL}}</td>
                                    <td>{{i.lieuLivraison}}</td>
                                    <td>{{i.date_LivraisonMouvementConteneur}}</td>
                                    <td>{{i.nom}}</td>
                                    <td>{{i.creerLe}}</td>
                                  </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="col-xs-12 col-ms-12 text-right" style="float: right;margin-top: 15px;" *ngIf="liste_pagination.length > 1">
                          <div class="pagination pagination_liste_tableau">
                            <a class="page-link" *ngFor="let n of liste_pagination, let i = index" [ngClass]="{'active': i == activePagination}" (click)="CurrentPagePagination(n,i,$event)">{{i + 1}}</a>
                          </div>
                        </div>
                        <br><br>
                        <div class="row">
                            <div  class="col-md-6 form-group"></div>
                            <div  class="col-md-3 form-group">
                                <div  class="form-group">
                                  <label >&nbsp;</label>
                                  <button  class="btn btn-success btn-block btn-sm" (click)="Exporter()">
                                    <i  class="fas fa-excel"></i>&nbsp;Exporter</button>
                                </div>
                            </div>
                            <div  class="col-md-3 form-group">
                                <div  class="form-group">
                                  <label >&nbsp;</label>
                                  <button  class="btn btn-warning btn-block btn-sm" (click)="ConfirmationImpressionFormulaire()"><i  class="fas fa-print"></i>&nbsp;Imprimer</button>
                                </div>
                              </div>
                        </div>
                    </div>	
                </div>    
            </div>
        </div>

    </div>
</div>
