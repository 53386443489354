<div class="x_panel x_panel_close">
    <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;"><span ><i  class="fa fa-arrow-left"></i></span><div  class="ml-auto text-right"><span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span><i  class="fa fa-chevron-right"></i><span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Créer Fonction</span>
        </div>
    </div>
    <div class="x_content">
      <div class="col-md-12">
        <div class="content-action">
             <ul class="nav nav-tabs bar_tabs" id="myTab" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link active" id="saisir-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='saisir'}" (click)="search('saisir')"
                           role="tab" aria-controls="saisir" aria-selected="true">Créer Fonction</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="profile-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='profile'}" (click)="search('profile')"
                            role="tab" aria-controls="profile" aria-selected="false">Liste Fonction</a>
                      </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div [ngClass]="{ 'show':activeTab==='saisir', 'active':activeTab==='saisir'}"
                              class="tab-pane fade active show" id="saisir" role="tabpanel" aria-labelledby="saisir-tab">
                              <br>
                              <div class="x_panel_title x_panel_close">
                                  <div class="x_title">
                                    <h6>Fonction</h6>
                                    <ul class="nav navbar-right panel_toolbox">
                                    </ul>
                                    <div class="clearfix"></div>
                                  </div>
                                  <div class="x_content">
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="form-group col-xs-12 col-sm-6">
                                                      <label for="">Fonction</label>
                                                     <input type="text" id="fonction" [(ngModel)]="FonctionModel.libelle" name="fonction" placeholder="Fonction" class="form-control form-control-sm">
                                                </div>
                                            </div>
                                        </div>
                                  </div>
                              </div>
                              <div class="text-right-btn">
                                <button type="button" class="btn btn-primary  btn-sm btn-enregistrer " (click)="enregistrer(FonctionModel)">Enregistrer</button>&nbsp;
                                <button type="button" class="btn btn-secondary btn-sm btn-annuler" (click)="Annuler()">Annuler</button>&nbsp;
                             </div>
                    </div>

                    <div class="tab-pane fade" id="profile" role="tabpanel"  aria-labelledby="profile-tab" [ngClass]="{'show':activeTab==='profile', 'active':activeTab==='profile'}">
                              <br>
                          <div class="row recherche_liste">
                              <div class="col-sm-12 col-md-3 ">
                                <div class="form-group">
                                  <label for="">Fonction</label>
                                  <input type="text" id="" placeholder="Fonction" class="form-control form-control-sm" name="libe" [(ngModel)]="recherche.libelle">
                                </div>
                              </div>
                              <div class="col-sm-12  col-md-3">
                                <div class="form-group AccessBtnResearch">
                                  <br>
                                  <button class="btn btn-primary btn-sm" (click)="rechercheFonction(recherche)">
                                    <i class="fas fa-search"></i>&nbsp;
                                    <span>Rechercher</span>
                                  </button>
                                </div>
                              </div>
                          </div>
                          <br>
                        <div class="table-responsive">
                        <table class="table_bloc_liste">
                          <thead>
                            <th>#</th>
                            <th>Fonction</th>
                            <th>Créer Par</th>
                            <th>Créer Le</th>
                            <th>Modifier Par</th>
                            <th>Modifier Le</th>
                            <th></th>
                            <th></th>
                          </thead>
                          <tbody id="hzga">
                            <tr *ngFor="let x of listefonction_data" class="text-center" >
                              <td>{{x.idFonction}}</td>
                              <td>{{x.libelle}}</td>
                              <td>{{x.nom}}</td>
                              <td>{{x.date}}</td>
                              <td>{{x.modifierpar}}</td>
                              <td>{{x.datemodification}}</td>

                              <td class="text-center">
                                <button  class="btn btn-success btn-sm" (click)="information(x.idFonction)">
                                  <i class="fas fa-pencil-alt"></i>
                                </button>
                              </td>
                              <td class="text-center">
                                <button  class="btn btn-danger btn-sm" (click)="supprimer(x.idFonction)">
                                   <i class="far fa-trash-alt"></i>
                                </button>
                              </td>

                            </tr>
                          </tbody>
                        </table>
                    </div>
                    </div>
                </div>
        </div>




      </div>
    </div>
</div>
