import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../services/transfertData/transfertData.service';
import { retry, delay, retryWhen, tap, scan } from 'rxjs/operators';

@Component({
  selector: 'app-tableaubord',
  templateUrl: './tableaubord.component.html',
  styleUrls: ['./tableaubord.component.css']
})
export class TableaubordComponent implements OnInit {
  recherche:any = {};
  resume:any = {};
  constructor(private transfertDataService : transfertDataService,) { }

  ngOnInit(): void {
     var date = new Date();
     this.resume.quinzePlusGrosTonnageNavire = [];
     this.resume.quinzePlusGrosClient = [];
     this.resume.listeDechargements = [];
     this.resume.listeMagasins = [];
     this.recherche.dateOuvertDebut = date.getFullYear()+"-"+"01"+"-01";
     this.recherche.dateOuvertFin = date.getFullYear()+"-"+"12"+"-"+"31";
     this.ResumeTableauBord();
  }

  DernierJourMoisenCours(y:any,m:any){
    return  new Date(y, m +1, 0).getDate();
  }

  ResumeTableauBord(): void{    
    var apiUrl = "SERVER/tableaubord/resume/details";
    var data = {datedebut:this.recherche.dateOuvertDebut, datefin: this.recherche.dateOuvertFin};
    this.transfertDataService.EnvoiData(data, apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
        
        
    ).subscribe((value:any) => {
      this.resume = value;
    }, (erreur:any) => {
    })
    
    
  }

  

}
