export class repertoiredocument {
  public idRepertoireDocument: number = 0;
  public RepertoireDocument: string = "";
  public date_creation: string = "";
  public idUser: number = 0;
  public modifierpar: number = 0;
  public datemodification: string = "";
  constructor() {
  }

}
