export class DOSSIER {

  public idGestionNavire: number = 0;
  public numeroDossier: string = "";
  public IdNavire: number = 0;
  public date_arrivee_prevu: string = "";
  public capitaine: string = "";
  public provenance: string = "";
  public NomCompagnie: string = "";
  public Navire: string = "";
  public numero_voyage: string = "";
  public total_poids: number = 0;
  public total_qte: number = 0;
  public total_poids_decharge: number = 0;
  public total_qte_decharge: number = 0;
  public iduser: number = 0;
  public modifierpar: number = 0;
  public datOuvert: string = "";
  public agent_navire: string = "";
  public date: string = "";
  public datemodification: string = "";
  constructor() {

  }

}
