import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';


@Component({
  selector: 'app-planning-navire',
  templateUrl: './planning-navire.component.html',
  styleUrls: ['./planning-navire.component.css']
})
export class PlanningNavireComponent implements OnInit {
  apiUrl:String = "";
  activeTab:String="saisir";
  planning:any = {};
  dossierclient:any = "";
  listeclients:any = [];
  PoidsTotal:any = "";
  QteTotal:any = "";
  constructor(private transfertDataService : transfertDataService, private router: Router) { }

  ngOnInit(): void {
    this.PlanningNavire();
  }

  search(activeTab:String){
     this.activeTab=activeTab;
     
  };

  OpenListeClients(numdoss:any, listeclients:any, PoidsTotal:any, QteTotal:any):void{
    this.dossierclient = numdoss;
    this.listeclients = listeclients;
    this.PoidsTotal = PoidsTotal;
    this.QteTotal = QteTotal;
    $(".popupclient").trigger("click");
    
  }

  OpenCaleNavire(iddoss:any):void{
    this.router.navigateByUrl('/gestioncalenavire?iddossier='+iddoss);
  }

  PlanningNavire():void{
      this.apiUrl = "SERVER/gestionnavire/navire/planning";
      var data = {};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.planning = value;
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }

}
