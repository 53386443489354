import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
@Component({
  selector: 'app-etat-differentiel',
  templateUrl: './etat-differentiel.component.html',
  styleUrls: ['./etat-differentiel.component.css']
})
export class EtatDifferentielComponent implements OnInit {
  activeTab:String="saisir";
  apiUrl:any="";
  listesuividechargement:any = [];
  info_dossier:any = [];
  listdoss:any = [];
  recherche:any = {};
  dossier:any = {};
  constructor(private transfertDataService : transfertDataService) { }

  ngOnInit(): void {
  }
  Tabulation(activeTab:any): void{
    this.activeTab=activeTab;
  }

   ListeAutoCompleteDossier(numerodossier:any){
   this.apiUrl = "SERVER/gestiondechargement/etatdifferentiel/recherche_dossier";
      var data = {numeroDossier:numerodossier};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listdoss= value.listdoss;
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }

  SelectionAutocomplete(data:any): void{
     this.recherche.numeroDossier = data.numeroDossier;
     this.recherche.idGestionNavire = data.idGestionNavire;
     this.recherche.Navire = data.Navire;
     this.Recherche(this.recherche);
  }

   Recherche(data:any): void{
      this.apiUrl = "SERVER/gestiondechargement/etatdifferentiel/recherche";
      var data = data;
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
      this.info_dossier= value.dechargement;
      console.log(this.info_dossier);
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }

  reInitSearchForm(): void{
    this.listdoss = [];
  }

  reInitSearchBlurForm(): void{
    setTimeout((val:any) => {
        this.listdoss = [];
    }, 500);
  }

  
}
