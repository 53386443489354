import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import {DragDropModule} from '@angular/cdk/drag-drop';

import { AppComponent } from './app.component';
import { ConnexionComponent } from './Auth/connexion/connexion.component';
import { MainComponent } from './main/main.component';
import { MenuComponent } from './main/menu/menu.component';
import { EspaceAffichageComponent } from './main/espace-affichage/espace-affichage.component';
import { NavTopComponent } from './Main/espace-affichage/nav-top/nav-top.component';
import { EspaceTravailComponent } from './Main/espace-affichage/espace-travail/espace-travail.component';
import { CreerMenuComponent } from './module/configurations/creer-menu/creer-menu.component';
import { CreerGroupeComponent } from './module/groupeware/creer-groupe/creer-groupe.component';
import { CreerUtilisateurComponent } from './module/groupeware/creer-utilisateur/creer-utilisateur.component';
import { UtilisateuretgroupeComponent } from './module/groupeware/utilisateuretgroupe/utilisateuretgroupe.component';
import { DroitaccesComponent } from './module/groupeware/droitacces/droitacces.component';
import { HistoriqueutilisateurComponent } from './module/groupeware/historiqueutilisateur/historiqueutilisateur.component';
import { ModeleComponent } from './module/modele/modele.component';
import { GroupewareComponent } from './module/groupeware/groupeware.component';
import { FonctionComponent } from './module/groupeware/fonction/fonction.component';
import { ConfigurationsComponent } from './module/configurations/configurations.component';
import { TableaubordComponent } from './module/tableaubord/tableaubord.component';
import { CreerDossierNavireComponent } from './module/gestion_navires/creer-dossier-navire/creer-dossier-navire.component';
import { MouvementsNaviresComponent } from './module/gestion_navires/mouvements-navires/mouvements-navires.component';
import { SaisirDechargementComponent } from './module/gestion_dechargements/saisir-dechargement/saisir-dechargement.component';
import { EtatDechargementComponent } from './module/gestion_dechargements/etat-dechargement/etat-dechargement.component';
import { EtatDifferentielComponent } from './module/gestion_dechargements/etat-differentiel/etat-differentiel.component';
import { StockInitialMagasinsComponent } from './module/gestion_des_magasins/stock-initial-magasins/stock-initial-magasins.component';
import { EntreesMagasinsComponent } from './module/gestion_des_magasins/entrees-magasins/entrees-magasins.component';
import { SortiesMagasinsComponent } from './module/gestion_des_magasins/sorties-magasins/sorties-magasins.component';
import { EtatStockMagasinsComponent } from './module/gestion_des_magasins/etat-stock-magasins/etat-stock-magasins.component';
import { TransfertMagasinsComponent } from './module/gestion_des_magasins/transfert-magasins/transfert-magasins.component';
import { StockMagasinsSortiesMagasinComponent } from './module/stock_magasins/stock-magasins-sorties-magasin/stock-magasins-sorties-magasin.component';
import { StockMagasinsEtatStockMagasinComponent } from './module/stock_magasins/stock-magasins-etat-stock-magasin/stock-magasins-etat-stock-magasin.component';
import { SaisirBudgetNavireComponent } from './module/exploitation/saisir-budget-navire/saisir-budget-navire.component';
import { SaisirChargesNavireComponent } from './module/exploitation/saisir-charges-navire/saisir-charges-navire.component';
import { ExploitationTableaubordComponent } from './module/exploitation/exploitation-tableaubord/exploitation-tableaubord.component';
import { ExploitationRecapitulatifComponent } from './module/exploitation/exploitation-recapitulatif/exploitation-recapitulatif.component';
import { GestionClientsComponent } from './module/gestion-clients/gestion-clients.component';
import { RpEtatStockMagasinComponent } from './module/reporting/rp-etat-stock-magasin/rp-etat-stock-magasin.component';
import { RpEtatStockClientComponent } from './module/reporting/rp-etat-stock-client/rp-etat-stock-client.component';
import { RpEtatCoutsManutentionComponent } from './module/reporting/rp-etat-couts-manutention/rp-etat-couts-manutention.component';
import { RpListeDossiersComponent } from './module/reporting/rp-liste-dossiers/rp-liste-dossiers.component';
import { RpListeDechargementsComponent } from './module/reporting/rp-liste-dechargements/rp-liste-dechargements.component';
import { RpListeClientsComponent } from './module/reporting/rp-liste-clients/rp-liste-clients.component';
import { RpListeProduitsClientsComponent } from './module/reporting/rp-liste-produits-clients/rp-liste-produits-clients.component';
import { GpNavireComponent } from './module/gestion_parametre/gp-navire/gp-navire.component';
import { GpDetailProduitComponent } from './module/gestion_parametre/gp-detail-produit/gp-detail-produit.component';
import { GpProvenanceComponent } from './module/gestion_parametre/gp-provenance/gp-provenance.component';
import { GpCompagnieComponent } from './module/gestion_parametre/gp-compagnie/gp-compagnie.component';
import { GpPositionNavireComponent } from './module/gestion_parametre/gp-position-navire/gp-position-navire.component';
import { GpQuaiComponent } from './module/gestion_parametre/gp-quai/gp-quai.component';
import { GpAcconierComponent } from './module/gestion_parametre/gp-acconier/gp-acconier.component';
import { GpElementChargeExploitationComponent } from './module/gestion_parametre/gp-element-charge-exploitation/gp-element-charge-exploitation.component';
import { GuCompteMagasinierComponent } from './module/gestion_utilisateurs/gu-compte-magasinier/gu-compte-magasinier.component';
import { GuCreerMagasinComponent } from './module/gestion_utilisateurs/gu-creer-magasin/gu-creer-magasin.component';
import { GuAttribuerMagasinComponent } from './module/gestion_utilisateurs/gu-attribuer-magasin/gu-attribuer-magasin.component';
import { GuCompteUtilisateurComponent } from './module/gestion_utilisateurs/gu-compte-utilisateur/gu-compte-utilisateur.component';
import { GestionProduitsClientsComponent } from './module/gestion-produits-clients/gestion-produits-clients.component';
import { GpCreerMagasinComponent } from './module/gestion_parametre/gp-creer-magasin/gp-creer-magasin.component';
import { GpAttribuerMagasinComponent } from './module/gestion_parametre/gp-attribuer-magasin/gp-attribuer-magasin.component';
import { GpNomConditionnementComponent } from './module/gestion_parametre/gp-nom-conditionnement/gp-nom-conditionnement.component';
import { GpRepertoiredocumentComponent } from './module/gestion_parametre/gp-repertoiredocument/gp-repertoiredocument.component';
import { PlanningNavireComponent } from './module/gestion_navires/planning-navire/planning-navire.component';
import { ArrivedepartnavireComponent } from './module/gestion_navires/arrivedepartnavire/arrivedepartnavire.component';
import { GpPortComponent } from './module/gestion_parametre/gp-port/gp-port.component';
import { ParametreImprimeComponent } from './module/gestion_parametre/parametre-imprime/parametre-imprime.component';
import { GpCaleComponent } from './module/gestion_parametre/gp-cale/gp-cale.component';
import { ProgrammerDechargementComponent } from './module/gestion_dechargements/programmer-dechargement/programmer-dechargement.component';
import { AgentNavireComponent } from './module/gestion_parametre/agent-navire/agent-navire.component';
import { CreerFournitureComponent } from './module/stock_petite_fourniture/creer-fourniture/creer-fourniture.component';
import { EntreeFournitureComponent } from './module/stock_petite_fourniture/entree-fourniture/entree-fourniture.component';
import { SortieFournitureComponent } from './module/stock_petite_fourniture/sortie-fourniture/sortie-fourniture.component';
import { StockDisponibleFournitureComponent } from './module/stock_petite_fourniture/stock-disponible-fourniture/stock-disponible-fourniture.component';
import { DeclarationFaitNavireComponent } from './module/declaration-fait-navire/declaration-fait-navire.component';
import { GestionCompteEscaleNavireComponent } from './module/gestion-compte-escale-navire/gestion-compte-escale-navire.component';
import { GestionCaleNavireComponent } from './module/gestion-cale-navire/gestion-cale-navire.component';
import { GestionParcEnginComponent } from './module/gestion-parc-engin/gestion-parc-engin.component';
import { SaisirProformaComponent } from './module/gestion-compte-escale-navire/saisir-proforma/saisir-proforma.component';
import { SaisirFactureComponent } from './module/gestion-compte-escale-navire/saisir-facture/saisir-facture.component';
import { EtatRecapitulatifComponent } from './module/gestion-compte-escale-navire/etat-recapitulatif/etat-recapitulatif.component';
import { ConditionnementproduitComponent } from './module/gestion_des_magasins/conditionnementproduit/conditionnementproduit.component';
import { FournisseurComponent } from './module/gestion_parametre/fournisseur/fournisseur.component';
import { RecuperatifStockClientComponent } from './module/gestion_des_magasins/recuperatif-stock-client/recuperatif-stock-client.component';
import { AccueilComponent } from './module/accueil/accueil.component';
import { OperationescaleComponent } from './module/gestion_parametre/operationescale/operationescale.component';

import { ImportTableauBordComponent } from './module/import-tableau-bord/import-tableau-bord.component';
import { ReceptionConteneurComponent } from './module/Import-Livraison-Conteneur/reception-conteneur/reception-conteneur.component';
import { TraiterConteneurComponent } from './module/Import-Livraison-Conteneur/traiter-conteneur/traiter-conteneur.component';
import { ListeConteneurLivrerComponent } from './module/Import-Livraison-Conteneur/liste-conteneur-livrer/liste-conteneur-livrer.component';
import { SaisirLivraisonConteneurComponent } from './module/Import-Livraison-Conteneur/saisir-livraison-conteneur/saisir-livraison-conteneur.component';

import { SaisirRetourConteneurComponent } from './module/Import-Recuperation-TC/saisir-retour-conteneur/saisir-retour-conteneur.component';
import { SaisirRecuperationComponent } from './module/Import-Recuperation-TC/saisir-recuperation/saisir-recuperation.component';


import { CreerFactureComponent } from './module/Import-Facturation-Conteneur/creer-facture/creer-facture.component';
import { ListeFactureComponent } from './module/Import-Facturation-Conteneur/liste-facture/liste-facture.component';
import { EtatStockComponent } from './module/Import-Reporting/etat-stock/etat-stock.component';
import { EtatJournalierMouvementConteneurComponent } from './module/Import-Reporting/etat-journalier-mouvement-conteneur/etat-journalier-mouvement-conteneur.component';
import { EtatJournalierLivraisonImportComponent } from './module/Import-Reporting/etat-journalier-livraison-import/etat-journalier-livraison-import.component';
import { EtatJournalierRestitutionConteneurVideComponent } from './module/Import-Reporting/etat-journalier-restitution-conteneur-vide/etat-journalier-restitution-conteneur-vide.component';

import { AmateurComponent } from './module/Import-Parametre/amateur/amateur.component';
import { ChauffeurComponent } from './module/Import-Parametre/chauffeur/chauffeur.component';
import { CamionComponent } from './module/Import-Parametre/camion/camion.component';
import { MouvementConteneurComponent } from './module/Import-Parametre/mouvement-conteneur/mouvement-conteneur.component';
import { MouvementRecupConteneurComponent } from './module/Import-Parametre/mouvement-recup-conteneur/mouvement-recup-conteneur.component';
import { AgentComponent } from './module/Import-Parametre/agent/agent.component';

import { SaisirJournalTransitComponent } from './module/journal-transit/saisir-journal-transit/saisir-journal-transit.component';
import { EtatJournalTransitComponent } from './module/journal-transit/etat-journal-transit/etat-journal-transit.component';
import { TransitaireComponent } from './module/Import-Parametre/transitaire/transitaire.component';
import { LieuLivraisonComponent } from './module/Import-Parametre/lieu-livraison/lieu-livraison.component';
import { ModeLivraisonComponent } from './module/Import-Parametre/mode-livraison/mode-livraison.component';

import { CreerBookingComponent } from './module/Export-Livraison-Conteneur/creer-booking/creer-booking.component';
import { EtatBookingComponent } from './module/Export-Livraison-Conteneur/etat-booking/etat-booking.component';
import { SaisirDepotConteneurComponent } from './module/Export-Livraison-Conteneur/saisir-depot-conteneur/saisir-depot-conteneur.component';
import { ListeDepotConteneurComponent } from './module/Export-Livraison-Conteneur/liste-depot-conteneur/liste-depot-conteneur.component';
import { SaisirPrevisionRelevageTcComponent } from './module/Expert-Relevage-TC/saisir-prevision-relevage-tc/saisir-prevision-relevage-tc.component';
import { SaisirRelevageTcComponent } from './module/Expert-Relevage-TC/saisir-relevage-tc/saisir-relevage-tc.component';
import { ExportTbordComponent } from './module/Export-Tableau-Bord/export-tbord/export-tbord.component';
import { ListeConteneurPositionneComponent } from './module/Export-Reporting/liste-conteneur-positionne/liste-conteneur-positionne.component';
import { ListeConteneurReleveComponent } from './module/Export-Reporting/liste-conteneur-releve/liste-conteneur-releve.component';
import { MouvementConteneurExportComponent } from './module/Export-Reporting/mouvement-conteneur-export/mouvement-conteneur-export.component';
import { CreerFactureExportComponent } from './module/Export-Facturation-Conteneur/creer-facture-export/creer-facture-export.component';
import { ListeFactureExportComponent } from './module/Export-Facturation-Conteneur/liste-facture-export/liste-facture-export.component';
import { CategorieCoutExpComponent } from './module/Import-Parametre/categorie-cout-exp/categorie-cout-exp.component';







@NgModule({
  declarations: [
    AppComponent,
    ConnexionComponent,
    MainComponent,
    MenuComponent,
    EspaceAffichageComponent,
    NavTopComponent,
    EspaceTravailComponent,
    CreerMenuComponent,
    CreerGroupeComponent,
    CreerUtilisateurComponent,
    UtilisateuretgroupeComponent,
    DroitaccesComponent,
    HistoriqueutilisateurComponent,
    ModeleComponent,
    GroupewareComponent,
    FonctionComponent,
    ConfigurationsComponent,
    TableaubordComponent,
    CreerDossierNavireComponent,
    MouvementsNaviresComponent,
    SaisirDechargementComponent,
    EtatDechargementComponent,
    EtatDifferentielComponent,
    StockInitialMagasinsComponent,
    EntreesMagasinsComponent,
    SortiesMagasinsComponent,
    EtatStockMagasinsComponent,
    TransfertMagasinsComponent,
    StockMagasinsSortiesMagasinComponent,
    StockMagasinsEtatStockMagasinComponent,
    SaisirBudgetNavireComponent,
    SaisirChargesNavireComponent,
    ExploitationTableaubordComponent,
    ExploitationRecapitulatifComponent,
    GestionClientsComponent,
    RpEtatStockMagasinComponent,
    RpEtatStockClientComponent,
    RpEtatCoutsManutentionComponent,
    RpListeDossiersComponent,
    RpListeDechargementsComponent,
    RpListeClientsComponent,
    RpListeProduitsClientsComponent,
    GpNavireComponent,
    GpDetailProduitComponent,
    GpProvenanceComponent,
    GpCompagnieComponent,
    GpPositionNavireComponent,
    GpQuaiComponent,
    GpAcconierComponent,
    GpElementChargeExploitationComponent,
    GuCompteMagasinierComponent,
    GuCreerMagasinComponent,
    GuAttribuerMagasinComponent,
    GuCompteUtilisateurComponent,
    GestionProduitsClientsComponent,
    GpCreerMagasinComponent,
    GpAttribuerMagasinComponent,
    GpNomConditionnementComponent,
    GpRepertoiredocumentComponent,
    PlanningNavireComponent,
    ArrivedepartnavireComponent,
    GpPortComponent,
    ParametreImprimeComponent,
    GpCaleComponent,
    AgentNavireComponent,
    ProgrammerDechargementComponent,
    CreerFournitureComponent,
    EntreeFournitureComponent,
    SortieFournitureComponent,
    StockDisponibleFournitureComponent,
    DeclarationFaitNavireComponent,
    GestionCompteEscaleNavireComponent,
    GestionCaleNavireComponent,
    GestionParcEnginComponent,
    SaisirProformaComponent,
    SaisirFactureComponent,
    EtatRecapitulatifComponent,
    ConditionnementproduitComponent,
    FournisseurComponent,
    RecuperatifStockClientComponent,
    AccueilComponent,
    OperationescaleComponent,

    ImportTableauBordComponent,
    ReceptionConteneurComponent,
    TraiterConteneurComponent,
    ListeConteneurLivrerComponent,
    SaisirLivraisonConteneurComponent,
    SaisirRetourConteneurComponent,

    ListeFactureComponent,
    CreerFactureComponent,
    EtatStockComponent,
    EtatJournalierMouvementConteneurComponent,
    EtatJournalierLivraisonImportComponent,
    EtatJournalierRestitutionConteneurVideComponent,
    AmateurComponent,
    ChauffeurComponent,
    CamionComponent,
    MouvementConteneurComponent,
    AgentComponent,
    SaisirJournalTransitComponent,
    EtatJournalTransitComponent,
    TransitaireComponent,
    LieuLivraisonComponent,
    ModeLivraisonComponent,
    SaisirRecuperationComponent,
    MouvementRecupConteneurComponent,
    CreerBookingComponent,
    EtatBookingComponent,
    SaisirDepotConteneurComponent,
    ListeDepotConteneurComponent,
    SaisirPrevisionRelevageTcComponent,
    SaisirRelevageTcComponent,
    ExportTbordComponent,
    ListeConteneurPositionneComponent,
    ListeConteneurReleveComponent,
    MouvementConteneurExportComponent,
    CreerFactureExportComponent,
    ListeFactureExportComponent,
    CategorieCoutExpComponent
    
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    DragDropModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
