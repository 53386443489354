export class dechargement {
  public id_dechargelent: number = 0;
  public iddossier: number = 0;
  public numeroDossier: string = "";
  public Navire: string = "";
  public nombre_equipe: number = 0;
  public jour_nuit: string = "";
  public fin_operation: string = "";
  public debut_operation: string = "";
  public observation: string = "";
  public superviseur: string = "";
  public responsable_acconage: string = "";
  public attente_arret_transfert: string = "";
  public date_dechargement: string = "";
  public num_mini: string = "";
  public acconier: string = "";
  public expert_client: string = "";
  public quai: string = "";
  public date: string = "";
  public numero_navire: string = "";
  public poids_total: string = "";
  public qte_total: string = "";
  public iduser: number = 0;
  public tonnage_cale1: number = 0;
  public tonnage_cale2: number = 0;
  public tonnage_cale3: number = 0;
  public tonnage_cale4: number = 0;
  public tonnage_cale5: number = 0;
  public datemodification: string = "";
  public modifierpar: number = 0;
  public status: boolean = false;

  constructor() {

  }

}
