export class AMATEUR {
  public idAmateur: number = 0;
  public nomAmateur: string = "";
  public contact: string = "";
  public creerPar: number = 0;
  public creerLe: string = "";
  public modifierPar: number = 0;
  public modifierLe: string = "";
  
  constructor() {
  }

}
