export class UTILISATEUR {

  public id: number = 0;
  public nom: string = "";
  public tel: string = "";
  public responsabilite: string = "";
  public departement: string = "";
  public idMagasin: number = 0;
  public login: string = "";
  public password: number = 0;
  public autorisation: number = 0;
  public active: string = "";
  public date: string = "";
  public creerparuser: number = 0;
  public modifierparusers: number = 0;
  public datemodificationuser: string = "";
  constructor() {

  }

}
