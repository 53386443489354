import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { DOSSIER } from '../../../classes/Dossier';
import { Agentnavire } from '../../../classes/agent_navire';
import { PROVENANCE } from '../../../classes/provenance';
import {DomSanitizer} from '@angular/platform-browser';
import { CONTENUENAVIRE } from '../../../classes/Contenunavire';
import { pipe } from 'rxjs';
import { AuthService } from '../../../services/connexion/auth.service';
import { ConfigurationService } from '../../../config/configuration.service';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';

@Component({
  selector: 'app-creer-dossier-navire',
  templateUrl: './creer-dossier-navire.component.html',
  styleUrls: ['./creer-dossier-navire.component.css']
})
export class CreerDossierNavireComponent implements OnInit {
  apiUrl:String = "";
  activeTab:String="saisir";
  navire:any={};
  Open:any = false;
  Open1:any = false;
  listnavire:any = [];
  dossier:any = {};
  dataSousMenuModel:any = [];
  listclient:any = [];
  listproduit:any = [];
  listedossiernavire:any = [];
  liste_des_provenances:any = [];
  liste_gents_navires:any = [];
  listeports:any = [];
  listecales:any = [];
  idClient:any;
  recherche:any={};
  totalPoidsDossier:any = 0;
  totalQteDossier:any = 0;
  ligneSelectionne:any = -1;
  precLine:any = 0;
  suppiddossier:any = {};
  lien_fiche_ExpST:any;
  code_navire:any = "";

  photos_previsualisation:any = [];
  array_files:any = [];

  DOSSIERModel:DOSSIER = new DOSSIER();
  CONTENUENAVIREModel:CONTENUENAVIRE = new CONTENUENAVIRE();
  Libprovenance:PROVENANCE = new PROVENANCE();
  agentnavire:Agentnavire = new Agentnavire();
  constructor(private sanitizer:DomSanitizer, private transfertDataService : transfertDataService, private AuthService : AuthService, private ConfigurationService : ConfigurationService, private HttpClient : HttpClient) { }

  ngOnInit(): void {
    this.dataSousMenuModel = [];
    this.listeDossierNavire();
    this.listeDesPorts();
    this.listeDesCales();
    this.listeAgentsnavire();
    this.listeProvenance();
    this.recherche.numeroDossier = "";
    this.recherche.navire = "";
    this.recherche.date_saisie = "";
    this.recherche.date_arrive_prevu = "";
  }

  reInitSearch(): void{
    this.listnavire = [];
    this.listproduit = [];
  }

  reInitSearchBlur(): void{
    setTimeout((val:any) => {
        this.listnavire = [];
        this.listproduit = [];
    }, 500);

  }

  //Fonction de verification de champs
    VerificationChampsObligatoire(tableau_champs_obligatoires:any){
        var verification_formulaire = true;
        for (var i = 0; i < tableau_champs_obligatoires.length; i++) {
            if($(tableau_champs_obligatoires[i]).val() == "" || $(tableau_champs_obligatoires[i]).val() == null || $(tableau_champs_obligatoires[i]).val() == "? string: ?"){
                    $(tableau_champs_obligatoires[i]).css("border","1px solid red");
                    verification_formulaire = false;
                    document.getElementById(tableau_champs_obligatoires[i].replace("#",""))?.scrollIntoView({behavior: "smooth"});
                    break;
            }else{
                $(tableau_champs_obligatoires[i]).css("border","");
            }
        }

        return verification_formulaire;
    }

  selectionligne(index:any, ordre:any){
    this.ligneSelectionne = index;
    this.precLine = ordre;
  }

  SuivantPrecedent(action:any){
    if(this.ligneSelectionne == -1)
      return;
    var apiUrl = "SERVER/gestionnavire/contenuenavire/SuivantPrecedent";
    var data = {"Ligne" : this.precLine, ac:action, "iddossier" : this.DOSSIERModel.idGestionNavire};
    this.transfertDataService.EnvoiData(data, apiUrl).subscribe((response:any) => {
        this.listeSousMenuMenus();
        this.ligneSelectionne = -1;
    });

  }

  apercu(iddocument:any): void{
    var element = <HTMLImageElement> $("#file_" + iddocument).get(0);
    element.requestFullscreen();
  }

  supprimer_image(nomfichier:any, iddocument:any) : void{
        if(iddocument == ""){
            for (var i = 0; i < this.array_files.length; i++) {

                if(this.array_files[i].name == nomfichier){
                    this.array_files.splice(i, 1);
                }
            }

            for (var i = 0; i < this.photos_previsualisation.length; i++) {
                if(this.photos_previsualisation[i].lien == nomfichier){
                    this.photos_previsualisation.splice(i, 1);
                }
            }
        }else{
            var apiUrl = "SERVER/gestionnavire/navire/SupprimerFile";
            var data = {id:iddocument};
            this.transfertDataService.EnvoiData(data, apiUrl).pipe(
               retryWhen(error =>
                 error.pipe(
                   scan((acc:any, error:any) => {
                     if (acc > 2) throw error;
                     return acc + 1;
                   }, 1),
                   delay(1000)
                 )
               )
            ).subscribe((value:any) => {
                for (var i = 0; i < this.photos_previsualisation.length; i++) {
                    if(this.photos_previsualisation[i].iddocument == iddocument){
                        this.photos_previsualisation.splice(i, 1);
                    }
                }
             }, (erreur:any) => {
            })
        }

  }

  onFileChanged(event: any): void {
        this.array_files = [];
        var src = URL.createObjectURL(event.target.files[0]);
        var elements = event.target.files;
        for(var i = 0; i < elements.length; i++){
          var src = URL.createObjectURL(event.target.files[i]);
          this.photos_previsualisation.push({iddocument : '', src : this.sanitizer.bypassSecurityTrustUrl(src), lien : event.target.files[i]});
          this.array_files.push(event.target.files[i]);
        }
  }

  ajouter(): void{
    if(this.DOSSIERModel.idGestionNavire == 0){
        var sousmenu = new CONTENUENAVIRE();
        this.dataSousMenuModel.push(sousmenu);
    }else{
      this.enregistrer_sous_menu();
    }

  }

  search(activeTab:String){
     this.activeTab=activeTab;
   };

  Annuler(){
    this.DOSSIERModel = new DOSSIER();
    this.dataSousMenuModel = [];

    this.array_files = [];
    this.photos_previsualisation = [];
  }

  ListeAutoCompleteDossier(navire:any){
   this.apiUrl = "SERVER/gestionnavire/navire/recherche_navire";
      var data = {Navire:navire};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listnavire= value.listnavire;
      }, (erreur:any) => {
      })
  }

  SelectionAutocomplete(data:any): void{
    this.DOSSIERModel.Navire = data.Navire;
    this.DOSSIERModel.IdNavire = data.idNavire;
    this.DOSSIERModel.NomCompagnie = data.NomCompagnie;
    this.code_navire = data.code;
    this.reInitSearch();
  }

  ListeAutoCompleteProd(prod:any){
   this.apiUrl = "SERVER/gestionnavire/contenuenavire/recherche_produit";
      var data = {NomSpecificProduit:prod};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listproduit = value.listproduit;
      }, (erreur:any) => {
      })
  }

  SelectionAutocompleteProd(data:any, datax:any): void{
    datax.designationProduit = data.NomSpecificProduit;
    this.idClient = data.idClient;
    datax.idClient = data.idClient;
    datax.idProduit = data.idProduit;
    var infoUser = this.AuthService.getInfoUtilisateur();
    datax.iduser = infoUser.id;
    this.ListeCLient(this.idClient, datax);
    this.UpdateContenuNavire(datax);
    this.listproduit = [];
  }

  UpdateContenuNavire(contenu:any){
    if(this.DOSSIERModel.idGestionNavire == 0){
        this.CalculPoidsQte();
    }else{
        this.apiUrl = "SERVER/gestionnavire/contenuenavire/modifier";
        var data = {contenu:contenu};
        this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
          retryWhen(error =>
            error.pipe(
              scan((acc:any, error:any) => {
                if (acc > 2) throw error;
                return acc + 1;
              }, 1),
              delay(1000)
            )
          )
      ).subscribe((value:any) => {
          this.totalPoidsDossier = value.totalPoidsDossier;
          this.totalQteDossier = value.totalQteDossier;
          this.DOSSIERModel.total_poids = value.totalPoidsDossier;
          this.DOSSIERModel.total_qte = value.totalQteDossier;
        }, (erreur:any) => {

      })
    }

  }

  CalculPoidsQte(){
    var poids = 0;
    var qte = 0;
    for(var i = 0; i < this.dataSousMenuModel.length; i++){
      if(isNaN(this.dataSousMenuModel[i].qte))
        this.dataSousMenuModel[i].qte = 0;
      if(isNaN(this.dataSousMenuModel[i].PoidsNet))
        this.dataSousMenuModel[i].PoidsNet = 0;

      var qte_liste = parseFloat(this.dataSousMenuModel[i].qte);
      var poids_liste = parseFloat(this.dataSousMenuModel[i].PoidsNet);

      poids = poids + poids_liste;
      qte = qte + qte_liste;
    }

    if(isNaN(poids))
        poids = 0;
     if(isNaN(qte))
        qte = 0;
    this.totalPoidsDossier = poids.toFixed(2);
    this.totalQteDossier = qte;
  }

  listeDesPorts( ){
   this.apiUrl = "SERVER/gestionnavire/navire/listeports";
      var data = {};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listeports = value.listeports;
      }, (erreur:any) => {

      })
  }

  listeDesCales(){
   this.apiUrl = "SERVER/gestionnavire/navire/listecales";
      var data = {};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listecales = value.listecales;
      }, (erreur:any) => {

      })
  }

  downloadDoc(iddossier:any){
    var apiUrl = "SERVER/gestionnavire/navire/zip";
      var data = {iddossier:iddossier};
      this.transfertDataService.EnvoiData(data, apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
      ).subscribe((value:any) => {
         window.open(this.ConfigurationService.configuration.hostname + value.link , "_self")?.focus();
       }, (erreur:any) => {
      })
  }

  ListeCLient(clt:any, tab:any){
   this.apiUrl = "SERVER/gestionnavire/contenuenavire/recherche_client";
      var data = {idClient:clt};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        tab.nom_societe = value.listclient[0].nom_societe;
      }, (erreur:any) => {
      })
  }

  Enregistrer(data:any, event:any): void{
    if(this.VerificationChampsObligatoire(["#NavireDossier", "#agentnavire", "#date_arrive_prevu"]) == false)
              return;
      event.target.disabled = true;
    if(this.DOSSIERModel.idGestionNavire == 0){
      this.apiUrl = "SERVER/gestionnavire/navire/enregistrer";
    }else{
      this.apiUrl = "SERVER/gestionnavire/navire/modifier";
    }

      this.DOSSIERModel.IdNavire = Number(this.DOSSIERModel.IdNavire);
      var infoUser = this.AuthService.getInfoUtilisateur();
      this.DOSSIERModel.iduser = infoUser.id;

      var dt = {"dossier": this.DOSSIERModel, "sousmenu" : this.dataSousMenuModel};
      var mData = JSON.stringify(dt);
      var formData = new FormData();

      formData.append('data', mData);
      formData.append('typedoc', '1');
      formData.append('code_navire', this.code_navire);

      if (this.array_files.length > 0) {
        for (var i = 0; i < this.array_files.length; i++) {
            formData.append("file["+i+"]", this.array_files[i]);
        }
      }
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data; charset=utf-8; boundary=' + Math.random().toString().substr(2));
      headers.append('Accept', 'application/json');

      this.HttpClient.post<any>(this.ConfigurationService.configuration.hostname + this.apiUrl, formData, {headers}).subscribe((resp:any) => {
          event.target.disabled = false;
          this.listeDossierNavire();
          this.Annuler();
          this.search("profile");
      })
  }

  listeDossierNavire(): void{
    this.apiUrl = "SERVER/gestionnavire/navire/listedossier";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
      this.listedossiernavire = response.listedossiernavire;
    }, (erreur:any) => {
    })
  }

  listeAgentsnavire(): void{
    this.apiUrl = "SERVER/gestionnavire/navire/listeAgentsnavire";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
      this.liste_gents_navires = response.liste_gents_navires;
    }, (erreur:any) => {
    })
  }

  listeProvenance(): void{
    this.apiUrl = "SERVER/gestionnavire/navire/listeProvenanceNavire";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
      this.liste_des_provenances = response.liste_des_provenances;
    }, (erreur:any) => {
    })
  }

   AfficherDonner(id:any): void{
      this.apiUrl = "SERVER/gestionnavire/navire/information";
      var data = {idGestionNavire:id};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
          this.DOSSIERModel = response.listedossiernavire[0];
          this.dataSousMenuModel = response.sousmenu;
          this.totalPoidsDossier = response.totalPoidsDossier;
          this.totalQteDossier = response.totalQteDossier;
          this.ligneSelectionne = -1;
          for(var i = 0; i < response.photos.length; i++){
            response.photos[i].src = this.ConfigurationService.configuration.ServerWebHostname + "/" + response.photos[i].src;
            this.photos_previsualisation = response.photos;
           }

          this.search("saisir");
      }, (erreur:any) => {
    })
  }

  SupprimerDossierPopup(id:any, numdoss:any): void{
    $(".suppdossier").trigger("click");
    this.suppiddossier.id = id;
    this.suppiddossier.NumDoss = numdoss;
  }

  SupprimerDossier(): void{
      this.apiUrl = "SERVER/gestionnavire/navire/supprimer";
      var data = {idGestionNavire:this.suppiddossier.id};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.suppiddossier = {};
        this.listeDossierNavire();
      }, (erreur:any) => {
      })
  }

  enregistrer_sous_menu(): void{
      var data = {
          "idContenuNavire": 0,
          "PortChargement": "",
          "date_arrive_prevu": "",
          "reference": "",
          "idClient": 0,
          "nom_societe": "",
          "idProduit": 0,
          "designationProduit": "",
          "cale": "",
          "PoidsNet": 0,
          "qte": 0,
          "iduser": 0,
          "ordre": 0,
          "lieuDechargement": "",
          "idDossier": this.DOSSIERModel.idGestionNavire,
          "date": ""
      }

      this.apiUrl = "SERVER/gestionnavire/contenuenavire/enregistrer";
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
      ).subscribe((value:any) => {
        this.listeSousMenuMenus();
      }, (erreur:any) => {
      })


  }

  ImpressionFiche(Id:any){
      this.lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/pdf_DossierNavire.php?iddoss="+Id;
      $("#printRapport").remove();
      $("body").append('<div id="printRapport">\
                          <div class="col-md-12 form-group" style="display:none;">\
                            <button class="btn btn-primary btn-sm bs-modal-frame-sm-btn" data-backdrop="static" data-toggle="modal" data-target=".bs-modal-frame-sm"></button>\
                          </div>\
                          <div class="modal fade bs-modal-frame-sm" tabindex="-1" role="dialog" aria-hidden="true">\
                            <div class="modal-dialog" role="document">\
                                <div class="modal-content">\
                                    <div class="row container_modal" style="width:100%;">\
                                    <div class="modal-header" style="margin-bottom:0px;">\
                                      <h6 class="modal-title" style="font-weight: bold;">IMPRESSION FICHE</h6>\
                                      <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>\
                                      </button>\
                                    </div>\
                                    <div class="row col-md-12" style="width:100%;height: calc(100vh - 90px);padding-left: 0px; padding-right: 0px; margin-left: 0px; margin-right: 0px;">\
                                      <div class="col-md-12 waiting">\
                                        <div class="snippet" data-title=".dot-pulse">\
                                          <div class="stage">\
                                            <div class="dot-pulse"></div>\
                                          </div>\
                                        </div>\
                                      </div>\
                                      <iframe class="rapportframe" name="rapportframe" style="width:100%;"></iframe>\
                                    </div>\
                                </div>\
                              </div>\
                            </div>\
                          </div>\
                      </div>');
      $(".bs-modal-frame-sm-btn").trigger("click");
      window.open(this.lien_fiche_ExpST , "rapportframe")?.focus();
      $(".rapportframe").css("display", "none");
      $('.rapportframe').on('load', function(){
          $(".waiting").css("display", "none");
          $(".rapportframe").css("display", "initial");
      });
    }

  supprimer_sous_menu(idsousmenu:any, index:any, idproduit:any): void{
      if(this.DOSSIERModel.idGestionNavire == 0){
        this.dataSousMenuModel.splice(index,1);
        this.CalculPoidsQte();
      }else{
          this.apiUrl = "SERVER/gestionnavire/contenuenavire/supprimer";
            var data = {idContenuNavire:idsousmenu, idDossier:this.DOSSIERModel.idGestionNavire , idProduit:idproduit};
            this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
              retryWhen(error =>
                error.pipe(
                  scan((acc:any, error:any) => {
                    if (acc > 2) throw error;
                    return acc + 1;
                  }, 1),
                  delay(1000)
                )
              )
          ).subscribe((value:any) => {
              this.listeSousMenuMenus();
              this.DOSSIERModel.total_poids = value.total[0].PoidsTotal;
              this.DOSSIERModel.total_qte = value.total[0].QteTotal;
            }, (erreur:any) => {

          })
      }

  }


  listeSousMenuMenus(): void{
    this.apiUrl = "SERVER/gestionnavire/contenuenavire/listesousmenu";
    var data = {idGestionNavire:this.DOSSIERModel.idGestionNavire};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
      this.dataSousMenuModel = response.liste_sous_menu;
      this.CalculPoidsQte();
    }, (erreur:any) => {
    })
  }


  RechercheDossier(data:any): void{
      this.apiUrl = "SERVER/gestionnavire/navire/recherche";
      var data = data;
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
      if(this.recherche.numeroDossier == "" && this.recherche.navire == "" && this.recherche.date_saisie == "" && this.recherche.date_arrive_prevu == ""){
        this.listeDossierNavire();
      }
       this.listedossiernavire= value.listedossiernavire;
      }, (erreur:any) => {
      })
  }

  nouvelle_provenance(): void{
        var apiUrl = "SERVER/gestionnavire/navire/enregistrer_provenance";
        var d = {"provenance": this.Libprovenance};
        this.transfertDataService.EnvoiData(d, apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((value:any) => {
            this.listeProvenance();
            this.DOSSIERModel.provenance = value.idprovenance;
            this.Libprovenance.libelle = "";
         }, (erreur:any) => {

         })

  }


  nouvelle_agent_navire(): void{
        var apiUrl = "SERVER/gestionnavire/navire/enregistrer_agentnavire";
         var d = {"agentnavire": this.agentnavire};
         this.transfertDataService.EnvoiData(d, apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((value:any) => {
           this.listeAgentsnavire();
           this.DOSSIERModel.agent_navire = value.idagentnavire;
           this.agentnavire.nom = "";
         }, (erreur:any) => {

         })

     }



}
