import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-etat-journalier-mouvement-conteneur',
  templateUrl: './etat-journalier-mouvement-conteneur.component.html',
  styleUrls: ['./etat-journalier-mouvement-conteneur.component.css']
})
export class EtatJournalierMouvementConteneurComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
