export class Menu {
  public id_menu: number = 0;
  public nom_menu: string = "";
  public icone: string = "";
  public ordre: number = 0;
  public url: string = "";
  public date: string = "";
  public idUser: number = 0;
  public modifierpar: number = 0;
  public datemodification: string = "";
  constructor() {

  }

}
