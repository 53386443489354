<div class="x_panel x_panel_close">
    <div class="x_content">
       <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;"><span ><i  class="fa fa-arrow-left"></i></span>
           <div  class="ml-auto text-right"><span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span><i  class="fa fa-chevron-right"></i><span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Exploitation - Récapitulatif Déchargements</span>
           </div>
       </div>
       <div class="">
        <div class="form-group col-xs-12 col-md-12 recherche_liste">
            <div class="row">
               <div class="form-group col-xs-12 col-md-3">
                    <div class="persoautocomplete">
                        <label for="">N°Dossier*</label>
                        <input [(ngModel)] = "dossierDechargement.numeroDossier" type="text" name="NumDoss01" id="NumDoss01" placeholder="N°Dossier"  class="form-control form-control-sm" autocomplete="off" (focus)="reInitSearch()" (blur)="reInitSearchBlur()" disabled="disabled">
                        <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="">
                        </button>
                        <div class="classPerso  dropdown-menu">
                            <form class="px-0 py-3">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-12">
                                          <input type="text" [(ngModel)]="dossierDechargement.numeroDossier1" class="form-control form-control-sm" placeholder="N°Dossier" name="NumDossSaisi03" (ngModelChange)="ListeAutoCompleteDossier(dossierDechargement.numeroDossier1)" autocomplete="off">
                                          <div>
                                            <br>
                                              <table class="table_bloc_liste tableReseach">
                                                  <thead class="theadfixed">
                                                      <th>N°Dossier</th>
                                                  </thead>
                                                  <tbody>
                                                      <tr class="SelectTable"  *ngFor="let x of ListeAutoCompleteDoss" (click)="SelectionAutocomplete(x)">
                                                          <td>{{x.numeroDossier}}</td>
                                                      </tr>
                                                  </tbody>
                                              </table>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                          </div>
                    </div>
              </div>
              <div class="form-group col-xs-12 col-md-3">
                  <label for="num_navire">Navire</label>
                  <input type="text" id="num_navire" [(ngModel)] = "dossierDechargement.Navire" class="form-control form-control-sm" placeholder="Navire" disabled="disabled">
              </div>
              <div class="form-group col-xs-12 col-md-3">
                  <label for="Recherche_date_dechargement">Date déchargement</label>
                  <input type="date" id="Recherche_date_dechargement" [(ngModel)] = "dossierDechargement.date_operation" class="form-control input-date form-control-sm" placeholder="Date déchargement">
              </div>
              
              <div class="form-group col-xs-12 col-md-3">
                  <label for="Recherche_navire">&nbsp;</label>
                  <br>
                  <button type="button" (click)="Recapitulatif()" class="btn btn-primary btn-sm">Rechercher</button>
              </div> 
            </div>
        </div>

        <div  *ngFor="let x of RecapitulatifData">
             <div>
                  <div class="x_content">
                      <div style="width: 100%;background: #e5e5e5;margin-bottom: 15px;">
                        <div style="width: 33%;display: inline-block;height: inherit;padding: 5px;text-align: center;font-size: 18px;font-weight: 600;text-align: center;">
                                <span style="display: block;font-size: 12px;">Date Déchargement</span>
                                <span style="display: block;color: #1184bd;font-size: 14px;">{{x.debut_operation}}</span>
                        </div>
                        <div style="width: 33%;display: inline-block;height: inherit;padding: 5px;text-align: center;font-size: 18px;font-weight: 600;text-align: center;">
                                <span style="display: block;font-size: 12px;"></span>
                                <span style="display: block;color: #1184bd;font-size: 14px;"></span>
                        </div>
                        <div style="width: 33%;display: inline-block;height: inherit;padding: 5px;text-align: center;font-size: 18px;font-weight: 600;text-align: center;">
                                <span style="display: block;font-size: 12px;">Période de Travail</span>
                                <span style="display: block;color: #1184bd;font-size: 14px;">{{x.jour_nuit}}</span>
                        </div>
                    </div>
                      <div class="form-row col-md-12 col-sm-12">
                        
                        <div class="form-group col-xs-12 col-md-12">
                            <div class="block_tableau">
                                <div class="panel-body">
                                    <legend style="width: max-content;padding: 5px;background: #f8f8f8;text-align: center;margin-bottom: 5px;font-size: 16px;border-radius: 3px;font-weight: 500;">Clients Déchargement</legend>
                                    <table class="table_bloc_liste">
                                        <thead>
                                            <tr>
                                              <th>
                                                Client
                                              </th>

                                              <th>
                                                Tonnage réalisé (Tonne)
                                              </th>

                                              <th>
                                                % Par client
                                              </th>

                                              <th>
                                                Coût à la tonne par client
                                              </th>

                                            </tr>

                                        </thead>
                                        <tbody>
                                          
                                          <tr *ngFor="let n of x.clients">
                                            <td>{{n.nom_societe}}</td>
                                            <td class="text-center">{{n.tonnage}}</td>
                                            <td class="text-center">{{n.pourcentage_tonnage}}</td>
                                            <td class="text-center"></td>                                                  
                                          </tr>
                                          <tr>
                                            <td><b>Total</b></td>
                                            <td class="text-center"><b>{{x.total_poids_decharge}}</b></td>
                                            <td class="text-center">100</td>
                                            <td class="text-center"></td>                                                  
                                          </tr>
                                          
                                    </tbody>
                                    </table>
                                    <br>
                                    <legend style="width: max-content;padding: 5px;background: #f8f8f8;text-align: center;margin-bottom: 5px;font-size: 16px;border-radius: 3px;font-weight: 500;">Coût Déchargement</legend>
                                    <table class="table_bloc_liste">
                                        <thead>
                                            <tr>
                                              <th>
                                                Désignation
                                              </th>

                                              <th>
                                                Qte
                                              </th>

                                              <th>
                                                Prix unitaire
                                              </th>

                                              <th>
                                                Coût
                                              </th>

                                              <th>
                                                Coût à la tonne
                                              </th>

                                            </tr>

                                        </thead>
                                        <tbody>
                                          
                                          <tr *ngFor="let n of x.charges_operation">
                                            <td>{{n.designation}}</td>
                                            <td class="text-center">{{n.qte}}</td>
                                            <td class="text-center">{{n.pu}}</td>
                                            <td class="text-center">{{n.cout}}</td>       
                                            <td class="text-center"></td>                                              
                                          </tr>
                                          <tr>
                                            <td></td>
                                            <td class="text-center"></td>
                                            <td class="text-center"><b>Total cout exploitation</b></td>
                                            <td class="text-center">{{x.cout_exploitation}}</td> 
                                            <td class="text-center"></td>                                                 
                                          </tr>
                                          
                                    </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                  </div>
            </div>
        </div>
    
    </div>
    </div>
</div>