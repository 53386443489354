export class MVT_PRODUIT {
  public id_mvt: number = 0;
  public id_produit_magasin: number = 0;
  public id_magasin: number = 0;
  public type_mvt: string = "";
  public qte: number = 0;
  public poids: number = 0;
  public date_mvt: string = "";
  public immat: string = "";
  public lieu_livraison: string = "";
  public clientapprovisionne: string = "";
  public provenance: string = "";
  public bl_lta: string = "";
  public bl_client: string = "";
  public ticket_depese: string = "";
  public observation: string = "";
  public numerofiche: string = "";
  public date_creation: string = "";
  constructor() {

  }

}
