import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { ConfigurationService } from '../../../config/configuration.service';
import { pipe } from 'rxjs';
import { AuthService } from '../../../services/connexion/auth.service';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';

@Component({
  selector: 'app-etat-journalier-livraison-import',
  templateUrl: './etat-journalier-livraison-import.component.html',
  styleUrls: ['./etat-journalier-livraison-import.component.css']
})
export class EtatJournalierLivraisonImportComponent implements OnInit {
  modelAutocompleCamion:any = {};
  liste_Camions:any = [];
  apiUrl:String = "";
  recherche:any = {};
  conteneur:any = {};
  listeConteneurs:any = [];
  amateur:any = [];
  chauffeur:any = [];
  camion:any = [];
  liste_pagination:any = [];
  activePagination:number = 0;

   constructor(private ConfigurationService : ConfigurationService,private transfertDataService : transfertDataService, private AuthService : AuthService) { }

  ngOnInit(): void {
    var date = new Date();
    this.listeRetour();
    this.listeChauffeur();
    this.listeCamion();
    this.listeAmateur();
    this.recherche.amateur = "";
    this.recherche.numero = "";
    this.recherche.bl = "";
    this.recherche.dateDebut = date.getFullYear()+"-"+"01"+"-"+"01";
    this.recherche.dateFin = date.getFullYear()+"-"+"12"+"-"+"31";

    this.listeAmateur();
  }

  listeAmateur(){
    this.apiUrl = "SERVER/importreporting/conteneurrecupere/nomAmateur";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((response:any) => {
         this.amateur = response.liste;

       }, (erreur:any) => {
           console.log(erreur.status);
       })

  }


  listeChauffeur(){
    this.apiUrl = "SERVER/importreporting/conteneurrecupere/nomChauffeur";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((response:any) => {
         this.chauffeur = response.liste;

       }, (erreur:any) => {
           console.log(erreur.status);
       })

  }

  listeCamion(){
    this.apiUrl = "SERVER/importreporting/conteneurrecupere/nomCamion";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((response:any) => {
         this.camion = response.liste;

       }, (erreur:any) => {
           console.log(erreur.status);
       })

  }

  listeRetour(): void{
    this.apiUrl = "SERVER/importreporting/conteneurrecupere/liste";
    let annee = new Date();
    let date1 = annee.getFullYear()+"-"+"01"+"-"+"01";
    let date2 = annee.getFullYear()+"-"+"12"+"-"+"31";
    let data = {date1:date1,date2:date2};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((response:any) => {
         this.listeConteneurs = response.liste;
         this.Calcule();
       }, (erreur:any) => {
           console.log(erreur.status);
       })

  }





  recherch(data:any): void{
    if(this.recherche.numConteneur == "" && this.recherche.dateDebut == "" && this.recherche.dateFin == "" && 
        this.recherche.amateur == "" && this.recherche.camion == "" && this.recherche.chauffeur == "")
     this.listeRetour();
     else{
       this.apiUrl = "SERVER/importreporting/conteneurrecupere/recherche";
       var data = data;
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
     ).subscribe((value:any) => {
         this.listeConteneurs= value.data;
         this.Calcule();
       }, (erreur:any) => {
           console.log(erreur.status);
       })
     }

 }

PaginationNb(index:number) : void{
  var data = {"index":index,  "data": this.recherche};
  this.apiUrl = "SERVER/importreporting/conteneurrecupere/PaginationNb";
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
       retryWhen(error =>
         error.pipe(
           scan((acc:any, error:any) => {
             if (acc > 2) throw error;
             return acc + 1;
           }, 1),
           delay(1000)
         )
       )
    ).subscribe((value:any) => {
       this.listeConteneurs = value.data;

     }, (erreur:any) => {
    })

}

Calcule() : void{
  this.apiUrl = "SERVER/importreporting/conteneurrecupere/CalculePagination";
    this.transfertDataService.EnvoiData(this.recherche, this.apiUrl).pipe(
       retryWhen(error =>
         error.pipe(
           scan((acc:any, error:any) => {
             if (acc > 2) throw error;
             return acc + 1;
           }, 1),
           delay(1000)
         )
       )
    ).subscribe((value:any) => {
       this.liste_pagination = value.data;
     }, (erreur:any) => {
    })
}

CurrentPagePagination(data:any, index:number, ev:any): void{
    this.activePagination = index;
    this.PaginationNb(data.index);
}


  Exporter(): void{
    var lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/importreporting/excel_recupconteneur.php?numConteneur="+this.recherche.numero+"&amateur="+this.recherche.amateur+"&date1="+this.recherche.date1+"&date2="+this.recherche.date2+"&bl="+this.recherche.bl;
        window.open(lien_fiche_ExpST , '_blank')?.focus();
  }

  ConfirmationImpressionFormulaire(){
         var lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/importreporting/pdfrecupconteneur.php?numConteneur="+this.recherche.numero+"&amateur="+this.recherche.amateur+"&date1="+this.recherche.date1+"&date2="+this.recherche.date2+"&bl="+this.recherche.bl;
       $("#printRapport").remove();
         $("body").append('<div id="printRapport">\
                             <div class="col-md-12 form-group" style="display:none;">\
                               <button class="btn btn-primary btn-sm bs-modal-frame-sm-btn" data-backdrop="static" data-toggle="modal" data-target=".bs-modal-frame-sm"></button>\
                             </div>\
                             <div class="modal fade bs-modal-frame-sm" tabindex="-1" role="dialog" aria-hidden="true">\
                               <div class="modal-dialog" role="document">\
                                   <div class="modal-content">\
                                       <div class="row container_modal" style="width:100%;">\
                                       <div class="modal-header" style="margin-bottom:0px;">\
                                         <h6 class="modal-title" style="font-weight: bold;">LISTE CONTENEUR RECUPERES</h6>\
                                         <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>\
                                         </button>\
                                       </div>\
                                       <div class="row col-md-12" style="width:100%;height: calc(100vh - 90px);padding-left: 0px; padding-right: 0px; margin-left: 0px; margin-right: 0px;">\
                                         <div class="col-md-12 waiting">\
                                           <div class="snippet" data-title=".dot-pulse">\
                                             <div class="stage">\
                                               <div class="dot-pulse"></div>\
                                             </div>\
                                           </div>\
                                         </div>\
                                         <iframe class="rapportframe" name="rapportframe" style="width:100%;"></iframe>\
                                       </div>\
                                   </div>\
                                 </div>\
                               </div>\
                             </div>\
                         </div>');
         $(".bs-modal-frame-sm-btn").trigger("click");
         window.open(lien_fiche_ExpST , "rapportframe")?.focus();
         $(".rapportframe").css("display", "none");
         $('.rapportframe').on('load', function(){
             $(".waiting").css("display", "none");
             $(".rapportframe").css("display", "initial");
         });
  }

}
