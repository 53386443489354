export class compagnie_maritime {
  public idCompagnie: number = 0;
  public nom_compagnie: string = "";
  public pays: string = "";
  public telephone: string = "";
  public mail: string = "";
  public user: string = "";
  public date: string = "";
  constructor() {  
   
  }
 
}