export class PRODUITDISPO {
  public id_produit_dispo_magasin: number = 0;
  public id_produit_magasin: number = 0;
  public id_magasin: number = 0;
  public qte_dispo: number = 0;
  public poids_dispo: number = 0;
  constructor() {

  }

}
