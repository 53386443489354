import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { pipe } from 'rxjs';
import { HttpClient, HttpHeaders, HttpEventType
 } from '@angular/common/http';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { AuthService } from '../../../services/connexion/auth.service';
import { historiquemvt } from '../../../classes/historiquemvt';
import { ConfigurationService } from '../../../config/configuration.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-saisir-livraison-conteneur',
  templateUrl: './saisir-livraison-conteneur.component.html',
  styleUrls: ['./saisir-livraison-conteneur.component.css']
})
export class SaisirLivraisonConteneurComponent implements OnInit {
  activeTab:String="saisir";
  apiUrl:String = "";
  Conteneur : any = {};
  mvt : any = {};
  recherche: any = {};
  liste_conteneurRech : any = [];
  liste_AmateurRech : any = [];
  liste_mvtRech : any = [];
  liste_camionSelecte : any = [];
  liste_chauffeurSelecte : any = [];
  listehistorique : any = [];
  historique : any = {};
  supp_id:any = "";
  historiqueBL : any = [];
  liste_MouvementContenur : any = [];
  liste_ModeLivraison : any = [];
  liste_agents : any = [];

  historiquemvt:historiquemvt = new historiquemvt();

  constructor(private HttpClient : HttpClient,private transfertDataService : transfertDataService, private sanitizer:DomSanitizer, private AuthService : AuthService,private ConfigurationService : ConfigurationService) { }

  ngOnInit(): void {
     this.listeHistorique();
     this.listeCamion();
     this.listeChauffeur();
     this.listeMouvement();
     this.listemodelivraison();
     this.listeAgents();
     this.recherche.numConteneur1="";
    this.recherche.dateDebut="";
    this.recherche.dateFin="";
    this.recherche.amateur1="";
    this.recherche.mouvementConteneur="";
  }

  VerificationChampsObligatoire(tableau_champs_obligatoires:any){
    var verification_formulaire = true;
    for (var i = 0; i < tableau_champs_obligatoires.length; i++) {
        if($(tableau_champs_obligatoires[i]).val() == "" || $(tableau_champs_obligatoires[i]).val() == null || $(tableau_champs_obligatoires[i]).val() == "? string: ?"){
                $(tableau_champs_obligatoires[i]).css("border","1px solid red");
                verification_formulaire = false;
                document.getElementById(tableau_champs_obligatoires[i].replace("#",""))?.scrollIntoView({behavior: "smooth"});
                break;
        }else{
            $(tableau_champs_obligatoires[i]).css("border","");
        }
    }

    return verification_formulaire;
  }

  Enregistrer(){
   //Champ obligatoire
   if(this.VerificationChampsObligatoire(["#Conteneur1"]) == false){
     return;
   }

   var infoUser = this.AuthService.getInfoUtilisateur();

   if(this.historiquemvt.idhistoriquemvt == 0){
     this.apiUrl = "SERVER/importlivraisonconteneur/saisirlivraisonconteneur/enregistrer";
     this.historiquemvt.creerPar = infoUser.id;

   }else{
     this.apiUrl = "SERVER/importlivraisonconteneur/saisirlivraisonconteneur/modifier";
     this.historiquemvt.modifierPar = infoUser.id;
   }

   this.historiquemvt.idconteneurImport = Number(this.historiquemvt.idconteneurImport);
   this.historiquemvt.idmvt = Number(this.historiquemvt.idmvt);
   this.historiquemvt.idcamion = Number(this.historiquemvt.idcamion);
   this.historiquemvt.idchauffeur = Number(this.historiquemvt.idchauffeur);

   var d = {"historique": this.historiquemvt};
   this.transfertDataService.EnvoiData(d, this.apiUrl).pipe(
           retryWhen(error =>
              error.pipe(
                scan((acc:any, error:any) => {
                  if (acc > 2) throw error;
                  return acc + 1;
                }, 1),
                delay(1000)
              )
            )
        ).subscribe((value:any) => {
         this.search("profile");
         this.listeHistorique();
          this.Annuler();
        }, (erreur:any) => {
            console.log(erreur.status);
   })
 }

 UpdateHistorique(x:any): void {
  
  this.apiUrl = "SERVER/importlivraisonconteneur/saisirlivraisonconteneur/updateHistorique";
  var data = {"data" : x};
  this.transfertDataService.EnvoiData(x, this.apiUrl).pipe(
          retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((value:any) => {
       }, (erreur:any) => {
           console.log(erreur.status);
  })
 }

 NouvelHistorique(x:any): void {
  var infoUser = this.AuthService.getInfoUtilisateur();
  this.apiUrl = "SERVER/importlivraisonconteneur/saisirlivraisonconteneur/nouvelhistorique";
  var data = {"idconteneurImport": x.idConteneurImport, creerPar : infoUser.id};
  this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
          retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((value:any) => {
          this.HistoriqueMvtConteneur(this.Conteneur.numBL);
       }, (erreur:any) => {
          
  })
}

SuppHistorique(x:any): void {
  var apiUrl = "SERVER/importlivraisonconteneur/saisirlivraisonconteneur/supprimerHistorique";
  this.transfertDataService.EnvoiData(x, apiUrl).pipe(
          retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((value:any) => {
          this.HistoriqueMvtConteneur(this.Conteneur.numBL);
       }, (erreur:any) => {
          
  })
}

HistoriqueMvtConteneur(numBL:Number): void {
  
  this.apiUrl = "SERVER/importlivraisonconteneur/saisirlivraisonconteneur/historiqueMvtConteneur";
  var data = {"numBL": numBL};
  this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
          retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((value:any) => {
          this.historiqueBL = value;
       }, (erreur:any) => {
          
  })
}

  Annuler(): void {
    this.historiquemvt = new historiquemvt();
    this.Conteneur = {};
    this.mvt = {};
  }
  search(activeTab:String){
     this.activeTab=activeTab
   };
   /*AUTOCOMPLET CONTENEUR*/

   reInitSearchFormconteneur(): void{
     this.liste_conteneurRech = [];
   }

   reInitSearchBlurFormconteneur(): void{
     setTimeout((val:any) => {
         this.liste_conteneurRech = [];
     }, 500);
   }
   listeHistorique(){
     this.apiUrl = "SERVER/importlivraisonconteneur/saisirlivraisonconteneur/liste";
         var data = {};
         this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
                  error.pipe(
                    scan((acc:any, error:any) => {
                      if (acc > 2) throw error;
                      return acc + 1;
                    }, 1),
                    delay(1000)
                  )
                )
            ).subscribe((response:any) => {
              this.listehistorique = response.liste;

            }, (erreur:any) => {
                console.log(erreur.status);
     })

   }

   listeAgents() : void{
    this.apiUrl = "SERVER/importlivraisonconteneur/saisirlivraisonconteneur/listeagents";
        var data = {};
        this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
            retryWhen(error =>
                 error.pipe(
                   scan((acc:any, error:any) => {
                     if (acc > 2) throw error;
                     return acc + 1;
                   }, 1),
                   delay(1000)
                 )
               )
           ).subscribe((response:any) => {
             this.liste_agents = response.liste;

           }, (erreur:any) => {
               console.log(erreur.status);
    })

  }

   listemodelivraison() : void{
    this.apiUrl = "SERVER/importlivraisonconteneur/saisirlivraisonconteneur/listemodelivraison";
        var data = {};
        this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
            retryWhen(error =>
                 error.pipe(
                   scan((acc:any, error:any) => {
                     if (acc > 2) throw error;
                     return acc + 1;
                   }, 1),
                   delay(1000)
                 )
               )
           ).subscribe((response:any) => {
             this.liste_ModeLivraison = response.liste;

           }, (erreur:any) => {
               console.log(erreur.status);
    })

  }

   listeCamion() : void{
     this.apiUrl = "SERVER/importlivraisonconteneur/saisirlivraisonconteneur/listecamion";
         var data = {};
         this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
                  error.pipe(
                    scan((acc:any, error:any) => {
                      if (acc > 2) throw error;
                      return acc + 1;
                    }, 1),
                    delay(1000)
                  )
                )
            ).subscribe((response:any) => {
              this.liste_camionSelecte = response.liste;

            }, (erreur:any) => {
                console.log(erreur.status);
     })

   }
   listeChauffeur() : void{
     this.apiUrl = "SERVER/importlivraisonconteneur/saisirlivraisonconteneur/listechaffeur";
         var data = {};
         this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
                  error.pipe(
                    scan((acc:any, error:any) => {
                      if (acc > 2) throw error;
                      return acc + 1;
                    }, 1),
                    delay(1000)
                  )
                )
            ).subscribe((response:any) => {
              this.liste_chauffeurSelecte = response.liste;

            }, (erreur:any) => {
                console.log(erreur.status);
     })

   }

   listeMouvement() : void{
    this.apiUrl = "SERVER/importlivraisonconteneur/saisirlivraisonconteneur/listeMouvement";
        var data = {};
        this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
            retryWhen(error =>
                 error.pipe(
                   scan((acc:any, error:any) => {
                     if (acc > 2) throw error;
                     return acc + 1;
                   }, 1),
                   delay(1000)
                 )
               )
           ).subscribe((response:any) => {
             this.liste_MouvementContenur = response.liste;

           }, (erreur:any) => {
               console.log(erreur.status);
    })

  }

   ListeAutoCompleteConteneurrech(numbl:any) : void{

     this.apiUrl = "SERVER/importlivraisonconteneur/saisirlivraisonconteneur/listeConteneurrecherche";
     var data = {numbl:numbl};
     this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
     ).subscribe((response:any) => {
       this.liste_conteneurRech = response.liste_conteneur;
     }, (erreur:any) => {})

   }

   SelectionAutocompleterechconteneur(data:any): void{
     this.Conteneur.numBL= data.numBL;
     this.Conteneur.idconteneurImport = data.idConteneurImport;
     this.HistoriqueMvtConteneur(this.Conteneur.numBL);
   }

   /*********************************************************/
   /*AUTOCOMPLET MVT*/

   reInitSearchFormMvt(): void{
     this.liste_mvtRech = [];
   }

   reInitSearchBlurFormMvt(): void{
     setTimeout((val:any) => {
         this.liste_mvtRech = [];
     }, 500);
   }

   ListeAutoCompleteMvtrech(mvt:any) : void{

     this.apiUrl = "SERVER/importlivraisonconteneur/saisirlivraisonconteneur/listeMvtrecherche";
     var data = {mvt:mvt};
     this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
     ).subscribe((response:any) => {
       this.liste_mvtRech = response.liste_mvt;
     }, (erreur:any) => {})

   }

   SelectionAutocompleterechMvt(data:any): void{
     this.mvt.mouvementConteneur= data.mouvementConteneur;
     this.historiquemvt.idmvt= data.idMouvementConteneur;
   }

   SelectionAutocompleterechMvt1(data:any): void{
     this.recherche.mouvementConteneur1 = data.mouvementConteneur;
     if(this.recherche.mouvementConteneur11 == "")
       this.recherche.mouvementConteneur1 = "";

       this.recherch(this.recherche);
   }
   /*********************************************************/
   reInitSearchBlurFormAmateur(): void{
     setTimeout((val:any) => {
         this.liste_AmateurRech = [];
     }, 500);
   }

   ListeAutoCompleteAmateurRech(amateur:any) : void{

     this.apiUrl = "SERVER/importlivraisonconteneur/traiterconteneur/listeamateurrecherche";
     var data = {amateur:amateur};
     this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
     ).subscribe((response:any) => {
       this.liste_AmateurRech = response.liste_amateur;
     }, (erreur:any) => {
     })

   }

   SelectionAutocompleteRecheche1(data:any): void{

     this.recherche.amateur1 = data.nomAmateur;
     if(this.recherche.amateurSaisi1 == "")
       this.recherche.amateur1 = "";

       this.recherch(this.recherche);

   }
   reInitSearchFormAmateur(): void{
     this.liste_AmateurRech = [];
   }


     information(id:any):void{
       this.apiUrl = "SERVER/importlivraisonconteneur/saisirlivraisonconteneur/information";
       var data = {id:id};
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
                 scan((acc:any, error:any) => {
                      if (acc > 2) throw error;
                      return acc + 1;
                    }, 1),
                    delay(1000)
                  )
                )
            ).subscribe((response:any) => {
                 this.historiquemvt = response.conteneur[0];
console.log(response.conteneur[0]["listeconteneur"][0]);
this.Conteneur.numConteneur= response.conteneur[0]["listeconteneur"][0].numConteneur;
this.historiquemvt.idconteneurImport=response.conteneur[0]["listeconteneur"][0].idConteneurImport;
this.Conteneur.type= response.conteneur[0]["listeconteneur"][0].type;
this.Conteneur.taille= response.conteneur[0]["listeconteneur"][0].taille;
this.Conteneur.numBL= response.conteneur[0]["listeconteneur"][0].numBL;
this.Conteneur.lieuLivraison= response.conteneur[0]["listeconteneur"][0].lieuLivraison;
this.Conteneur.client= response.conteneur[0]["listeconteneur"][0].nom_societe;
this.mvt.mouvementConteneur= response.conteneur[0].mouvementConteneur;
this.historiquemvt.idmvt= response.conteneur[0].idMouvementConteneur;

                 this.search("saisir");
              }, (erreur:any) => {
                console.log(erreur.status);
            })

        }

     SupprimerPopup(id:any): void{
       $(".suppliste").trigger("click");
       this.supp_id = id;
     }

     supprimer(id:any):void{
          this.apiUrl = "SERVER/importlivraisonconteneur/saisirlivraisonconteneur/supprimer";
          var data = {id:id};
          this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
            retryWhen(error =>
              error.pipe(
                scan((acc:any, error:any) => {
                  if (acc > 2) throw error;
                  return acc + 1;
                }, 1),
                delay(1000)
              )
            )
        ).subscribe((value:any) => {
            this.listeHistorique();
          }, (erreur:any) => {
              console.log(erreur.status);
          })

     }

     recherch(data:any): void{
        if(this.recherche.numConteneur1 == "" && this.recherche.dateDebut == "" && this.recherche.dateFin == "" && this.recherche.amateur1 == "" && this.recherche.mouvementConteneur1== "")
        this.listeHistorique();
        else{
          this.apiUrl = "SERVER/importlivraisonconteneur/saisirlivraisonconteneur/recherche";
          var data = data;
          this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
            retryWhen(error =>
              error.pipe(
                scan((acc:any, error:any) => {
                  if (acc > 2) throw error;
                  return acc + 1;
                }, 1),
                delay(1000)
              )
            )
        ).subscribe((value:any) => {
            this.listehistorique= value.data;
          }, (erreur:any) => {
              console.log(erreur.status);
          })
        }

    }


}
