<div class="x_panel x_panel_close">
        <div class="x_content">
            <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;">
                <span><i  class="fa fa-arrow-left"></i></span>
                <div  class="ml-auto text-right">
                    <span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span>
                          <i class="fa fa-chevron-right"></i>
                    <span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Gestion Magasins - Sorties Magasins
                    </span>
             </div>
            </div>
            <br>
               <div class="col-md-12">
                            <div class="content-action">
                                 <ul class="nav nav-tabs bar_tabs" id="myTab" role="tablist">
                                          <li class="nav-item">
                                            <a class="nav-link active" id="saisir-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='saisir'}" (click)="search('saisir')"
                                               role="tab" aria-controls="saisir" aria-selected="true">Saisir Nouvelle Sortie Magasin</a>
                                          </li>
                                          <li class="nav-item">
                                            <a class="nav-link" id="profile-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='profile'}" (click)="search('profile')"
                                                role="tab" aria-controls="profile" aria-selected="false">Liste des Sorties Magasins</a>
                                          </li>
                                    </ul>
                                    <div class="tab-content" id="myTabContent">
                                        <div [ngClass]="{ 'show':activeTab==='saisir', 'active':activeTab==='saisir'}"
                                        class="tab-pane fade active show" id="saisir" role="tabpanel" aria-labelledby="saisir-tab">

                                          <br>
                                           <div class="x_panel x_panel_close">
                                                  <div class="x_title">
                                                        <h5>Saisir Sortie Magasin</h5>
                                                        <ul class="nav navbar-right panel_toolbox" style="padding-left: 0px;"></ul>
                                                        <div class="clearfix"></div>
                                                  </div>
                                                  <div class="col-md-12">
                                                    <div class="row">
                                                        <div class="form-group col-xs-12 col-md-6">
                                                            <label for="Recherche_magasin">Magasin*</label>
                                                            <select class="form-control form-control-sm" name="listeMagasins" id="listeMagasins" [(ngModel)] = "magasinModel.idMagasin">
                                                                <option *ngFor = "let x of liste_magasins" value="{{x.idMagasin}}">{{x.NomMagasin}}</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-md-6 form-group">
                                                            <div class="persoautocomplete">
                                                            <label for="">N°Dossier*</label>
                                                           <input type="text"  class="form-control form-control-sm" id="numeroDossierSortieMag" name="numeroDossierSortieMag" [(ngModel)] = "dossierAuto.numDossier" autocomplete="off" [disabled]="true" placeholder="N°Dossier">
                                                            <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                                                                <div class="classPerso  dropdown-menu">
                                                                  <form class="px-0">
                                                                      <div class="col-md-12">
                                                                          <div class="row">
                                                                              <div class="col-md-12">
                                                                                <label>N°Dossier</label>
                                                                                <input type="text" [(ngModel)] = "dossierAuto.numeroDossier" class="form-control form-control-sm" placeholder="N°Dossier" (ngModelChange)="ListeAutoCompleteDossier(dossierAuto.numeroDossier)" name="dosss1">
                                                                                <div>
                                                                                  <br>
                                                                                    <table class="table_bloc_liste tableReseach">
                                                                                        <thead class="theadfixed">
                                                                                            <th>N°Dossier</th>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <tr class="SelectTable" *ngFor="let x of listauto" (click)="SelectionAutocomplete(x)">
                                                                                                <td>{{x.numeroDossier}}</td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  </form>
                                                                </div>
                                                              </div>
                                                        </div>

                                                        <div class="col-md-6 form-group">
                                                           <label>Navire</label>
                                                           <input type="text" class="form-control form-control-sm" name="navire" autocomplete="off" placeholder="Navire" [(ngModel)] = "dossierAuto.NomCompagnie"  disabled="disabled">
                                                        </div>

                                                        <div class="col-md-6 form-group">
                                                            <label>Client*</label>
                                                            <select name="listeclientDossSortie" id="listeclientDossSortieMag" [(ngModel)] = "dossierAuto.idClient" class="form-control form-control-sm">
                                                                <option value="{{x.idClient}}" *ngFor="let x of listeClientsDossier">{{x.nom_societe}}</option>
                                                            </select>
                                                        </div>

                                                        <div class="col-md-6 form-group">
                                                            <div class="persoautocomplete">
                                                                <label for="">Produit*</label>
                                                                <input type="text"  class="form-control form-control-sm" name="ProduitSortieMag" id="ProduitSortieMag" [(ngModel)] = "produitAuto.nomSous" autocomplete="off" [disabled]="true" placeholder="Produit">
                                                                <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                </button>
                                                                <div class="classPerso  dropdown-menu">
                                                                  <form class="px-0">
                                                                      <div class="col-md-12">
                                                                          <div class="row">
                                                                              <div class="col-md-12">
                                                                                 <label>Produit*</label>
                                                                                    <input type="text" [(ngModel)] = "produitAuto.nomSousProduit" class="form-control form-control-sm" placeholder="Produit" (ngModelChange)="ListeAutoCompleteProduit(produitAuto.nomSousProduit)" name="prod1">
                                                                                <div>
                                                                                  <br>
                                                                                    <table class="table_bloc_liste tableReseach">
                                                                                        <thead class="theadfixed">
                                                                                            <th>Produit</th>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <tr class="SelectTable" *ngFor="let x of listautoSpecifik" (click)="SelectionAutocompleteProduit(x)">
                                                                                                <td>{{x.nomSousProduit}}</td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  </form>
                                                                </div>
                                                              </div>
                                                        </div>


                                                           <div class="col-md-6 form-group">
                                                                <label>Quantite*</label>
                                                                <input type="number" class="form-control form-control-sm" id="quantiteSortieMag" name="quantiteSortieMag" placeholder="quantite" autocomplete="off" [(ngModel)] = "produitdispoModel.qte_dispo" (ngModelChange)="PoidsTotalCalcule()">
                                                           </div>
                                                            <div class="col-md-6 form-group">
                                                                <label>Poids total (Tonne)*</label>
                                                                <input type="number" class="form-control form-control-sm" id="poids_totalSortieMag" name="poids_totalSortieMag" placeholder="Poids Total" autocomplete="off" [(ngModel)] = "produitdispoModel.poids_dispo" [disabled]="true">
                                                           </div>
                                                           <div class="col-md-6  form-group">
                                                            <label>Bl LTA</label>
                                                            <input type="text" class="form-control input-date form-control-sm" id="blltaSortieMag" name="blltaSortieMag" placeholder="Bl LTA" [(ngModel)] = "mvtModel.bl_lta" autocomplete="off">

                                                        </div>
                                                        <div class="col-md-6  form-group">
                                                            <label>Bl Client</label>
                                                            <input type="text" class="form-control input-date form-control-sm" id="blclientenSortieMag" name="blclientSortieMag" placeholder="Bl Client" [(ngModel)] = "mvtModel.bl_client" autocomplete="off">

                                                        </div>

                                                        <div class="col-md-6  form-group">
                                                            <label>Immat</label>
                                                            <input type="text" class="form-control input-date form-control-sm" id="immatSortieMag" name="immatSortieMag" placeholder="Immat" [(ngModel)] = "mvtModel.immat" autocomplete="off">

                                                        </div>

                                                        <div class="col-md-6  form-group">
                                                            <label>Ticket de pesé</label>
                                                            <input type="text" class="form-control input-date form-control-sm" id="ticketSortieMag" name="ticketSortieMag" placeholder="Ticket de pesé" [(ngModel)] = "mvtModel.ticket_depese" autocomplete="off">

                                                        </div>

                                                        <div class="col-md-6  form-group">
                                                            <label>N°Fiche</label>
                                                            <input type="text" class="form-control input-date form-control-sm" id="numficheSortie" name="numficheSortie" placeholder="N°Fiche" [(ngModel)] = "mvtModel.numerofiche" autocomplete="off">
                                                        </div>

                                                        <div class="col-md-6  form-group">
                                                            <label>Observation</label>
                                                            <textarea class="form-control input-date form-control-sm" name="observationentSortieMag" id="observationSortieMag" rows="3" placeholder="Observation" [(ngModel)] = "mvtModel.observation" autocomplete="off"></textarea>
                                                        </div>


                                                    </div>
                                                    <div class="row">
                                                        <div class="form-group col-md-6">
                                                            <div class="form-group">
                                                                <label for="exampleFormControlInput2">Date Sortie Stock*</label>
                                                                <input type="date" id="datsortieMag" placeholder="jj/mm/yyyy" class="form-control form-control-sm" [(ngModel)] = "this.mvtModel.date_mvt">
                                                            </div>
                                                     </div>
                                                    </div>
                                                  </div>
                                              </div>
                                            <br>
                                            <div class="col-md-12 text-center">
                                              <div class="card-body">
                                                   <span>Télécharger des Documents</span>
                                                   <br><br>
                                                   <div class="col-md-12">
                                                    <div class="download-card" style="text-align: left!important;">
                                                      <div class="col-md-12">
                                                        <div class="row">
                                                          <div class="inline"><br><br><img style ="width: 35px;" src="./assets/img/cloud.png"></div>
                                                          <div class="inline1"><br><br><p><span>&nbsp;Cliquez sur le bouton pour ajouter des fichers</span><br>&nbsp; <button type="button" class="btn btn-primary btn-sm btn-sm-perso" (click)="fileInputBlock.click()">PARCOURIR LES FICHIERS </button></p></div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                   </div>
                                                   <div class="row blockphotosZone" *ngIf="photos_previsualisation.length > 0">
                                                    <br><br>
                                                    <div class="col-md-3 photosZone" *ngFor="let x of photos_previsualisation" [ngClass]=" x.iddocument == '' ? 'uploadfile' : ''">
                                                        <span>
                                                          <span *ngIf="x.iddocument == ''" class="fichierToUpload">FICHIER NON ENREGISTRE</span>
                                                        </span>
                                                        <img [src]="x.src" id="file_{{x.iddocument}}">
                                                        <div class="text-center text-center pt-2 pb-2 action-band">
                                                          <button class="btn btn-sm btn-secondary" (click)="apercu(x.iddocument)">
                                                                <i class="fas fa-search"></i>
                                                            </button>&nbsp;&nbsp;
                                                            <button class="btn btn-sm btn-danger" (click)="supprimer_image(x.lien, x.iddocument)">
                                                                <i class="fas fa-trash"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>

                                                <input type="file" name="file" id="file" [hidden]="true" (change)="onFileChanged($event)" multiple="multiple" #fileInputBlock>
                                            </div>
                                            <br>
                                            <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="errorstockdispo == true">
                                                <strong>Attention!</strong> La quantité saisie pour le produit <b>{{produitAuto.nomSous}}</b> dépasse le stock disponible.
                                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                  <span aria-hidden="true">&times;</span>
                                                </button>
                                              </div>
                                              <br>
                                           <div class="col-sm-12 text-right mobile-buttons">
                                                      <button class="btn btn-primary btn-enregistrer" (click) = "Enregistrer($event)">
                                                          <font style="vertical-align: inherit;">
                                                              <font style="vertical-align: inherit;">Enregistrer</font>
                                                          </font>
                                                      </button>
                                                      &nbsp;
                                                      <button class="btn btn-secondary btn-enregistrer" (click) = "Annuler()">
                                                          <font style="vertical-align: inherit;">
                                                              <font style="vertical-align: inherit;">Annuler</font>
                                                          </font>
                                                      </button>
                                              </div>

                                        </div>

                                     <div class="tab-pane fade" id="profile" role="tabpanel"  aria-labelledby="profile-tab" [ngClass]="{'show':activeTab==='profile', 'active':activeTab==='profile'}">

                                        <br>
                                        <div class="row">
                                          <div class="form-group col-xs-12 col-md-12 recherche_liste">
                                            <div class="row">
                                                <div class="form-group col-xs-12 col-md-3">
                                                    <label for="Recherche_magasin">Nom magasin</label>
                                                    <select class="form-control form-control-sm" name="recherche_magasin" id="recherche_magasin" [(ngModel)] = "recherche.idMagasin" (ngModelChange)="RechercheTabListe(recherche)">
                                                        <option value=""></option>
                                                        <option *ngFor = "let x of liste_magasins" value="{{x.idMagasin}}">{{x.NomMagasin}}</option>
                                                    </select>
                                              </div>
                                              <div class="form-group col-xs-12 col-md-3">
                                                    <div class="persoautocomplete">
                                                    <label for="">N°Dossier</label>
                                                   <input [(ngModel)]="recherche.numeroDossier" type="text" name="recherchenumdossFormEntree" id="recherchenumdossFormEntree" placeholder="N°Dossier"  class="form-control form-control-sm" autocomplete="off" (focus)="reInitSearchForm()" (blur)="reInitSearchBlurForm()" disabled="disabled">
                                                     <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="">
                                                     </button>
                                                      <div class="classPerso  dropdown-menu">
                                                        <form class="px-0 py-3">
                                                            <div class="col-md-12">
                                                                <div class="row">
                                                                    <div class="col-md-12">
                                                                      <input type="text" [(ngModel)]="recherche.numeroDossier1" class="form-control form-control-sm" placeholder="N°Dossier" name="FormSaisiEntreeStock" (ngModelChange)="ListeAutoCompleteDossierrech(recherche.numeroDossier1)" autocomplete="off">
                                                                      <div>
                                                                        <br>
                                                                          <table class="table_bloc_liste tableReseach">
                                                                              <thead class="theadfixed">
                                                                                  <th>N°Dossier</th>
                                                                              </thead>
                                                                              <tbody>
                                                                                  <tr class="SelectTable"  *ngFor="let x of listdoss" (click)="SelectionAutocompleterech(x)">
                                                                                      <td>{{x.numeroDossier}}</td>
                                                                                  </tr>
                                                                              </tbody>
                                                                          </table>
                                                                      </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                      </div>
                                                    </div>
                                                </div>
                                              <div class="form-group col-xs-12 col-md-3">
                                                      <div class="persoautocomplete">
                                                        <label for="">Client</label>
                                                       <input [(ngModel)]="recherche.client" type="text" name="recherchenumdossFormEntree" id="recherchenumdossFormEntree" placeholder="Client"  class="form-control form-control-sm" autocomplete="off" (focus)="reInitSearchFormClient()" (blur)="reInitSearchBlurFormClient()" disabled="disabled">
                                                         <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="">
                                                         </button>
                                                          <div class="classPerso  dropdown-menu">
                                                            <form class="px-0 py-3">
                                                                <div class="col-md-12">
                                                                    <div class="row">
                                                                        <div class="col-md-12">
                                                                          <input type="text" [(ngModel)]="recherche.client1" class="form-control form-control-sm" placeholder="Client" name="FormSaisiEntreeStockRech" (ngModelChange)="ListeAutoCompleteClientrech(recherche.client1)" autocomplete="off">
                                                                          <div>
                                                                            <br>
                                                                              <table class="table_bloc_liste tableReseach">
                                                                                  <thead class="theadfixed">
                                                                                      <th>Client</th>
                                                                                  </thead>
                                                                                  <tbody>
                                                                                      <tr class="SelectTable"  *ngFor="let x of liste_ClientsRech" (click)="SelectionAutocompleterechClient(x)">
                                                                                          <td>{{x.nom_societe}}</td>
                                                                                      </tr>
                                                                                  </tbody>
                                                                              </table>
                                                                          </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                          </div>
                                                        </div>
                                              </div>
                                              <div class="form-group col-xs-12 col-md-3">
                                                      <div class="persoautocomplete">
                                                        <label for="">Produit</label>
                                                       <input [(ngModel)]="recherche.nom_produit_magasin" type="text" name="recherchenom_produit_magasinFormEntree" id="recherchenom_produit_magasinFormEntree" placeholder="Produit"  class="form-control form-control-sm" autocomplete="off" (focus)="reInitSearchFormEntreeProduit()" (blur)="reInitSearchBlurFormEntreeProduit()" disabled="disabled">
                                                         <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="">
                                                         </button>
                                                          <div class="classPerso  dropdown-menu">
                                                            <form class="px-0 py-3">
                                                                <div class="col-md-12">
                                                                    <div class="row">
                                                                        <div class="col-md-12">
                                                                          <input type="text" [(ngModel)]="recherche.nom_produit_magasin1" class="form-control form-control-sm" placeholder="Produit" name="FormSaisiEntreeProduitStockRech" (ngModelChange)="ListeAutoCompleteProduitrech(recherche.nom_produit_magasin1)" autocomplete="off">
                                                                          <div>
                                                                            <br>
                                                                              <table class="table_bloc_liste tableReseach">
                                                                                  <thead class="theadfixed">
                                                                                      <th>Produit</th>
                                                                                  </thead>
                                                                                  <tbody>
                                                                                      <tr class="SelectTable"  *ngFor="let x of liste_ProduitsRech" (click)="SelectionAutocompleterechProduit(x)">
                                                                                          <td>{{x.nom_produit_magasin}}</td>
                                                                                      </tr>
                                                                                  </tbody>
                                                                              </table>
                                                                          </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                          </div>
                                                        </div>
                                              </div>
                                              <div class="form-group col-xs-12 col-md-3">
                                                        <label for="">Période du</label>
                                                        <input type="date" class="form-control form-control-sm" name="recherche_magasin" id="recherche_magasin" [(ngModel)] = "recherche.date1" (ngModelChange)="RechercheTabListe(recherche)">
                                                  </div>
                                                   <div class="form-group col-xs-12 col-md-3">
                                                        <label for="">Au</label>
                                                        <input type="date" class="form-control form-control-sm" name="recherche_magasin" id="recherche_magasin" [(ngModel)] = "recherche.date2" (ngModelChange)="RechercheTabListe(recherche)">
                                                  </div>
                                            </div>
                                          </div>
                                          <table class="table_bloc_liste">
                                              <thead>
                                                <tr>
                                                <th>
                                                   Magasin
                                                </th>
                                                <th>
                                                   Date Sortir
                                                </th>
                                                <th>
                                                    N°Dossier
                                                </th>
                                                <th>
                                                    Navire
                                                </th>
                                                <th>
                                                    Client
                                                </th>
                                                <th>
                                                   Nom produit
                                                </th>
                                                <th>
                                                    Qte total
                                                </th>
                                                <th>
                                                    Poids total
                                                </th>
                                                <th>
                                                    Creer Par
                                                </th>
                                                <th>
                                                    Date création
                                                </th>
                                                <th>
                                                    Modifié par
                                                </th>
                                                <th>
                                                    Date Modif.
                                                </th>
                                                <th>
                                                </th>
                                                <th>
                                                </th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  <tr *ngFor="let n of liste_prodmagasins" class="ng-scope">
                                                      <td>{{n.NomMagasin}}</td>
                                                      <td>{{n.date_mvt}}</td>
                                                      <td>{{n.numeroDossier}}</td>
                                                      <td>{{n.Navire}}</td>
                                                      <td>{{n.nom_societe}}</td>
                                                      <td>{{n.nom_produit_magasin}}</td>
                                                      <td>{{n.qte}}</td>
                                                      <td>{{n.poids}}</td>
                                                      <td>{{n.creer_par}}</td>
                                                      <td>{{n.date_creation}}</td>
                                                      <td>{{n.modifierpar}}</td>
                                                      <td>{{n.date_modification}}</td>
                                                      <td class="text-center">
                                                            <button type="button" class="btn btn-info btn-sm" (click)="AfficherDonner(n.id_mvt)"><i class="fas fa-edit"></i></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-danger btn-sm" (click)="Supprimer(n.id_mvt)"><i class="far fa-trash-alt"></i></button>
                                                        </td>
                                                  </tr>
                                              </tbody>
                                          </table>

                                      </div>

                                      <div  class="col-sm-12 text-right mobile-buttons" *ngIf="liste_prodmagasins.length > 0">
                                        <br>
                                        <button  class="btn btn-success btn-sm btn-enregistrer" (click)="EtatExcel()">
                                          <font style="vertical-align: inherit;">
                                              <font style="vertical-align: inherit;">Exporter</font>
                                          </font>
                                      </button>&nbsp;&nbsp;
                                        <button _ngcontent-wyu-c52="" class="btn btn-secondary btn-sm btn-enregistrer" (click)="EtatPdf()">
                                            <font style="vertical-align: inherit;">
                                                <font style="vertical-align: inherit;">Imprimer</font>
                                            </font>
                                        </button>
                                    </div>

                                     </div>

                                    </div>
                              </div>
                        </div>

        </div>
</div>

<button type="button" class="btn btn-primary  btn-sm suppdossier" style="display:none;" data-backdrop="static" data-toggle="modal" data-target=".bs-Form-sm"></button>
<div class="modal fade bs-Form-sm" tabindex="-1" role="dialog" aria-hidden="true">
 <div class="modal-dialog modal-sm" role="document">
  <div class="modal-content">
      <div class="row container_modal">
          <div class="modal-header">
            <h6 class="modal-title" style="font-weight: bold;">ERREUR</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>
            </button>
          </div>
          <div>
             <div class="modal-content-dl">
              <p><b>Stock Indisponible !!!</b></p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" data-dismiss="modal"  class="btn btn-primary btn-sm" (click) = "search('saisir')">OK</button>
          </div>
      </div>
    </div>
  </div>
</div>
