export class magasin_attribute {
  public idmagasinattribue: number = 0;
  public iduser: number = 0;
  public idmagasin: number = 0;
  public date_attribution: string = "";
  public commentaire: string = "";
  public date: string = "";
  public modifierpar: number = 0;
  public datemodification: string = "";
  constructor() {
  }

}
