import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { fournisseur } from '../../../classes/fournisseur';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { AuthService } from '../../../services/connexion/auth.service';
@Component({
  selector: 'app-fournisseur',
  templateUrl: './fournisseur.component.html',
  styleUrls: ['./fournisseur.component.css']
})
export class FournisseurComponent implements OnInit {


  activeTab:String="saisir";
     apiUrl:String = "";
     listefourniseur:any = [];
     recherche:any = {};
     Libfournisseur:fournisseur = new fournisseur();
     supp_id:any = "";

      constructor(private transfertDataService : transfertDataService,private AuthService : AuthService) { }

     ngOnInit(): void {
       this.Liste_des_fournisseur();
     }
     search(activeTab:String){
        this.activeTab=activeTab
      };

       Annuler():void{
         this.Libfournisseur = new fournisseur();
         this.recherche.fournisseur = "";
        }

     //Fonction de verification de champs
     VerificationChampsObligatoire(tableau_champs_obligatoires:any){
         var verification_formulaire = true;
         for (var i = 0; i < tableau_champs_obligatoires.length; i++) {
             if($(tableau_champs_obligatoires[i]).val() == "" || $(tableau_champs_obligatoires[i]).val() == null || $(tableau_champs_obligatoires[i]).val() == "? string: ?"){
                     $(tableau_champs_obligatoires[i]).css("border","1px solid red");
                     verification_formulaire = false;
                     document.getElementById(tableau_champs_obligatoires[i].replace("#",""))?.scrollIntoView({behavior: "smooth"});
                     break;
             }else{
                 $(tableau_champs_obligatoires[i]).css("border","");
             }
         }

         return verification_formulaire;
     }

     enregistrer(data:any): void{
        if(this.VerificationChampsObligatoire(["#nomfourn"]) == false)
               return;


        if(this.Libfournisseur.id == 0)
          this.apiUrl = "SERVER/parametres/fournisseur/enregistrer";
        else
          this.apiUrl = "SERVER/parametres/fournisseur/modifier";

          var infoUser = this.AuthService.getInfoUtilisateur();
           this.Libfournisseur.idUser = infoUser.id;
          var d = {"fournisseur": this.Libfournisseur};
          this.transfertDataService.EnvoiData(d, this.apiUrl).pipe(
              retryWhen(error =>
                error.pipe(
                  scan((acc:any, error:any) => {
                    if (acc > 2) throw error;
                    return acc + 1;
                  }, 1),
                  delay(1000)
                )
              )
          ).subscribe((value:any) => {

            this.Liste_des_fournisseur();
            this.search("profile");
            this.Annuler();


          }, (erreur:any) => {
              console.log(erreur.status);
          })

      }

     supprimer(id:any):void{
        this.apiUrl = "SERVER/parametres/fournisseur/supprimer";
        var data = {id:id};
        this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
          retryWhen(error =>
            error.pipe(
              scan((acc:any, error:any) => {
                if (acc > 2) throw error;
                return acc + 1;
              }, 1),
              delay(1000)
            )
          )
      ).subscribe((value:any) => {
          this.Liste_des_fournisseur();
        }, (erreur:any) => {
            console.log(erreur.status);
        })

      }



      Liste_des_fournisseur(): void{

        this.apiUrl = "SERVER/parametres/fournisseur/listefournisseur";
        var data = {};
        this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
            retryWhen(error =>
              error.pipe(
                scan((acc:any, error:any) => {
                  if (acc > 2) throw error;
                  return acc + 1;
                }, 1),
                delay(1000)
              )
            )
        ).subscribe((response:any) => {
          this.listefourniseur = response.liste_fournisseur;
        }, (erreur:any) => {
            console.log(erreur.status);
        })

      }


      information(id:any):void{

            this.apiUrl = "SERVER/parametres/fournisseur/information";
            var data = {id:id};
            this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
              retryWhen(error =>
                error.pipe(
                  scan((acc:any, error:any) => {
                    if (acc > 2) throw error;
                    return acc + 1;
                  }, 1),
                  delay(1000)
                )
              )
          ).subscribe((response:any) => {
                this.Libfournisseur = response.fournisseur[0];
                this.search("saisir");

            }, (erreur:any) => {
              console.log(erreur.status);
          })

      }

      recherchefournisseurs(data:any): void{
        if(this.recherche.nomfourn == "")
        this.Liste_des_fournisseur();
        else{
          this.apiUrl = "SERVER/parametres/fournisseur/recherche";
          var data = data;
          this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
            retryWhen(error =>
              error.pipe(
                scan((acc:any, error:any) => {
                  if (acc > 2) throw error;
                  return acc + 1;
                }, 1),
                delay(1000)
              )
            )
        ).subscribe((value:any) => {
            this.listefourniseur= value.liste_fournisseur;
          }, (erreur:any) => {
              console.log(erreur.status);
          })
        }

    }

   SupprimerPopup(id:any): void{
     $(".suppliste").trigger("click");
     this.supp_id = id;
   }

}
