<div class="x_panel x_panel_close">
    <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;"><span ><i  class="fa fa-arrow-left"></i></span><div  class="ml-auto text-right"><span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span><i  class="fa fa-chevron-right"></i><span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Historique Utilisateur</span>
        </div>
    </div>
    <div class="x_content">
      <div class="col-md-12">
        <div class="content-action">
              <div class="row recherche_liste">
                <div class="col-sm-12 col-md-3">
								<div class="form-group">
									<label for="">Nom Utilisateur</label>
									<input type="text" id="" placeholder="Nom Utilisateur" name="historique_user" [(ngModel)]="recherche_historique.nom" class="form-control form-control-sm">
								</div>
							  </div>
                              <div class="col-sm-12 col-md-3">
								<div class="form-group">
									<label for="">Période</label>
									<input type="date" id="historique_user" placeholder="Période du" name="historique_du" [(ngModel)]="recherche_historique.date_debut" class="form-control form-control-sm">
								</div>
							  </div>
							  <div class="col-sm-12 col-md-3">
								<div class="form-group">
									<label for="">Au</label>
									<input type="date" id="" placeholder="Période au" name="historique_au" [(ngModel)]="recherche_historique.date_fin" class="form-control form-control-sm">
								</div>
							  </div>
                              <div class="col-sm-12  col-md-3">
                                <div class="form-group">
                                	<br>
                                  <button class="btn btn-primary btn-sm" (click)="listehistoriqueutilisateur()">
                                    <i class="fas fa-search"></i>&nbsp;
                                    <span>Rechercher</span>
                                  </button>
                                </div>
                              </div>
                        </div>
                        <br>
                        <div class="row table-Table_Longer">
		                        <table class="table_bloc_liste">
		                          <thead>
		                            <th>#</th>
																<th>Date</th>
																<th>Heure</th>
																<th>IP</th>
																<th>User</th>
																<th>Url</th>
		                          </thead>
		                          <tbody>
		                            <tr  *ngFor="let x of Liste_HistoriqueUtilisateur; let i = index">
		                                <td>{{i}}</td>
																		<td>{{x.date}}</td>
																		<td>{{x.time}}</td>
																		<td>{{x.ip}}</td>
																		<td>{{x.nom}}</td>
																		<td>{{x.url}}</td>
		                            </tr>
		                          </tbody>
		                        </table>
                       </div>

                
              
        </div>
 

            

      </div>  
    </div>
</div>


