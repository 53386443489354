<div class="x_panel x_panel_close">
        <div class="x_content">
            <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;"><span ><i  class="fa fa-arrow-left"></i></span><div  class="ml-auto text-right"><span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span><i  class="fa fa-chevron-right"></i><span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Import Tableau de Bord
              </span>
             </div>
            </div>
                 <div class="">
                        <div class="">
                            <div class="x_content">
                                <div class="col-md-12">
                                        <div class="recherche_liste row">
                                               <div class="form-group col-md-0" style="margin-bottom: 0;">
                                                
                                              </div>
                                              <div class="form-group col-md-2" style="margin-bottom: 0;">
                                                   <label for="staticEmail" class="col-form-label" style="padding-bottom: 0px;">Periode du</label>
                                              </div>
                                              <div class="form-group col-md-3" style="margin-bottom: 0;">
                                                   <input type="date" class="form-control form-control-sm" [(ngModel)] = "recherche.dateOuvertDebut" name="periodedu" id="periodedu" autocomplete="off"  placeholder="Periode du">
                                             </div>
                                             <div class="form-group col-md-1 text-center" style="margin-bottom: 0;">
                                                <label for="staticEmail" class="col-form-label" style="padding-bottom: 0px;">Au</label>
                                              </div>
                                             <div class="form-group col-md-3" style="margin-bottom: 0;">
                                               <input type="date" class="form-control form-control-sm" [(ngModel)] = "recherche.dateOuvertFin" name="periodeau" id="periodeau" autocomplete="off"  placeholder="N°Mission" >
                                              </div>
                                              <div class="form-group col-md-1" style="margin-bottom: 0;">
                                                <div class="form-group" style="margin-bottom: 5px;">
                                                      <button class="btn btn-primary btn-block btn-sm" (click)="ResumeTableauBord()">
                                                          <i class=" fas fa-search"></i>
                                                      </button>
                                               </div>
                                             </div>
                                        </div>
                                </div>
                                <br>
                                <div class="col-md-12">
                                   <div class="row">
                                      <div class="col-md-4" style="padding-left: 0;padding-right: 0;">
                                        <div class="x_panel x_panel_close">
                                          <div class="x_title x_title_assureur">
                                            <h6>Classement des Amateurs</h6>
                                            <ul class="nav navbar-right panel_toolbox">
                                            </ul>
                                            <div class="clearfix"></div>
                                          </div>
                                          <div class="x_content ">
                                            <div class="col-md-12">
                                              <div class="row">
                                                <div class="table-responsive">
                                                      <table class="table_bloc_liste table_bloc_liste_assureur">
                                                          <thead>
                                                              <tr>
                                                                <th>Amateur</th>
                                                                <th>Nombre de Conteneur</th>
                                                                <th>% Conteneur</th>
                                                              </tr>
                                                          </thead>
                                                          <tbody>
                                                            <tr *ngFor="let x of resume.quinzePlusGrosTonnageNavire">
                                                                <td style="width: 150px;">{{x.nomAmateur}}</td>
                                                                <td>{{x.TT}}</td>
                                                                <td>{{x.pourcentage}}%</td>
                                                            </tr>
                                                      </table>
                                                  </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <br>
                                        <div class="x_panel x_panel_close">
                                          <div class="x_title x_title_facilitateur">
                                            <h6>Les 10 clients ayant les plus de conteneurs</h6>
                                            <ul class="nav navbar-right panel_toolbox">
                                            </ul>
                                            <div class="clearfix"></div>
                                          </div>
                                          <div class="x_content ">
                                            <div class="col-md-12">
                                              <div class="row">
                                                <div class="table-responsive">
                                                      <table class="table_bloc_liste table_bloc_liste_facilitateur">
                                                          <thead>
                                                              <tr>
                                                                <th>Client</th>
                                                                <th>Nombre Total Conteneur</th>
                                                              </tr>
                                                          </thead>
                                                          <tbody>
                                                              <tr *ngFor="let x of resume.quinzePlusGrosClient">
                                                                <td>{{x.nom_societe}}</td>
                                                                <td>{{x.TTconteneur}}</td>
                                                              </tr>
                                                      </table>
                                                  </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-8">
                                          <div class="col-md-12">
                                            <div class="row">
                                              <div class="col-md-3">

                                                <div class="card-counter primary">
                                                    <i class="fas fa-ship"></i>
                                                    <span class="count-numbers">
                                                     {{resume.total_dossiers}}
                                                      <span style="font-size: 11px;position: relative;top: -8px;left: 5px;">{{resume.total_dossiers_pourcentage}}%
                                                      </span>
                                                    </span>
                                                    <span class="count-name">Total Conteneur</span>
                                                </div>
                                              </div>

                                              <div class="col-md-3">

                                                <div class="card-counter primary">
                                                    <i class="fas fa-pallet"></i>
                                                    <span class="count-numbers">{{resume.total_conteneur_livre}}<span style="font-size: 11px;position: relative;top: -8px;left: 5px;">
                                                      </span></span>
                                                    <span class="count-name">Total Conteneur Livré</span>
                                                </div>
                                              </div>

                                              <div class="col-md-3">

                                                <div class="card-counter primary">
                                                    <i class="fas fa-dolly"></i>
                                                    <span class="count-numbers">{{resume.total_conteneur_recupere}}<span style="font-size: 11px;position: relative;top: -8px;left: 5px;">
                                                      </span></span>
                                                    <span class="count-name">Total Conteneur Recuperé</span>
                                                </div>
                                              </div>

                                              <div class="col-md-3">

                                                <div class="card-counter success">
                                                    <i class="fas fa-user-check"></i>
                                                    <span class="count-numbers">{{resume.total_non_recupere}}<span style="font-size: 11px;position: relative;top: -8px;left: 5px;">
                                                      </span></span>
                                                    <span class="count-name">Conteneur Non Recupéré</span>
                                                </div>
                                              </div>

                                              <!--<div class="col-md-3">

                                                <div class="card-counter primary">
                                                    <i class="far fa-money-bill-alt"></i>
                                                    <span class="count-numbers">0<span style="font-size: 11px;position: relative;top: -8px;left: 5px;">
                                                      </span></span>
                                                    <span class="count-name">Chiffres d'Affaire</span>
                                                </div>
                                              </div>-->

                                              <!--<div class="col-md-3">

                                                <div class="card-counter danger">
                                                    <i class="far fa-money-bill-alt"></i>
                                                   {{resume.TotalCharge}}
                                                    <span class="count-numbers">0<span style="font-size: 11px;position: relative;top: -8px;left: 5px;">
                                                      </span></span>
                                                    <span class="count-name">Total Charges</span>
                                                </div>
                                              </div>-->

                                              <!--<div class="col-md-3">

                                                <div class="card-counter success">
                                                    <i class="far fa-money-bill-alt"></i>
                                                    <span class="count-numbers">0<span style="font-size: 11px;position: relative;top: -8px;left: 5px;">
                                                      </span></span>
                                                    <span class="count-name">Bénéfice Total Réalisé</span>
                                                </div>
                                              </div>-->

                                            </div>
                                          </div>
                                          <br>
                                          <div class="col-md-12">
                                             <div class="row">
                                                <div class="x_panel x_panel_close">
                                                <div class="x_title">
                                                  <h6>20 Dernières Livraisons de Conteneur</h6>
                                                  <ul class="nav navbar-right panel_toolbox">
                                                  </ul>
                                                  <div class="clearfix"></div>
                                                </div>
                                                <div class="x_content ">
                                                  <div class="col-md-12">
                                                    <div class="row">
                                                      <div class="table-responsive">
                                                            <table class="table_bloc_liste">
                                                                <thead>
                                                                    <tr>
                                                                       <th>Numero Conteneur</th>
                                                                       <th>Amateur</th>
                                                                        <th>client</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                  <tr *ngFor="let x of resume.DerniereLivraison">
                                                                      <td>{{x.numConteneur}}</td>
                                                                      <td>{{x.nomAmateur}}</td>
                                                                      <td>{{x.nom_societe}}</td>
                                                                  </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                             </div>
                                          </div>
                                          
                                          <div class="col-md-12">
                                             <div class="row">
                                                  <div class="x_panel x_panel_close">
                                                            <div class="x_title">
                                                              <h6>20 Dernières Recuperations de Conteneur</h6>
                                                              <ul class="nav navbar-right panel_toolbox">
                                                              </ul>
                                                              <div class="clearfix"></div>
                                                            </div>
                                                            <div class="x_content ">
                                                              <div class="col-md-12">
                                                                <div class="row">
                                                                  <div class="table-responsive">
                                                                        <table class="table_bloc_liste">
                                                                            <thead>
                                                                                <tr>
                                                                                   <th>Numero Conteneur</th>
                                                                                   <th>Amateur</th>
                                                                                    <th>client</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                               <tr *ngFor="let x of resume.DerniereRecuperation">
                                                                                   <td>{{x.numConteneur}}</td>
                                                                                    <td>{{x.nomAmateur}}</td>
                                                                                    <td>{{x.nom_societe}}</td>
                                                                              </tr>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                </div>
                                          </div>
                                      </div>
                                   </div>
                                </div>
                            </div>
                        </div>
                </div>
        </div>
</div>
