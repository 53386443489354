<div class="x_panel x_panel_close">
    <div class="x_content">
        <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;">
            <span><i  class="fa fa-arrow-left"></i></span>
            <div  class="ml-auto text-right">
                <span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span>
                    <i class="fa fa-chevron-right"></i>
                <span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Journal Transit - Etat</span>
            </div>
        </div>
        <br>
        <div class="col-md-12">
            <div class="content-action">
                <ul class="nav nav-tabs bar_tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                    	<a class="nav-link active" id="saisir-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='saisir'}" (click)="search('saisir')"
                           role="tab" aria-controls="saisir" aria-selected="true">Saisir Etat Jounal Transit</a>
                    </li>

                </ul>
                <div class="tab-content" id="myTabContent">
                    <div [ngClass]="{ 'show':activeTab==='saisir', 'active':activeTab==='saisir'}" class="tab-pane fade active show" id="saisir" role="tabpanel" aria-labelledby="saisir-tab">
					<br>
                        <div class="x_panel x_panel_close">
                            <div class="x_title">
                                <h5>Etat Jounal Transit</h5>
                                <ul class="nav navbar-right panel_toolbox" style="padding-left: 0px;"></ul>
                                <div class="clearfix"></div>
                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                  <div class="form-group col-xs-12 col-md-6">
                                    <div class="persoautocomplete">
                                          <label for="">Selectionner N°Dossier*</label>
                                         <input [(ngModel)]="dossier.numeroDossier1" type="text" name="numdossForm" id="numdossForm" placeholder="N°Dossier"  class="form-control form-control-sm" autocomplete="off" (focus)="reInitSearchForm()" (blur)="reInitSearchBlurForm()" disabled="disabled">
                                           <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="">
                                           </button>
                                            <div class="classPerso  dropdown-menu">
                                              <form class="px-0 py-3">
                                                  <div class="col-md-12">
                                                      <div class="row">
                                                          <div class="col-md-12">
                                                            <input type="text" [(ngModel)]="dossier.numeroDossier" class="form-control form-control-sm" placeholder="N°Dossier" name="FormSaisiMouvNav" (ngModelChange)="ListeAutoCompleteDossier(dossier.numeroDossier)" autocomplete="off">
                                                            <div>
                                                              <br>
                                                                <table class="table_bloc_liste tableReseach">
                                                                    <thead class="theadfixed">
                                                                        <th>N°Dossier</th>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr class="SelectTable"  *ngFor="let x of listdoss" (click)="SelectionAutocomplete(x)">
                                                                            <td>{{x.numeroDossier}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </form>
                                            </div>
                                    </div>
                                  </div>

                                    <div class="Table_Longer">
                                        <table class="table_bloc_liste">
                                            <thead>
                                                <tr>
                                                    <th>N° BL</th>
                                                    <th>Nombre de colis</th>
                                                    <th>Poids</th>
                                                    <th>Etats Bateau</th>
                                                    <th>Client</th>
                                                    <th>Déclaration</th>
                                                    <th>Date Déclaration</th>
                                                    <th>Nature</th>
                                                    <th>Total Mag Lta(Poids)</th>
                                                    <th>Total Mag Client(Poids)</th>
                                                    <th>Total Chargement à Quai(Poids)</th>
                                                    <th>Total Mag Port (Poids)</th>
                                                    <th>Total Cumul (Poids)</th>
                                                    <th>Total Solde (Poids)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor = "let x of dataSousMenu">
                                                  <td>{{x.reference}}</td>
                                                  <td>{{x.qte}}</td>
                                                  <td>{{x.PoidsNet}}</td>
                                                  <td></td>
                                                  <td>{{x.nom_societe}}</td>
                                                  <td></td>
                                                  <td></td>
                                                  <td>{{x.NomSpecificProduit}}</td>
                                                  <td>{{x.totalmagLta}}</td>
                                                  <td>{{x.totalmagCli}}</td>
                                                  <td>{{x.totalmagquai}}</td>
                                                  <td>{{x.totalmagport}}</td>
                                                  <td>{{x.Totalpoids}}</td>
                                                  <td>{{x.TotalSolde}}</td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>


                                </div>
                                <div class="row">
                                  <div  class="col-md-6 form-group"></div>
                                  <div  class="col-md-3 form-group">
                                    <div  class="form-group">
                                      <label >&nbsp;</label>
                                      <button  class="btn btn-success btn-block btn-sm">
                                        <i  class="fas fa-excel"></i>&nbsp;Exporter</button>
                                    </div>
                                  </div>
                                  <div  class="col-md-3 form-group">
                                    <div  class="form-group">
                                      <label >&nbsp;</label>
                                      <button  class="btn btn-warning btn-block btn-sm"><i  class="fas fa-print"></i>&nbsp;Imprimer</button>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                        <br>


                    </div>



                </div>
            </div>
        </div>
    </div>
</div>
