export class Navire {
  public idNavire: number = 0;
  public NomCompagnie: string = "";
  public Navire: string = "";
  public NbreCale: string = "";
  public capacite: string = "";
  public draft: string = "";
  public code: string = "";
  public user: string = "";
  public date: string = "";
  public modifierpar: number = 0;
  public datemodification: string = "";
  constructor() {

  }


}
