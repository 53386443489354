<div class="x_panel x_panel_close">
        <div class="x_content">
            <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;"><span ><i  class="fa fa-arrow-left"></i></span><div  class="ml-auto text-right"><span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span><i  class="fa fa-chevron-right"></i><span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Reporting- Etat stock Client
              </span>
             </div>
            </div>
            <br>
               <div class="col-md-12">
                            <div class="content-action">
                                 <ul class="nav nav-tabs bar_tabs" id="myTab" role="tablist">
                                          <li class="nav-item">
                                            <a class="nav-link active" id="saisir-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='saisir'}" (click)="search('saisir')"
                                               role="tab" aria-controls="saisir" aria-selected="true">Etat stock Client</a>
                                          </li>
                                    </ul>
                                    <div class="tab-content" id="myTabContent">
                                        <div [ngClass]="{ 'show':activeTab==='saisir', 'active':activeTab==='saisir'}"
                                        class="tab-pane fade active show" id="saisir" role="tabpanel" aria-labelledby="saisir-tab">

                                          <br>
                                                            <div class="x_content">
                                                              <div class="form-group col-xs-12 col-md-12 recherche_liste">
                                                                 <div class="row">
                                                                    <div class="col-md-4 form-group">
                                                                        <div class="persoautocomplete">
                                                                            <label for="">Client</label>
                                                                            <input type="text"  class="form-control form-control-sm"  autocomplete="off" disabled="" [(ngModel)] = "recherche.nom_societe"  name="client1">
                                                                            <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                            </button>
                                                                            <div class="classPerso  dropdown-menu">
                                                                              <form class="px-0 py-3">
                                                                                  <div class="col-md-12">
                                                                                      <div class="row">
                                                                                          <div class="col-md-12">
                                                                                              <label>Saisir Nom Client</label>
                                                                                                <input type="text" [(ngModel)] = "recherche.nom_societe1" class="form-control" placeholder="Nom Client" (ngModelChange)="ListeAutoCompleteDossier(recherche.nom_societe1)" name="client">
                                                                                            <div>
                                                                                              <br>
                                                                                                <table class="table_bloc_liste tableReseach">
                                                                                                    <thead class="theadfixed">
                                                                                                        <th>Nom Client</th>
                                                                                                    </thead>
                                                                                                    <tbody>
                                                                                                        <tr class="SelectTable" *ngFor="let x of listdoss" (click)="SelectionAutocomplete(x)">
                                                                                                            <td>{{x.nom_societe}}</td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </div>
                                                                                          </div>
                                                                                      </div>
                                                                                  </div>
                                                                              </form>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      <div class="form-group col-xs-12 col-md-4">
                                                                              <div _ngcontent-cqh-c52="" class="form-group">
                                                                                    <label>Nom Magasin</label>
                                                                                    <select id="exampleFormControlSelect1" class="form-control form-control-sm" [(ngModel)] = "recherche.NomMagasin" (ngModelChange) = "Recherche(recherche)">
                                                                                        <option value="">...</option>
                                                                                        <option *ngFor = "let i of listmagasin" value="{{i.NomMagasin}}">{{i.NomMagasin}}</option>
                                                                                    </select>
                                                                              </div>
                                                                      </div>
                                                              </div>
                                                            </div>
                                                        </div>
                                                        <table class="table_bloc_liste">
                                                                <thead>
                                                                  <tr>
                                                                    <th>#</th>
                                                                    <th>Nom Magasin</th>
                                                                    <th>N° DOSSIER</th>
                                                                    <th>Nom Client</th>
                                                                    <th>Produit</th>
                                                                    <th>Quantité</th>
                                                                    <th>Poids (en tonne)</th>
                                                                  </tr>
                                                                </thead>
                                                                <tbody>
                                                                  <tr *ngFor="let x of Liste_etat_clt">
                                                                    <td>{{x.id_produit_dispo_magasin}}</td>
                                                                    <td>{{x.NomMagasin}}</td>
                                                                    <td>{{x.numeroDossier}}</td>
                                                                    <td>{{x.nom_societe}}</td>
                                                                    <td>{{x.nom_produit_magasin}}</td>
                                                                    <td>{{x.qte_dispo}}</td>
                                                                    <td>{{x.poids_dispo}}</td>
                                                                  </tr>
                                                                </tbody>
                                                          </table>
                                                    <br>
                                                    <div _ngcontent-wyu-c52="" class="col-sm-12 text-right mobile-buttons">
                                                        <button _ngcontent-wyu-c52="" class="btn btn-secondary btn-sm btn-enregistrer" (click) =" RapportPdf()">
                                                            <font style="vertical-align: inherit;">
                                                                <font style="vertical-align: inherit;">Imprimer</font>
                                                            </font>
                                                        </button>
                                                    </div>

                                        </div>

                                     <div class="tab-pane fade" id="profile" role="tabpanel"  aria-labelledby="profile-tab" [ngClass]="{'show':activeTab==='profile', 'active':activeTab==='profile'}">
                                     </div>

                                    </div>
                              </div>
                        </div>

        </div>
</div>
