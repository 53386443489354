import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../services/transfertData/transfertData.service';
import { pipe } from 'rxjs';
import { NomConditionnement } from '../../classes/NomConditionnement';
import { PRODUIT } from '../../classes/produit';
import { UTILISATEUR } from '../../classes/Utilisateur';
import { AuthService } from '../../services/connexion/auth.service';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
@Component({
  selector: 'app-gestion-produits-clients',
  templateUrl: './gestion-produits-clients.component.html',
  styleUrls: ['./gestion-produits-clients.component.css']
})
export class GestionProduitsClientsComponent implements OnInit {
  activeTab:String="saisir";
  apiUrl:String = "";
  listeProduit_data:any = [];
  liste_Clients:any = [];
  liste_Poids:any = [];
  recherche:any = {};
  liste_ClientsRC:any = [];
  modelAutocompleClient:any = {};
  duplicateProduit:boolean = false;
  message_error:any = "";

  ProduitModel:PRODUIT = new PRODUIT();
  PoidsModel:NomConditionnement = new NomConditionnement();
  userModel:UTILISATEUR = new UTILISATEUR();
  constructor(private transfertDataService : transfertDataService, private AuthService : AuthService) { }

  ngOnInit(): void {
    this.listePoids();
    this.Liste_des_Produits();
  }
  search(activeTab:String){
     this.activeTab=activeTab
   };

  reInitSearch(): void{
    this.liste_Clients = [];
  }

  reInitSearchBlur(): void{
    setTimeout((val:any) => {
        this.liste_Clients = [];
    }, 500);

  }

  //Fonction de verification de champs
    VerificationChampsObligatoire(tableau_champs_obligatoires:any){
        var verification_formulaire = true;
        for (var i = 0; i < tableau_champs_obligatoires.length; i++) {
            if($(tableau_champs_obligatoires[i]).val() == "" || $(tableau_champs_obligatoires[i]).val() == null || $(tableau_champs_obligatoires[i]).val() == "? string: ?"){
                    $(tableau_champs_obligatoires[i]).css("border","1px solid red");
                    verification_formulaire = false;
                    document.getElementById(tableau_champs_obligatoires[i].replace("#",""))?.scrollIntoView({behavior: "smooth"});
                    break;
            }else{
                $(tableau_champs_obligatoires[i]).css("border","");
            }
        }

        return verification_formulaire;
    }

  reInitSearchRC(): void{
    this.liste_ClientsRC = [];
  }

  reInitSearchBlurRC(): void{
    setTimeout((val:any) => {
        this.liste_ClientsRC = [];
    }, 500);

  }

  ListeAutoCompleteClientRC(nomclient:any){
   this.apiUrl = "SERVER/produitclient/produit/listeclient";
      var data = {nomclient:nomclient};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.liste_ClientsRC= value.liste_Client;

      }, (erreur:any) => {

      })
  }

  ListeAutoCompleteClient(nomclient:any){
   this.apiUrl = "SERVER/produitclient/produit/listeclient";
      var data = {nomclient:nomclient};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.liste_Clients= value.liste_Client;

      }, (erreur:any) => {

      })
  }

  SelectionAutocomplete(data:any): void{
    this.ProduitModel.idClient = data.idClient;
    this.modelAutocompleClient.nomclient = data.nom_societe;
    this.reInitSearch();
  }

  SelectionAutocompleteRC(data:any): void{
    this.recherche.nom_societeSaisi = data.nom_societe;
    this.recherche.nom_societe = data.nom_societe;
    this.rechercheProduit(this.recherche);
    this.reInitSearchRC();
  }

  Annuler():void{
       this.ProduitModel = new PRODUIT();
       this.PoidsModel = new NomConditionnement();
       this.recherche.nom_societe = "";
       this.recherche.NomProduit = "";
       this.recherche.NomSpecificProduit = "";
       this.modelAutocompleClient = {};
  }

    enregistrer(event:any): void{
        this.duplicateProduit = false;
        if(this.VerificationChampsObligatoire(["#NomClient", "#nompro", "#nomConditionnement"]) == false)
            return;
            event.target.disabled = true;
        if(this.ProduitModel.idProduit == 0)
          this.apiUrl = "SERVER/produitclient/produit/enregistrer";
        else
          this.apiUrl = "SERVER/produitclient/produit/modifier";


        this.ProduitModel.idClient = Number(this.ProduitModel.idClient);
        this.ProduitModel.user = Number(this.userModel.id);
        this.ProduitModel.poidsProduit= this.PoidsModel.NomConditionnement;

        var infoUser = this.AuthService.getInfoUtilisateur();
        this.ProduitModel.user = infoUser.id;


        var d = {"produit": this.ProduitModel};
        this.transfertDataService.EnvoiData(d, this.apiUrl).pipe(
            retryWhen(error =>
              error.pipe(
                scan((acc:any, error:any) => {
                  if (acc > 2) throw error;
                  return acc + 1;
                }, 1),
                delay(1000)
              )
            )
        ).subscribe((value:any) => {
          if(value.status == -1){
            event.target.disabled = false;
             this.duplicateProduit = true;
             this.message_error = value.msg;
          }else{
            event.target.disabled = false;
            this.Liste_des_Produits();
            this.search("profile");
            this.Annuler();
          }

        }, (erreur:any) => {
            event.target.disabled = false;
        })

    }

    supprimer(idProduit:any):void{
      this.apiUrl = "SERVER/produitclient/produit/supprimer";
      var data = {id:idProduit};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.Liste_des_Produits();
      }, (erreur:any) => {
          console.log(erreur.status);
      })

    }
    GenererNomSpecificProduit(){
      this.ProduitModel.NomSpecificProduit= this.ProduitModel.NomProduit+ " - " +this.PoidsModel.NomConditionnement;
    }


          listePoids() : void{

            this.apiUrl = "SERVER/produitclient/produit/liste_poids";

            var data = {};
            this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
                retryWhen(error =>
                  error.pipe(
                    scan((acc:any, error:any) => {
                      if (acc > 2) throw error;
                      return acc + 1;
                    }, 1),
                    delay(1000)
                  )
                )
            ).subscribe((response:any) => {
              this.liste_Poids = response.liste_poids;
            }, (erreur:any) => {
                console.log(erreur.status);
            })
          }



        Liste_des_Produits(): void{

          this.apiUrl = "SERVER/produitclient/produit/listeProduitclient";
          var data = {};
          this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
              retryWhen(error =>
                error.pipe(
                  scan((acc:any, error:any) => {
                    if (acc > 2) throw error;
                    return acc + 1;
                  }, 1),
                  delay(1000)
                )
              )
          ).subscribe((response:any) => {
            this.listeProduit_data = response.liste_produit;
          }, (erreur:any) => {
              console.log(erreur.status);
          })

        }

        information(idProduit:any):void{


              this.apiUrl = "SERVER/produitclient/produit/information";

              var data = {idProduit:idProduit};
              this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
                retryWhen(error =>
                  error.pipe(
                    scan((acc:any, error:any) => {
                      if (acc > 2) throw error;
                      return acc + 1;
                    }, 1),
                    delay(1000)
                  )
                )
            ).subscribe((response:any) => {
                  this.ProduitModel = response.liste_produitAfficher[0];
                  this.PoidsModel.NomConditionnement=this.ProduitModel.poidsProduit;
                  this.ProduitModel.idClient = response.liste_produitAfficher[0].idClient;
                  this.modelAutocompleClient.nomclient = response.liste_produitAfficher[0].nom_societe;
                  this.search("saisir");

              }, (erreur:any) => {
                console.log(erreur.status);
            })

        }
        rechercheProduit(data:any): void{


          if(this.recherche.nom_societe == "" && this.recherche.NomProduit == "" && this.recherche.NomSpecificProduit == "" )
          this.Liste_des_Produits();
          else{
            this.apiUrl = "SERVER/produitclient/produit/recherche";
            var data = data;
            this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
              retryWhen(error =>
                error.pipe(
                  scan((acc:any, error:any) => {
                    if (acc > 2) throw error;
                    return acc + 1;
                  }, 1),
                  delay(1000)
                )
              )
          ).subscribe((value:any) => {
              this.listeProduit_data= value.liste_produit;
            }, (erreur:any) => {
                console.log(erreur.status);
            })
          }

      }
}
