export class fournisseur {
  public id: number = 0;
  public nomfourn: string = "";
  public telfourn: string = "";
  public email: string = "";
  public date: string = "";
  public idUser: number = 0;
  public modifierpar: number = 0;
  public datemodification: string = "";
  constructor() {

  }


}
