import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  configuration:any ={};
  constructor() {
    this.configuration.hostname = "https://www.appshop.tech/digitaltransit/";
    this.configuration.ServerWebHostname = "https://www.appshop.tech/digitaltransit/SERVER/";

    /*this.configuration.host = window.location.hostname;
    this.configuration.hostname = window.location.protocol + "//"+this.configuration.host+"/ShippingControle/";
    this.configuration.ServerWebHostname = window.location.protocol +"//"+this.configuration.host+"/ShippingControle/SERVER/";*/
  }

}
