export class elt_facture_escale {

  public id_elt_facture: number = 0;
  public id_facture: number = 0;
  public date: string = "";
  public libelle: string = "";
  public prix_unitaire: number = 0;
  public nbre: number = 0;
  public tva: number = 0;
  public ordre: number = 0;
  public remise: number = 0;
  public montant_ht_euro: number = 0;
  public montant_ht_cfa: number = 0;
  public date_creation: string = "";
  public type_activite: string = "";
  constructor() {

  }

}
