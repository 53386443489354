import { Component, OnInit } from '@angular/core';
import { dechargement } from '../../../classes/Dechargement';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { ConfigurationService } from '../../../config/configuration.service';
import { AuthService } from '../../../services/connexion/auth.service';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';

@Component({
  selector: 'app-programmer-dechargement',
  templateUrl: './programmer-dechargement.component.html',
  styleUrls: ['./programmer-dechargement.component.css']
})
export class ProgrammerDechargementComponent implements OnInit {
  activeTab:String="saisir";
  apiUrl:String = "";
  listdoss:any = [];
  programmation_dechargements:any = [];
  dossier:any = {};
  message_error:any = "";
  duplicateDechargement:boolean = false;
  supp_id:any = "";
  liste_quai:any = [];
  liste_acconier:any = [];
  listedossierRecherche:any = [];
  recherche:any={};
  dechargementModel:dechargement = new dechargement();
  constructor(private transfertDataService : transfertDataService, private AuthService : AuthService ,  private ConfigurationService : ConfigurationService) { }

  ngOnInit(): void {
    this.recherche.numeroDossier = "";
    this.recherche.date = "";
    this.ListeQuai();
    this.ListeAcconier();
    this.ListeProgrammtion();
  }

  search(activeTab:String){
     this.activeTab=activeTab;
  };



  ListeQuai(){
   this.apiUrl = "SERVER/gestiondechargement/programmerdechargement/recherche_quai";
      var data = {};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.liste_quai = value.liste_quai;
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }


  ListeAcconier(){
   this.apiUrl = "SERVER/gestiondechargement/programmerdechargement/recherche_Acconier";
      var data = {};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.liste_acconier = value.liste_acconier;
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }

  VerificationChampsObligatoire(tableau_champs_obligatoires:any){
        var verification_formulaire = true;
        for (var i = 0; i < tableau_champs_obligatoires.length; i++) {
            if($(tableau_champs_obligatoires[i]).val() == "" || $(tableau_champs_obligatoires[i]).val() == null || $(tableau_champs_obligatoires[i]).val() == "? string: ?"){
                    $(tableau_champs_obligatoires[i]).css("border","1px solid red");
                    verification_formulaire = false;
                    document.getElementById(tableau_champs_obligatoires[i].replace("#",""))?.scrollIntoView({behavior: "smooth"});
                    break;
            }else{
                $(tableau_champs_obligatoires[i]).css("border","");
            }
        }

        return verification_formulaire;
    }

  programmerDechargement(event:any){
      this.duplicateDechargement = false;
       if(this.VerificationChampsObligatoire(["#numdossFormPro", "#jour_nuit", "#date_dechargement"]) == false)
              return;
  event.target.disabled = true;
      if(this.dechargementModel.id_dechargelent == 0)
         this.apiUrl = "SERVER/gestiondechargement/programmerdechargement/programmer";
       else
         this.apiUrl = "SERVER/gestiondechargement/programmerdechargement/modifier";

      var infoUser = this.AuthService.getInfoUtilisateur();
      this.dechargementModel.iduser = infoUser.id;
      this.transfertDataService.EnvoiData(this.dechargementModel, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
          if(value.status == -1){
            event.target.disabled = false;
             this.duplicateDechargement = true;
             this.message_error = value.msg;
          }else{
            event.target.disabled = false;
            this.Annuler();
            this.ListeProgrammtion();
          }

      }, (erreur:any) => {
        event.target.disabled = false;
      })
  }

  AutocompleteRecherche(numerodossier:any){
   this.apiUrl = "SERVER/gestionnavire/naviremouvementsnavire/recherche_dossier";
      var data = {numeroDossier:numerodossier};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listedossierRecherche = value.listdoss;
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }

  selectionAutocompleteRecherche(data:any): void{
    this.recherche.numeroDossier = data.numeroDossier;
    if(this.recherche.NumDossSaisi == "")
      this.recherche.numeroDossier = "";
    this.Recherche(this.recherche);
  }

  Exporter(): void{
      var lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/Dechargement/excel_programmation_dechargement.php?numeroDossier="+this.recherche.numeroDossier+"&date="+this.recherche.date;
        window.open(lien_fiche_ExpST , '_blank')?.focus();
  }

  Imprimer(): void{
    var lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/Dechargement/pdf_programmation_dechargement.php";
    $("#printRapport").remove();
    $("body").append('<div id="printRapport">\
                       <div class="col-md-12 form-group" style="display:none;">\
                         <button class="btn btn-primary btn-sm bs-modal-frame-sm-btn" data-backdrop="static" data-toggle="modal" data-target=".bs-modal-frame-sm"></button>\
                       </div>\
                       <div class="modal fade bs-modal-frame-sm" tabindex="-1" role="dialog" aria-hidden="true">\
                         <div class="modal-dialog" role="document">\
                             <div class="modal-content">\
                                 <div class="row container_modal">\
                                 <div class="modal-header" style="margin-bottom:0px;">\
                                   <h6 class="modal-title" style="font-weight: bold;">PROGRAMMATION DECHARGEMENTS</h6>\
                                   <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>\
                                   </button>\
                                 </div>\
                                 <div class="row col-md-12" style="width:100%;height: calc(100vh - 90px);padding-left: 0px; padding-right: 0px; margin-left: 0px; margin-right: 0px;">\
                                   <div class="col-md-12 waiting">\
                                     <div class="snippet" data-title=".dot-pulse">\
                                       <div class="stage">\
                                         <div class="dot-pulse"></div>\
                                       </div>\
                                     </div>\
                                   </div>\
                                   <iframe class="rapportframe" name="rapportframe" style="width:100%;"></iframe>\
                                 </div>\
                             </div>\
                           </div>\
                         </div>\
                       </div>\
                   </div>');
    $(".bs-modal-frame-sm-btn").trigger("click");
    window.open(lien_fiche_ExpST , "rapportframe")?.focus();
    $(".rapportframe").css("display", "none");
    $('.rapportframe').on('load', function(){
       $(".waiting").css("display", "none");
       $(".rapportframe").css("display", "initial");
    });
  }

  Recherche(data:any): void{
      if(this.recherche.numeroDossier == "" && this.recherche.date == ""){
        this.ListeProgrammtion();
        return;
      }
      var apiUrl = "SERVER/gestiondechargement/programmerdechargement/recherche";
      var data = data;
      this.transfertDataService.EnvoiData(data, apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
      this.programmation_dechargements = value.data;
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }

  supprimer(id:any):void{
       this.apiUrl = "SERVER/gestiondechargement/programmerdechargement/supprimer";
       var data = {id:id};
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
     ).subscribe((value:any) => {
         this.ListeProgrammtion();
       }, (erreur:any) => {

       })

  }

  SupprimerPopup(id:any): void{
    $(".suppliste").trigger("click");
    this.supp_id = id;
  }

  Annuler(){
    this.dechargementModel = new dechargement();
  }

  InfoProgrammtion(id_dechargement:any){
   this.apiUrl = "SERVER/gestiondechargement/programmerdechargement/information";
      var data = {id:id_dechargement};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.dechargementModel = value.data[0];
        this.dechargementModel.iddossier = value.data[0].idGestionNavire;
        this.search("saisir");
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }

  ListeProgrammtion(){
   this.apiUrl = "SERVER/gestiondechargement/programmerdechargement/liste_programmation";
      var data = {};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.programmation_dechargements = value.data;
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }

  ListeAutoCompleteDossier(numerodossier:any){
   this.apiUrl = "SERVER/gestiondechargement/programmerdechargement/recherche_dossier";
      var data = {numeroDossier:numerodossier};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listdoss= value.listdoss;
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }

  SelectionAutocomplete(data:any): void{
   this.dechargementModel.numeroDossier = data.numeroDossier;
   this.dechargementModel.Navire = data.Navire;
   this.dechargementModel.iddossier = data.idGestionNavire;
  }

  reInitSearchForm(): void{
    this.listdoss = [];
  }

  reInitSearchBlurForm(): void{
    setTimeout((val:any) => {
        this.listdoss = [];
    }, 500);
  }

  reInitSearch(): void{
    this.listedossierRecherche = [];
  }

  reInitSearchBlur(): void{
    setTimeout((val:any) => {
        this.listedossierRecherche = [];
    }, 500);
  }

}
