export class cout_exploitation {
  public id_cout_exploitation: number = 0;
  public iddossier: number = 0;
  public total_cout_exploitation: number = 0;
  public date_exploitation: string = "";
  public date_creation: string = "";
  public idUser: number = 0;
  public modifierpar: number = 0;
  public datemodification: string = "";
  constructor() {
  }

}
