export class MAGASIN {
  public idMagasin: number = 0;
  public NomMagasin: string = "";
  public lieu: string = "";
  public tel: string = "";
  public capacite: string = "";
  public Responsable: string = "";
  public user: number = 0;
  public date: string = "";
  constructor() {

  }

}
