import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { AuthService } from '../../../services/connexion/auth.service';
import { CAMION } from '../../../classes/camion';

@Component({
  selector: 'app-camion',
  templateUrl: './camion.component.html',
  styleUrls: ['./camion.component.css']
})
export class CamionComponent implements OnInit {
  activeTab:String="saisir";

  apiUrl:String = "";
  listeCamions:any = [];
  recherche:any = {};
  supp_id:any = "";
  erreurSuppression = "";
  CAMION:CAMION = new CAMION();
  
  constructor(private transfertDataService : transfertDataService, private AuthService : AuthService) { }

  ngOnInit(): void {
    this.ListeDesCamions();
  }

  Annuler():void{
    this.CAMION = new CAMION();
    this.recherche.immat = "";
    
  }

  search(activeTab:String){
     this.activeTab=activeTab
   };

  ListeDesCamions(): void{
    this.apiUrl = "SERVER/importparametre/camion/liste";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((response:any) => {
         this.listeCamions = response.liste;

       }, (erreur:any) => {
           console.log(erreur.status);
       })

  }

  VerificationChampsObligatoire(tableau_champs_obligatoires:any){
        var verification_formulaire = true;
        for (var i = 0; i < tableau_champs_obligatoires.length; i++) {
            if($(tableau_champs_obligatoires[i]).val() == "" || $(tableau_champs_obligatoires[i]).val() == null || $(tableau_champs_obligatoires[i]).val() == "? string: ?"){
                    $(tableau_champs_obligatoires[i]).css("border","1px solid red");
                    verification_formulaire = false;
                    document.getElementById(tableau_champs_obligatoires[i].replace("#",""))?.scrollIntoView({behavior: "smooth"});
                    break;
            }else{
                $(tableau_champs_obligatoires[i]).css("border","");
            }
        }

        return verification_formulaire;
  }

  Enregistrer(data:any): void{
    //Champ obligatoire
    if(this.VerificationChampsObligatoire(["#immat1"]) == false){
      return;
    }

    var infoUser = this.AuthService.getInfoUtilisateur();

    if(this.CAMION.idCamion == 0){
      this.apiUrl = "SERVER/importparametre/camion/enregistrer";
      this.CAMION.creerPar = infoUser.id;
     
    }else{
      this.apiUrl = "SERVER/importparametre/camion/modifier";
      this.CAMION.modifierPar = infoUser.id;
    }



    var d = {"camion": this.CAMION};
    this.transfertDataService.EnvoiData(d, this.apiUrl).pipe(
            retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((value:any) => {
            /*CONTRAINTE D'UNICITE*/
            if(value.status == -1){
              $("#immat1").css("border","1px solid red");
            }else{
              this.ListeDesCamions();
             this.search("profile");
             this.Annuler();
            } 
         }, (erreur:any) => {
             console.log(erreur.status);
         })

  }

  information(id:any):void{
    this.apiUrl = "SERVER/importparametre/camion/information";
    var data = {id:id};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
      retryWhen(error =>
        error.pipe(
              scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((response:any) => {
               this.CAMION = response.camion[0];
               this.search("saisir");
           }, (erreur:any) => {
             console.log(erreur.status);
         })

     }

  SupprimerPopup(id:any): void{
    $(".suppliste").trigger("click");
    this.supp_id = id;
  }

  supprimer(id:any):void{
       this.apiUrl = "SERVER/importparametre/camion/supprimer";
       var data = {id:id};
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
     ).subscribe((value:any) => {
      if(value.status == -1){
            this.erreurSuppression = "Impossible de supprimer ce camion.";
            $(".exampleModal").trigger("click");
        }else{
          this.ListeDesCamions();
        } 
         
       }, (erreur:any) => {
           console.log(erreur.status);
       })

  }

  recherch(data:any): void{
     if(this.recherche.immat == "")
     this.ListeDesCamions();
     else{
       this.apiUrl = "SERVER/importparametre/camion/recherche";
       var data = data;
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
     ).subscribe((value:any) => {
         this.listeCamions= value.liste;
       }, (erreur:any) => {
           console.log(erreur.status);
       })
     }

 }





}
