import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { repertoiredocument } from '../../../classes/repertoiredocument';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { AuthService } from '../../../services/connexion/auth.service';

@Component({
  selector: 'app-gp-repertoiredocument',
  templateUrl: './gp-repertoiredocument.component.html',
  styleUrls: ['./gp-repertoiredocument.component.css']
})
export class GpRepertoiredocumentComponent implements OnInit {
  activeTab:String="saisir";
  apiUrl:String = "";
  listeRepertoireDoc:any = [];
  recherche:any = {};
  supp_id:any = "";
  document:repertoiredocument = new repertoiredocument();
  constructor(private transfertDataService : transfertDataService,private AuthService : AuthService) { }

  ngOnInit(): void {
    this.Liste_des_repertoireDoc();
  }
  search(activeTab:String){
     this.activeTab=activeTab
   };


     Annuler():void{
        this.document = new repertoiredocument();
        this.recherche.RepertoireDocument = "";
       }

    enregistrer(data:any): void{

       if(this.document.idRepertoireDocument == 0)
         this.apiUrl = "SERVER/parametres/repertoireDoc/enregistrer";
       else
         this.apiUrl = "SERVER/parametres/repertoireDoc/modifier";

         var infoUser = this.AuthService.getInfoUtilisateur();
          this.document.idUser = infoUser.id;

         var d = {"repertoireDoc": this.document};
         this.transfertDataService.EnvoiData(d, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((value:any) => {
           this.Liste_des_repertoireDoc();
           this.search("profile");
           this.Annuler();
         }, (erreur:any) => {
             console.log(erreur.status);
         })

     }

    supprimer(idRepertoireDocument:any):void{
       this.apiUrl = "SERVER/parametres/repertoireDoc/supprimer";
       var data = {id:idRepertoireDocument};
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
     ).subscribe((value:any) => {
         this.Liste_des_repertoireDoc();
       }, (erreur:any) => {
           console.log(erreur.status);
       })

     }


     Liste_des_repertoireDoc(): void{

       this.apiUrl = "SERVER/parametres/repertoireDoc/listerepertoiredocument";
       var data = {};
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
           retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((response:any) => {
         this.listeRepertoireDoc = response.liste_repertoireDoc;
       }, (erreur:any) => {
           console.log(erreur.status);
       })

     }

     information(idRepertoireDocument:any):void{


           this.apiUrl = "SERVER/parametres/repertoireDoc/information";
           var data = {id:idRepertoireDocument};
           console.log(data);

           this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((response:any) => {
               this.document = response.repertoiredocument[0];
               this.search("saisir");

           }, (erreur:any) => {
             console.log(erreur.status);
         })

     }

     rechercherepertoireDoc(data:any): void{
       if(this.recherche.RepertoireDocument == "")
       this.Liste_des_repertoireDoc();
       else{
         this.apiUrl = "SERVER/parametres/repertoireDoc/recherche";
         var data = data;
         this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
           retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((value:any) => {
           this.listeRepertoireDoc= value.liste_repertoireDoc;
         }, (erreur:any) => {
             console.log(erreur.status);
         })
       }

   }

    SupprimerPopup(id:any): void{
    $(".suppliste").trigger("click");
    this.supp_id = id;
  }

}
