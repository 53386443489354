<div class="x_panel x_panel_close">
    <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;"><span ><i  class="fa fa-arrow-left"></i></span><div  class="ml-auto text-right"><span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span><i  class="fa fa-chevron-right"></i><span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Créer Menu</span>
        </div>
    </div>
    <div class="x_content">
                <ul class="nav nav-tabs bar_tabs" id="myTab" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link active" id="saisir-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='saisir'}" (click)="Tabulation('saisir')"
                           role="tab" aria-controls="saisir" aria-selected="true">Créer Menu</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="profile-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='profile'}" (click)="Tabulation('profile')"
                            role="tab" aria-controls="profile" aria-selected="false">Liste des Menus</a>
                      </li>
                </ul>
                <br>
                <div class="tab-content" id="myTabContent">
                <div [ngClass]="{ 'show':activeTab==='saisir', 'active':activeTab==='saisir'}"
                              class="tab-pane fade active show" id="saisir" role="tabpanel" aria-labelledby="saisir-tab">
                <div class="x_panel x_panel_close">
                    <div class="x_title">
                      <h6>Menu Principal</h6>
                      <ul class="nav navbar-right panel_toolbox">
                      </ul>
                      <div class="clearfix"></div>
                    </div>
                    <div class="x_content">
                          <div class="col-md-12" >
                                <div class="row">
                                       <div class="col-md-12">
                                            <div class="row">
                                                <div class="form-group col-md-3">
                                                  <label for="">Nom Menu</label>
                                                  <input  type="text" id="nom_menu" placeholder="Nom Menu" name="nom_menu" class="form-control form-control-sm" [(ngModel)]="MenuModel.nom_menu">
                                                </div>
                                                <div class="form-group col-md-3">
                                                  <label for="">Url</label>
                                                  <input  type="text"  placeholder="Url Menu" [(ngModel)]="MenuModel.url" name="Url_Menu" class="form-control form-control-sm">
                                                </div>
                                                <div class="form-group col-md-3">
                                                  <label for="">Icone</label>
                                                  <input  type="text" id="" placeholder="Icone Menu" [(ngModel)]="MenuModel.icone" name="Icone_Menu" class="form-control form-control-sm">
                                                </div>
                                                <div class="form-group col-md-3">
                                                  <label for="">Ordre</label>
                                                  <input  type="number" name="Ordre_Menu" placeholder="Ordre Menu" [(ngModel)]="MenuModel.ordre" class="form-control form-control-sm">
                                                </div>
                                           </div>

                                 </div>
                              </div>
                          </div>
                    </div>
                </div>
                <div class="x_panel x_panel_close">
                    <div class="x_title">
                      <h6>Sous menu</h6>
                      <ul class="nav navbar-right panel_toolbox">
                      </ul>
                      <div class="clearfix"></div>
                    </div>
                    <div class="x_content">
                          <div class="col-md-12">
                              <div class="row">
                                  <div class="form-group col-md-12 row ">
                                    <div class="col-md-12">
                                      <button type="button" class="btn btn-primary btn-sm"  (click)="ajouter()" >Nouveau</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                    </div>
                                  </div>
                                  <div class="table-responsive mb-2">
                                              <table class="table_bloc_liste">
                                                      <thead>
                                                        <tr >
                                                            <th>#</th>
                                                            <th>titre</th>
                                                            <th>url</th>
                                                            <th>Ordre</th>

                                                            <th class="action">Action</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>

                                                <tr *ngFor="let x of dataSousMenuModel;let i = index;" >
                                                            <td>{{i+1}}</td>
                                                            <td>
                                                               <input type="text" [(ngModel)]="x.nom_sous_menu"  placeholder="" class="form-control form-control-sm" name="titre">
                                                            <td>
                                                              <input type="text" [(ngModel)]="x.url" placeholder="" class="form-control form-control-sm" name="url">
                                                            </td>
                                                            <td>
                                                              <input type="number" [(ngModel)]="x.ordre"  placeholder="" class="form-control form-control-sm" name="ordre">
                                                            </td>

                                                            <td>
                                                              <div class="dropdown">
                                                                  <button class="btn btn-outline-secondary boutton" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                     <i class="fas fa-ellipsis-h icone"></i>
                                                                  </button>
                                                                  <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                                    <button class="dropdown-item" type="button"
                                                                    (click)="enregistrer_sous_menu(x)" *ngIf="MenuModel.id_menu != 0">
                                                                      <i class="fas fa-pencil-alt"></i> &nbsp;&nbsp; Sauvegarder
                                                                    </button>
                                                                    <button class="dropdown-item" type="button" (click)="supprimer_sous_menu(x.id_sous_menu)">
                                                                      <i class="fas fa-trash-alt" ></i> &nbsp;&nbsp; Suprimer
                                                                    </button>
                                                                  </div>
                                                              </div>
                                                            </td>

                                                        </tr>

                                              </tbody>
                                        </table>
                                  </div>
                                  <div class="text-right mobile-buttons col-sm-12 AccessBtnEnr">
                                    <div class="text-right">
                                        <button  class="btn btn-primary  btn-sm btn-enregistrer" (click)="Enregistrer(MenuModel)">Enregistrer</button>&nbsp;&nbsp;
                                        <button  class="btn btn-secondary btn-sm btn-annuler" (click)="Annuler()">Annuler</button>
                                    </div>
                                  </div>

                              </div>
                          </div>
                    </div>
                </div>


    </div>


                <div class="col-sm-12 tab-pane fade" id="profile" role="tabpanel"  aria-labelledby="profile-tab" [ngClass]="{ 'show':activeTab==='profile', 'active':activeTab==='profile'}">
                    <div class="row recherche_liste">
                              <div class="col-sm-12 col-md-3">
                                <div class="form-group">
                                  <label for="">Nom Menu</label>
                                  <input type="text" id="" [(ngModel)]="rechercheObj.nom_menu" placeholder="Nom Menu" class="form-control form-control-sm">
                                </div>
                              </div>
                              <div class="col-sm-12  col-md-3 col-button-recherche">
                                <div class="form-group">
                                  <button class="btn btn-primary btn-sm" (click)="Recherche(rechercheObj)">
                                    <i class="fas fa-search"></i>&nbsp;
                                    <span>Rechercher</span>
                                  </button>
                                </div>
                              </div>
                    </div>
                    <br>
                      <div class="row table-Table_Longer">
                        <table class="table_bloc_liste">
                          <thead>
                            <th>#</th>
                            <th>titre</th>
                            <th>Url</th>
                            <th>Icone</th>
                            <th>Ordre</th>
                            <th>Créer Par</th>
                            <th>Créer Le</th>
                            <th>Modifier Par</th>
                            <th>Modifier Le</th>
                            <th></th>
                            <th></th>
                          </thead>
                          <tbody>
                            <tr *ngFor="let x of liste_menus">
                              <td>{{x.id_menu}}</td>
                              <td>{{x.nom_menu}}</td>
                              <td>{{x.url}}</td>
                              <td>{{x.icone}}</td>
                              <td>{{x.ordre}}</td>
                              <td>{{x.nom}}</td>
                              <td>{{x.date}}</td>
                              <td>{{x.modifierpar}}</td>
                              <td>{{x.datemodification}}</td>
                              <td class="text-center">
                                <button  class="btn btn-success btn-sm" (click)="AfficherDonner(x.id_menu)">
                                  <i class="fas fa-pencil-alt"></i>
                                </button>
                              </td>
                              <td class="text-center">
                                <button  class="btn btn-danger btn-sm" (click)="SupprimerMenu(x.id_menu)">
                                   <i class="far fa-trash-alt"></i>
                                </button>
                              </td>
                          </tbody>
                        </table>
                      </div>

                 </div>

            </div>

    </div>
</div>
