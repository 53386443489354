export class PRODUITMAG {
  public id_produit_magasin: number = 0;
  public iddossier: number = 0;
  public id_sous_produit: number = 0;
  public id_client: number = 0;
  public iduser: number = 0;
  public nom_produit_magasin: string = "";
  public poids_unitaire: number = 0;
  public bl_lta: string = "";
  public bl_client: string = "";
  public immat: string = "";
  public ticket_depese: string = "";
  public observation: string = "";
  public date_creation: string = "";
  constructor() {

  }

}
