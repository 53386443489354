<div class="x_panel x_panel_close">
        <div class="x_content">
               <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;"><span ><i  class="fa fa-arrow-left"></i></span>
                   <div  class="ml-auto text-right"><span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span><i  class="fa fa-chevron-right"></i><span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Gestion Navires - Mouvements Navire</span>
                   </div>
               </div>
                <ul class="nav nav-tabs bar_tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" id="saisir-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='saisir'}" (click)="search('saisir')"
                       role="tab" aria-controls="saisir" aria-selected="true">Saisir Mouvement Navire</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='profile'}" (click)="search('profile')"
                        role="tab" aria-controls="profile" aria-selected="false">Liste Mouvements Navires</a>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div [ngClass]="{ 'show':activeTab==='saisir', 'active':activeTab==='saisir'}" class="tab-pane fade active show" id="saisir" role="tabpanel" aria-labelledby="saisir-tab">
                        <br>
                    <div class="x_panel_title x_panel_close">
                        <div class="x_title">
                          <h5>Mouvement Navire</h5>
                          <ul class="nav navbar-right panel_toolbox" style="padding-left: 50px;">
                          </ul>
                          <div class="clearfix"></div>
                        </div>
                        <div class="x_content">
                          <div class="row">
                            <div class="col-md-4 form-group">
                                <div class="persoautocomplete">
                                      <label for="">Selectionner N°Dossier*</label>
                                     <input [(ngModel)]="DOSSIERModel.numeroDossier" type="text" name="numdossForm" id="numdossForm" placeholder="N°Dossier"  class="form-control form-control-sm" autocomplete="off" (focus)="reInitSearchForm()" (blur)="reInitSearchBlurForm()" disabled="disabled">
                                       <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="">
                                       </button>
                                        <div class="classPerso  dropdown-menu">
                                          <form class="px-0 py-3">
                                              <div class="col-md-12">
                                                  <div class="row">
                                                      <div class="col-md-12">
                                                        <input type="text" [(ngModel)]="dossier.numeroDossier" class="form-control form-control-sm" placeholder="N°Dossier" name="FormSaisiMouvNav" (ngModelChange)="ListeAutoCompleteDossier(dossier.numeroDossier)" autocomplete="off">
                                                        <div>
                                                          <br>
                                                            <table class="table_bloc_liste tableReseach">
                                                                <thead class="theadfixed">
                                                                    <th>N°Dossier</th>
                                                                </thead>
                                                                <tbody>
                                                                    <tr class="SelectTable"  *ngFor="let x of listdoss" (click)="SelectionAutocomplete(x)">
                                                                        <td>{{x.numeroDossier}}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </form>
                                        </div>
                                </div>
                            </div>


                          <div class="form-group col-xs-12 col-md-4">
                            <label for="Navire">Navire</label>
                            <input type="text" class="form-control form-control-sm" id="provenance" placeholder="Navire" [(ngModel)]="DOSSIERModel.Navire" disabled="true">
                          </div>

                          <div class="form-group col-xs-12 col-md-4">
                            <label for="Compagnie">Position*</label>
                            <select class="form-control form-control-sm" id="position_navire" [(ngModel)]="position_navireModel.lieu_position">
                                <option *ngFor = "let i of listeposition" value="{{i.libelle}}">{{i.libelle}}</option>
                            </select>
                          </div>
                          <div class="form-group col-xs-12 col-md-4">
                            <label for="date_arrive_prevu">Date Mouvement*</label>
                            <input type="date" class="form-control input-date form-control-sm" id="date_mouvement" [(ngModel)]="position_navireModel.date_position">
                          </div>
                          <div class="form-group col-xs-12 col-md-4">
                            <label for="capitaine">Heure</label>
                            <input type="time" class="form-control form-control-sm" id="Heure" placeholder="Heure" [(ngModel)]="position_navireModel.heure">
                          </div>

                          <div class="form-group col-xs-12 col-md-4">
                            <label for="provenance">Commentaire</label>
                            <textarea class="form-control form-control-sm" id="commentaire" placeholder="Commentaire" [(ngModel)]="position_navireModel.commentaire"></textarea>
                          </div>

                          <div class="col-sm-12 text-right mobile-buttons">
                          <button class="btn btn-primary btn-enregistrer" (click) = "Enregistrer(DOSSIERModel, $event)">
                              <font style="vertical-align: inherit;">
                                  <font style="vertical-align: inherit;">Enregistrer</font>
                              </font>
                          </button>
                          &nbsp;
                          <button class="btn btn-secondary btn-enregistrer" (click) = "Annuler()">
                              <font style="vertical-align: inherit;">
                                  <font style="vertical-align: inherit;">Annuler</font>
                              </font>
                          </button>
                      </div>

                      </div>

                    </div>


                  </div>


                 </div>
                    <div class="tab-pane fade" id="profile" role="tabpanel"  aria-labelledby="profile-tab" [ngClass]="{ 'show':activeTab==='profile', 'active':activeTab==='profile'}">
                        <br>
                        <div class="x_panel">
                  <div class="x_content">
                      <div class="form-group col-xs-12 col-md-12 recherche_liste">
                        <div class="row">
                                    <div class="form-group col-xs-12 col-md-3">
                                      <div class="persoautocomplete">
                                      <label for="">Selectionner N°Dossier</label>
                                     <input [(ngModel)]="recherche.numeroDossier" type="text" name="numdossDRech" id="numdossDRech" placeholder="N°Dossier"  class="form-control form-control-sm" autocomplete="off" (focus)="reInitSearch()" (blur)="reInitSearchBlur()" disabled="disabled">
                                       <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="">
                                       </button>
                                        <div class="classPerso  dropdown-menu">
                                          <form class="px-0 py-3">
                                              <div class="col-md-12">
                                                  <div class="row">
                                                      <div class="col-md-12">
                                                        <input type="text" [(ngModel)]="recherche.NumDossSaisi" class="form-control form-control-sm" placeholder="N°Dossier" name="rechercheSaisiDNavire" (ngModelChange)="AutocompleteRecherche(recherche.NumDossSaisi)" autocomplete="off">
                                                        <div>
                                                          <br>
                                                            <table class="table_bloc_liste tableReseach">
                                                                <thead class="theadfixed">
                                                                    <th>N°Dossier</th>
                                                                </thead>
                                                                <tbody>
                                                                    <tr class="SelectTable"  *ngFor="let x of listedossierRecherche" (click)="selectionAutocompleteRecherche(x)">
                                                                        <td>{{x.numeroDossier}}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </form>
                                        </div>
                                      </div>
                                    </div>

                                     <div class="form-group col-md-3">
                                            <label for="Recherche_navire">&nbsp;</label>
                                            <input type="text" id="Recherche_navire" [(ngModel)]="recherche.Navire" class="form-control form-control-sm" placeholder="Navire" (ngModelChange)="Recherche(recherche)" >
                                    </div>

                                    <div class="form-group col-md-3">
                                            <label for="Recherche_date_saisie">&nbsp;</label>
                                            <select class="form-control form-control-sm" [(ngModel)]="recherche.lieu_position" (ngModelChange)="Recherche(recherche)">
                                              <option value="" selected="selected">Sélectionner une position</option>
                                              <option *ngFor = "let i of listeposition" value="{{i.libelle}}">{{i.libelle}}</option>
                                            </select>
                                    </div>

                                    <div class="form-group col-md-3">
                                            <label for="Recherche_date_arrive_prevu">&nbsp;</label>
                                            <input type="date" id="Recherche_date_position" [(ngModel)]="recherche.date_position" class="form-control form-control-sm" placeholder="Date mouvement" (ngModelChange)="Recherche(recherche)" >
                                    </div>

                        </div>

                        </div>
                            <table class="table_bloc_liste">
                                      <thead>
                                          <tr>
                                            <th>#</th>
                                            <th>
                                              Date
                                            </th>

                                            <th>
                                              Heure
                                            </th>
                                            <th>
                                              N°Dossier
                                            </th>
                                            <th>
                                              Navire
                                            </th>
                                            <th>
                                              Position
                                            </th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                          </tr>

                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let n of Liste_mouvement_navires">
                                          <td>{{n.idPositionNavire}}</td>
                                          <td>{{n.date_position}}</td>
                                          <td>{{n.heure}}</td>
                                          <td>{{n.numeroDossier}}</td>
                                          <td>{{n.Navire}}</td>
                                          <td>{{n.lieu_position}}</td>
                                          <td class="text-center">
                                            <button ng-disabled="UtilisateurConnecte.autorisation == '3'" class="btn btn-warning btn-sm" (click) ="AfficherDonner(n.idGestionNavire, n.idPositionNavire)">
                                                <i class="fas fa-pencil-alt"></i>
                                            </button>
                                          </td>
                                          <td class="text-center">
                                              <button ng-disabled="UtilisateurConnecte.autorisation != '1'" (click) = "SupprimerDossier(n.idPositionNavire)" class="btn btn-danger btn-sm">
                                              <i class="far fa-trash-alt"></i>
                                          </button></td>
                                           <td class="text-center">
                                              <button class="btn btn-primary btn-sm" (click)="ImpressionFiche(n.idGestionNavire, n.idPositionNavire)">
                                                <i class="far fa-file-pdf"></i>
                                              </button>
                                          </td>
                                        </tr>
                                  </tbody>
                            </table>

                  </div>
                </div>
                        </div>
                   </div>
                </div>
            </div>
