<div class="container-fluid">
	<div class="row row-block">
		<div class="col-xs-12 col-md-6 block-droit">
			<table class="formulaire_connexion">
				<tbody>
					<tr>
						<td class="text-center">
							<div class="logo" style="margin-top: 100px; font-size: 25px; color: blue; font-weight: 800;">
								DIGITAL TRANSIT
								<!-- <img src="./assets/img/logo_small.png"> -->
							</div>
							<div class="form_content">

								<div class="col-md-12 text-left form-group">
									<label>Nom d'utilisateur</label>
									<input type="text" class="form-control" id="login" placeholder="" name="login" [(ngModel)] = "user.login" autocomplete="off">
								</div>

								<div class="col-md-12 text-left form-group">
									<label>Mot de passe</label>
									<input type="password" class="form-control" id="pwd1" placeholder="" [(ngModel)] = "user.pwd" name="pwd" autocomplete="off">
								</div>
								 <p class="errorLogin" *ngIf="error == true">Echec connexion : Login et/ou Mot de passe incorrect.</p>
                                <p class="errorLogin" *ngIf="error2 == true">Echec connexion : Compte désactivé.</p>
								<div class="col-md-12 text-center form-group group-btn-button">
									<br>
									<button class="btn btn-primary btn-sm btn-connexion" (click)="connexion(user)">Se connecter</button>
								</div>

							</div>
						</td>
					</tr>

				</tbody>
				<div class="footer">
					© 2022 Thanks You, inc. Tous droits réservés. |
					225 0707175421
				</div>
			</table>
		</div>

		<div class="col-xs-12 col-md-6 block-gauche hidden-xs" >
			<div class="bg-image11" style="background-image: url({{image_couverture}});">
				<div class="block-gauche-content">
					<h5  style="color:WHITE; font-size: 20px; line-height: 18px; padding-top: 15px; text-transform: uppercase;">{{dateLocale}}</h5>
					<div class="title-text">
            DIGITAL TRANSIT
					</div>


				</div>

			</div>

		</div>


	</div>
</div>
