<div class="x_panel x_panel_close">
        <div class="x_content">
             <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;"><span ><i  class="fa fa-arrow-left"></i></span>
                 <div  class="ml-auto text-right"><span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span><i  class="fa fa-chevron-right"></i><span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Gestion navires - Planning navires</span>
                 </div>
             </div>
             <div class="row">
               <div class="col-sm-12 col-md-4">
                  <div style="width: 98%;display: block;margin: 0px auto;background: #bcdaeb;padding-bottom: 10px;" >
                      <div>
                         <p style="background: #8282ef;padding: 10px;border-radius: 3px;margin-bottom: 10px;color: #fff;font-weight: 400;">
                           Navires Prévus (ETA)
                           <span style="float: right;font-weight: 500;font-size: 15px;color: #fff;">{{planning.listedossiersprevu.length}}</span>
                         </p>
                       </div>
                       <div style="width: 95%;margin: 0px auto;background: #fff;padding: 10px;border-radius: 3px;box-shadow: 1px 2px 3px;margin-bottom: 15px;padding-top: 5px;font-size: 13px;" *ngFor="let x of planning.listedossiersprevu">
                          <div style="position:relative;">
                            <span style="position: absolute;right: 0px;font-size: 1.2rem;color: #041c72;text-align: center;cursor: pointer;" (click)="OpenListeClients(x.numeroDossier, x.listeclients, x.PoidsTotal, x.QteTotal)">
                                <i class="fa-users fas"></i>
                                <span style="display: block;font-size: 12px;font-weight: 500;">Clients</span>
                            </span>
                            <span style="position: absolute;right: 5px;font-size: 1.2rem;color: #041c72;text-align: center;cursor: pointer;top: 55px;" (click)="OpenCaleNavire(x.idGestionNavire)">
                                <i class="fa-box fas"></i>
                                <span style="display: block;font-size: 12px;font-weight: 500;">Cales</span>
                            </span>
                            <span>
                                Navire : <b style="font-weight: 600;">{{x.Navire}}</b>
                            </span><br>
                            <span>
                                N°Dossier : <b style="font-weight: 600;">{{x.numeroDossier}}</b>
                            </span><br>
                            <span>
                                Draft : <b style="font-weight: 600;">{{x.draft}}</b>
                            </span><br>
                            <span>
                                Agent Navire : <b style="font-weight: 600;">{{x.agent_navire}}</b>
                            </span><br>
                            <span>
                                Poids : <b style="font-weight: 600;">{{x.total_poids}}</b> &nbsp;&nbsp;&nbsp; Qte : <b style="font-weight: 600;">{{x.total_qte}}</b>
                            </span><br>
                            <span>
                                Provenance : <b style="font-weight: 600;">{{x.provenance}}</b>
                            </span><br>
                            <span>
                                Date Arrivée prévue (ETA) : <b style="font-weight: 600;">{{x.date_arrivee_prevu}}</b>
                            </span>
                          </div>
                       </div>
                  </div>
                 
               </div>
               <div class="col-sm-12 col-md-4">
                  <div style="width: 98%;display: block;margin: 0px auto;background: #e7c7b0;padding-bottom: 10px;" >
                      <div>
                         <p style="background: #ed9150;padding: 10px;border-radius: 3px;margin-bottom: 10px;color: #fff;font-weight: 400;">
                           Navires Accostés (ETB)
                           <span style="float: right;font-weight: 500;font-size: 15px;color: #fff;">{{planning.listedossiersarrive.length}}</span>
                         </p>
                       </div>
                       <div style="width: 95%;margin: 0px auto;background: #fff;padding: 10px;border-radius: 3px;box-shadow: 1px 2px 3px;margin-bottom: 15px;padding-top: 5px;font-size: 13px;" *ngFor="let x of planning.listedossiersarrive">
                          <div style="position:relative;">
                           
                            <span style="position: absolute;right: 0px;font-size: 1.2rem;color: #ed9150;text-align: center;cursor: pointer;" (click)="OpenListeClients(x.numeroDossier, x.listeclients, x.PoidsTotal, x.QteTotal)">
                                <i class="fa-users fas"></i>
                                <span style="display: block;font-size: 12px;font-weight: 500;">Clients</span>
                            </span>

                            <span style="position: absolute;right: 5px;font-size: 1.2rem;color: #ed9150;text-align: center;cursor: pointer;top: 55px;" (click)="OpenCaleNavire(x.idGestionNavire)">
                                <i class="fa-box fas"></i>
                                <span style="display: block;font-size: 12px;font-weight: 500;">Cales</span>
                            </span>

                            <span>
                                Navire : <b style="font-weight: 600;">{{x.Navire}}</b>
                            </span><br>
                            <span>
                                N°Dossier : <b style="font-weight: 600;">{{x.numeroDossier}}</b>
                            </span><br>
                            <span>
                                Draft : <b style="font-weight: 600;">{{x.draft}}</b>
                            </span><br>
                            <span>
                                Agent Navire : <b style="font-weight: 600;">{{x.agent_navire}}</b>
                            </span><br>
                            <span>
                                Poids : <b style="font-weight: 600;">{{x.total_poids}}</b> &nbsp;&nbsp;&nbsp; Qte : <b style="font-weight: 600;">{{x.total_qte}}</b>
                            </span><br>
                            <span>
                                Reste : <b style="font-weight: 600;">{{x.reste}}</b>
                            </span><br>
                            <span>
                                Provenance : <b style="font-weight: 600;">{{x.provenance}}</b>
                            </span>
                            <br>
                            <span>
                                Date Arrivée (ETB) : <b style="font-weight: 600;">{{x.date_arrivee}}</b>
                            </span>
                            <br>
                            <span>
                                Date Arrivée prévue (ETA) : <b style="font-weight: 600;">{{x.date_arrivee_prevu}}</b>
                            </span>
                          </div>
                       </div>
                  </div>
                 
               </div>

               <div class="col-sm-12 col-md-4">
                  <div style="width: 98%;display: block;margin: 0px auto;background: #c9e0e7;padding-bottom: 10px;">
                      <div>
                         <p style="background: #10b9eb;padding: 10px;border-radius: 3px;margin-bottom: 10px;color: #fff;font-weight: 400;">
                           Navires Sortis (ETD)
                           <span style="float: right;font-weight: 500;font-size: 15px;color: #fff;">{{planning.listedossierssortie.length}}</span>
                         </p>
                       </div>
                       <div style="width: 95%;margin: 0px auto;background: #fff;padding: 10px;border-radius: 3px;box-shadow: 1px 2px 3px;margin-bottom: 15px;padding-top: 5px;font-size: 13px;padding-top: 5px;" *ngFor="let x of planning.listedossierssortie">
                          <div style="position: relative;">
                            
                            <span style="position: absolute;right: 0px;font-size: 1.2rem;color: #10b9eb;text-align: center;cursor: pointer;" (click)="OpenListeClients(x.numeroDossier, x.listeclients, x.PoidsTotal, x.QteTotal)">
                                <i class="fa-users fas"></i>
                                <span style="display: block;font-size: 12px;font-weight: 500;">Clients</span>
                            </span>
                            <span style="position: absolute;right: 5px;font-size: 1.2rem;color: #10b9eb;text-align: center;cursor: pointer;top: 55px;" (click)="OpenCaleNavire(x.idGestionNavire)">
                                <i class="fa-box fas"></i>
                                <span style="display: block;font-size: 12px;font-weight: 500;">Cales</span>
                            </span>

                            <span>
                                Navire : <b style="font-weight: 600;">{{x.Navire}}</b>
                            </span><br>
                            <span>
                                N°Dossier : <b style="font-weight: 600;">{{x.numeroDossier}}</b>
                            </span><br>
                            <span>
                                Draft : <b style="font-weight: 600;">{{x.draft}}</b>
                            </span><br>
                            <span>
                                Agent Navire : <b style="font-weight: 600;">{{x.agent_navire}}</b>
                            </span><br>
                            <span>
                                Poids : <b style="font-weight: 600;">{{x.total_poids}}</b> &nbsp;&nbsp;&nbsp; Qte : <b style="font-weight: 600;">{{x.total_qte}}</b>
                            </span><br>
                            <span>
                                Provenance : <b style="font-weight: 600;">{{x.provenance}}</b>
                            </span>
                            <br>
                            <span>
                                Date Départ (ETD) : <b style="font-weight: 600;">{{x.date_sortie}}</b>
                            </span>
                            <br>
                            <span>
                                Date Arrivée (ETB) : <b style="font-weight: 600;">{{x.date_arrivee}}</b>
                            </span>
                            <br>
                            <span>
                                Date Arrivée prévue (ETA) : <b style="font-weight: 600;">{{x.date_arrivee_prevu}}</b>
                            </span>
                          </div>
                       </div>
                  </div>
                 
               </div>
             </div>
    </div>
</div>
<button type="button" class="btn btn-primary  btn-sm popupclient" style="display:none;" data-backdrop="static" data-toggle="modal" data-target=".bs-Form-sm"></button>
<div class="modal fade bs-Form-sm" tabindex="-1" role="dialog" aria-hidden="true">
 <div class="modal-dialog modal-sm" role="document" style="max-width: 700px;">
  <div class="modal-content">
      <div class="row container_modal">
          <div class="modal-header">
            <h6 class="modal-title" style="font-weight: bold;">LISTE DES CLIENTS DU DOSSIER : {{dossierclient}}</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>
            </button>
          </div>
          <div style="width:100%;">
             <div class="modal-content-dl">
              <table class="table_bloc_liste">
                  <thead>
                        <th>#</th>
                        <th>Client</th>
                        <th>Produit</th>
                        <th>Poids (Tonne)</th>
                        <th>Qte</th>
                  </thead>
                  <tbody>
                        <tr *ngFor="let x of listeclients; let i = index">
                          <td>{{i+1}}</td>
                          <td>{{x.nom_societe}}</td>
                          <td>{{x.NomSpecificProduit}}</td>
                          <td>{{x.PoidsTotal}}</td>
                          <td>{{x.QteTotal}}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>
                              <b>Total</b>
                          </td>
                          <td><b>{{PoidsTotal}}</b></td>
                          <td><b>{{QteTotal}}</b></td>
                        </tr>
                  </tbody>
              </table>
              <br>
              <br>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>