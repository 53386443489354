import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { historique_utilisateur } from './historiqueutilisateur';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';


@Component({
  selector: 'app-historiqueutilisateur',
  templateUrl: './historiqueutilisateur.component.html',
  styleUrls: ['./historiqueutilisateur.component.css']
})
export class HistoriqueutilisateurComponent implements OnInit {
  apiUrl:String = "SERVER/groupeware/historiqueutilisateur/recherche";
  Liste_HistoriqueUtilisateur:any=[];
  recherche_historique:any={};
  constructor(private transfertDataService : transfertDataService) { }
  

  ngOnInit(): void {
    this.recherche_historique.nom = "";
    this.recherche_historique.date_debut = "";
    this.recherche_historique.date_fin = "";
  }

 
listehistoriqueutilisateur():void{
  this.transfertDataService.EnvoiData(this.recherche_historique, this.apiUrl)
  .subscribe((value:any) => {this.Liste_HistoriqueUtilisateur = value.liste_historique_utilisateur;});

}  

}
