import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-conditionnementproduit',
  templateUrl: './conditionnementproduit.component.html',
  styleUrls: ['./conditionnementproduit.component.css']
})
export class ConditionnementproduitComponent implements OnInit {
  activeTab:String="saisir";
  constructor() { }

  ngOnInit(): void {
  }

  search(activeTab:String){
    this.activeTab=activeTab;
  };

}
