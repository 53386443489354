import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { transfertDataService } from '../transfertData/transfertData.service';

@Injectable({
  providedIn: 'root'
})
export class CRUDService {

  constructor(private transfertDataService : transfertDataService) { }

		CRUD(table:String, data:any, url:String){
			var json = {data : data, table : table};
			
			return this.transfertDataService.EnvoiData(json, url).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
      )
		}

}
