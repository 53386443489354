export class factureconteneur {
  public IDFactureConteneur: number = 0;
  public DateFacture: number = 0;
  public idAmateur: number = 0;
  public creerPar: number = 0;
  public periodefacture: string = "";
  public creerLe: string = "";
  public modifierPar: number = 0;

  public modifierLe: string = "";
  constructor() {

  }

}
