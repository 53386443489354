import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { AuthService } from '../../../services/connexion/auth.service';

@Component({
  selector: 'app-transfert-magasins',
  templateUrl: './transfert-magasins.component.html',
  styleUrls: ['./transfert-magasins.component.css']
})
export class TransfertMagasinsComponent implements OnInit {
  apiUrl:String = "";
  activeTab:String="saisir";
  dossier:any = "";
  listauto:any = [];
  Listetrsfertmag:any = [];
  dossiertransfert:any={};
  produitAuto:any={};
  listautoSpecifik:any = [];
  liste_magasins:any = [];
  recherche:any = {};
  constructor(private transfertDataService : transfertDataService, private AuthService : AuthService) { }

  ngOnInit(): void {
    this.listemagasin();
    /*this.ListeTrsfertmag();*/
    this.dossiertransfert.id_produit_magasin = 0;
  }
  search(activeTab:String){
       this.activeTab=activeTab
     };

   Annuler(){
      this.dossiertransfert = {};
      this.dossiertransfert.id_produit_magasin = 0;
    }

  ListeAutoCompleteDossier(numDoss:any){
    this.apiUrl = "SERVER/gestionmagasin/transfertmagasin/recherche_magasin";
       var data = {NumDoss:numDoss};
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
     ).subscribe((value:any) => {
          this.listauto = value.listAuto;
       }, (erreur:any) => {
           console.log(erreur.status);
       })
   }

   SelectionAutocomplete(data:any): void{
       this.dossiertransfert.iddossier = data.idGestionNavire;
       this.dossiertransfert.numeroDossier = data.numeroDossier;
       this.dossiertransfert.numeroDossier1 = data.numeroDossier;
   }

    ListeAutoCompleteProduit(nomSousProduit:any){
      this.apiUrl = "SERVER/gestionmagasin/transfertmagasin/recherche_produit";
         var data = {nom:nomSousProduit, iddossier:this.dossiertransfert.iddossier};
         this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
           retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((value:any) => {
           this.listautoSpecifik= value.listautoSpecifiks;
         }, (erreur:any) => {
             console.log(erreur.status);
         })
     }

     SelectionAutocompleteProduit(data:any): void{
       this.dossiertransfert.idProduit= data.idProduit;
       this.dossiertransfert.nomSousProduit1 = data.nomSousProduit;
       this.dossiertransfert.nomSousProduit= data.nomSousProduit;
       this.dossiertransfert.nom_societe = data.nom_societe;
       this.dossiertransfert.id_produit_magasin = data.id_produit_magasin;
       this.dossiertransfert.idClient = data.idClient;
       this.dossiertransfert.poids_unitaire = data.poids;
       this.PoidsTotalCalcule();
     }

     PoidsTotalCalcule() : void{
           this.dossiertransfert.poids = (this.dossiertransfert.poids_unitaire * this.dossiertransfert.quantite)/1000;
           this.dossiertransfert.poids = this.dossiertransfert.poids.toFixed(2);
       }

    AfficherDonner(id_produit_magasin:any, idmagasin:any): void{
         this.apiUrl = "SERVER/gestionmagasin/transfertmagasin/information";
         var data = {id:id_produit_magasin, idmagasin:idmagasin};
         this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
           retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((response:any) => {
            this.dossiertransfert.id_magasin_source = response.listeproduitmagModel[0].id_magasin_source;
            this.dossiertransfert.numeroDossier = response.listeproduitmagModel[0].numeroDossier;
            this.dossiertransfert.id_magasin_destination = response.listeproduitmagModel[0].id_magasin_destination;
            this.dossiertransfert.nomSousProduit = response.listeproduitmagModel[0].nom_produit_magasin;
            this.dossiertransfert.nom_societe = response.listeproduitmagModel[0].nom_societe;
            this.dossiertransfert.quantite = response.listeproduitmagModel[0].qte_dispo;
            this.dossiertransfert.poids = response.listeproduitmagModel[0].poids_dispo;
            this.dossiertransfert.provenance = response.listeproduitmagModel[0].provenance;
            this.dossiertransfert.date_mvt = response.listeproduitmagModel[0].date_mvt;
            this.dossiertransfert.bl = response.listeproduitmagModel[0].bl;
            this.dossiertransfert.id_produit_magasin = response.listeproduitmagModel[0].id_produit_magasin;

             this.search("saisir");
         }, (erreur:any) => {
           console.log(erreur.status);
       })
     }

     listemagasin() : void{
         this.apiUrl = "SERVER/gestionmagasin/transfertmagasin/listemagasin";
         var data = {};
         this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((response:any) => {
           this.liste_magasins = response.liste_magasin;
         }, (erreur:any) => {
             console.log(erreur.status);
         })
      }


      Enregistrer(data:any): void{
        
         if(this.dossiertransfert.id_produit_magasin == 0){
           this.apiUrl = "SERVER/gestionmagasin/transfertmagasin/enregistrer";
         }else{
           this.apiUrl = "SERVER/gestionmagasin/transfertmagasin/modifier";
         }

         var infoUser = this.AuthService.getInfoUtilisateur();
         this.dossiertransfert.iduser = infoUser.id;
         

         this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((value:any) => {
           this.ListeTrsfertmag();
           this.Annuler();
           this.search("profile");
         }, (erreur:any) => {
             console.log(erreur.status);
         })
       }

      ListeTrsfertmag(){
       this.apiUrl = "SERVER/gestionmagasin/transfertmagasin/listeproduitmag";
       var data = {};
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
       ).subscribe((value:any) => {
           this.Listetrsfertmag = value.Listetrsfertmag;
         }, (erreur:any) => {
             console.log(erreur.status);
         })
      }

      Supprimer(id_produit_magasin:any): void{
           //this.apiUrl = "SERVER/gestionmagasin/transfertmagasin/supprimer";
           var data = {id:id_produit_magasin};
           this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((value:any) => {
             this.ListeTrsfertmag();
           }, (erreur:any) => {
               console.log(erreur.status);
           })
       }

    Recherche(data:any): void{
       if(this.recherche.numeroDossier == "" && this.recherche.id_client == "" && this.recherche.id_sous_produit == ""){
         this.ListeTrsfertmag();
       }else{
         //this.apiUrl = "SERVER/gestionmagasin/transfertmagasin/recherche";
         var data = data;

         this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
           retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((value:any) => {
          this.Listetrsfertmag= value.Listetrsfertmag;
         }, (erreur:any) => {
             console.log(erreur.status);
         })
      }
    }
}
