import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { Agentnavire } from '../../../classes/agent_navire';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { AuthService } from '../../../services/connexion/auth.service';

@Component({
  selector: 'app-agent-navire',
  templateUrl: './agent-navire.component.html',
  styleUrls: ['./agent-navire.component.css']
})
export class AgentNavireComponent implements OnInit {
  activeTab:String="saisir";
  apiUrl:String = "";
  listeagentnavire:any = [];
  recherche:any = {};
  supp_id:any = "";
  agentnavire:Agentnavire = new Agentnavire();
  constructor(private transfertDataService : transfertDataService,private AuthService : AuthService) { }
  ngOnInit(): void {
    this.liste_des_agentnavire();
  }

  search(activeTab:String){
     this.activeTab=activeTab
  };


     Annuler():void{
        this.agentnavire = new Agentnavire();
        this.recherche.nom = "";
       }

    enregistrer(data:any): void{

       if(this.agentnavire.id == 0)
         this.apiUrl = "SERVER/parametres/agentnavire/enregistrer";
       else
         this.apiUrl = "SERVER/parametres/agentnavire/modifier";
         var infoUser = this.AuthService.getInfoUtilisateur();
          this.agentnavire.idUser = infoUser.id;

         var d = {"agentnavire": this.agentnavire};
         this.transfertDataService.EnvoiData(d, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((value:any) => {
           this.liste_des_agentnavire();
           this.search("profile");
           this.Annuler();
         }, (erreur:any) => {

         })

     }

    supprimer(id:any):void{
       this.apiUrl = "SERVER/parametres/agentnavire/supprimer";
       var data = {id:id};
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
     ).subscribe((value:any) => {
         this.liste_des_agentnavire();
       }, (erreur:any) => {

       })

     }

       SupprimerPopup(id:any): void{
         $(".suppliste").trigger("click");
         this.supp_id = id;
       }



     liste_des_agentnavire(): void{

       this.apiUrl = "SERVER/parametres/agentnavire/listeagentnavire";
       var data = {};
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
           retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((response:any) => {
         this.listeagentnavire = response.listeagentnavire;
       }, (erreur:any) => {
           console.log(erreur.status);
       })

     }

     information(id:any):void{

           this.apiUrl = "SERVER/parametres/agentnavire/information";
           var data = {id:id};
           this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((response:any) => {
               this.agentnavire = response.agentnavire[0];
               this.search("saisir");

           }, (erreur:any) => {

         })

     }

     rechercheagentnavire(data:any): void{
       if(this.recherche.nom == "")
       this.liste_des_agentnavire();
       else{
         this.apiUrl = "SERVER/parametres/agentnavire/recherche";
         var data = data;
         this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
           retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((value:any) => {
           this.listeagentnavire= value.listeagentnavire;
         }, (erreur:any) => {
             console.log(erreur.status);
         })
       }

   }

}
