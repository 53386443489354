import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { Navire } from '../../../classes/navires';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { AuthService } from '../../../services/connexion/auth.service';

@Component({
  selector: 'app-gp-navire',
  templateUrl: './gp-navire.component.html',
  styleUrls: ['./gp-navire.component.css']
})
export class GpNavireComponent implements OnInit {


 activeTab:String="saisir";
    apiUrl:String = "";
    listenavires:any = [];
    liste_NomCompagnie:any = [];
    recherche:any = {};
    Libnavires:Navire = new Navire();
    supp_id:any = "";
    duplicateCodeNavire:boolean = false;
    message_error:any = "";

     constructor(private transfertDataService : transfertDataService,private AuthService : AuthService) { }

    ngOnInit(): void {
      this.listeNomCompagnie();
      this.Liste_des_navires();
    }
    search(activeTab:String){
       this.activeTab=activeTab
     };

      Annuler():void{
        this.Libnavires = new Navire();
        this.recherche.Navire = "";
       }

    //Fonction de verification de champs
    VerificationChampsObligatoire(tableau_champs_obligatoires:any){
        var verification_formulaire = true;
        for (var i = 0; i < tableau_champs_obligatoires.length; i++) {
            if($(tableau_champs_obligatoires[i]).val() == "" || $(tableau_champs_obligatoires[i]).val() == null || $(tableau_champs_obligatoires[i]).val() == "? string: ?"){
                    $(tableau_champs_obligatoires[i]).css("border","1px solid red");
                    verification_formulaire = false;
                    document.getElementById(tableau_champs_obligatoires[i].replace("#",""))?.scrollIntoView({behavior: "smooth"});
                    break;
            }else{
                $(tableau_champs_obligatoires[i]).css("border","");
            }
        }

        return verification_formulaire;
    }

    enregistrer(data:any): void{
       if(this.VerificationChampsObligatoire(["#NomNavire", "#codenavire"]) == false)
              return;

       this.duplicateCodeNavire = false;
       if(this.Libnavires.idNavire == 0)
         this.apiUrl = "SERVER/parametres/navire/enregistrer";
       else
         this.apiUrl = "SERVER/parametres/navire/modifier";

         var infoUser = this.AuthService.getInfoUtilisateur();
          this.Libnavires.user = infoUser.id;
         var d = {"navires": this.Libnavires};
         this.transfertDataService.EnvoiData(d, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((value:any) => {
            if(value.status == -1){
               this.duplicateCodeNavire = true;
               this.message_error = value.msg;
            }else{
              this.Liste_des_navires();
           this.search("profile");
           this.Annuler();
            }

         }, (erreur:any) => {
             console.log(erreur.status);
         })

     }

    supprimer(idNavire:any):void{
       this.apiUrl = "SERVER/parametres/navire/supprimer";
       var data = {idNavire:idNavire};
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
     ).subscribe((value:any) => {
         this.Liste_des_navires();
       }, (erreur:any) => {
           console.log(erreur.status);
       })

     }

     listeNomCompagnie() : void{

            this.apiUrl = "SERVER/parametres/navire/liste_NomCompagnie";

            var data = {};
            this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
                retryWhen(error =>
                  error.pipe(
                    scan((acc:any, error:any) => {
                      if (acc > 2) throw error;
                      return acc + 1;
                    }, 1),
                    delay(1000)
                  )
                )
            ).subscribe((response:any) => {
              this.liste_NomCompagnie = response.liste_NomCompagnie;
            }, (erreur:any) => {
                console.log(erreur.status);
            })
          }

     Liste_des_navires(): void{

       this.apiUrl = "SERVER/parametres/navire/listenavires";
       var data = {};
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
           retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((response:any) => {
         this.listenavires = response.liste_navires;
       }, (erreur:any) => {
           console.log(erreur.status);
       })

     }


     information(idNavire:any):void{

           this.apiUrl = "SERVER/parametres/navire/information";
           var data = {idNavire:idNavire};
           this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((response:any) => {
               this.Libnavires = response.navires[0];
               this.search("saisir");

           }, (erreur:any) => {
             console.log(erreur.status);
         })

     }

     recherchenavires(data:any): void{
       if(this.recherche.Navire == "")
       this.Liste_des_navires();
       else{
         this.apiUrl = "SERVER/parametres/navire/recherche";
         var data = data;
         this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
           retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((value:any) => {
           this.listenavires= value.liste_navires;
         }, (erreur:any) => {
             console.log(erreur.status);
         })
       }

   }

  SupprimerPopup(id:any): void{
    $(".suppliste").trigger("click");
    this.supp_id = id;
  }

}
