<div class="row">
    <div class="col-12 col-md-12 text-center">
        <br>
        <br>
        <!-- <img src="./assets/img/logo_small.png" alt="LOGO LTA"> -->
        <br>
        <br>
        <h4>Bienvenue ! </h4>
        <h3>LOGICIEL INTELLIGENT DIGITAL TRANSIT</h3>
    </div>
</div>