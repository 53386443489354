export class eltcoutexploitation {
  public id_eltCoutExp: number = 0;
  public id_cout_exploitation: number = 0;
  public categorie: string = "";
  public libelle: string = "";
  public qte: number = 0;
  public pu: number = 0;
  public cout: number = 0;
  public date_creation: string = "";
  public iduser: number = 0;
  public datemodification: string = "";
  public modifierPar: number = 0;
  constructor() {
  }

}
