<div class="x_panel x_panel_close">
    <div class="x_content">
       <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;"><span ><i  class="fa fa-arrow-left"></i></span>
           <div  class="ml-auto text-right"><span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span><i  class="fa fa-chevron-right"></i><span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Gestion Saisie Déchargements</span>
           </div>
       </div>
       <ul class="nav nav-tabs bar_tabs" id="myTab" role="tablist">
             <li class="nav-item">
               <a class="nav-link active" id="saisir-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='saisir'}" (click)="Tabulation('saisir')"
                  role="tab" aria-controls="saisir" aria-selected="true">Saisir Nouveau Déchargement</a>
             </li>
             <li class="nav-item">
               <a class="nav-link" id="profile-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='profile'}" (click)="Tabulation('profile')" role="tab" aria-controls="profile" aria-selected="false">Liste des Déchargements</a>
             </li>
       </ul>
       <div class="tab-content" id="myTabContent">
           <div [ngClass]="{ 'show':activeTab==='saisir', 'active':activeTab==='saisir'}" class="tab-pane fade active show" id="saisir" role="tabpanel" aria-labelledby="saisir-tab">
             <br>
             <div class="x_panel_title x_panel_close">
               <div class="x_title">
                 <h6>Informations Déchargement</h6>
                 <ul class="nav navbar-right panel_toolbox">
                 </ul>
                 <div class="clearfix"></div>
               </div>
               <div class="x_content">
                         <div class="form-row">
                            <div class="col-md-4 form-group">

                               <div class="persoautocomplete">
                                      <label for="">N°Dossier*</label>
                                     <input [(ngModel)]="dechargementModel.numeroDossier" type="text" name="numdossForm" id="numdossForm" placeholder="N°Dossier"  class="form-control form-control-sm" autocomplete="off" (focus)="reInitSearchForm()" (blur)="reInitSearchBlurForm()" disabled="disabled">
                                       <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="">
                                       </button>
                                        <div class="classPerso  dropdown-menu" style="width: 400px;">
                                          <form class="px-0 py-3">
                                              <div class="col-md-12">
                                                  <div class="row">
                                                      <div class="col-md-12">
                                                        <input type="text" [(ngModel)]="dossier.numeroDossier" class="form-control form-control-sm" placeholder="N°Dossier" name="FormSaisiDechargement" (ngModelChange)="ListeAutoCompleteDossierDechargements(dossier.numeroDossier)" autocomplete="off">
                                                        <div>
                                                          <br>
                                                            <table class="table_bloc_liste tableReseach">
                                                                <thead class="theadfixed">
                                                                    <th>Date</th>
                                                                    <th>N°Dossier</th>
                                                                    <th>Période Travail</th>
                                                                    <th>Tonnage Prévu</th>
                                                                </thead>
                                                                <tbody>
                                                                    <tr class="SelectTable"  *ngFor="let x of listdoss" (click)="SelectionAutocomplete(x)">
                                                                        <td>{{x.debut_operation_fr}}</td>
                                                                        <td>{{x.numeroDossier}}</td>
                                                                        <td>{{x.periode_travail}}</td>
                                                                        <td>{{x.tonnageprevu}}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </form>
                                        </div>
                                </div>

                           </div>

                           <div class="form-group col-xs-12 col-md-4">
                             <label for="num_navire">Navire</label>
                             <input type="text" class="form-control form-control-sm" id="num_navire" placeholder="Navire" [(ngModel)]="dechargementModel.Navire" ng-disabled="true" disabled="disabled">

                           </div>

                           <div class="form-group col-xs-12 col-md-4">
                             <label for="poids_total">Tonnage Total Prévu</label>
                             <input type="text" class="form-control form-control-sm" id="poids_total" placeholder="Poids Total Prévu" [(ngModel)]="dechargementModel.poids_total" ng-disabled="true" disabled="disabled">
                           </div>

                           <div class="form-group col-xs-12 col-md-4">
                               <label for="jour_nuit">Période de Travail</label>
                               <select id="jour_nuit" class="form-control form-control-sm" [(ngModel)]="dechargementModel.jour_nuit" placeholder="Période de Travail" [disabled]="true">
                                 <option value="0" selected="selected">Jour</option>
                                 <option value="1">Nuit</option>
                               </select>
                           </div>

                           <div class="form-group col-xs-12 col-md-4">
                             <label for="date_debut_operation">Date Déchargement</label>
                             <input type="date" class="form-control form-control-sm" id="date_dechargement" placeholder="Date déchargement" [(ngModel)]="dechargementModel.debut_operation" [disabled]="true">
                           </div>

                           <div class="form-group col-xs-12 col-md-4">
                             <label for="num_mini">N°Mini</label>
                             <input type="text" class="form-control form-control-sm" id="num_mini" placeholder="N°Mini" [(ngModel)]="dechargementModel.num_mini">
                           </div>

                           <div class="form-group col-xs-12 col-md-4">
                             <label for="quai">Quai</label>
                               <select id="quai" class="form-control form-control-sm" placeholder="Quai" [(ngModel)]="dechargementModel.quai">
                                 <option *ngFor = "let i of liste_quai" value="{{i.libelle}}">{{i.libelle}}</option>
                                 </select>
                           </div>

                           <div class="form-group col-xs-12 col-md-4">
                             <label for="acconier">Acconier</label>
                             <select id="acconier" class="form-control form-control-sm"  placeholder="Acconier" [(ngModel)]="dechargementModel.acconier">
                                 <option *ngFor = "let i of liste_acconier" value="{{i.libelle}}">{{i.libelle}}</option>
                               </select>
                           </div>

                           <div class="form-group col-xs-12 col-md-4">
                             <label for="Superviseur">Superviseur</label>
                             <input type="text" class="form-control form-control-sm" id="Superviseur" placeholder="Superviseur" [(ngModel)]="dechargementModel.superviseur">
                           </div>

                           <div class="form-group col-xs-12 col-md-4">
                             <label for="expert_client">Expert Client</label>
                             <input type="text" class="form-control form-control-sm" id="expert_client" placeholder="Expert Client" [(ngModel)]="dechargementModel.expert_client">
                           </div>

                           <div class="form-group col-xs-12 col-md-4">
                             <label for="Observation">Observation</label>
                             <textarea class="form-control form-control-sm" id="Observation" placeholder="Observation" [(ngModel)]="dechargementModel.observation"></textarea>
                           </div>
                     </div>
               </div>
             </div>
             <div class="x_panel_title x_panel_close">
               <div class="x_title">
                 <h6>Contenus Déchargement</h6>
                 <ul class="nav navbar-right panel_toolbox">
                 </ul>
                 <div class="clearfix"></div>
               </div>
              <div class="x_content">
                <div class="row">
                  <div class="col-md-12">
                  <div class="">
                      <p>
                        <button class="btn btn-sm btn-primary" (click) = "ajouter()">
                          <i class="fas fa-plus"></i>&nbsp;Nouveau
                        </button>
                      </p>
                         <table class="table_mainoeuvre_fourniture table_bloc_liste">
                            <thead>
                              <tr >
                                  <th>Désignation produit</th>
                                  <th>Client</th>
                                  <th>Cale</th>
                                  <th>N°Borderau</th>
                                  <th>Destination</th>
                                  <th>Poids (Tonne)</th>
                                  <th>Quantité</th>
                                  <th class="th_action_btn"></th>
                              </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let x of dataSousMenuModel;let i = index;">

                                <td>
                                  <div class="col-sm-12 persoautocomplete">

                                     <input [(ngModel)]="x.designationProduit" type="text" name="designationProduit" id="designationProduit" placeholder="Désignation Produit"  class="form-control form-control-sm" autocomplete="off" (focus)="reInitSearchDesignationProduit()" (blur)="reInitSearchBlurDesignationProduit()" disabled="disabled" style="width: 260px;">
                                       <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="" style="position: absolute;top: 0;">
                                       </button>
                                        <div class="classPerso  dropdown-menu">
                                          <form class="px-0 py-3">
                                              <div class="col-md-12">
                                                  <div class="row">
                                                      <div class="col-md-12">
                                                        <input type="text" [(ngModel)]="x.designationProduit1" class="form-control form-control-sm" placeholder="Désignation Produit" name="FormSaisidesignationProduitDecharg" (ngModelChange)="ListeAutoCompleteProd(x.designationProduit1)" autocomplete="off" >
                                                        <div>
                                                          <br>
                                                            <table class="table_bloc_liste tableReseach">
                                                                <thead class="theadfixed">
                                                                    <th>Désignation Produit</th>
                                                                    <th>Client</th>
                                                                </thead>
                                                                <tbody>
                                                                    <tr class="SelectTable"  *ngFor="let t of listproduit" (click)="SelectionAutocompleteProd(t, x)">
                                                                        <td>{{t.designationProduit}}</td>
                                                                        <td>{{t.nom_societe}}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </form>
                                        </div>


                                   </div>
                                </td>
                                <td>
                                  <input type="text" class="form-control form-control-sm" [(ngModel)]="x.nom_societe" disabled="disabled" style="width: 160px;">
                                </td>
                                <td>
                                  <select class="form-control form-control-sm" [(ngModel)]="x.cale" (ngModelChange)="UpdateDechargementNavire(x)">
                                    <option value="">Choisir un Cale</option>
                                    <option *ngFor="let x of listecales" value="{{x.id}}">{{x.libelle}}</option>
                                  </select>
                                </td>

                                 <td>
                                  <input type="text" class="form-control form-control-sm" [(ngModel)]="x.reference" (ngModelChange)="UpdateDechargementNavire(x)">
                                </td>

                                <td>
                                  <input type="text" class="form-control form-control-sm" [(ngModel)]="x.lieu_destination" (ngModelChange)="UpdateDechargementNavire(x)">
                                </td>
                                <td class="">
                                  <input type="number" class="form-control form-control-sm text-center" [(ngModel)]="x.PoidsNet" (ngModelChange)="UpdateDechargementNavire(x)">
                                </td>
                                <td  class="">
                                  <input type="number" class="form-control form-control-sm text-center" [(ngModel)]="x.qte" (ngModelChange)="UpdateDechargementNavire(x)">
                                </td>

                                <td class="text-center">
                                  <button class="btn btn-danger btn-sm" type="button" (click)="supprimer_sous_menu(x.idContenuNavire, i)" title="supprimer">
                                        <i class="fa fa-trash-alt" ></i>
                                  </button>

                                  </td>
                                </tr>
                                <tr *ngIf="dataSousMenuModel.length > 0">
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td style="text-align: center;font-size: 14px;font-weight: 500;">TOTAL</td>
                                  <td style="text-align: center;font-size: 14px;font-weight: 500;">{{totalPoidsDossier}}</td>
                                  <td style="text-align: center;font-size: 14px;font-weight: 500;">{{totalQteDossier}}</td>
                                  <td></td>
                                </tr>
                          </tbody>
                      </table>

                    </div>
                  </div>
                </div>

               </div>
             </div>
             <div class="col-md-12 text-center">
                          <div class="card-body">
                               <span>Télécharger des Documents</span>
                               <br><br>
                               <div class="col-md-12">
                                <div class="download-card" style="text-align: left!important;">
                                  <div class="col-md-12">
                                    <div class="row">
                                      <div class="inline"><br><br><img style ="width: 35px;" src="./assets/img/cloud.png"></div>
                                      <div class="inline1"><br><br><p><span>&nbsp;Cliquez sur le bouton pour ajouter des fichers</span><br>&nbsp; <button type="button" class="btn btn-primary btn-sm btn-sm-perso" (click)="fileInputBlock.click()">PARCOURIR LES FICHIERS </button></p></div>
                                    </div>
                                  </div>
                                </div>
                               </div>
                               <div class="row blockphotosZone" *ngIf="photos_previsualisation.length > 0">
                                <br><br>
                                <div class="col-md-3 photosZone" *ngFor="let x of photos_previsualisation" [ngClass]=" x.iddocument == '' ? 'uploadfile' : ''">
                                    <span>
                                      <span *ngIf="x.iddocument == ''" class="fichierToUpload">FICHIER NON ENREGISTRE</span>
                                    </span>
                                    <img [src]="x.src" id="file_{{x.iddocument}}">
                                    <div class="text-center text-center pt-2 pb-2 action-band">
                                      <button class="btn btn-sm btn-secondary" (click)="apercu(x.iddocument)">
                                            <i class="fas fa-search"></i>
                                        </button>&nbsp;&nbsp;
                                        <button class="btn btn-sm btn-danger" (click)="supprimer_image(x.lien, x.iddocument)">
                                            <i class="fas fa-trash"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            </div>

                            <input type="file" name="file" id="file" [hidden]="true" (change)="onFileChanged($event)" multiple="multiple" #fileInputBlock>
                        </div>
                        <br>
             <div class="text-right-btn">
                 <button type="button" class="btn btn-primary  btn-sm btn-enregistrer" (click) = "Enregistrer(dechargementModel, $event)">Enregistrer</button>&nbsp;
                 <button type="button" class="btn btn-secondary btn-sm btn-annuler" (click)="Annuler()">Annuler</button>&nbsp;
              </div>
            </div>
            <div [ngClass]="{ 'show':activeTab==='profile', 'profile':activeTab==='profile'}" class="tab-pane fade active show" id="profile" role="tabpanel" aria-labelledby="profile-tab">
             <div class="col-md-12"><br>
                     <div class="recherche_liste row">
                       <div class="col-sm-12 col-md-3 ">
                         <div class="form-group">
                           <label for="">N°Dossier</label>
                           <input type="text" id="" placeholder="N°DOSSIER" class="form-control form-control-sm" name="libe" [(ngModel)]="recherche.numeroDossier" (ngModelChange)="Recherche(recherche)">
                         </div>
                       </div>

                       <div class="col-sm-12 col-md-3 ">
                         <div class="form-group">
                           <label for="">Période de Travail</label>
                           <select id="Recherche_jour_nuit" class="form-control form-control-sm" [(ngModel)]="recherche.jour_nuit" (ngModelChange)="Recherche(recherche)">
                             <option selected="selected" value="">Période de Travail</option>
                             <option value="0">JOUR</option>
                             <option value="1">NUIT</option>
                          </select>
                        </div>
                       </div>
                       <div class="col-sm-12 col-md-3 ">
                         <div class="form-group">
                           <label for="">Date Déchargement</label>
                           <input type="date" id="" placeholder="jj/mm/aaaa" class="form-control form-control-sm" name="libe" [(ngModel)]="recherche.date" (ngModelChange)="Recherche(recherche)">
                         </div>
                       </div>

                       </div>
                   </div>

                        <br>
                         <div class="">
                         <div class="Table_Longer1">
                            <table class="table_bloc_liste table_responsive table_bloc_liste">
                                 <thead>
                                   <tr>
                                          <th>#</th>

                                          <th>
                                            Date Déchargement
                                          </th>
                                          <th>
                                            N°Dossier
                                          </th>
                                          <th>
                                            Navire
                                          </th>
                                          <th>
                                            Période de Travail
                                          </th>

                                          <th>
                                            Tonnage Réalisé
                                          </th>

                                          <th>
                                            Tonnage Prévu
                                          </th>

                                          <th>
                                            Performance
                                          </th>
                                          <th>
                                            Creer Par
                                          </th>
                                          <th>
                                            Modifier Par
                                          </th>
                                          <th>
                                            Modifier Le
                                          </th>
                                          <th style="width: 50px;"></th>
                                          <th style="width: 50px;"></th>
                                          <th style="width: 50px;"></th>
                                          <th style="width: 50px;"></th>
                                        </tr>
                                 </thead>
                                 <tbody>
                                      <tr *ngFor = "let x of Liste_dechargement_navires">
                                         <td>{{x.id_dechargelent}}</td>
                                         <td>{{x.date}}</td>
                                         <td>{{x.numeroDossier}}</td>
                                         <td>{{x.Navire}}</td>
                                         <td>{{x.jour_nuit}}</td>
                                         <td>{{x.total_poids_decharge}}</td>
                                         <td>{{x.tonnageprevu}}</td>
                                         <td>{{x.performance}}</td>
                                         <td>{{x.nom}}</td>
                                         <td>{{x.modifierpar}}</td>
                                         <td>{{x.datemodification}}</td>
                                         <td class="text-center">
                                           <button  class="btn btn-success btn-sm" (click) ="AfficherDonner(x.id_dechargelent)">
                                                  <i class="fas fa-pencil-alt"></i>
                                           </button>
                                         </td>
                                          <td class="text-center">
                                             <button  class="btn btn-danger btn-sm" (click) = "SupprimerDechargementPopup(x.id_dechargelent)">
                                                <i class="far fa-trash-alt"></i>
                                             </button>
                                          </td>
                                          <td class="text-center">
                                              <button class="btn btn-primary btn-sm" (click)="ImpressionFiche(x.id_dechargelent)">
                                                <i class="far fa-file-pdf"></i>
                                              </button>
                                          </td>
                                          <td class="text-center">
                                              <button class="btn btn-warning btn-sm" (click)="downloadDoc(x.id_dechargelent)" *ngIf="x.doc == 1">
                                                <i class="fa fa-download"></i>
                                              </button>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                         </div>
                     </div>

            </div>

       </div>
    </div>
</div>
<button type="button" class="btn btn-primary  btn-sm suppdossier" style="display:none;" data-backdrop="static" data-toggle="modal" data-target=".bs-Form-sm"></button>
<div class="modal fade bs-Form-sm" tabindex="-1" role="dialog" aria-hidden="true">
 <div class="modal-dialog modal-sm" role="document">
  <div class="modal-content">
      <div class="row container_modal">
          <div class="modal-header">
            <h6 class="modal-title" style="font-weight: bold;">SUPPRESSION DECHARGEMENT</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>
            </button>
          </div>
          <div>
             <div class="modal-content-dl">
              <p>Souhaitez-vous supprimer ce déchargement ?</p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" data-dismiss="modal"  class="btn btn-primary btn-sm" (click) = "SupprimerDechargement()">Oui</button>
            <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Non</button>
          </div>
      </div>
    </div>
  </div>
</div>
