import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../services/transfertData/transfertData.service';
import { declaration_fait_navire } from '../../classes/declaration_fait_navire';
import { AuthService } from '../../services/connexion/auth.service';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { ConfigurationService } from '../../config/configuration.service';

@Component({
  selector: 'app-declaration-fait-navire',
  templateUrl: './declaration-fait-navire.component.html',
  styleUrls: ['./declaration-fait-navire.component.css']
})
export class DeclarationFaitNavireComponent implements OnInit {
  activeTab:String="saisir";
  apiUrl:String="";
  fait_navire:any = {};
  listedossiers:any = [];
  dataSousMenuModel:any = [];
  ligneSelectionne:any = -1;
  precLine:any = 0;
  nbreline:any = 0;
  recherche:any = {};
  constructor(private ConfigurationService : ConfigurationService,private transfertDataService : transfertDataService, private AuthService : AuthService) { }

  ngOnInit(): void {
    this.fait_navire.iddossier = 0;
  }

  reInitSearch(): void{
    this.listedossiers = [];
  }

  reInitSearchBlur(): void{
    setTimeout((val:any) => {
        this.listedossiers = [];
    }, 500);
  }

  search(activeTab:String){
     this.activeTab=activeTab;
   };


  selectionligne(index:any, ordre:any){
    this.ligneSelectionne = index;
    this.precLine = ordre;
  }

  SuivantPrecedent(action:any){
    if(this.ligneSelectionne == -1)
      return;
    var apiUrl = "SERVER/gestionnavire/declaration_fait_navire/SuivantPrecedent";
    var data = {"Ligne" : this.precLine, ac:action, "iddossier" : this.fait_navire.iddossier};
    this.transfertDataService.EnvoiData(data, apiUrl).subscribe((response:any) => {
        this.listeSousMenuMenus();
        this.ligneSelectionne = -1;
    });

  }

  enregistrer_sous_menu(): void{
      var declaration_fait_navire_object = new declaration_fait_navire();
      var infoUser = this.AuthService.getInfoUtilisateur();
      declaration_fait_navire_object.iddossier = this.fait_navire.iddossier;
      declaration_fait_navire_object.iduser = infoUser.id;
      declaration_fait_navire_object.ordre = this.nbreline;
      this.apiUrl = "SERVER/gestionnavire/declaration_fait_navire/enregistrer_fait";
      this.transfertDataService.EnvoiData(declaration_fait_navire_object, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
      ).subscribe((value:any) => {
        this.listeSousMenuMenus();
      }, (erreur:any) => {
      })


  }

  supprimer_sous_menu(id_declaration_fait_navire:any): void{
      this.apiUrl = "SERVER/gestionnavire/declaration_fait_navire/supprimer_fait";
        var data = {id_declaration_fait_navire:id_declaration_fait_navire};
        this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
          retryWhen(error =>
            error.pipe(
              scan((acc:any, error:any) => {
                if (acc > 2) throw error;
                return acc + 1;
              }, 1),
              delay(1000)
            )
          )
      ).subscribe((value:any) => {
          this.listeSousMenuMenus();
        }, (erreur:any) => {
            
      })
  }

  UpdateFaitDeclarationNavire(fait:any){
    this.apiUrl = "SERVER/gestionnavire/declaration_fait_navire/modifier_fait";
      var data = {fait:fait};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
      
      }, (erreur:any) => {
         
    })
    
  }

  listeSousMenuMenus(): void{
    this.apiUrl = "SERVER/gestionnavire/declaration_fait_navire/listesousmenu";
    var data = {iddossier:this.fait_navire.iddossier};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
      this.dataSousMenuModel = response.liste_sous_menu;
    }, (erreur:any) => {
    })
  }

  ListeAutoCompleteDossier(numdoss:any){
   this.apiUrl = "SERVER/gestionnavire/declaration_fait_navire/autocomplete_dossier";
      var data = {numdoss:numdoss};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listedossiers= value.listedossiers;
      }, (erreur:any) => {
      })
  }

  selectionAutocompleteDossier(data:any):void{
    this.fait_navire.iddossier = data.idGestionNavire;
    this.fait_navire.numeroDossier = data.numeroDossier;
    this.recherche.numero = data.idGestionNavire;
    this.fait_navire.navire = data.Navire;
    this.fait_navire.agentnavire = data.nom;
    this.fait_navire.date_arrivee_prevu = data.date_arrivee_prevu;
    this.fait_navire.date_arrivee = data.date_arrivee;
    this.fait_navire.date_sortie = data.date_sortie;
    this.fait_navire.navire = data.Navire;
    this.dataSousMenuModel = data.faits;
    this.nbreline = this.dataSousMenuModel.length + 1;

  }

  Annuler(){
    this.fait_navire = {};
    this.dataSousMenuModel = [];
    this.nbreline = 0;
    this.listedossiers = [];
    this.fait_navire.iddossier = 0;
  }


  Exporter(): void{
    var lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/declarationfait/excel_declaration.php?numDossier="+this.recherche.numero;
    window.open(lien_fiche_ExpST , '_blank')?.focus();
  }

}
