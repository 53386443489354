export class TRANSITAIRE {
  public id: number = 0;
  public nom: string = "";
  public tel: string = "";
  public creerPar: number = 0;
  public creerLe: string = "";
  public modifierPar: number = 0;
  public modifierLe: string = "";
  
  constructor() {
  }

}
