import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { Groupe } from './groupe';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { AuthService } from '../../../services/connexion/auth.service';

@Component({
  selector: 'app-creer-groupe',
  templateUrl: './creer-groupe.component.html',
  styleUrls: ['./creer-groupe.component.css']
})
export class CreerGroupeComponent implements OnInit {
   apiUrl:String = "";
   activeTab:String="saisir";
   listegroupe_data:any = [];
   recherche:any = {};

   GroupeModel:Groupe = new Groupe();
  constructor(private AuthService : AuthService,private transfertDataService : transfertDataService) { }

  ngOnInit(): void {
   this.listeGroupe();
  }

 Tabulation(activeTab:any): void{
    this.activeTab=activeTab;
  }

  Annuler():void{
      this.GroupeModel = new Groupe();
  }

  Enregistrer(data:any): void{
    if(this.GroupeModel.idgroupe == 0)
      this.apiUrl = "SERVER/groupeware/groupe/enregistrer";
    else
      this.apiUrl = "SERVER/groupeware/groupe/modifier";
      var infoUser = this.AuthService.getInfoUtilisateur();
      this.GroupeModel.idUser = infoUser.id;
    var d = {"groupe": this.GroupeModel};
    this.transfertDataService.EnvoiData(d, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
      this.listeGroupe();
      this.Annuler();
      this.Tabulation("profile");
    }, (erreur:any) => {
        console.log(erreur.status);
    })
  }

  SupprimerGroupe(idgroupe:any): void{
      this.apiUrl = "SERVER/groupeware/groupe/supprimer";
      var data = {id:idgroupe};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listeGroupe();
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }


  listeGroupe(): void{
    this.apiUrl = "SERVER/groupeware/groupe/listegroupe";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
      this.listegroupe_data = response.liste_groupe;
    }, (erreur:any) => {
        console.log(erreur.status);
    })
  }


  AfficherDonner(idgroupe:any): void{
      this.apiUrl = "SERVER/groupeware/groupe/information";
      var data = {idgroupe:idgroupe};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
          this.GroupeModel = response.groupe[0];
          this.Tabulation("saisir");
      }, (erreur:any) => {
        console.log(erreur.status);
    })
  }

  Recherche(data:any): void{
      this.apiUrl = "SERVER/groupeware/groupe/recherche";
      var data = data;
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listegroupe_data= value.liste_groupe;
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }
}
