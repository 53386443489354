<div class="x_panel x_panel_close">
        <div class="x_content">
               <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;"><span ><i  class="fa fa-arrow-left"></i></span>
                   <div  class="ml-auto text-right"><span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span><i  class="fa fa-chevron-right"></i><span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Gestion navires - Arrivée Navire | Départ Navire</span>
                   </div>
               </div>
                <ul class="nav nav-tabs bar_tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" id="saisir-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='saisir'}" (click)="search('saisir')"
                       role="tab" aria-controls="saisir" aria-selected="true">Arrivées Navires (ETB) | Départs Navires (ETD)</a>
                  </li>

                </ul>
                <div class="tab-content" id="myTabContent">
                    <div [ngClass]="{ 'show':activeTab==='saisir', 'active':activeTab==='saisir'}" class="tab-pane fade active show" id="saisir" role="tabpanel" aria-labelledby="saisir-tab">
                        <br>
                        <div class="x_title">
                          <h5>INFORMATIONS NAVIRE</h5>
                          <ul class="nav navbar-right panel_toolbox" style="padding-left: 50px;">
                          </ul>
                          <div class="clearfix"></div>
                        </div>
                        <div class="x_content">
                          <div class="row">
                            <div class="form-group col-xs-12 col-md-4">
                             <div class="persoautocomplete">
                              <label for="">Selectionner N°Dossier*</label>
                             <input [(ngModel)]="jsonnavire.numeroDossier" type="text" name="numdossArrive" id="numdossArrive" placeholder="N°Dossier"  class="form-control form-control-sm" autocomplete="off" (focus)="reInitSearch()" (blur)="reInitSearchBlur()" disabled="disabled">
                               <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="">
                               </button>
                                <div class="classPerso  dropdown-menu">
                                  <form class="px-0 py-3">
                                      <div class="col-md-12">
                                          <div class="row">
                                              <div class="col-md-12">
                                                <input type="text" [(ngModel)]="jsonnavire.NumDossSaisi" class="form-control form-control-sm" placeholder="N°Dossier" name="NavireSaisiDepArrive" (ngModelChange)="AutocompleteArriveeNavire(jsonnavire.NumDossSaisi)" autocomplete="off">
                                                <div>
                                                  <br>
                                                    <table class="table_bloc_liste tableReseach">
                                                        <thead class="theadfixed">
                                                            <th>N°Dossier</th>
                                                        </thead>
                                                        <tbody>
                                                            <tr class="SelectTable"  *ngFor="let x of listedossierarrivee" (click)="selectionAutocompleteArriveeNavire(x)">
                                                                <td>{{x.numeroDossier}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                              </div>
                                          </div>
                                      </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                            <div class="form-group col-xs-12 col-md-4">
                              <label for="numero_dossier">Navire</label>
                              <input type="text" class="form-control form-control-sm" id="Navire" name="Navire" [(ngModel)]="jsonnavire.navire" [disabled]="true">
                            </div>
                            <div class="form-group col-xs-12 col-md-4">
                              <label for="numero_dossier">Agent Navire</label>
                              <input type="text" class="form-control form-control-sm" id="agentnavire" name="agentnavire" [(ngModel)]="jsonnavire.agentnavire" [disabled]="true">
                            </div>
                            <div class="col-xs-12 col-md-4">
                                <h5>ETAT DU NAVIRE</h5>
                                <div class="x_panel text-center" style="padding-left:0px;padding-right: 0px;padding-left: 0px;padding-right: 0px;background: #dede;border-radius: 3px;">
                                   <div class="x_content">
                                         <div class="row col-md-12" style="display: inline-block;">
                                              <div class="tile_count row">
                                                <div class="col-md-4 col-sm-4  tile_stats_count" style="margin-bottom: 10px;border-bottom: 0;padding-bottom: 0px;border-right: 1px solid #D9DEE4; ">
                                                   <span><h3 style="width: 100%;padding: 8px;margin-top: 0px;color: #006699;font-size: 15px;">Prévu</h3></span>
                                                   <div class="count ng-binding" style="">
                                                     <input type="radio" name="etatNavire" [(ngModel)]="jsonnavire.etatNavire" value="0" class="form-control form-control-sm">
                                                   </div>
                                                </div>

                                                <div class="col-md-4 col-sm-4  tile_stats_count" style="margin-bottom: 10px;border-bottom: 0;padding-bottom: 0px; border-right: 1px solid #D9DEE4;">
                                                   <span><h3 style="width: 100%; padding: 8px;margin-top: 0px;color: #006699;font-size: 15px;">Arrivé</h3></span>
                                                   <div class="count green ng-binding" style="">
                                                     <input type="radio" name="etatNavire" [(ngModel)]="jsonnavire.etatNavire" value="1" class="form-control form-control-sm">
                                                   </div>
                                                </div>
                                               <div class="col-md-4 col-sm-4  tile_stats_count" style="margin-bottom: 10px;border-bottom: 0;padding-bottom: 0px;">
                                                   <span><h3 style="width: 100%;padding: 8px;margin-top: 0px;color: #006699;font-size: 15px;">Départ</h3></span>
                                                   <div class="count red ng-binding" style="">
                                                     <input type="radio" name="etatNavire" [(ngModel)]="jsonnavire.etatNavire" value="2" class="form-control form-control-sm">
                                                   </div>
                                               </div>
                                             </div>
                                         </div>
                                   </div>
                                  </div>
                            </div>
                            <div class="form-group col-xs-12 col-md-6">

                            </div>
                            <div class="form-group col-xs-12 col-md-4">
                              <label for="numero_dossier">Date*</label>
                              <input type="date" class="form-control form-control-sm" id="date_arrivee" name="date_arrivee" [(ngModel)]="jsonnavire.date" >
                            </div>
                              <div class="col-sm-12 text-right mobile-buttons">
                                <button class="btn btn-primary btn-enregistrer" (click)="Enregistrer(jsonnavire, $event)">
                                    <font style="vertical-align: inherit;">
                                        <font style="vertical-align: inherit;">Enregistrer</font>
                                    </font>
                                </button>
                                &nbsp;
                                <button class="btn btn-secondary btn-enregistrer">
                                    <font style="vertical-align: inherit;" (click)="Annuler()">
                                        <font style="vertical-align: inherit;">Annuler</font>
                                    </font>
                                </button>

                              </div>
                          </div>

                        </div>
                    </div>

                   </div>
                </div>
            </div>
