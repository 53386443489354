<div class="x_panel x_panel_close">
    <div class="x_content">
        <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;"><span ><i  class="fa fa-arrow-left"></i></span>
        	<div  class="ml-auto text-right"><span class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span>
        		<i  class="fa fa-chevron-right"></i>
        		<span class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Import Livraison Conteneur - Réception Conteneur</span>
         	</div>
        </div>
        <br>
        <div class="col-md-12">
            <div class="content-action">
              <ul class="nav nav-tabs bar_tabs" id="myTab" role="tablist">
                 <li class="nav-item">
                   <a class="nav-link active" id="saisir-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='saisir'}"
                      (click)="search('saisir')"
                      role="tab" aria-controls="saisir" aria-selected="true">Saisir Conteneur</a>
                 </li>
                 <li class="nav-item">
                   <a class="nav-link" id="profile-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='profile'}"
                       (click)="search('profile')"
                       role="tab" aria-controls="profile" aria-selected="false">Liste Conteneur</a>
                 </li>
              </ul>

                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade active show" id="saisir" role="tabpanel" aria-labelledby="saisir-tab"
                         [ngClass]="{ 'show':activeTab==='saisir', 'active':activeTab==='saisir'}">
                    	<br>
                      <div class="cadreformulaire">
                        <div class="row">
                          <!--<div class="form-group col-md-6">
                              <div class="persoautocomplete">
                                  <label for="">Nom Amateur*</label>
                                  <input [(ngModel)]="AmateurAutocoplet.amateur" type="text" name="" id="amateur2"   class="form-control form-control-sm"
                                         autocomplete="off" (focus)="reInitSearchFormAmateur()" (blur)="reInitSearchBlurFormAmateur()" disabled="disabled">
                                  <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton"
                                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id=""></button>
                                  <div class="classPerso  dropdown-menu">
                                      <form class="px-0 py-3">
                                          <div class="col-md-12">
                                              <div class="row">
                                                  <div class="col-md-12">
                                                      <input type="text" [(ngModel)]="AmateurAutocoplet.amateur1" class="form-control form-control-sm"
                                                             placeholder="Nom Amateur" name="FormSaisiEntreeStockRechDisopRecap"
                                                             (ngModelChange)="ListeAutoCompleteAmateurRech(AmateurAutocoplet.amateur1)" autocomplete="off">
                                                      <div>
                                                      <br>
                                                          <table class="table_bloc_liste tableReseach">
                                                              <thead class="theadfixed">
                                                                  <th>Nom Amateur</th>
                                                              </thead>
                                                              <tbody>
                                                                  <tr class="SelectTable"  *ngFor="let x of liste_AmateurRech"
                                                                      (click)="SelectionAutocompleteRechAmateur(x)">
                                                                      <td>{{x.nomAmateur}}</td>
                                                                  </tr>
                                                              </tbody>
                                                          </table>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </form>
                                  </div>
                              </div>
                          </div>--->
                          <div class="form-group col-md-6">
                             <label for="">Armateur*</label>
                             <select class="form-control form-control-sm" [(ngModel)]="CONTENEURIMPORT.idAmateur" id="amateur2">

                              <option *ngFor="let i of amateur" value="{{i.idAmateur}}">{{i.nomAmateur}}</option>
                             </select>
                          </div>
                          <div class="form-group col-md-6">
                              <label>
                                  <font style="vertical-align: inherit;">
                                      <font style="vertical-align: inherit;">N° BL*</font>
                                  </font>
                              </label>
                              <input name="numBL" type="text" placeholder="N° BL" class="form-control form-control-sm"
                                     [(ngModel)]="CONTENEURIMPORT.numBL" id="numBL1">
                          </div>

                          <div class="form-group col-md-6">
                              <div class="persoautocomplete">
                                  <label for="">Nom Client*</label>
                                  <input [(ngModel)]="clientAutocoplet.client1" type="text" name="" id="client1"   class="form-control form-control-sm"
                                         autocomplete="off" (focus)="reInitSearchFormClient()" (blur)="reInitSearchBlurFormClient()" disabled="disabled">
                                  <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton"
                                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id=""></button>
                                  <div class="classPerso  dropdown-menu">
                                      <form class="px-0 py-3">
                                          <div class="col-md-12">
                                              <div class="row">
                                                  <div class="col-md-12">
                                                      <input type="text" [(ngModel)]="clientAutocoplet.client" class="form-control form-control-sm"
                                                             placeholder="Nom Client" name="FormSaisiEntreeStockRechDisopRecap"
                                                             (ngModelChange)="ListeAutoCompleteClientrech(clientAutocoplet.client)" autocomplete="off">
                                                      <div>
                                                      <br>
                                                          <table class="table_bloc_liste tableReseach">
                                                              <thead class="theadfixed">
                                                                  <th>Nom Client</th>
                                                              </thead>
                                                              <tbody>
                                                                  <tr class="SelectTable"  *ngFor="let x of liste_ClientsRech"
                                                                      (click)="SelectionAutocompleterechClient(x)">
                                                                      <td>{{x.nom_societe}}</td>
                                                                  </tr>
                                                              </tbody>
                                                          </table>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </form>
                                  </div>
                              </div>
                          </div>
                          <div class="form-group col-md-6">
                              <label>
                                  <font style="vertical-align: inherit;">
                                      <font style="vertical-align: inherit;">Contact Client</font>
                                  </font>
                              </label>
                              <input name="contactClient" type="text" placeholder="Contact" class="form-control form-control-sm"
                                      [(ngModel)]="CONTENEURIMPORT.contactClient">
                          </div>
                          <div class="form-group col-md-6">
                              <label>
                                  <font style="vertical-align: inherit;">
                                      <font style="vertical-align: inherit;">N° Conteneur*</font>
                                  </font>
                              </label>
                              <input name="numConteneur" id="numConteneur1" type="text" placeholder="N° Conteneur" class="form-control form-control-sm"
                                     [(ngModel)]="CONTENEURIMPORT.numConteneur">
                          </div>

                          <div class="form-group col-md-6">
                        <label for="colFormLabelSm" class="form-label">Type</label>
                        <input type="text" class="form-control form-control-sm" list="datalistOptions101" id="colFormLabelSm" [(ngModel)]="CONTENEURIMPORT.type" placeholder="Type">
                        <datalist id="datalistOptions101">
                          <option value="Dry">Dry</option>
                          <option value="Frigo">Frigo</option>
                          <option value="Flat">Flat</option>
                        </datalist>
                      </div>

                          <div class="form-group col-md-6">

                              <label for="colFormLabelSm" class="form-label">Taille</label>
                              <input type="text" class="form-control form-control-sm" list="datalistOptions10" id="colFormLabelSm" placeholder="Taille" [(ngModel)]="CONTENEURIMPORT.taille" >
                              <datalist id="datalistOptions10">
                                <option value="20">20</option>
                                <option value="40">40</option>
                                <option value="autre">autre</option>
                              </datalist>

                          </div>
                          <div class="form-group col-md-6">
                              <label>
                                  <font style="vertical-align: inherit;">
                                      <font style="vertical-align: inherit;">Transitaire*</font>
                                  </font>
                              </label>
                              <select class="form-control form-control-sm" [(ngModel)]="CONTENEURIMPORT.idtransitaire" id="transitoire1">

                               <option *ngFor="let i of Transitaireliste" value="{{i.id}}">{{i.nom}}</option>
                              </select>
                          </div>

                              <div class="form-group col-md-6">
                                  <label>
                                      <font style="vertical-align: inherit;">
                                          <font style="vertical-align: inherit;">N° Déclaration</font>
                                      </font>
                                  </label>
                                  <input name="numDeclaration" type="text" placeholder="N° Déclaration" class="form-control form-control-sm"
                                         [(ngModel)]="CONTENEURIMPORT.numDeclaration">
                              </div>

                                <div class="form-group col-md-6">
                                    <label>
                                        <font style="vertical-align: inherit;">
                                            <font style="vertical-align: inherit;">Lieu Livraison*</font>
                                        </font>
                                    </label>
                                    <select class="form-control form-control-sm" [(ngModel)]="CONTENEURIMPORT.idlieuLivraison" id="lieuLivraison">

                                     <option *ngFor="let i of livraison" value="{{i.id}}">{{i.lieu}}</option>
                                    </select>
                                </div>


                              <div class="form-group col-md-6">
                                  <label>
                                      <font style="vertical-align: inherit;">
                                          <font style="vertical-align: inherit;">BADT*</font>
                                      </font>
                                  </label>
                                  <input name="rl" type="date" placeholder="BADI" class="form-control form-control-sm"
                                         [(ngModel)]="CONTENEURIMPORT.badi" id="BADI">
                              </div>
                              <div class="form-group col-md-6">
                                  <label>
                                      <font style="vertical-align: inherit;">
                                          <font style="vertical-align: inherit;">DO</font>
                                      </font>
                                  </label>
                                  <input name="rl" type="date" placeholder="DO" class="form-control form-control-sm"
                                         [(ngModel)]="CONTENEURIMPORT.do">
                              </div>

                              <div class="form-group col-md-6">
                                 <label>
                                      <font style="vertical-align: inherit;">
                                                <font style="vertical-align: inherit;">Date Reception*</font>
                                                </font>
                                </label>
                                <input name="dateReception" type="date" class="form-control form-control-sm"
                                                                       [(ngModel)]="CONTENEURIMPORT.dateReception" id="dateReception">
                                  </div>
                              <br>
                              <div class="col-md-12 text-center">
                              <div class="card-body">
                                  <span>Télécharger des Documents</span>
                                     <br><br>
                                     <div class="col-md-12">
                                      <div class="download-card" style="text-align: left!important;">
                                        <div class="col-md-12">
                                          <div class="row">
                                            <div class="inline"><br><br><img style ="width: 35px;" src="./assets/img/cloud.png"></div>
                                            <div class="inline1"><br><br><p><span>&nbsp;Cliquez sur le bouton pour ajouter des fichers</span><br>&nbsp; <button type="button" class="btn btn-primary btn-sm btn-sm-perso" (click)="fileInputBlock.click()">PARCOURIR LES FICHIERS </button></p></div>
                                          </div>
                                        </div>
                                      </div>
                                     </div>
                                     <div class="row blockphotosZone" *ngIf="photos_previsualisation.length > 0">
                                          <br><br>
                                          <div class="col-md-3 photosZone" *ngFor="let x of photos_previsualisation"
                                               [ngClass]=" x.iddocument == '' ? 'uploadfile' : ''">
                                              <span>
                                                  <span *ngIf="x.iddocument == ''" class="fichierToUpload">FICHIER NON ENREGISTRE</span>
                                              </span>
                                              <img [src]="x.src" id="file_{{x.iddocument}}">
                                              <div class="text-center text-center pt-2 pb-2 action-band">
                                                <button class="btn btn-sm btn-secondary" (click)="apercu(x.iddocument)">
                                                      <i class="fas fa-search"></i>
                                                  </button>&nbsp;&nbsp;
                                                  <button class="btn btn-sm btn-danger" (click)="supprimer_image(x.lien, x.iddocument)">
                                                      <i class="fas fa-trash"></i>
                                                  </button>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <input type="file" name="file" id="file" [hidden]="true" (change)="onFileChanged($event)" multiple="multiple" #fileInputBlock>
                              </div>

                        </div>
                        <br>
                        <div class="text-right-btn">
                            <button type="button" class="btn btn-primary  btn-sm btn-enregistrer" (click) = "Enregistrer(CONTENEURIMPORT, $event)">Enregistrer</button>&nbsp;
                            <button type="button" class="btn btn-secondary btn-sm btn-annuler" (click)="Annuler()">Annuler</button>&nbsp;
                         </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="profile" role="tabpanel"  aria-labelledby="profile-tab"
                         [ngClass]="{'show':activeTab==='profile', 'active':activeTab==='profile'}">
                         <br>
                        <div class="">
                          <div class="recherche_liste">
                            <div class="row">
                              <div class="form-group col-md-4">
                                 <label for="">Armateur</label>
                                 <select class="form-control form-control-sm" [(ngModel)]="recherche.amateur" (ngModelChange)="recherch(recherche)">
                                  <option value=""></option>
                                  <option *ngFor="let i of amateur" value="{{i.nomAmateur}}">{{i.nomAmateur}}</option>
                                 </select>
                              </div>
                              <!--  <div class="form-group col-md-4">

                                  <div class="persoautocomplete">
                                      <label for="">Nom Amateur</label>
                                      <input [(ngModel)]="recherche.amateur" type="text" name="" id="amateur"   class="form-control form-control-sm"
                                             autocomplete="off" (focus)="reInitSearchFormAmateur()" (blur)="reInitSearchBlurFormAmateur()" disabled="disabled">
                                      <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton"
                                              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id=""></button>
                                      <div class="classPerso  dropdown-menu">
                                          <form class="px-0 py-3">
                                              <div class="col-md-12">
                                                  <div class="row">
                                                      <div class="col-md-12">
                                                          <input type="text" [(ngModel)]="recherche.amateurSaisi" class="form-control form-control-sm"
                                                                 placeholder="Nom Amateur" name="FormSaisiEntreeStockRechDisopRecap"
                                                                 (ngModelChange)="ListeAutoCompleteAmateurRech(recherche.amateurSaisi)" autocomplete="off">
                                                          <div>
                                                          <br>
                                                              <table class="table_bloc_liste tableReseach">
                                                                  <thead class="theadfixed">
                                                                      <th>Nom Amateur</th>
                                                                  </thead>
                                                                  <tbody>
                                                                      <tr class="SelectTable"  *ngFor="let x of liste_AmateurRech"
                                                                          (click)="SelectionAutocompleteRecheche(x)">
                                                                          <td>{{x.nomAmateur}}</td>
                                                                      </tr>
                                                                  </tbody>
                                                              </table>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </form>
                                      </div>
                                  </div>
                              </div>--->
                                <div class="form-group col-xs-12 col-md-4">
                                  <label>
                                      <font style="vertical-align: inherit;">
                                          <font style="vertical-align: inherit;">N° Conteneur</font>
                                      </font>
                                  </label>
                                    <input name="recherche" type="text" placeholder="N° Conteneur" class="form-control form-control-sm"
                                           [(ngModel)]="recherche.numConteneur" (ngModelChange)="recherch(recherche)">
                                </div>
                                <div class="form-group col-md-4">
                                      <label>N° BL</label>
                                    <input name="numBL" type="text" placeholder="N° BL" class="form-control form-control-sm"
                                           [(ngModel)]="recherche.numBL" (ngModelChange)="recherch(recherche)">
                                </div>



                                <div class="form-group col-xs-12 col-md-4">
                                    <label>Periode Du</label>
                                    <input name="recherche" type="date" class="form-control form-control-sm"
                                           [(ngModel)]="recherche.dateDebut" (ngModelChange)="recherch(recherche)">
                                </div>

                                <div class="form-group col-xs-12 col-md-4">
                                    <label>Au</label>
                                    <input name="recherche" type="date" class="form-control form-control-sm"
                                           [(ngModel)]="recherche.dateFin" (ngModelChange)="recherch(recherche)">
                                </div>
                            </div>

                          </div>
                       </div>
                       <br>
                       <div class="Table_Longer">
                            <table class="table_bloc_liste">
                             <thead>
                                  <tr>
                                    <th>Date Reception</th>
                                    <th>N° Bl</th>
                                    <th>Nom Armateur</th>
                                    <th>N° Conteneur</th>
                                    <th>Nom Client</th>
                                    <th>Lieu Livraison</th>
                                    <th>BADT</th>
                                    <th>Créer Par</th>
                                    <th>Créer Le</th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let i of listeConteneurs">

                                    <td>{{i.dateReception}}</td>
                                    <td>{{i.numBL}}</td>
                                    <td>{{i.amateur}}</td>
                                    <td>{{i.numConteneur}}</td>

                                    <td>{{i.client}}</td>
                                    <td>{{i.lieuLivraison}}</td>
                                    <td>{{i.badi}}</td>
                                    <td>{{i.nom}}</td>
                                    <td>{{i.creerLe}}</td>
                                    <td class="text-center">
                                        <button  class="btn btn-warning btn-sm" (click)="information(i.idConteneurImport)">
                                           <i class="fas fa-pencil-alt"></i>
                                        </button>
                                    </td>
                                    <td class="text-center">
                                        <button  class="btn btn-danger btn-sm" (click)="SupprimerPopup(i.idConteneurImport)">
                                            <i class="far fa-trash-alt"></i>
                                        </button>
                                    </td>
                                    <td class="text-center">
                                        <button class="btn btn-warning btn-sm" (click)="downloadDoc(i.idConteneurImport)" *ngIf="i.doc == 1">
                                          <i class="fa fa-download"></i>
                                        </button>
                                    </td>
                                  </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-xs-12 col-ms-12 text-right" style="float: right;margin-top: 15px;" *ngIf="liste_pagination.length > 1">
                          <div class="pagination pagination_liste_tableau">
                            <a class="page-link" *ngFor="let n of liste_pagination, let i = index" [ngClass]="{'active': i == activePagination}" (click)="CurrentPagePagination(n,i,$event)">{{i + 1}}</a>
                          </div>
                        </div>
<br><br>
                        <div class="row">
                              <div  class="col-md-6 form-group"></div>
                              <div  class="col-md-3 form-group">
                                <div  class="form-group">
                                  <label >&nbsp;</label>
                                  <button  class="btn btn-success btn-block btn-sm" (click)="Exporter()">
                                    <i  class="fas fa-excel"></i>&nbsp;Exporter</button>
                                </div>
                              </div>
                              <div  class="col-md-3 form-group">
                                <div  class="form-group">
                                  <label >&nbsp;</label>
                                  <button  class="btn btn-warning btn-block btn-sm" (click)="ConfirmationImpressionFormulaire()"><i  class="fas fa-print"></i>&nbsp;Imprimer</button>
                                </div>
                              </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>

    </div>
</div>

<button type="button" class="btn btn-primary  btn-sm suppliste" style="display:none;" data-backdrop="static" data-toggle="modal" data-target=".bs-Form-sm"></button>
<div class="modal fade bs-Form-sm" tabindex="-1" role="dialog" aria-hidden="true">
 <div class="modal-dialog modal-sm" role="document">
  <div class="modal-content">
      <div class="row container_modal">
          <div class="modal-header">
            <h6 class="modal-title" style="font-weight: bold;">SUPPRESSION</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>
            </button>
          </div>
          <div>
             <div class="modal-content-dl">
              <p>Souhaitez-vous supprimer ce enregistrement ?</p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" data-dismiss="modal"  class="btn btn-primary btn-sm" (click)="supprimer(supp_id)">Oui</button>
            <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Non</button>
          </div>
      </div>
    </div>
  </div>
</div>
