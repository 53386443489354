<div class="x_panel x_panel_close">
    <div class="x_content">
        <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;"><span ><i  class="fa fa-arrow-left"></i></span>
        	<div  class="ml-auto text-right"><span class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span>
        		<i  class="fa fa-chevron-right"></i>
        		<span class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Import Livraison Conteneur  - Prévision TC/VT Conteneur</span>
         	</div>
        </div>
        <br>
        <div class="col-md-12">
            <div class="content-action">
              <ul class="nav nav-tabs bar_tabs" id="myTab" role="tablist">
                 <li class="nav-item">
                   <a class="nav-link active" id="saisir-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='saisir'}" (click)="search('saisir')"
                      role="tab" aria-controls="saisir" aria-selected="true">Saisir Prévision TC/VT</a>
                 </li>
                <li class="nav-item">
                   <a class="nav-link" id="profile-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='profile'}"
                       (click)="search('profile')"
                       role="tab" aria-controls="profile" aria-selected="false">Liste Prévision TC/VT</a>
                 </li>
              </ul>

                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade active show" id="saisir" role="tabpanel" aria-labelledby="saisir-tab"
                       [ngClass]="{ 'show':activeTab==='saisir', 'active':activeTab==='saisir'}">
                    <br>

                    <div class="x_panel_title x_panel_close">
                      <div class="x_title">
                        <h6>Saisir Prévision TC/VT</h6>
                        <ul class="nav navbar-right panel_toolbox">
                        </ul>
                        <div class="clearfix"></div>
                      </div>
                      <div class="x_content">


                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label>Date Prévision *</label>
                                    <input name="dateTraite" id="dateTraitee" type="date" placeholder="Date Traitée" class="form-control form-control-sm"
                                           [(ngModel)]="CONTENEURTRAITE.dateTraite">
                                </div>


                                <div class="form-group col-md-6">
                                    <label>Date Transmis Abidjan Conteneur*</label>
                                    <input name="dateTransmis" id="dateTransmis" type="date" placeholder="Date Traitée" class="form-control form-control-sm"
                                           [(ngModel)]="CONTENEURTRAITE.dateTransmis">
                                </div>

                            </div>
                            <div class="x_panel_title x_panel_close">
                              <div class="x_title HH" style="background :#6c757d">
                                <h6>Liste Conteneur A Traité</h6>
                                <ul class="nav navbar-right panel_toolbox">
                                </ul>
                                <div class="clearfix"></div>
                              </div>
                              <div class="x_content">
                                <div class="row">
                                  <div class=" row  col-md-12">
                                    <div class="col-sm-4">
                                        <div class="row">
                                          <div class="form-group col-md-12">
                                             <label for="">N° BL</label>
                                             <input name="numBL" type="text" placeholder="N° BL" class="form-control form-control-sm"
                                                    [(ngModel)]="recherche.numBL" id="numBL1" (ngModelChange)="recherch(recherche)">
                                          </div>

                                        </div>

                                    </div>
                                    <div class="col-sm-4">
                                        <div class="row">
                                            <div class="form-group col-xs-12 col-md-12">
                                              <label for="">N° Conteneur</label>
                                                <input name="recherche" type="text" placeholder="N° Conteneur" class="form-control form-control-sm"
                                                       [(ngModel)]="recherche.numConteneur" (ngModelChange)="recherch(recherche)">
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-sm-2">
                                      <div class="row">
                                        <br>
                                         <div class="form-check col-xs-12 col-md-2" style="margin-left:13px;">
                                             <br>
                                              <input type="checkbox" class="form-check-input" id="" style="float: left;" [(ngModel)]="recherche.tous"
                                              (ngModelChange)="TousAfficher()">
                                              <label for="flexCheckChecked1">Tous</label>
                                          </div>
                                          <div class="form-check col-xs-12 col-md-2"style="margin-left:30px;">
                                              <br>
                                              <input type="checkbox" class="form-check-input" id="" style="float: left;" [(ngModel)]="recherche.Aucun"
                                              (ngModelChange)="RienAfficher()">
                                              <label for="flexCheckChecked1">Aucun</label>
                                          </div>
                                      </div>
                                    </div>
                                    <div class="col-sm-2">
                                      <div class="form-group col-xs-12 col-md-12" style="margin-top: 20px;">
                                          <div class="btn-group" style="float:right;">
                                              <button type="button" class="btn btn-primary btn-md" (click)="Ajouter()">
                                                  Ajouter
                                              </button>
                                          </div>
                                      </div>
                                    </div>
                                  </div>


                                    <div class="Table_Longer mx-3">
                                        <table class="table_bloc_liste">
                                            <thead>
                                                <tr>
                                                    <th style="width: 50px;">Choix</th>
                                                    <th>N° Conteneur</th>
                                                    <th>Client</th>
                                                    <th>N°BL</th>
                                                    <th>Taille</th>
                                                    <th>Type</th>
                                                    <th>Numéro Déclaration</th>
                                                    <th>Transitaire</th>
                                                    <th>Lieu Livraison</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                              <tr *ngFor="let i of listeConteneurs">
                                                <td class="">
                                                    &nbsp;<input type="checkbox" class="form-check-input" id="" style="margin-left: 10px;" [(ngModel)]="i.choix">
                                                </td>
                                                <td>{{i.numConteneur}}</td>
                                                <td>{{i.client}}</td>
                                                <td>{{i.numBL}}</td>
                                                <td>{{i.taille}}</td>
                                                <td>{{i.type}}</td>
                                                <td>{{i.numDeclaration}}</td>
                                                <td>{{i.transitaire}}</td>
                                                <td>{{i.lieuLivraison}}</td>
                                              </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-xs-12 col-ms-12 text-right" style="float: right;margin-top: 15px;" *ngIf="liste_pagination1.length > 1">
                                      <div class="pagination pagination_liste_tableau">
                                        <a class="page-link" *ngFor="let n of liste_pagination1, let i = index" [ngClass]="{'active': i == activePagination1}" (click)="CurrentPagePagination1(n,i,$event)">{{i + 1}}</a>
                                      </div>
                                    </div>

                                </div>
                              </div>
                            </div>

                          <br>
                          <div class="x_panel_title x_panel_close">
                            <div class="x_title">
                              <h6>Liste Conteneur Selectionné</h6>
                              <ul class="nav navbar-right panel_toolbox">
                              </ul>
                              <div class="clearfix"></div>
                            </div>
                            <div class="x_content">
                              <div class="row">
                                  <div class="col-sm-4">
                                      <div class="row">
                                         <div class="form-check col-xs-12 col-md-2" style="margin-left:13px;">
                                              <input type="checkbox" class="form-check-input" id="" style="float: left;"
                                              [(ngModel)]="recherche.tous1"
                                              (ngModelChange)="TousAfficher1()">
                                              <label for="flexCheckChecked1">Tous</label>
                                          </div>
                                          <div class="form-check col-xs-12 col-md-2">
                                              <input type="checkbox" class="form-check-input" id="" style="float: left;"
                                              [(ngModel)]="recherche.Aucun1"
                                              (ngModelChange)="RienAfficher1()">
                                              <label for="flexCheckChecked1">Aucun</label>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="form-group col-xs-12 col-md-4"></div>
                                  <div class="form-group col-xs-12 col-md-4">
                                      <div class="btn-group" style="float:right;">
                                          <button type="button" class="btn btn-danger btn-md" (click)="Retirer()">
                                              Retirer
                                          </button>
                                      </div>
                                  </div>
                                  <div class="Table_Longer mx-3">
                                      <table class="table_bloc_liste">
                                          <thead>
                                              <tr>
                                                <th style="width: 50px;">Choix</th>
                                                <th>N° Conteneur</th>
                                                <th>Client</th>
                                                <th>N°BL</th>
                                                <th>Taille</th>
                                                <th>Type</th>
                                                <th>Numéro Déclaration</th>
                                                <th>Transitaire</th>
                                                <th>Lieu Livraison</th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                            <tr *ngFor="let y of listeConteneursSelect">
                                              <td class="">
                                                  &nbsp;<input type="checkbox" class="form-check-input" id="" style="margin-left: 10px;" [(ngModel)]="y.choix">
                                              </td>
                                              <td>{{y.numConteneur}}</td>
                                              <td>{{y.nom_societe}}</td>
                                              <td>{{y.numBL}}</td>
                                              <td>{{y.taille}}</td>
                                              <td>{{y.type}}</td>
                                              <td>{{y.numDeclaration}}</td>
                                              <td>{{y.transitaire}}</td>
                                              <td>{{y.lieuLivraison}}</td>
                                            </tr>
                                          </tbody>
                                      </table>
                                  </div>
                              </div>
                            </div>
                          </div>


                            <div class="col-sm-12 text-right mobile-buttons">
                                <button class="btn btn-primary btn-sm btn-enregistrer" (click)="Enregistrer()">
                                    <font style="vertical-align: inherit;">
                                        <font style="vertical-align: inherit;">Enregistrer</font>
                                    </font>
                                </button>&nbsp;&nbsp;
                                <button type="button" class="btn btn-primary  btn-sm ouverturefiche" style="display:none;" data-backdrop="static" data-toggle="modal" data-target=".bs-Form-sm"></button>&nbsp;&nbsp;


                                <button class="btn btn-secondary btn-sm btn-enregistrer" (click)="Annuler()">
                                    <font style="vertical-align: inherit;">
                                        <font style="vertical-align: inherit;">Annuler</font>
                                    </font>
                                </button>
                            </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal fade bs-Form-sm" tabindex="-1" role="dialog" aria-hidden="true">
                                       <div class="modal-dialog modal-sm" role="document">
                                        <div class="modal-content">
                                            <div class="row container_modal">
                                                <div class="modal-header">
                                                  <h6 class="modal-title" style="font-weight: bold;">{{ModaleTitle}}</h6>
                                                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>
                                                  </button>
                                                </div>
                                                <div>
                                                   <div class="modal-content-dl">
                                                    <p>{{ModaleText}}</p>
                                                  </div>
                                                </div>
                                                <div class="modal-footer" *ngIf = "ModalFoot == 'FootNoErr'">
                                                  <button type="button" data-dismiss="modal"  class="btn btn-primary btn-sm" (click) = "Fiche()">Imprimer</button>
                                                  <button type="button" class="btn btn-success btn-sm" data-dismiss="modal" (click) = "Fiche1()">Exporter</button>
                                                  <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" (click) = "search('profile')">Non</button>

                                                </div>
                                                <div class="modal-footer" *ngIf = "ModalFoot == 'FootErr'">
                                                  <button type="button" class="btn btn-primary btn-sm" data-dismiss="modal" (click) = "search('saisir')">Non</button>
                                                </div>
                                            </div>
                                          </div>
                                        </div>
                    </div>
                    <div class="tab-pane fade" id="profile" role="tabpanel"  aria-labelledby="profile-tab"
                         [ngClass]="{'show':activeTab==='profile', 'active':activeTab==='profile'}">
                         <br>
                        <div class="">
                            <div class="recherche_liste">
                                <div class="row">
                                    <div class="form-group col-xs-12 col-md-4">
                                        <label>Periode Du</label>
                                        <input name="recherche" type="date" class="form-control form-control-sm"
                                               [(ngModel)]="rechercher.dateDebut" (ngModelChange)="rechercheConteneurtraiter(rechercher)">
                                    </div>

                                    <div class="form-group col-xs-12 col-md-4">
                                        <label>Au</label>
                                        <input name="recherche" type="date" class="form-control form-control-sm"
                                               [(ngModel)]="rechercher.dateFin" (ngModelChange)="rechercheConteneurtraiter(rechercher)">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="Table_Longer">
                            <table class="table_bloc_liste">
                             <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Date Prévision</th>
                                    <th>Date Transmis Abidjan Conteneur</th>
                                    <th>Créer Par</th>
                                    <th>Créer Le</th>
                                    <th>Modifier Par</th>
                                    <th>Modifier Le</th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr  *ngFor="let i of listeConteneurstraite">
                                    <td>{{i.idConteneurTraite}}</td>
                                    <td>{{i.dateTraite}}</td>
                                    <td>{{i.dateTransmis}}</td>
                                    <td>{{i.nom}}</td>
                                    <td>{{i.creerLe}}</td>
                                    <td>{{i.modifierPar}}</td>
                                    <td>{{i.modifierLe}}</td>

                                    <td class="text-center">
                                        <button  class="btn btn-warning btn-sm" (click)="information(i.idConteneurTraite)">
                                           <i class="fas fa-pencil-alt"></i>
                                        </button>
                                    </td>
                                    <td class="text-center">
                                        <button  class="btn btn-danger btn-sm" (click)="SupprimerPopup(i.idConteneurTraite)">
                                            <i class="far fa-trash-alt"></i>
                                        </button>
                                    </td>
                                    <td class="text-center">
                                        <button  class="btn btn-success btn-sm" (click)="Exporter1(i.idConteneurTraite)">
                                           <i class="fas fa-file-excel"></i>
                                        </button>
                                    </td>
                                    <td class="text-center">
                                        <button  class="btn btn-warning btn-sm" (click)="ConfirmationImpressionFormulaire1(i.idConteneurTraite)">
                                           <i class="fas fa-print"></i>
                                        </button>
                                    </td>
                                  </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-xs-12 col-ms-12 text-right" style="float: right;margin-top: 15px;" *ngIf="liste_pagination.length > 1">
                          <div class="pagination pagination_liste_tableau">
                            <a class="page-link" *ngFor="let n of liste_pagination, let i = index" [ngClass]="{'active': i == activePagination}" (click)="CurrentPagePagination(n,i,$event)">{{i + 1}}</a>
                          </div>
                        </div>
<br><br>
                        <div class="row">
                              <div  class="col-md-6 form-group"></div>
                              <div  class="col-md-3 form-group">
                                <div  class="form-group">
                                  <label >&nbsp;</label>
                                  <button  class="btn btn-success btn-block btn-sm" (click)="Exporter()">
                                    <i  class="fas fa-excel"></i>&nbsp;Exporter</button>
                                </div>
                              </div>
                              <div  class="col-md-3 form-group">
                                <div  class="form-group">
                                  <label >&nbsp;</label>
                                  <button  class="btn btn-warning btn-block btn-sm" (click)="ConfirmationImpressionFormulaire()"><i  class="fas fa-print"></i>&nbsp;Imprimer</button>
                                </div>
                              </div>
                            </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<button type="button" class="btn btn-primary  btn-sm suppliste" style="display:none;" data-backdrop="static" data-toggle="modal" data-target=".bs-Form-sm"></button>
<div class="modal fade bs-Form-sm" tabindex="-1" role="dialog" aria-hidden="true">
 <div class="modal-dialog modal-sm" role="document">
  <div class="modal-content">
      <div class="row container_modal">
          <div class="modal-header">
            <h6 class="modal-title" style="font-weight: bold;">SUPPRESSION</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>
            </button>
          </div>
          <div>
             <div class="modal-content-dl">
              <p>Souhaitez-vous supprimer ce enregistrement ?</p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" data-dismiss="modal"  class="btn btn-primary btn-sm" (click)="supprimer(supp_id)">Oui</button>
            <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Non</button>
          </div>
      </div>
    </div>
  </div>
</div>
