import { Component, OnInit } from '@angular/core';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { Router } from '@angular/router';
import { pipe } from 'rxjs';
import { transfertDataService } from '../../services/transfertData/transfertData.service';

@Component({
  selector: 'app-gestion-cale-navire',
  templateUrl: './gestion-cale-navire.component.html',
  styleUrls: ['./gestion-cale-navire.component.css']
})
export class GestionCaleNavireComponent implements OnInit {
  activeTab:String="saisir";
  liste_des_provenances:any = [];
  liste_gents_navires:any = [];
  dossier:any = {};
  listdoss:any = [];
  contenuCale:any = [
    {id: 5, libelle: "CALE 5", PoidsTotal: 0, QteTotal: 0, listeclients : []},
    {id: 4, libelle: "CALE 4", PoidsTotal: 0, QteTotal: 0, listeclients : []},
    {id: 3, libelle: "CALE 3", PoidsTotal: 0, QteTotal: 0, listeclients : []},
    {id: 2, libelle: "CALE 2", PoidsTotal: 0, QteTotal: 0, listeclients : []},
    {id: 1, libelle: "CALE 1", PoidsTotal: 0, QteTotal: 0, listeclients : []},
  ];
  constructor(private transfertDataService : transfertDataService, private router: Router) { }

  ngOnInit(): void {
    this.listeAgentsnavire();
    this.listeProvenance();
    this.autoLoadCale();
  }

  autoLoadCale(){
    var url = this.router.url.split('?')[1];
    if(url !== undefined){
        var query = url.split('=');
        if(query[0] == "iddossier" && query[1] != ""){
            this.dossier.idGestionNavire = query[1];
            this.AutoCompleteDossier();
            this.ProduitsCale();
        }
    }
  }

  AutoCompleteDossier(){
      var apiUrl = "SERVER/gestionnavire/gestioncalenavire/recherche_dossier_iddoss";
      var data = {iddoss:this.dossier.idGestionNavire};
      this.transfertDataService.EnvoiData(data, apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.dossier = value.data[0];
      }, (erreur:any) => {
          
      })
  }

  ListeAutoCompleteDossier(numerodossier:any){
      var apiUrl = "SERVER/gestionnavire/gestioncalenavire/recherche_dossier";
      var data = {numeroDossier:numerodossier};
      this.transfertDataService.EnvoiData(data, apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listdoss= value.listdoss;
      }, (erreur:any) => {
          
      })
  }

  listeAgentsnavire(): void{
    var apiUrl = "SERVER/gestionnavire/gestioncalenavire/listeAgentsnavire";
    var data = {};
    this.transfertDataService.EnvoiData(data, apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
      this.liste_gents_navires = response.liste_gents_navires;
    }, (erreur:any) => {
    })
  }

  listeProvenance(): void{
    var apiUrl = "SERVER/gestionnavire/gestioncalenavire/listeProvenanceNavire";
    var data = {};
    this.transfertDataService.EnvoiData(data, apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
      this.liste_des_provenances = response.liste_des_provenances;
    }, (erreur:any) => {
    })
  }

  ProduitsCale(): void{
    var apiUrl = "SERVER/gestionnavire/gestioncalenavire/produits_cale";
    var data = {iddoss:this.dossier.idGestionNavire};
    this.transfertDataService.EnvoiData(data, apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
      this.contenuCale = response;
    }, (erreur:any) => {
    })
  }

  SelectionAutocomplete(data:any): void{
    this.dossier = data;
    this.ProduitsCale();
  }

  reInitSearchForm(): void{
    this.listdoss = [];
  }

  reInitSearchBlurForm(): void{
    setTimeout((val:any) => {
        this.listdoss = [];
    }, 500);
  }

  search(activeTab:String){
     this.activeTab=activeTab;
  };


}
