import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { CREERMAGASIN } from '../../../classes/creermagasin';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { AuthService } from '../../../services/connexion/auth.service';

@Component({
  selector: 'app-gp-creer-magasin',
  templateUrl: './gp-creer-magasin.component.html',
  styleUrls: ['./gp-creer-magasin.component.css']
})
export class GpCreerMagasinComponent implements OnInit {

  activeTab:String="saisir";
  apiUrl:String = "";
  listemagasin:any = [];
  suppid:any = {};
  recherche:any = {};
  Libmagasin:CREERMAGASIN = new CREERMAGASIN();

  constructor(private transfertDataService : transfertDataService,private AuthService : AuthService) { }

  ngOnInit(): void {
    this.Liste_des_magasin();
  }

  search(activeTab:String){
     this.activeTab=activeTab
   }

   Annuler():void{
        this.Libmagasin = new CREERMAGASIN();
        this.recherche.NomMagasin = "";
       }

    enregistrer(data:any): void{

       if(this.Libmagasin.idMagasin == 0)
         this.apiUrl = "SERVER/parametres/magasin/enregistrer";
       else
         this.apiUrl = "SERVER/parametres/magasin/modifier";

         var infoUser = this.AuthService.getInfoUtilisateur();
          this.Libmagasin.user = infoUser.id;
          console.log(this.Libmagasin);

         var d = {"magasin": this.Libmagasin};
         this.transfertDataService.EnvoiData(d, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((value:any) => {
           this.Liste_des_magasin();
           this.search("profile");
           this.Annuler();
         }, (erreur:any) => {
             console.log(erreur.status);
         })

     }

    supprimer(idMagasin:any):void{
       this.apiUrl = "SERVER/parametres/magasin/supprimer";
       var data = {idMagasin:idMagasin};
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
     ).subscribe((value:any) => {
         this.Liste_des_magasin();
       }, (erreur:any) => {
           console.log(erreur.status);
       })

     }


     Liste_des_magasin(): void{

       this.apiUrl = "SERVER/parametres/magasin/listemagasin";
       var data = {};
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
           retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((response:any) => {
         this.listemagasin = response.liste_magasin;
         console.log(this.listemagasin);

       }, (erreur:any) => {
           console.log(erreur.status);
       })

     }


     information(idMagasin:any):void{

           this.apiUrl = "SERVER/parametres/magasin/information";
           var data = {idMagasin:idMagasin};
           this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((response:any) => {
               this.Libmagasin = response.magasin[0];
               this.search("saisir");

           }, (erreur:any) => {
             console.log(erreur.status);
         })

     }

     recherchemagasin(data:any): void{
       if(this.recherche.NomMagasin == "")
       this.Liste_des_magasin();
       else{
         this.apiUrl = "SERVER/parametres/magasin/recherche";
         var data = data;
         this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
           retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((value:any) => {
           this.listemagasin= value.liste_magasin;
         }, (erreur:any) => {
             console.log(erreur.status);
         })
       }

   }


     SupprimerPopup(id:any, NomMagasin:any): void{
       $(".suppdossier").trigger("click");
       this.suppid.id = id;
       this.suppid.NomMagasin = NomMagasin;
     }

     Supprimermagasin(): void{
         this.apiUrl = "SERVER/parametres/magasin/supprimer";
         var data = {idMagasin:this.suppid.id};
         this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
           retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((value:any) => {
           this.suppid = {};
           this.Liste_des_magasin();
         }, (erreur:any) => {
             console.log(erreur.status);
         })
     }

}
