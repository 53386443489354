<nav class="navbar navbar-dark bg-primary">
  <div class="col-md-12">
    <div class="row">
      <div class="Inline">
            <a class="btn dropdown-toogle" type="button" id ="dropdownMenuButton1" data-bs-toogle= "dropdown" aria-expanded = "false" >
              <i class="fas fa-bars" (click) = "toogleMenue()" [ngClass] = "{'fa-bar':showFiller === false}"></i>
            </a>
      </div>
      <div class="InlineRight">
        
        <div class="" style="display: inline-block; text-align:right">
           <div></div>
          <span class="SpanBloc" (click) = "Poppup()">
            <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="color: inherit;text-decoration: none;">
              {{utilisateur.nom}}
            </a>
            <div class="dropdown">
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="left: -50px;position: absolute;top: 30px;">
                <a class="dropdown-item" type="button" data-toggle="modal" data-target=".bs-infouser">Profile</a>
                <a class="dropdown-item" (click) = "deconnexion()">Deconnexion</a>
              </div>
            </div>
           </span>
        </div>

        

        <div class="modal fade bs-infouser" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog" role="document">
              <div class="modal-content">
                    <div class="row container_modal">
                        <div class="modal-header">
                          <h6 class="modal-title" style="font-weight: bold;">INFORMATIONS UTILISATEUR</h6>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>
                          </button>
                        </div>
                        
                        <div class="col-md-12 text-left">
                            <div class="row">
                              <div class="form-group col-xs-12 col-md-4">
                                  <label for="">Nom & Prénoms </label>
                                 <input  [(ngModel)]="userProfile.nom" type="text" placeholder="Nom & Prénom" name="nom" id="nom"  class="form-control form-control-sm">
                              </div>
                              <div class="form-group col-xs-12 col-md-4">
                                <label for="">Login</label>
                                <input  [(ngModel)]="userProfile.login" type="text" placeholder="Login" name="Login" class="form-control form-control-sm" id="Login" disabled="disabled">
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-xs-12 col-md-4">
                                  <label for="">Mot de passe</label>
                                  <input  [(ngModel)]="userProfile.password" type="password" id="pwd" placeholder="Mot de passe avec 8 caractères au minimum" name="pwd" class="form-control form-control-sm">
                               </div>
                                <div class="form-group col-xs-12 col-md-4">
                                 <label for="Recherche_libelle_catégorie">Téléphone</label>
                                 <input  [(ngModel)]="userProfile.tel"  type="text" id="tel" name="tel" placeholder="Téléphone" class="form-control form-control-sm">
                              </div>
                          
                             <div class="form-group col-xs-12 col-md-4">
                                <label for="">Email</label>
                                <input  [(ngModel)]="userProfile.email" type="email" placeholder="Email" name="Email" class="form-control form-control-sm" id="Email">
                             </div>
                         
                           
                         </div>
                        </div>
                        <div class="modal-footer">
                          <button type="button" data-dismiss="modal"  class="btn btn-primary btn-sm" (click)="Enregistrer(userProfile)">Enregistrer</button>
                          <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Annuler</button>
                        </div>
                    </div>
              </div>
          </div>
                                                
        </div>

      </div>
      </div>
    </div>
</nav>

<app-menu (show_Filler) = "Filer($event)" [hidden] = "showFiller == false"></app-menu>
