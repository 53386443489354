<div class="x_panel x_panel_close">
    <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;"><span ><i  class="fa fa-arrow-left"></i></span><div  class="ml-auto text-right"><span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span><i  class="fa fa-chevron-right"></i><span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Stock Petites Fournitures - Créer Fourniture</span>
        </div>
    </div>
    <div class="x_content">
      <div class="col-md-12">
        <div class="content-action">
             <ul class="nav nav-tabs bar_tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" id="saisir-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='saisir'}" (click)="Tabulation('saisir')"
                       role="tab" aria-controls="saisir" aria-selected="true"> Créer Fourniture</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='profile'}" (click)="Tabulation('profile')"
                        role="tab" aria-controls="profile" aria-selected="false">Liste Fournitures</a>
                  </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div [ngClass]="{ 'show':activeTab==='saisir', 'active':activeTab==='saisir'}" class="tab-pane fade active show"
                   id="saisir" role="tabpanel" aria-labelledby="saisir-tab">
                <br>
                  <div class="x_panel x_panel_close">
                    <div class="x_title">
                      <h6>Information Produit</h6>
                      <ul class="nav navbar-right panel_toolbox">
                      </ul>
                      <div class="clearfix"></div>
                    </div>
                    <div class="x_content">
                      <div class="col-md-12" >
                            <div class="row">
                              <div class="col-md-12">
                                <div class="row">
                                  <div class="form-group col-md-6">
                                    <label for="">Référence Produit *</label>
                                    <input  type="text" [(ngModel)]="produit.numProduit"  placeholder="Référence Produit" name="l1"  class="form-control form-control-sm" id="ref">
                                     <div *ngIf = "errfile == -1" style="color: white; Margin-top: 5px;width: 250px; background-color: red;text-align: center;height: 25px;">{{err}}</div>
                                  </div>
                                  <div class="form-group col-md-6">
                                    <label for="">Nom Produit</label>
                                    <input  type="text" [(ngModel)]="produit.NomProd"  placeholder="Nom Produit" name="l12"  class="form-control form-control-sm" id="NomProd">
                                  </div>
                                  <div class="form-group col-md-6">
                                    <label for="">Desciption Produit</label>
                                    <textarea  type="text" [(ngModel)]="produit.description"  placeholder="Desciption" name="date1"  class="form-control form-control-sm"></textarea>
                                  </div>
                                  <div class="form-group col-md-6">
                                    <label for="">Quantité Initial *</label>
                                    <input  type="number" [(ngModel)]="produit.qteInit"  placeholder="" name="obs"  class="form-control form-control-sm" id="qte">
                                  </div>
                                  <div class="form-group col-md-6">
                                    <label for="">Prix Produit</label>
                                    <input  type="number" [(ngModel)]="produit.prix"  placeholder="0" name="obs1"  class="form-control form-control-sm" >
                                  </div>
                                  <div class="form-group col-md-6">
                                    <label for="">Fournisseur</label>
                                    <select  [(ngModel)]="produit.idfournisseur"  name="c1"  class="form-control form-control-sm">
                                        <option value=""></option>
                                        <option *ngFor = "let i of listefournisseur" value="{{i.id}}">{{i.nomfourn}}</option>
                                    </select>
                                  </div>
                                </div>
                             </div>
                          </div>
                      </div>
                    </div>
                  </div>
                  <br>
                <div class="form-group col-md-12 row text-right">
                  <div class="col-md-12">
                    <button type="button" class="btn btn-primary btn-sm btn-enregistrer" (click)="VerifSinistre($event)">Enregistrer</button>&nbsp;&nbsp;&nbsp;&nbsp;
                    <button type="button"  class="btn btn-secondary btn-sm btn-annuler" (click)="Annuler()">&nbsp;Annuler&nbsp;</button>
                  </div>
                </div>
              </div>

            <div class="tab-pane fade" id="profile" role="tabpanel"  aria-labelledby="profile-tab"
                 [ngClass]="{'show':activeTab==='profile', 'active':activeTab==='profile'}">

                   <br>

                  <div class="row recherche_liste">
                      <div class="col-sm-12 col-md-4">
                        <div class="form-group">
                          <label for="">Référence Produit</label>
                          <input type="text" id="" placeholder="Référence Produit" [(ngModel)] = "recherche.numProduit" class="form-control form-control-sm" (ngModelChange) = "Recherche()">
                        </div>
                      </div>
                      <div class="form-group col-md-4">
                          <label for="">Nom Produit</label>
                          <input type="text" id="" placeholder="Nom Produit" [(ngModel)] = "recherche.NomProd" class="form-control form-control-sm" (ngModelChange) = "Recherche()">
                        </div>
                      <div class="form-group col-md-4">
                        <label for="">Fournisseur</label>
                        <select  [(ngModel)]="recherche.idfournisseur"  name="c1"  class="form-control form-control-sm" (ngModelChange) = "Recherche()">
                            <option value=""></option>
                            <option *ngFor = "let i of listefournisseur" value="{{i.id}}">{{i.nomfourn}}</option>
                        </select>
                      </div>
                      <div class="col-sm-12 col-md-4">
                        <div class="form-group">
                          <label for="">Date Debut Création</label>
                          <input type="date" id="" placeholder="jj/mm/aaaa" [(ngModel)] = "recherche.date1" class="form-control form-control-sm" (ngModelChange) = "Recherche()">
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-4">
                        <div class="form-group">
                          <label for="">Date Fin Création</label>
                          <input type="date" id="" placeholder="jj/mm/aaaa" [(ngModel)] = "recherche.date2" class="form-control form-control-sm" (ngModelChange) = "Recherche()">
                        </div>
                      </div>
                    </div>
                    <br>
                    <div class="row table-Table_Longer">
                        <table class="table_bloc_liste">
                          <thead>
                            <th>Date Création</th>
                            <th>Référence Produit</th>
                            <th>Nom Produit</th>
                            <th>Fournisseur</th>
                            <th>Quantite Initiale</th>
                            <th>Prix</th>
                            <th>Créer Par</th>
                            <th>Modifier Par</th>
                            <th>Modifier Le</th>
                            <th></th>
                            <th></th>
                          </thead>
                          <tbody>
                          <tr  *ngFor = "let x of liste">
                              <td>{{x.dateCreation}}</td>
                              <td>{{x.numProduit}}</td>
                              <td>{{x.NomProd}}</td>
                              <td>{{x.nomfourn}}</td>
                              <td>{{x.qteInit}}</td>
                              <td>{{x.prix}}</td>
                              <td>{{x.nom}}</td>
                              <td>{{x.modifierpar}}</td>
                              <td>{{x.datemodification}}</td>
                              <td class="text-center">
                                <button  class="btn btn-success btn-sm" (click)="AfficherDonnee(x.idProduit)">
                                  <i class="fas fa-pencil-alt"></i>
                                </button>
                              </td>
                              <td class="text-center">
                                <button  class="btn btn-danger btn-sm" (click)="SupprimerDossierPopup(x.idProduit)">
                                   <i class="far fa-trash-alt"></i>
                                </button>
                              </td>
                          </tr>
                          </tbody>
                        </table>

                    </div>
                    <br>

                  <div class="text-right mobile-buttons col-sm-12 AccessBtnEnr">
                    <div class="text-right">
                      <br>
                        <button  class="btn btn-success  btn-sm btn-enregistrer" (click) = "ExporterExcel()">Exporter Excel</button>&nbsp;&nbsp;
                        <button  class="btn btn-warning btn-sm btn-annuler" (click) = "ConfirmationImpressionFormulaire()">Imprimer</button>
                    </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
</div>
<button type="button" class="btn btn-primary  btn-sm suppdossier" style="display:none;" data-backdrop="static" data-toggle="modal" data-target=".bs-Form-sm"></button>
<div class="modal fade bs-Form-sm" tabindex="-1" role="dialog" aria-hidden="true">
 <div class="modal-dialog modal-sm" role="document">
  <div class="modal-content">
      <div class="row container_modal">
          <div class="modal-header">
            <h6 class="modal-title" style="font-weight: bold;">SUPPRESSION</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>
            </button>
          </div>
          <div>
             <div class="modal-content-dl">
              <p>Souhaitez-vous le supprimer ?</p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" data-dismiss="modal"  class="btn btn-primary btn-sm" (click) = "supprimer()">Oui</button>
            <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Non</button>
          </div>
      </div>
    </div>
  </div>
</div>
