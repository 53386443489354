import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { AuthService } from '../../../services/connexion/auth.service';
import { CHAUFFEUR } from '../../../classes/chauffeur';

@Component({
  selector: 'app-chauffeur',
  templateUrl: './chauffeur.component.html',
  styleUrls: ['./chauffeur.component.css']
})
export class ChauffeurComponent implements OnInit {
  activeTab:String="saisir";
  apiUrl:String = "";
  listeChauffeurs:any = [];
  recherche:any = {};
  supp_id:any = "";
  erreurSuppression = "";
  chauffeur:CHAUFFEUR = new CHAUFFEUR();

  constructor(private transfertDataService : transfertDataService, private AuthService : AuthService) { }

  ngOnInit(): void {
    this.ListeDesChauffeurs();
  }

  Annuler():void{
    this.chauffeur = new CHAUFFEUR();
    this.recherche.NomChauffeur = "";
  }

  search(activeTab:String){
     this.activeTab=activeTab
   };

  VerificationChampsObligatoire(tableau_champs_obligatoires:any){
        var verification_formulaire = true;
        for (var i = 0; i < tableau_champs_obligatoires.length; i++) {
            if($(tableau_champs_obligatoires[i]).val() == "" || $(tableau_champs_obligatoires[i]).val() == null || $(tableau_champs_obligatoires[i]).val() == "? string: ?"){
                    $(tableau_champs_obligatoires[i]).css("border","1px solid red");
                    verification_formulaire = false;
                    document.getElementById(tableau_champs_obligatoires[i].replace("#",""))?.scrollIntoView({behavior: "smooth"});
                    break;
            }else{
                $(tableau_champs_obligatoires[i]).css("border","");
            }
        }

        return verification_formulaire;
  }

  Enregistrer(data:any): void{
    //Champ obligatoire
    if(this.VerificationChampsObligatoire(["#NomChauffeur1"]) == false){
      return;
    }

    
    var infoUser = this.AuthService.getInfoUtilisateur();

    if(this.chauffeur.idChauffeur == 0){
      this.apiUrl = "SERVER/importparametre/chauffeur/enregistrer";
      this.chauffeur.creerPar = infoUser.id;

      for(let i = 0; i < this.listeChauffeurs.length; i++){
        if(data.NomChauffeur == this.listeChauffeurs[i].NomChauffeur){
          $("#NomChauffeur1").css("border","1px solid red");
            return;
        }
      }
      
    }else{
      this.apiUrl = "SERVER/importparametre/chauffeur/modifier";
      this.chauffeur.modifierPar = infoUser.id;
    }



    var d = {"chauffeur": this.chauffeur};
    this.transfertDataService.EnvoiData(d, this.apiUrl).pipe(
            retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((value:any) => {
            /*CONTRAINTE D'UNICITE*/
            if(value.status == -1){
              $("#NomChauffeur1").css("border","1px solid red");
            }else{
              this.ListeDesChauffeurs();
              this.search("profile");
              this.Annuler();
            } 

         }, (erreur:any) => {
             console.log(erreur.status);
         })

  }

  ListeDesChauffeurs(): void{
    this.apiUrl = "SERVER/importparametre/chauffeur/listechauffeur";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((response:any) => {
         this.listeChauffeurs = response.liste;

       }, (erreur:any) => {
           console.log(erreur.status);
       })

  }

  information(id:any):void{
    this.apiUrl = "SERVER/importparametre/chauffeur/information";
    var data = {id:id};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
      retryWhen(error =>
        error.pipe(
              scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((response:any) => {
               this.chauffeur = response.chauffeur[0];
               this.search("saisir");
           }, (erreur:any) => {
             console.log(erreur.status);
         })

     }

  SupprimerPopup(id:any): void{
    $(".suppliste").trigger("click");
    this.supp_id = id;
  }

  supprimer(id:any):void{
       this.apiUrl = "SERVER/importparametre/chauffeur/supprimer";
       var data = {id:id};
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
     ).subscribe((value:any) => {
        if(value.status == -1){
            this.erreurSuppression = "Impossible de supprimer ce chauffeur.";
            $(".exampleModal").trigger("click");
        }else{
          this.ListeDesChauffeurs();
        } 
       }, (erreur:any) => {
           console.log(erreur.status);
       })

  }


  recherch(data:any): void{
     if(this.recherche.NomChauffeur == "")
     this.ListeDesChauffeurs();
     else{
       this.apiUrl = "SERVER/importparametre/chauffeur/recherche";
       var data = data;
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
     ).subscribe((value:any) => {
         this.listeChauffeurs= value.liste;
       }, (erreur:any) => {
           console.log(erreur.status);
       })
     }

 }




}
