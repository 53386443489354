import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { operationescale } from '../../../classes/operationescale';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { AuthService } from '../../../services/connexion/auth.service';

@Component({
  selector: 'app-operationescale',
  templateUrl: './operationescale.component.html',
  styleUrls: ['./operationescale.component.css']
})
export class OperationescaleComponent implements OnInit {
  activeTab:String="saisir";
  apiUrl:String = "";
  listeoperationescale:any = [];
  recherche:any = {};
  supp_id:any = "";

  Liboperationescale:operationescale = new operationescale();
  constructor(private transfertDataService : transfertDataService,private AuthService : AuthService) { }

  ngOnInit(): void {
    this.Liste_des_operationescale();
  }
  search(activeTab:String){
     this.activeTab=activeTab
   };


     Annuler():void{
        this.Liboperationescale = new operationescale();
        this.recherche.libelle = "";
       }

    enregistrer(data:any): void{

       if(this.Liboperationescale.idoperationEscale == 0)
         this.apiUrl = "SERVER/parametres/operationescale/enregistrer";
       else
         this.apiUrl = "SERVER/parametres/operationescale/modifier";

         var infoUser = this.AuthService.getInfoUtilisateur();
          this.Liboperationescale.idUser = infoUser.id;
         var d = {"operationescale": this.Liboperationescale};
         this.transfertDataService.EnvoiData(d, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((value:any) => {
           this.Liste_des_operationescale();
           this.search("profile");
           this.Annuler();
         }, (erreur:any) => {
             console.log(erreur.status);
         })

     }

    supprimer(id:any):void{
       this.apiUrl = "SERVER/parametres/operationescale/supprimer";
       var data = {id:id};
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
     ).subscribe((value:any) => {
         this.Liste_des_operationescale();
       }, (erreur:any) => {
           console.log(erreur.status);
       })

     }


     Liste_des_operationescale(): void{

       this.apiUrl = "SERVER/parametres/operationescale/listeoperationescale";
       var data = {};
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
           retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((response:any) => {
         this.listeoperationescale = response.liste_operationescale;
       }, (erreur:any) => {
           console.log(erreur.status);
       })

     }

     information(id:any):void{


           this.apiUrl = "SERVER/parametres/operationescale/information";
           var data = {id:id};
           this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((response:any) => {
               this.Liboperationescale = response.operationescale[0];
               this.search("saisir");

           }, (erreur:any) => {
             console.log(erreur.status);
         })

     }

     rechercheoperationescale(data:any): void{
       if(this.recherche.libelle == "")
       this.Liste_des_operationescale();
       else{
         this.apiUrl = "SERVER/parametres/operationescale/recherche";
         var data = data;
         this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
           retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((value:any) => {
           this.listeoperationescale= value.liste_operationescale;
         }, (erreur:any) => {
             console.log(erreur.status);
         })
       }

   }

  SupprimerPopup(id:any): void{
    $(".suppliste").trigger("click");
    this.supp_id = id;
  }

}
