import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { DOSSIER } from '../../../classes/Dossier';
import { position_navire } from '../../../classes/Mouvementnavire';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { ConfigurationService } from '../../../config/configuration.service';
@Component({
  selector: 'app-mouvements-navires',
  templateUrl: './mouvements-navires.component.html',
  styleUrls: ['./mouvements-navires.component.css']
})
export class MouvementsNaviresComponent implements OnInit {
  apiUrl:String = "";
  activeTab:String="saisir";
  navire:any={};
  recherche:any={};
  dossier:any={};
  listdoss:any = [];
  dataSousMenuModel:any = [];
  listeposition:any = [];
  listproduit:any = [];
  Liste_mouvement_navires:any = [];
  listedossierRecherche:any = [];
  idPositionNavire:any;
  lien_fiche_ExpST:any;
  DOSSIERModel:DOSSIER = new DOSSIER();
  position_navireModel:position_navire = new position_navire();
  constructor(private transfertDataService : transfertDataService, private ConfigurationService : ConfigurationService) { }

  ngOnInit(): void {
    this.dataSousMenuModel = [];
    this.ListePosition();
    this.listeMouvementNavire();
    this.recherche.numeroDossier = "";
    this.recherche.Navire = "";
    this.recherche.lieu_position = "";
    this.recherche.date_position = "";
  }


  search(activeTab:String){
     this.activeTab=activeTab;
   };


  Annuler(){
    this.DOSSIERModel = new DOSSIER();
    this.position_navireModel = new position_navire()
    this.dataSousMenuModel = [];
  }

   ImpressionFiche(Id:any, Id1:any){
      this.lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/pdf_MouvementNavire.php?idPositionNavire="+Id1+"&idGestionNavire="+Id;
      $("#printRapport").remove();
      $("body").append('<div id="printRapport">\
                          <div class="col-md-12 form-group" style="display:none;">\
                            <button class="btn btn-primary btn-sm bs-modal-frame-sm-btn" data-backdrop="static" data-toggle="modal" data-target=".bs-modal-frame-sm"></button>\
                          </div>\
                          <div class="modal fade bs-modal-frame-sm" tabindex="-1" role="dialog" aria-hidden="true">\
                            <div class="modal-dialog" role="document">\
                                <div class="modal-content">\
                                    <div class="row container_modal" style="width:100%;">\
                                    <div class="modal-header" style="margin-bottom:0px;">\
                                      <h6 class="modal-title" style="font-weight: bold;">IMPRESSION FICHE</h6>\
                                      <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>\
                                      </button>\
                                    </div>\
                                    <div class="row col-md-12" style="width:100%;height: calc(100vh - 90px);padding-left: 0px; padding-right: 0px; margin-left: 0px; margin-right: 0px;">\
                                      <div class="col-md-12 waiting">\
                                        <div class="snippet" data-title=".dot-pulse">\
                                          <div class="stage">\
                                            <div class="dot-pulse"></div>\
                                          </div>\
                                        </div>\
                                      </div>\
                                      <iframe class="rapportframe" name="rapportframe" style="width:100%;"></iframe>\
                                    </div>\
                                </div>\
                              </div>\
                            </div>\
                          </div>\
                      </div>');
      $(".bs-modal-frame-sm-btn").trigger("click");
      window.open(this.lien_fiche_ExpST , "rapportframe")?.focus();
      $(".rapportframe").css("display", "none");
      $('.rapportframe').on('load', function(){
          $(".waiting").css("display", "none");
          $(".rapportframe").css("display", "initial");
      });
    }


  //Fonction de verification de champs
    VerificationChampsObligatoire(tableau_champs_obligatoires:any){
        var verification_formulaire = true;
        for (var i = 0; i < tableau_champs_obligatoires.length; i++) {
            if($(tableau_champs_obligatoires[i]).val() == "" || $(tableau_champs_obligatoires[i]).val() == null || $(tableau_champs_obligatoires[i]).val() == "? string: ?"){
                    $(tableau_champs_obligatoires[i]).css("border","1px solid red");
                    verification_formulaire = false;
                    document.getElementById(tableau_champs_obligatoires[i].replace("#",""))?.scrollIntoView({behavior: "smooth"});
                    break;
            }else{
                $(tableau_champs_obligatoires[i]).css("border","");
            }
        }

        return verification_formulaire;
    }

  ListeAutoCompleteDossier(numerodossier:any){
   this.apiUrl = "SERVER/gestionnavire/naviremouvementsnavire/recherche_dossier";
      var data = {numeroDossier:numerodossier};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listdoss= value.listdoss;
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }

  AutocompleteRecherche(numerodossier:any){
   this.apiUrl = "SERVER/gestionnavire/naviremouvementsnavire/recherche_dossier";
      var data = {numeroDossier:numerodossier};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listedossierRecherche= value.listdoss;
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }

  SelectionAutocomplete(data:any): void{
    this.DOSSIERModel.numeroDossier = data.numeroDossier;
    this.DOSSIERModel.Navire = data.Navire;
    this.position_navireModel.iddossier = data.idGestionNavire;

  }

  selectionAutocompleteRecherche(data:any): void{
    this.recherche.numeroDossier = data.numeroDossier;
    if(this.recherche.NumDossSaisi == "")
      this.recherche.numeroDossier = "";
    this.Recherche(this.recherche);
  }

  ListePosition(){
   this.apiUrl = "SERVER/gestionnavire/naviremouvementsnavire/recherche_quai";
      var data = {};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listeposition = value.quai;
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }

  Enregistrer(data:any, event:any): void{
    if(this.VerificationChampsObligatoire(["#numdossForm", "#position_navire", "#date_mouvement"]) == false)
              return;
      event.target.disabled = true;
    if(this.position_navireModel.idPositionNavire == 0){
      this.apiUrl = "SERVER/gestionnavire/naviremouvementsnavire/enregistrer";
    }
    else{
      this.apiUrl = "SERVER/gestionnavire/naviremouvementsnavire/modifier";
    }

    var d = {"mvt": this.position_navireModel};
    this.transfertDataService.EnvoiData(d, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
      event.target.disabled = false;
      this.listeMouvementNavire();
      this.Annuler();
      this.search("profile");
    }, (erreur:any) => {
      event.target.disabled = false;
        console.log(erreur.status);
    })
  }


   listeMouvementNavire(): void{
    this.apiUrl = "SERVER/gestionnavire/naviremouvementsnavire/listedossier";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
      this.Liste_mouvement_navires = response.Liste_mouvement_navires;
    }, (erreur:any) => {
        console.log(erreur.status);
    })
  }

  AfficherDonner(id:any, idPositionNavire:any): void{
      this.apiUrl = "SERVER/gestionnavire/naviremouvementsnavire/information";
      var data = {idGestionNavire:id, idPositionNavire:idPositionNavire};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
          this.DOSSIERModel = response.listedossier[0];
          this.position_navireModel.idPositionNavire = response.sousmenu[0].idPositionNavire;
          this.position_navireModel.iddossier = response.sousmenu[0].iddossier;
          this.position_navireModel.date_position = response.sousmenu[0].date_position;
          this.position_navireModel.heure = response.sousmenu[0].heure;
          this.position_navireModel.lieu_position = response.sousmenu[0].lieu_position;
          this.position_navireModel.commentaire = response.sousmenu[0].commentaire;
          this.dataSousMenuModel = response.sousmenu;
          this.idPositionNavire = response.sousmenu.idPositionNavire;
          this.search("saisir");
      }, (erreur:any) => {
        console.log(erreur.status);
    })
  }

  SupprimerDossier(id:any): void{
      this.apiUrl = "SERVER/gestionnavire/naviremouvementsnavire/supprimer";
      var data = {idPositionNavire:id};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listeMouvementNavire();
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }


  Recherche(data:any): void{
      this.apiUrl = "SERVER/gestionnavire/naviremouvementsnavire/recherche";
      var data = data;
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
      if(this.recherche.numeroDossier == "" && this.recherche.Navire == "" && this.recherche.lieu_position == "" && this.recherche.date_position == ""){
        this.listeMouvementNavire();
      }
      this.Liste_mouvement_navires= value.Liste_mouvement_navires;
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }

  reInitSearch(): void{
    this.listedossierRecherche = [];
  }

  reInitSearchBlur(): void{
    setTimeout((val:any) => {
        this.listedossierRecherche = [];
    }, 500);
  }


  reInitSearchForm(): void{
    this.listdoss = [];
  }

  reInitSearchBlurForm(): void{
    setTimeout((val:any) => {
        this.listdoss = [];
    }, 500);
  }






}
