import { Component } from '@angular/core';
import { Router, CanActivate, Event, NavigationStart, NavigationEnd, NavigationError} from '@angular/router';
import { Location } from "@angular/common";
import { JwtHelperService } from "@auth0/angular-jwt";
import { AuthService } from './services/connexion/auth.service';
import { transfertDataService } from './services/transfertData/transfertData.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
   title = 'ModeleApp';
  historique:any = {};
  networkOnline:boolean = true;
  apiUrl:any = "ExpertAutoWeb/SERVER/modules/groupeware/HU/Historique_utilisateur.php";
  constructor(private router: Router,private AuthService : AuthService,private transfertDataService : transfertDataService, private location: Location) {
     this.StatusReseau();
     this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {
            this.historique.url = window.location.href;
            this.changeOfRoutes();
        }

    });
    
  }
  
  changeOfRoutes() : boolean{
    if(this.AuthService.getToken() == null){
      this.router.navigate(['connexion']);
      return false;
    }
    else{
    this.AuthService.getInfoUtilisateur();
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(this.AuthService.getToken());
    const expirationDate = helper.getTokenExpirationDate(this.AuthService.getToken());
    const isExpired = helper.isTokenExpired(this.AuthService.getToken());
    console.log(isExpired);
    if(isExpired){
      this.router.navigate(['connexion']);
        return false;
    }else
      return true;
    }
  }

  StatusReseau():void{
    window.addEventListener('online', () => {this.networkOnline = true;});
    window.addEventListener('offline', () => {this.networkOnline = false;});
  }

  

}
