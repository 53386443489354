import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { ConfigurationService } from '../../config/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class transfertDataService {

  constructor(private http : HttpClient, private ConfigurationService : ConfigurationService) { }

  	EnvoiData(data:any, url:String) : Observable<any>{
	  return this.http.post<any>(this.ConfigurationService.configuration.hostname + url, data, {observe:'body', responseType:'json'});
	}

}
