import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { Utilisateur } from './utilisateur';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { AuthService } from '../../../services/connexion/auth.service';

@Component({
  selector: 'app-creer-utilisateur',
  templateUrl: './creer-utilisateur.component.html',
  styleUrls: ['./creer-utilisateur.component.css']
})
export class CreerUtilisateurComponent implements OnInit {
  apiUrl:String = "";
  recherche:any = {};
  action:any="enregistrer";
  utilisateur_liste:any = [];
  listegroupe:any = [];
  utilisateurfonction_liste:any = [];
  activeTab:String="saisir";
  errorLogin:boolean = false;
  errorIndentifiant:boolean = false;
  errorIndentifiantMessage:String = "";
  errorLoginMessage:String = "";

  userModel:Utilisateur = new Utilisateur();
  constructor(private AuthService : AuthService, private transfertDataService : transfertDataService) { }

  ngOnInit(): void {
    this.liste_des_utilisateurs();
    this.Listefonctioutilisateur();
    this.Listegroupe();
    this.recherche.nom = "";
    this.recherche.login = "";
    this.userModel.autorisation = "1";
  }

  Tabulation(activeTab:String){
    this.activeTab=activeTab;
  }

  Annuler():void{
      this.userModel = new Utilisateur();
      this.userModel.autorisation = "1";
  }

  //Fonction de verification de champs
    VerificationChampsObligatoire(tableau_champs_obligatoires:any){
        var verification_formulaire = true;
        for (var i = 0; i < tableau_champs_obligatoires.length; i++) {
            if($(tableau_champs_obligatoires[i]).val() == "" || $(tableau_champs_obligatoires[i]).val() == null || $(tableau_champs_obligatoires[i]).val() == "? string: ?"){
                    $(tableau_champs_obligatoires[i]).css("border","1px solid red");
                    verification_formulaire = false;
                    document.getElementById(tableau_champs_obligatoires[i].replace("#",""))?.scrollIntoView({behavior: "smooth"});
                    break;
            }else{
                $(tableau_champs_obligatoires[i]).css("border","");
            }
        }
        return verification_formulaire;
    }

  Enregistrer(data:any): void{
    if(this.VerificationChampsObligatoire(["#login", "#pwd1", "#groupe1"]) == false)
      return;

    if(this.userModel.id == 0)
      this.apiUrl = "SERVER/groupeware/utilisateur/enregistrer";
    else
      this.apiUrl = "SERVER/groupeware/utilisateur/modifier";
      var infoUser = this.AuthService.getInfoUtilisateur();
      this.userModel.creerparuser = infoUser.id;
    this.userModel.idgroupe = Number(this.userModel.idgroupe);
    var d = {"utilisateur": this.userModel};
    this.transfertDataService.EnvoiData(d, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
      this.liste_des_utilisateurs();
      this.Annuler();
      this.Tabulation("profile");
    }, (erreur:any) => {
        console.log(erreur.status);
    })
  }

  AfficherDonner(idgroupe:any): void{
      this.apiUrl = "SERVER/groupeware/utilisateur/information";
      var data = {id:idgroupe};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
          this.userModel = response.utilisateur[0];
          if(response.utilisateur[0].active == "1"){
            this.userModel.active = true;
          }else{
            this.userModel.active = false;
          }
          this.userModel = response.utilisateur[0];
          this.Tabulation("saisir");
      }, (erreur:any) => {
        console.log(erreur.status);
    })
  }



   SupprimerUser(idgroupe:any): void{
      this.apiUrl = "SERVER/groupeware/utilisateur/supprimer";
      var data = {id:idgroupe};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.liste_des_utilisateurs();
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }


  liste_des_utilisateurs(): void{
    this.apiUrl = "SERVER/groupeware/utilisateur/liste_utilisateur";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
      this.utilisateur_liste = response.liste_user;
    }, (erreur:any) => {
        console.log(erreur.status);
    })
  }



  Listefonctioutilisateur(): void{
    this.apiUrl = "SERVER/groupeware/utilisateur/utilisateurFonction";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
      this.utilisateurfonction_liste = response.liste_fonction;
    }, (erreur:any) => {
        console.log(erreur.status);
    })
  }

  Listegroupe(): void{
   this.apiUrl = "SERVER/groupeware/utilisateur/utilisateurGroupe";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
      this.listegroupe = response.liste_groupe;
    }, (erreur:any) => {
        console.log(erreur.status);
    })
  }


  Recherche(data:any): void{
    if( this.recherche.nom == "" &&  this.recherche.login == ""){
          this.liste_des_utilisateurs();
    }else{
      this.apiUrl = "SERVER/groupeware/utilisateur/recherche";
      var data = data;
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.utilisateur_liste= value.liste_user;
      }, (erreur:any) => {
          console.log(erreur.status);
      })
    }

  }

}
