import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-liste-depot-conteneur',
  templateUrl: './liste-depot-conteneur.component.html',
  styleUrls: ['./liste-depot-conteneur.component.css']
})
export class ListeDepotConteneurComponent implements OnInit {

  activeTab:String="profile";

  constructor() { }

  ngOnInit(): void {
  }

  search(activeTab:String){
    this.activeTab=activeTab
  }

}
