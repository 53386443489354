import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  @Input()resize : boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  resize_window(size:any) : void{
    this.resize = size;
    console.log(this.resize);
  }
}
