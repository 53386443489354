<div class="x_panel x_panel_close">
    <div class="x_content">
        <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;"><span ><i  class="fa fa-arrow-left"></i></span>
        	<div  class="ml-auto text-right"><span class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span>
        		<i  class="fa fa-chevron-right"></i>
        		<span class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Import Recuperation TC  - Créer Prévision Récupération TC</span>
         	</div>
        </div>
        <br>
        <div class="col-md-12">
            <div class="content-action">
                <ul class="nav nav-tabs bar_tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="saisir-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='saisir'}"
                           (click)="search('saisir')" role="tab" aria-controls="saisir" aria-selected="true">Saisir Prévision Récupération TC</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="profile-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='profile'}"
                          (click)="search('profile')"
                          role="tab" aria-controls="profile" aria-selected="false">Liste Prévision Récupération TC</a>
                    </li>
                </ul>

                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade active show" id="saisir" role="tabpanel" aria-labelledby="saisir-tab"
                         [ngClass]="{'show':activeTab==='saisir', 'active':activeTab==='saisir'}">
                    	<br>
                        <div class="x_title">
                            <h5> Informations Conteneur </h5>
                            <ul class="nav navbar-right panel_toolbox" style="padding-left: 50px;"></ul>
                            <div class="clearfix"></div>
                        </div>
                        <div class="cadreformulaire">
                            <div class="row">
                                <div class="col-md-6 form-group">
                                    <div class=" persoautocomplete">
                                        <label for="">Sélectionner N°Conteneur*</label>
                                        <input [(ngModel)]="modelAutocompleCamion.numeroCamion" type="text" name="numeroCamion" id="numeroCamion" placeholder="N°Conteneur"  
                                                class="form-control form-control-sm" autocomplete="off" (focus)="reInitSearch()" (blur)="reInitSearchBlur()" disabled="disabled">

                                        <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" 
                                                aria-haspopup="true" aria-expanded="false" id=""></button>
                                            <div class="classPerso  dropdown-menu">
                                                <form class="px-0 py-3">
                                                    <div class="col-md-12">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <input type="text" [(ngModel)]="modelAutocompleCamion.numeroCamionSaisi" class="form-control form-control-sm"
                                                                               placeholder="N°Conteneur" name="numeroCamionSaisi" 
                                                                               (ngModelChange)="ListeAutoCompleteCamion(modelAutocompleCamion.numeroCamionSaisi,modelAutocompleCamion.numBL)" autocomplete="off">
                                                                    </div>

                                                                    <div class="col-md-6">
                                                                        <input type="text" [(ngModel)]="modelAutocompleCamion.numBL" class="form-control form-control-sm"
                                                                               placeholder="N°BL" name="numeroBL" 
                                                                               (ngModelChange)="ListeAutoCompleteCamion(modelAutocompleCamion.numeroCamionSaisi,modelAutocompleCamion.numBL)" autocomplete="off">
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                    <br>
                                                                    <table class="table_bloc_liste tableReseach">
                                                                        <thead class="theadfixed">
                                                                            <th>Conteneur</th>
                                                                            <th>Armateur</th>
                                                                            <th>N°BL</th>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr class="SelectTable"  *ngFor="let x of liste_Camions" (click)="SelectionAutocomplete(x)">
                                                                                <td>{{x.numConteneur}}</td>
                                                                                <td>{{x.nomAmateur}}</td>
                                                                                <td>{{x.nomAmateur}}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                    </div>
                                </div>

                                <div class="form-group col-md-6">
                                    <label>
                                        <font style="vertical-align: inherit;">
                                            <font style="vertical-align: inherit;">Taille</font>
                                        </font>
                                    </label>
                                    <input type="text" placeholder="Taille" name="nompro" id="nompro" [(ngModel)]="conteneur.taille" 
                                          class="form-control form-control-sm" disabled>
                                </div>

                                <div class="form-group col-md-6">
                                    <label>
                                        <font style="vertical-align: inherit;">
                                            <font style="vertical-align: inherit;">Type</font>
                                        </font>
                                    </label>
                                    <input type="text" placeholder="Type" name="nompro" id="nompro" [(ngModel)]="conteneur.type" 
                                          class="form-control form-control-sm" disabled>
                                </div>

                                <div class="form-group col-md-6">
                                    <label>
                                        <font style="vertical-align: inherit;">
                                            <font style="vertical-align: inherit;">Nom Client</font>
                                        </font>
                                    </label>
                                    <input type="text" placeholder="Nom Client" name="nompro" [(ngModel)]="modelAutocompleCamion.nomClient" 
                                           class="form-control form-control-sm" disabled="disabled">
                                </div>

                                <div class="form-group col-md-6">
                                    <label>
                                        <font style="vertical-align: inherit;">
                                            <font style="vertical-align: inherit;">N°BL</font>
                                        </font>
                                    </label>
                                    <input type="text" placeholder="N°BL" name="nompro" [(ngModel)]="conteneur.numBL" 
                                           class="form-control form-control-sm" disabled="disabled">
                                </div>

                                <div class="form-group col-md-6">
                                    <label>
                                        <font style="vertical-align: inherit;">
                                            <font style="vertical-align: inherit;">Armateur</font>
                                        </font>
                                    </label>
                                    <input type="text" placeholder="Nom Armateur" name="nompro" [(ngModel)]="conteneur.nomAmateur" 
                                           class="form-control form-control-sm" disabled="disabled">
                                </div>
                                <div class="form-group col-md-6">
                                    <label>
                                        <font style="vertical-align: inherit;">
                                            <font style="vertical-align: inherit;">Lieu Livraison</font>
                                        </font>
                                    </label>
                                    <input type="text" placeholder="Lieu Livraison" name="nompro" [(ngModel)]="conteneur.lieuLivraison" 
                                           class="form-control form-control-sm" disabled="disabled">
                                </div>
                                <div class="form-group col-md-6">
                                    <label>
                                        <font style="vertical-align: inherit;">
                                            <font style="vertical-align: inherit;">Date Livraison</font>
                                        </font>
                                    </label>
                                    <input type="date" placeholder="Date Livraison" name="nompro" [(ngModel)]="conteneur.dateLivraison" 
                                           class="form-control form-control-sm" disabled="disabled">
                                </div>
                                <div class="form-group col-md-6"></div>
                                
                                <div class="m-3 border w-100">
                                     <div class="x_title w-100">
                                        <h5> Informations Conteneur </h5>
                                        <ul class="nav navbar-right panel_toolbox" style="padding-left: 50px;"></ul>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="row p-2">
                                        <div class="form-group col-md-6">
                                        <label>
                                            <font style="vertical-align: inherit;">
                                                <font style="vertical-align: inherit;">Date Requete *</font>
                                            </font>
                                        </label>
                                        <input type="date" placeholder="Date Requette" id="dateRequete" name="dateRequete" [(ngModel)]="ConteneurImportModel.dateRequette" 
                                               class="form-control form-control-sm">
                                </div>
                                <div class="form-group col-md-6">
                                    <label>
                                        <font style="vertical-align: inherit;">
                                            <font style="vertical-align: inherit;">Shift recupération</font>
                                        </font>
                                    </label>
                                    <select [(ngModel)]="ConteneurImportModel.shiftReception" class="form-control form-control-sm" name="shiftReception">
                                        <option value="Jour">Jour</option>
                                        <option value="Nuit">Nuit</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label>
                                        <font style="vertical-align: inherit;">
                                            <font style="vertical-align: inherit;">EIR</font>
                                        </font>
                                    </label>
                                    <input type="text" placeholder="EIR" name="eir" [(ngModel)]="ConteneurImportModel.eir" 
                                           class="form-control form-control-sm">
                                </div>       
                                </div>
                                </div>
                                <br>
                                <div class="text-right-btn col-md-12 my-4">
                                  <button type="button" class="btn btn-primary btn-sm btn-enregistrer" (click)="enregistrer($event)">Enregistrer</button>&nbsp;
                                  <button type="button" class="btn btn-secondary btn-sm btn-annuler" (click)="Annuler()">Annuler</button>&nbsp;
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="profile" role="tabpanel"  aria-labelledby="profile-tab"
                         [ngClass]="{'show':activeTab==='profile', 'active':activeTab==='profile'}">
                         <br>
                        <div class="x_content">
                            <div class=" form-group col-xs-12 col-md-12 recherche_liste">
                                <div class="row">
                                    <div class="form-group col-md-4">
                                       <label for="">Armateur</label>
                                       <select class="form-control form-control-sm" [(ngModel)]="recherche.amateur" (ngModelChange)="recherch(recherche)">
                                        <option *ngFor="let i of amateur" value="{{i.idAmateur}}">{{i.nomAmateur}}</option> 
                                       </select>
                                    </div>
                                   <div class="form-group col-md-4">
                                       <label for="">N°Conteneur</label>
                                       <input type="text" id="numero" placeholder="N°Conteneur" class="form-control form-control-sm" name="numero" 
                                              [(ngModel)]="recherche.numero" (ngModelChange)="recherch(recherche)">
                                   </div>
                                   <div class="form-group col-md-4">
                                       <label for="">N°BL</label>
                                       <input type="text" id="bl" placeholder="N°BL" class="form-control form-control-sm" name="bl" 
                                              [(ngModel)]="recherche.bl" (ngModelChange)="recherch(recherche)">
                                   </div>
                                   <div class="form-group col-md-4">
                                       <label for="">Periode Du</label>
                                       <input type="date" id=""  class="form-control form-control-sm" [(ngModel)]="recherche.date1"
                                              (ngModelChange)="recherch(recherche)">
                                   </div>

                                   <div class="form-group col-md-4">
                                       <label for="">Au</label>
                                       <input type="date" id="" placeholder="jj/mm/aaaa" class="form-control form-control-sm" [(ngModel)]="recherche.date2"
                                              (ngModelChange)="recherch(recherche)">
                                   </div>

                             </div>
                           </div>
                            <table class="table_bloc_liste">
                                <thead>
                                   <tr>
                                     <th>N° Conteneur</th>
                                     <th>Date Requete</th>
                                     <th>Shift Recuperation</th>
                                     <th>Date Creation</th>
                                     <th>Créer Par</th>
                                     <th></th>
                                     <th></th>
                                   </tr>
                                 </thead>
                                 <tbody>
                                   <tr *ngFor="let x of listeConteneurs">
                                     <td>{{x.numConteneur}}</td>
                                     <td>{{x.dateRequette}}</td>
                                     <td>{{x.shiftReception}}</td>
                                     <td>{{x.creerLe}}</td>
                                     <td>{{x.nom}}</td>
                                     <td class="text-center">
                                           <button  class="btn btn-success btn-sm" (click)="information(x.idConteneurImport)">
                                             <i class="fas fa-pencil-alt"></i>
                                           </button>
                                     </td>

                                     <td class="text-center">
                                        <button  class="btn btn-danger btn-sm" (click)="SupprimerPopup(x.idConteneurImport)">
                                        <i class="far fa-trash-alt"></i>
                                        </button>
                                    </td>
                                 </tr>
                                 </tbody>
                            </table>
                        </div>
                        <div class="col-xs-12 col-ms-12 text-right" style="float: right;margin-top: 15px;" *ngIf="liste_pagination.length > 1">
                          <div class="pagination pagination_liste_tableau">
                            <a class="page-link" *ngFor="let n of liste_pagination, let i = index" [ngClass]="{'active': i == activePagination}" (click)="CurrentPagePagination(n,i,$event)">
                              {{i + 1}}
                            </a>
                          </div>
                        </div>

                        <div class="row" style="clear:both">
                            <div  class="col-md-6 form-group"></div>
                            <div  class="col-md-3 form-group">
                                <div  class="form-group">
                                  <label >&nbsp;</label>
                                  <button  class="btn btn-success btn-block btn-sm" (click)="Exporter()">
                                    <i  class="fas fa-excel"></i>&nbsp;Exporter</button>
                                </div>
                            </div>
                            <div  class="col-md-3 form-group">
                                <div  class="form-group">
                                  <label >&nbsp;</label>
                                  <button  class="btn btn-warning btn-block btn-sm" (click)="ConfirmationImpressionFormulaire()"><i  class="fas fa-print"></i>&nbsp;Imprimer</button>
                                </div>
                            </div>
                        </div> 


                </div>    
            </div>
        </div>

    </div>
</div>

<button type="button" class="btn btn-primary  btn-sm suppliste" style="display:none;" data-backdrop="static" data-toggle="modal" data-target=".bs-Form-sm"></button>
<div class="modal fade bs-Form-sm" tabindex="-1" role="dialog" aria-hidden="true">
 <div class="modal-dialog modal-sm" role="document">
  <div class="modal-content">
      <div class="row container_modal">
          <div class="modal-header">
            <h6 class="modal-title" style="font-weight: bold;">SUPPRESSION</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>
            </button>
          </div>
          <div>
             <div class="modal-content-dl">
              <p>Souhaitez-vous supprimer ce enregistrement ?</p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" data-dismiss="modal"  class="btn btn-primary btn-sm" (click)="supprimer(supp_id)">Oui</button>
            <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Non</button>
          </div>
      </div>
    </div>
  </div>
</div>
