<div class="x_panel x_panel_close">
        <div class="x_content">
               <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;"><span ><i  class="fa fa-arrow-left"></i></span>
                   <div  class="ml-auto text-right"><span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span><i  class="fa fa-chevron-right"></i><span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Gestion navires - Créer dossier navire</span>
                   </div>
               </div>
                <ul class="nav nav-tabs bar_tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" id="saisir-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='saisir'}" (click)="search('saisir')"
                       role="tab" aria-controls="saisir" aria-selected="true">Créer Dossier Navire</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='profile'}" (click)="search('profile')"
                        role="tab" aria-controls="profile" aria-selected="false">Liste Dossiers Navires</a>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div [ngClass]="{ 'show':activeTab==='saisir', 'active':activeTab==='saisir'}" class="tab-pane fade active show" id="saisir" role="tabpanel" aria-labelledby="saisir-tab">
                      <br>
                    <div class="x_panel_title x_panel_close">
                        <div class="x_title">
                          <h5>INFORMATIONS DOSSIER</h5>
                          <ul class="nav navbar-right panel_toolbox" style="padding-left: 50px;">
                          </ul>
                          <div class="clearfix"></div>
                        </div>
                        <div class="x_content">
                          <div class="row">
                            <div class="form-group col-xs-12 col-md-4">
                              <label for="numero_dossier">N°Dossier</label>
                              <input type="text" class="form-control form-control-sm" id="numero_dossier" ng-disabled="true" placeholder="N°Dossier" [(ngModel)]="DOSSIERModel.numeroDossier" disabled="disabled">
                            </div>
                            <div class="form-group col-xs-12 col-md-4">
                              <label for="numero_dossier">Date Ouverture</label>
                              <input type="date" class="form-control form-control-sm" id="datOuvert_dossier" [(ngModel)]="DOSSIERModel.datOuvert">
                            </div>
                          </div>
                        </div>
                        <div class="x_panel_title x_panel_close" style="width: 99%;margin: 0px auto;display: table;">
                          <div class="x_title">
                            <h5>INFORMATIONS NAVIRE</h5>
                            <ul class="nav navbar-right panel_toolbox" style="padding-left: 50px;">
                            </ul>
                            <div class="clearfix"></div>
                          </div>
                          <div class="x_content">
                            <div class="row">
                              <div class="col-md-4 form-group">
                                <div class="persoautocomplete">
                                <label for="">Selectionner navire*</label>
                               <input [(ngModel)]="DOSSIERModel.Navire" type="text" name="NavireDossier" id="NavireDossier" placeholder="Navire"  class="form-control form-control-sm" autocomplete="off" (focus)="reInitSearch()" (blur)="reInitSearchBlur()" disabled="disabled">
                                 <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="">
                                 </button>
                                  <div class="classPerso  dropdown-menu">
                                    <form class="px-0 py-3">
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-12">
                                                  <input type="text" [(ngModel)]="dossier.Navire" class="form-control form-control-sm" placeholder="Navire" name="navireSaisiGestNav" (ngModelChange)="ListeAutoCompleteDossier(dossier.Navire)" autocomplete="off">
                                                  <div>
                                                    <br>
                                                      <table class="table_bloc_liste tableReseach">
                                                          <thead class="theadfixed">
                                                              <th>Navire</th>
                                                          </thead>
                                                          <tbody>
                                                              <tr class="SelectTable"  *ngFor="let x of listnavire" (click)="SelectionAutocomplete(x)">
                                                                  <td>{{x.Navire}}</td>
                                                              </tr>
                                                          </tbody>
                                                      </table>
                                                  </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group col-xs-12 col-md-4">
                                <label for="Compagnie">Compagnie</label>
                                <input type="text" class="form-control form-control-sm" id="marque" placeholder="Compagnie" [(ngModel)]="DOSSIERModel.NomCompagnie" ng-disabled="true" disabled="disabled">
                              </div>
                              <div class="form-group col-xs-12 col-md-4">
                                <label for="agentnavire">Agent navire*</label>
                                <select class="form-control form-control-sm input-float-left" [(ngModel)]="DOSSIERModel.agent_navire" id="agentnavire">
                                  <option *ngFor="let x of liste_gents_navires" value="{{x.id}}">{{x.nom}}</option>
                                </select>
                                <button class="btn btn-primary btn-sm btn-float-right" style="float: right;" data-backdrop="static" data-toggle="modal" data-target=".bs-Form-sm-agentnavire">
                                  <i class="fas fa-plus"></i>
                                </button>
                              </div>
                              <div class="form-group col-xs-12 col-md-4">
                                <label for="date_arrive_prevu">Date d'arrivée prévu (ETA)*</label>
                                <input type="date" class="form-control form-control-sm hasDatepicker" id="date_arrive_prevu" placeholder="Date d'arrivée prévu" [(ngModel)]="DOSSIERModel.date_arrivee_prevu">

                              </div>
                              <div class="form-group col-xs-12 col-md-4">
                                <label for="capitaine">Capitaine</label>
                                <input type="text" class="form-control form-control-sm" id="capitaine" placeholder="Capitaine" [(ngModel)]="DOSSIERModel.capitaine">
                              </div>


                              <div class="form-group col-xs-12 col-md-4">
                                <label for="provenance">Provenance</label>
                                <select class="form-control form-control-sm input-float-left" [(ngModel)]="DOSSIERModel.provenance" id="provenance">
                                  <option *ngFor="let x of liste_des_provenances" value="{{x.idProvenance}}">{{x.libelle}}</option>
                                </select>
                                <button class="btn btn-primary btn-sm btn-float-right" style="float: right;" data-backdrop="static" data-toggle="modal" data-target=".bs-Form-sm-provenance">
                                  <i class="fas fa-plus"></i>
                                </button>
                              </div>

                              <div class="form-group col-xs-12 col-md-4">
                                <label for="num_voyage">Numéro voyage</label>
                                <input type="text" class="form-control form-control-sm" id="num_voyage" placeholder="Numéro voyage" [(ngModel)]="DOSSIERModel.numero_voyage">
                              </div>

                              </div>
                          </div>
                        </div>
                     <br>
                     <div class="x_panel_title x_panel_close" style="width: 99%;margin: 0px auto;display: table;">
                        <div class="x_title">
                          <h5>CONTENUS NAVIRE</h5>
                          <ul class="nav navbar-right panel_toolbox" style="padding-left: 50px;">
                          </ul>
                          <div class="clearfix"></div>
                        </div>
                        <div class="x_content">
                          <div class="row">
                                  <div class="col-md-12">
                                  <div class="">
                                      <p>
                                        <button class="btn btn-sm btn-primary" (click)="ajouter()">
                                          <i class="fas fa-plus"></i>&nbsp;Nouveau
                                        </button>&nbsp;&nbsp;
                                        <button class="btn btn-sm btn-secondary" (click)="SuivantPrecedent('prev')" *ngIf="DOSSIERModel.idGestionNavire != 0">
                                          <i class="fas fa-chevron-left"></i>
                                          &nbsp;&nbsp;&nbsp;Précédent&nbsp;
                                        </button>
                                        &nbsp;&nbsp;
                                        <button class="btn btn-sm btn-secondary" (click)="SuivantPrecedent('next')" *ngIf="DOSSIERModel.idGestionNavire != 0">
                                          Suivant&nbsp;&nbsp;&nbsp;
                                          <i class="fas fa-chevron-right" style="color:#fff;"></i>
                                        </button>
                                      </p>
                                      <table class="table_mainoeuvre_fourniture table_bloc_liste">
                                            <thead>
                                              <tr >

                                                  <th>Port Chargement</th>
                                                  <th>Bl</th>
                                                  <th>Designation Produit</th>
                                                  <th>Client</th>
                                                  <th>Poids (Tonne)</th>
                                                  <th>Quantité</th>
                                                  <th>Cale</th>
                                                  <th>Lieu Déchargement</th>
                                                  <th>Action</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr *ngFor="let x of dataSousMenuModel;let i = index;" (click)="selectionligne(i, x.ordre)" [ngClass]="i == ligneSelectionne ? 'choix' : ''">

                                                <td>
                                                  <select class="form-control form-control-sm" [(ngModel)]="x.PortChargement" (ngModelChange)="UpdateContenuNavire(x)">
                                                    <option value="">Choisir un Port</option>
                                                    <option *ngFor="let x of listeports" value="{{x.libelle}}">{{x.libelle}}</option>
                                                  </select>
                                                </td>
                                                <td>

                                                  <input type="text" class="form-control form-control-sm" [(ngModel)]="x.reference" (ngModelChange)="UpdateContenuNavire(x)" style="width: 120px;">
                                                </td>
                                                <td>
                                                  <div class="col-md-12 persoautocomplete" style="width: 230px;">
                                                   <input [(ngModel)]="x.designationProduit" type="text" name="designProduitCli{{i}}" id="designProduitCli{{i}}" placeholder="Désignation Produit"  class="form-control form-control-sm" autocomplete="off" (focus)="reInitSearch()" (blur)="reInitSearchBlur()" disabled="disabled">
                                                     <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="" style="position: absolute;top: 0;">
                                                     </button>
                                                      <div class="classPerso  dropdown-menu">
                                                        <form class="px-0 py-3">
                                                            <div class="col-md-12">
                                                                <div class="row">
                                                                    <div class="col-md-12">
                                                                      <input type="text" [(ngModel)]="x.designationProduit1" class="form-control form-control-sm" placeholder="Désignation Produit" name="ProduitCli{{i}}" (ngModelChange)="ListeAutoCompleteProd(x.designationProduit1)" style="padding-right: 0px;" autocomplete="off">
                                                                      <div>
                                                                        <br>
                                                                          <table class="table_bloc_liste tableReseach">
                                                                              <thead class="theadfixed">
                                                                                  <th>Désignation Produit</th>
                                                                                  <th>Client</th>
                                                                              </thead>
                                                                              <tbody>
                                                                                  <tr class="SelectTable"  *ngFor="let t of listproduit" (click)="SelectionAutocompleteProd(t, x)">
                                                                                      <td>{{t.NomSpecificProduit}}</td>
                                                                                      <td>{{t.nom_societe}}</td>
                                                                                  </tr>
                                                                              </tbody>
                                                                          </table>
                                                                      </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                      </div>
                                                  </div>

                                                </td>
                                                 <td>

                                                  <input type="text" class="form-control form-control-sm" [(ngModel)]="x.nom_societe" disabled="disabled">
                                                </td>
                                                <td>

                                                  <input type="number" class="form-control form-control-sm text-center" [(ngModel)]="x.PoidsNet" (ngModelChange)="UpdateContenuNavire(x)" style="width: 100px;">
                                                </td>
                                               <td>

                                                  <input type="number" class="form-control form-control-sm text-center" [(ngModel)]="x.qte" (ngModelChange)="UpdateContenuNavire(x)" style="width: 100px;">
                                                </td>
                                                <td>
                                                  <select class="form-control form-control-sm" [(ngModel)]="x.cale" (ngModelChange)="UpdateContenuNavire(x)">
                                                    <option value="">Choisir un Cale</option>
                                                    <option *ngFor="let x of listecales" value="{{x.id}}">{{x.libelle}}</option>
                                                  </select>
                                                </td>
                                                <td>
                                                  <select class="form-control form-control-sm" [(ngModel)]="x.lieuDechargement" (ngModelChange)="UpdateContenuNavire(x)">

                                                    <option value="Port">Port</option>
                                                    <option value="Hort Port">Hort Port</option>
                                                    <option value="Magasin Client">Magasin Client</option>
                                                  </select>
                                                </td>
                                                <td class="text-center">
                                                  <button class="btn btn-danger btn-sm" type="button" (click)="supprimer_sous_menu(x.idContenuNavire, i, x.idProduit)" title="supprimer">
                                                    <i class="fa fa-trash-alt" ></i>
                                                  </button>
                                                </td>
                                                </tr>
                                                <tr *ngIf="dataSousMenuModel.length > 0">
                                                  <td></td>
                                                  <td></td>
                                                  <td></td>
                                                  <td style="text-align: center;font-size: 14px;font-weight: 500;">TOTAL</td>
                                                  <td style="text-align: center;font-size: 14px;font-weight: 500;">{{totalPoidsDossier}}</td>
                                                  <td style="text-align: center;font-size: 14px;font-weight: 500;">{{totalQteDossier}}</td>
                                                  <td></td>
                                                </tr>
                                            </tbody>
                                      </table>
                                    </div>
                              </div>
                            </div>
                        </div>
                     </div>
                     <br>
                     <div class="col-md-12 text-center">
                        <div class="card-body">
                               <span>Télécharger des Documents</span>
                               <br><br>
                               <div class="col-md-12">
                                <div class="download-card" style="text-align: left!important;">
                                  <div class="col-md-12">
                                    <div class="row">
                                      <div class="inline"><br><br><img style ="width: 35px;" src="./assets/img/cloud.png"></div>
                                      <div class="inline1"><br><br><p><span>&nbsp;Cliquez sur le bouton pour ajouter des fichers</span><br>&nbsp; <button type="button" class="btn btn-primary btn-sm btn-sm-perso" (click)="fileInputBlock.click()">PARCOURIR LES FICHIERS </button></p></div>
                                    </div>
                                  </div>
                                </div>
                               </div>
                               <div class="row blockphotosZone" *ngIf="photos_previsualisation.length > 0">
                                <br>
                                <div class="col-md-3 photosZone" *ngFor="let x of photos_previsualisation" [ngClass]=" x.iddocument == '' ? 'uploadfile' : ''">
                                    <span>
                                      <span *ngIf="x.iddocument == ''" class="fichierToUpload">FICHIER NON ENREGISTRE</span>
                                    </span>
                                    <img [src]="x.src" id="file_{{x.iddocument}}">
                                    <div class="text-center text-center pt-2 pb-2 action-band">
                                      <button class="btn btn-sm btn-secondary" (click)="apercu(x.iddocument)">
                                            <i class="fas fa-search"></i>
                                        </button>&nbsp;&nbsp;
                                        <button class="btn btn-sm btn-danger" (click)="supprimer_image(x.lien, x.iddocument)">
                                            <i class="fas fa-trash"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            </div>

                            <input type="file" name="file" id="file" [hidden]="true" (change)="onFileChanged($event)" multiple="multiple" #fileInputBlock>
                        </div>
                        <br>
                      <div class="col-sm-12 text-right mobile-buttons">
                          <button class="btn btn-primary btn-enregistrer" (click) = "Enregistrer(DOSSIERModel, $event)">
                              <font style="vertical-align: inherit;">
                                  <font style="vertical-align: inherit;">Enregistrer</font>
                              </font>
                          </button>
                          &nbsp;
                          <button class="btn btn-secondary btn-enregistrer" (click) = "Annuler()">
                              <font style="vertical-align: inherit;">
                                  <font style="vertical-align: inherit;">Annuler</font>
                              </font>
                          </button>

                      </div>

                    </div>


                     <br>


                 </div>
                    <div class="tab-pane fade" id="profile" role="tabpanel"  aria-labelledby="profile-tab" [ngClass]="{ 'show':activeTab==='profile', 'active':activeTab==='profile'}">
                        <br>
                        <div class="">
                  <div class="x_content">
                      <div class="form-group col-xs-12 col-md-12 recherche_liste">
                        <div class="row">
                            <div class="form-group col-md-3">
                                            <label for="Recherche_numero_dossier">N°Dossier</label>
                                            <input type="text" id="Recherche_numero_dossier" [(ngModel)]="recherche.numeroDossier" class="form-control form-control-sm" placeholder="N°Dossier" (ngModelChange)="RechercheDossier(recherche)" >
                                    </div>

                                     <div class="form-group col-md-3">
                                            <label for="Recherche_navire">Navire</label>
                                            <input type="text" id="Recherche_navire" [(ngModel)]="recherche.navire" class="form-control form-control-sm" placeholder="Navire" (ngModelChange)="RechercheDossier(recherche)" >
                                    </div>

                                    <div class="form-group col-md-3">
                                            <label for="Recherche_date_saisie">Date saisie</label>
                                            <input type="date" id="Recherche_date_saisie" [(ngModel)]="recherche.date_saisie" class="form-control form-control-sm" placeholder="Date saisie" (ngModelChange)="RechercheDossier(recherche)" >
                                    </div>

                                    <div class="form-group col-md-3">
                                            <label for="Recherche_date_arrive_prevu">Date arrivée prévue (ETA)</label>
                                            <input type="date" id="Recherche_date_arrive_prevu" [(ngModel)]="recherche.date_arrive_prevu" class="form-control form-control-sm" placeholder="Date arrivée prévu" (ngModelChange)="RechercheDossier(recherche)" >
                                    </div>

                        </div>

                        </div>
                            <table class="table_bloc_liste">
                                      <thead>
                                          <tr>
                                            <th>#</th>
                                            <th>
                                              Date saisie
                                            </th>
                                            <th>
                                              Date ouverture
                                            </th>
                                            <th>
                                              N°Dossier
                                            </th>
                                            <th>
                                              Navire
                                            </th>
                                            <th>
                                              Capitaine
                                            </th>
                                            <th>
                                              Compagnie
                                            </th>
                                            <th>
                                              Date arrivée prévue (ETA)
                                            </th>
                                            <th>
                                              Poids prévu
                                            </th>
                                            <th>
                                              Qte prévue
                                            </th>
                                            <th>
                                              Crée par
                                            </th>
                                            <th>
                                              Modifier Par
                                            </th>
                                            <th>
                                              Modifier Le
                                            </th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                          </tr>

                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let n of listedossiernavire">
                                          <td>{{n.idGestionNavire}}</td>
                                          <td>{{n.date}}</td>
                                          <td>{{n.datOuvert}}</td>
                                          <td>{{n.numeroDossier}}</td>
                                          <td>{{n.Navire}}</td>
                                          <td>{{n.capitaine}}</td>
                                          <td>{{n.NomCompagnie}}</td>
                                          <td>{{n.date_arrivee_prevu}}</td>
                                          <td>{{n.total_poids}}</td>
                                          <td>{{n.total_qte}}</td>
                                          <td>{{n.utilisateur}}</td>
                                          <td>{{n.modifierpar}}</td>
                                          <td>{{n.datemodification}}</td>
                                          <td class="text-center">
                                            <button class="btn btn-success btn-sm" (click)="AfficherDonner(n.idGestionNavire)">
                                                <i class="fas fa-pencil-alt"></i>
                                            </button>
                                          </td>
                                          <td class="text-center">
                                              <button  (click)="SupprimerDossierPopup(n.idGestionNavire, n.numeroDossier)" class="btn btn-danger btn-sm">
                                              <i class="far fa-trash-alt"></i>
                                              </button>
                                          </td>
                                          <td class="text-center">
                                              <button class="btn btn-primary btn-sm" (click)="ImpressionFiche(n.idGestionNavire)">
                                                <i class="far fa-file-pdf"></i>
                                              </button>
                                          </td>
                                          <td class="text-center">
                                              <button class="btn btn-warning btn-sm" (click)="downloadDoc(n.idGestionNavire)" *ngIf="n.doc == 1">
                                                <i class="fa fa-download"></i>
                                              </button>
                                          </td>
                                        </tr>
                                  </tbody>
                            </table>

                  </div>
                </div>
                        </div>
                   </div>
                </div>
            </div>
<button type="button" class="btn btn-primary  btn-sm suppdossier" style="display:none;" data-backdrop="static" data-toggle="modal" data-target=".bs-Form-sm"></button>
<div class="modal fade bs-Form-sm" tabindex="-1" role="dialog" aria-hidden="true">
 <div class="modal-dialog modal-sm" role="document">
  <div class="modal-content">
      <div class="row container_modal">
          <div class="modal-header">
            <h6 class="modal-title" style="font-weight: bold;">SUPPRESSION</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>
            </button>
          </div>
          <div>
             <div class="modal-content-dl">
              <p>Souhaitez-vous supprimer le dossier <b>{{suppiddossier.NumDoss}}</b> ?</p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" data-dismiss="modal"  class="btn btn-primary btn-sm" (click) = "SupprimerDossier()">Oui</button>
            <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Non</button>
          </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade bs-Form-sm-agentnavire" tabindex="-1" role="dialog" aria-hidden="true">
 <div class="modal-dialog modal-sm" role="document">
  <div class="modal-content">
      <div class="row container_modal">
          <div class="modal-header">
            <h6 class="modal-title" style="font-weight: bold;">NOUVEAU AGENT NAVIRE</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="form-group col-xs-12 col-md-12">
            <label for="num_voyage">Nom Agent Navire</label>
            <input type="text" class="form-control form-control-sm" id="new_agentnavire" [(ngModel)]="agentnavire.nom" placeholder="Nom Agent Navire">
          </div>
          <div class="modal-footer">
            <button type="button" data-dismiss="modal"  class="btn btn-primary btn-sm" (click)="nouvelle_agent_navire()">Enregistrer</button>
            <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Annuler</button>
          </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade bs-Form-sm-provenance" tabindex="-1" role="dialog" aria-hidden="true">
 <div class="modal-dialog modal-sm" role="document">
  <div class="modal-content">
      <div class="row container_modal">
          <div class="modal-header">
            <h6 class="modal-title" style="font-weight: bold;">NOUVELLE PROVENANCE</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="form-group col-xs-12 col-md-12">
            <label for="new_Provenance">Provenance</label>
            <input type="text" class="form-control form-control-sm" id="new_Provenance" placeholder="Provenance" [(ngModel)]="Libprovenance.libelle">
          </div>
          <div class="modal-footer">
            <button type="button" data-dismiss="modal"  class="btn btn-primary btn-sm" (click)="nouvelle_provenance()">Enregistrer</button>
            <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Annuler</button>
          </div>
      </div>
    </div>
  </div>
</div>
