<div class="x_panel x_panel_close">
     <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;"><span ><i  class="fa fa-arrow-left"></i></span><div  class="ml-auto text-right"><span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span><i  class="fa fa-chevron-right"></i><span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Groupes / Utilisateurs</span>
        </div>
    </div>
    <div class="x_content">
        <div class="row">
            <div class=" col-xs-12 order-12 order-sm-12 col-md-6 order-md-1 Border-right" style="">
                <div class="x_title">
                  <h6>Groupes</h6>
                  <ul class="nav navbar-right panel_toolbox">
                  </ul>
                  <div class="clearfix"></div>
                </div>
                <ul class="submenu-ul">
                  <li class="li-style" *ngFor = "let x of liste_groupes_utilisateurs">
                    <a class="submenu-ul-active">
                      <i class="fas fa-home"></i>&nbsp;&nbsp;&nbsp;
                      <span class="groupes_users">{{x.libelle}}</span>
                    </a>
                    <ul *ngIf="x.users.length > 0" class="inliste">
                      <li *ngFor = "let i of x.users">
                        <a class="username">
                          <i class="fas fa-user"></i>&nbsp;&nbsp;
                          <span >{{i.nom}}</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div class="col-xs-12 order-1  order-sm-1 col-md-6 order-md-12">
                <div class="x_title">
                  <h6>Attribuer Groupe</h6>
                  <ul class="nav navbar-right panel_toolbox">
                  </ul>
                  <div class="clearfix"></div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Utilisateur</label>
                    <select id="utilisateurGroupe" name="utilisateurGroupe" class="form-control form-control-sm" [(ngModel)]="usersModel.id" (ngModelChange)="Selectuser(usersModel.id)">
                      <option *ngFor = "let x of liste_utilisateurs" value="{{x.id}}">{{x.nom}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Groupe</label>
                    <select id="GroupeListe" name="GroupeListe" class="form-control form-control-sm" [(ngModel)]="groupeModel.idgroupe">
                      <option *ngFor = "let x of liste_groupes" value="{{x.idgroupe}}">{{x.libelle}}</option>
                    </select>
                  </div>
                </div>
                 <div class="col-sm-12">
                     <div class="text-right-btn">
                        <button type="button" class="btn btn-primary  btn-sm btn-enregistrer" (click)="Enregistrer()">Enregistrer</button>&nbsp;
                        <button type="button" class="btn btn-secondary btn-sm btn-annuler" (click)="Annuler()">Annuler</button>&nbsp;
                     </div>
                     <br>
                </div>

              </div>
        </div>
    </div>

</div>
