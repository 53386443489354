import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gestion-compte-escale-navire',
  templateUrl: './gestion-compte-escale-navire.component.html',
  styleUrls: ['./gestion-compte-escale-navire.component.css']
})
export class GestionCompteEscaleNavireComponent implements OnInit {

  activeTab:String="saisir";
  constructor() { }

  ngOnInit(): void {
  }

  search(activeTab:String){
     this.activeTab=activeTab;
   };

}
