import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { pipe } from 'rxjs';
import { HttpClient, HttpHeaders, HttpEventType
 } from '@angular/common/http';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { AuthService } from '../../../services/connexion/auth.service';
import { CONTENEURIMPORT } from '../../../classes/conteneurimport';
import { ConfigurationService } from '../../../config/configuration.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-liste-conteneur-livrer',
  templateUrl: './liste-conteneur-livrer.component.html',
  styleUrls: ['./liste-conteneur-livrer.component.css']
})
export class ListeConteneurLivrerComponent implements OnInit {

  activeTab:String="saisir";
  activePagination:number = 0;
  liste_pagination:any = [];
  amateur:any = [];
  apiUrl:String = "";
  listeConteneurs:any = [];
  recherche:any = {};
  clientAutocoplet:any = {};
  AmateurAutocoplet:any = {};
  Liste_etat_stock:any=[];
  Liste_etat_amateur:any=[];
  Transitaireliste:any=[];
  livraison:any=[];
  liste_ClientsRech:any=[];
  liste_AmateurRech:any=[];
  photos_previsualisation:any = [];
  array_files:any = [];
  supp_id:any = "";

  CONTENEURIMPORT:CONTENEURIMPORT = new CONTENEURIMPORT();

  constructor(private HttpClient : HttpClient,private transfertDataService : transfertDataService, private sanitizer:DomSanitizer, private AuthService : AuthService,private ConfigurationService : ConfigurationService) { }

  ngOnInit(): void {
        var date = new Date();
    this.ListeDesConteneurs();
    this.listeAmateur();
    this.recherche.numConteneur = "";
    this.recherche.dateFin = date.getFullYear()+"-"+("0" + (date.getMonth() + 1)).slice(-2)+"-"+this.DernierJourMoisenCours(date.getFullYear(),date.getMonth());
    this.recherche.dateDebut = date.getFullYear()+"-"+("0" + (date.getMonth() + 1)).slice(-2)+"-01";

    this.recherche.amateur = "";
    this.recherche.numBL = "";
    this.recherche.delais = "";

  }
  DernierJourMoisenCours(y:any,m:any){
    return  new Date(y, m +1, 0).getDate();
  }
  downloadDoc(iddossier:any){
    var apiUrl = "SERVER/importlivraisonconteneur/listeconteneur/zip";
      var data = {iddossier:iddossier};
      this.transfertDataService.EnvoiData(data, apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
      ).subscribe((value:any) => {
         window.open(this.ConfigurationService.configuration.hostname + value.link , "_self")?.focus();
       }, (erreur:any) => {
      })
  }
  listeAmateur(){
    this.apiUrl = "SERVER/importretourconteneur/retour/nomAmateur";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((response:any) => {
         this.amateur = response.liste;

       }, (erreur:any) => {
           console.log(erreur.status);
       })

  }
  Exporter(): void{
      var lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/ImportLivraisonConteneur/excel_ListeConteneur.php?numConteneur="+this.recherche.numConteneur+"&amateur="+this.recherche.amateur+"&numBL="+this.recherche.numBL+"&dateDebut="+this.recherche.dateDebut+"&dateFin="+this.recherche.dateFin+"&delais="+this.recherche.delais;
        window.open(lien_fiche_ExpST , '_blank')?.focus();
  }


  ConfirmationImpressionFormulaire(){
         var lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/ImportLivraisonConteneur/pdfListeConteneur.php?numConteneur="+this.recherche.numConteneur+"&amateur="+this.recherche.amateur+"&numBL="+this.recherche.numBL+"&dateDebut="+this.recherche.dateDebut+"&dateFin="+this.recherche.dateFin+"&delais="+this.recherche.delais;
       $("#printRapport").remove();
         $("body").append('<div id="printRapport">\
                             <div class="col-md-12 form-group" style="display:none;">\
                               <button class="btn btn-primary btn-sm bs-modal-frame-sm-btn" data-backdrop="static" data-toggle="modal" data-target=".bs-modal-frame-sm"></button>\
                             </div>\
                             <div class="modal fade bs-modal-frame-sm" tabindex="-1" role="dialog" aria-hidden="true">\
                               <div class="modal-dialog" role="document">\
                                   <div class="modal-content">\
                                       <div class="row container_modal" style="width:100%;">\
                                       <div class="modal-header" style="margin-bottom:0px;">\
                                         <h6 class="modal-title" style="font-weight: bold;">LISTE CONTENEUR DE LIVRAISON</h6>\
                                         <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>\
                                         </button>\
                                       </div>\
                                       <div class="row col-md-12" style="width:100%;height: calc(100vh - 90px);padding-left: 0px; padding-right: 0px; margin-left: 0px; margin-right: 0px;">\
                                         <div class="col-md-12 waiting">\
                                           <div class="snippet" data-title=".dot-pulse">\
                                             <div class="stage">\
                                               <div class="dot-pulse"></div>\
                                             </div>\
                                           </div>\
                                         </div>\
                                         <iframe class="rapportframe" name="rapportframe" style="width:100%;"></iframe>\
                                       </div>\
                                   </div>\
                                 </div>\
                               </div>\
                             </div>\
                         </div>');
         $(".bs-modal-frame-sm-btn").trigger("click");
         window.open(lien_fiche_ExpST , "rapportframe")?.focus();
         $(".rapportframe").css("display", "none");
         $('.rapportframe').on('load', function(){
             $(".waiting").css("display", "none");
             $(".rapportframe").css("display", "initial");
         });
  }
  ListeDesConteneurs(): void{
    this.apiUrl = "SERVER/importlivraisonconteneur/listeconteneur/liste";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((response:any) => {
         this.listeConteneurs = response.liste;
this.Calcule();
       }, (erreur:any) => {
           console.log(erreur.status);
       })

  }

  recherch(data:any): void{
     if(this.recherche.numConteneur == "" && this.recherche.dateDebut == "" && this.recherche.dateFin == "" && this.recherche.amateur == "" && this.recherche.numBL== "" && this.recherche.delais== "")
     this.ListeDesConteneurs();
     else{
       this.apiUrl = "SERVER/importlivraisonconteneur/listeconteneur/recherche";
       var data = data;
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
     ).subscribe((value:any) => {
         this.listeConteneurs= value.data;
         this.Calcule();
       }, (erreur:any) => {
           console.log(erreur.status);
       })
     }

 }
 PaginationNb(index:number) : void{
 var data = {"index":index,  "data": this.recherche};
 this.apiUrl = "SERVER/importlivraisonconteneur/receptionconteneur/PaginationNb";
   this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
      retryWhen(error =>
        error.pipe(
          scan((acc:any, error:any) => {
            if (acc > 2) throw error;
            return acc + 1;
          }, 1),
          delay(1000)
        )
      )
   ).subscribe((value:any) => {
      this.listeConteneurs = value.data;

    }, (erreur:any) => {
   })

}

Calcule() : void{
 this.apiUrl = "SERVER/importlivraisonconteneur/receptionconteneur/CalculePagination";
   this.transfertDataService.EnvoiData(this.recherche, this.apiUrl).pipe(
      retryWhen(error =>
        error.pipe(
          scan((acc:any, error:any) => {
            if (acc > 2) throw error;
            return acc + 1;
          }, 1),
          delay(1000)
        )
      )
   ).subscribe((value:any) => {
      this.liste_pagination = value.data;
    }, (erreur:any) => {
   })
}

CurrentPagePagination(data:any, index:number, ev:any): void{
   this.activePagination = index;
   this.PaginationNb(data.index);
}
}
