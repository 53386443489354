import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-espace-affichage',
  templateUrl: './espace-affichage.component.html',
  styleUrls: ['./espace-affichage.component.css']
})
export class EspaceAffichageComponent implements OnInit {
  @Input() resize : boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

}
