<div class="x_panel x_panel_close">
    <div class="x_content">
        <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;">
            <span><i  class="fa fa-arrow-left"></i></span>
            <div  class="ml-auto text-right">
                <span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span>
                    <i class="fa fa-chevron-right"></i>
                <span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Journal Transit - Etat</span>
            </div>
        </div>
        <br>
        <div class="col-md-12">
            <div class="content-action">
                <ul class="nav nav-tabs bar_tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                    	<a class="nav-link active" id="saisir-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='saisir'}" (click)="search('saisir')"
                           role="tab" aria-controls="saisir" aria-selected="true">Saisir Jounal Transit</a>
                    </li>

                </ul>
                <div class="tab-content" id="myTabContent">
                    <div [ngClass]="{ 'show':activeTab==='saisir', 'active':activeTab==='saisir'}" class="tab-pane fade active show" id="saisir" role="tabpanel" aria-labelledby="saisir-tab">
					<br>
                        <div class="x_panel x_panel_close">
                            <div class="x_title">
                                <h5>Saisir Jounal Transit</h5>
                                <ul class="nav navbar-right panel_toolbox" style="padding-left: 0px;"></ul>
                                <div class="clearfix"></div>
                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                  <div class="form-group col-xs-12 col-md-6">
                                    <div class="persoautocomplete">
                                          <label for="">Selectionner N°Dossier*</label>
                                         <input [(ngModel)]="dossier.numeroDossier1" type="text" name="numdossForm" id="numdossForm" placeholder="N°Dossier"  class="form-control form-control-sm" autocomplete="off" (focus)="reInitSearchForm()" (blur)="reInitSearchBlurForm()" disabled="disabled">
                                           <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="">
                                           </button>
                                            <div class="classPerso  dropdown-menu">
                                              <form class="px-0 py-3">
                                                  <div class="col-md-12">
                                                      <div class="row">
                                                          <div class="col-md-12">
                                                            <input type="text" [(ngModel)]="dossier.numeroDossier" class="form-control form-control-sm" placeholder="N°Dossier" name="FormSaisiMouvNav" (ngModelChange)="ListeAutoCompleteDossier(dossier.numeroDossier)" autocomplete="off">
                                                            <div>
                                                              <br>
                                                                <table class="table_bloc_liste tableReseach">
                                                                    <thead class="theadfixed">
                                                                        <th>N°Dossier</th>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr class="SelectTable"  *ngFor="let x of listdoss" (click)="SelectionAutocomplete(x)">
                                                                            <td>{{x.numeroDossier}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </form>
                                            </div>
                                    </div>
                                  </div>
                                  <div class="form-group col-xs-12 col-md-6">
                                    <label for="Navire">Navire</label>
                                    <input type="text" class="form-control form-control-sm" id="provenance" placeholder="Navire" [(ngModel)]="dossier.Navire" disabled="true">

                                  </div>
                                  <div class="col-md-6 form-group">
                                      <div class="persoautocomplete">
                                          <label for="">Produit*</label>
                                          <input type="text"  class="form-control form-control-sm" name="ProduitSortieMag" id="ProduitSortieMag" [(ngModel)] = "produit.nomSous" autocomplete="off" [disabled]="true" placeholder="Produit">
                                          <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          </button>
                                          <div class="classPerso  dropdown-menu">
                                            <form class="px-0">
                                                <div class="col-md-12">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                           <label>N° BL</label>
                                                              <input type="text" [(ngModel)] = "produit.numBL" class="form-control form-control-sm" placeholder="N° BL" (ngModelChange)="ListeAutoCompleteProduit(produit.numBL)" name="prod1">
                                                          <div>
                                                            <br>
                                                              <table class="table_bloc_liste tableReseach">
                                                                  <thead class="theadfixed">
                                                                    <th>N° BL</th>
                                                                    <th>Produit</th>
                                                                    <th>Nom Client</th>
                                                                  </thead>
                                                                  <tbody>
                                                                      <tr class="SelectTable" *ngFor="let x of listautoSpecifik" (click)="SelectionAutocompleteProduit(x)">
                                                                          <td>{{x.reference}}</td>
                                                                          <td>{{x.NomSpecificProduit}}</td>
                                                                          <td>{{x.nom_societe}}</td>
                                                                      </tr>
                                                                  </tbody>
                                                              </table>
                                                          </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                          </div>
                                        </div>
                                  </div>


                                     <div class="col-md-6 form-group">
                                          <label>Qte</label>
                                          <input type="number" class="form-control form-control-sm" id="quantiteSortieMag" name="quantiteSortieMag" placeholder="quantite" autocomplete="off" [(ngModel)] = "produit.qte" [disabled]="true">
                                     </div>
                                      <div class="col-md-6 form-group">
                                          <label>Poids total (Tonne)</label>
                                          <input type="number" class="form-control form-control-sm" id="poids_totalSortieMag" name="Poids" placeholder="Poids" autocomplete="off" [(ngModel)] = "produit.PoidsNet" [disabled]="true">
                                     </div>
                                     <div class="col-md-6 form-group">
                                         <label>Client</label>
                                         <input type="text" class="form-control form-control-sm" id="poids_totalSortieMag" name="Client" placeholder="Client" autocomplete="off" [(ngModel)] = "produit.nom_societe" [disabled]="true">
                                    </div>

                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="">
                                  <p>
                                    <button class="btn btn-sm btn-primary" (click) = "ajouter()">
                                      <i class="fas fa-plus"></i>&nbsp;Nouveau
                                    </button>&nbsp;&nbsp;

                                  </p>
                                    <table class="table_mainoeuvre_fourniture table_bloc_liste">
                                        <thead>
                                          <tr>

                                          <th rowspan="2">Date</th>
                                              <th rowspan="2">Type Transport</th>
                                              <th rowspan="2">Camions</th>
                                              <th rowspan="2">Destination</th>
                                              <th rowspan="2">Nbre Sacs</th>
                                              <th rowspan="2">Poids</th>
                                              <th rowspan="2">BL Expert</th>
                                              <th rowspan="2">BL Acc</th>
                                              <th rowspan="2">BL Transit</th>
                                              <th colspan="2">Cumul</th>
                                              <th colspan="2">Solde</th>
                                              <th rowspan="2"></th>


                                          </tr>
                                          <tr>

                                              <th>Sacs</th>
                                              <th>Poids</th>
                                              <th>Sacs</th>
                                              <th>Poids</th>


                                          </tr>
                                        </thead>
                                        <tbody>

                                          <tr *ngFor="let i of dataSousMenuModel;let x = index;">

                                              <td>
                                                <input type="date" class="form-control form-control-sm" style="width: 100px;" placeholder="jj/mm/aaaa" [(ngModel)] = "i.date" (ngModelChange)="UpdateEltProforma(i)">
                                             </td>
                                             <td>
                                               <select class="form-control form-control-sm" name="Camion" [(ngModel)] = "i.typetransport" (ngModelChange)="UpdateEltProforma(i)">
                                                 <option value=""></option>
                                                 <option value="Camion Lta">Camion Lta</option>
                                                 <option value="Camion Client">Camion Client</option>
                                                 <option value="Chargement a Main(Camon Bourse)">Chargement a Main(Camon Bourse)</option>

                                               </select>
                                           </td>
                                              <td>
                                                <select class="form-control form-control-sm" name="Camion" [(ngModel)] = "i.camion" (ngModelChange)="UpdateEltProforma(i)">
                                                  <option value="">Sélectionner un Camion</option>
                                                  <option *ngFor="let xx of camion" value="{{xx.immat}}">{{xx.immat}}</option>

                                                </select>
                                              </td>
                                              <td>

                                                <select class="form-control form-control-sm" name="Camion" [(ngModel)] = "i.destination" (ngModelChange)="UpdateEltProforma(i)">
                                                  <option value=""></option>
                                                  <option value="Magasin Lta">Magasin Lta</option>
                                                  <option value="Magasin Client">Magasin Client</option>
                                                    <option value="Magasin Portuaire">Magasin Portuaire</option>
                                                  <option value="Chargement à Quai">Chargement à Quai</option>

                                                </select>
                                              </td>
                                              <td>
                                                <input type="number" class="form-control form-control-sm" name="Nbre Sac"  placeholder="Nbre Sac" [(ngModel)] = "i.nbresac" (ngModelChange)="UpdateEltProforma(i)">
                                              </td>
                                              <td>
                                                <input type="number" class="form-control form-control-sm" name="Poids" placeholder="poids" [(ngModel)] = "i.poids" (ngModelChange)="UpdateEltProforma(i)">
                                              </td>

                                              <td>
                                                <input type="text" class="form-control form-control-sm" name="Bl Expert" placeholder="Bl Expert" [(ngModel)] = "i.blexpert" (ngModelChange)="UpdateEltProforma(i)">
                                              </td>
                                              <td>
                                                <input type="text" class="form-control form-control-sm" name="Bl Acc" placeholder="Bl Acc" [(ngModel)] = "i.blacc" (ngModelChange)="UpdateEltProforma(i)">
                                              </td>
                                              <td>
                                                <input type="text" class="form-control form-control-sm" name="Bl Transite" placeholder="Bl Transite" [(ngModel)] = "i.bltransite" (ngModelChange)="UpdateEltProforma(i)">
                                              </td>
                                              <td>
                                                <input type="number" class="form-control form-control-sm" name="Cumul Sacs"  placeholder="Cumul Sacs"  [(ngModel)] = "i.cumulsacs" [disabled]="true" (ngModelChange)="UpdateEltProforma(i)">
                                              </td>
                                              <td>
                                                <input type="number" class="form-control form-control-sm" name="Cumil Poids" placeholder="Cumil Poids" [(ngModel)] = "i.cumulpoids" [disabled]="true"  (ngModelChange)="UpdateEltProforma(i)">
                                              </td>
                                              <td>
                                                <input type="number" class="form-control form-control-sm" name="Solde Sacs"  placeholder="Solde Sacs"  [(ngModel)] = "i.soldesacs" [disabled]="true" (ngModelChange)="UpdateEltProforma(i)">
                                              </td>
                                              <td>
                                                <input type="number" class="form-control form-control-sm" name="Solde Poids" placeholder="Solde Poids" [(ngModel)] = "i.soldepoids" [disabled]="true"  (ngModelChange)="UpdateEltProforma(i)">
                                              </td>
                                              <td class="text-center">
                                                <button class="btn btn-danger btn-sm" type="button" (click)="supprimer_sous_menu(i.idjournaltransite, x)" title="supprimer">
                                                      <i class="fa fa-trash-alt" ></i>
                                                </button>

                                                </td>

                                          </tr>
                                          <tr>
                                             <td style="text-align: right; font-weight:bold" colspan="12">Cumul Total Sacs</td>
                                             <td colspan="12">{{Totalcumul_sacs}}</td>
                                          </tr>
                                          <tr>
                                             <td style="text-align: right; font-weight:bold" colspan="12">Cumul Total Poids</td>
                                             <td colspan="12">{{Totalcumul_poids}}</td>
                                          </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <br>


                    </div>



                </div>
            </div>
        </div>
    </div>
</div>
