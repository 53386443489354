<div class="x_panel x_panel_close">
        <div class="x_content">
            <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;"><span ><i  class="fa fa-arrow-left"></i></span><div  class="ml-auto text-right"><span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span><i  class="fa fa-chevron-right"></i><span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Reporting- Liste Déchargement
              </span>
             </div>
            </div>
            <br>
               <div class="col-md-12">
                            <div class="content-action">
                                 <ul class="nav nav-tabs bar_tabs" id="myTab" role="tablist">
                                          <li class="nav-item">
                                            <a class="nav-link active" id="saisir-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='saisir'}" (click)="search('saisir')"
                                               role="tab" aria-controls="saisir" aria-selected="true">Liste de Déchargements</a>
                                          </li>
                                    </ul>
                                    <div class="tab-content" id="myTabContent">
                                        <div [ngClass]="{ 'show':activeTab==='saisir', 'active':activeTab==='saisir'}"
                                        class="tab-pane fade active show" id="saisir" role="tabpanel" aria-labelledby="saisir-tab">

                                         <br>
                                                <div class="">
                                                            <div class="x_content">
                                                              <div class="form-group col-xs-12 col-md-12 recherche_liste">
                                                                <div class="row">
                                                                       <div class="col-md-3 form-group">
                                                                        <div class="persoautocomplete">
                                                                            <label for="">N° Dossier</label>
                                                                            <input type="text"  class="form-control form-control-sm"  autocomplete="off" disabled="" [(ngModel)] = "recherche.numeroDossier" name="doss0">
                                                                            <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                            </button>
                                                                            <div class="classPerso  dropdown-menu">
                                                                              <form class="px-0 py-3">
                                                                                  <div class="col-md-12">
                                                                                      <div class="row">
                                                                                          <div class="col-md-12">
                                                                                            <label>Saisir N° Dossier</label>
                                                                                              <input type="text" [(ngModel)] = "recherche.numeroDossier1" class="form-control" placeholder="N°Dossier" (ngModelChange)="ListeAutoCompleteDossier(recherche.numeroDossier1)" name="doss1">
                                                                                            <div>
                                                                                              <br>
                                                                                                <table class="table_bloc_liste tableReseach">
                                                                                                    <thead class="theadfixed">
                                                                                                        <th>N°Dossier</th>
                                                                                                    </thead>
                                                                                                    <tbody>
                                                                                                        <tr class="SelectTable" *ngFor="let x of listdoss" (click)="SelectionAutocomplete(x)">
                                                                                                            <td>{{x.numeroDossier}}</td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </div>
                                                                                          </div>
                                                                                      </div>
                                                                                  </div>
                                                                              </form>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      <div class="form-group col-xs-12 col-md-3">
                                                                              <div class="form-group">
                                                                                    <label for="exampleFormControlInput2">Navire</label>
                                                                                    <input type="text" id="exampleFormControlInput2" placeholder="Navire" class="form-control form-control-sm" [(ngModel)] = "recherche.Navire" (ngModelChange)="Recherche(recherche)">
                                                                              </div>
                                                                       </div>
                                                                       <div class="form-group col-xs-12 col-md-3">
                                                                              <div class="form-group">
                                                                                    <label for="exampleFormControlInput2">Periode du</label>
                                                                                    <input type="date" id="exampleFormControlInput2" placeholder="Periode du" class="form-control form-control-sm" [(ngModel)] = "recherche.debut1" (ngModelChange)="Recherche(recherche)">
                                                                              </div>
                                                                      </div>
                                                                      <div class="form-group col-xs-12 col-md-3">
                                                                            <div class="form-group">
                                                                                <label for="exampleFormControlInput2">Au</label>
                                                                                <input type="date" id="exampleFormControlInput2" placeholder="Au" class="form-control form-control-sm" [(ngModel)] = "recherche.debut2" (ngModelChange)="Recherche(recherche)">
                                                                            </div>
                                                                       </div>
                                                              </div>
                                                            </div>
                                                        </div>
                                                                <table class="table_bloc_liste">
                                                                        <thead>
                                                                          <tr>
                                                                            <th>#</th>
                                                                            <th>Date Déchargement</th>
                                                                            <th>N°Dossier</th>
                                                                            <th>Navire</th>
                                                                            <th>Quantité Décharge</th>
                                                                            <th>Poids Décharge (en Tonne)</th>
                                                                          </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                          <tr *ngFor="let x of Liste_etat_dechargement">
                                                                               <td>{{x.id_dechargelent}}</td>
                                                                                <td>{{x.debut_operation}}</td>
                                                                                <td>{{x.numeroDossier}}</td>
                                                                                <td>{{x.Navire}}</td>
                                                                                <td>{{x.total_qte_decharge}}</td>
                                                                                <td>{{x.total_poids_decharge}}</td>
                                                                          </tr>
                                                                        </tbody>
                                                                  </table>
                                                    </div>
                                                    <br>
                                                    <div class="col-sm-12 text-right mobile-buttons">
                                                      
                                                        <button  class="btn btn-secondary btn-sm btn-enregistrer" (click) = "RapportPdf()">
                                                            <font style="vertical-align: inherit;">
                                                                <font style="vertical-align: inherit;">Imprimer</font>
                                                            </font>
                                                        </button>
                                                    </div>

                                        </div>

                                     <div class="tab-pane fade" id="profile" role="tabpanel"  aria-labelledby="profile-tab" [ngClass]="{'show':activeTab==='profile', 'active':activeTab==='profile'}">
                                     </div>

                                    </div>
                              </div>
                        </div>

        </div>
</div>
