import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/connexion/auth.service';

@Component({
  selector: 'app-connexion',
  templateUrl: './connexion.component.html',
  styleUrls: ['./connexion.component.css']
})
export class ConnexionComponent implements OnInit {
  user:any = {};
  error:boolean = false;
  error2:boolean = false;
  dateLocale : any = {};
image_couverture: any = "";
mes_images : any = [
                  './assets/img/port1.jpg',
                ];
  constructor(private AuthService : AuthService, private router: Router) { }

  ngOnInit(): void {

    this.user.login = "";
    this.user.pwd = "";
    this.image_couverture = this.mes_images[0];
  this.afficherDate();
  }

  Annuler() : void{
    this.user.login = "";
    this.user.pwd = "";
  }

imagesAleatoire(): void{


var numeroImages = Math.floor(Math.random()*this.mes_images.length);
setInterval(()=> {
var numeroImages = Math.floor(Math.random()*this.mes_images.length);
this.image_couverture = this.mes_images[numeroImages];
},8000);

}
afficherDate():void{
let now = new Date();

this.dateLocale = now.toLocaleString('fr-FR',{
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric'});
}

  VerificationChampsObligatoire(tableau_champs_obligatoires:any){
        var verification_formulaire = true;
        for (var i = 0; i < tableau_champs_obligatoires.length; i++) {
            if($(tableau_champs_obligatoires[i]).val() == "" || $(tableau_champs_obligatoires[i]).val() == null || $(tableau_champs_obligatoires[i]).val() == "? string: ?"){
                    $(tableau_champs_obligatoires[i]).css("border","1px solid red");
                    verification_formulaire = false;
                    document.getElementById(tableau_champs_obligatoires[i].replace("#",""))?.scrollIntoView({behavior: "smooth"});
                    break;
            }else{
                $(tableau_champs_obligatoires[i]).css("border","");
            }
        }
        return verification_formulaire;
    }

  connexion(data:any) : void{
    if(this.VerificationChampsObligatoire(["#login", "#pwd1"]) == false)
      return;

    this.error = false;
    this.error2 = false;
    this.AuthService.Connexion(data).subscribe((value:any) => {
        if(value.status == 1){
          this.error = false;
          this.Annuler();
          localStorage.setItem("_tkex", value.token);
          this.AuthService.getInfoUtilisateur();
          this.router.navigateByUrl('/accueil');
        }else{
           if(value.status == -1){
              this.error2 = true;
              return;
            }
          this.error = true;
        }
    });

  }

}
