import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-creer-facture-export',
  templateUrl: './creer-facture-export.component.html',
  styleUrls: ['./creer-facture-export.component.css']
})
export class CreerFactureExportComponent implements OnInit {

  activeTab:String="profile";

  constructor() { }

  ngOnInit(): void {
  }

  search(activeTab:String){
    this.activeTab=activeTab
  }

}
