export class reglementnavire {
  public idReglementnavire: number = 0;
  public iddossier: number = 0;
  public num_navire: number = 0;
  public total_reglementnavire: number = 0;
  public date_reglementnavire: string = "";
  public date_creation: string = "";
  public idUser: number = 0;
  public modifierpar: number = 0;
  public datemodification: string = "";
  constructor() {

  }

}
