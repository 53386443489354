export class PROVENANCE {
  public idProvenance: number = 0;
  public libelle: string = "";
  public user: string = "";
  public date: string = "";
  public modifierpar: number = 0;
  public datemodification: string = "";
  constructor() {
  }

}
