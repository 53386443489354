import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router'
import { AuthService } from '../../../services/connexion/auth.service';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';

@Component({
  selector: 'app-nav-top',
  templateUrl: './nav-top.component.html',
  styleUrls: ['./nav-top.component.css']
})
export class NavTopComponent implements OnInit {
  apiUrl:String = "SERVER/modules/groupeware/CU/Utilisateur.php";
  utilisateur:any = {};
  userProfile:any = {};
  utilisateurfonction_liste:any = [];
  showFiller:boolean = false;
  OpenPopup:boolean = false;
  OpenMessagePopup:boolean = false;
  constructor(private AuthService : AuthService, private router : Router, private transfertDataService : transfertDataService) { }

  ngOnInit(): void {
    this.utilisateur = this.AuthService.getInfoUtilisateur();
    this.userProfile.nom = this.utilisateur.nom;
    this.userProfile.tel = this.utilisateur.tel;
    this.userProfile.email = this.utilisateur.email;
    this.userProfile.login = this.utilisateur.login;
    this.userProfile.password = this.utilisateur.password;
    this.userProfile.uid = this.utilisateur.id;

    //this.InactivitePlateform();
  }

  Filer(event:boolean){
    this.showFiller = false;
    console.log(this.showFiller);

  }

  toogleMenue(){
    this.showFiller = !this.showFiller;
    console.log(this.showFiller);

  }

  Poppup(){
    this.OpenPopup = !this.OpenPopup;
  }


  MessagePoppup(){
    this.OpenMessagePopup = !this.OpenMessagePopup;
  }

  deconnexion() : void{
    if(localStorage.getItem("_tkex")){
      localStorage.removeItem("_tkex");
      this.router.navigateByUrl("/connexion");
    }
  }

  Enregistrer(user:any) : void{
   user.action = "UpdateProfile";
    this.transfertDataService.EnvoiData(user, this.apiUrl).subscribe((value:any) => {
      
    })
  }


  InactivitePlateform() : void{
    var time:any;
    window.onload = this.resetTimer(time);
    // DOM Events
    document.onmousemove = this.resetTimer(time);
    document.onkeypress = this.resetTimer(time);

    
  }

  resetTimer(time:any) : any{
        clearTimeout(time);
        time = setTimeout(() => {
          this.deconnexion();
        }, 1000 * 60 * 30);
        
    }


}
