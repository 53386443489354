import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { AuthService } from '../../../services/connexion/auth.service';
import { factureconteneur } from '../../../classes/factureconteneur';
import { ConfigurationService } from '../../../config/configuration.service';
import { FactureConteneurService } from '../../../services/facture-conteneur/facture-conteneur.service';

@Component({
  selector: 'app-creer-facture',
  templateUrl: './creer-facture.component.html',
  styleUrls: ['./creer-facture.component.css']
})
export class CreerFactureComponent implements OnInit {

  activeTab:String="saisir";
  agent: any = [];
  choix:any;
  amateur: any = [];
  tous :boolean = false;
  aucun :boolean = false;
    supp_id:any = "";
  listeConteneurs: any = [];
  liste_conteneurRech: any = [];
    listeConteneursSelect: any = [];
    listeConteneursfacturer: any = [];
  recherche:any = {};
  rechercher:any = {};
  liste_AmateurRech:any=[];
  apiUrl:String = "";
  id:any;
ModaleTitle:any;
ModaleText:any;
ModalFoot:any = "FootNoErr";

  factureconteneur:factureconteneur = new factureconteneur();

  constructor(private FactureConteneurService : FactureConteneurService,private transfertDataService : transfertDataService, private AuthService : AuthService,private ConfigurationService : ConfigurationService) { }

  ngOnInit(): void {
      var date = new Date();
    this.listeAgent();
    this.ListeDesConteneurs();
    this.listeAmateur();
    this.listeAmateur1();
    this.ListeDesConteneursFacturer();
    this.rechercher.numConteneur = "";
    this.recherche.amateur1= "";

    this.recherche.dateFin = date.getFullYear()+"-"+("0" + (date.getMonth() + 1)).slice(-2)+"-"+this.DernierJourMoisenCours(date.getFullYear(),date.getMonth());
    this.recherche.dateDebut = date.getFullYear()+"-"+("0" + (date.getMonth() + 1)).slice(-2)+"-01";
  this.initRoute();

  }

initRoute():void{
if(this.FactureConteneurService.id != 0){
  this.information(this.FactureConteneurService.id);
  this.FactureConteneurService.id = 0;
}
}
  DernierJourMoisenCours(y:any,m:any){
    return  new Date(y, m +1, 0).getDate();
  }
  reInitSearchBlurFormAmateur(): void{
    setTimeout((val:any) => {
        this.liste_AmateurRech = [];
    }, 500);
  }

  Fiche(){
    console.log(this.id);

     this.ConfirmationImpressionFormulaire1(this.id);

     this.search("profile");
  }
  Fiche1(){
        console.log(this.id);
     this.Exporter1(this.id);
     this.search("profile");
  }
  ConfirmationImpressionFormulaire1(Id:any){
    var lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/ImportFactureConteneur/pdfConteneurFacture1.php?Id="+Id;
    $("#printRapport").remove();
    $("body").append('<div id="printRapport">\
                        <div class="col-md-12 form-group" style="display:none;">\
                          <button class="btn btn-primary btn-sm bs-modal-frame-sm-btn" data-backdrop="static" data-toggle="modal" data-target=".bs-modal-frame-sm"></button>\
                        </div>\
                        <div class="modal fade bs-modal-frame-sm" tabindex="-1" role="dialog" aria-hidden="true">\
                          <div class="modal-dialog" role="document">\
                              <div class="modal-content">\
                                  <div class="row container_modal" style="width:100%;">\
                                  <div class="modal-header" style="margin-bottom:0px;">\
                                    <h6 class="modal-title" style="font-weight: bold;">LISTE PREVISIONNEL DES LIVRAISONS</h6>\
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>\
                                    </button>\
                                  </div>\
                                  <div class="row col-md-12" style="width:100%;height: calc(100vh - 90px);padding-left: 0px; padding-right: 0px; margin-left: 0px; margin-right: 0px;">\
                                    <div class="col-md-12 waiting">\
                                      <div class="snippet" data-title=".dot-pulse">\
                                        <div class="stage">\
                                          <div class="dot-pulse"></div>\
                                        </div>\
                                      </div>\
                                    </div>\
                                    <iframe class="rapportframe" name="rapportframe" style="width:100%;"></iframe>\
                                  </div>\
                              </div>\
                            </div>\
                          </div>\
                        </div>\
                    </div>');
    $(".bs-modal-frame-sm-btn").trigger("click");
    window.open(lien_fiche_ExpST , "rapportframe")?.focus();
    $(".rapportframe").css("display", "none");
    $('.rapportframe').on('load', function(){
        $(".waiting").css("display", "none");
        $(".rapportframe").css("display", "initial");
    });
  }
  Exporter1(Id:any): void{
      var lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/ImportFactureConteneur/excel_ConteneurFacture1.php?Id="+Id;
        window.open(lien_fiche_ExpST , '_blank')?.focus();
  }

  listeAmateur1(){
    this.apiUrl = "SERVER/importretourconteneur/retour/nomAmateur";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((response:any) => {
         this.amateur = response.liste;

       }, (erreur:any) => {
           console.log(erreur.status);
       })

  }

  reInitSearchFormconteneur(): void{
    this.liste_conteneurRech = [];
  }

  reInitSearchBlurFormconteneur(): void{
    setTimeout((val:any) => {
        this.liste_conteneurRech = [];
    }, 500);
  }
  ListeAutoCompleteConteneurrech(conteneur:any) : void{

    this.apiUrl = "SERVER/importlivraisonconteneur/saisirlivraisonconteneur/listeConteneurrecherche";
    var data = {conteneur:conteneur};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
      this.liste_conteneurRech = response.liste_conteneur;
    }, (erreur:any) => {})

  }
  SelectionAutocompleterechconteneur1(data:any): void{
         this.rechercher.numConteneur1 = data.numConteneur;
         if(this.rechercher.numConteneurSaisi == "")
           this.rechercher.numConteneur1 = "";
            this.recherch(this.rechercher);
  }
  ListeAutoCompleteAmateurRech(amateur:any) : void{

    this.apiUrl = "SERVER/importlivraisonconteneur/traiterconteneur/listeamateurrecherche";
    var data = {amateur:amateur};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
      this.liste_AmateurRech = response.liste_amateur;
    }, (erreur:any) => {
    })

  }


  SelectionAutocompleteRecheche(data:any): void{

    this.rechercher.amateur= data.nomAmateur;
    this.factureconteneur.idAmateur = data.idAmateur;

  }

  reInitSearchFormAmateur(): void{
    this.liste_AmateurRech = [];
  }


  Annuler():void{
    this.factureconteneur = new factureconteneur();
    this.listeConteneurs= [];
    this.listeConteneursSelect= [];
    this.recherche = {};
  }
  ListeDesConteneursFacturer(): void{
    this.apiUrl = "SERVER/importfactureonconteneur/saisirfacturelivraison/liste";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((response:any) => {

          this.listeConteneursfacturer = response.liste;

       }, (erreur:any) => {
           console.log(erreur.status);
       })

  }
  ListeDesConteneurs(): void{
    this.apiUrl = "SERVER/importfactureonconteneur/saisirfacturelivraison/listeRecive";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((response:any) => {


         for (let i = 0; i <  response.liste.length; i++) {
            response.liste[i].choix = false;

         }
          this.listeConteneurs = response.liste;

       }, (erreur:any) => {
           console.log(erreur.status);
       })

  }
  search(activeTab:String){
     this.activeTab=activeTab
   };

  listeAgent(){
    this.apiUrl = "SERVER/importfactureonconteneur/saisirfacturelivraison/nomAgent";
        var data = {};
        this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
            retryWhen(error =>
                 error.pipe(
                   scan((acc:any, error:any) => {
                     if (acc > 2) throw error;
                     return acc + 1;
                   }, 1),
                   delay(1000)
                 )
               )
           ).subscribe((response:any) => {
             this.agent = response.liste;

           }, (erreur:any) => {
               console.log(erreur.status);
    })

  }

  listeAmateur(){
    this.apiUrl = "SERVER/importfactureonconteneur/saisirfacturelivraison/nomAmateur";
        var data = {};
        this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
            retryWhen(error =>
                 error.pipe(
                   scan((acc:any, error:any) => {
                     if (acc > 2) throw error;
                     return acc + 1;
                   }, 1),
                   delay(1000)
                 )
               )
           ).subscribe((response:any) => {
             this.amateur = response.liste;

           }, (erreur:any) => {
               console.log(erreur.status);
    })

  }

  ConteneursTraite(): void{
    this.apiUrl = "SERVER/importfactureonconteneur/saisirfacturelivraison/ConteneursTraite";
    var data = {id:this.factureconteneur.IDFactureConteneur};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((response:any) => {


         for (let i = 0; i <  response.liste.length; i++) {
            response.liste[i].choix = false;

         }
          this.listeConteneursSelect = response.liste;

       }, (erreur:any) => {
           console.log(erreur.status);
       })

  }
  afficher(){
    if (this.tous == true) {
      this.recherche.amateur= "";
      this.ListeDesConteneurs();
    }
  }
    /*CHAMP OBLIGATOIRE*/
  VerificationChampsObligatoire(tableau_champs_obligatoires:any){
    var verification_formulaire = true;
    for (var i = 0; i < tableau_champs_obligatoires.length; i++) {
        if($(tableau_champs_obligatoires[i]).val() == "" || $(tableau_champs_obligatoires[i]).val() == null || $(tableau_champs_obligatoires[i]).val() == "? string: ?"){
                $(tableau_champs_obligatoires[i]).css("border","1px solid red");
                verification_formulaire = false;
                document.getElementById(tableau_champs_obligatoires[i].replace("#",""))?.scrollIntoView({behavior: "smooth"});
                break;
        }else{
            $(tableau_champs_obligatoires[i]).css("border","");
        }
    }

    return verification_formulaire;
  }

  TousAfficher(): void{
    if (this.recherche.tous == true) {
      this.recherche.Aucun = false;
      for (let i = 0; i < this.listeConteneurs.length; i++) {

        this.listeConteneurs[i].choix = true;


      }
    }
  }
  RienAfficher(): void{
    if (this.recherche.Aucun == true) {
        this.recherche.tous = false;
      for (let i = 0; i < this.listeConteneurs.length; i++) {

        this.listeConteneurs[i].choix = false;


      }
    }
  }

   Enregistrer(){
    //Champ obligatoire
    if(this.VerificationChampsObligatoire(["#DateFacturee","#amateur1"]) == false){
      return;
    }

    var infoUser = this.AuthService.getInfoUtilisateur();

    if(this.factureconteneur.IDFactureConteneur == 0){
      this.apiUrl = "SERVER/importfactureonconteneur/saisirfacturelivraison/enregistrer";
      this.factureconteneur.creerPar = infoUser.id;
    }else{
      this.apiUrl = "SERVER/importfactureonconteneur/saisirfacturelivraison/modifier";
      this.factureconteneur.modifierPar = infoUser.id;
    }

    this.factureconteneur.idAmateur = Number(this.factureconteneur.idAmateur);

    var d = {"factureconteneur": this.factureconteneur, "sousmenu" : this.listeConteneursSelect};
    this.transfertDataService.EnvoiData(d, this.apiUrl).pipe(
            retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((value:any) => {
           this.id = value.precedent[0].IDFactureConteneur;
           this.ModaleTitle = "CONFIRMATION TELECHARGEMENT";
           this.ModaleText  = "Souhaitez vous Telecharger ?";
           this.ModalFoot = "FootNoErr";
           $(".ouverturefiche").trigger("click");
          //this.search("profile");
          this.ListeDesConteneursFacturer();
           this.Annuler();
         }, (erreur:any) => {
             console.log(erreur.status);
    })
  }

  Ajouter(d:any, x:any){

      if (d.choix == true) {
           this.listeConteneursSelect.push(d);
      }else{
        this.listeConteneursSelect.splice(x);
      }
console.log(this.listeConteneursSelect);

  }
  Retirer(){
      console.log(this.factureconteneur.IDFactureConteneur);
    if (this.factureconteneur.IDFactureConteneur == 0) {
      for (let i = 0; i < this.listeConteneursSelect.length; i++) {

        if (this.listeConteneursSelect[i].choix == false) {
             this.listeConteneursSelect.splice(i, 1);
        }

      }
    }else{
      let eltSuprimer = [];
      for (let y = 0; y < this.listeConteneursSelect.length; y++) {
        if (this.listeConteneursSelect[y].choix == true) {
            eltSuprimer.push(this.listeConteneursSelect);
        }

      }
      this.apiUrl = "SERVER/importfactureonconteneur/saisirfacturelivraison/supprimerTraite";
      var data = {eltSuprimer:eltSuprimer};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
this.ConteneursTraite();
      }, (erreur:any) => {
          console.log(erreur.status);
      })
    }

  }
  information(id:any):void{

    this.apiUrl = "SERVER/importfactureonconteneur/saisirfacturelivraison/information";
    var data = {id:id};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
      retryWhen(error =>
        error.pipe(
              scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((response:any) => {
              this.factureconteneur = response.conteneur[0];

              this.rechercher.amateur = response.conteneur[0].nomAmateur;

              for (let i = 0; i < response.conteneur[0]["Facturer"].length; i++) {
                this.listeConteneursSelect[i]= response.conteneur[0]["Facturer"][i];


              }
              for (let z = 0; z < response.conteneur[0]["listeAFacturer"].length; z++) {
                this.listeConteneurs[z]= response.conteneur[0]["listeAFacturer"][z];


              }


              this.search("saisir");
           }, (erreur:any) => {
             console.log(erreur.status);
         })

     }
  SupprimerPopup(id:any): void{
    $(".suppliste").trigger("click");
    this.supp_id = id;
  }

  supprimer(id:any):void{
       this.apiUrl = "SERVER/importfactureonconteneur/saisirfacturelivraison/supprimer";
       var data = {id:id};
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
     ).subscribe((value:any) => {
         this.ListeDesConteneursFacturer();
       }, (erreur:any) => {
           console.log(erreur.status);
       })

  }
  recherch(data:any): void{

    if (this.recherche.tous == true) {
      this.ListeDesConteneurs();
      this.recherche.amateur = "";
        this.recherche.Aucun =false;
        return;
    }

    if (this.recherche.Aucun == true) {
      this.recherche.tous =false;
      this.recherche.amateur = "";
        return;
    }
       if(this.recherche.numConteneur == "")
       this.ListeDesConteneurs();
       else{
         this.apiUrl = "SERVER/importfactureonconteneur/saisirfacturelivraison/rechercheReception";
         var data = data;
         this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
           retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((value:any) => {
              this.listeConteneurs= value.data;
         }, (erreur:any) => {
             console.log(erreur.status);
         })
       }

   }


rechercheFacture(data:any): void{
     if(this.recherche.amateur1 == "" && this.recherche.dateDebut == ""  && this.recherche.dateFin == "")
     this.ListeDesConteneursFacturer();
     else{
       this.apiUrl = "SERVER/importfactureonconteneur/saisirfacturelivraison/recherche";
       var data = data;
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
     ).subscribe((value:any) => {
            this.listeConteneursfacturer= value.data;

       }, (erreur:any) => {
           console.log(erreur.status);
       })
     }

 }
 Exporter(): void{
     var lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/ImportFactureConteneur/excel_ConteneurFacture.php?amateur1="+this.recherche.amateur1+"&dateDebut="+this.recherche.dateDebut+"&dateFin="+this.recherche.dateFin;
       window.open(lien_fiche_ExpST , '_blank')?.focus();
 }


 ConfirmationImpressionFormulaire(){
        var lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/ImportFactureConteneur/pdfConteneurFacture.php?amateur1="+this.recherche.amateur1+"&dateDebut="+this.recherche.dateDebut+"&dateFin="+this.recherche.dateFin;
      $("#printRapport").remove();
        $("body").append('<div id="printRapport">\
                            <div class="col-md-12 form-group" style="display:none;">\
                              <button class="btn btn-primary btn-sm bs-modal-frame-sm-btn" data-backdrop="static" data-toggle="modal" data-target=".bs-modal-frame-sm"></button>\
                            </div>\
                            <div class="modal fade bs-modal-frame-sm" tabindex="-1" role="dialog" aria-hidden="true">\
                              <div class="modal-dialog" role="document">\
                                  <div class="modal-content">\
                                      <div class="row container_modal" style="width:100%;">\
                                      <div class="modal-header" style="margin-bottom:0px;">\
                                        <h6 class="modal-title" style="font-weight: bold;">LISTE CONTENEUR DE LIVRAISON</h6>\
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>\
                                        </button>\
                                      </div>\
                                      <div class="row col-md-12" style="width:100%;height: calc(100vh - 90px);padding-left: 0px; padding-right: 0px; margin-left: 0px; margin-right: 0px;">\
                                        <div class="col-md-12 waiting">\
                                          <div class="snippet" data-title=".dot-pulse">\
                                            <div class="stage">\
                                              <div class="dot-pulse"></div>\
                                            </div>\
                                          </div>\
                                        </div>\
                                        <iframe class="rapportframe" name="rapportframe" style="width:100%;"></iframe>\
                                      </div>\
                                  </div>\
                                </div>\
                              </div>\
                            </div>\
                        </div>');
        $(".bs-modal-frame-sm-btn").trigger("click");
        window.open(lien_fiche_ExpST , "rapportframe")?.focus();
        $(".rapportframe").css("display", "none");
        $('.rapportframe').on('load', function(){
            $(".waiting").css("display", "none");
            $(".rapportframe").css("display", "initial");
        });
 }

}
