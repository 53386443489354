<div class="x_panel x_panel_close">
  <div class="x_content">
   <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;"><span ><i  class="fa fa-arrow-left"></i></span><div  class="ml-auto text-right"><span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span><i  class="fa fa-chevron-right"></i><span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Gestion des Utilisateurs - compte</span>
      </div>
  </div>

    <ul class="nav nav-tabs bar_tabs" id="myTab" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" id="saisir-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='saisir'}" (click)="Tabulation('saisir')"
               role="tab" aria-controls="saisir" aria-selected="true">Créer utilisateur</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="profile-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='profile'}" (click)="Tabulation('profile')" role="tab" aria-controls="profile" aria-selected="false">Liste utilisateurs</a>
          </li>
    </ul>
    <div class="tab-content" id="myTabContent">
        <div [ngClass]="{ 'show':activeTab==='saisir', 'active':activeTab==='saisir'}" class="tab-pane fade active show" id="saisir" role="tabpanel" aria-labelledby="saisir-tab">
          <br>
          <div class="x_panel_title x_panel_close">
            <div class="x_title">
              <h6>Créer un Compte</h6>
              <ul class="nav navbar-right panel_toolbox">
              </ul>
              <div class="clearfix"></div>
            </div>
            <div class="x_content">
                  <div class="col-md-12">
                  <form>

                    <div class="row">
                        <div class="form-group col-xs-12 col-sm-6">
                            <label for="">Nom & Prénom </label>
                           <input  [(ngModel)]="userModel.nom" type="text" placeholder="Nom & Prénom" name="nom" id="nom"  class="form-control form-control-sm">
                        </div>

                        <div class="form-group col-xs-12 col-sm-6">
                           <label for="Recherche_libelle_catégorie">Téléphone</label>
                           <input  [(ngModel)]="userModel.tel"  type="text" id="tel" name="tel" placeholder="Téléphone" class="form-control form-control-sm">
                       </div>
                       <div class="form-group col-xs-12 col-sm-6">
                          <label for="">Fonction</label>
                          <select [(ngModel)]="userModel.responsabilite"  id="fonction" name="fonction" class="form-control form-control-sm">
                            <option *ngFor="let x of utilisateurfonction_liste" value="{{x.idFonction}}">{{x.libelle}}</option>
                          </select>
                       </div>
                       <div class="form-group col-xs-12 col-sm-6">
                          <label for="">Email</label>
                          <input  [(ngModel)]="userModel.email" type="email" placeholder="Email" name="EmailCreationUser" class="form-control form-control-sm" id="EmailCreationUser">
                       </div>
                      <div class="form-group col-xs-12 col-sm-6">
                          <label for="">Login*</label>
                          <input [(ngModel)]="userModel.login" type="text" placeholder="Login" name="login" class="form-control form-control-sm" id="login">
                          <small class="alert-danger msg-error" *ngIf="errorLogin == true">{{errorLoginMessage}}</small>
                       </div>

                       <div class="form-group col-xs-12 col-sm-6">
                          <label for="">Mot de passe*</label>
                          <input  [(ngModel)]="userModel.password" type="password" id="pwd1" placeholder="Mot de passe avec 8 caractères au minimum" name="pwd" class="form-control form-control-sm">
                       </div>

                       <div class="form-group col-xs-12 col-sm-6">
                          <label for="">Groupe</label>
                          <select [(ngModel)]="userModel.idgroupe" id="groupe1" placeholder="Groupe" name="groupe1" class="form-control form-control-sm">
                            <option *ngFor="let x of listegroupe" value="{{x.idgroupe}}">{{x.libelle}}</option>
                          </select>
                       </div>


                       <div class="form-group col-xs-12 col-sm-12">
                              <div  class="form-check form-switch">
                                  <input  type="checkbox" name="" id="active" class="form-check-input">
                                  <label>Superviseur</label>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <input  [(ngModel)]="userModel.active" type="checkbox" name="active" id="active" class="form-check-input">
                                  <label>Activé</label>
                              </div>
                       </div>


                      </div>


               </form>
               </div>
            </div>
          </div>
          <div class="text-right-btn">
              <button type="button" class="btn btn-primary  btn-sm btn-enregistrer" (click) = "Enregistrer(userModel)">Enregistrer</button>&nbsp;
              <button type="button" class="btn btn-secondary btn-sm btn-annuler" (click) = "Annuler()">Annuler</button>&nbsp;
           </div>
         </div>
         <div [ngClass]="{ 'show':activeTab==='profile', 'profile':activeTab==='profile'}" class="tab-pane fade active show" id="profile" role="tabpanel" aria-labelledby="profile-tab">
          <div class="col-md-12"><br>
                  <div class="recherche_liste row">
                            <div class="form-group col-md-3 ">
                              <label for="exampleInputEmail1">Recherche par</label>
                              <input [(ngModel)]="recherche.nom" type="text" class="form-control form-control-sm" id="Nom Utilisateur" name="recherche_nom" placeholder="Nom Utilisateur" (ngModelChange)="Recherche(recherche)">
                            </div>
                             <div class="form-group col-md-3 ">
                              <label for="exampleInputEmail1">Login</label>
                              <input [(ngModel)]="recherche.login" type="text" class="form-control form-control-sm" id="Login Utilsateur" name="recherche_login" placeholder="Login" (ngModelChange)="Recherche(recherche)">
                            </div>
                    </div>
                </div>

                      <div class="card-body">
                      <div class="Table_Longer1">
                         <table class="table_bloc_liste table_responsive">
                              <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Nom & Prénoms</th>
                                    <th>Groupe</th>
                                    <th>Téléphone</th>
                                    <th>Fonction</th>
                                    <th>Login</th>
                                    <th>Créer Par</th>
                                    <th>Créer Le</th>
                                    <th>Modifier Par</th>
                                    <th>Modifier Le</th>
                                    <th></th>
                                    <th></th>
                                  </tr>
                              </thead>
                              <tbody>
                                   <tr *ngFor="let x of utilisateur_liste">
                                      <td>{{x.id}}</td>
                                      <td>{{x.nom}}</td>
                                      <td>{{x.groupe}}</td>
                                      <td>{{x.tel}}</td>
                                      <td>{{x.libelle}}</td>
                                      <td>{{x.login}}</td>
                                      <td>{{x.nomUSER}}</td>
                                      <td>{{x.date}}</td>
                                      <td>{{x.modifierparusers}}</td>
                                      <td>{{x.datemodificationuser}}</td>
                                      <td class="text-center">
                                        <button  class="btn btn-success btn-sm" (click)="AfficherDonner(x.id)">
                                               <i class="fas fa-pencil-alt"></i>
                                        </button>
                                      </td>
                                       <td class="text-center">
                                          <button  class="btn btn-danger btn-sm" (click)="SupprimerUser(x.id)">
                                             <i class="far fa-trash-alt"></i>
                                       </button>
                                       </td>
                                   </tr>
                               </tbody>
                           </table>
                      </div>
                  </div>

         </div>

    </div>
  </div>
</div>
