export class element_charge_exploitation {
  public id_element_charge: number = 0;
  public type_element: string = "";
  public designation: string = "";
  public pu: number = 0;
  public date: string = "";
  public idUser: number = 0;
  public modifierpar: number = 0;
  public datemodification: string = "";
  constructor() {

  }


}
