<div class="x_panel x_panel_close">
    <div class="x_content">
        <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;">
            <span><i  class="fa fa-arrow-left"></i></span>
            <div  class="ml-auto text-right">
                <span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span>
                      <i class="fa fa-chevron-right"></i>
                <span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Gestion Magasins - Conditionnement Produits
                </span>
         </div>
        </div>
        <br>
           <div class="col-md-12">
                        <div class="content-action">
                             <ul class="nav nav-tabs bar_tabs" id="myTab" role="tablist">
                                      <li class="nav-item">
                                        <a class="nav-link active" id="saisir-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='saisir'}" (click)="search('saisir')"
                                           role="tab" aria-controls="saisir" aria-selected="true">Saisir Conditionnement Produit</a>
                                      </li>
                                      <li class="nav-item">
                                        <a class="nav-link" id="profile-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='profile'}" (click)="search('profile')"
                                            role="tab" aria-controls="profile" aria-selected="false">Liste Conditionnement Produits</a>
                                      </li>
                                </ul>
                                <div class="tab-content" id="myTabContent">
                                    <div [ngClass]="{ 'show':activeTab==='saisir', 'active':activeTab==='saisir'}"
                                    class="tab-pane fade active show" id="saisir" role="tabpanel" aria-labelledby="saisir-tab">
                                    <br>
                                    <div class="x_panel x_panel_close">
                                        <div class="x_title">
                                              <h5>Produit Reconditionné</h5>
                                              <ul class="nav navbar-right panel_toolbox" style="padding-left: px;"></ul>
                                              <div class="clearfix"></div>
                                        </div>
                                        <div class="x_content">
                                          <div class="col-md-12">
                                              <div class="row">
                                                  <div class="form-group col-md-6">
                                                          <label>Magasin*</label>
                                                          <select class="form-control form-control-sm" name="magasin" id="magasin" >
                                                              <option value=""></option>
                                                          </select>
                                                  </div>
                                                  <div class="form-group col-md-6">
                                                      <div class="persoautocomplete">
                                                          <label for="">N° Dossier</label>
                                                          <input type="text"  class="form-control form-control-sm" name="numeroDossier"  autocomplete="off" disabled="">
                                                          <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                                                          <div class="classPerso  dropdown-menu">
                                                                <form class="px-0 py-3">
                                                                    <div class="col-md-12">
                                                                        <div class="row">
                                                                            <div class="col-md-12">
                                                                              <input type="text"  class="form-control form-control-sm" placeholder="N°Dossier"  name="dosss1">
                                                                              <div>
                                                                                <br>
                                                                                  <table class="table_bloc_liste tableReseach">
                                                                                      <thead class="theadfixed">
                                                                                          <th>N°Dossier</th>
                                                                                      </thead>
                                                                                      <tbody>
                                                                                          <tr class="SelectTable" >
                                                                                              <td></td>
                                                                                          </tr>
                                                                                      </tbody>
                                                                                  </table>
                                                                              </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="form-group col-md-6">
                                                    <label>Navire</label>
                                                 <input type="text" class="form-control form-control-sm" id="qte" name="qte" placeholder="Navire"  autocomplete="off" [disabled]="true">
                                                </div>

                                                  <div class="col-md-6 form-group">
                                                      <label>Client*</label>
                                                      <select name="listeclientDossEntreeMag" id="listeclientDossEntreeMag" class="form-control form-control-sm">
                                                          <option value=""></option>
                                                      </select>
                                                  </div>
                                                  
                                                  <div class="form-group col-md-6">
                                                      <div class="persoautocomplete">
                                                              <!--<label for="">Produit</label>
                                                               <input type="text"  class="form-control form-control-sm" name="nomSousProduit" [(ngModel)] = "dossiertransfert.nomSousProduit" autocomplete="off" disabled="">
                                                                <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                </button>
                                                                <div class="col-md-12">
                                                                  <div class="classPerso dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                     <div class="col-md-12">
                                                                         <div class="row">
                                                                             <div class="col-md-12">
                                                                                 <label>Saisir Sous produits</label>
                                                                                  <input type="text" [(ngModel)] = "dossiertransfert.nomSousProduit1" class="form-control" placeholder="Sous Produit" (ngModelChange)="ListeAutoCompleteProduit(dossiertransfert.nomSousProduit1)">
                                                                                 <ul class="listeresultatsautcomplete">
                                                                                     <li *ngFor="let x of listautoSpecifik" (click)="SelectionAutocompleteProduit(x)">{{x.nomSousProduit}}</li>
                                                                                 </ul>
                                                                             </div>
                                                                         </div>
                                                                     </div>
                                                                  </div>
                                                                </div>-->
                                                              <label for="">Produit*</label>
                                                              <input type="text"  class="form-control form-control-sm" name="nomSousProduit"  autocomplete="off" disabled="">
                                                              <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                                                              <div class="classPerso  dropdown-menu">
                                                                    <form class="px-0 py-3">
                                                                        <div class="col-md-12">
                                                                            <div class="row">
                                                                                <div class="col-md-12">
                                                                                  <input type="text" class="form-control form-control-sm" placeholder="Produit"  name="dosss1">
                                                                                  <div>
                                                                                    <br>
                                                                                      <table class="table_bloc_liste tableReseach">
                                                                                          <thead class="theadfixed">
                                                                                              <th>Produit</th>
                                                                                          </thead>
                                                                                          <tbody>
                                                                                              <tr class="SelectTable" >
                                                                                                  <td></td>
                                                                                              </tr>
                                                                                          </tbody>
                                                                                      </table>
                                                                                  </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                              </div>
                                                      </div>
                                                   
                                                  </div>
      
                                                  
                                                  <!--<div class="form-group col-md-6">
                                                      <label>Nom Client</label>
                                                      <input type="text" class="form-control form-control-sm" name="numdossier" autocomplete="off" placeholder="Nom Client" [(ngModel)] ="dossiertransfert.nom_societe" ng-disabled="true" disabled="disabled">
                                                  </div>-->
                                                  <div class="form-group col-md-6">
                                                      <label>Quantite*</label>
                                                   <input type="number" class="form-control form-control-sm" id="qte" name="qte" placeholder="Quantite"  autocomplete="off" >
                                                  </div>
                                                  <div class="form-group col-md-6">
                                                    <label>Poids*</label>
                                                    <input type="number" class="form-control form-control-sm" id="bllta" name="poids" placeholder="Poids"  autocomplete="off">
                                                  </div>
                                                  
                                              </div>
                                          </div>
                                          
                                  </div>
                                      </div>

                                      <br>
                                    <div class="x_panel x_panel_close">
                                        <div class="x_title">
                                              <h5>Produit Obtenu</h5>
                                              <ul class="nav navbar-right panel_toolbox" style="padding-left: px;"></ul>
                                              <div class="clearfix"></div>
                                        </div>
                                        <div class="x_content">
                                          <div class="col-md-12">
                                              <div class="row">
                                                  <div class="form-group col-md-6">
                                                          <label>Magasin*</label>
                                                          <select class="form-control form-control-sm" name="magasin" id="magasin" >
                                                              <option value=""></option>
                                                          </select>
                                                  </div>
                                                  <div class="form-group col-md-6">
                                                      <div class="persoautocomplete">
                                                          <label for="">N° Dossier</label>
                                                          <input type="text"  class="form-control form-control-sm" name="numeroDossier"  autocomplete="off" disabled="">
                                                          <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                                                          <div class="classPerso  dropdown-menu">
                                                                <form class="px-0 py-3">
                                                                    <div class="col-md-12">
                                                                        <div class="row">
                                                                            <div class="col-md-12">
                                                                              <input type="text"  class="form-control form-control-sm" placeholder="N°Dossier"  name="dosss1">
                                                                              <div>
                                                                                <br>
                                                                                  <table class="table_bloc_liste tableReseach">
                                                                                      <thead class="theadfixed">
                                                                                          <th>N°Dossier</th>
                                                                                      </thead>
                                                                                      <tbody>
                                                                                          <tr class="SelectTable" >
                                                                                              <td></td>
                                                                                          </tr>
                                                                                      </tbody>
                                                                                  </table>
                                                                              </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="form-group col-md-6">
                                                    <label>Navire</label>
                                                 <input type="text" class="form-control form-control-sm" id="qte" name="qte" placeholder="Navire"  autocomplete="off" [disabled]="true">
                                                </div>

                                                  <div class="col-md-6 form-group">
                                                      <label>Client*</label>
                                                      <select name="listeclientDossEntreeMag" id="listeclientDossEntreeMag" class="form-control form-control-sm">
                                                          <option value=""></option>
                                                      </select>
                                                  </div>
                                                  
                                                  <div class="form-group col-md-6">
                                                      <div class="persoautocomplete">
                                                              <!--<label for="">Produit</label>
                                                               <input type="text"  class="form-control form-control-sm" name="nomSousProduit" [(ngModel)] = "dossiertransfert.nomSousProduit" autocomplete="off" disabled="">
                                                                <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                </button>
                                                                <div class="col-md-12">
                                                                  <div class="classPerso dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                     <div class="col-md-12">
                                                                         <div class="row">
                                                                             <div class="col-md-12">
                                                                                 <label>Saisir Sous produits</label>
                                                                                  <input type="text" [(ngModel)] = "dossiertransfert.nomSousProduit1" class="form-control" placeholder="Sous Produit" (ngModelChange)="ListeAutoCompleteProduit(dossiertransfert.nomSousProduit1)">
                                                                                 <ul class="listeresultatsautcomplete">
                                                                                     <li *ngFor="let x of listautoSpecifik" (click)="SelectionAutocompleteProduit(x)">{{x.nomSousProduit}}</li>
                                                                                 </ul>
                                                                             </div>
                                                                         </div>
                                                                     </div>
                                                                  </div>
                                                                </div>-->
                                                              <label for="">Produit*</label>
                                                              <input type="text"  class="form-control form-control-sm" name="nomSousProduit"  autocomplete="off" disabled="">
                                                              <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                                                              <div class="classPerso  dropdown-menu">
                                                                    <form class="px-0 py-3">
                                                                        <div class="col-md-12">
                                                                            <div class="row">
                                                                                <div class="col-md-12">
                                                                                  <input type="text" class="form-control form-control-sm" placeholder="Produit"  name="dosss1">
                                                                                  <div>
                                                                                    <br>
                                                                                      <table class="table_bloc_liste tableReseach">
                                                                                          <thead class="theadfixed">
                                                                                              <th>Produit</th>
                                                                                          </thead>
                                                                                          <tbody>
                                                                                              <tr class="SelectTable" >
                                                                                                  <td></td>
                                                                                              </tr>
                                                                                          </tbody>
                                                                                      </table>
                                                                                  </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                              </div>
                                                      </div>
                                                   
                                                  </div>
      
                                                  
                                                  <!--<div class="form-group col-md-6">
                                                      <label>Nom Client</label>
                                                      <input type="text" class="form-control form-control-sm" name="numdossier" autocomplete="off" placeholder="Nom Client" [(ngModel)] ="dossiertransfert.nom_societe" ng-disabled="true" disabled="disabled">
                                                  </div>-->
                                                  <div class="form-group col-md-6">
                                                      <label>Quantite*</label>
                                                   <input type="number" class="form-control form-control-sm" id="qte" name="qte" placeholder="Quantite"  autocomplete="off" >
                                                  </div>
                                                  <div class="form-group col-md-6">
                                                    <label>Poids*</label>
                                                    <input type="number" class="form-control form-control-sm" id="bllta" name="poids" placeholder="Poids"  autocomplete="off">
                                                  </div>
                                                  
                                              </div>
                                          </div>
                                          
                                  </div>
                                      </div>
                                      <div class="row">
                                        <div class="col-sm-12 text-right mobile-buttons">
                                            <button class="btn btn-primary btn-enregistrer">
                                                <font style="vertical-align: inherit;">
                                                    <font style="vertical-align: inherit;">Enregistrer</font>
                                                </font>
                                            </button>
                                            &nbsp;
                                            <button class="btn btn-secondary btn-enregistrer">
                                                <font style="vertical-align: inherit;">
                                                    <font style="vertical-align: inherit;">Annuler</font>
                                                </font>
                                            </button>
                                    </div>
                                      </div>
                                    </div>

                                 <div class="tab-pane fade" id="profile" role="tabpanel"  aria-labelledby="profile-tab" [ngClass]="{'show':activeTab==='profile', 'active':activeTab==='profile'}">

                                 
                                 </div>

                                </div>
                          </div>
                    </div>

    </div>
</div>
