<div class="x_panel x_panel_close">
        <div class="x_content">
               <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;"><span ><i  class="fa fa-arrow-left"></i></span>
                   <div  class="ml-auto text-right"><span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span><i  class="fa fa-chevron-right"></i><span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Exploitation - Coûts Déchargements</span>
                   </div>
               </div>
                <ul class="nav nav-tabs bar_tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" id="saisir-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='saisir'}" (click)="search('saisir')"
                       role="tab" aria-controls="saisir" aria-selected="true">Saisir Coût Exploitation</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='profile'}" (click)="search('profile')"
                        role="tab" aria-controls="profile" aria-selected="false">Liste Coûts Exploitations</a>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div [ngClass]="{ 'show':activeTab==='saisir', 'active':activeTab==='saisir'}" class="tab-pane fade active show" id="saisir" role="tabpanel" aria-labelledby="saisir-tab">
                      <br>
                    <div class="x_panel_title x_panel_close">
                        <div class="x_title">
                          <h5>Informations Coût Exploitation</h5>
                          <ul class="nav navbar-right panel_toolbox" style="padding-left: 50px;">
                          </ul>
                          <div class="clearfix"></div>
                        </div>
                        <div class="x_content">
                          <div class="row">
                            <div class="col-md-4 form-group">
                              <div class="persoautocomplete">
                                <label for="">N°Dossier*</label>
                               <input [(ngModel)] = "dossierDechargement.numeroDossier" type="text" name="NumDoss" id="NumDoss" placeholder="N°Dossier"  class="form-control form-control-sm" autocomplete="off" (focus)="reInitSearch()" (blur)="reInitSearchBlur()" disabled="disabled">
                                 <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="">
                                 </button>
                                  <div class="classPerso  dropdown-menu">
                                    <form class="px-0 py-3">
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-12">
                                                  <input type="text" [(ngModel)]="dossierDechargement.numeroDossier1" class="form-control form-control-sm" placeholder="N°Dossier" name="NumDossSaisiChargesExp" (ngModelChange)="ListeAutoCompleteDossier(dossierDechargement.numeroDossier1)" autocomplete="off">
                                                  <div>
                                                    <br>
                                                      <table class="table_bloc_liste tableReseach">
                                                          <thead class="theadfixed">
                                                              <th>N°Dossier</th>
                                                          </thead>
                                                          <tbody>
                                                              <tr class="SelectTable"  *ngFor="let x of ListeAutoCompleteDoss" (click)="SelectionAutocomplete(x)">
                                                                  <td>{{x.numeroDossier}}</td>
                                                              </tr>
                                                          </tbody>
                                                      </table>
                                                  </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                  </div>
                              </div>
                            </div>
                            <div class="form-group col-xs-12 col-md-4">
                              <label for="Compagnie">Navire</label>
                              <input type="text" class="form-control form-control-sm" id="marque" placeholder="Navire" [disabled]="true" [ngModel] = "dossierDechargement.Navire">
                            </div>

                              <div class="form-group col-xs-12 col-md-4">
                                <label for="numero_dossier">Date coût Exploitation*</label>
                                <input type="date" class="form-control form-control-sm" id="date_budget" [(ngModel)] = "cout_exploitation.date_exploitation">
                              </div>
                          </div>
                        </div>

                     <br>
                     <div class="x_panel_title x_panel_close" style="width: 99%;margin: 0px auto;display: table;">
                        <div class="x_title">
                          <h5>Liste Element Coûts Exploitation</h5>
                          <ul class="nav navbar-right panel_toolbox" style="padding-left: 50px;">
                          </ul>
                          <div class="clearfix"></div>
                        </div>
                        <div class="x_content">
                          <div class="row">
                                  <div class="col-md-12">
                                  <div class="">
                                      <p>
                                        <button class="btn btn-sm btn-primary" (click)="ajouter()">
                                          <i class="fas fa-plus"></i>&nbsp;Nouveau
                                        </button>&nbsp;&nbsp;

                                      </p>
                                      <table class="table_mainoeuvre_fourniture table_bloc_liste">
                                            <thead>
                                              <tr >

                                                  <th>Categorie</th>
                                                  <th>Libelle</th>
                                                  <th style="width:100px;">Date</th>
                                                  <th style="width: 100px;">Prix unitaire</th>
                                                  <th style="width:100px;">Qte</th>
                                                  <th style="width:150px;">Coût</th>
                                                  <th></th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr *ngFor="let x of dataSousMenuModel;let i = index;">

                                                <td>
                                                  <select type="text" [(ngModel)] = "x.categorie" class="form-control form-control-sm" (ngModelChange)="updateDesignationCharge(x.id_elt_charge_exp, i)">
                                                    <option *ngFor = "let x of listeCategorieDintinct" value="{{x.libelle}}">{{x.libelle}}</option>
                                                  </select>
                                                </td>
                                                <td>
                                                    <input type="text" [(ngModel)] = "x.libelle" class="form-control form-control-sm" (ngModelChange)="UpdateCharge(x)">
                                                </td>
                                                <td>
                                                  <input type="date" [(ngModel)] = "x.date_creation" class="form-control form-control-sm text-center">
                                                </td>
                                                <td>
                                                  <input type="number" [(ngModel)] = "x.pu" class="form-control form-control-sm" (ngModelChange)="UpdateCharge(x)">
                                                </td>
                                                 <td>
                                                  <input type="number" [(ngModel)] = "x.qte" class="form-control form-control-sm"  (ngModelChange)="UpdateCharge(x)">
                                                </td>
                                                <td>
                                                  <input type="number" [(ngModel)] = "x.cout" class="form-control form-control-sm text-center" [disabled]="true">
                                                </td>

                                                <td class="text-center">
                                                  <button class="btn btn-danger btn-sm" type="button" (click)="supprimer_sous_menu(x.id_charge_operation, i)" title="supprimer">
                                                    <i class="fa fa-trash-alt" ></i>
                                                  </button>
                                                </td>
                                                </tr>
                                                <tr *ngIf="dataSousMenuModel.length > 0">
                                                  <td></td>
                                                  <td></td>
                                                  <td></td>
                                                  <td></td>
                                                  <td class="text-center">
                                                    <b>Coût total</b>
                                                  </td>
                                                  <td class="text-center">
                                                    <b>{{TotalChargeDechargement}}</b>
                                                  </td>
                                                </tr>
                                            </tbody>
                                      </table>
                                  </div>
                              </div>
                            </div>
                        </div>
                     </div>

                      <br>
                      <div class="col-sm-12 text-right mobile-buttons">
                          <button class="btn btn-primary btn-enregistrer" (click) = "Enregistrer($event)">
                              <font style="vertical-align: inherit;">
                                  <font style="vertical-align: inherit;">Enregistrer</font>
                              </font>
                          </button>
                          &nbsp;
                          <button class="btn btn-secondary btn-enregistrer" (click) = "Annuler()">
                              <font style="vertical-align: inherit;">
                                  <font style="vertical-align: inherit;">Annuler</font>
                              </font>
                          </button>
                      </div>

                    </div>


                     <br>


                 </div>
                    <div class="tab-pane fade" id="profile" role="tabpanel"  aria-labelledby="profile-tab" [ngClass]="{ 'show':activeTab==='profile', 'active':activeTab==='profile'}">
                        <br>
                        <div class="">
                  <div class="x_content">
                      <div class="form-group col-xs-12 col-md-12 recherche_liste">
                        <div class="row">
                                  <div class="form-group col-xs-12 col-md-3">
                                      <label for="Recherche_numero_dossier">Période du</label>
                                      <input type="date" id="Recherche_nom" [(ngModel)] = "recherche.date1"  class="form-control form-control-sm" placeholder="Navire"  (ngModelChange)="Recherche(recherche)" >
                                    </div>
                                  <div class="form-group col-xs-12 col-md-3">
                                      <label for="Recherche_navire">Au</label>
                                      <input type="date" id="Recherche_nom" [(ngModel)] = "recherche.date2"  class="form-control form-control-sm" placeholder="Navire"  (ngModelChange)="Recherche(recherche)" >
                                  </div>
                        </div>
                      </div>

                           <table class="table_bloc_liste">
                              <thead>
                                <tr>
                                  <th>Date Coût Exploitation</th>
                                  <th>N°Dossier</th>
                                  <th>Coût Total Exploitation</th>
                                  <th>Créer Par</th>
                                  <th>Créer Le</th>
                                  <th>Modifier Par</th>
                                  <th>Modifier Le</th>
                                  <th style="width:50px;"></th>
                                  <th style="width:50px;"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let n of listecharges" >
                                  <td>{{n.date_exploitation}}</td>
                                  <td>{{n.numeroDossier}}</td>
                                  <td>{{n.total_cout_exploitation}}</td>
                                  <td>{{n.nom}}</td>
                                  <td>{{n.date_creation}}</td>
                                  <td>{{n.modifierpar}}</td>
                                  <td>{{n.datemodification}}</td>
                                <td class="text-center">
                                      <button type="button" class="btn btn-success btn-sm" (click)="AfficherDonnerChargeExp(n.id_cout_exploitation)">
                                        <i class="fas fa-pencil-alt"></i>
                                      </button>
                                  </td>

                                  <td class="text-center">
                                     <button  class="btn btn-danger btn-sm" (click) = "SupprimerPopup(n.id_cout_exploitation)">
                                        <i class="far fa-trash-alt"></i>
                                     </button>
                                  </td>
                                </tr>
                              </tbody>
                    </table>

                    <div class="row">
                      <div  class="col-md-9 form-group"></div>
                      <div  class="col-md-3 form-group">
                        <div  class="form-group">
                          <label >&nbsp;</label>
                          <button  class="btn btn-success btn-block btn-sm" (click)="Exporter()">
                            <i  class="fas fa-excel"></i>&nbsp;Exporter</button>
                        </div>
                      </div>
                    </div>
                </div>
                        </div>
                   </div>
                </div>
            </div>
            <button type="button" class="btn btn-primary  btn-sm suppdossier" style="display:none;" data-backdrop="static" data-toggle="modal" data-target=".bs-Form-sm"></button>
            <div class="modal fade bs-Form-sm" tabindex="-1" role="dialog" aria-hidden="true">
             <div class="modal-dialog modal-sm" role="document">
              <div class="modal-content">
                  <div class="row container_modal">
                      <div class="modal-header">
                        <h6 class="modal-title" style="font-weight: bold;">Supprimer Coût Exploitation</h6>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div>
                         <div class="modal-content-dl">
                          <p>Souhaitez-vous supprimer cet Coût Exploitation ?</p>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button type="button" data-dismiss="modal"  class="btn btn-primary btn-sm" (click) = "SupprimerChargeExp()">Oui</button>
                        <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Non</button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
