import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';

@Component({
  selector: 'app-droitacces',
  templateUrl: './droitacces.component.html',
  styleUrls: ['./droitacces.component.css']
})
export class DroitaccesComponent implements OnInit {
  apiUrl:String = "";
  listegroupe:any = [];
  droitsaccess:any = {};
  listemenus:any = [];
  Elementliste:any = [];
  constructor(private transfertDataService : transfertDataService) { }

  ngOnInit(): void {
    this.listeGroupesUtilisateurs();
    this.listeMenuSousMenu();
  }
  
  listeGroupesUtilisateurs() : void{
    this.apiUrl = "SERVER/groupeware/droitsacces/listegroupe";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
          retryWhen(error =>
            error.pipe(
              scan((acc:any, error:any) => {
                if (acc > 2) throw error;
                return acc + 1;
              }, 1),
              delay(1000)
            )
          )
      ).subscribe((value:any) => {
        this.listegroupe = value.data;
    })
  }

  listeMenuSousMenu() : void{
    this.apiUrl = "SERVER/groupeware/droitsacces/listemenusousmenu";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
          retryWhen(error =>
            error.pipe(
              scan((acc:any, error:any) => {
                if (acc > 2) throw error;
                return acc + 1;
              }, 1),
              delay(1000)
            )
          )
      ).subscribe((value:any) => {
        this.listemenus = value.data;
    })
  }

  updateAccess(idgroupe:any) : void{
    this.apiUrl = "SERVER/groupeware/droitsacces/droitsaccesgroupe";
    var data = {"idgroupe":idgroupe};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
          retryWhen(error =>
            error.pipe(
              scan((acc:any, error:any) => {
                if (acc > 2) throw error;
                return acc + 1;
              }, 1),
              delay(1000)
            )
          )
      ).subscribe((value:any) => {
        for(var i = 0; i < this.listemenus.length; i++){
              if(this.listemenus[i].sous_menus.length == 0){
                  this.Elementliste[this.listemenus[i].id_menu+'_0'] = false;
                  if(this.Elementliste[this.listemenus[i].id_menu+'_0'] === undefined)
                    this.Elementliste[this.listemenus[i].id_menu+'_0'] = false;
              }else{
                  for(var j = 0; j < this.listemenus[i].sous_menus.length; j++){
                      this.Elementliste[this.listemenus[i].id_menu+'_'+this.listemenus[i].sous_menus[j].id_sous_menu] = false;
                  }
              }
        }

        for(var i = 0; i < value.data.length; i++){
              if(value.data[i].sous_menus.length == 0){
                  this.Elementliste[value.data[i].idmenu+'_0'] = true;
              }else{
                  for(var j = 0; j < value.data[i].sous_menus.length; j++){
                      this.Elementliste[value.data[i].idmenu+'_'+value.data[i].sous_menus[j].id_sous_menu] = true;
                  }
              }
        }
    })
  }

  ActiverDesactiver(id_menu:any, id_sous_menu:any, idgroupe:any){
    this.apiUrl = "SERVER/groupeware/droitsacces/enregistrer";
    var data = {"id_menu": id_menu, "id_sous_menu" : id_sous_menu, "idgroupe" : this.droitsaccess.idgroupe, "status" : this.Elementliste[id_menu+'_'+id_sous_menu]}
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
          retryWhen(error =>
            error.pipe(
              scan((acc:any, error:any) => {
                if (acc > 2) throw error;
                return acc + 1;
              }, 1),
              delay(1000)
            )
          )
      ).subscribe((value:any) => {

    })
  }

}
