<div class="x_panel x_panel_close">
        <div class="x_content">
               <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;"><span ><i  class="fa fa-arrow-left"></i></span>
                   <div  class="ml-auto text-right"><span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span><i  class="fa fa-chevron-right"></i><span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Gestion Déchargements - Programmer Déchargement</span>
                   </div>
               </div>
                <ul class="nav nav-tabs bar_tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" id="saisir-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='saisir'}" (click)="search('saisir')"
                       role="tab" aria-controls="saisir" aria-selected="true">Programmer Déchargement </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='profile'}" (click)="search('profile')"
                        role="tab" aria-controls="profile" aria-selected="false">Liste Déchargements Programmés</a>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div [ngClass]="{ 'show':activeTab==='saisir', 'active':activeTab==='saisir'}" class="tab-pane fade active show" id="saisir" role="tabpanel" aria-labelledby="saisir-tab">
                    	<br>
                        <div class="x_panel_title x_panel_close">
			               <div class="x_title">
			                 <h6>Informations déchargement</h6>
			                 <ul class="nav navbar-right panel_toolbox">
			                 </ul>
			                 <div class="clearfix"></div>
			               </div>
			               <div class="x_content">

			                         <div class="form-row">
			                            <div class="col-md-4 form-group">

			                               <div class="persoautocomplete">
			                                      <label for="">N°Dossier*</label>
			                                     <input [(ngModel)]="dechargementModel.numeroDossier" type="text" name="numdossFormPro" id="numdossFormPro" placeholder="N°Dossier"  class="form-control form-control-sm" autocomplete="off" (focus)="reInitSearchForm()" (blur)="reInitSearchBlurForm()" disabled="disabled">
			                                       <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="">
			                                       </button>
			                                        <div class="classPerso  dropdown-menu">
			                                          <form class="px-0 py-3">
			                                              <div class="col-md-12">
			                                                  <div class="row">
			                                                      <div class="col-md-12">
			                                                        <input type="text" [(ngModel)]="dossier.numeroDossier" class="form-control form-control-sm" placeholder="N°Dossier" name="FormSaisiDechargementPro" (ngModelChange)="ListeAutoCompleteDossier(dossier.numeroDossier)" autocomplete="off">
			                                                        <div>
			                                                          <br>
			                                                            <table class="table_bloc_liste tableReseach">
			                                                                <thead class="theadfixed">
			                                                                    <th>N°Dossier</th>
			                                                                </thead>
			                                                                <tbody>
			                                                                    <tr class="SelectTable"  *ngFor="let x of listdoss" (click)="SelectionAutocomplete(x)">
			                                                                        <td>{{x.numeroDossier}}</td>
			                                                                    </tr>
			                                                                </tbody>
			                                                            </table>
			                                                        </div>
			                                                      </div>
			                                                  </div>
			                                              </div>
			                                          </form>
			                                        </div>
			                                </div>

			                           </div>

			                           <div class="form-group col-xs-12 col-md-4">
			                             <label for="num_navire">Navire</label>
			                             <input type="text" class="form-control form-control-sm" id="num_navire" placeholder="Navire" [(ngModel)]="dechargementModel.Navire" [disabled]="true">

			                           </div>



			                           <div class="form-group col-xs-12 col-md-4">
			                               <label for="jour_nuit">Période de Travail*</label>
			                               <select id="jour_nuit" class="form-control form-control-sm" [(ngModel)]="dechargementModel.jour_nuit" placeholder="Port">
			                                 <option value="0" selected="selected">Jour</option>
			                                 <option value="1">Nuit</option>
			                               </select>
			                           </div>

			                           <div class="form-group col-xs-12 col-md-4">
			                             <label for="date_debut_operation">Date déchargement*</label>
			                             <input type="date" class="form-control form-control-sm" id="date_dechargement" placeholder="Date déchargement" [(ngModel)]="dechargementModel.debut_operation">
			                           </div>

			                           <div class="form-group col-xs-12 col-md-4">
			                             <label for="num_mini">N°Mini</label>
			                             <input type="text" class="form-control form-control-sm" id="num_mini" placeholder="N°Mini" [(ngModel)]="dechargementModel.num_mini">
			                           </div>

			                           <div class="form-group col-xs-12 col-md-4">
			                             <label for="quai">Quai</label>
			                               <select id="quai" class="form-control form-control-sm" placeholder="Quai" [(ngModel)]="dechargementModel.quai">
			                                 <option *ngFor = "let i of liste_quai" value="{{i.libelle}}">{{i.libelle}}</option>
			                                 </select>
			                           </div>


			                           <div class="form-group col-xs-12 col-md-4">
			                             <label for="acconier">Acconier</label>
			                             <select id="acconier" class="form-control form-control-sm"  placeholder="Acconier" [(ngModel)]="dechargementModel.acconier">
			                                 <option *ngFor = "let i of liste_acconier" value="{{i.libelle}}">{{i.libelle}}</option>
			                               </select>
			                           </div>

			                           <div class="form-group col-xs-12 col-md-4">
			                             <label for="Superviseur">Superviseur</label>
			                             <input type="text" class="form-control form-control-sm" id="Superviseur" placeholder="Superviseur" [(ngModel)]="dechargementModel.superviseur">
			                           </div>

			                           <div class="form-group col-xs-12 col-md-4">
			                             <label for="expert_client">Expert Client</label>
			                             <input type="text" class="form-control form-control-sm" id="expert_client" placeholder="Expert Client" [(ngModel)]="dechargementModel.expert_client">
			                           </div>

			                       		<div class="form-group col-xs-12 col-md-4">
			                             <label for="nb_equipage">Tonnage Prévu Cale 1</label>
			                             <input type="number" class="form-control form-control-sm" id="nb_equipage" placeholder="Tonnage Cale 1" [(ngModel)]="dechargementModel.tonnage_cale1">
			                           </div>

			                           <div class="form-group col-xs-12 col-md-4">
			                             <label for="nb_equipage">Tonnage Prévu Cale 2</label>
			                             <input type="number" class="form-control form-control-sm" id="nb_equipage" placeholder="Tonnage Cale 2" [(ngModel)]="dechargementModel.tonnage_cale2">
			                           </div>

			                           <div class="form-group col-xs-12 col-md-4">
			                             <label for="nb_equipage">Tonnage Prévu Cale 3</label>
			                             <input type="number" class="form-control form-control-sm" id="nb_equipage" placeholder="Tonnage Cale 3" [(ngModel)]="dechargementModel.tonnage_cale3">
			                           </div>

			                           <div class="form-group col-xs-12 col-md-4">
			                             <label for="nb_equipage">Tonnage Prévu Cale 4</label>
			                             <input type="number" class="form-control form-control-sm" id="nb_equipage" placeholder="Tonnage Cale 4" [(ngModel)]="dechargementModel.tonnage_cale4">
			                           	</div>

			                           	<div class="form-group col-xs-12 col-md-4">
			                             <label for="nb_equipage">Tonnage Prévu Cale 5</label>
			                             <input type="number" class="form-control form-control-sm" id="nb_equipage" placeholder="Tonnage Cale 5" [(ngModel)]="dechargementModel.tonnage_cale5">
			                           	</div>



			                           	<div class="form-group col-xs-12 col-md-4">
			                             <label for="Observation">Observation</label>
			                             <textarea class="form-control form-control-sm" id="Observation" placeholder="Observation" [(ngModel)]="dechargementModel.observation"></textarea>
			                           	</div>
			                           	<div class="col-md-12" *ngIf="duplicateDechargement == true">
                                         	<div class="alert alert-danger alert-dismissible fade show" role="alert">
												<strong>Alerte !</strong> {{message_error}}
													<button type="button" class="close" data-dismiss="alert" aria-label="Close">
												    <span aria-hidden="true">&times;</span>
												  </button>
												</div>
                                        </div>
			                           	<div class="col-sm-12 text-right mobile-buttons">
					                          <button class="btn btn-primary btn-enregistrer" (click)="programmerDechargement($event)">
					                              <font style="vertical-align: inherit;">
					                                  <font style="vertical-align: inherit;">Enregistrer</font>
					                              </font>
					                          </button>
					                          &nbsp;
					                          <button class="btn btn-secondary btn-enregistrer" (click) = "Annuler()">
					                              <font style="vertical-align: inherit;">
					                                  <font style="vertical-align: inherit;">Annuler</font>
					                              </font>
					                          </button>
                      					</div>
			                     </div>
			               </div>
		             	</div>
                 	</div>
                    <div class="tab-pane fade" id="profile" role="tabpanel"  aria-labelledby="profile-tab" [ngClass]="{ 'show':activeTab==='profile', 'active':activeTab==='profile'}">

		             	<br>

                      	<div class="x_content">
                      		<div class="col-md-12">
                      			<div class="recherche_liste row">
			                       <!--<div class="col-sm-12 col-md-3 ">
			                         <div class="form-group">
			                           <label for="">N°Dossier</label>
			                           <input type="text" id="" placeholder="N°DOSSIER" class="form-control form-control-sm" name="libe" [(ngModel)]="recherche.numeroDossier" (ngModelChange)="Recherche(recherche)">
			                         </div>
			                       </div>-->
			                       <div class="form-group col-xs-12 col-md-3">
                                      <div class="persoautocomplete">
                                      <label for="">Selectionner N°Dossier</label>
                                     <input [(ngModel)]="recherche.numeroDossier" type="text" name="numdossDRechPro" id="numdossDRechPro" placeholder="N°Dossier"  class="form-control form-control-sm" autocomplete="off" (focus)="reInitSearch()" (blur)="reInitSearchBlur()" disabled="disabled">
                                       <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="">
                                       </button>
                                        <div class="classPerso  dropdown-menu">
                                          <form class="px-0 py-3">
                                              <div class="col-md-12">
                                                  <div class="row">
                                                      <div class="col-md-12">
                                                        <input type="text" [(ngModel)]="recherche.NumDossSaisi" class="form-control form-control-sm" placeholder="N°Dossier" name="rechercheSaisiDNavire" (ngModelChange)="AutocompleteRecherche(recherche.NumDossSaisi)" autocomplete="off">
                                                        <div>
                                                          <br>
                                                            <table class="table_bloc_liste tableReseach">
                                                                <thead class="theadfixed">
                                                                    <th>N°Dossier</th>
                                                                </thead>
                                                                <tbody>
                                                                    <tr class="SelectTable"  *ngFor="let x of listedossierRecherche" (click)="selectionAutocompleteRecherche(x)">
                                                                        <td>{{x.numeroDossier}}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
			                       <div class="col-sm-12 col-md-3 ">
			                         <div class="form-group">
			                           <label for="">Date déchargement</label>
			                           <input type="date" id="" placeholder="jj/mm/aaaa" class="form-control form-control-sm" name="libe" [(ngModel)]="recherche.date" (ngModelChange)="Recherche(recherche)">
			                         </div>
			                       </div>

			                    </div>
                      		</div>
		                      	<br>
	                            <table class="table_bloc_liste">
	                                      <thead>
	                                          <tr>
	                                            <th>
	                                              Date
	                                            </th>
	                                            <th>
	                                              Période Travail
	                                            </th>
	                                            <th>
	                                              N°Dossier
	                                            </th>
	                                            <th>
	                                              Navire
	                                            </th>

	                                            <th>
	                                              Cale 1
	                                            </th>
	                                            <th>
	                                              Cale 2
	                                            </th>
	                                            <th>
	                                              Cale 3
	                                            </th>
	                                            <th>
	                                              Cale 4
	                                            </th>
	                                            <th>
	                                              Cale 5
	                                            </th>
	                                            <th>
	                                            	Tonnage prévu
	                                            </th>
	                                            <th>
	                                            	Tonnage Réalisé
	                                            </th>

	                                            <th>
	                                            	Performance
	                                            </th>
	                                            <th>
	                                            	Crée par
	                                            </th>
                                              <th>
                                                Modifier Par
                                              </th>
                                              <th>
                                                Modifier Le
                                              </th>
	                                            <th>
	                                            </th>
	                                            <th>
	                                            </th>
	                                          </tr>

	                                      </thead>
	                                      <tbody>
	                                       		<tr *ngFor="let x of programmation_dechargements">
	                                       			<td>
		                                              {{x.debut_operation}}
		                                            </td>
		                                            <td>
		                                              {{x.jour_nuit}}
		                                            </td>
		                                            <td>
		                                              {{x.numeroDossier}}
		                                            </td>
		                                            <td>
		                                              {{x.Navire}}
		                                            </td>

		                                            <td>
		                                              {{x.tonnage_cale1}}
		                                            </td>
		                                            <td>
		                                              {{x.tonnage_cale2}}
		                                            </td>
		                                            <td>
		                                              {{x.tonnage_cale3}}
		                                            </td>
		                                            <td>
		                                              {{x.tonnage_cale4}}
		                                            </td>
		                                            <td>
		                                              {{x.tonnage_cale5}}
		                                            </td>
		                                            <td>
		                                            	{{x.tonnageprevu}}
		                                            </td>
		                                            <td>
		                                            	{{x.total_poids_decharge}}
		                                            </td>

		                                            <td>
		                                            	{{x.performance}}
		                                            </td>
		                                            <td>
		                                            	{{x.nom}}
		                                            </td>
                                                <td>{{x.modifierpar}}</td>
                                                <td>{{x.datemodification}}</td>

		                                            <td class="text-center">
						                                <button  class="btn btn-success btn-sm" (click)="InfoProgrammtion(x.id_dechargelent)">
						                                  <i class="fas fa-pencil-alt"></i>
						                                </button>
						                              </td>
						                              <td class="text-center">
						                                <button  class="btn btn-danger btn-sm" (click)="SupprimerPopup(x.id_dechargelent)">
						                                   <i class="far fa-trash-alt"></i>
						                                </button>
						                              </td>
	                                       		</tr>
	                                  	  </tbody>
	                            </table>

	                            <div  class="row" *ngIf="programmation_dechargements.length > 0">
	                            	<div  class="col-md-6 form-group"></div>
	                            	<div  class="col-md-3 form-group">
	                            		<div  class="form-group">
	                            			<label >&nbsp;</label>
	                            			<button  class="btn btn-success btn-block btn-sm" (click)="Exporter()">
	                            				<i  class="fas fa-excel"></i>&nbsp;Exporter</button>
	                            		</div>
	                            	</div>
	                            	<div  class="col-md-3 form-group">
	                            		<div  class="form-group">
	                            			<label >&nbsp;</label>
	                            			<button  class="btn btn-warning btn-block btn-sm" (click)="Imprimer()"><i  class="fas fa-print"></i>&nbsp;Imprimer</button>
	                            		</div>
	                            	</div>
	                            </div>

	                 	</div>
                    </div>
                </div>
                </div>
            </div>
<button type="button" class="btn btn-primary  btn-sm suppliste" style="display:none;" data-backdrop="static" data-toggle="modal" data-target=".bs-Form-sm"></button>
<div class="modal fade bs-Form-sm" tabindex="-1" role="dialog" aria-hidden="true">
 <div class="modal-dialog modal-sm" role="document">
  <div class="modal-content">
      <div class="row container_modal">
          <div class="modal-header">
            <h6 class="modal-title" style="font-weight: bold;">SUPPRESSION</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>
            </button>
          </div>
          <div>
             <div class="modal-content-dl">
              <p>Souhaitez-vous supprimer ce enregistrement ?</p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" data-dismiss="modal"  class="btn btn-primary btn-sm" (click) = "supprimer(supp_id)">Oui</button>
            <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Non</button>
          </div>
      </div>
    </div>
  </div>
</div>
