export class CONTENUENAVIRE {
  public idContenuNavire: number = 0;
	public PortChargement: string = "";
	public date_arrive_prevu: string = "";
	public reference: string = "";
	public idClient: number = 0;
	public nom_societe: string = "";
	public idProduit: number = 0;
	public designationProduit: string = "";
	public PoidsNet: number = 0;
	public qte: number = 0;
	public cale: string = "";
	public iduser: number = 0;
	public idDossier: number = 0;
  public lieuDechargement: string = "";
	public date: string = "";

  constructor() {

  }

}
