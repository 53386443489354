export class historiquemvt {
  public idhistoriquemvt: number = 0;
  public idconteneurImport: number = 0;
  public idmvt: number = 0;
  public modelivraison: string = "";
  public datelivraison: string = "";
  public idcamion: number = 0;
  public idchauffeur: number = 0;
  public shift: string = "";
  public creerPar: number = 0;
  public creerLe: string = "";
  public modifierPar: number = 0;
  public modifierLe: string = "";
  public observation: string = "";

  constructor() {
  }

}
