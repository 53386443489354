export class MOUVEMENTRECUPCONTENEUR {
  public idMouvementConteneur: number = 0;
  public mouvementConteneur: string = "";
  public creerPar: number = 0;
  public creerLe: string = "";
  public modifierPar: number = 0;
  public modifierLe: string = "";
  
  constructor() {
  }

}
