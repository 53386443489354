export class operationescale {
  public idoperationEscale: number = 0;
  public libelle: string = "";
  public date: string = "";
  public idUser: number = 0;
  public modifierpar: number = 0;
  public datemodification: string = "";
  constructor() {
  }

}
