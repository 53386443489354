import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { pipe } from 'rxjs';
import { HttpClient, HttpHeaders, HttpEventType
 } from '@angular/common/http';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { AuthService } from '../../../services/connexion/auth.service';
import { CONTENEURIMPORT } from '../../../classes/conteneurimport';
import { ConfigurationService } from '../../../config/configuration.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-reception-conteneur',
  templateUrl: './reception-conteneur.component.html',
  styleUrls: ['./reception-conteneur.component.css']
})

export class ReceptionConteneurComponent implements OnInit {
  activeTab:String="saisir";
  activePagination:number = 0;
  liste_pagination:any = [];
  amateur:any = [];
  apiUrl:String = "";
  listeConteneurs:any = [];
  recherche:any = {};
  clientAutocoplet:any = {};
  AmateurAutocoplet:any = {};
  Liste_etat_stock:any=[];
  Liste_etat_amateur:any=[];
  Transitaireliste:any=[];
livraison:any=[];
  liste_ClientsRech:any=[];
  liste_AmateurRech:any=[];
  photos_previsualisation:any = [];
  array_files:any = [];
  supp_id:any = "";

  CONTENEURIMPORT:CONTENEURIMPORT = new CONTENEURIMPORT();

  constructor(private HttpClient : HttpClient,private transfertDataService : transfertDataService, private sanitizer:DomSanitizer, private AuthService : AuthService,private ConfigurationService : ConfigurationService) { }

  ngOnInit(): void {
    var date = new Date();
    this.ListeDesConteneurs();
    this.listeAmateur();
      this.listeTransitaire();
      this.listelivraison();
    this.recherche.numConteneur = "";
    this.recherche.dateFin = date.getFullYear()+"-"+("0" + (date.getMonth() + 1)).slice(-2)+"-"+this.DernierJourMoisenCours(date.getFullYear(),date.getMonth());
    this.recherche.dateDebut = date.getFullYear()+"-"+("0" + (date.getMonth() + 1)).slice(-2)+"-01";

    this.recherche.amateur = "";
    this.recherche.numBL = "";

  }

  DernierJourMoisenCours(y:any,m:any){
    return  new Date(y, m +1, 0).getDate();
  }
  listeTransitaire(){
    this.apiUrl = "SERVER/importlivraisonconteneur/receptionconteneur/liste_Transitaire";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((response:any) => {
         this.Transitaireliste = response.liste;

       }, (erreur:any) => {
           console.log(erreur.status);
       })

  }

  listelivraison(){
    this.apiUrl = "SERVER/importlivraisonconteneur/receptionconteneur/livraison";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((response:any) => {
         this.livraison = response.liste;

       }, (erreur:any) => {
           console.log(erreur.status);
       })

  }
  listeAmateur(){
    this.apiUrl = "SERVER/importretourconteneur/retour/nomAmateur";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((response:any) => {
         this.amateur = response.liste;

       }, (erreur:any) => {
           console.log(erreur.status);
       })

  }
  onFileChanged(event: any): void {
        this.array_files = [];
        var src = URL.createObjectURL(event.target.files[0]);
        var elements = event.target.files;
        for(var i = 0; i < elements.length; i++){
          var src = URL.createObjectURL(event.target.files[i]);
          this.photos_previsualisation.push({iddocument : '', src : this.sanitizer.bypassSecurityTrustUrl(src), lien : event.target.files[i]});
          this.array_files.push(event.target.files[i]);
        }
  }
  Annuler():void{
    this.CONTENEURIMPORT = new CONTENEURIMPORT();
    this.liste_ClientsRech = [];
    this.liste_AmateurRech = [];
    this.clientAutocoplet = {};
    this.AmateurAutocoplet.amateur= {};
    this.array_files = [];
    this.photos_previsualisation = [];

  }

  search(activeTab:String){
     this.activeTab=activeTab
   };


  Enregistrer(data:any, event:any): void{
    if(this.VerificationChampsObligatoire(["#amateur2","#numBL1","#numConteneur1","#client1","#transitoire1","#lieuLivraison","#BADI","#dateReception"]) == false){
      return;
    }
 event.target.disabled = true;
    var infoUser = this.AuthService.getInfoUtilisateur();

    if(this.CONTENEURIMPORT.idConteneurImport == 0){
      this.apiUrl = "SERVER/importlivraisonconteneur/receptionconteneur/enregistrer";
      this.CONTENEURIMPORT.creerPar = infoUser.id;

    }else{
      this.apiUrl = "SERVER/importlivraisonconteneur/receptionconteneur/modifier";
      this.CONTENEURIMPORT.modifierPar = infoUser.id;
    }


    this.CONTENEURIMPORT.idAmateur = Number(this.CONTENEURIMPORT.idAmateur);
    this.CONTENEURIMPORT.IDFactureConteneur = Number(this.CONTENEURIMPORT.IDFactureConteneur);
    this.CONTENEURIMPORT.facturer = Number(this.CONTENEURIMPORT.facturer);
    this.CONTENEURIMPORT.idCamion = Number(this.CONTENEURIMPORT.idCamion);
    this.CONTENEURIMPORT.idChauffeur = Number(this.CONTENEURIMPORT.idChauffeur);
    this.CONTENEURIMPORT.idAgent = Number(this.CONTENEURIMPORT.idAgent);
    this.CONTENEURIMPORT.idtransitaire = Number(this.CONTENEURIMPORT.idtransitaire);
    this.CONTENEURIMPORT.idlieuLivraison = Number(this.CONTENEURIMPORT.idlieuLivraison);
    this.CONTENEURIMPORT.idmodeLivraison = Number(this.CONTENEURIMPORT.idmodeLivraison);


    var d = {"conteneur": this.CONTENEURIMPORT};

      var mData = JSON.stringify(d);
      var formData = new FormData();

      formData.append('data', mData);
      formData.append('typedoc', '8');
      if (this.array_files.length > 0) {
        for (var i = 0; i < this.array_files.length; i++) {
            formData.append("file["+i+"]", this.array_files[i]);
        }
      }
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data; charset=utf-8; boundary=' + Math.random().toString().substr(2));
      headers.append('Accept', 'application/json');

      this.HttpClient.post<any>(this.ConfigurationService.configuration.hostname + this.apiUrl, formData, {headers}).subscribe((resp:any) => {
            event.target.disabled = false;
            this.ListeDesConteneurs();
            this.search("profile");
            this.Annuler();
      })
  }

  downloadDoc(iddossier:any){
    var apiUrl = "SERVER/importlivraisonconteneur/receptionconteneur/zip";
      var data = {iddossier:iddossier};
      this.transfertDataService.EnvoiData(data, apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
      ).subscribe((value:any) => {
         window.open(this.ConfigurationService.configuration.hostname + value.link , "_self")?.focus();
       }, (erreur:any) => {
      })
  }

  ListeDesConteneurs(): void{
    this.apiUrl = "SERVER/importlivraisonconteneur/receptionconteneur/liste";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((response:any) => {
         this.listeConteneurs = response.liste;
this.Calcule();
       }, (erreur:any) => {
           console.log(erreur.status);
       })

  }

  recherch(data:any): void{
     if(this.recherche.numConteneur == "" && this.recherche.dateDebut == "" && this.recherche.dateFin == "" && this.recherche.amateur == "" && this.recherche.numBL== "")
     this.ListeDesConteneurs();
     else{
       this.apiUrl = "SERVER/importlivraisonconteneur/receptionconteneur/recherche";
       var data = data;
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
     ).subscribe((value:any) => {

         this.listeConteneurs= value.data;
         this.Calcule();
       }, (erreur:any) => {
           console.log(erreur.status);
       })
     }

 }


  information(id:any):void{
    this.apiUrl = "SERVER/importlivraisonconteneur/receptionconteneur/information";
    var data = {id:id};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
      retryWhen(error =>
        error.pipe(
              scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((response:any) => {
              this.CONTENEURIMPORT = response.conteneur[0];
              this.clientAutocoplet.client1= response.conteneur[0].client;
              this.AmateurAutocoplet.amateur= response.conteneur[0].amateur;

              for(var i = 0; i < response.photos.length; i++){
                response.photos[i].src = this.ConfigurationService.configuration.ServerWebHostname + "/" + response.photos[i].src;
                this.photos_previsualisation = response.photos;
               }
              this.search("saisir");
           }, (erreur:any) => {
             console.log(erreur.status);
         })

     }

  SupprimerPopup(id:any): void{
    $(".suppliste").trigger("click");
    this.supp_id = id;
  }

  supprimer(id:any):void{
       this.apiUrl = "SERVER/importlivraisonconteneur/receptionconteneur/supprimer";
       var data = {id:id};
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
     ).subscribe((value:any) => {
         this.ListeDesConteneurs();
       }, (erreur:any) => {
           console.log(erreur.status);
       })

  }


  /*AUTOCOMPLET CLIENT*/

  reInitSearchFormClient(): void{
    this.liste_ClientsRech = [];
  }

  reInitSearchBlurFormClient(): void{
    setTimeout((val:any) => {
        this.liste_ClientsRech = [];
    }, 500);
  }

  ListeAutoCompleteClientrech(client:any) : void{

    this.apiUrl = "SERVER/importlivraisonconteneur/receptionconteneur/listeclientrecherche";
    var data = {client:client};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
      this.liste_ClientsRech = response.liste_client;
    }, (erreur:any) => {})

  }

  SelectionAutocompleterechClient(data:any): void{
    this.CONTENEURIMPORT.idClient= data.idClient;
    this.clientAutocoplet.client1= data.nom_societe;
    this.clientAutocoplet.clientR= data.nom_societe;
    this.CONTENEURIMPORT.contactClient= data.telephone;
  }

  /*AUTOCOMPLET NOM AMATEUR*/

  reInitSearchFormAmateur(): void{
    this.liste_AmateurRech = [];
  }

  reInitSearchBlurFormAmateur(): void{
    setTimeout((val:any) => {
        this.liste_AmateurRech = [];
    }, 500);
  }

  ListeAutoCompleteAmateurRech(amateur:any) : void{

    this.apiUrl = "SERVER/importlivraisonconteneur/receptionconteneur/listeamateurrecherche";
    var data = {amateur:amateur};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
      this.liste_AmateurRech = response.liste_amateur;
    }, (erreur:any) => {
    })

  }

  SelectionAutocompleteRechAmateur(data:any): void{
    this.CONTENEURIMPORT.idAmateur= data.idAmateur;
    this.AmateurAutocoplet.amateur= data.nomAmateur;
    this.AmateurAutocoplet.amateurR= data.nomAmateur;
  }
  SelectionAutocompleteRecheche(data:any): void{

    this.recherche.amateur = data.nomAmateur;
    if(this.recherche.amateurSaisi == "")
      this.recherche.amateur = "";
    this.recherch(this.recherche);
  }

  PaginationNb(index:number) : void{
  var data = {"index":index,  "data": this.recherche};
  this.apiUrl = "SERVER/importlivraisonconteneur/receptionconteneur/PaginationNb";
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
       retryWhen(error =>
         error.pipe(
           scan((acc:any, error:any) => {
             if (acc > 2) throw error;
             return acc + 1;
           }, 1),
           delay(1000)
         )
       )
    ).subscribe((value:any) => {
       this.listeConteneurs = value.data;

     }, (erreur:any) => {
    })

}

Calcule() : void{
  this.apiUrl = "SERVER/importlivraisonconteneur/receptionconteneur/CalculePagination";
    this.transfertDataService.EnvoiData(this.recherche, this.apiUrl).pipe(
       retryWhen(error =>
         error.pipe(
           scan((acc:any, error:any) => {
             if (acc > 2) throw error;
             return acc + 1;
           }, 1),
           delay(1000)
         )
       )
    ).subscribe((value:any) => {
       this.liste_pagination = value.data;
     }, (erreur:any) => {
    })
}

CurrentPagePagination(data:any, index:number, ev:any): void{
    this.activePagination = index;
    this.PaginationNb(data.index);
}
/**********************************************************************/

  /*CHAMP OBLIGATOIRE*/
  VerificationChampsObligatoire(tableau_champs_obligatoires:any){
    var verification_formulaire = true;
    for (var i = 0; i < tableau_champs_obligatoires.length; i++) {
        if($(tableau_champs_obligatoires[i]).val() == "" || $(tableau_champs_obligatoires[i]).val() == null || $(tableau_champs_obligatoires[i]).val() == "? string: ?"){
                $(tableau_champs_obligatoires[i]).css("border","1px solid red");
                verification_formulaire = false;
                document.getElementById(tableau_champs_obligatoires[i].replace("#",""))?.scrollIntoView({behavior: "smooth"});
                break;
        }else{
            $(tableau_champs_obligatoires[i]).css("border","");
        }
    }

    return verification_formulaire;
  }


  Exporter(): void{
      var lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/ImportLivraisonConteneur/excel_conteneur.php?numConteneur="+this.recherche.numConteneur+"&amateur="+this.recherche.amateur+"&numBL="+this.recherche.numBL+"&dateDebut="+this.recherche.dateDebut+"&dateFin="+this.recherche.dateFin;
        window.open(lien_fiche_ExpST , '_blank')?.focus();
  }


  ConfirmationImpressionFormulaire(){
         var lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/ImportLivraisonConteneur/pdfConteneur.php?numConteneur="+this.recherche.numConteneur+"&amateur="+this.recherche.amateur+"&numBL="+this.recherche.numBL+"&dateDebut="+this.recherche.dateDebut+"&dateFin="+this.recherche.dateFin;
       $("#printRapport").remove();
         $("body").append('<div id="printRapport">\
                             <div class="col-md-12 form-group" style="display:none;">\
                               <button class="btn btn-primary btn-sm bs-modal-frame-sm-btn" data-backdrop="static" data-toggle="modal" data-target=".bs-modal-frame-sm"></button>\
                             </div>\
                             <div class="modal fade bs-modal-frame-sm" tabindex="-1" role="dialog" aria-hidden="true">\
                               <div class="modal-dialog" role="document">\
                                   <div class="modal-content">\
                                       <div class="row container_modal" style="width:100%;">\
                                       <div class="modal-header" style="margin-bottom:0px;">\
                                         <h6 class="modal-title" style="font-weight: bold;">LISTE CONTENEUR DE LIVRAISON</h6>\
                                         <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>\
                                         </button>\
                                       </div>\
                                       <div class="row col-md-12" style="width:100%;height: calc(100vh - 90px);padding-left: 0px; padding-right: 0px; margin-left: 0px; margin-right: 0px;">\
                                         <div class="col-md-12 waiting">\
                                           <div class="snippet" data-title=".dot-pulse">\
                                             <div class="stage">\
                                               <div class="dot-pulse"></div>\
                                             </div>\
                                           </div>\
                                         </div>\
                                         <iframe class="rapportframe" name="rapportframe" style="width:100%;"></iframe>\
                                       </div>\
                                   </div>\
                                 </div>\
                               </div>\
                             </div>\
                         </div>');
         $(".bs-modal-frame-sm-btn").trigger("click");
         window.open(lien_fiche_ExpST , "rapportframe")?.focus();
         $(".rapportframe").css("display", "none");
         $('.rapportframe').on('load', function(){
             $(".waiting").css("display", "none");
             $(".rapportframe").css("display", "initial");
         });
  }
  
  apercu(iddocument:any): void{
    var element = <HTMLImageElement> $("#file_" + iddocument).get(0);
    element.requestFullscreen();
  }
  supprimer_image(nomfichier:any, iddocument:any) : void{
        if(iddocument == ""){
            for (var i = 0; i < this.array_files.length; i++) {

                if(this.array_files[i].name == nomfichier){
                    this.array_files.splice(i, 1);
                }
            }

            for (var i = 0; i < this.photos_previsualisation.length; i++) {
                if(this.photos_previsualisation[i].lien == nomfichier){
                    this.photos_previsualisation.splice(i, 1);
                }
            }
        }else{
            var apiUrl = "SERVER/GestionCompteEscale/proforma/SupprimerFile";
            var data = {id:iddocument};
            this.transfertDataService.EnvoiData(data, apiUrl).pipe(
               retryWhen(error =>
                 error.pipe(
                   scan((acc:any, error:any) => {
                     if (acc > 2) throw error;
                     return acc + 1;
                   }, 1),
                   delay(1000)
                 )
               )
            ).subscribe((value:any) => {
                for (var i = 0; i < this.photos_previsualisation.length; i++) {
                    if(this.photos_previsualisation[i].iddocument == iddocument){
                        this.photos_previsualisation.splice(i, 1);
                    }
                }
             }, (erreur:any) => {
            })
        }

  }

}
