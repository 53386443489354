<div class="x_panel x_panel_close">
        <div class="x_content">
            <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;">
                <span><i  class="fa fa-arrow-left"></i></span>
                <div  class="ml-auto text-right">
                    <span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span>
                          <i class="fa fa-chevron-right"></i>
                    <span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Gestion Magasins - Transferts Magasins
                    </span>
             </div>
            </div>
            <br>
               <div class="col-md-12">
                <div class="content-action">
                     <ul class="nav nav-tabs bar_tabs" id="myTab" role="tablist">
                              <li class="nav-item">
                                <a class="nav-link active" id="saisir-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='saisir'}" (click)="search('saisir')"
                                   role="tab" aria-controls="saisir" aria-selected="true">Nouveau Transfert Magasin</a>
                              </li>
                              <li class="nav-item">
                                <a class="nav-link" id="profile-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='profile'}" (click)="search('profile')"
                                    role="tab" aria-controls="profile" aria-selected="false">Liste Transferts Magasins</a>
                              </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div [ngClass]="{ 'show':activeTab==='saisir', 'active':activeTab==='saisir'}"
                            class="tab-pane fade active show" id="saisir" role="tabpanel" aria-labelledby="saisir-tab">

                              <br>
                               <div class="x_panel x_panel_close">
                                  <div class="x_title">
                                        <h5>Saisir Transfert Magasin</h5>
                                        <ul class="nav navbar-right panel_toolbox" style="padding-left: px;"></ul>
                                        <div class="clearfix"></div>
                                  </div>
                                  <div class="x_content">
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="form-group col-md-6">
                                                    <label>Magasin source*</label>
                                                    <select class="form-control form-control-sm" name="magasin" id="magasin" [(ngModel)] ="dossiertransfert.id_magasin_source">
                                                        <option *ngFor = "let x of liste_magasins" value="{{x.idMagasin}}">{{x.NomMagasin}}</option>
                                                    </select>
                                            </div>
                                            <div class="form-group col-md-6">
                                                <div class="persoautocomplete">
                                                    <label for="">N° Dossier</label>
                                                    <input type="text"  class="form-control form-control-sm" name="numeroDossier" [(ngModel)] = "dossiertransfert.numeroDossier" autocomplete="off" disabled="">
                                                    <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                                                    <div class="classPerso  dropdown-menu">
                                                          <form class="px-0 py-3">
                                                              <div class="col-md-12">
                                                                  <div class="row">
                                                                      <div class="col-md-12">
                                                                        <input type="text" [(ngModel)] = "dossiertransfert.numeroDossier1" class="form-control form-control-sm" placeholder="N°Dossier" (ngModelChange)="ListeAutoCompleteDossier(dossiertransfert.numeroDossier1)" name="dosss1">
                                                                        <div>
                                                                          <br>
                                                                            <table class="table_bloc_liste tableReseach">
                                                                                <thead class="theadfixed">
                                                                                    <th>N°Dossier</th>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr class="SelectTable" *ngFor="let x of listauto" (click)="SelectionAutocomplete(x)">
                                                                                        <td>{{x.numeroDossier}}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </form>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-6 form-group">
                                                <label>Client*</label>
                                                <select name="listeclientDossEntreeMag" id="listeclientDossEntreeMag" class="form-control form-control-sm">
                                                    <option value=""></option>
                                                </select>
                                            </div>
                                            
                                            <div class="form-group col-md-6">
                                                <div class="persoautocomplete">
                                                        <!--<label for="">Produit</label>
                                                         <input type="text"  class="form-control form-control-sm" name="nomSousProduit" [(ngModel)] = "dossiertransfert.nomSousProduit" autocomplete="off" disabled="">
                                                          <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                          </button>
                                                          <div class="col-md-12">
                                                            <div class="classPerso dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                               <div class="col-md-12">
                                                                   <div class="row">
                                                                       <div class="col-md-12">
                                                                           <label>Saisir Sous produits</label>
                                                                            <input type="text" [(ngModel)] = "dossiertransfert.nomSousProduit1" class="form-control" placeholder="Sous Produit" (ngModelChange)="ListeAutoCompleteProduit(dossiertransfert.nomSousProduit1)">
                                                                           <ul class="listeresultatsautcomplete">
                                                                               <li *ngFor="let x of listautoSpecifik" (click)="SelectionAutocompleteProduit(x)">{{x.nomSousProduit}}</li>
                                                                           </ul>
                                                                       </div>
                                                                   </div>
                                                               </div>
                                                            </div>
                                                          </div>-->
                                                        <label for="">Produit*</label>
                                                        <input type="text"  class="form-control form-control-sm" name="nomSousProduit" [(ngModel)] = "dossiertransfert.nomSousProduit" autocomplete="off" disabled="">
                                                        <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                                                        <div class="classPerso  dropdown-menu">
                                                              <form class="px-0 py-3">
                                                                  <div class="col-md-12">
                                                                      <div class="row">
                                                                          <div class="col-md-12">
                                                                            <input type="text" [(ngModel)] = "dossiertransfert.nomSousProduit1" class="form-control form-control-sm" placeholder="Produit" (ngModelChange)="ListeAutoCompleteProduit(dossiertransfert.nomSousProduit1)" name="dosss1">
                                                                            <div>
                                                                              <br>
                                                                                <table class="table_bloc_liste tableReseach">
                                                                                    <thead class="theadfixed">
                                                                                        <th>Produit</th>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr class="SelectTable" *ngFor="let x of listautoSpecifik" (click)="SelectionAutocompleteProduit(x)">
                                                                                            <td>{{x.nomSousProduit}}</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              </form>
                                                        </div>
                                                </div>
                                             
                                            </div>

                                            
                                            <!--<div class="form-group col-md-6">
                                                <label>Nom Client</label>
                                                <input type="text" class="form-control form-control-sm" name="numdossier" autocomplete="off" placeholder="Nom Client" [(ngModel)] ="dossiertransfert.nom_societe" ng-disabled="true" disabled="disabled">
                                            </div>-->
                                            <div class="form-group col-md-6">
                                                <label>Quantite*</label>
                                             <input type="number" class="form-control form-control-sm" id="qte" name="qte" placeholder="Quantite" [(ngModel)] ="dossiertransfert.quantite" autocomplete="off" (ngModelChange)="PoidsTotalCalcule()">
                                            </div>
                                            <div class="form-group col-md-6">
                                              <label>Poids*</label>
                                              <input type="number" class="form-control form-control-sm" id="bllta" name="poids" placeholder="Poids" [(ngModel)] ="dossiertransfert.poids" autocomplete="off">
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label>BL LTA</label>
                                                <input type="text" class="form-control form-control-sm" id="bllta" name="bllta" placeholder="BL LTA" [(ngModel)] ="dossiertransfert.bl" autocomplete="off">
                                            </div>
                                            <!--<div class="form-group col-md-6">
                                              <label>Provenance</label>
                                              <input type="text" class="form-control form-control-sm" id="provenance" name="provenance" placeholder="Provenance" [(ngModel)] ="dossiertransfert.provenance" autocomplete="off">
                                            </div>-->
                                            <div class="form-group col-md-6">
                                              <label>Date Mouvement*</label>
                                              <input type="date" class="form-control form-control-sm" id="date_mvt" name="date_mvt" placeholder="Date Mouvement" [(ngModel)] ="dossiertransfert.date_mvt" autocomplete="off">
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label>Magasin Destination*</label>
                                                 <select class="form-control form-control-sm" name="magasin" id="magasin" [(ngModel)] ="dossiertransfert.id_magasin_destination">
                                                     <option *ngFor = "let x of liste_magasins" value="{{x.idMagasin}}">{{x.NomMagasin}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    
                            </div>
                                </div>
                       <div class="x_panel x_panel_close">
                              <div class="x_title">
                                    <h5>Gestion Electronique des Document</h5>
                                    <ul class="nav navbar-right panel_toolbox" style="padding-left: 50px;"></ul>
                                    <div class="clearfix"></div>
                              </div>
                             <div class="col-md-12 text-center">
                                   <div class="animate form login_form">
                                        <section class="login_content">
                                          <form class="ng-pristine ng-valid">
                                            <br><br>  
                                            <p class="title">GESTION ELECTRONIQUE DE DOCUMENTS</p>
                                            <button class=" btn btn-warning btn-sm" ng-click="triggerFaireCapture()">
                                              <i class="fa fa-camera"> </i>&nbsp;&nbsp;<span>Faire une photo</span>
                                            </button>
                                            &nbsp;&nbsp;
                                            <button class=" btn btn-info btn-sm" ng-click="triggerSelection()">
                                              <i class="fa fa-file-o"> </i>&nbsp;&nbsp;<span>Sélectionner un fichier</span>
                                            </button>
                                            <br>
                                          </form>
                                        </section>
                                   </div>
                            </div>
                            <br>
                       </div>
                       <div class="col-sm-12 text-right mobile-buttons">
                                <button class="btn btn-primary btn-enregistrer" (click) = "Enregistrer(dossiertransfert)">
                                    <font style="vertical-align: inherit;">
                                        <font style="vertical-align: inherit;">Enregistrer</font>
                                    </font>
                                </button>
                                &nbsp;
                                <button class="btn btn-secondary btn-enregistrer" (click) = "Annuler()">
                                    <font style="vertical-align: inherit;">
                                        <font style="vertical-align: inherit;">Annuler</font>
                                    </font>
                                </button>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="profile" role="tabpanel"  aria-labelledby="profile-tab" [ngClass]="{'show':activeTab==='profile', 'active':activeTab==='profile'}">
                            <br>
                            <div class="x_panel">
                              <div class="x_content">
                                    <div class="form-group col-xs-12 col-md-12 recherche_liste">
                                            <div class="row">
                                                 <div class="form-group col-xs-12 col-md-4">
                                                          <label for="Recherche_numeroDossier">Rechercher par</label>
                                                          <input type="text" id="Recherche_numeroDossier" [(ngModel)] ="recherche.numeroDossier" class="form-control form-control-sm" placeholder="N° Dossier" autocomplete="off" (ngModelChange) ="recherche(recherche)" >
                                                  </div>
                                                  <div class="form-group col-xs-12 col-md-4">
                                                          <label for="Recherche_client">&nbsp;</label>
                                                          <input type="text" id="Recherche_client" [(ngModel)] ="recherche.id_client" class="form-control form-control-sm" placeholder="Nom client" (ngModelChange) ="recherche(recherche)" autocomplete="off" >
                                                  </div>
                                                  <div class="form-group col-xs-12 col-md-4">
                                                          <label for="Recherche_sous_produit">&nbsp;</label>
                                                          <input type="text" id="Recherche_sous_produit" [(ngModel)] ="recherche.id_sous_produit" class="form-control form-control-sm" placeholder="Nom sous produit" autocomplete="off" (ngModelChange) ="recherche(recherche)" >
                                                  </div>
                                            </div>  
                                               
                                        </div>
                                       <table class="table_bloc_liste">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>
                                                        Magasin
                                                    </th>
                                                    <th>
                                                       Nom produit
                                                    </th>
                                                    <th>
                                                        N°Dossier
                                                    </th>
                                                    <th>
                                                        Client
                                                    </th>
                                                    <th>
                                                        Qte
                                                    </th>
                                                    <th>
                                                        Poids
                                                    </th>

                                                    <th>
                                                    </th>
                                                    <th>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor = "let i of Listetrsfertmag">
                                                    <td>{{i.id_produit_dispo_magasin}}</td>
                                                    <td>{{i.NomMagasin}}</td>
                                                    <td>{{i.nom_produit_magasin}}</td>
                                                    <td>{{i.numeroDossier}}</td>
                                                    <td>{{i.nom_societe}}</td>
                                                    <td>{{i.qte_dispo}}</td>
                                                    <td>{{i.poids_dispo}}</td>
                                                    <td class="text-center">
                                                        <button type="button" class="btn btn-info btn-sm" (click)="AfficherDonner(i.id_produit_dispo_magasin, i.id_magasin)"><i class="fas fa-edit"></i></button>
                                                    </td>
                                                    <td class="text-center">
                                                        <button type="button" class="btn btn-danger btn-sm" (click)="Supprimer(i.id_produit_dispo_magasin)"><i class="far fa-trash-alt"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                  </div>
                                </div>
                         </div>

                        </div>
                  </div>
            </div>

        </div>
</div>
