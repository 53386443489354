import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-etat-booking',
  templateUrl: './etat-booking.component.html',
  styleUrls: ['./etat-booking.component.css']
})
export class EtatBookingComponent implements OnInit {

  activeTab:String="saisir";

  constructor() { }

  ngOnInit(): void {
  }

  search(activeTab:String){
    this.activeTab=activeTab
  }

}
