<div class="x_panel x_panel_close">
    <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;"><span ><i  class="fa fa-arrow-left"></i></span><div  class="ml-auto text-right"><span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span><i  class="fa fa-chevron-right"></i><span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Gestion Stock</span>
        </div>
    </div>
    <div class="x_content">
      <div class="col-md-12">
                   <div class="recherche_liste">
                    <div class="row">
                      <div class="col-sm-12 col-md-4">
                        <div class="form-group">
                          <label for="">Référence Produit</label>
                          <input type="text" id="" placeholder="Référence Produit" [(ngModel)] = "recherche.numProduit" class="form-control form-control-sm" (ngModelChange) = "Recherche()">
                        </div>
                      </div>
                     <div class="form-group col-md-4">
                          <label for="">Nom Produit</label>
                          <input type="text" id="" placeholder="Nom Produit" [(ngModel)] = "recherche.NomProd" class="form-control form-control-sm" (ngModelChange) = "Recherche()">
                        </div>
                    </div>
                   </div>
                   <br>
                    <div class="col-md-12">
                        <table class="table_bloc_liste">
                          <thead>
                            <th>Référence Produit</th>
                            <th>Nom Produit</th>
                            <th>Founisseur</th>
                            <th>Quantité Disponible</th>
                            <th>Créer Par</th>
                            <th>Créer Le</th>
                            <th>Modifier Par</th>
                            <th>Modifier Le</th>
                          </thead>
                          <tbody>
                          <tr  *ngFor = "let x of liste">
                              <td>{{x.numProduit}}</td>
                              <td>{{x.NomProd}}</td>
                              <td>{{x.nomfourn}}</td>
                              <td>{{x.qteDispo}}</td>
                              <td>{{x.nom}}</td>
                              <td>{{x.dateCreation}}</td>
                              <td>{{x.modifierpar}}</td>
                              <td>{{x.datemodification}}</td>

                          </tr>
                          </tbody>
                        </table>

                    </div>

      </div>
       <div class="text-right mobile-buttons col-sm-12 AccessBtnEnr">
                   <div class="text-right">
                      <br><br>
                        <button  class="btn btn-success  btn-sm btn-enregistrer" (click) = "ExporterExcel()">Exporter Excel</button>&nbsp;&nbsp;
                        <button  class="btn btn-warning btn-sm btn-annuler" (click) = "ConfirmationImpressionFormulaire()">Imprimer</button>
                    </div>
                </div>
    </div>
</div>
