import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { ConfigurationService } from '../../../config/configuration.service';


@Component({
  selector: 'app-etat-stock-magasins',
  templateUrl: './etat-stock-magasins.component.html',
  styleUrls: ['./etat-stock-magasins.component.css']
})
export class EtatStockMagasinsComponent implements OnInit {
  apiUrl:String = "";
  activeTab:String="saisir";
  listmagasin:any = [];
  recherche:any={};
  listdoss:any=[];
  listclient:any=[];
  listprod:any=[];
  Liste_etat_stock:any=[];
  liste_prodmagasins:any = [];
  lien_fiche_ExpST:any;
  liste_ProduitsRech:any = [];
  liste_ClientsRech:any = [];
  liste_magasins:any = [];
  
  constructor(private transfertDataService : transfertDataService,  private ConfigurationService : ConfigurationService) { }

 
  ngOnInit(): void {
    
    this.recherche.iddossier="";
    this.recherche.idclient="";
    this.recherche.nom_produit_magasin="";
    this.recherche.idMagasin="";
    this.listemagasin();
    this.listeStockProduit();

  }
  search(activeTab:String){
    this.activeTab=activeTab
  };

  listeStockProduit() : void{

    this.apiUrl = "SERVER/gestionmagasin/etatdestockmagasin/listeStockProduit";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
      this.Liste_etat_stock = response.Liste_etat_stock;
    }, (erreur:any) => {
    })

  }

  listemagasin() : void{

    this.apiUrl = "SERVER/gestionmagasin/sortiemagasin/listemagasin";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
      this.liste_magasins = response.liste_magasin;
    }, (erreur:any) => {
    })

  }

  listeProduitmag(): void{
    this.apiUrl = "SERVER/gestionmagasin/sortiemagasin/listeproduitmag";
    var data = {};


    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
      this.liste_prodmagasins = response.liste_prodmagasins;
    }, (erreur:any) => {
    })
  }

  RechercheTabListe(data:any): void{
    if(this.recherche.iddossier == "" && this.recherche.idclient == "" && this.recherche.nom_produit_magasin == "" && this.recherche.idMagasin == "" ){
      this.listeStockProduit();
    }else{
      this.apiUrl = "SERVER/gestionmagasin/etatdestockmagasin/recherche";
      var data = data;

      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {

       this.Liste_etat_stock= value.Liste_etat_stock;
      }, (erreur:any) => {
      })
    }

  }

  ListeAutoCompleteDossierrech(numDoss:any){
    this.apiUrl = "SERVER/gestionmagasin/entremagasin/recherche_magasinrech";
       var data = {NumDoss:numDoss};
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
     ).subscribe((value:any) => {
         this.listdoss= value.listautorech;

       }, (erreur:any) => {
       })
   }
   SelectionAutocompleterech(data:any): void{
    this.recherche.iddossier= data.iddossier;
    this.recherche.numeroDossier= data.numeroDossier;
    this.recherche.numeroDossier1= data.numeroDossier;
    this.RechercheTabListe(this.recherche);

  }

  ListeAutoCompleteProduitrech(produit:any) : void{

    this.apiUrl = "SERVER/gestionmagasin/entremagasin/listeAutocompleteProduitRecherche";
    var data = {produit:produit};


    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
      this.liste_ProduitsRech = response.listautoSpecifiks;


    }, (erreur:any) => {
    })

  }

  ListeAutoCompleteClientrech(client:any) : void{

    this.apiUrl = "SERVER/gestionmagasin/entremagasin/listeclientrecherche";
    var data = {client:client};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
      this.liste_ClientsRech = response.liste_client;
    }, (erreur:any) => {
    })

  }

  reInitSearchForm(): void{
    this.listdoss = [];
  }

  reInitSearchBlurForm(): void{
    setTimeout((val:any) => {
        this.listdoss = [];
    }, 500);
  }

  SelectionAutocompleterechClient(data:any): void{
    this.recherche.idclient= data.idClient;
    this.RechercheTabListe(this.recherche);
  }

  SelectionAutocompleterechProduit(data:any): void{
    this.recherche.nom_produit_magasin= data.nom_produit_magasin;
    this.RechercheTabListe(this.recherche);

  }

  reInitSearchFormEntreeProduit(): void{
    this.liste_ProduitsRech = [];
  }
  
  reInitSearchBlurFormEntreeProduit(): void{
    setTimeout((val:any) => {
        this.liste_ProduitsRech = [];
    }, 500);
  }

   reInitSearchFormClient(): void{
    this.liste_ClientsRech = [];
  }

  reInitSearchBlurFormClient(): void{
    setTimeout((val:any) => {
        this.liste_ClientsRech = [];
    }, 500);
  }
  EtatExcel(){
    this.lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/Reporting/Excelficheetatstockmag.php?iddossier="+this.recherche.iddossier+"&idclient="+this.recherche.idclient+"&nom_produit_magasin="+encodeURI(this.recherche.nom_produit_magasin)+"&idMagasin="+this.recherche.idMagasin;
    window.open(this.lien_fiche_ExpST , '_blank')?.focus();
  }

  EtatPdf(){
      this.lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/Reporting/pdf_Reportingficheetatstockmag.php?iddossier="+this.recherche.iddossier+"&idclient="+this.recherche.idclient+"&nom_produit_magasin="+encodeURI(this.recherche.nom_produit_magasin)+"&idMagasin="+this.recherche.idMagasin;
         $("#printRapport").remove();
        $("body").append('<div id="printRapport">\
                           <div class="col-md-12 form-group" style="display:none;">\
                             <button class="btn btn-primary btn-sm bs-modal-frame-sm-btn" data-backdrop="static" data-toggle="modal" data-target=".bs-modal-frame-sm"></button>\
                           </div>\
                           <div class="modal fade bs-modal-frame-sm" tabindex="-1" role="dialog" aria-hidden="true">\
                             <div class="modal-dialog" role="document">\
                                 <div class="modal-content">\
                                     <div class="row container_modal" style="width:100%;">\
                                     <div class="modal-header" style="margin-bottom:0px;">\
                                       <h6 class="modal-title" style="font-weight: bold;">ETAT STOCK MAGASIN</h6>\
                                       <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>\
                                       </button>\
                                     </div>\
                                     <div class="row col-md-12" style="width:100%;height: calc(100vh - 90px);padding-left: 0px; padding-right: 0px; margin-left: 0px; margin-right: 0px;">\
                                       <div class="col-md-12 waiting">\
                                         <div class="snippet" data-title=".dot-pulse">\
                                           <div class="stage">\
                                             <div class="dot-pulse"></div>\
                                           </div>\
                                         </div>\
                                       </div>\
                                       <iframe class="rapportframe" name="rapportframe" style="width:100%;"></iframe>\
                                     </div>\
                                 </div>\
                               </div>\
                             </div>\
                           </div>\
                       </div>');
        $(".bs-modal-frame-sm-btn").trigger("click");
        window.open(this.lien_fiche_ExpST , "rapportframe")?.focus();
        $(".rapportframe").css("display", "none");
        $('.rapportframe').on('load', function(){
           $(".waiting").css("display", "none");
           $(".rapportframe").css("display", "initial");
        });
  }



}
