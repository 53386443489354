export class CONTENEURTRAITE {
  public idConteneurTraite: number = 0;
  public dateTraite: string = "";
  public lieuConteneur: string = "";
  public idAgent: number = 0;
  public dateTransmis: string = "";
  public creerPar: number = 0;
  public creerLe: string = "";
  public modifierPar: number = 0;
  public modifierLe: string = "";

  constructor() {
  }

}
