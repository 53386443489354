import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { Menu } from './menu';
import { Sousmenu } from './sousmenu';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { AuthService } from '../../../services/connexion/auth.service';

@Component({
  selector: 'app-creer-menu',
  templateUrl: './creer-menu.component.html',
  styleUrls: ['./creer-menu.component.css']
})
export class CreerMenuComponent implements OnInit {
  apiUrl:String = "";
  liste_sous_menu:any = [];
  rechercheObj:any = {};
  liste_menus:any = [];
  dataSousMenuModel:any = [];
  activeTab:String="saisir";

  MenuModel:Menu = new Menu();
  SousMenuModel:Sousmenu = new Sousmenu();
  constructor(private AuthService : AuthService,private transfertDataService : transfertDataService) {

  }

  ngOnInit(): void {
      this.dataSousMenuModel = [];
      this.ajouter();
      this.listeMenus();
  }

  ajouter(): void{
    var sousmenu = new Sousmenu();
    this.dataSousMenuModel.push(sousmenu);
  }

  Tabulation(activeTab:any): void{
    this.activeTab=activeTab;
  }

  Annuler(){
    this.MenuModel = new Menu();
    this.dataSousMenuModel = [];
  }

  Enregistrer(data:any): void{
    if(this.MenuModel.id_menu == 0)
      this.apiUrl = "SERVER/groupeware/menu/enregistrer";
    else
      this.apiUrl = "SERVER/groupeware/menu/modifier";
      var infoUser = this.AuthService.getInfoUtilisateur();
      this.MenuModel.idUser = infoUser.id;
    var d = {"menu": this.MenuModel, "sousmenu" : this.dataSousMenuModel};
    this.transfertDataService.EnvoiData(d, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
      this.listeMenus();
      this.Annuler();
      this.Tabulation("profile");
    }, (erreur:any) => {
        console.log(erreur.status);
    })
  }

  AfficherDonner(idmenu:any): void{
      this.apiUrl = "SERVER/groupeware/menu/information";
      var data = {id_menu:idmenu};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
          this.MenuModel = response.menu[0];
          this.dataSousMenuModel = response.sousmenu;
          this.MenuModel.ordre = Number(response.menu[0].ordre);
          this.dataSousMenuModel.ordre = Number(response.sousmenu.ordre);
          this.Tabulation("saisir");
      }, (erreur:any) => {
        console.log(erreur.status);
    })
  }

  enregistrer_sous_menu(sousmenu:any): void{
      console.log(sousmenu.id_sous_menu);
      if(sousmenu.id_sous_menu != 0){
        var data = sousmenu;
        data.id_menu = this.MenuModel.id_menu;
        this.apiUrl = "SERVER/groupeware/sousmenu/modifier";
      }else{
        var data = sousmenu;
        data.id_menu = this.MenuModel.id_menu;
        this.apiUrl = "SERVER/groupeware/sousmenu/enregistrer";

      }

      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
      ).subscribe((value:any) => {
        this.listeSousMenuMenus();
      }, (erreur:any) => {
          console.log(erreur.status);
      })


  }

  supprimer_sous_menu(idsousmenu:any): void{
      this.apiUrl = "SERVER/groupeware/sousmenu/supprimer";
      var data = {id:idsousmenu};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listeSousMenuMenus();
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }


  SupprimerMenu(idmenu:any): void{
      this.apiUrl = "SERVER/groupeware/menu/supprimer";
      var data = {id:idmenu};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listeMenus();
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }

  listeMenus(): void{
    this.apiUrl = "SERVER/groupeware/menu/listemenu";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
      this.liste_menus = response.liste_menu;
    }, (erreur:any) => {
        console.log(erreur.status);
    })
  }

  listeSousMenuMenus(): void{
    this.apiUrl = "SERVER/groupeware/sousmenu/listesousmenu";
    var data = {id_menu:this.MenuModel.id_menu};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
      this.dataSousMenuModel = response.liste_sous_menu;
    }, (erreur:any) => {
        console.log(erreur.status);
    })
  }

  Recherche(data:any): void{
      this.apiUrl = "SERVER/groupeware/menu/recherche";
      var data = data;
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.liste_menus= value.liste_menu;
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }

}
