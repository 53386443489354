import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType
 } from '@angular/common/http';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { ConfigurationService } from '../../../config/configuration.service';
import { eltcoutexploitation } from '../../../classes/eltcoutexploitation';
import { cout_exploitation } from '../../../classes/cout_exploitation';
import { AuthService } from '../../../services/connexion/auth.service';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';


@Component({
  selector: 'app-saisir-charges-navire',
  templateUrl: './saisir-charges-navire.component.html',
  styleUrls: ['./saisir-charges-navire.component.css']
})
export class SaisirChargesNavireComponent implements OnInit {
  activeTab:String="saisir";
  apiUrl:String = "";
  eltcoutexploitation:eltcoutexploitation = new eltcoutexploitation();
  cout_exploitation:cout_exploitation = new cout_exploitation();
  recherche:any = {};
    suppelt:any = {};
  TotalChargeDechargement:any = 0;
  listeCategorie:any = [];
  listeCategorieDintinct:any = [];
  dossierDechargement:any = {};
  dataSousMenuModel:any = [];
  listecharges:any = [];
  ListeAutoCompleteDoss:any = [];
  ligneSelectionne:any = -1;
  precLine:any = 0;


  constructor(private AuthService : AuthService,private transfertDataService : transfertDataService, private ConfigurationService : ConfigurationService, private HttpClient : HttpClient) { }

  ngOnInit(): void {
    this.recherche.date1 = "";
    this.recherche.date2 = "";

    this.ListeElementsExp();
    this.ListeElementsExpDistin();

    this.ListeExploitation();
  }

  search(activeTab:String){
     this.activeTab=activeTab;
  }
  Exporter(): void{
      var lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/Exploitation/excel_CoutExp.php?date1="+this.recherche.date1+"&date2="+this.recherche.date2;
        window.open(lien_fiche_ExpST , '_blank')?.focus();
  }
  ListeElementsExp():void{
      var api = "SERVER/exploitation/saisircharges/listeEltCharges";
      var data = {};
      this.transfertDataService.EnvoiData(data, api).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listeCategorie= value.listeEltCharges;
      }, (erreur:any) => {
      })
  }
  ListeElementsExpDistin():void{
      var api = "SERVER/exploitation/saisircharges/listeEltChargesDistin";
      var data = {};
      this.transfertDataService.EnvoiData(data, api).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listeCategorieDintinct= value.listeEltCharges;
      }, (erreur:any) => {
      })
  }

  ListeExploitation(){
      this.apiUrl = "SERVER/exploitation/saisircharges/listeExp";
      var data = {};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listecharges= value.listecharges;
      }, (erreur:any) => {
      })
  }

  ListeAutoCompleteDossier(numdoss:any){
   this.apiUrl = "SERVER/exploitation/saisircharges/ListeAutoCompleteDossier";
      var data = {numdoss:numdoss};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.ListeAutoCompleteDoss= value.ListeAutoCompleteDoss;
      }, (erreur:any) => {
      })
  }

  SelectionAutocomplete(data:any): void{
    this.cout_exploitation.iddossier = data.idGestionNavire;

    this.dossierDechargement.numeroDossier = data.numeroDossier;
    this.dossierDechargement.Navire = data.Navire;
    this.dossierDechargement.dechargement = data.id_dechargelent;
    this.dossierDechargement.periode_travail = data.jour_nuit;
    this.dossierDechargement.date_operation = data.debut_operation;

//this.listeSousMenuMenus();
if (data.sousmenu.status == 1) {
  this.listeSousMenuMenus();
}else{
  for (let i = 0; i < this.listeCategorie.length; i++) {
        this.dataSousMenuModel.push(new eltcoutexploitation());
        this.dataSousMenuModel[i].categorie = this.listeCategorie[i].libelle;
        this.dataSousMenuModel[i].libelle = this.listeCategorie[i].libelleCategorie;

      }
}



  }


  reInitSearch(): void{
    this.ListeAutoCompleteDoss = [];
  }

  reInitSearchBlur(): void{
    setTimeout((val:any) => {
        this.ListeAutoCompleteDoss = [];
    }, 500);

  }

  AfficherDonnerChargeExp(id_cout_exploitation:any): void{
     this.apiUrl = "SERVER/exploitation/saisircharges/information";
      var data = {id_cout_exploitation:id_cout_exploitation};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
          this.cout_exploitation.iddossier = response.charge_exp[0].iddossier;

          this.dossierDechargement.Navire = response.charge_exp[0].Navire;
          this.dossierDechargement.numeroDossier = response.charge_exp[0].numeroDossier;

          this.TotalChargeDechargement = response.charge_exp[0].total_cout_exploitation;
          this.cout_exploitation.date_exploitation = response.charge_exp[0].date_exploitation;
          this.cout_exploitation.id_cout_exploitation = response.charge_exp[0].id_cout_exploitation;
          this.cout_exploitation.date_creation = response.charge_exp[0].date_creation;

          this.dataSousMenuModel = response.sousmenu;

          this.search("saisir");
      }, (erreur:any) => {
    })
  }
  SupprimerPopup(id:any): void{
    $(".suppdossier").trigger("click");
    this.suppelt.id = id;
  }

  SupprimerChargeExp(): void{
    this.apiUrl = "SERVER/exploitation/saisircharges/supprimer_charge_exp";
      var data = {id_cout_exploitation:this.suppelt.id};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
      ).subscribe((value:any) => {
        this.ListeExploitation();
      }, (erreur:any) => {
    })
  }

  listeSousMenuMenus(): void{
    var apiUrl = "SERVER/exploitation/saisircharges/listesousmenu";
    var data = {iddossier:this.cout_exploitation.iddossier};
    this.transfertDataService.EnvoiData(data, apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
      this.dataSousMenuModel = response.sousmenu;
      this.CalculPoidsQte();
    }, (erreur:any) => {
    })
  }

  CalculPoidsQte(): void{
    this.TotalChargeDechargement = 0;

    for(var i = 0; i < this.dataSousMenuModel.length; i++){
      if(isNaN(this.dataSousMenuModel[i].cout))
        this.dataSousMenuModel[i].cout = 0;
      if(isNaN(this.dataSousMenuModel[i].pu))
        this.dataSousMenuModel[i].pu = 0;
      if(isNaN(this.dataSousMenuModel[i].qte))
        this.dataSousMenuModel[i].qte = 0;

      this.dataSousMenuModel[i].cout = this.dataSousMenuModel[i].pu * this.dataSousMenuModel[i].qte;
      var cout = parseFloat(this.dataSousMenuModel[i].cout);
      this.TotalChargeDechargement = this.TotalChargeDechargement + cout;
    }

  }

  ajouter(): void{
    if(this.cout_exploitation.id_cout_exploitation == 0){
        var ch = new eltcoutexploitation();
        this.dataSousMenuModel.push(ch);
    }else{
      this.enregistrer_sous_menu();
    }
  }

  selectionligne(index:any, ordre:any){
    this.ligneSelectionne = index;
    this.precLine = ordre;
  }

  SuivantPrecedent(action:any){
    if(this.ligneSelectionne == -1)
      return;
    var apiUrl = "SERVER/exploitation/saisircharges/SuivantPrecedent";
    var data = {"Ligne" : this.precLine, ac:action, "iddossier" : this.cout_exploitation.iddossier};
    this.transfertDataService.EnvoiData(data, apiUrl).subscribe((response:any) => {
        this.listeSousMenuMenus();
        this.ligneSelectionne = -1;
    });

  }

  enregistrer_sous_menu(): void{

      var data = new eltcoutexploitation();
      data.id_cout_exploitation = this.cout_exploitation.id_cout_exploitation;

      this.apiUrl = "SERVER/exploitation/saisircharges/enregistrer_sous_menu";
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
      ).subscribe((value:any) => {
        this.listeSousMenuMenus();
      }, (erreur:any) => {
      })


  }

  supprimer_sous_menu(idsousmenu:any, index:any): void{
      if(this.cout_exploitation.id_cout_exploitation == 0){
        this.dataSousMenuModel.splice(index,1);
        this.CalculPoidsQte();
      }else{
            var apiUrl = "SERVER/exploitation/saisircharges/supprimer_sous_menu";
            var data = {id_charge_operation:idsousmenu};
            this.transfertDataService.EnvoiData(data, apiUrl).pipe(
              retryWhen(error =>
                error.pipe(
                  scan((acc:any, error:any) => {
                    if (acc > 2) throw error;
                    return acc + 1;
                  }, 1),
                  delay(1000)
                )
              )
          ).subscribe((value:any) => {
              this.listeSousMenuMenus();
            }, (erreur:any) => {

          })
      }

  }

  Enregistrer(event:any):void{
 event.target.disabled = true;
      if(this.cout_exploitation.id_cout_exploitation == 0){
        this.apiUrl = "SERVER/exploitation/saisircharges/enregistrer";
      }else{
        this.apiUrl = "SERVER/exploitation/saisircharges/modifier";
      }
      var infoUser = this.AuthService.getInfoUtilisateur();
      this.cout_exploitation.idUser = infoUser.id;
      var data = {"exp": this.cout_exploitation, "sousmenu" : this.dataSousMenuModel};
        this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
          retryWhen(error =>
            error.pipe(
              scan((acc:any, error:any) => {
                if (acc > 2) throw error;
                return acc + 1;
              }, 1),
              delay(1000)
            )
          )
      ).subscribe((value:any) => {
        event.target.disabled = false;



            this.Annuler();
            this.ListeExploitation();
            this.search("profile");
        }, (erreur:any) => {
             event.target.disabled = false;
      })
  }

  Annuler():void{
    this.cout_exploitation = new cout_exploitation();
    this.dataSousMenuModel = [];
    this.dossierDechargement = {};
    this.TotalChargeDechargement = 0;
  }

  Recherche(rech:any):void{
    this.apiUrl = "SERVER/exploitation/saisircharges/recherche";
    if(this.recherche.date1 == "" && this.recherche.date2 == "" ){
      this.ListeExploitation();
    }else{
      this.transfertDataService.EnvoiData(rech, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
       this.listecharges= value.listecharges;
      }, (erreur:any) => {
      })
  }
}
  updateDesignationCharge(id_elt_charge_exp:any, index:any){

    for(var i = 0; i < this.listeCategorie.length; i++){
        if(id_elt_charge_exp == this.listeCategorie[i].id_element_charge){
            this.dataSousMenuModel[index].pu = this.listeCategorie[i].pu;
            this.dataSousMenuModel[index].designation = this.listeCategorie[i].designation;
            this.UpdateCharge(this.dataSousMenuModel[index]);
            break;
        }
    }
  }

  UpdateCharge(charge:any):void{
    if(this.cout_exploitation.id_cout_exploitation == 0){
        this.CalculPoidsQte();
    }else{
        this.CalculPoidsQte();
        this.apiUrl = "SERVER/exploitation/saisircharges/modifier_sous_menu";
        var data = {charge:charge};
        this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
          retryWhen(error =>
            error.pipe(
              scan((acc:any, error:any) => {
                if (acc > 2) throw error;
                return acc + 1;
              }, 1),
              delay(1000)
            )
          )
      ).subscribe((value:any) => {
          this.CalculPoidsQte();
        }, (erreur:any) => {

      })
    }
  }

}
