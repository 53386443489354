
<div class="x_panel x_panel_close">
        <div class="x_content">
            <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;"><span ><i  class="fa fa-arrow-left"></i></span><div  class="ml-auto text-right"><span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span><i  class="fa fa-chevron-right"></i><span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Gestion Paramètres - Attribution Magasin
              </span>
             </div>
            </div>
            <br>

                                 <ul class="nav nav-tabs bar_tabs" id="myTab" role="tablist">
                                          <li class="nav-item">
                                            <a class="nav-link active" id="saisir-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='saisir'}" (click)="search('saisir')"
                                               role="tab" aria-controls="saisir" aria-selected="true">Saisir Attribution Magasin</a>
                                          </li>
                                          <li class="nav-item">
                                            <a class="nav-link" id="profile-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='profile'}" (click)="search('profile')"
                                                role="tab" aria-controls="profile" aria-selected="false">Liste Attributions Magasin</a>
                                          </li>
                                    </ul>
                                    <div class="tab-content" id="myTabContent">
                                        <div [ngClass]="{ 'show':activeTab==='saisir', 'active':activeTab==='saisir'}"
                                        class="tab-pane fade active show" id="saisir" role="tabpanel" aria-labelledby="saisir-tab">
                                             <br>
                                            <div class="cadreformulaire">
                                                        <div class="row">
                                                        <div class="form-group col-md-6">
                                                                <label for="exampleFormControlSelect1">
                                                                    <font style="vertical-align: inherit;">
                                                                        <font style="vertical-align: inherit;">Magasin</font>
                                                                    </font>
                                                                </label>
                                                                <select id="exampleFormControlSelect1" name="Magasin" [(ngModel)]="magasin_attributeModel.idmagasin" class="form-control form-control-sm">
                                                                    <option *ngFor = "let x of listeMagasin" value="{{x.idMagasin}}">{{x.NomMagasin}}</option>
                                                              </select>

                                                         </div>
                                                         <div class="form-group col-md-6">
                                                                <label for="exampleFormControlSelect1">
                                                                    <font style="vertical-align: inherit;">
                                                                        <font style="vertical-align: inherit;"> Nom Utilisateur</font>
                                                                    </font>
                                                                </label>
                                                                <select id="exampleFormControlSelect1" name="Nom Utilisateur" [(ngModel)]="magasin_attributeModel.iduser" class="form-control form-control-sm">
                                                                    <option *ngFor = "let x of listeutilisateur" value="{{x.id}}">{{x.nom}}</option>

                                                              </select>

                                                         </div>
                                                            <div class="form-group col-md-6">
                                                                    <label for="exampleFormControlSelect1">
                                                                        <font style="vertical-align: inherit;">
                                                                            <font style="vertical-align: inherit;">Date Attribution</font>
                                                                        </font>
                                                                    </label>
                                                                    <input  type="date" placeholder="jj/mm/aaaa" [(ngModel)]="magasin_attributeModel.date_attribution" name="date"class="form-control form-control-sm">

                                                            </div>
                                                            <div class="form-group col-md-6">
                                                                    <label for="exampleFormControlSelect1">
                                                                        <font style="vertical-align: inherit;">
                                                                            <font style="vertical-align: inherit;">Commentaire</font>
                                                                        </font>
                                                                    </label>
                                                                    <textarea placeholder="Commentaire" name="Commentaire" [(ngModel)]="magasin_attributeModel.commentaire" class="form-control form-control-sm"></textarea>

                                                             </div>

                                                         <div class="col-sm-12 text-right mobile-buttons">
                                                                <button class="btn btn-primary btn-sm btn-enregistrer" (click)="enregistrer()">
                                                                    <font style="vertical-align: inherit;">
                                                                        <font style="vertical-align: inherit;" >Enregistrer</font>
                                                                    </font>
                                                                </button>
                                                                &nbsp;
                                                                <button class="btn btn-secondary btn-sm btn-enregistrer" (click)="Annuler()">
                                                                    <font style="vertical-align: inherit;">
                                                                        <font style="vertical-align: inherit;">Annuler</font>
                                                                    </font>
                                                                </button>
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>

                                     <div class="tab-pane fade" id="profile" role="tabpanel"  aria-labelledby="profile-tab" [ngClass]="{'show':activeTab==='profile', 'active':activeTab==='profile'}">
                                          <br>
                                          <div class="">
                                              <div class="recherche_liste">
                                                <div class="row">
                                                    <div class="form-group col-xs-12 col-md-4">
                                                          <div class="form-group">
                                                                <label >
                                                                    <font style="vertical-align: inherit;">
                                                                        <font style="vertical-align: inherit;">Magasin</font>
                                                                    </font>
                                                                </label>
                                                                <input  type="text" placeholder="Magasin" [(ngModel)]="recherche.NomMagasin" (ngModelChange)="rechercheMagasin(recherche)" class="form-control form-control-sm">
                                                          </div>
                                                    </div>

                                                    <div class="form-group col-xs-12 col-md-4">
                                                        <div class="form-group">
                                                          <label >
                                                              <font style="vertical-align: inherit;">
                                                                  <font style="vertical-align: inherit;">Utilisateur</font>
                                                              </font>
                                                          </label>
                                                          <input  type="text" placeholder="Utilisateur" [(ngModel)]="recherche.nom" (ngModelChange)="rechercheMagasin(recherche)"class="form-control form-control-sm">
                                                    </div>
                                                </div>

                                                </div>
                                              </div>
                                          </div>
                                        <br>
                                          <div class="Table_Longer">
                                              <table class="table_bloc_liste">
                                            <thead>
                                              <tr>
                                                <th>#</th>
                                                <th>Nom magasin</th>
                                                <th>Nom utilisateur</th>
                                                <th>Date attribution</th>
                                                <th>Commentaire</th>
                                                <th>Créer Par</th>
                                                <th>Créer Le</th>
                                                <th>Modifier Par</th>
                                                <th>Modifier Le</th>
                                                <th></th>
                                                <th></th>
                                            </thead>
                                            <tbody>
                                              <tr *ngFor="let x of listeAttribue_magasin" class="text-center">
                                                    <td>{{x.idmagasinattribue}}</td>
                                                    <td>{{x.NomMagasin}}</td>
                                                    <td>{{x.nom}}</td>
                                                    <td>{{x.date_attribution}}</td>
                                                    <td>{{x.commentaire}}</td>
                                                    <td>{{x.nom}}</td>
                                                    <td>{{x.date}}</td>
                                                    <td>{{x.modifierpar}}</td>
                                                    <td>{{x.datemodification}}</td>
                                                    <td class="text-center">
                                                      <button  class="btn btn-warning btn-sm" (click)="information(x.idmagasinattribue)">
                                                        <i class="fas fa-pencil-alt"></i>
                                                      </button>
                                                    </td>
                                                    <td class="text-center">
                                                      <button  class="btn btn-danger btn-sm" (click)="SupprimerPopup(x.idmagasinattribue)">
                                                         <i class="far fa-trash-alt"></i>
                                                      </button>
                                                    </td>
                                                 </tr>

                                            </tbody>
                                          </table>
                                          </div>

                                     </div>

                                    </div>

        </div>
</div>
<button type="button" class="btn btn-primary  btn-sm suppliste" style="display:none;" data-backdrop="static" data-toggle="modal" data-target=".bs-Form-sm"></button>
<div class="modal fade bs-Form-sm" tabindex="-1" role="dialog" aria-hidden="true">
 <div class="modal-dialog modal-sm" role="document">
  <div class="modal-content">
      <div class="row container_modal">
          <div class="modal-header">
            <h6 class="modal-title" style="font-weight: bold;">SUPPRESSION</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>
            </button>
          </div>
          <div>
             <div class="modal-content-dl">
              <p>Souhaitez-vous supprimer ce enregistrement ?</p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" data-dismiss="modal"  class="btn btn-primary btn-sm" (click) = "supprimer(supp_id)">Oui</button>
            <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Non</button>
          </div>
      </div>
    </div>
  </div>
</div>
