<div class="x_panel x_panel_close">
	    <div class="x_content">
	    	<div  class="d-flex no-block align-items-center" style="margin: 5px 10px;"><span ><i  class="fa fa-arrow-left"></i></span><div  class="ml-auto text-right"><span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span><i  class="fa fa-chevron-right"></i><span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;GestionsProduitsclients
              </span>
             </div>
            </div>
	    	   <div class="">
					        <div class="content-action">
					             <ul class="nav nav-tabs bar_tabs" id="myTab" role="tablist">
					                      <li class="nav-item">
					                        <a class="nav-link active" id="saisir-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='saisir'}" (click)="search('saisir')"
					                           role="tab" aria-controls="saisir" aria-selected="true">Saisir Produit Client</a>
					                      </li>
					                      <li class="nav-item">
					                        <a class="nav-link" id="profile-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='profile'}" (click)="search('profile')"
					                            role="tab" aria-controls="profile" aria-selected="false">Liste des Produits Clients</a>
					                      </li>
					                </ul>
					                <div class="tab-content" id="myTabContent">
					                    <div [ngClass]="{ 'show':activeTab==='saisir', 'active':activeTab==='saisir'}"
								        class="tab-pane fade active show" id="saisir" role="tabpanel" aria-labelledby="saisir-tab">

								           <br>
								           									<div class="cadreformulaire">
                                                <div class="row">
                                                		<div class="col-md-6 form-group">
                                                				<div class=" persoautocomplete">
												                              <label for="">Sélectionner Nom Client*</label>
												                             <input [(ngModel)]="modelAutocompleClient.nomclient" type="text" name="Navire" id="NomClient" placeholder="Nom Client"  class="form-control form-control-sm" autocomplete="off" (focus)="reInitSearch()" (blur)="reInitSearchBlur()" disabled="disabled">
												                               <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="">
												                               </button>
												                                <div class="classPerso  dropdown-menu">
												                                  <form class="px-0 py-3">
												                                      <div class="col-md-12">
												                                          <div class="row">
												                                              <div class="col-md-12">
												                                                <input type="text" [(ngModel)]="modelAutocompleClient.nomclientSaisi" class="form-control form-control-sm" placeholder="Nom Client" name="NomClientSaisiProdClient" (ngModelChange)="ListeAutoCompleteClient(modelAutocompleClient.nomclientSaisi)" autocomplete="off">
												                                                <div>
												                                                  <br>
												                                                    <table class="table_bloc_liste tableReseach">
												                                                        <thead class="theadfixed">
												                                                            <th>Client</th>
												                                                        </thead>
												                                                        <tbody>
												                                                            <tr class="SelectTable"  *ngFor="let x of liste_Clients" (click)="SelectionAutocomplete(x)">
												                                                                <td>{{x.nom_societe}}</td>
												                                                            </tr>
												                                                        </tbody>
												                                                    </table>
												                                                </div>
												                                              </div>
												                                          </div>
												                                      </div>
												                                  </form>
												                                </div>
												                            </div>
												                    </div>
				                                             <div class="form-group col-md-6">
					                                             	<label>
					                                             		<font style="vertical-align: inherit;">
					                                             			<font style="vertical-align: inherit;">Nom Principal Produit*</font>
					                                             		</font>
					                                             	</label>
					                                             	<input type="text" placeholder="Nom Principal Produit" name="nompro" id="nompro" [(ngModel)]="ProduitModel.NomProduit" (ngModelChange)="GenererNomSpecificProduit()" class="form-control form-control-sm">
				                                            </div>
				                                            <div class="form-group col-md-6">
				                                             <label>
				                                             		<font style="vertical-align: inherit;">
				                                             			<font style="vertical-align: inherit;">Nom Conditionnement*</font>
				                                             		</font>
				                                             	</label>
				                                             	<select id="exampleFormControlSelect1" name="nomConditionnement" id="nomConditionnement" [(ngModel)]="PoidsModel.NomConditionnement" (ngModelChange)="GenererNomSpecificProduit()" class="form-control form-control-sm">
				                                             		<option *ngFor = "let x of liste_Poids" value="{{x.NomConditionnement}}">{{x.NomConditionnement}}</option>
				                                                </select>
			                                             </div>
			                                             <div class="form-group col-md-6">
			                                             	    <label>
				                                             		<font style="vertical-align: inherit;">
				                                             			<font style="vertical-align: inherit;">Nom Spécifique Produit</font>
				                                             		</font>
				                                             	</label>
				                                             	<input type="text" placeholder="Nom Spécifique Produit" name="nompro" [(ngModel)]="ProduitModel.NomSpecificProduit" class="form-control form-control-sm" disabled="disabled">
			                                             </div>
			                                             <div class="col-md-12" *ngIf="duplicateProduit == true">
			                                             	<div class="alert alert-danger alert-dismissible fade show" role="alert">
																										  <strong>Alerte!</strong> {{message_error}}
																										  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
																										    <span aria-hidden="true">&times;</span>
																										  </button>
																										</div>
			                                             </div>
			                                             	<div class="text-right-btn col-md-12">
				                                              <button type="button" class="btn btn-primary btn-sm btn-enregistrer" (click)="enregistrer($event)">Enregistrer</button>&nbsp;
				                                              <button type="button" class="btn btn-secondary btn-sm btn-annuler" (click)="Annuler()">Annuler</button>&nbsp;
				                                            </div>

                                                </div>
                                            </div>

					                    </div>

					                 <div class="tab-pane fade" id="profile" role="tabpanel"  aria-labelledby="profile-tab" [ngClass]="{'show':activeTab==='profile', 'active':activeTab==='profile'}">

					                 	<br>

								                    <div class="x_content">
								                      <div class="form-group col-xs-12 col-md-12 recherche_liste">
								                         <div class="">
								                          <div class="row" style="margin-left: 0px;margin-right: 0;">
								                          <div class="col-md-4 form-group persoautocomplete">
												                              <label for="">Rechercher par</label>
												                             <input [(ngModel)]="recherche.nom_societe" type="text" name="nom_societe" id="nom_societe" placeholder="Nom Client"  class="form-control form-control-sm" autocomplete="off" (focus)="reInitSearchRC()" (blur)="reInitSearchBlurRC()" disabled="disabled">
												                               <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="">
												                               </button>
												                                <div class="classPerso  dropdown-menu">
												                                  <form class="px-0 py-3">
												                                      <div class="col-md-12">
												                                          <div class="row">
												                                              <div class="col-md-12">
												                                                <input type="text" [(ngModel)]="recherche.nom_societeSaisi" class="form-control form-control-sm" placeholder="Nom Client" name="NomClientSaisiRC" (ngModelChange)="ListeAutoCompleteClientRC(recherche.nom_societeSaisi)" autocomplete="off">
												                                                <div>
												                                                  <br>
												                                                    <table class="table_bloc_liste tableReseach">
												                                                        <thead class="theadfixed">
												                                                            <th>Client</th>
												                                                        </thead>
												                                                        <tbody>
												                                                            <tr class="SelectTable"  *ngFor="let x of liste_ClientsRC" (click)="SelectionAutocompleteRC(x)">
												                                                                <td>{{x.nom_societe}}</td>
												                                                            </tr>
												                                                        </tbody>
												                                                    </table>
												                                                </div>
												                                              </div>
												                                          </div>
												                                      </div>
												                                  </form>
												                                </div>
												                            </div>

								                          <div class="form-group col-xs-12 col-md-4">
								                                  <div class="form-group">
										                                <label>
								                                     		<font style="vertical-align: inherit;">
								                                     			<font style="vertical-align: inherit;"></font>
								                                     		</font>
								                                     	</label>
										                                <input type="text" placeholder="Nom Spécifique Produit" name="NomSpecificProduit" [(ngModel)]="recherche.NomSpecificProduit" (ngModelChange)="rechercheProduit(recherche)" class="form-control form-control-sm">
									                              </div>
								                          </div>

								                          <div class="form-group col-xs-12 col-md-4">
								                                  <div class="form-group">
										                                <label>
								                                     		<font style="vertical-align: inherit;">
								                                     			<font style="vertical-align: inherit;"></font>
								                                     		</font>
								                                     	</label>
										                                <input type="text" placeholder="Nom Produit"  name="NomProduit" [(ngModel)]="recherche.NomProduit" (ngModelChange)="rechercheProduit(recherche)"class="form-control form-control-sm">
									                              </div>
								                          </div>
								                      		</div>
                                                        </div>
								                      </div>
																			<div class="">
																			<div class="Table_Longer1">
																				 <table class="table_bloc_liste table_responsive">
																							<thead>
																								<tr>
																									<th>#</th>
											                            <th>Nom client</th>
											                            <th>Nom spécifique produit</th>
											                            <th>Nom produit</th>
											                            <th>Poids</th>
																									<th>Créer Par</th>
											                            <th>Créer Le</th>
											                            <th>Modifier Par</th>
											                            <th>Modifier Le</th>
											                            <th></th>
											                            <th></th>
																							</thead>
																							<tbody>
																									 <tr *ngFor="let x of listeProduit_data">
																											<td>{{x.idProduit}}</td>
																											<td>{{x.nom_societe}}</td>
																											<td>{{x.NomSpecificProduit}}</td>
																											<td>{{x.NomProduit}}</td>
																											<td>{{x.NomConditionnement}}</td>
																											<td>{{x.nom}}</td>
												                              <td>{{x.date}}</td>
												                              <td>{{x.modifierpar}}</td>
												                              <td>{{x.datemodification}}</td>
																											<td class="text-center">
												                                <button  class="btn btn-success btn-sm" (click)="information(x.idProduit)">
												                                  <i class="fas fa-pencil-alt"></i>
												                                </button>
												                              </td>
												                              <td class="text-center">
												                                <!--<button  class="btn btn-danger btn-sm" (click)="supprimer(x.idProduit)">
												                                   <i class="far fa-trash-alt"></i>
												                                </button>-->
												                              </td>
																									 </tr>
																							 </tbody>
																					 </table>
																			</div>
																	</div>

								                    </div>


					                 </div>

								    </div>
						      </div>
						</div>

	    </div>
</div>
