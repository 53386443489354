export class position_navire {
  public idPositionNavire: number = 0;
	public date_position: string = "";
	public lieu_position: string = "";
	public heure: string = "";
	public commentaire: string = "";
	public iddossier: number = 0;

  constructor() {

  }

}
