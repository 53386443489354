<div class="x_panel x_panel_close">
    <div class="x_content">
       <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;"><span ><i  class="fa fa-arrow-left"></i></span>
           <div  class="ml-auto text-right"><span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span><i  class="fa fa-chevron-right"></i><span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Gestion Déchargements - Etat différentie</span>
           </div>
       </div>

       <div class="">

    <div class="col-md-12 col-sm-12 x_panel x_panel_close">
        <div class="x_title">
            <h5>Etat Différentiel</h5>
            <ul class="nav navbar-right panel_toolbox" style="padding-left: 50px;"></ul>
            <div class="clearfix"></div>
          </div>
          <div class="col-md-12">
              <div class="form-group col-xs-12 col-md-12 recherche_liste">
               <div class="row">
                  <div class="col-md-4 form-group">
                    
                    <div class="persoautocomplete">
                                      <label for="">N°Dossier</label>
                                     <input [(ngModel)]="recherche.numeroDossier" type="text" name="recherchenumdossForm" id="recherchenumdossForm" placeholder="N°Dossier"  class="form-control form-control-sm" autocomplete="off" (focus)="reInitSearchForm()" (blur)="reInitSearchBlurForm()" disabled="disabled">
                                       <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="">
                                       </button>
                                        <div class="classPerso  dropdown-menu">
                                          <form class="px-0 py-3">
                                              <div class="col-md-12">
                                                  <div class="row">
                                                      <div class="col-md-12">
                                                        <input type="text" [(ngModel)]="dossier.numeroDossier" class="form-control form-control-sm" placeholder="N°Dossier" name="FormSaisiDiffDecharg" (ngModelChange)="ListeAutoCompleteDossier(dossier.numeroDossier)" autocomplete="off">
                                                        <div>
                                                          <br>
                                                            <table class="table_bloc_liste tableReseach">
                                                                <thead class="theadfixed">
                                                                    <th>N°Dossier</th>
                                                                </thead>
                                                                <tbody>
                                                                    <tr class="SelectTable"  *ngFor="let x of listdoss" (click)="SelectionAutocomplete(x)">
                                                                        <td>{{x.numeroDossier}}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </form>
                                        </div>
                                </div>
                  </div>
                  <div class="form-group col-xs-12 col-md-4">
                        <label>
                            <font style="vertical-align: inherit;">
                              <font style="vertical-align: inherit;"></font>
                            </font>
                        </label>
                        <input type="text"  class="form-control form-control-sm" [(ngModel)] = "recherche.Navire" autocomplete="off" disabled="" placeholder="Navire">
                    </div>
                </div>
            </div>
          </div>
            
             
<div class="col-md-12">
  <div  class="table-responsive">
     <table class="table_bloc_liste">
       <thead>
         <tr>

          <th>#</th>
          <th>Produit</th>
          <th>Client</th>
          <th>Poids Prévu (Tonne)</th>
          <th>Poids Déchargé (Tonne)</th>
          <th>Différence Poids (Tonne)</th>
          <th>Qte Prévu</th>
          <th>Qte Déchargé</th>
          <th>Différence Qte</th>
         </tr>
       </thead>
       <tbody>
         <tr *ngFor = "let i of info_dossier; let n = index">
           <td>{{n+1}}</td>
           <td>{{i.designationProduit}}</td>
           <td>{{i.nom_societe}}</td>
           <td>{{i.PoidsPrevu}}</td>
           <td>{{i.PoidsDecharge}}</td>
           <td>{{i.Poids_restant}}</td>
           <td>{{i.qtePrevue}}</td>
           <td>{{i.qteDecharge}}</td>
           <td>{{i.Qte_restant}}</td>
         </tr>
       </tbody>
     </table>
   </div>
 </div>
</div>

<div class="col-md-12 col-sm-12 text-right" *ngIf="info_dossier.length > 0">
          <button class="btn btn-secondary btn-sm">
              <i class="fa fa-print"></i>&nbsp;<span>Imprimer</span>
          </button>
      </div>

</div>
    </div>
</div>
