<div class="x_panel x_panel_close">
        <div class="x_content">
               <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;"><span ><i  class="fa fa-arrow-left"></i></span>
                   <div  class="ml-auto text-right"><span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span><i  class="fa fa-chevron-right"></i><span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Exploitation - Budget Navire</span>
                   </div>
               </div>
                <ul class="nav nav-tabs bar_tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" id="saisir-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='saisir'}" (click)="search('saisir')"
                       role="tab" aria-controls="saisir" aria-selected="true">Saisir Règlement Navire </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='profile'}" (click)="search('profile')"
                        role="tab" aria-controls="profile" aria-selected="false">Liste Règlements Navire</a>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div [ngClass]="{ 'show':activeTab==='saisir', 'active':activeTab==='saisir'}" class="tab-pane fade active show" id="saisir" role="tabpanel" aria-labelledby="saisir-tab">
                      <br>
                    <div class="x_panel_title x_panel_close">
                        <div class="x_title">
                          <h5>REGLEMENT NAVIRE</h5>
                          <ul class="nav navbar-right panel_toolbox" style="padding-left: 50px;">
                          </ul>
                          <div class="clearfix"></div>
                        </div>
                        <div class="x_content">
                          <div class="row">
                            <div class="col-md-6 form-group">
                              <div class="persoautocomplete">
                                <label for="">N°Dossier*</label>
                               <input [(ngModel)] = "dossierAuto.Dossier" type="text" name="NumDoss" id="NumDoss" placeholder="N°Dossier"  class="form-control form-control-sm" autocomplete="off" (focus)="reInitSearch()" (blur)="reInitSearchBlur()" disabled="disabled">
                                 <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="">
                                 </button>
                                  <div class="classPerso  dropdown-menu">
                                    <form class="px-0 py-3">
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-12">
                                                  <input type="text" [(ngModel)]="dossierAuto.numeroDossier" class="form-control form-control-sm" placeholder="N°Dossier" name="NumDossSaisiSaisirExp" (ngModelChange)="ListeAutoCompleteDossier(dossierAuto.numeroDossier)" autocomplete="off">
                                                  <div>
                                                    <br>
                                                      <table class="table_bloc_liste tableReseach">
                                                          <thead class="theadfixed">
                                                              <th>N°Dossier</th>
                                                          </thead>
                                                          <tbody>
                                                              <tr class="SelectTable"  *ngFor="let x of listauto" (click)="SelectionAutocomplete(x)">
                                                                  <td>{{x.numeroDossier}}</td>
                                                              </tr>
                                                          </tbody>
                                                      </table>
                                                  </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                  </div>
                              </div>
                            </div>
                            <div class="form-group col-xs-12 col-md-6">
                              <label for="Compagnie">Navire</label>
                              <input type="text" class="form-control form-control-sm" id="marque" placeholder="Navire" [disabled]="true" [ngModel] = "dossierAuto.Navire">
                            </div>
                              <div class="form-group col-xs-12 col-md-6">
                                <label for="numero_dossier">N° Règlement Navire</label>
                                <input type="text" class="form-control form-control-sm" id="numero_dossier" ng-disabled="true" [(ngModel)] = "reglementnavireModel.idReglementnavire" placeholder="N° Règlement Navire"  disabled="disabled" [(ngModel)] = "dossierAuto.idBudgetNavire">
                              </div>
                              <div class="form-group col-xs-12 col-md-6">
                                <label for="numero_dossier">Règlement Navire*</label>
                                <input type="date" class="form-control form-control-sm" id="date_budget" [(ngModel)] = "reglementnavireModel.date_reglementnavire">
                              </div>
                          </div>
                        </div>

                     <br>
                     <div class="x_panel_title x_panel_close" style="width: 99%;margin: 0px auto;display: table;">
                        <div class="x_title">
                          <h6>REGLEMENT PAR CLIENT</h6>
                          <ul class="nav navbar-right panel_toolbox" style="padding-left: 50px;">
                          </ul>
                          <div class="clearfix"></div>
                        </div>
                        <div class="x_content">

                          <div class="row col-sm-12 text-left">
                                <div class="col-sm-6">
                                  <button class="btn btn-primary btn-sm" (click) = "NOUVEAUClt()">
                                    <i class="bi bi-plus-circle"></i>Nouveau Règlement  Client
                                  </button>&nbsp;
                                </div>

                                <br><br>
                          </div>
                          <div class="row">

                                  <div class="col-md-12">
                                  <div class="x_panel_title x_panel_close" style="width: 99%;margin: 0px auto;display: table;MARGIN-BOTTOM: 10PX" *ngFor = "let t of dataSousMenuModel; let y = index;">

                                      <div class="">
                                        <div class="form-group col-md-4">
                                            <div class="persoautocomplete">
                                                <label for="">Nom Client</label>
                                                <input [(ngModel)]="t.client" type="text" name="" id="client1"   class="form-control form-control-sm"
                                                       autocomplete="off" (focus)="reInitSearchFormClient()" (blur)="reInitSearchBlurFormClient()" disabled="disabled">
                                                <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id=""></button>
                                                <div class="classPerso  dropdown-menu">
                                                    <form class="px-0 py-3">
                                                        <div class="col-md-12">
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <input type="text" [(ngModel)]="clientAutocoplet.client" class="form-control form-control-sm"
                                                                           placeholder="Nom Client" name="FormSaisiEntreeStockRechDisopRecap"
                                                                           (ngModelChange)="ListeAutoCompleteClientrech(clientAutocoplet.client)" autocomplete="off">
                                                                    <div>
                                                                    <br>
                                                                        <table class="table_bloc_liste tableReseach">
                                                                            <thead class="theadfixed">
                                                                                <th>Nom Client</th>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr class="SelectTable"  *ngFor="let x of liste_ClientsRech"
                                                                                    (click)="SelectionAutocompleterechClient(t, x, y)">
                                                                                    <td>{{x.nom_societe}}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>


                                        </div>
                                      </div>


                                   <div class="col-md-12">
                                     <table class="table_mainoeuvre_fourniture table_bloc_liste">
                                           <thead>
                                             <tr >

                                                 <th>Date</th>
                                                 <th>Destination</th>
                                                 <th>Prix/T</th>
                                                 <th>Tonnage</th>
                                                 <th style="width:20px"></th>
                                             </tr>
                                           </thead>
                                           <tbody>
                                             <tr *ngFor="let x of t.eltReglmentClient;let i = index;">
                                               <td>
                                                 <input type="date" [(ngModel)] = "x.date" class="form-control form-control-sm" placeholder="JJ/mm/aaaa" (ngModelChange)="UpdateElt(x, t.eltReglmentClient)">
                                               </td>
                                               <td>
                                                 <select type="text" [(ngModel)] = "x.destination" class="form-control form-control-sm" (ngModelChange)="UpdateElt(x, t.eltReglmentClient)">
                                                   <option value="Mag Portuaire">Mag Portuaire</option>
                                                   <option value="Mag Hors Port (Client)">Mag Hors Port (Client)</option>
                                                   <option value="Mag Hors Port (LTA)">Mag Hors Port (LTA)</option>
                                                   <option value="Chargement à Quai">Chargement à Quai</option>
                                                   <option value="Autre">Autre</option>
                                                 </select>
                                               </td>

                                                <td>
                                                 <input type="text" [(ngModel)] = "x.prixT" class="form-control form-control-sm" (ngModelChange)="UpdateElt(x, t)">
                                               </td>
                                               <td>
                                                 <input type="number" [(ngModel)] = "x.tonnage" class="form-control form-control-sm" (ngModelChange)="UpdateElt(x, t)">
                                               </td>

                                               <td class="btm-remove-line">
                                                  <button class="btn btn-danger btn-sm" (click) = "supprimer_sous_menu(t, x.ideltReglementClient)">
                                                        <i class="far fa-trash-alt"></i>
                                                  </button>
                                                </td>
                                               </tr>
                                               <tr *ngIf="dataSousMenuModel.length > 0">
                                                 <td class="text-right" colspan="3">
                                                   <b>Net à Payer</b>
                                                 </td>
                                                 <td>
                                                   <input type="number" [(ngModel)] = "t.Netapayer" class="form-control form-control-sm" id="Objectif" placeholder="0" [disabled]="true" (ngModelChange)="UpdateClt(t)">

                                                 </td>
                                               </tr>
                                               <tr *ngIf="dataSousMenuModel.length > 0">
                                                 <td class="text-right" colspan="3">
                                                   <b>Compensation (Perte)</b>
                                                 </td>
                                                 <td>
                                                   <input type="number" [(ngModel)] = "t.Perte" class="form-control form-control-sm" id="Objectif" placeholder="0"  (ngModelChange)="UpdateClt(t)">

                                                 </td>
                                               </tr>
                                               <tr *ngIf="dataSousMenuModel.length > 0">
                                                 <td class="text-right" colspan="3">
                                                   <b>Compensation (Achat De riz)</b>
                                                 </td>
                                                 <td>
                                                   <input type="number" [(ngModel)] = "t.AchatRiz" class="form-control form-control-sm" id="Objectif" placeholder="0" (ngModelChange)="UpdateClt(t)">

                                                 </td>
                                               </tr>
                                               <tr *ngIf="dataSousMenuModel.length > 0">
                                                 <td class="text-right" colspan="3">
                                                   <b>Montant à Regler</b>
                                                 </td>
                                                 <td>
                                                   <input type="number" [(ngModel)] = "t.MontantRegle"  class="form-control form-control-sm" id="Objectif" placeholder="0" [disabled]="true" (ngModelChange)="UpdateClt(t)">

                                                 </td>
                                               </tr>
                                           </tbody>
                                     </table>
                                   </div>
                                  </div>
<br>
                                    <div class="row">
                                      <div class="col-sm-6">

                                      </div>
                                      <div class="col-sm-6 form-group text-left">
                                            <label for="colFormLabelSm" class="form-label">Total Réglement Navire</label>
                                            <input type="number" [(ngModel)] = "reglementnavireModel.total_reglementnavire"  class="form-control form-control-sm" id="Objectif" placeholder="0"  [disabled]="true">
                                        </div>
                                    </div>

                              </div>
                            </div>
                        </div>
                     </div>

                      <br>
                      <div class="col-sm-12 text-right mobile-buttons">
                          <button class="btn btn-primary btn-enregistrer" (click) = "Enregistrer(reglementnavireModel,$event)">
                              <font style="vertical-align: inherit;">
                                  <font style="vertical-align: inherit;">Enregistrer</font>
                              </font>
                          </button>
                          &nbsp;
                          <button class="btn btn-secondary btn-enregistrer" (click) = "Annuler()">
                              <font style="vertical-align: inherit;">
                                  <font style="vertical-align: inherit;">Annuler</font>
                              </font>
                          </button>
                      </div>

                    </div>


                     <br>


                 </div>
                    <div class="tab-pane fade" id="profile" role="tabpanel"  aria-labelledby="profile-tab" [ngClass]="{ 'show':activeTab==='profile', 'active':activeTab==='profile'}">
                        <br>
                        <div class="">
                  <div class="x_content">
                      <div class="form-group col-xs-12 col-md-12 recherche_liste">
                        <div class="row">
                                  <div class="form-group col-xs-12 col-md-3">
                                      <label for="Recherche_numero_dossier">Rechercher par</label>
                                      <input type="text" id="Recherche_numero_dossier" [(ngModel)] = "recherche.numeroDossier" class="form-control form-control-sm" placeholder="N°Dossier" (ngModelChange)="Recherche(recherche)">
                                  </div>
                                  <div class="form-group col-xs-12 col-md-3">
                                      <label for="Recherche_navire">&nbsp;</label>
                                      <input type="text" id="Recherche_nom" [(ngModel)] = "recherche.Navire"  class="form-control form-control-sm" placeholder="Navire"  (ngModelChange)="Recherche(recherche)" >
                                  </div>
                        </div>
                      </div>

                     <table class="table_bloc_liste">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Date saisie</th>
                                  <th>N°Dossier</th>
                                  <th>Navire</th>
                                  <th>Total reglement Navire</th>
                                  <th>Créer Par</th>
                                  <th>Créer Le</th>
                                  <th>Modifier Par</th>
                                  <th>Modifier Le</th>
                                  <th style="width:50px;"></th>
                                  <th style="width:50px;"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let n of listebudgetnavire">
                                  <td>{{n.idReglementnavire}}</td>
                                  <td>{{n.date_saisie}}</td>
                                  <td>{{n.numeroDossier}}</td>
                                  <td>{{n.Navire}}</td>
                                  <td>{{n.total_reglementnavire}}</td>
                                  <td>{{n.nom}}</td>
                                  <td>{{n.date_creation}}</td>
                                  <td>{{n.modifierpar}}</td>
                                  <td>{{n.datemodification}}</td>
                                <td class="text-center">
                                      <button type="button" class="btn btn-success btn-sm" (click)="AfficherDonner(n.idReglementnavire)">
                                        <i class="fas fa-pencil-alt"></i>
                                      </button>
                                  </td>
                                  <td class="text-center">
                                     <button  class="btn btn-danger btn-sm" (click) = "SupprimerPopup(n.idReglementnavire)">
                                        <i class="far fa-trash-alt"></i>
                                     </button>
                                  </td>
                                </tr>
                              </tbody>
                     </table>

                </div>
                        </div>
                   </div>
                </div>
            </div>
            <button type="button" class="btn btn-primary  btn-sm suppdossier" style="display:none;" data-backdrop="static" data-toggle="modal" data-target=".bs-Form-sm"></button>
            <div class="modal fade bs-Form-sm" tabindex="-1" role="dialog" aria-hidden="true">
             <div class="modal-dialog modal-sm" role="document">
              <div class="modal-content">
                  <div class="row container_modal">
                      <div class="modal-header">
                        <h6 class="modal-title" style="font-weight: bold;">SUPPRESSION REGLEMENT NAVIRE</h6>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div>
                         <div class="modal-content-dl">
                          <p>Souhaitez-vous supprimer ce reglement Navire ?</p>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button type="button" data-dismiss="modal"  class="btn btn-primary btn-sm" (click) = "SupprimerBudjet()">Oui</button>
                        <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Non</button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
