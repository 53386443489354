<div class="x_panel x_panel_close">
    <div class="x_content">
       <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;"><span ><i  class="fa fa-arrow-left"></i></span>
           <div  class="ml-auto text-right"><span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span><i  class="fa fa-chevron-right"></i><span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Exploitation</span>
           </div>
       </div>
       <ul class="nav nav-tabs bar_tabs" id="myTab" role="tablist">
             <li class="nav-item">
               <a class="nav-link active" id="saisir-tab" data-toggle="tab" href="javascript:void(0)"  [ngClass]="{ 'active':activeTab==='saisir'}" (click)="Tabulation('saisir')"
                  role="tab" aria-controls="saisir" aria-selected="true">Recapitulatif Exploitation</a>
             </li>
       </ul>

   <div class="tab-content" id="myTabContent">
     <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
       <br>
       <div class="">
          <div class="x_content">
            <div class="form-group col-xs-12 col-md-12 recherche_liste">
               <div class="row">
                  <div class="col-md-4 form-group">
                      <div class="persoautocomplete">
                                      <label for="">N°Dossier</label>
                                     <input [(ngModel)]="recherche.numeroDossier" type="text" name="recherchenumdossForm" id="recherchenumdossForm" placeholder="N°Dossier"  class="form-control form-control-sm" autocomplete="off" (focus)="reInitSearchForm()" (blur)="reInitSearchBlurForm()" disabled="disabled">
                                       <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="">
                                       </button>
                                        <div class="classPerso  dropdown-menu">
                                          <form class="px-0 py-3">
                                              <div class="col-md-12">
                                                  <div class="row">
                                                      <div class="col-md-12">
                                                        <input type="text" [(ngModel)]="dossier.numeroDossier" class="form-control form-control-sm" placeholder="N°Dossier" name="FormSaisiEtatDecharg" (ngModelChange)="ListeAutoCompleteDossier(dossier.numeroDossier)" autocomplete="off">
                                                        <div>
                                                          <br>
                                                            <table class="table_bloc_liste tableReseach">
                                                                <thead class="theadfixed">
                                                                    <th>N°Dossier</th>
                                                                </thead>
                                                                <tbody>
                                                                    <tr class="SelectTable"  *ngFor="let x of listdoss" (click)="SelectionAutocomplete(x)">
                                                                        <td>{{x.numeroDossier}}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </form>
                                        </div>
                                </div>
                    </div>

                </div>
            </div>
         </div>
       </div>
       <div class="x_panel text-center">
         <div class="x_content">
               <div class="row col-md-12" style="display: inline-block;">
                    <div class="tile_count row">
                      <div class="col-md-4 col-sm-4  tile_stats_count" style="margin-bottom: 10px;border-bottom: 0;padding-bottom: 10px;border-right: 2px solid #D9DEE4; ">
                         <span><h3 style="width: 100%;padding: 8px;margin-top: 0px;color: #006699;font-size: 15px;">Montant Navire</h3></span>
                         <div class="count ng-binding" style="line-height: 47px;font-weight: 600; font-size: 40px;">{{TotalMontant}}</div>
                      </div>

                      <div class="col-md-4 col-sm-4  tile_stats_count" style="margin-bottom: 10px;border-bottom: 0;padding-bottom: 10px; border-right: 2px solid #D9DEE4;">
                         <span><h3 style="width: 100%; padding: 8px;margin-top: 0px;color: #006699;font-size: 15px;">Montant Total Navire</h3></span>
                         <div class="count green ng-binding" style="font-size: 40px;line-height: 47px;font-weight: 600;color: #1ABB9C;">{{TotalMontantNavire}}</div>
                      </div>
                     <div class="col-md-4 col-sm-4  tile_stats_count" style="margin-bottom: 10px;border-bottom: 0;padding-bottom: 10px;">
                         <span><h3 style="width: 100%;padding: 8px;margin-top: 0px;color: #006699;font-size: 15px;">Montant total Coût Explotation</h3></span>
                         <div class="count red ng-binding" style="font-size: 40px;line-height: 47px;font-weight: 600;color: #E74C3C;">{{TotalCoutExp}}</div>
                     </div>
                   </div>
               </div>
         </div>
        </div>

     </div>
   </div>



            </div>

       </div>
