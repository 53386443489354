import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { pipe } from 'rxjs';
import { PRODUIT } from '../../../classes/produit';
import { PRODUITMAG } from '../../../classes/produit_magasin';
import { MAGASIN } from '../../../classes/magasin';
import { DOSSIER } from '../../../classes/Dossier';
import { PRODUITDISPO } from '../../../classes/produit_dispo_magasin';
import { MVT_PRODUIT } from '../../../classes/mvt_produit_magasin';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { AuthService } from '../../../services/connexion/auth.service';
import { ConfigurationService } from '../../../config/configuration.service';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-entrees-magasins',
  templateUrl: './entrees-magasins.component.html',
  styleUrls: ['./entrees-magasins.component.css']
})
export class EntreesMagasinsComponent implements OnInit {

  apiUrl:String = "";
  activeTab:String="saisir";
  dossier:any = "";
  listdoss:any = [];
  listauto:any = [];
  liste_ProduitsRech:any = [];
  dossierAuto:any={};
  produitAuto:any={};
  liste_magasins:any = [];
  listautorech:any = [];
  liste_prodmagasins:any = [];
  listautoSpecifik:any = [];
  recherche:any={};
  liste_Clients:any = [];
  liste_ClientsRech:any = [];
  liste_specifi:any = [];
   photos_previsualisation:any = [];
  array_files:any = [];
  listeClientsDossier:any = [];
  produitmagModel:PRODUITMAG = new PRODUITMAG();
  produitdispoModel:PRODUITDISPO = new PRODUITDISPO();
  magasinModel:MAGASIN = new MAGASIN();
  dossierModel:DOSSIER = new DOSSIER();
  prduitModel:PRODUIT = new PRODUIT();
  mvtModel:MVT_PRODUIT = new MVT_PRODUIT();
  constructor(private sanitizer:DomSanitizer, private transfertDataService : transfertDataService, private AuthService : AuthService,  private ConfigurationService : ConfigurationService) { }

  ngOnInit(): void {
    this.listeProduitmag();
    this.recherche.iddossier="";
    this.recherche.idclient="";
    this.recherche.nom_produit_magasin="";
    this.recherche.idMagasin="";
    this.recherche.date1="";
    this.recherche.date2="";
    this.listemagasin();
    this.listeprodui();
  }

  search(activeTab:String){
     this.activeTab=activeTab;
   };


     Annuler(){
       this.produitmagModel = new PRODUITMAG();
       this.dossierModel = new DOSSIER();
       this.prduitModel = new PRODUIT();
       this.magasinModel = new MAGASIN();
       this.produitdispoModel= new PRODUITDISPO();
       this.mvtModel= new MVT_PRODUIT();
       this.dossierAuto = {};
       this.produitAuto = {};
     }

     ListeAutoCompleteDossier(numDoss:any){
      this.apiUrl = "SERVER/gestionmagasin/entremagasin/recherche_magasin";
         var data = {NumDoss:numDoss};

         this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
           retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((value:any) => {
           this.listauto= value.listAuto;
         }, (erreur:any) => {
         })
     }


     ListeAutoClientDossier(iddossier:any){
      this.apiUrl = "SERVER/gestionmagasin/entremagasin/ClientDossier";
         var data = {iddossier:iddossier};
         this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
           retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((value:any) => {
           this.listeClientsDossier= value.listeClientsDossier;
         }, (erreur:any) => {
             console.log(erreur.status);
         })
     }



     SelectionAutocomplete(data:any): void{
       this.dossierAuto.iddossier= data.idGestionNavire;
       this.dossierAuto.numDossier= data.numeroDossier;
       this.dossierAuto.numeroDossier= data.numeroDossier;
       this.dossierAuto.NomCompagnie = data.Navire;

       this.ListeAutoClientDossier(this.dossierAuto.iddossier);
     }

     ListeAutoCompleteProduit(nomSousProduit:any){


      this.apiUrl = "SERVER/gestionmagasin/entremagasin/recherche_produit";
      var data = {nom:nomSousProduit, idclient:this.dossierAuto.idClient, iddossier:this.dossierAuto.iddossier};

         this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
           retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((value:any) => {
           this.listautoSpecifik= value.listautoSpecifiks;
         }, (erreur:any) => {
         })
     }

     SelectionAutocompleteProduit(data:any): void{
       this.produitAuto.idProduit= data.idProduit;
       this.produitAuto.nomSous= data.nomSousProduit;
       this.produitAuto.nomSousProduit= data.nomSousProduit;
       this.produitAuto.nom_societe = data.nom_societe;
       this.produitAuto.poids = data.poids;
       this.produitAuto.idClient =data.idClient;
       this.produitAuto.idSousProduit=data.idSousProduit;
       this.produitAuto.id_produit_magasin=data.id_produit_magasin;
     }



       listemagasin() : void{

         this.apiUrl = "SERVER/gestionmagasin/entremagasin/listemagasin";
         var data = {};
         this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((response:any) => {
           this.liste_magasins = response.liste_magasin;
         }, (erreur:any) => {

         })

       }

       PoidsTotalCalcule() : void{
           this.produitdispoModel.poids_dispo = (this.produitAuto.poids * this.produitdispoModel.qte_dispo)/1000;


       }

       //Fonction de verification de champs
       VerificationChampsObligatoire(tableau_champs_obligatoires:any){
        var verification_formulaire = true;
        for (var i = 0; i < tableau_champs_obligatoires.length; i++) {
            if($(tableau_champs_obligatoires[i]).val() == "" ||  $(tableau_champs_obligatoires[i]).val() == null || $(tableau_champs_obligatoires[i]).val() == "? string: ?"){
                    $(tableau_champs_obligatoires[i]).css("border","1px solid red");
                    verification_formulaire = false;
                    document.getElementById(tableau_champs_obligatoires[i].replace("#",""))?.scrollIntoView({behavior: "smooth"});
                    break;
            }else{
                $(tableau_champs_obligatoires[i]).css("border","");
            }
        }

        return verification_formulaire;
    }



        Enregistrer( event:any): void{
          if(this.VerificationChampsObligatoire(["#listeMagasinsEntree", "#numeroDossierEntreeMag","#listeclientDossEntreeMag", "#nomSousProduitEntreeMag", "#quantiteEntreeMag", "#poids_totalEntreeMag", "#datentreeMag"]) == false)
                return;
                event.target.disabled = true;

          if(this.mvtModel.id_mvt == 0){
            this.apiUrl = "SERVER/gestionmagasin/entremagasin/enregistrer";
          }else{
            this.apiUrl = "SERVER/gestionmagasin/entremagasin/modifier";
          }
           this.produitmagModel = this.produitAuto;
           this.produitmagModel.id_produit_magasin = this.produitAuto.id_produit_magasin;
           this.produitmagModel.iddossier = this.dossierAuto.iddossier;

           var infoUser = this.AuthService.getInfoUtilisateur();

          var d = {"produitmag": this.produitmagModel,"idMagasin": this.magasinModel.idMagasin,"poids_dispo": this.produitdispoModel.poids_dispo,
                 "qte_dispo": this.produitdispoModel.qte_dispo,"date_mvt":this.mvtModel.date_mvt,"clientapprovisionne":"",
                 "provenance":this.mvtModel.provenance, "bl":this.mvtModel.bl_lta, "mvt":this.mvtModel, "iduser":infoUser.id};

          this.transfertDataService.EnvoiData(d, this.apiUrl).pipe(
              retryWhen(error =>
                error.pipe(
                  scan((acc:any, error:any) => {
                    if (acc > 2) throw error;
                    return acc + 1;
                  }, 1),
                  delay(1000)
                )
              )
          ).subscribe((value:any) => {
            event.target.disabled = false;
            this.listeProduitmag();
            this.Annuler();
            this.search("profile");
          }, (erreur:any) => {
            event.target.disabled = false;
          })
        }

       listeProduitmag(): void{
         this.apiUrl = "SERVER/gestionmagasin/entremagasin/listeproduitmag";
         var data = {};


         this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((response:any) => {
           this.liste_prodmagasins = response.liste_prodmagasins;
         }, (erreur:any) => {
         })
       }

       autocompleteProduitClient(id_client:any){


                  this.apiUrl = "SERVER/gestionmagasin/entremagasin/listeautocompleteProduitClient";
                  var data = {id_client:id_client};


                  this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
                      retryWhen(error =>
                        error.pipe(
                          scan((acc:any, error:any) => {
                            if (acc > 2) throw error;
                            return acc + 1;
                          }, 1),
                          delay(1000)
                        )
                      )
                  ).subscribe((response:any) => {
                    this.liste_specifi = response.listeProduitClient;

                  }, (erreur:any) => {
                  })

                  this.RechercheTabListe(this.recherche);

       }
       listeClient() : void{

         this.apiUrl = "SERVER/gestionmagasin/entremagasin/listeclient";
         var data = {iddossier:this.recherche.iddossier};


         this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((response:any) => {
           this.liste_Clients = response.liste_client;


           this.listeprodui();
         }, (erreur:any) => {
         })

       }

       ListeAutoCompleteClientrech(client:any) : void{

        this.apiUrl = "SERVER/gestionmagasin/entremagasin/listeclientrecherche";
        var data = {client:client};
        this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
            retryWhen(error =>
              error.pipe(
                scan((acc:any, error:any) => {
                  if (acc > 2) throw error;
                  return acc + 1;
                }, 1),
                delay(1000)
              )
            )
        ).subscribe((response:any) => {
          this.liste_ClientsRech = response.liste_client;


          this.listeprodui();
        }, (erreur:any) => {
        })

      }

      ListeAutoCompleteProduitrech(produit:any) : void{

        this.apiUrl = "SERVER/gestionmagasin/entremagasin/listeAutocompleteProduitRecherche";
        var data = {produit:produit};


        this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
            retryWhen(error =>
              error.pipe(
                scan((acc:any, error:any) => {
                  if (acc > 2) throw error;
                  return acc + 1;
                }, 1),
                delay(1000)
              )
            )
        ).subscribe((response:any) => {
          this.liste_ProduitsRech = response.listautoSpecifiks;


          this.listeprodui();
        }, (erreur:any) => {
        })

      }


       listeprodui() : void{

         this.apiUrl = "SERVER/gestionmagasin/entremagasin/listeprodmag";
         var data = {idClient:this.recherche.idClient};

         this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((response:any) => {
           this.liste_specifi = response.listeprodmag;

         }, (erreur:any) => {
         })

       }
        AfficherDonner(id_mvt:any): void{
           this.apiUrl = "SERVER/gestionmagasin/entremagasin/information";
           var data = {id:id_mvt};
           this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((response:any) => {
               this.produitmagModel = response.listeproduitmagModel[0];
               this.dossierAuto.numDossier= response.listeproduitmagModel[0].numeroDossier;
               this.magasinModel.idMagasin= response.listeproduitmagModel[0].id_magasin;
               this.dossierAuto.NomCompagnie= response.listeproduitmagModel[0].Navire;
               this.produitAuto.nomSous= response.listeproduitmagModel[0].nom_produit_magasin;
               this.produitAuto.nomSous = response.listeproduitmagModel[0].id_produit_magasin;
               this.produitAuto.nom_societe= response.listeproduitmagModel[0].nom_societe;
               this.produitdispoModel.qte_dispo= response.listeproduitmagModel[0].qte;
               this.produitdispoModel.poids_dispo= response.listeproduitmagModel[0].poids;
               this.mvtModel.date_mvt= response.listeproduitmagModel[0].date_mvt;
               this.mvtModel.provenance= response.listeproduitmagModel[0].provenance;
               this.mvtModel.bl_lta= response.listeproduitmagModel[0].bl_lta;
               this.mvtModel.bl_client= response.listeproduitmagModel[0].bl_client;
               this.mvtModel.immat= response.listeproduitmagModel[0].immat;
               this.mvtModel.numerofiche= response.listeproduitmagModel[0].numerofiche;
               this.mvtModel.ticket_depese= response.listeproduitmagModel[0].ticket_depese;
               this.mvtModel.observation= response.listeproduitmagModel[0].observation;
               this.mvtModel.id_mvt= response.listeproduitmagModel[0].id_mvt;


               this.produitAuto.nomSous = response.listeproduitmagModel[0].nom_produit_magasin;
               this.produitAuto.nomSousProduit= response.listeproduitmagModel[0].nom_produit_magasin;
               this.produitAuto.nom_societe = response.listeproduitmagModel[0].nom_societe;
               this.produitAuto.poids = response.listeproduitmagModel[0].poidsunitaire;
               this.dossierAuto.idClient = response.listeproduitmagModel[0].id_client;
               this.produitAuto.idSousProduit= response.listeproduitmagModel[0].id_sous_produit;
               this.produitAuto.id_produit_magasin= response.listeproduitmagModel[0].id_produit_magasin;
               this.dossierAuto.iddossier = response.listeproduitmagModel[0].iddossier;
               this.ListeAutoClientDossier(this.produitmagModel.iddossier);
               this.search("saisir");

           }, (erreur:any) => {
         })
       }

       Supprimer(id_mvt:any): void{
           this.apiUrl = "SERVER/gestionmagasin/entremagasin/supprimer";
           var data = {id:id_mvt};
           this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((value:any) => {
             this.listeProduitmag();
           }, (erreur:any) => {
           })
       }

       RechercheTabListe(data:any): void{
         if(this.recherche.iddossier == "" && this.recherche.idclient == "" && this.recherche.nom_produit_magasin == "" && this.recherche.idMagasin == ""  && this.recherche.date1 == ""  && this.recherche.date2 == "" ){
           this.listeProduitmag();
         }else{
           this.apiUrl = "SERVER/gestionmagasin/entremagasin/recherche";
           var data = data;

           this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((value:any) => {

            this.liste_prodmagasins= value.liste_prodmagasins;
           }, (erreur:any) => {
           })
         }

       }

       ListeAutoCompleteDossierrech(numDoss:any){
        this.apiUrl = "SERVER/gestionmagasin/entremagasin/recherche_magasinrech";
           var data = {NumDoss:numDoss};
           this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((value:any) => {
             this.listdoss= value.listautorech;

           }, (erreur:any) => {
           })
       }

       SelectionAutocompleterech(data:any): void{
         this.recherche.iddossier= data.iddossier;
         this.recherche.numeroDossier= data.numeroDossier;
         this.recherche.numeroDossier1= data.numeroDossier;
         this.RechercheTabListe(this.recherche);

       }

       SelectionAutocompleterechClient(data:any): void{
        this.recherche.idclient= data.idClient;
        this.RechercheTabListe(this.recherche);
      }

      SelectionAutocompleterechProduit(data:any): void{
        this.recherche.nom_produit_magasin= data.nom_produit_magasin;
        this.RechercheTabListe(this.recherche);

      }

      reInitSearchFormEntreeProduit(): void{
        this.liste_ProduitsRech = [];
      }

      reInitSearchBlurFormEntreeProduit(): void{
        setTimeout((val:any) => {
            this.liste_ProduitsRech = [];
        }, 500);
      }

       reInitSearchFormClient(): void{
        this.liste_ClientsRech = [];
      }

      reInitSearchBlurFormClient(): void{
        setTimeout((val:any) => {
            this.liste_ClientsRech = [];
        }, 500);
      }

       reInitSearchForm(): void{
        this.listdoss = [];
      }

      reInitSearchBlurForm(): void{
        setTimeout((val:any) => {
            this.listdoss = [];
        }, 500);
      }


  apercu(iddocument:any): void{
    var element = <HTMLImageElement> $("#file_" + iddocument).get(0);
    element.requestFullscreen();
  }

  supprimer_image(nomfichier:any, iddocument:any) : void{
        if(iddocument == ""){
            for (var i = 0; i < this.array_files.length; i++) {

                if(this.array_files[i].name == nomfichier){
                    this.array_files.splice(i, 1);
                }
            }

            for (var i = 0; i < this.photos_previsualisation.length; i++) {
                if(this.photos_previsualisation[i].lien == nomfichier){
                    this.photos_previsualisation.splice(i, 1);
                }
            }
        }else{
            var apiUrl = "SERVER/gestionnavire/navire/SupprimerFile";
            var data = {id:iddocument};
            this.transfertDataService.EnvoiData(data, apiUrl).pipe(
               retryWhen(error =>
                 error.pipe(
                   scan((acc:any, error:any) => {
                     if (acc > 2) throw error;
                     return acc + 1;
                   }, 1),
                   delay(1000)
                 )
               )
            ).subscribe((value:any) => {
                for (var i = 0; i < this.photos_previsualisation.length; i++) {
                    if(this.photos_previsualisation[i].iddocument == iddocument){
                        this.photos_previsualisation.splice(i, 1);
                    }
                }
             }, (erreur:any) => {
            })
        }

  }

  onFileChanged(event: any): void {
        this.array_files = [];
        var src = URL.createObjectURL(event.target.files[0]);
        var elements = event.target.files;
        for(var i = 0; i < elements.length; i++){
          var src = URL.createObjectURL(event.target.files[i]);
          this.photos_previsualisation.push({iddocument : '', src : this.sanitizer.bypassSecurityTrustUrl(src), lien : event.target.files[i]});
          this.array_files.push(event.target.files[i]);
        }
  }

  EtatExcel(){
    var lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/Reporting/ExcelEntreeMagasin.php?iddossier="+this.recherche.iddossier+"&idclient="+this.recherche.idclient+"&nom_produit_magasin="+encodeURI(this.recherche.nom_produit_magasin)+"&idMagasin="+this.recherche.idMagasin+"&date1="+this.recherche.date1+"&date2="+this.recherche.date2;
    window.open(lien_fiche_ExpST , '_blank')?.focus();
  }

  EtatPdf(){
        var lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/Reporting/pdf_EntreeMagasin.php?iddossier="+this.recherche.iddossier+"&idclient="+this.recherche.idclient+"&nom_produit_magasin="+encodeURI(this.recherche.nom_produit_magasin)+"&idMagasin="+this.recherche.idMagasin+"&date1="+this.recherche.date1+"&date2="+this.recherche.date2;
        $("#printRapport").remove();
        $("body").append('<div id="printRapport">\
                           <div class="col-md-12 form-group" style="display:none;">\
                             <button class="btn btn-primary btn-sm bs-modal-frame-sm-btn" data-backdrop="static" data-toggle="modal" data-target=".bs-modal-frame-sm"></button>\
                           </div>\
                           <div class="modal fade bs-modal-frame-sm" tabindex="-1" role="dialog" aria-hidden="true">\
                             <div class="modal-dialog" role="document">\
                                 <div class="modal-content">\
                                     <div class="row container_modal" style="width:100%;">\
                                     <div class="modal-header" style="margin-bottom:0px;">\
                                       <h6 class="modal-title" style="font-weight: bold;">ETAT STOCK MAGASIN</h6>\
                                       <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>\
                                       </button>\
                                     </div>\
                                     <div class="row col-md-12" style="width:100%;height: calc(100vh - 90px);padding-left: 0px; padding-right: 0px; margin-left: 0px; margin-right: 0px;">\
                                       <div class="col-md-12 waiting">\
                                         <div class="snippet" data-title=".dot-pulse">\
                                           <div class="stage">\
                                             <div class="dot-pulse"></div>\
                                           </div>\
                                         </div>\
                                       </div>\
                                       <iframe class="rapportframe" name="rapportframe" style="width:100%;"></iframe>\
                                     </div>\
                                 </div>\
                               </div>\
                             </div>\
                           </div>\
                       </div>');
        $(".bs-modal-frame-sm-btn").trigger("click");
        window.open(lien_fiche_ExpST , "rapportframe")?.focus();
        $(".rapportframe").css("display", "none");
        $('.rapportframe').on('load', function(){
           $(".waiting").css("display", "none");
           $(".rapportframe").css("display", "initial");
        });
  }

}
