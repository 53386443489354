export class Utilisateur {
  public id: number = 0;
  public nom: string = "";
  public tel: string = "";
  public responsabilite: string = "26";
  public identifiant: string = "";
  public email: string = "";
  public idgroupe: number = 0;
  public login: string = "";
  public password: string = "";
  public autorisation: string = "";
  public active: boolean = true;
  public date: string = "";
  public creerparuser: number = 0;
  public modifierparusers: number = 0;
  public datemodificationuser: string = "";
  constructor() {

  }

}
