<div class="row">
  <div class="col-xs-12 col-sm-3 droit">
    <div class="" [hidden]="resize===true" style="width: auto;background:#fff;">
      <div class="content-smenu">
        <div class="header">
          <div class="word">
            <i class="fas fa-bars" style="cursor:pointer"></i>
            <span class="applabel">DIGITAL TRANSIT</span>
            <a mat-button [hidden]="resize==true" (click)="toggleResize()" class="resize_content-smenu-d">
                <i class="fas fa-arrow-left" style="color: white;"></i>
            </a>
          </div>
        </div>
        <div class="scrollBar">
          <div class="Subheader">
            <div class="underText">Bienvenue, <br> {{utilisateur.nom}}</div>
          </div>
          <div class="list-submenu">
            <ul class="submenu-ul" *ngIf="menuData.length > 0">
              <li *ngFor="let x of menuData">
                  <a class="submenu-ul" (click)="OpenClosemenu[x.id_menu] = !OpenClosemenu[x.id_menu];" [hidden]="x.sousmenu.length == 0" >
                    <div class="icon-box"><i class="{{x.icone}}"></i></div>
                    <span>{{x.nom_menu}}</span><i [hidden]="x.sousmenu.length == 0" class="fas fa-chevron-down i_right"></i>
                  </a>
                  <a class="submenu-ul" routerLink="{{x.url}}" *ngIf="x.sousmenu.length ==  0" (click)="ShowFiller()">
                    <div class="icon-box"><i class="{{x.icone}}"></i></div>
                    <span>{{x.nom_menu}}</span><i [hidden]="x.sousmenu.length == 0" class="fas fa-chevron-down i_right"></i>
                  </a>
                  <ul  style="display: none;" class="inliste child_menu">
                  <li *ngFor="let y of x.sousmenu">
                    <a  routerLink="{{y.url}}">
                      
                      &nbsp;&nbsp;&nbsp;
                      <span (click) = "ShowFiller()">{{y.nom_sous_menu}}</span>
                    </a>
                  </li>
                </ul>
              </li>
          </ul>
        </div>
      </div>
      </div>
    </div>
     <div class="" [hidden]="resize===false" style="width: 85px;background:#fff;">
      <div class="content-smenu content-smenu-mobile">
        <div class="header" style="height:48px;">
          <div class="word" style="margin-left: 0px;top: -5px;left: 0px;">
            <a mat-button  (click)="toggleResize()" class="expand_content-smenu-d resize_icon">
                <i class="fas fa-arrow-right" style="color: white"></i>
            </a>
          </div>
        </div>
        <div class="list-submenu">
            <ul class="submenu-ul" *ngIf="menuData.length > 0">
              <li *ngFor="let x of menuData">
                  <a class="submenu-ul" (click)="ClickMenu(x.id_menu, OpenClosemenu)" [hidden]="x.sousmenu.length == 0">
                    <div class="icon-box" style="font-size: 24px;width: 60px;text-align: center;"><i class="{{x.icone}}"></i></div>
                    <span class="span_ellipsis"></span>
                  </a>
                  <a class="submenu-ul" routerLink="{{x.url}}" *ngIf="x.sousmenu.length ==  0">
                    <div class="icon-box" style="font-size: 24px;width: 60px;text-align: center;"><i class="{{x.icone}}"></i></div>
                    <span class="span_ellipsis"></span>
                  </a>
                  <ul  style="display: none;" class="inliste child_menu">
                  <li *ngFor="let y of x.sousmenu">
                    <a  routerLink="{{y.url}}">
                      &nbsp;&nbsp;&nbsp;
                      <span class="span_ellipsis">{{y.nom_sous_menu}}</span>
                    </a>
                  </li>
                </ul>
              </li>
          </ul>
      </div>
      </div>
    </div>
  </div>
