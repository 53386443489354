export class categoriecoutexp {
  public idCategorieCoutExp: number = 0;
  public libelle: string = "";
  public libelleCategorie: string = "";
  public Datecreation: string = "";
  public creerPar: number = 0;
  public Modifierle: string = "";
  public modifierPar: number = 0;

  constructor() {
  }

}
