import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { AuthService } from '../../../services/connexion/auth.service';
import { TRANSITAIRE } from '../../../classes/transitaire';


@Component({
  selector: 'app-transitaire',
  templateUrl: './transitaire.component.html',
  styleUrls: ['./transitaire.component.css']
})
export class TransitaireComponent implements OnInit {
  activeTab:String="saisir";
  apiUrl:String = "";
  listeAgent:any = [];
  recherche:any = {};
  agentPrecedent:String = "";
  supp_id:any = "";
  erreurSuppression = "";
  TRANSITAIRE:TRANSITAIRE = new TRANSITAIRE();

  constructor(private transfertDataService : transfertDataService, private AuthService : AuthService) { }



  ngOnInit(): void {
    this.ListeDesTransitaires();
  }

  search(activeTab:String){
    this.activeTab=activeTab;
  };

  Annuler():void{
    this.TRANSITAIRE = new TRANSITAIRE();
    this.recherche.nom = "";
  }


  ListeDesTransitaires(): void{
    this.apiUrl = "SERVER/importparametre/transitaire/liste";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((response:any) => {
         this.listeAgent = response.liste;

       }, (erreur:any) => {
           console.log(erreur.status);
       })

  }

  VerificationChampsObligatoire(tableau_champs_obligatoires:any){
      var verification_formulaire = true;
      for (var i = 0; i < tableau_champs_obligatoires.length; i++) {
          if($(tableau_champs_obligatoires[i]).val() == "" || $(tableau_champs_obligatoires[i]).val() == null || $(tableau_champs_obligatoires[i]).val() == "? string: ?"){
                  $(tableau_champs_obligatoires[i]).css("border","1px solid red");
                  verification_formulaire = false;
                  document.getElementById(tableau_champs_obligatoires[i].replace("#",""))?.scrollIntoView({behavior: "smooth"});
                  break;
          }else{
              $(tableau_champs_obligatoires[i]).css("border","");
          }
      }

    return verification_formulaire;
  }

  Enregistrer(data:any): void{
    //Champ obligatoire
    if(this.VerificationChampsObligatoire(["#nom1"]) == false){
      return;
    }

    var infoUser = this.AuthService.getInfoUtilisateur();

    if(this.TRANSITAIRE.id == 0){
      this.apiUrl = "SERVER/importparametre/transitaire/enregistrer";
      this.TRANSITAIRE.creerPar = infoUser.id;
      
    }else{
      this.apiUrl = "SERVER/importparametre/transitaire/modifier";
      this.TRANSITAIRE.modifierPar = infoUser.id;

    }


    this.TRANSITAIRE.tel = String(this.TRANSITAIRE.tel);
    var d = {"transitaire": this.TRANSITAIRE};
    this.transfertDataService.EnvoiData(d, this.apiUrl).pipe(
            retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((value:any) => {
          /*CONTRAINTE D'UNICITE*/
          if(value.status == -1){
            $("#nom1").css("border","1px solid red");
          }else{
            this.ListeDesTransitaires();
            this.search("profile");
            this.Annuler();
          }

         }, (erreur:any) => {
             console.log(erreur.status);
         })

  }


  information(id:any):void{
    this.apiUrl = "SERVER/importparametre/transitaire/information";
    var data = {id:id};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
      retryWhen(error =>
        error.pipe(
              scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((response:any) => {
               this.TRANSITAIRE = response.agent[0];
               this.agentPrecedent = response.agent[0].nom;
               this.search("saisir");
           }, (erreur:any) => {
             console.log(erreur.status);
         })

     }

  SupprimerPopup(id:any): void{
    $(".suppliste").trigger("click");
    this.supp_id = id;
  }

  supprimer(id:any):void{
       this.apiUrl = "SERVER/importparametre/transitaire/supprimer";
       var data = {id:id};
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
     ).subscribe((value:any) => {
      if(value.status == -1){
            this.erreurSuppression = "Impossible de supprimer ce Transitaire.";
            $(".exampleModal").trigger("click");
        }else{
          this.ListeDesTransitaires();
        } 

       }, (erreur:any) => {
           console.log(erreur.status);
       })

  }

  recherch(data:any): void{
     if(this.recherche.nom == "")
     this.ListeDesTransitaires();
     else{
       this.apiUrl = "SERVER/importparametre/transitaire/recherche";
       var data = data;
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
     ).subscribe((value:any) => {
         this.listeAgent= value.liste;
       }, (erreur:any) => {
           console.log(erreur.status);
       })
     }

 }



}
