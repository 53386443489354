import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-liste-conteneur-positionne',
  templateUrl: './liste-conteneur-positionne.component.html',
  styleUrls: ['./liste-conteneur-positionne.component.css']
})
export class ListeConteneurPositionneComponent implements OnInit {

  activeTab:String="profile";

  constructor() { }

  ngOnInit(): void {
  }

  search(activeTab:String){
    this.activeTab=activeTab
  }

}
