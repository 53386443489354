export class LIEUPOSITION {
  public id: number = 0;
  public lieu_position: string = "";
  public date: string = "";
  public idUser: number = 0;
  public modifierpar: number = 0;
  public datemodification: string = "";
  constructor() {
  }

}
