import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-export-tbord',
  templateUrl: './export-tbord.component.html',
  styleUrls: ['./export-tbord.component.css']
})
export class ExportTbordComponent implements OnInit {

  activeTab:String="profile";

  constructor() { }

  ngOnInit(): void {
  }

  search(activeTab:String){
    this.activeTab=activeTab
  }

}
