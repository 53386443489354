<div class="x_panel x_panel_close">
        <div class="x_content">
               <div class="x_panel_title x_panel_close">
                            <div class="x_title">
                              <h5>INFORMATIONS DOSSIER</h5>
                              <ul class="nav navbar-right panel_toolbox" style="padding-left: 50px;">
                              </ul>
                              <div class="clearfix"></div>
                            </div>
                            <div class="x_content">
                              <div class="row">
                                <div class="form-group col-xs-12 col-md-4">
                                 <div class="persoautocomplete">
                                  <label for="">Selectionner N°Dossier*</label>
                                 <input [(ngModel)]="fait_navire.numeroDossier" type="text" name="FaitDeclarationDoss" id="FaitDeclarationDoss" placeholder="N°Dossier"  class="form-control form-control-sm" autocomplete="off" (focus)="reInitSearch()" (blur)="reInitSearchBlur()" disabled="disabled">
                                   <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="">
                                   </button>
                                    <div class="classPerso  dropdown-menu">
                                      <form class="px-0 py-3">
                                          <div class="col-md-12">
                                              <div class="row">
                                                  <div class="col-md-12">
                                                    <input type="text" [(ngModel)]="fait_navire.NumDossSaisi" class="form-control form-control-sm" placeholder="N°Dossier" name="NavireSaisiFaitDeclaration" (ngModelChange)="ListeAutoCompleteDossier(fait_navire.NumDossSaisi)" autocomplete="off">
                                                    <div>
                                                      <br>
                                                        <table class="table_bloc_liste tableReseach">
                                                            <thead class="theadfixed">
                                                                <th>N°Dossier</th>
                                                            </thead>
                                                            <tbody>
                                                                <tr class="SelectTable"  *ngFor="let x of listedossiers" (click)="selectionAutocompleteDossier(x)">
                                                                    <td>{{x.numeroDossier}}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group col-xs-12 col-md-4">
                                  <label for="numero_dossier">Navire</label>
                                  <input type="text" class="form-control form-control-sm" id="Navire" name="Navire" [(ngModel)]="fait_navire.navire" [disabled]="true" placeholder="Navire">
                                </div>
                                <div class="form-group col-xs-12 col-md-4">
                                  <label for="numero_dossier">Agent Navire</label>
                                  <input type="text" class="form-control form-control-sm" id="agentnavire" name="agentnavire" [(ngModel)]="fait_navire.agentnavire" [disabled]="true" placeholder="Agent Navire">
                                </div>
                                  <div class="form-group col-xs-12 col-md-4">
                                    <label for="date_arrive_prevu">Date d'arrivée prévu</label>
                                    <input type="date" class="form-control form-control-sm" id="date_arrive_prevu" placeholder="Date d'arrivée prévu" [(ngModel)]="fait_navire.date_arrivee_prevu" [disabled]="true">

                                  </div>

                                  <div class="form-group col-xs-12 col-md-4">
                                    <label for="date_arrive_prevu">Date arrivée</label>
                                    <input type="date" class="form-control form-control-sm" id="date_arrive_prevu" placeholder="Date d'arrivée prévu" [(ngModel)]="fait_navire.date_arrivee" [disabled]="true">

                                  </div>

                                  <div class="form-group col-xs-12 col-md-4">
                                    <label for="date_arrive_prevu">Date départ</label>
                                    <input type="date" class="form-control form-control-sm" id="date_arrive_prevu" placeholder="Date d'arrivée prévu" [(ngModel)]="fait_navire.date_sortie" [disabled]="true">

                                  </div>
                              </div>
                            </div>
                        
                            <br>
                            <div class="x_panel_title x_panel_close" style="width: 99%;margin: 0px auto;display: table;">
                                <div class="x_title">
                                  <h5>DECLARATION FAITS NAVIRE</h5>
                                  <ul class="nav navbar-right panel_toolbox" style="padding-left: 50px;">
                                  </ul>
                                  <div class="clearfix"></div>
                                </div>
                                <div class="x_content">
                                  <div class="row">
                                          <div class="col-md-12">
                                          <div class="">
                                                <p>
                                                <button class="btn btn-sm btn-primary" (click)="enregistrer_sous_menu()" *ngIf="fait_navire.iddossier != 0">
                                                  <i class="fas fa-plus"></i>&nbsp;Nouveau
                                                </button>
                                                &nbsp;&nbsp;
                                                 <button class="btn btn-sm btn-secondary" (click)="SuivantPrecedent('prev')" *ngIf="fait_navire.iddossier != 0">
                                                    <i class="fas fa-chevron-left"></i>
                                                    &nbsp;&nbsp;&nbsp;Précédent&nbsp;
                                                  </button>
                                                  &nbsp;&nbsp;
                                                  <button class="btn btn-sm btn-secondary" (click)="SuivantPrecedent('next')" *ngIf="fait_navire.iddossier != 0">
                                                    Suivant&nbsp;&nbsp;&nbsp;
                                                    <i class="fas fa-chevron-right" style="color:#fff;"></i>
                                                 </button>
                                              </p>
                                              <table class="table_mainoeuvre_fourniture table_bloc_liste">
                                                    <thead>
                                                        <tr>
                                                          <th>Date</th>
                                                          <th>Time</th>
                                                          <th>Fait</th>
                                                          <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr *ngFor="let x of dataSousMenuModel;let i = index;" (click)="selectionligne(i, x.ordre)" [ngClass]="i == ligneSelectionne ? 'choix' : ''">
                                                        <td style="width:50px;">
                                                          <input type="date" class="form-control form-control-sm" [(ngModel)]="x.date" (ngModelChange)="UpdateFaitDeclarationNavire(x)">
                                                        </td>
                                                        <td style="width:200px;">
                                                          <input type="text" class="form-control form-control-sm" [(ngModel)]="x.time" (ngModelChange)="UpdateFaitDeclarationNavire(x)" placeholder="Time">
                                                        </td>
                                                        <td>
                                                          <input type="text" class="form-control form-control-sm" [(ngModel)]="x.fait" (ngModelChange)="UpdateFaitDeclarationNavire(x)" placeholder="Fait">
                                                        </td>
                                                        
                                                        <td class="text-center" style="width:75px;">
                                                          <button class="btn btn-danger btn-sm" type="button" (click)="supprimer_sous_menu(x.id_declaration_fait_navire)" title="supprimer">
                                                            <i class="fa fa-trash-alt" ></i>
                                                          </button>                                                 
                                                        </td>
                                                        </tr>
                                                        
                                                    </tbody>
                                              </table>
                                            </div>
                                      </div>
                                    </div>
                                </div>
                            </div>
                     <br>
                    <div class="col-md-12">
                        <div class="row">
                             <div class="col-sm-6 text-left mobile-buttons" *ngIf="dataSousMenuModel.length > 0">
                                <button class="btn btn-secondary btn-enregistrer" >
                                    <font style="vertical-align: inherit;">
                                        <font style="vertical-align: inherit;">Imprimer</font>
                                    </font>
                                </button>
                            </div>
                            <div class="col-sm-6 text-right mobile-buttons" *ngIf="dataSousMenuModel.length > 0">
                                <button class="btn btn-success btn-enregistrer" (click) = "Annuler()">
                                    <font style="vertical-align: inherit;">
                                        <font style="vertical-align: inherit;">Enregistrer</font>
                                    </font>
                                </button>
                            </div>
                      
                        </div>
                    </div>
                  </div>
                  <br><br>
                  <div class="row">
                      <div  class="col-md-9 form-group"></div>
                      <div  class="col-md-3 form-group">
                          <div  class="form-group">
                            <label >&nbsp;</label>
                            <button  class="btn btn-success btn-block btn-sm" (click)="Exporter()">
                              <i  class="fas fa-excel"></i>&nbsp;Exporter
                            </button>
                          </div>
                      </div>
                  </div>
                </div>
            </div>










