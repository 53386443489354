import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { magasin_attribute } from '../../../classes/magasin_attribue';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { AuthService } from '../../../services/connexion/auth.service';


@Component({
  selector: 'app-gp-attribuer-magasin',
  templateUrl: './gp-attribuer-magasin.component.html',
  styleUrls: ['./gp-attribuer-magasin.component.css']
})
export class GpAttribuerMagasinComponent implements OnInit {

    activeTab:String="saisir";
       apiUrl:String = "";
       listeAttribue_magasin:any = [];
       listeMagasin:any = [];
       listeutilisateur:any = [];
       recherche:any = {};
       magasin_attributeModel:magasin_attribute = new magasin_attribute();
       supp_id:any = "";

        constructor(private transfertDataService : transfertDataService,private AuthService : AuthService) { }

       ngOnInit(): void {
         this.Liste_des_magasin_attribute();
         this.Liste_des_magasin();
         this.Liste_des_user();

         this.recherche.NomMagasin = "";
         this.recherche.nom = "";
       }
       search(activeTab:String){
          this.activeTab=activeTab
        };

         Annuler():void{
           this.magasin_attributeModel = new magasin_attribute();
           this.recherche.magasin_attribute = "";
          }

       //Fonction de verification de champs
       VerificationChampsObligatoire(tableau_champs_obligatoires:any){
           var verification_formulaire = true;
           for (var i = 0; i < tableau_champs_obligatoires.length; i++) {
               if($(tableau_champs_obligatoires[i]).val() == "" || $(tableau_champs_obligatoires[i]).val() == null || $(tableau_champs_obligatoires[i]).val() == "? string: ?"){
                       $(tableau_champs_obligatoires[i]).css("border","1px solid red");
                       verification_formulaire = false;
                       document.getElementById(tableau_champs_obligatoires[i].replace("#",""))?.scrollIntoView({behavior: "smooth"});
                       break;
               }else{
                   $(tableau_champs_obligatoires[i]).css("border","");
               }
           }

           return verification_formulaire;
       }

       enregistrer(): void{
          /*if(this.VerificationChampsObligatoire(["#nomfourn"]) == false)
                 return;*/


          if(this.magasin_attributeModel.idmagasinattribue == 0)
            this.apiUrl = "SERVER/parametres/attribuermagasin/enregistrer";
          else
            this.apiUrl = "SERVER/parametres/attribuermagasin/modifier";
            this.magasin_attributeModel.idmagasin = Number(this.magasin_attributeModel.idmagasin);
            var infoUser = this.AuthService.getInfoUtilisateur();
             this.magasin_attributeModel.iduser = infoUser.id;

            var d = {"attribuermagasin": this.magasin_attributeModel};
            this.transfertDataService.EnvoiData(d, this.apiUrl).pipe(
                retryWhen(error =>
                  error.pipe(
                    scan((acc:any, error:any) => {
                      if (acc > 2) throw error;
                      return acc + 1;
                    }, 1),
                    delay(1000)
                  )
                )
            ).subscribe((value:any) => {

              this.Liste_des_magasin_attribute();
              this.search("profile");
              this.Annuler();


            }, (erreur:any) => {
                console.log(erreur.status);
            })

        }

       supprimer(id:any):void{
          this.apiUrl = "SERVER/parametres/attribuermagasin/supprimer";
          var data = {id:id};
          this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
            retryWhen(error =>
              error.pipe(
                scan((acc:any, error:any) => {
                  if (acc > 2) throw error;
                  return acc + 1;
                }, 1),
                delay(1000)
              )
            )
        ).subscribe((value:any) => {
            this.Liste_des_magasin_attribute();
          }, (erreur:any) => {
              console.log(erreur.status);
          })

        }



        Liste_des_magasin_attribute(): void{

          this.apiUrl = "SERVER/parametres/attribuermagasin/listeattribuermagasin";
          var data = {};
          this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
              retryWhen(error =>
                error.pipe(
                  scan((acc:any, error:any) => {
                    if (acc > 2) throw error;
                    return acc + 1;
                  }, 1),
                  delay(1000)
                )
              )
          ).subscribe((response:any) => {
            this.listeAttribue_magasin = response.liste_attribuermagasin;
          }, (erreur:any) => {
              console.log(erreur.status);
          })

        }

        Liste_des_magasin(): void{

          this.apiUrl = "SERVER/parametres/attribuermagasin/listeMagasins";
          var data = {};
          this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
              retryWhen(error =>
                error.pipe(
                  scan((acc:any, error:any) => {
                    if (acc > 2) throw error;
                    return acc + 1;
                  }, 1),
                  delay(1000)
                )
              )
          ).subscribe((response:any) => {
            this.listeMagasin = response.liste_Magasin;
          }, (erreur:any) => {
              console.log(erreur.status);
          })

        }

        Liste_des_user(): void{

          this.apiUrl = "SERVER/parametres/attribuermagasin/liste_user";
          var data = {};
          this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
              retryWhen(error =>
                error.pipe(
                  scan((acc:any, error:any) => {
                    if (acc > 2) throw error;
                    return acc + 1;
                  }, 1),
                  delay(1000)
                )
              )
          ).subscribe((response:any) => {
            this.listeutilisateur = response.liste_user;
          }, (erreur:any) => {
              console.log(erreur.status);
          })

        }


        information(id:any):void{

              this.apiUrl = "SERVER/parametres/attribuermagasin/information";
              var data = {id:id};
              this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
                retryWhen(error =>
                  error.pipe(
                    scan((acc:any, error:any) => {
                      if (acc > 2) throw error;
                      return acc + 1;
                    }, 1),
                    delay(1000)
                  )
                )
            ).subscribe((response:any) => {
                  this.magasin_attributeModel = response.liste_attribuermagasinAfficher[0];
                  this.search("saisir");

              }, (erreur:any) => {
                console.log(erreur.status);
            })

        }

        rechercheMagasin(data:any): void{
          if(this.recherche.NomMagasin == "" && this.recherche.nom == "")
          this.Liste_des_magasin_attribute();
          else{
            this.apiUrl = "SERVER/parametres/attribuermagasin/recherche";
            var data = data;
            this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
              retryWhen(error =>
                error.pipe(
                  scan((acc:any, error:any) => {
                    if (acc > 2) throw error;
                    return acc + 1;
                  }, 1),
                  delay(1000)
                )
              )
          ).subscribe((value:any) => {
              this.listeAttribue_magasin= value.liste_attribuermagasin;
            }, (erreur:any) => {
                console.log(erreur.status);
            })
          }

      }

     SupprimerPopup(id:any): void{
       $(".suppliste").trigger("click");
       this.supp_id = id;
     }

}
