export class BUDGET_CLIENT_NAVIRE {
  public id_budget_client: number = 0;
	public id_budget_navire: number = 0;
	public idClient: number = 0;
	public qte_prevu: number = 0;
	public poids_prevu: number = 0;
	public iddossier: number = 0;
	public budget_client: number = 0;
  public date_creation: string = "";
  constructor() {

  }

}
