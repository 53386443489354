export class reglementclient {
  public idReglementClient: number = 0;
  public idReglementNavire: number = 0;
  public Netapayer: number = 0;
  public Perte: number = 0;
  public idClient: number = 0;
  public AchatRiz: number = 0;
  public MontantRegle: number = 0;
  public dateCreation: string = "";
  public dateModif: string = "";
  public creerPar: number = 0;
  public modifierPar: number = 0;

  constructor() {

  }

}
