import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType
 } from '@angular/common/http';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import {DomSanitizer} from '@angular/platform-browser';
import { pipe } from 'rxjs';
import { AuthService } from '../../../services/connexion/auth.service';
import { ConfigurationService } from '../../../config/configuration.service';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';

@Component({
  selector: 'app-parametre-imprime',
  templateUrl: './parametre-imprime.component.html',
  styleUrls: ['./parametre-imprime.component.css']
})
export class ParametreImprimeComponent implements OnInit {
   apiUrl:String = "";
  activeTab:String="saisir";
  photos_previsualisation:any = [];
  array_files:any = [];
  imprime:any = {};
  recherche:any = {};
  liste:any = [];
  supp_id:any = "";
  constructor(private sanitizer:DomSanitizer, private transfertDataService : transfertDataService, private AuthService : AuthService, private ConfigurationService : ConfigurationService, private HttpClient : HttpClient) { }

  ngOnInit(): void {
    this.listeImprime();
    this.recherche.pied_page = "";
  }

  search(activeTab:String){
     this.activeTab=activeTab
   };

    Annuler(){
      this.imprime = {};
      this.array_files = [];
      this.photos_previsualisation = [];
    }

   Enregistrer(data:any): void{

    if(this.imprime.id == 0 || this.imprime.id == undefined){
      this.apiUrl = "SERVER/parametres/imprime/enregistrer";
    }else{
      this.apiUrl = "SERVER/parametres/imprime/modifier";
    }

      var infoUser = this.AuthService.getInfoUtilisateur();
      this.imprime.iduser = infoUser.id;

      var dt = {"data": this.imprime};
      var mData = JSON.stringify(dt);
      var formData = new FormData();

      formData.append('data', mData);
      formData.append('typedoc', '1');
      if (this.array_files.length > 0) {
        for (var i = 0; i < this.array_files.length; i++) {
            formData.append("file["+i+"]", this.array_files[i]);
        }
      }
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data; charset=utf-8; boundary=' + Math.random().toString().substr(2));
      headers.append('Accept', 'application/json');

      this.HttpClient.post<any>(this.ConfigurationService.configuration.hostname + this.apiUrl, formData, {headers}).subscribe((resp:any) => {
          this.listeImprime();
          this.Annuler();
          this.search("profile");
      })
  }

  listeImprime(): void{
    this.apiUrl = "SERVER/parametres/imprime/listedossier";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
      this.liste = response.listedossier;
    }, (erreur:any) => {
    })
  }

  information(id:any): void{
      this.apiUrl = "SERVER/parametres/imprime/information";
      var data = {id:id};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
          this.imprime = response.listedossier[0];
          this.imprime.id = response.listedossier[0].id;
          for(var i = 0; i < response.listedossier.length; i++){
            response.listedossier[i].src = this.ConfigurationService.configuration.ServerWebHostname + "/" + response.listedossier[i].chemin
            this.photos_previsualisation = response.listedossier;
           }
          this.search("saisir");
      }, (erreur:any) => {
        console.log(erreur.status);
    })
  }

  supprimer(id:any): void{
      this.apiUrl = "SERVER/parametres/imprime/supprimer";
      var data = {id:id};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listeImprime();
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }



  apercu(iddocument:any): void{
    var element = <HTMLImageElement> $("#file_" + iddocument).get(0);
    element.requestFullscreen();
  }

  supprimer_image(nomfichier:any, iddocument:any) : void{
        if(iddocument == ""){
            for (var i = 0; i < this.array_files.length; i++) {

                if(this.array_files[i].name == nomfichier){
                    this.array_files.splice(i, 1);
                }
            }

            for (var i = 0; i < this.photos_previsualisation.length; i++) {
                if(this.photos_previsualisation[i].lien == nomfichier){
                    this.photos_previsualisation.splice(i, 1);
                }
            }
        }else{
            var apiUrl = "SERVER/gestionnavire/navire/SupprimerFile";
            var data = {id:iddocument};
            this.transfertDataService.EnvoiData(data, apiUrl).pipe(
               retryWhen(error =>
                 error.pipe(
                   scan((acc:any, error:any) => {
                     if (acc > 2) throw error;
                     return acc + 1;
                   }, 1),
                   delay(1000)
                 )
               )
            ).subscribe((value:any) => {
                for (var i = 0; i < this.photos_previsualisation.length; i++) {
                    if(this.photos_previsualisation[i].iddocument == iddocument){
                        this.photos_previsualisation.splice(i, 1);
                    }
                }
             }, (erreur:any) => {
            })
        }

  }

  onFileChanged(event: any): void {
        this.array_files = [];
        var src = URL.createObjectURL(event.target.files[0]);
        var elements = event.target.files;
        for(var i = 0; i < elements.length; i++){
          var src = URL.createObjectURL(event.target.files[i]);
          this.photos_previsualisation.push({iddocument : '', src : this.sanitizer.bypassSecurityTrustUrl(src), lien : event.target.files[i]});
          this.array_files.push(event.target.files[i]);
        }
  }

  Recherche(): void{
      this.apiUrl = "SERVER/parametres/imprime/recherche";
      var data = this.recherche;
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
      if(this.recherche.pied_page == "" ){
        this.listeImprime();
      }
       this.liste = value.listedossier;
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }

  SupprimerPopup(id:any): void{
    $(".suppliste").trigger("click");
    this.supp_id = id;
  }


}
