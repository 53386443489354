import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-saisir-prevision-relevage-tc',
  templateUrl: './saisir-prevision-relevage-tc.component.html',
  styleUrls: ['./saisir-prevision-relevage-tc.component.css']
})
export class SaisirPrevisionRelevageTcComponent implements OnInit {

  activeTab:String="saisir";

  constructor() { }

  ngOnInit(): void {
  }

  search(activeTab:String){
    this.activeTab=activeTab
  }

}
