import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '../../../config/configuration.service';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { AuthService } from '../../../services/connexion/auth.service';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { CRUDService } from '../../../services/CRUD/crud.service';

@Component({
  selector: 'app-stock-disponible-fourniture',
  templateUrl: './stock-disponible-fourniture.component.html',
  styleUrls: ['./stock-disponible-fourniture.component.css']
})
export class StockDisponibleFournitureComponent implements OnInit {

  recherche:any = {};
  Auto:any = {};
  liste:any = [];
  listefournisseur:any = [];
  listecategorieproduit:any = [];
  lien_fiche_ExpST:any;
  constructor(private AuthService : AuthService, private transfertDataService : transfertDataService, private ConfigurationService : ConfigurationService, private CRUDService : CRUDService) { }

  ngOnInit(): void {
    this.recherche.numProduit = "";
    this.recherche.NomProd = "";
    this.recherche.idfournisseur = "";
     this.recherche.date1 = "";
    this.recherche.date2 = "";
    this.Listefournisseur();
    this.Listecategorieproduit();
  }

  Recherche(){
     if(this.recherche.numProduit == "" &&  this.recherche.date1 == "" && this.recherche.date2 == "" && this.recherche.NomProd == "" && this.recherche.idfournisseur == ""){
        this.liste = [];
    }else{
    var apiUrl = "SERVER/GestionStock/stockdisponible/recherchedoss";
    this.transfertDataService.EnvoiData(this.recherche, apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
      ).subscribe((value:any) => {
         this.liste = value.data;
       }, (erreur:any) => {
      })
    }
  }


  Listefournisseur():void{
      var url = "SERVER/g/api/liste";
      this.CRUDService.CRUD("fournisseur", this.Auto, url).subscribe((value:any) => {
          this.listefournisseur = value.data;
         }, (erreur:any) => {
  
        });
    }

 Listecategorieproduit():void{
      var url = "SERVER/g/api/liste";
      this.CRUDService.CRUD("categorieproduit", this.Auto, url).subscribe((value:any) => {
          this.listecategorieproduit = value.data;
         }, (erreur:any) => {
  
        });
    }


    ConfirmationImpressionFormulaire(){
   this.lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/ReportingStockPetitesFournitures/Gestion_stock/pdf_article.php?numProduit="+this.recherche.numProduit+"&idfournisseur="+this.recherche.idfournisseur+"&NomProd="+this.recherche.NomProd+"&date1="+this.recherche.date1+"&date2="+this.recherche.date2;
   $("#printRapport").remove();
   $("body").append('<div id="printRapport">\
                       <div class="col-md-12 form-group" style="display:none;">\
                         <button class="btn btn-primary btn-sm bs-modal-frame-sm-btn" data-backdrop="static" data-toggle="modal" data-target=".bs-modal-frame-sm"></button>\
                       </div>\
                       <div class="modal fade bs-modal-frame-sm" tabindex="-1" role="dialog" aria-hidden="true">\
                         <div class="modal-dialog" role="document">\
                             <div class="modal-content">\
                                 <div class="row container_modal" style="width:100%;">\
                                 <div class="modal-header" style="margin-bottom:0px;">\
                                   <h6 class="modal-title" style="font-weight: bold;">REPORTING STOCK PRODUIT</h6>\
                                   <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>\
                                   </button>\
                                 </div>\
                                 <div class="row col-md-12" style="width:100%;height: calc(100vh - 90px);padding-left: 0px; padding-right: 0px; margin-left: 0px; margin-right: 0px;">\
                                   <div class="col-md-12 waiting">\
                                     <div class="snippet" data-title=".dot-pulse">\
                                       <div class="stage">\
                                         <div class="dot-pulse"></div>\
                                       </div>\
                                     </div>\
                                   </div>\
                                   <iframe class="rapportframe" name="rapportframe" style="width:100%;"></iframe>\
                                 </div>\
                             </div>\
                           </div>\
                         </div>\
                       </div>\
                   </div>');
   $(".bs-modal-frame-sm-btn").trigger("click");
   window.open(this.lien_fiche_ExpST , "rapportframe")?.focus();
   $(".rapportframe").css("display", "none");
   $('.rapportframe').on('load', function(){
       $(".waiting").css("display", "none");
       $(".rapportframe").css("display", "initial");
   });
 }

 ExporterExcel(){
    var lien = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/FicheExcel/reporting.php?numProduit="+this.recherche.numProduit+"&idfournisseur="+this.recherche.idfournisseur+"&NomProd="+this.recherche.NomProd+"&date1="+this.recherche.date1+"&date2="+this.recherche.date2+"&action=Stockearticle";
    window.open(lien , '_blank')?.focus();
}

}
