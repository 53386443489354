import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mouvement-conteneur-export',
  templateUrl: './mouvement-conteneur-export.component.html',
  styleUrls: ['./mouvement-conteneur-export.component.css']
})
export class MouvementConteneurExportComponent implements OnInit {

 activeTab:String="profile";

  constructor() { }

  ngOnInit(): void {
  }

  search(activeTab:String){
    this.activeTab=activeTab
  }

}
