import { Component, OnInit } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpEventType
 } from '@angular/common/http';
 import { AuthService } from '../../../services/connexion/auth.service';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { proforma_escale } from '../../../classes/proforma_escale';
import { elt_proforma_escale } from '../../../classes/elt_proforma_escale';
import { fournisseur } from '../../../classes/fournisseur';
import { DOSSIER } from '../../../classes/Dossier';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { ConfigurationService } from '../../../config/configuration.service';
@Component({
  selector: 'app-saisir-proforma',
  templateUrl: './saisir-proforma.component.html',
  styleUrls: ['./saisir-proforma.component.css']
})
export class SaisirProformaComponent implements OnInit {
  apiUrl:String = "";
  activeTab:String="saisir";
  listnavire:any = [];
  listproforma:any = [];
  listefournisseur:any = [];
listeoperation:any = [];
  suppelt:any = {};
  photos_previsualisation:any = [];
  array_files:any = [];
  dataSousMenuModel:any = [];
  total:any={};
  dossier:any={};
    recherche:any={};
  listdoss:any = [];
  ligneSelectionne:any = -1;
  precLine:any = 0;
  MTTVA:any = 0;
  MTTTC:any = 0;
  MTHT:any = 0;
  MTTVA_euro:any = 0;
  MTTTC_euro:any = 0;
  MTHT_euro:any = 0;
  MTR:any = 0;
  montantRemise:any = 0;
montantTVA:any = 0;
montantTTC:any = 0;
activePagination:number = 0;
liste_pagination:any = [];
  proformaModel:proforma_escale = new proforma_escale();
  elt_proforma:elt_proforma_escale = new elt_proforma_escale();
  fournisseur:fournisseur = new fournisseur();
  DOSSIERModel:DOSSIER = new DOSSIER();

  constructor(private HttpClient : HttpClient, private AuthService : AuthService,private sanitizer:DomSanitizer, private transfertDataService : transfertDataService, private ConfigurationService : ConfigurationService) { }


  ngOnInit(): void {
    this.listeFournisseur();
      this.listeProforma();
      this.listeOperation();
      this.proformaModel.tauxEuro=655;
      this.recherche.numeroDossier= "";
      this.recherche.nomfourn= "";
      this.recherche.date1= "";
      this.recherche.date2= "";
  }
  CurrentPagePagination(data:any, index:number, ev:any): void{
    this.activePagination = index;
    this.PaginationNb(index);
  }

PaginationNb(index:number) : void{
this.recherche.index = index;
var apiUrl = "SERVER/GestionCompteEscale/proforma/Pagination";
  this.transfertDataService.EnvoiData(this.recherche, apiUrl).pipe(
     retryWhen(error =>
       error.pipe(
         scan((acc:any, error:any) => {
           if (acc > 2) throw error;
           return acc + 1;
         }, 1),
         delay(1000)
       )
     )
  ).subscribe((value:any) => {
     this.listproforma = value.data;
   }, (erreur:any) => {
  })

}

Calcule() : void{
var apiUrl = "SERVER/GestionCompteEscale/proforma/CalculePagination";
  this.transfertDataService.EnvoiData(this.recherche, apiUrl).pipe(
     retryWhen(error =>
       error.pipe(
         scan((acc:any, error:any) => {
           if (acc > 2) throw error;
           return acc + 1;
         }, 1),
         delay(1000)
       )
     )
  ).subscribe((value:any) => {
     this.listproforma = value.data;
     this.liste_pagination= value.pagination;
   }, (erreur:any) => {
  })
}

  Tabulation(activeTab:any): void{
    this.activeTab=activeTab;
  }
  VerificationChampsObligatoire(tableau_champs_obligatoires:any){
      var verification_formulaire = true;
      for (var i = 0; i < tableau_champs_obligatoires.length; i++) {
          if($(tableau_champs_obligatoires[i]).val() == "" || $(tableau_champs_obligatoires[i]).val() == null || $(tableau_champs_obligatoires[i]).val() == "? string: ?"){
                  $(tableau_champs_obligatoires[i]).css("border","1px solid red");
                  verification_formulaire = false;
                  document.getElementById(tableau_champs_obligatoires[i].replace("#",""))?.scrollIntoView({behavior: "smooth"});
                  break;
          }else{
              $(tableau_champs_obligatoires[i]).css("border","");
          }
      }

      return verification_formulaire;
  }

  Annuler(){
    this.proformaModel = new proforma_escale();
    this.dataSousMenuModel = [];
    this.array_files = [];
    this.photos_previsualisation = [];
    this.listdoss = [];
    this.MTTVA = 0;
    this.MTTTC = 0;
    this.MTHT = 0;
    this.proformaModel.tauxEuro=655;
    this.MTTVA_euro = 0;
    this.MTTTC_euro = 0;
    this.MTHT_euro = 0;
  this.DOSSIERModel= new DOSSIER();
  }
  CalculHT(): void{

console.log(this.dataSousMenuModel);

   this.MTTVA = 0;
    this.MTR = 0;
    this.MTHT = 0;
    this.MTTTC = 0;
    this.MTTVA_euro = 0;
    this.MTTTC_euro = 0;
    this.MTHT_euro = 0;

    for(let i = 0; i < this.dataSousMenuModel.length; i++){
      if (isNaN(this.dataSousMenuModel[i].tva)) {
        this.dataSousMenuModel[i].tva=0;
      }
      this.dataSousMenuModel[i].montant_ht_cfa = this.dataSousMenuModel[i].prix_unitaire * this.dataSousMenuModel[i].nbre;

      this.montantRemise = this.dataSousMenuModel[i].montant_ht_cfa * (this.dataSousMenuModel[i].remise/100);
      this.dataSousMenuModel[i].montant_ht_cfa = this.dataSousMenuModel[i].montant_ht_cfa - this.montantRemise;
      this.dataSousMenuModel[i].montant_ht_euro = this.dataSousMenuModel[i].montant_ht_cfa * this.proformaModel.tauxEuro;
      this.montantTTC = this.dataSousMenuModel[i].montant_ht_cfa + this.montantTVA;

      this.MTTVA = (this.dataSousMenuModel[i].montant_ht_cfa * (this.dataSousMenuModel[i].tva/100)) + this.MTTVA;
      this.MTTVA_euro = (this.dataSousMenuModel[i].montant_ht_euro * (this.dataSousMenuModel[i].tva/100)) + this.MTTVA;


      this.MTHT_euro = this.MTHT_euro + this.dataSousMenuModel[i].montant_ht_euro;
      this.MTHT = this.MTHT + this.dataSousMenuModel[i].montant_ht_cfa;
      this.MTTTC =  (this.MTHT + this.MTTVA);
      this.MTTTC_euro =  (this.MTHT_euro + this.MTTVA_euro);
    }


  }
  ListeAutoCompleteDossier(numerodossier:any){
   this.apiUrl = "SERVER/GestionCompteEscale/proforma/recherche_dossier";
      var data = {numeroDossier:numerodossier};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listdoss= value.listdoss;
        //console.log(value);

      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }
  SelectionAutocomplete(data:any): void{
  //  console.log(data.elt[0].id_proforma);

    this.DOSSIERModel.numeroDossier = data.numeroDossier;
    this.DOSSIERModel.Navire = data.Navire;
    this.proformaModel.id_dossier = data.idGestionNavire;
    this.proformaModel.id_proforma=  data.elt[0].id_proforma;

    this.dataSousMenuModel= data.elt;
    this.MTTVA = data.montant_tva;
    this.MTTTC = data.montant_ttc;
    this.MTHT = data.montant_ht;
    this.MTTVA_euro = data.montant_tva_ero;
    this.MTTTC_euro = data.montant_ttc_ero;
    this.MTHT_euro = data.montant_ht_ero;
    this.CalculHT();

  }

  reInitSearchForm(): void{
    this.listdoss = [];
  }

  reInitSearchBlurForm(): void{
    setTimeout((val:any) => {
        this.listdoss = [];
    }, 500);
  }

  onFileChanged(event: any): void {
        this.array_files = [];
        var src = URL.createObjectURL(event.target.files[0]);
        var elements = event.target.files;
        for(var i = 0; i < elements.length; i++){
          var src = URL.createObjectURL(event.target.files[i]);
          this.photos_previsualisation.push({iddocument : '', src : this.sanitizer.bypassSecurityTrustUrl(src), lien : event.target.files[i]});
          this.array_files.push(event.target.files[i]);
        }
  }

  listeOperation(){
   this.apiUrl = "SERVER/GestionCompteEscale/proforma/listeOperationEscal";
      var data = {};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listeoperation = value;
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }

  listeFournisseur(){
   this.apiUrl = "SERVER/GestionCompteEscale/proforma/listeFournisseur";
      var data = {};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listefournisseur = value;
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }
  selectionligne(index:any, ordre:any){
    this.ligneSelectionne = index;
    this.precLine = ordre;
  }

  SuivantPrecedent(action:any){
    if(this.ligneSelectionne == -1)
      return;
    var apiUrl = "SERVER/GestionCompteEscale/proforma/SuivantPrecedent";
    var data = {"Ligne" : this.precLine, ac:action, "id_proforma" : this.proformaModel.id_proforma};
    this.transfertDataService.EnvoiData(data, apiUrl).subscribe((response:any) => {
        this.listeSousMenuMenus();
        this.ligneSelectionne = -1;
    });

  }
    ajouter(): void{
      if(this.proformaModel.id_proforma == 0){
        var sousmenu = new elt_proforma_escale();
        this.dataSousMenuModel.push(sousmenu);
      }
      else{
        this.enregistrer_sous_menu();
      }

    }


    enregistrer_sous_menu(): void{
console.log(this.proformaModel);

        var data = {
            "id_elt_proforma": 0,
            "id_proforma": Number(this.proformaModel.id_proforma),
            "date": "",
            "libelle": "",
            "prix_unitaire": 0,
            "nbre": 0,
            "ordre": 0,
            "tva": 0,
            "remise": 0,
            "montant_ht_euro": 0,
            "montant_ht_cfa": 0,
            "date_creation":  "",
            "type_activite":  ""
        }
        console.log(data);

        this.apiUrl = "SERVER/GestionCompteEscale/proforma/enregistrersousmenu";


        this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
          retryWhen(error =>
            error.pipe(
              scan((acc:any, error:any) => {
                if (acc > 2) throw error;
                return acc + 1;
              }, 1),
              delay(1000)
            )
          )
        ).subscribe((value:any) => {
          this.listeSousMenuMenus();
        }, (erreur:any) => {
            console.log(erreur.status);
        })

    }
    UpdateEltProforma(elt:any){
 if (this.proformaModel.id_proforma == 0) {
   this.CalculHT();
 }else{
   this.apiUrl = "SERVER/GestionCompteEscale/proforma/modifiersousmenu";
   var data = {elt:elt};
   this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
     retryWhen(error =>
       error.pipe(
         scan((acc:any, error:any) => {
           if (acc > 2) throw error;
           return acc + 1;
         }, 1),
         delay(1000)
       )
     )
 ).subscribe((value:any) => {
   this.CalculHT();
     /*this.totalPoidsDossier = value.totalPoidsDossier;
     this.totalQteDossier = value.totalQteDossier;
     this.DOSSIERModel.total_poids = value.totalPoidsDossier;
     this.DOSSIERModel.total_qte = value.totalQteDossier;*/
   }, (erreur:any) => {

 })
 }



    }
    Enregistrer(data:any, event:any): void{
      if(this.VerificationChampsObligatoire(["#numdossForm"]) == false)
          return;
           event.target.disabled = true;
      if(this.proformaModel.id_proforma == 0){
        this.apiUrl = "SERVER/GestionCompteEscale/proforma/enregistrer";
      }
      else{
        this.apiUrl = "SERVER/GestionCompteEscale/proforma/modifier";
      }

      var infoUser = this.AuthService.getInfoUtilisateur();
      this.proformaModel.id_user = infoUser.id;
      this.total.MTTVA = Number(this.MTTVA);
      this.total.MTHT = Number(this.MTHT);
      this.total.MTTTC = Number(this.MTTTC);
      this.total.MTTVA_euro = Number(this.MTTVA_euro);
      this.total.MTTTC_euro = Number(this.MTTTC_euro);
      this.total.MTHT_euro = Number(this.MTHT_euro);



      var d = {"proforma": this.proformaModel, "sousmenu" : this.dataSousMenuModel, "total" :this.total};
      //d.dechargement.jour_nuit = String(d.dechargement.jour_nuit);
      d.proforma.idfournisseur = Number(d.proforma.idfournisseur);
      d.proforma.id_user = Number(d.proforma.id_user);
        var mData = JSON.stringify(d);
        var formData = new FormData();

        formData.append('data', mData);
        formData.append('typedoc', '8');
        if (this.array_files.length > 0) {
          for (var i = 0; i < this.array_files.length; i++) {
              formData.append("file["+i+"]", this.array_files[i]);
          }
        }
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data; charset=utf-8; boundary=' + Math.random().toString().substr(2));
        headers.append('Accept', 'application/json');

        this.HttpClient.post<any>(this.ConfigurationService.configuration.hostname + this.apiUrl, formData, {headers}).subscribe((resp:any) => {
              event.target.disabled = false;
            this.listeProforma();
            this.Annuler();
            this.Tabulation("profile");
        })
    }
    listeProforma(): void{
      this.apiUrl = "SERVER/GestionCompteEscale/proforma/liste";
      var data = {};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
          retryWhen(error =>
            error.pipe(
              scan((acc:any, error:any) => {
                if (acc > 2) throw error;
                return acc + 1;
              }, 1),
              delay(1000)
            )
          )
      ).subscribe((response:any) => {
        this.listproforma = response.data;
        this.liste_pagination= response.pagination;
      }, (erreur:any) => {
          console.log(erreur.status);
      })
    }



       AfficherDonner(id:any): void{
           this.apiUrl = "SERVER/GestionCompteEscale/proforma/information";
           var data = {id_proforma:id};
           this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
             retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((response:any) => {
               this.proformaModel = response.menu[0];
               this.dataSousMenuModel = response.smenu;
               this.proformaModel.id_proforma = response.menu[0].id_proforma;
               this.DOSSIERModel.numeroDossier=response.menu[0].numeroDossier;
               this.DOSSIERModel.Navire=response.menu[0].Navire;

               this.MTTVA =response.menu[0].montant_tva;
               this.MTHT =response.menu[0].montant_ht ;
               this.MTTTC=response.menu[0].montant_ttc;
               this.MTTVA_euro =response.menu[0].montant_tva_ero;
               this.MTHT_euro =response.menu[0].montant_ht_ero ;
               this.MTTTC_euro=response.menu[0].montant_ttc_ero;
               /*this.dechargementModel.poids_total = response.listedossier[0].total_poids;
               this.dechargementModel.qte_total = response.listedossier[0].total_qte;

               this.totalPoidsDossier = response.totalPoidsDossier;
               this.totalQteDossier = response.totalQteDossier;*/
               this.ligneSelectionne = -1;
               for(var i = 0; i < response.photos.length; i++){
                 response.photos[i].src = this.ConfigurationService.configuration.ServerWebHostname + "/" + response.photos[i].src;
                 this.photos_previsualisation = response.photos;
                }
               this.Tabulation("saisir");
           }, (erreur:any) => {
             console.log(erreur.status);
         })
       }
    listeSousMenuMenus(): void{
      this.apiUrl = "SERVER/GestionCompteEscale/proforma/listesousmenu";
      var data = {id_proforma:this.proformaModel.id_proforma};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
          retryWhen(error =>
            error.pipe(
              scan((acc:any, error:any) => {
                if (acc > 2) throw error;
                return acc + 1;
              }, 1),
              delay(1000)
            )
          )
      ).subscribe((response:any) => {
        this.dataSousMenuModel = response.liste_sous_menu;
        this.CalculHT();


      }, (erreur:any) => {
          console.log(erreur.status);
      })
    }
    supprimer_sous_menu(idsousmenu:any, index:any): void{

        if(this.proformaModel.id_proforma == 0){
          this.dataSousMenuModel.splice(index,1);
          this.CalculHT();
        }else{
            this.apiUrl = "SERVER/GestionCompteEscale/proforma/supprimersousmenu";
              var data = {id_elt_proforma:idsousmenu, id_proforma : this.proformaModel.id_proforma};
              this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
                retryWhen(error =>
                  error.pipe(
                    scan((acc:any, error:any) => {
                      if (acc > 2) throw error;
                      return acc + 1;
                    }, 1),
                    delay(1000)
                  )
                )
            ).subscribe((value:any) => {

                this.listeSousMenuMenus();

              }, (erreur:any) => {

            })
        }

    }
    downloadDoc(id_proforma:any){
      var apiUrl = "SERVER/GestionCompteEscale/proforma/zip";
        var data = {id_proforma:id_proforma};
        this.transfertDataService.EnvoiData(data, apiUrl).pipe(
           retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
        ).subscribe((value:any) => {
           window.open(this.ConfigurationService.configuration.hostname + value.link , "_self")?.focus();
         }, (erreur:any) => {
        })
    }
    SupprimerPopup(id:any): void{
      $(".suppdossier").trigger("click");
      this.suppelt.id = id;
    }

    Supprimer(): void{
        this.apiUrl = "SERVER/GestionCompteEscale/proforma/supprimerProforma";
        var data = {id_proforma:this.suppelt.id};
        this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
          retryWhen(error =>
            error.pipe(
              scan((acc:any, error:any) => {
                if (acc > 2) throw error;
                return acc + 1;
              }, 1),
              delay(1000)
            )
          )
      ).subscribe((value:any) => {
          this.listeProforma();
        }, (erreur:any) => {
            console.log(erreur.status);
        })
    }

      apercu(iddocument:any): void{
        var element = <HTMLImageElement> $("#file_" + iddocument).get(0);
        element.requestFullscreen();
      }
      supprimer_image(nomfichier:any, iddocument:any) : void{
            if(iddocument == ""){
                for (var i = 0; i < this.array_files.length; i++) {

                    if(this.array_files[i].name == nomfichier){
                        this.array_files.splice(i, 1);
                    }
                }

                for (var i = 0; i < this.photos_previsualisation.length; i++) {
                    if(this.photos_previsualisation[i].lien == nomfichier){
                        this.photos_previsualisation.splice(i, 1);
                    }
                }
            }else{
                var apiUrl = "SERVER/GestionCompteEscale/proforma/SupprimerFile";
                var data = {id:iddocument};
                this.transfertDataService.EnvoiData(data, apiUrl).pipe(
                   retryWhen(error =>
                     error.pipe(
                       scan((acc:any, error:any) => {
                         if (acc > 2) throw error;
                         return acc + 1;
                       }, 1),
                       delay(1000)
                     )
                   )
                ).subscribe((value:any) => {
                    for (var i = 0; i < this.photos_previsualisation.length; i++) {
                        if(this.photos_previsualisation[i].iddocument == iddocument){
                            this.photos_previsualisation.splice(i, 1);
                        }
                    }
                 }, (erreur:any) => {
                })
            }

      }
      RechercheProfrma(data:any): void{
          this.apiUrl = "SERVER/GestionCompteEscale/proforma/rechercheProf";
          var data = data;
          if(this.recherche.numeroDossier == "" && this.recherche.nomfourn == "" && this.recherche.date1 == "" && this.recherche.date2 == ""){
            this.listeProforma();
            return;
          }
          this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
            retryWhen(error =>
              error.pipe(
                scan((acc:any, error:any) => {
                  if (acc > 2) throw error;
                  return acc + 1;
                }, 1),
                delay(1000)
              )
            )
        ).subscribe((value:any) => {

          this.listproforma= value.data;

          }, (erreur:any) => {
              console.log(erreur.status);
          })
      }
      Exporter(): void{
          var lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/GestionCompteEscale/excel_Proforma.php?numeroDossier="+this.recherche.numeroDossier+"&nomfourn="+this.recherche.nomfourn+"&date1="+this.recherche.date1+"&date2="+this.recherche.date2;
            window.open(lien_fiche_ExpST , '_blank')?.focus();
      }
      ConfirmationImpressionFormulaire(){
         var lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/GestionCompteEscale/pdfProforma.php?numeroDossier="+this.recherche.numeroDossier+"&nomfourn="+this.recherche.nomfourn+"&date1="+this.recherche.date1+"&date2="+this.recherche.date2;
       $("#printRapport").remove();
         $("body").append('<div id="printRapport">\
                             <div class="col-md-12 form-group" style="display:none;">\
                               <button class="btn btn-primary btn-sm bs-modal-frame-sm-btn" data-backdrop="static" data-toggle="modal" data-target=".bs-modal-frame-sm"></button>\
                             </div>\
                             <div class="modal fade bs-modal-frame-sm" tabindex="-1" role="dialog" aria-hidden="true">\
                               <div class="modal-dialog" role="document">\
                                   <div class="modal-content">\
                                       <div class="row container_modal" style="width:100%;">\
                                       <div class="modal-header" style="margin-bottom:0px;">\
                                         <h6 class="modal-title" style="font-weight: bold;">ETAT RECAPITULATIF COMPTE ESCALE</h6>\
                                         <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>\
                                         </button>\
                                       </div>\
                                       <div class="row col-md-12" style="width:100%;height: calc(100vh - 90px);padding-left: 0px; padding-right: 0px; margin-left: 0px; margin-right: 0px;">\
                                         <div class="col-md-12 waiting">\
                                           <div class="snippet" data-title=".dot-pulse">\
                                             <div class="stage">\
                                               <div class="dot-pulse"></div>\
                                             </div>\
                                           </div>\
                                         </div>\
                                         <iframe class="rapportframe" name="rapportframe" style="width:100%;"></iframe>\
                                       </div>\
                                   </div>\
                                 </div>\
                               </div>\
                             </div>\
                         </div>');
         $(".bs-modal-frame-sm-btn").trigger("click");
         window.open(lien_fiche_ExpST , "rapportframe")?.focus();
         $(".rapportframe").css("display", "none");
         $('.rapportframe').on('load', function(){
             $(".waiting").css("display", "none");
             $(".rapportframe").css("display", "initial");
         });
       }



}
