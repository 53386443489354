import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { ConfigurationService } from '../../../config/configuration.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-rp-etat-stock-client',
  templateUrl: './rp-etat-stock-client.component.html',
  styleUrls: ['./rp-etat-stock-client.component.css']
})
export class RpEtatStockClientComponent implements OnInit {
  apiUrl:String = "";
  activeTab:String="saisir";
  listmagasin:any = [];
  recherche:any={};
  listdoss:any=[];
  listclient:any=[];
  listprod:any=[];
  Liste_etat_clt:any=[];
  lien_fiche_ExpST:any;
  constructor(private transfertDataService : transfertDataService,  private ConfigurationService : ConfigurationService) { }

  ngOnInit(): void {
    this.ListeMagasin();
  }
  search(activeTab:String){
    this.activeTab=activeTab
  };

   ListeAutoCompleteDossier(nom_societe:any){
   this.apiUrl = "SERVER/reporting/etatstockclient/recherche_dossier";
      var data = {nom_societe:nom_societe};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listdoss= value.listdoss;
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }

  SelectionAutocomplete(data:any): void{
    this.recherche.nom_societe = data.nom_societe;
    this.recherche.idClient = data.idClient;
    this.Recherche(this.recherche);
  }


  ListeMagasin(){
   this.apiUrl = "SERVER/reporting/etatstockclient/listemagasin";
      var data = {};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listmagasin = value.listmagasin;
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }

  Recherche(data:any): void{
      this.apiUrl = "SERVER/reporting/etatstockclient/recherche";
      var data = data;
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
      if(this.recherche.nom_societe == "" && this.recherche.NomMagasin == ""){
        this.Liste_etat_clt = [];
      }
      this.Liste_etat_clt= value.Liste_etat_clt;
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }

  RapportPdf(){
this.lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/Reporting/pdf_Reportingficheetatstockclt.php?NomMagasin="+this.recherche.NomMagasi+"&nom_societe="+this.recherche.nom_societe;
$("#printRapport").remove();
$("body").append('<div id="printRapport">\
                   <div class="col-md-12 form-group" style="display:none;">\
                     <button class="btn btn-primary btn-sm bs-modal-frame-sm-btn" data-backdrop="static" data-toggle="modal" data-target=".bs-modal-frame-sm"></button>\
                   </div>\
                   <div class="modal fade bs-modal-frame-sm" tabindex="-1" role="dialog" aria-hidden="true">\
                     <div class="modal-dialog" role="document">\
                         <div class="modal-content">\
                             <div class="row container_modal" style="width:700px;margin-left:-35px">\
                             <div class="modal-header" style="margin-bottom:0px;">\
                               <h6 class="modal-title" style="font-weight: bold;">ETAT STOCK CLIENT</h6>\
                               <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>\
                               </button>\
                             </div>\
                             <div class="row col-md-12" style="width:100%;height: calc(100vh - 90px);padding-left: 0px; padding-right: 0px; margin-left: 0px; margin-right: 0px;">\
                               <div class="col-md-12 waiting">\
                                 <div class="snippet" data-title=".dot-pulse">\
                                   <div class="stage">\
                                     <div class="dot-pulse"></div>\
                                   </div>\
                                 </div>\
                               </div>\
                               <iframe class="rapportframe" name="rapportframe" style="width:100%;"></iframe>\
                             </div>\
                         </div>\
                       </div>\
                     </div>\
                   </div>\
               </div>');
$(".bs-modal-frame-sm-btn").trigger("click");
window.open(this.lien_fiche_ExpST , "rapportframe")?.focus();
$(".rapportframe").css("display", "none");
$('.rapportframe').on('load', function(){
   $(".waiting").css("display", "none");
   $(".rapportframe").css("display", "initial");
});
}

}
