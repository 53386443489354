<div class="x_panel x_panel_close">
        <div class="x_content">
               <div  class="d-flex no-block align-items-center" style="margin: 5px 10px;"><span ><i  class="fa fa-arrow-left"></i></span>
                   <div  class="ml-auto text-right"><span  class="sidebar-link has-arrow waves-effect waves-dark"><i  class="fa fa-home"></i>&nbsp;&nbsp;</span><i  class="fa fa-chevron-right"></i><span  class="sidebar-link has-arrow waves-effect waves-dark">&nbsp;Gestion Navires - Gestion Cales Navire</span>
                   </div>
               </div>
               
                   <div class="x_panel_title x_panel_close" style="width: 99%;margin: 0px auto;display: table;">
                          <div class="x_title">
                            <h5>INFORMATIONS NAVIRE</h5>
                            <ul class="nav navbar-right panel_toolbox" style="padding-left: 50px;">
                            </ul>
                            <div class="clearfix"></div>
                          </div>
                          <div class="x_content">
                            <div class="row">
                              <div class="col-xs-12 col-md-4"></div>
                              <div class="col-xs-12 col-md-4 form-group" style="margin-bottom: 20px;">
                                <div class="persoautocomplete">
                                      <label for="">Selectionner N°Dossier*</label>
                                     <input [(ngModel)]="dossier.numeroDossier" type="text" name="numdossForm" id="numdossForm" placeholder="N°Dossier"  class="form-control form-control-sm" autocomplete="off" (focus)="reInitSearchForm()" (blur)="reInitSearchBlurForm()" disabled="disabled">
                                       <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="">
                                       </button>
                                        <div class="classPerso  dropdown-menu">
                                          <form class="px-0 py-3">
                                              <div class="col-md-12">
                                                  <div class="row">
                                                      <div class="col-md-12">
                                                        <input type="text" [(ngModel)]="dossier.numeroDossier" class="form-control form-control-sm" placeholder="N°Dossier" name="FormSaisiGestCal" (ngModelChange)="ListeAutoCompleteDossier(dossier.numeroDossier)" autocomplete="off">
                                                        <div>
                                                          <br>
                                                            <table class="table_bloc_liste tableReseach">
                                                                <thead class="theadfixed">
                                                                    <th>N°Dossier</th>
                                                                </thead>
                                                                <tbody>
                                                                    <tr class="SelectTable"  *ngFor="let x of listdoss" (click)="SelectionAutocomplete(x)">
                                                                        <td>{{x.numeroDossier}}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </form>
                                        </div>
                                </div>
                              </div>
                              <div class="col-xs-12 col-md-4"></div>
                              <div class="form-group col-xs-12 col-md-3">
                                <label for="Compagnie">Navire</label> : 
                                <span>{{dossier.Navire}}</span>
                              </div>
                              <div class="form-group col-xs-12 col-md-3">
                                <label for="Compagnie">Draft</label> : 
                                <span>{{dossier.draft}}</span>
                              </div>
                              <div class="form-group col-xs-12 col-md-3">
                                <label for="agentnavire">Agent navire</label>
                                 : 
                                <span>{{dossier.agent_navire}}</span>
                              </div>
                              <div class="form-group col-xs-12 col-md-3">
                                <label for="date_arrive_prevu">Date d'arrivée prévu (ETA)</label>
                                 : 
                                <span>{{dossier.date_arrivee_prevu}}</span>
                              </div>

                              <div class="form-group col-xs-12 col-md-3">
                                <label for="date_arrive_prevu">Date arrivée</label>
                                 : 
                                <span>{{dossier.date_arrivee}}</span>
                              </div>

                              <div class="form-group col-xs-12 col-md-3">
                                <label for="date_arrive_prevu">Date départ</label>
                                 : 
                                <span>{{dossier.date_sortie}}</span>
                              </div>

                              <div class="form-group col-xs-12 col-md-3">
                                <label for="provenance">Provenance</label>
                                 : 
                                <span>{{dossier.provenance}}</span>
                              </div>

                              <div class="form-group col-xs-12 col-md-3">
                                <label for="capitaine">Poids total (Tonne)</label>
                                 : 
                                <span>{{dossier.total_poids}}</span>
                              </div>

                              <div class="form-group col-xs-12 col-md-3">
                                <label for="capitaine">Quantité total</label>
                                 : 
                                <span>{{dossier.total_qte}}</span>
                              </div>

                              <div class="form-group col-xs-12 col-md-3">
                                <label for="capitaine">Capitaine</label>
                                 : 
                                <span>{{dossier.capitaine}}</span>
                              </div>


                              <div class="form-group col-xs-12 col-md-3">
                                <label for="num_voyage">Numéro voyage</label>
                                 : 
                                <span>{{dossier.numero_voyage}}</span>
                              </div>

                              </div>
                              <br>
                                <div class="rowP">
                                    <div class="col-sm-12 col-md-3 contenucale" *ngFor="let x of contenuCale">
                                      <div style="width: 98%;display: block;margin: 0px auto;padding-bottom: 10px;" >
                                          <div>
                                             <p style="background: #1C65B9;padding: 10px;border-radius: 3px 3px 0px 0px;margin-bottom: 0px;color: #fff;font-weight: 400;">
                                               {{x.libelle}}
                                               
                                             </p>
                                              <div class="row" style="padding-left: 15px;padding-right: 15px;margin-bottom: 5px;">
                                                  <div class="col-md-4" style="background: #00f3ff;text-align: center;padding-left: 2px;padding-right: 2px;">
                                                    <span style="font-size: 12px;width: 100%;display: block;text-align: center;">T.Manifesté</span>
                                                    <span style="font-size: 12px;width: 100%;display: block;text-align: center;"><b>{{x.tonnage_manifeste}}</b></span>
                                                  </div>
                                                  <div class="col-md-4" style="background: #00f3ff;text-align: center;padding-left: 2px;padding-right: 2px;">
                                                    <span style="font-size: 12px;width: 100%;display: block;text-align: center;">T.Réalisé</span>
                                                    <span style="font-size: 12px;width: 100%;display: block;text-align: center;"><b>{{x.tonnage_realise}}</b></span>
                                                  </div>
                                                  <div class="col-md-4" style="background: #00f3ff;text-align: center;padding-left: 2px;padding-right: 2px;">
                                                    <span style="font-size: 12px;width: 100%;display: block;text-align: center;">T.Restant</span>
                                                    <span style="font-size: 12px;width: 100%;display: block;text-align: center;"><b>{{x.restant}}</b></span>
                                                  </div>
                                               </div>
                                             
                                           </div>
                                           <div style="width: 95%;margin: 0px auto;background: #1C65B9;padding: 10px;border-radius: 3px;margin-bottom: 15px;padding-top: 5px;font-size: 13px;color: #fff;padding-bottom: 0px;" *ngFor="let y of x.listeclients">
                                              <div>
                                            
                                                <span>
                                                    Client : <b style="font-weight: 600;">{{y.nom_societe}}</b>
                                                </span><br>
                                                <span>
                                                    Produit : <b style="font-weight: 600;">{{y.NomSpecificProduit}}</b>
                                                </span><br>
                                                <span>
                                                    Poids (Tonne) : <b style="font-weight: 600;">{{y.PoidsTotal}}</b>
                                                </span><br>
                                                <span>
                                                    Qte : <b style="font-weight: 600;">{{y.QteTotal}}</b>
                                                </span><br>
                                               
                                              </div>
                                              <div class="row" style="padding-left: 5px;padding-right: 5px;margin-bottom: 0px;margin-top: 5px;color: #000;">
                                                  <div class="col-md-4" style="background: #00f3ff;text-align: center;padding-left: 0px;padding-right: 0px;border-radius: 0px 0px 0px 3px;">
                                                    <span style="font-size: 12px;width: 100%;display: block;text-align: center;">T.Manifesté</span>
                                                    <span style="font-size: 12px;width: 100%;display: block;text-align: center;"><b>{{y.tonnage_manifeste}}</b></span>
                                                  </div>
                                                  <div class="col-md-4" style="background: #00f3ff;text-align: center;padding-left: 0px;padding-right: 0px;">
                                                    <span style="font-size: 12px;width: 100%;display: block;text-align: center;">T.Réalisé</span>
                                                    <span style="font-size: 12px;width: 100%;display: block;text-align: center;"><b>{{y.tonnage_realise}}</b></span>
                                                  </div>
                                                  <div class="col-md-4" style="background: #00f3ff;text-align: center;padding-left: 0px;padding-right: 0px;border-radius: 0px 0px 3px 0px;">
                                                    <span style="font-size: 12px;width: 100%;display: block;text-align: center;">T.Restant</span>
                                                    <span style="font-size: 12px;width: 100%;display: block;text-align: center;"><b>{{y.restant}}</b></span>
                                                  </div>
                                               </div>
                                           </div>
                                      </div>
                                     
                                    </div>
                                </div>
                          </div>
                        </div>
                </div>
            </div>









