import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { pipe } from 'rxjs';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';
import { AuthService } from '../../../services/connexion/auth.service';
import { CONTENEURTRAITE } from '../../../classes/conteneurtraite';
import { ConfigurationService } from '../../../config/configuration.service';

@Component({
  selector: 'app-traiter-conteneur',
  templateUrl: './traiter-conteneur.component.html',
  styleUrls: ['./traiter-conteneur.component.css']
})
export class TraiterConteneurComponent implements OnInit {
  activeTab:String="saisir";
  agent: any = [];
  choix:any;
  amateur: any = [];
  tous :boolean = false;
  aucun :boolean = false;
    supp_id:any = "";
  listeConteneurs: any = [];
    listeConteneursSelect: any = [];
    listeConteneurstraite: any = [];
    activePagination:number = 0;
    liste_pagination:any = [];
    activePagination1:number = 0;
    liste_pagination1:any = [];
  recherche:any = {};
  rechercher:any = {};
  liste_AmateurRech:any=[];
  apiUrl:String = "";
  photos_previsualisation:any = [];
  array_files:any = [];
    id:any;
  ModaleTitle:any;
ModaleText:any;
ModalFoot:any = "FootNoErr";
  CONTENEURTRAITE:CONTENEURTRAITE = new CONTENEURTRAITE();

  constructor(private transfertDataService : transfertDataService, private AuthService : AuthService,private ConfigurationService : ConfigurationService) { }

  ngOnInit(): void {
      var date = new Date();
    this.ListeDesConteneurs();
    this.listeAgent();
    this.listeAmateur1();
    this.ListeDesConteneursTraiter();
    this.recherche.numConteneur = "";
    this.recherche.amateur = "";
        this.recherche.numBL= "";
    this.rechercher.amateur1= "";
    this.rechercher.agent= "";
    this.rechercher.dateFin = date.getFullYear()+"-"+("0" + (date.getMonth() + 1)).slice(-2)+"-"+this.DernierJourMoisenCours(date.getFullYear(),date.getMonth());
    this.rechercher.dateDebut = date.getFullYear()+"-"+("0" + (date.getMonth() + 1)).slice(-2)+"-01";
    this.listeAmateur();
  }
  DernierJourMoisenCours(y:any,m:any){
    return  new Date(y, m +1, 0).getDate();
  }
  reInitSearchBlurFormAmateur(): void{
    setTimeout((val:any) => {
        this.liste_AmateurRech = [];
    }, 500);
  }
  Fiche(){
    console.log(this.id);

     this.ConfirmationImpressionFormulaire1(this.id);

     this.search("profile");
  }
  Fiche1(){
        console.log(this.id);
     this.Exporter1(this.id);
     this.search("profile");
  }
  ConfirmationImpressionFormulaire1(Id:any){
    var lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/ImportLivraisonConteneur/pdfConteneurTraite1.php?Id="+Id;
    $("#printRapport").remove();
    $("body").append('<div id="printRapport">\
                        <div class="col-md-12 form-group" style="display:none;">\
                          <button class="btn btn-primary btn-sm bs-modal-frame-sm-btn" data-backdrop="static" data-toggle="modal" data-target=".bs-modal-frame-sm"></button>\
                        </div>\
                        <div class="modal fade bs-modal-frame-sm" tabindex="-1" role="dialog" aria-hidden="true">\
                          <div class="modal-dialog" role="document">\
                              <div class="modal-content">\
                                  <div class="row container_modal" style="width:100%;">\
                                  <div class="modal-header" style="margin-bottom:0px;">\
                                    <h6 class="modal-title" style="font-weight: bold;">LISTE PREVISIONNEL DES LIVRAISONS</h6>\
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>\
                                    </button>\
                                  </div>\
                                  <div class="row col-md-12" style="width:100%;height: calc(100vh - 90px);padding-left: 0px; padding-right: 0px; margin-left: 0px; margin-right: 0px;">\
                                    <div class="col-md-12 waiting">\
                                      <div class="snippet" data-title=".dot-pulse">\
                                        <div class="stage">\
                                          <div class="dot-pulse"></div>\
                                        </div>\
                                      </div>\
                                    </div>\
                                    <iframe class="rapportframe" name="rapportframe" style="width:100%;"></iframe>\
                                  </div>\
                              </div>\
                            </div>\
                          </div>\
                        </div>\
                    </div>');
    $(".bs-modal-frame-sm-btn").trigger("click");
    window.open(lien_fiche_ExpST , "rapportframe")?.focus();
    $(".rapportframe").css("display", "none");
    $('.rapportframe').on('load', function(){
        $(".waiting").css("display", "none");
        $(".rapportframe").css("display", "initial");
    });
  }
  Exporter1(Id:any): void{
      var lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/ImportLivraisonConteneur/excel_ConteneurTraite1.php?Id="+Id;
        window.open(lien_fiche_ExpST , '_blank')?.focus();
  }

  listeAmateur(){
    this.apiUrl = "SERVER/importretourconteneur/retour/nomAmateur";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((response:any) => {
         this.amateur = response.liste;

       }, (erreur:any) => {
           console.log(erreur.status);
       })

  }
  ListeAutoCompleteAmateurRech(amateur:any) : void{

    this.apiUrl = "SERVER/importlivraisonconteneur/traiterconteneur/listeamateurrecherche";
    var data = {amateur:amateur};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((response:any) => {
      this.liste_AmateurRech = response.liste_amateur;
    }, (erreur:any) => {
    })

  }


  SelectionAutocompleteRecheche(data:any): void{

    this.recherche.amateur = data.nomAmateur;
    if(this.recherche.amateurSaisi == "")
      this.recherche.amateur = "";
    this.recherch(this.recherche);
  }
  SelectionAutocompleteRecheche1(data:any): void{

    this.rechercher.amateur1 = data.nomAmateur;
    if(this.rechercher.amateurSaisi1 == "")
      this.rechercher.amateur1 = "";

    this.rechercheConteneurtraiter(this.rechercher);
  }
  reInitSearchFormAmateur(): void{
    this.liste_AmateurRech = [];
  }


  Annuler():void{
    this.CONTENEURTRAITE = new CONTENEURTRAITE();
    this.listeConteneurs= [];
    this.listeConteneursSelect= [];
    this.recherche = {};
  }
  ListeDesConteneursTraiter(): void{
    this.apiUrl = "SERVER/importlivraisonconteneur/traiterconteneur/liste";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((response:any) => {

          this.listeConteneurstraite = response.liste;
this.Calcule();
       }, (erreur:any) => {
           console.log(erreur.status);
       })

  }
  ListeDesConteneurs(): void{
    this.apiUrl = "SERVER/importlivraisonconteneur/traiterconteneur/listeRecive";
    var data = {};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((response:any) => {


         for (let i = 0; i <  response.liste.length; i++) {
            response.liste[i].choix = false;

         }
          this.listeConteneurs = response.liste;

       }, (erreur:any) => {
           console.log(erreur.status);
       })

  }
  search(activeTab:String){
     this.activeTab=activeTab
   };

  listeAgent(){
    this.apiUrl = "SERVER/importlivraisonconteneur/traiterconteneur/nomAgent";
        var data = {};
        this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
            retryWhen(error =>
                 error.pipe(
                   scan((acc:any, error:any) => {
                     if (acc > 2) throw error;
                     return acc + 1;
                   }, 1),
                   delay(1000)
                 )
               )
           ).subscribe((response:any) => {
             this.agent = response.liste;

           }, (erreur:any) => {
               console.log(erreur.status);
    })

  }

  listeAmateur1(){
    this.apiUrl = "SERVER/importlivraisonconteneur/traiterconteneur/nomAmateur";
        var data = {};
        this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
            retryWhen(error =>
                 error.pipe(
                   scan((acc:any, error:any) => {
                     if (acc > 2) throw error;
                     return acc + 1;
                   }, 1),
                   delay(1000)
                 )
               )
           ).subscribe((response:any) => {
             this.amateur = response.liste;

           }, (erreur:any) => {
               console.log(erreur.status);
    })

  }

  ConteneursTraite(): void{
    this.apiUrl = "SERVER/importlivraisonconteneur/traiterconteneur/ConteneursTraite";
    var data = {id:this.CONTENEURTRAITE.idConteneurTraite};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((response:any) => {


         for (let i = 0; i <  response.liste.length; i++) {
            response.liste[i].choix = false;

         }
          this.listeConteneursSelect = response.liste;

       }, (erreur:any) => {
           console.log(erreur.status);
       })

  }
  afficher(){
    if (this.tous == true) {
      this.recherche.amateur= "";
      this.ListeDesConteneurs();
    }
  }
    /*CHAMP OBLIGATOIRE*/
  VerificationChampsObligatoire(tableau_champs_obligatoires:any){
    var verification_formulaire = true;
    for (var i = 0; i < tableau_champs_obligatoires.length; i++) {
        if($(tableau_champs_obligatoires[i]).val() == "" || $(tableau_champs_obligatoires[i]).val() == null || $(tableau_champs_obligatoires[i]).val() == "? string: ?"){
                $(tableau_champs_obligatoires[i]).css("border","1px solid red");
                verification_formulaire = false;
                document.getElementById(tableau_champs_obligatoires[i].replace("#",""))?.scrollIntoView({behavior: "smooth"});
                break;
        }else{
            $(tableau_champs_obligatoires[i]).css("border","");
        }
    }

    return verification_formulaire;
  }


   Enregistrer(){
    //Champ obligatoire
    if(this.VerificationChampsObligatoire(["#dateTraitee","#dateTransmis"]) == false){
      return;
    }

    var infoUser = this.AuthService.getInfoUtilisateur();

    if(this.CONTENEURTRAITE.idConteneurTraite == 0){
      this.apiUrl = "SERVER/importlivraisonconteneur/traiterconteneur/enregistrer";
      this.CONTENEURTRAITE.creerPar = infoUser.id;

    }else{
      this.apiUrl = "SERVER/importlivraisonconteneur/traiterconteneur/modifier";
      this.CONTENEURTRAITE.modifierPar = infoUser.id;
    }

    this.CONTENEURTRAITE.idAgent = Number(this.CONTENEURTRAITE.idAgent);
    this.CONTENEURTRAITE.dateTraite = String(this.CONTENEURTRAITE.dateTraite);

    var d = {"conteneurtraite": this.CONTENEURTRAITE, "sousmenu" : this.listeConteneursSelect};
    this.transfertDataService.EnvoiData(d, this.apiUrl).pipe(
            retryWhen(error =>
               error.pipe(
                 scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((value:any) => {

            this.id = value.precedent[0].idConteneurTraite;
            this.ModaleTitle = "CONFIRMATION TELECHARGEMENT";
            this.ModaleText  = "Souhaitez vous Telecharger ?";
            this.ModalFoot = "FootNoErr";
            $(".ouverturefiche").trigger("click");

          this.ListeDesConteneursTraiter();
           this.Annuler();
         }, (erreur:any) => {
             console.log(erreur.status);
    })
  }

  Ajouter(){
    for (let i = 0; i < this.listeConteneurs.length; i++) {

      if (this.listeConteneurs[i].choix == true) {
           this.listeConteneursSelect.push(this.listeConteneurs[i]);
      }

    }
  }
  Retirer(){
      console.log(this.CONTENEURTRAITE.idConteneurTraite);
    if (this.CONTENEURTRAITE.idConteneurTraite == 0) {
      for (let i = 0; i < this.listeConteneursSelect.length; i++) {

        if (this.listeConteneursSelect[i].choix == false) {
             this.listeConteneursSelect.splice(i, 1);
        }

      }
    }else{
      let eltSuprimer = [];
      for (let y = 0; y < this.listeConteneursSelect.length; y++) {
        if (this.listeConteneursSelect[y].choix == true) {
            eltSuprimer.push(this.listeConteneursSelect);
        }

      }
      this.apiUrl = "SERVER/importlivraisonconteneur/traiterconteneur/supprimerTraite";
      var data = {eltSuprimer:eltSuprimer};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
this.ConteneursTraite();
      }, (erreur:any) => {
          console.log(erreur.status);
      })
    }

  }
  information(id:any):void{

    this.apiUrl = "SERVER/importlivraisonconteneur/traiterconteneur/information";
    var data = {id:id};
    this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
      retryWhen(error =>
        error.pipe(
              scan((acc:any, error:any) => {
                   if (acc > 2) throw error;
                   return acc + 1;
                 }, 1),
                 delay(1000)
               )
             )
         ).subscribe((response:any) => {
              this.CONTENEURTRAITE = response.conteneur[0];

              for (let i = 0; i < response.conteneur[0]["Traiter"].length; i++) {
                this.listeConteneursSelect[i]= response.conteneur[0]["Traiter"][i];


              }



              this.search("saisir");
           }, (erreur:any) => {
             console.log(erreur.status);
         })

     }
  SupprimerPopup(id:any): void{
    $(".suppliste").trigger("click");
    this.supp_id = id;
  }

  supprimer(id:any):void{
       this.apiUrl = "SERVER/importlivraisonconteneur/traiterconteneur/supprimer";
       var data = {id:id};
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
     ).subscribe((value:any) => {
         this.ListeDesConteneursTraiter();
       }, (erreur:any) => {
           console.log(erreur.status);
       })

  }
  TousAfficher(): void{
    if (this.recherche.tous == true) {
      this.recherche.Aucun = true;
      for (let i = 0; i < this.listeConteneurs.length; i++) {

        this.listeConteneurs[i].choix = true;


      }
    }
  }
  RienAfficher(): void{
    if (this.recherche.Aucun == true) {
        this.recherche.tous = false;
      for (let i = 0; i < this.listeConteneurs.length; i++) {

        this.listeConteneurs[i].choix = false;


      }
    }
  }
  TousAfficher1(): void{
    if (this.recherche.tous1 == true) {
      this.recherche.Aucun1 = true;
      for (let i = 0; i < this.listeConteneursSelect.length; i++) {

        this.listeConteneursSelect[i].choix = true;


      }
    }
  }
  RienAfficher1(): void{
    if (this.recherche.Aucun1 == true) {
        this.recherche.tous1 = false;
      for (let i = 0; i < this.listeConteneursSelect.length; i++) {

        this.listeConteneursSelect[i].choix = false;


      }
    }
  }
  recherch(data:any): void{




       if(this.recherche.numConteneur == "" && this.recherche.numBL == "")
       this.ListeDesConteneurs();
       else{
         this.apiUrl = "SERVER/importlivraisonconteneur/traiterconteneur/rechercheReception";
         var data = data;
         this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
           retryWhen(error =>
             error.pipe(
               scan((acc:any, error:any) => {
                 if (acc > 2) throw error;
                 return acc + 1;
               }, 1),
               delay(1000)
             )
           )
       ).subscribe((value:any) => {
              this.listeConteneurs= value.data;
              this.Calcule1();
         }, (erreur:any) => {
             console.log(erreur.status);
         })
       }

   }


rechercheConteneurtraiter(data:any): void{

     if(this.rechercher.amateur1 == "" && this.rechercher.agent == "" && this.rechercher.dateDebut == ""  && this.rechercher.dateFin == "")
     this.ListeDesConteneursTraiter();
     else{
       this.apiUrl = "SERVER/importlivraisonconteneur/traiterconteneur/recherche";
       var data = data;
       this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
         retryWhen(error =>
           error.pipe(
             scan((acc:any, error:any) => {
               if (acc > 2) throw error;
               return acc + 1;
             }, 1),
             delay(1000)
           )
         )
     ).subscribe((value:any) => {
            this.listeConteneurstraite= value.data;
this.Calcule();
       }, (erreur:any) => {
           console.log(erreur.status);
       })
     }

 }
 Exporter(): void{
     var lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/ImportLivraisonConteneur/excel_ConteneurTraite.php?amateur1="+this.rechercher.amateur1+"&agent="+this.rechercher.agent+"&dateDebut="+this.rechercher.dateDebut+"&dateFin="+this.rechercher.dateFin;
       window.open(lien_fiche_ExpST , '_blank')?.focus();
 }


 ConfirmationImpressionFormulaire(){
        var lien_fiche_ExpST = this.ConfigurationService.configuration.hostname + "SERVER/modules/Etats/vues/ImportLivraisonConteneur/pdfConteneurTraite.php?amateur1="+this.rechercher.amateur1+"&agent="+this.rechercher.agent+"&dateDebut="+this.rechercher.dateDebut+"&dateFin="+this.rechercher.dateFin;
      $("#printRapport").remove();
        $("body").append('<div id="printRapport">\
                            <div class="col-md-12 form-group" style="display:none;">\
                              <button class="btn btn-primary btn-sm bs-modal-frame-sm-btn" data-backdrop="static" data-toggle="modal" data-target=".bs-modal-frame-sm"></button>\
                            </div>\
                            <div class="modal fade bs-modal-frame-sm" tabindex="-1" role="dialog" aria-hidden="true">\
                              <div class="modal-dialog" role="document">\
                                  <div class="modal-content">\
                                      <div class="row container_modal" style="width:100%;">\
                                      <div class="modal-header" style="margin-bottom:0px;">\
                                        <h6 class="modal-title" style="font-weight: bold;">LISTE CONTENEUR TRAITE</h6>\
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>\
                                        </button>\
                                      </div>\
                                      <div class="row col-md-12" style="width:100%;height: calc(100vh - 90px);padding-left: 0px; padding-right: 0px; margin-left: 0px; margin-right: 0px;">\
                                        <div class="col-md-12 waiting">\
                                          <div class="snippet" data-title=".dot-pulse">\
                                            <div class="stage">\
                                              <div class="dot-pulse"></div>\
                                            </div>\
                                          </div>\
                                        </div>\
                                        <iframe class="rapportframe" name="rapportframe" style="width:100%;"></iframe>\
                                      </div>\
                                  </div>\
                                </div>\
                              </div>\
                            </div>\
                        </div>');
        $(".bs-modal-frame-sm-btn").trigger("click");
        window.open(lien_fiche_ExpST , "rapportframe")?.focus();
        $(".rapportframe").css("display", "none");
        $('.rapportframe').on('load', function(){
            $(".waiting").css("display", "none");
            $(".rapportframe").css("display", "initial");
        });
 }
 PaginationNb(index:number) : void{
 var data = {"index":index,  "data": this.recherche};
 this.apiUrl = "SERVER/importlivraisonconteneur/traiterconteneur/PaginationNb";
   this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
      retryWhen(error =>
        error.pipe(
          scan((acc:any, error:any) => {
            if (acc > 2) throw error;
            return acc + 1;
          }, 1),
          delay(1000)
        )
      )
   ).subscribe((value:any) => {
      this.listeConteneurstraite = value.data;

    }, (erreur:any) => {
   })

}
 PaginationNb1(index:number) : void{
 var data = {"index":index,  "data": this.recherche};
 this.apiUrl = "SERVER/importlivraisonconteneur/traiterconteneur/PaginationNb1";
   this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
      retryWhen(error =>
        error.pipe(
          scan((acc:any, error:any) => {
            if (acc > 2) throw error;
            return acc + 1;
          }, 1),
          delay(1000)
        )
      )
   ).subscribe((value:any) => {
      this.listeConteneurs = value.data;

    }, (erreur:any) => {
   })

}

Calcule() : void{
 this.apiUrl = "SERVER/importlivraisonconteneur/traiterconteneur/CalculePagination";
   this.transfertDataService.EnvoiData(this.recherche, this.apiUrl).pipe(
      retryWhen(error =>
        error.pipe(
          scan((acc:any, error:any) => {
            if (acc > 2) throw error;
            return acc + 1;
          }, 1),
          delay(1000)
        )
      )
   ).subscribe((value:any) => {
      this.liste_pagination = value.data;
    }, (erreur:any) => {
   })
}
Calcule1() : void{
 this.apiUrl = "SERVER/importlivraisonconteneur/traiterconteneur/CalculePagination1";
   this.transfertDataService.EnvoiData(this.recherche, this.apiUrl).pipe(
      retryWhen(error =>
        error.pipe(
          scan((acc:any, error:any) => {
            if (acc > 2) throw error;
            return acc + 1;
          }, 1),
          delay(1000)
        )
      )
   ).subscribe((value:any) => {
      this.liste_pagination1 = value.data;
    }, (erreur:any) => {
   })
}

CurrentPagePagination(data:any, index:number, ev:any): void{
   this.activePagination = index;
   this.PaginationNb(data.index);
}

CurrentPagePagination1(data:any, index:number, ev:any): void{
   this.activePagination1 = index;
   this.PaginationNb1(data.index);
}
}
