import { Component, OnInit } from '@angular/core';
import { transfertDataService } from '../../../services/transfertData/transfertData.service';
import { retry, delay, retryWhen, tap, scan, shareReplay  } from 'rxjs/operators';

@Component({
  selector: 'app-arrivedepartnavire',
  templateUrl: './arrivedepartnavire.component.html',
  styleUrls: ['./arrivedepartnavire.component.css']
})
export class ArrivedepartnavireComponent implements OnInit {
  apiUrl:String = "";
  activeTab:String="saisir";
  jsonnavire:any = {};
  listedossierarrivee:any = [];
  constructor(private transfertDataService : transfertDataService,) { }

  ngOnInit(): void {
  }

  //Fonction de verification de champs
    VerificationChampsObligatoire(tableau_champs_obligatoires:any){
        var verification_formulaire = true;
        for (var i = 0; i < tableau_champs_obligatoires.length; i++) {
            if($(tableau_champs_obligatoires[i]).val() == "" || $(tableau_champs_obligatoires[i]).val() == null || $(tableau_champs_obligatoires[i]).val() == "? string: ?"){
                    $(tableau_champs_obligatoires[i]).css("border","1px solid red");
                    verification_formulaire = false;
                    document.getElementById(tableau_champs_obligatoires[i].replace("#",""))?.scrollIntoView({behavior: "smooth"});
                    break;
            }else{
                $(tableau_champs_obligatoires[i]).css("border","");
            }
        }

        return verification_formulaire;
    }

  reInitSearch(): void{
    this.listedossierarrivee = [];
  }

  reInitSearchBlur(): void{
    setTimeout((val:any) => {
        this.listedossierarrivee = [];
    }, 500);
  }


  search(activeTab:String){
     this.activeTab=activeTab;
  };

  Annuler():void{
    this.jsonnavire.numeroDossier = "";
    this.jsonnavire.date = "";
    this.jsonnavire.agentnavire = "";
    this.jsonnavire.navire = "";
    this.jsonnavire.etatNavire = -1;
    this.listedossierarrivee = [];
  }


  selectionAutocompleteArriveeNavire(data:any):void{
    this.jsonnavire.idGestionNavire = data.idGestionNavire;
    this.jsonnavire.numeroDossier = data.numeroDossier;
    this.jsonnavire.agentnavire = data.agent_navire;
    this.jsonnavire.navire = data.Navire;
    this.jsonnavire.etatNavire = data.status_navire;

    switch(data.status_navire){
      case "0" :
        this.jsonnavire.date = data.date_arrivee_prevu;
      break;
      case "1" :
        this.jsonnavire.date = data.date_arrivee;
      break;
      case "2" :
        this.jsonnavire.date = data.date_sortie;
      break;
    }
  }

  AutocompleteArriveeNavire(numeroDossier:any):void{
      this.apiUrl = "SERVER/gestionnavire/navire/autocomplete_numdoss_arrivee_navire";
      var data = {numeroDossier:numeroDossier};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
        this.listedossierarrivee= value.listedossiers;
      }, (erreur:any) => {
          console.log(erreur.status);
      })
  }

  Enregistrer(x:any, event:any):void{
      if(this.VerificationChampsObligatoire(["#numdossArrive", "#date_arrivee"]) == false)
              return;
  event.target.disabled = true;
      this.apiUrl = "SERVER/gestionnavire/navire/enregistrer_depart_arrivee";
      var data = {dt:x};
      this.transfertDataService.EnvoiData(data, this.apiUrl).pipe(
        retryWhen(error =>
          error.pipe(
            scan((acc:any, error:any) => {
              if (acc > 2) throw error;
              return acc + 1;
            }, 1),
            delay(1000)
          )
        )
    ).subscribe((value:any) => {
      event.target.disabled = false;
        this.Annuler();
      }, (erreur:any) => {
  event.target.disabled = false;
      })
  }


}
