export class MODELIVRAISON {
  public id: number = 0;
  public mode: string = "";
  public creerPar: number = 0;
  public creerLe: string = "";
  public modifierPar: number = 0;
  public modifierLe: string = "";
  
  constructor() {
  }

}
