import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-liste-facture-export',
  templateUrl: './liste-facture-export.component.html',
  styleUrls: ['./liste-facture-export.component.css']
})
export class ListeFactureExportComponent implements OnInit {

  activeTab:String="profile";

  constructor() { }

  ngOnInit(): void {
  }

  search(activeTab:String){
    this.activeTab=activeTab
  }

}
